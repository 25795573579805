import React, { useState } from 'react';
import { colors } from '../styles';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { H2, F3, ModalContainer, ModalRoot, ModalHeader, ModalFooter, PrimaryButtonShort } from '.';
// import NurtureProspectsAPI from '../api/nurtureProspects';
import Loader from './Loader';
import moment from 'moment';
import { useMutation } from '@apollo/client';
import { SEND_TEST_EMAIL } from '../api/nurtureProspects';

export const MainTextInput = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
  },
});

export const ModalBody = styled('div')({
  display: 'flex',
  flex: 1,
  padding: '0px 32px',
});

interface Props {
  modalStatus: boolean;
  emails: any[];
  sender: string;
  closeModal: any;
}

const TestEmailModal = ({ modalStatus, sender, emails, closeModal }: Props) => {
  const [emailSent, setEmailSent] = useState<any>(false);
  const [emailAddr, setEmailAddr] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);
  // const [loading, setLoading] = useState(false);

  const formatEmail = (email: any) => {
    const newFormattedEmail = {
      name: email.name,
      description: email.name,
      html: email.html,
      sendDatetime: moment(email.sendDatetime).format(),
      subject: email.subject,
      editorJson: JSON.stringify(email.json),
    };
    return newFormattedEmail;
  };

  const [sendEmail, { loading, data, error }] = useMutation(SEND_TEST_EMAIL, {
    onCompleted: (response: any) => {
      if (data?.nurtureLeadsSendTestEmail?.success) {
        setEmailSent(true);
      }
    },
    onError: (error: any) => {
      setErrors([error?.message]);
    },
  });

  const sendTestEmail = async () => {
    const newErrors: any[] = [];
    try {
      if (
        !emailAddr ||
        !String(emailAddr).match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        newErrors.push('Please enter a valid email.');
      }
      const testEmail = formatEmail(emails[0]);

      sendEmail({ variables: { testEmailAddress: emailAddr, emailContent: testEmail } });
    } catch (error: any) {
      console.warn('error sending test email: ', error);
      newErrors.push(error.message);
    }
    setErrors(newErrors);
  };

  const exit = () => {
    setEmailSent(false);
    closeModal();
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer sx={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '8px 32px', margin: '0px 0px 8px' }}>
          <H2>Test Your Campaign</H2>
        </ModalHeader>
        <ModalBody style={{ width: '100%' }}>
          {!sender ? (
            <div style={{ width: '100%' }}>
              <H2>You are currently in demo mode.</H2>
              <F3 sx={{ color: colors.grey_02 }}>Emails from your campaign will not be delivered.</F3>
            </div>
          ) : !emailSent ? (
            <div>
              <H2 style={{ margin: '16px 0px 0px' }}>Enter A Test Email</H2>
              <F3 sx={{ color: colors.grey_02 }}>
                Please enter an email address to test the delivery of your new campaign.
              </F3>
              <MainTextInput
                id="email-field"
                label="Email"
                size="small"
                // sx={{ margin: '12px 0', width: '100%' }}
                value={emailAddr}
                /*className={classes.fieldOverride}*/
                focused
                onChange={(event) => setEmailAddr(event.target.value)}
              />
              {errors?.length > 0 && <F3 style={{ color: colors.red, margin: '0px 0px 8px' }}>Error: {errors?.[0]}</F3>}
            </div>
          ) : (
            <div>
              <H2>Your email has been sent!</H2>
              <F3 sx={{ color: colors.grey_02 }}>
                {'Please check and make sure the your recieved the email correctly.'}
              </F3>
            </div>
          )}
        </ModalBody>
        <ModalFooter style={{ width: '100%', padding: '10px 32px 8px' }}>
          <PrimaryButtonShort
            onClick={exit}
            style={{ background: colors.grey_02, border: `1px solid ${colors.grey_02}`, margin: 'auto 4px auto 0px' }}
          >
            Close
          </PrimaryButtonShort>
          {!emailSent && sender && (
            <PrimaryButtonShort
              onClick={sendTestEmail}
              style={{ margin: emailSent ? 'auto 0px' : 'auto 0px auto 4px' }}
            >
              {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Send'}
            </PrimaryButtonShort>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default TestEmailModal;
