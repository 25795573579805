// New Lead Pool Chart react functional component
import React from 'react';
import { colors } from '../../styles';
import { formatNumber } from '../../helpers/dataFormatter';
import { PieChart, Pie, Cell, Sector } from 'recharts';

interface Props {
  label?: string;
  hideLabel?: boolean;
  hideSectionLabel?: boolean;
  currentPoolSize: number | null;
  pieChartData?: any;
  totalPoolSize: number | null;
  activePieSector?: number;
  height?: number;
  width?: number;
  innerRadius?: number;
  outerRadius?: number;
}

export const LeadPoolChart = ({
  label,
  hideLabel,
  hideSectionLabel,
  currentPoolSize,
  totalPoolSize,
  activePieSector,
  height,
  width,
  innerRadius,
  outerRadius,
}: Props) => {
  // console.log('activePieSector: ', activePieSector);

  const renderActiveShape = (props: any) => {
    console.log('renderActiveShape: ', props);
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          style={{ fontWeight: 500, color: colors.grey_03 }}
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {`${(percent * 100).toFixed(0)}% of total`}
          {/* {`${value ? formatNumber(value) : 0} Leads`} */}
        </text>
        {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
          {`${(percent * 100).toFixed(0)}% of total`}
        </text> */}
      </g>
    );
  };

  const pieChartData = [
    {
      name: ' ',
      value: currentPoolSize,
    },
    {
      name: `${label ? label : 'Lead'} Pool`,
      value: currentPoolSize && totalPoolSize ? totalPoolSize - currentPoolSize : 0,
    },
  ];
  return (
    <>
      {!hideLabel && (
        <h1 style={{ fontWeight: 500, fontSize: '16px', margin: '16px 0px 0px 24px', color: colors.black_01 }}>
          Advisor Lead Pool
        </h1>
      )}
      <PieChart height={height ? height : 360} width={width ? width : 620}>
        <Pie
          data={
            currentPoolSize
              ? pieChartData
              : [
                  { name: ' ', value: 0 },
                  { name: 'Lead Pool', value: totalPoolSize },
                ]
          }
          activeIndex={0}
          // renderActiveShape={hideSectionLabel ? renderActiveShape : null}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          legendType="line"
          labelLine={false}
          isAnimationActive={false}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          fill={colors.grey_03}
        >
          <Cell key={`cell-0`} fill={colors.light_purple} />
          <Cell key={`cell-1`} fill={colors.grey_03} />
        </Pie>
      </PieChart>
    </>
  );
};
