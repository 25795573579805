import React from 'react';
import _ from 'lodash';
import { TextField, MenuItem } from '@mui/material';
import { colors } from '../../styles';
import { styled } from '@mui/material/styles';

interface Props {
  id: string;
  label: string;
  filterOptions: any;
  updateFilters: any;
  selectedFilter: any;
  updateMultipleFilters?: any;
  widthInput?: string;
  hideBorder?: boolean;
  disabled?: boolean;
}

const GeneralTextField = styled(TextField)({
  color: '#242B2F',
  borderColor: '#ECECEC',
  '& .MuiSelect-select': {
    zIndex: 100,
    '& .MuiPaper-root': {
      zIndex: 100,
      background: '#fff',
      boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
    },
  },
  '& label': {
    fontSize: '13px',
    margin: '3px 0px 0px 4px',
    color: colors.grey_03,
    '&.Mui-focused': {
      color: '#404B50',
    },
  },
  '& .MuiOutlinedInput-root': {
    height: 30,
    paddingBottom: 0,
    margin: '0 4px',
    fontSize: 12,
    '& fieldset': {
      color: '#404B50',
    },
    '&:hover fieldset': {
      borderColor: '#404B50',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#404B50',
      border: `1px solid #404B50`,
      color: '#242B2F',
    },
  },
});

const MenuContainer = styled(MenuItem)({
  height: '42px',
  //width: '230px',
  'z-index': 100,
  backgroundColor: colors.white_01,
  '&:hover': {
    fontWeight: '600',
  },
});

export const BasicFilterMenu = ({
  id,
  label,
  updateFilters,
  filterOptions,
  selectedFilter,
  updateMultipleFilters,
  widthInput,
  hideBorder,
  disabled,
}: Props) => {
  return (
    <>
      <GeneralTextField
        id={id ? id : 'basic-menu'}
        // onClose={() => handleCloseCustom(label)}
        label={label}
        value={selectedFilter ? selectedFilter?.label : 'Any'}
        size="small"
        select
        //@ts-ignore
        // inputProps={{ classes: { input: classes.input1 } }}
        sx={{
          fontFamily: 'Inter',
          margin: '5px 4px 5px 0px',
          'z-index': 0,
          // border: true ? 'none' : '1px solid #ECECEC',
          width: widthInput ? widthInput : '230px',
        }}
        disabled={disabled}
      >
        {filterOptions.map((field: any, index: number) => {
          return (
            <MenuContainer
              key={index}
              sx={{ fontFamily: 'Inter', width: widthInput ? widthInput : '230px' }}
              id={`${id}-${field.label.replace(' ', '-')}`}
              onClick={() => updateFilters(label, field)}
              value={field.label}
            >
              <div
                style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: colors.grey_03,
                  fontWeight: _.isEqual(filterOptions[label], field.value) ? '600' : undefined,
                  display: 'flex',
                  margin: '0 0px',
                  cursor: 'pointer',
                }}
              >
                {field.label}
              </div>
            </MenuContainer>
          );
        })}
      </GeneralTextField>
    </>
  );
};
