import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../styles';
import _ from 'lodash';
import FilterMenu from './FilterMenu';
import arrowRight from '../assets/icons/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: '#fff',
    height: '50px',
    position: 'relative',
    padding: '0px 0px 0px 0px',
    'z-index': 2,
    boxShadow: colors.main_box_shadow,
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  innerContainer: {
    padding: 0,
    width: '95%',
    display: 'flex',
    margin: '0 0 0 30px',
    justifyContent: 'space-between',
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'start',
    overflowX: 'auto',
  },
  adjustContainer: {
    width: '98%',
    display: 'flex',
    margin: '0px 40px 0px 0px',
    padding: '8px 0px 0px 0px',
    overflowX: 'auto',
  },
  rightContentContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  countStyle: {
    display: 'flex',
    fontWeight: 500,
    fontSize: 14,
    color: '#404B50',
    margin: '0px 14px 0 0',
  },
  numberStyle: {
    color: '#00CFAA',
    marginRight: 4,
  },
  dropdownMenu: {
    '& .MuiPaper-root': {
      background: colors.white_01,
      boxShadow: colors.main_box_shadow,
    },
  },
  buttonStyle: {
    color: 'white',
    fontWeight: 400,
    display: 'flex',
    padding: '20px 20px 0',
    background: '#242B2F',
    height: 61,
    alignItems: 'center',
    margin: '0px auto',
    cursor: 'pointer',
    //lineHeight: 1,
    fontSize: 14,
    border: 0,
    '&:hover': {
      opacity: 0.7,
    },
    '&:disabled': {
      color: 'grey',
      background: 'white',
      borderColor: 'grey',
    },
  },
  filterLabel: {
    ...typography.mainFont,
    fontWeight: 600,
    fontSize: 14,
    whiteSpace: 'nowrap',
    lineHeight: '30px',
    margin: '13px 0px 0px 0px',
    paddingRight: 5,
    color: colors.grey_03,
  },
  buttonStyleInverted: {
    color: '#242B2F',
    fontWeight: 400,
    display: 'flex',
    padding: '20px 20px 0',
    alignItems: 'center',
    background: '#fafafa',
    height: 61,
    margin: '0px auto',
    cursor: 'pointer',
    borderLeft: '0.5px solid #e3e5e4',
    lineHeight: 1,
    fontSize: 14,
    border: 0,
    '&:hover': {
      border: 0,
      background: '#404B50',
      color: 'white',
    },
    '&:disabled': {
      color: 'grey',
      background: 'white',
      borderColor: 'grey',
    },
  },
  arrowRightStyle: {
    height: 16,
    width: 16,
    // margin: '8px 10px 0',
    position: 'absolute',
    top: 18,
    right: 50,
  },
}));

interface Props {
  label?: string;
  featureSelections: any;
  setFeatureSelections: any;
  filterOptions: any;
  setFilterOptions: any;
  clientCount?: number;
  options?: any;
  selectedProspects?: any;
  adjustParams: any;
  rightButtons?: any;
}

const FilterHeader = ({
  label,
  filterOptions,
  setFilterOptions,
  clientCount,
  selectedProspects = 0,
  adjustParams,
  rightButtons,
}: Props) => {
  const classes = useStyles();

  const updateFilters = (key: any, value: any) => {
    setFilterOptions({ ...filterOptions, [key]: value });
  };

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.selectContainer}>
          <h1 className={classes.filterLabel}>{label ? label : 'Filter by'}</h1>
          <div className={classes.adjustContainer}>
            {adjustParams.map(
              (
                param: { label: string; options: any; width: string | undefined; component: any; style: any },
                index: React.Key | null | undefined,
              ) => {
                return param.component ? (
                  <div style={param.style}>{param.component}</div>
                ) : (
                  param.options.length > 1 && (
                    <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
                      <FilterMenu
                        id={`filter-menu-${param.label}`}
                        label={param.label}
                        filterOptions={param.options}
                        selectedFilter={filterOptions[param.label]}
                        updateFilters={updateFilters}
                        widthInput={param.width}
                      />
                    </div>
                  )
                );
              },
            )}
          </div>
          {adjustParams.length > 7 && <img src={arrowRight} alt="arrow" className={classes.arrowRightStyle} />}
        </div>
        {((clientCount && clientCount > 0) || selectedProspects > 0 || rightButtons) && (
          <div className={classes.rightContentContainer}>
            {clientCount && clientCount > 0 ? (
              <div className={classes.countStyle}>
                <p className={classes.numberStyle}>{clientCount}</p>
                <p>{clientCount > 0 ? 'Connected Clients' : 'Connected Client'}</p>
              </div>
            ) : null}
            {clientCount && selectedProspects > 0 ? (
              <div className={classes.countStyle}>
                <p className={classes.numberStyle}>{selectedProspects}</p>
                <p>{clientCount > 0 ? 'Advisor' : 'Advisors'}</p>
              </div>
            ) : null}
            {rightButtons &&
              rightButtons.map((button: any, index: number) => {
                return (
                  <button
                    key={index}
                    children={button.text}
                    onClick={() => button.action()}
                    className={button.type === 'bold' ? classes.buttonStyle : classes.buttonStyleInverted}
                    style={{ padding: '0px 20px' }}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterHeader;
