import React, { useState } from 'react';
import { colors } from '../styles';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { H2, F3, ModalContainer, ModalRoot, ModalHeader, ModalFooter, PrimaryButtonShort } from '.';
import Loader from './Loader';

export const MainTextInput = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
  },
});

export const ModalBody = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '0px 32px',
});

interface Props {
  modalStatus: boolean;
  confirmCampaign: any;
  copy: string;
  emailSender: string;
  closeModal: any;
  error: any;
}

const CampaignConfirmationModal = ({ modalStatus, closeModal, emailSender, copy, confirmCampaign }: Props) => {
  const [emailSent, setEmailSent] = useState<any>(false);
  // const [loading, setLoading] = useState(false);

  const exit = () => {
    setEmailSent(false);
    closeModal();
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer sx={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '16px 32px' }}>
          <H2>Schedule Confirmation</H2>
        </ModalHeader>
        <ModalBody style={{ width: '100%' }}>
          {emailSender ? (
            <div style={{ margin: '10px 0px 0px' }}>
              <H2>You are about to send an email to your target audience.</H2>
              <F3 sx={{ color: colors.grey_02 }} dangerouslySetInnerHTML={{ __html: copy }}></F3>
            </div>
          ) : (
            <div style={{}}>
              <H2>You are currently in demo mode.</H2>
              <F3 sx={{ color: colors.grey_02 }}>Emails from your campaign will not be delivered.</F3>
            </div>
          )}
        </ModalBody>
        <ModalFooter style={{ width: '100%', padding: '10px 32px 8px' }}>
          <PrimaryButtonShort
            onClick={exit}
            style={{ background: colors.grey_02, border: `1px solid ${colors.grey_02}`, margin: 'auto 4px auto 0px' }}
          >
            Cancel
          </PrimaryButtonShort>
          <PrimaryButtonShort
            onClick={confirmCampaign}
            style={{ margin: emailSent ? 'auto 0px' : 'auto 0px auto 4px' }}
          >
            {false ? <Loader color={colors.white} size={'10px'} hideText /> : 'Confirm'}
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default CampaignConfirmationModal;
