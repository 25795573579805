import { Action, Thunk, action, thunk } from 'easy-peasy';

export interface DiscoverIntentModel {
  bubbleChartData: any;

  setBubbleChartData: Action<DiscoverIntentModel, any>;
}

export const discoverIntent: DiscoverIntentModel = {
  bubbleChartData: { 8: null, 24: null, 72: null },

  setBubbleChartData: action((state, payload) => {
    state.bubbleChartData = payload;
  }),
};
