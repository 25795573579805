import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3 } from '..';
import { colors } from '../../styles';
import hubspotLogo from '../../assets/logos/hubspot-logo.svg';
import salesforceLogo from '../../assets/logos/salesforce-logo.svg';
import dynamicsLogo from '../../assets/logos/dynamics-logo.png';

const CRMLogos = styled('img')({
  cursor: 'pointer',
  padding: '0px',
  borderRadius: '5px',
  margin: '0px -6px',
  //   background: white;
  ':hover': {
    opacity: 0.7,
  },
});

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '60px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  setModalPage: any;
};

const CRMSelection = ({ setModalPage }: any) => {
  const pageCopy = {
    header: 'Available Partners',
    subheader: 'Select your CRM to automatically populate your advisor data',
  };
  return (
    <>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02 }}>{pageCopy.subheader}</F3>
      </TextContainer>
      <Row>
        <CRMLogos src={salesforceLogo} onClick={() => setModalPage('Salesforce')} alt="salesforce" />
        <CRMLogos src={hubspotLogo} onClick={() => setModalPage('HubspotNewIntegration')} alt="hubspot" />
        <CRMLogos
          style={{
            height: '80px',
            width: '140px',
            padding: '0px 16px 0px',
            background: colors.white,
            objectFit: 'contain',
            margin: '25px 18px 0px',
            boxShadow: colors.main_box_shadow,
          }}
          src={dynamicsLogo}
          onClick={() => setModalPage('Dynamics')}
          alt="dynamics"
        />
      </Row>
    </>
  );
};

export default CRMSelection;
