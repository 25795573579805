import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import { colors } from '../../../styles';
import { TablePresets } from './tableSettings';
import ComingSoonModal from '../../../components/ComingSoonModal';
import LoadingModal from '../../../components/LoadingModal';
import { SectionPageHeader } from '../../../components/SectionPageHeader';
import EngagementModal from '../../../components/EngagementModal';
import { randomNumber } from '../../../helpers/numberGenerator';
import { ScoreImpactView } from './ScoreImpactView';
import { DetailedCampaignView } from './DetailedCampaignView';
import { GET_CAMPAIGNS } from '../../../api/nurtureProspects';
import { styled } from '@mui/material/styles';
import { useStoreState } from '../../../store';
import { useQuery } from '@apollo/client';
import { GET_ARCHIVES } from '../../../api/driveSales';

const Container = styled('div')({
  width: '100%',
  margin: '0px auto 0px auto',
  overflow: 'hidden',
  paddingBottom: 120,
});

const Header = styled('div')({
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});

export const Feedback = () => {
  const navigate = useNavigate();
  const [resultsPage, setResultsPage] = useState('influence');
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState<any>(null);
  const [focusedCampaignEngagement, setFocusedCampaignEngagement] = useState(null);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  return (
    <>
      <LoadingModal
        modalStatus={loadingCSV}
        closeModal={() => setLoadingCSV(false)}
        headerText={'Downloading all results'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <Container>
        <Header>
          <SectionPageHeader
            title={'Drive Sales'}
            buttonText={'Zoom Out'}
            buttonAction={() => navigate('/outcomes')}
            subtitle={'Outcomes for'}
            description={
              'View the impact of your activities on your clients’ outcomes. Use this information to make better decisions about how to engage with your clients.'
            }
          />
          <PageHeader
            selectedButton={resultsPage}
            leftButtons={[
              {
                text: 'Influence',
                key: 'influence',
                onPress: () => {
                  setResultsPage('influence');
                  setSelectedCampaign(null);
                },
              },
              {
                text: 'Discover Leads',
                key: 'discoverLeads',
                onPress: () => {
                  setResultsPage('discoverLeads');
                  setSelectedCampaign(null);
                },
              },
              {
                text: 'Nurture',
                key: 'nurture',
                onPress: () => {
                  setResultsPage('nurture');
                  setSelectedCampaign(null);
                },
              },
            ]}
            stats={[]}
          />
        </Header>
        <div>
          {selectedCampaign ? (
            <DetailedCampaignView campaign={selectedCampaign} setSelectedCampaign={setSelectedCampaign} />
          ) : (
            <ScoreImpactView
              setSelectedCampaign={setSelectedCampaign}
              resultsPage={resultsPage}
              setResultsPage={setResultsPage}
            />
          )}
        </div>
      </Container>
      <EngagementModal campaign={focusedCampaignEngagement} setCampaign={setFocusedCampaignEngagement} />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </>
  );
};
