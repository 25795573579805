import React from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../styles';
import checkbox_true from '../assets/misc/checkbox_true.svg';
import checkbox_false from '../assets/misc/checkbox_false.svg';

const Icon = styled('img')``;

interface Props {
  checked: boolean;
  onClick?: any;
  size?: string;
  style?: any;
  id?: string;
  disabled?: boolean;
}

const CheckboxButton = styled(IconButton)({
  height: '18px',
  width: '18px',
  ':disabled': {
    opacity: 0.2,
  },
});

const Checkbox = ({ checked, onClick, size, style, id, disabled }: Props) => {
  return (
    <CheckboxButton
      id={id}
      onClick={onClick}
      disabled={disabled}
      sx={
        style
          ? {
              ...style,
            }
          : { height: '18px', width: '18px' }
      }
    >
      <Icon
        sx={size ? { height: size, width: size } : {}}
        src={checked ? checkbox_true : checkbox_false}
        alt="checkBox"
      />
    </CheckboxButton>
  );
};

export default Checkbox;
