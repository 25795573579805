import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../styles';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import { H2 } from '.';
import UserProfile from './UserProfile/UserProfile';
import closeIcon from '../assets/icons/close.svg';
import ComingSoonModal from './ComingSoonModal';
import { styled } from '@mui/material/styles';
// import faceImage2 from '../../images/profile_img_2.png';
// import faceImage1 from '../../images/profile_img_1.png';
// import faceImage3 from '../../images/profile_img_3.png';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
  },

  circleImg: {
    margin: '0px 8px 0 0',
  },

  buttonContainer: {
    display: 'flex',
    height: '60px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderTop: `1px solid ${colors.grey_01}`,
  },
}));

const Root = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  // alignItems: 'center',
  margin: 'auto',
});

const HeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '50px',
  padding: 'auto 20px auto 0px',
  marginRight: '10px',
  borderBottom: `1px solid ${colors.grey_01}`,
});

const Medium24Text = styled(H2)({
  fontWeight: 500,
  fontSize: 16,
  color: '#404B50',
});

const LeadCards = styled('div')({
  margin: '30px 0px 0px',
  display: 'flex',
  background: colors.white,
  maxHeight: '760px',
  flexDirection: 'column',
  padding: '0px 0px',
  color: 'black',
  borderRadius: '5px',
  textAlign: 'center',
  boxShadow: colors.main_box_shadow,
});

const CloseButton = styled(IconButton)({
  marginRight: '10px',
});

interface Props {
  client: any;
  close: any;
  getRadarChartData: any;
  radarFeatures: any;
  textData: any;
  contentType: any;
  runId?: number;
  width?: any;
  expandedModal: any;
  packageName: any;
  fundChart?: any;
  lifeStage?: any;
  showNurture?: any;
  isDemoData?: boolean;
  cardProps?: any;
}

export const ModalCard = ({
  client,
  close,
  getRadarChartData,
  radarFeatures,
  textData,
  contentType,
  runId,
  expandedModal,
  packageName,
  fundChart,
  showNurture,
  isDemoData,
  cardProps,
}: Props) => {
  const [showComingSoon, setShowComingSoon] = useState(false);

  return (
    <Root open={expandedModal} onClose={close} aria-labelledby="simple-modal-title">
      <LeadCards style={{ height: packageName === 'individualLeads' ? 560 : 760 }}>
        <HeaderContainer>
          <Medium24Text style={{ margin: '0 10px 0 20px', padding: '20px 0px', textAlign: 'left' }}>
            {textData?.expandedHeading || 'Advisor Details'}
          </Medium24Text>
          <CloseButton onClick={close} id={'close-client-card-modal'}>
            <img src={closeIcon} alt="close" />
          </CloseButton>
        </HeaderContainer>
        <UserProfile
          runId={runId}
          client={{ ...client }}
          getRadarChartData={getRadarChartData}
          radarFeatures={radarFeatures}
          textData={textData}
          contentType={contentType}
          packageName={packageName}
          fundChart={fundChart}
          showNurtureScore={showNurture}
          isDemoData={isDemoData}
          cardProps={cardProps}
        />
        <ComingSoonModal modalStatus={showComingSoon} closeModal={() => setShowComingSoon(false)} />
      </LeadCards>
    </Root>
  );
};

export default ModalCard;
