import React, { useEffect } from 'react';
import FieldMapper from '../FieldMapper/FieldMapper';
import { Column, H2, F3, MainTextInput, Row, PrimaryButtonShort } from '..';
import { colors } from '../../styles';
import moment from 'moment';
import { GET_MARKETO_ACTIVITIES } from '../../api/integrations';
import { useQuery } from '@apollo/client';

interface Props {
  setModalPage: any;
  pullMarketoData: any;
  marketoIntegrationId: any;
}

const defaultFieldMapping = {
  EMAIL_CLICK: { srcKey: '', dstKey: 'EMAIL_CLICK' },
  EMAIL_OPEN: { srcKey: '', dstKey: 'EMAIL_OPEN' },
  EMAIL_DELIVERY: { srcKey: '', dstKey: 'EMAIL_DELIVERY' },
};

function ConfigureMarketoPull({ setModalPage, pullMarketoData, marketoIntegrationId }: Props) {
  const [fieldMapping, setFieldMapping] = React.useState(defaultFieldMapping);
  const [fileHeaders, setFileHeaders] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));

  const { data, loading, error } = useQuery(GET_MARKETO_ACTIVITIES, {
    variables: { integrationId: marketoIntegrationId },
    onCompleted: (data) => {
      const activityTypes = data?.marketo?.activityTypes?.result;
      setFileHeaders(activityTypes);
    },
  });

  const formatValidFieldName = (value: string) => {
    switch (value) {
      case 'EMAIL_CLICK':
        return 'Email Clicks';
      case 'EMAIL_OPEN':
        return 'Email Opens';
      case 'EMAIL_DELIVERY':
        return 'Email Deliveries';
      default:
        return '-';
    }
  };

  const createMarketoPull = () => {
    const formattedFieldMapping = Object.values(fieldMapping).map((field) => {
      //@ts-ignore
      return { eventType: field.dstKey, marketoId: field?.srcKey?.id };
    });

    pullMarketoData(marketoIntegrationId, startDate, formattedFieldMapping);
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  // console.log('fieldMapping: ', fieldMapping);

  return (
    <Column style={{ alignItems: 'center' }}>
      <H2 style={{ width: '100%', textAlign: 'center' }}>Configure Marketo Pull</H2>
      <F3 style={{ color: colors.grey_02, width: '100%', textAlign: 'center', margin: '20px 0px 20px' }}>
        Please select a start date for your data pull, and select the event ids which correspond with the events below.
      </F3>
      <MainTextInput
        type={'date'}
        style={{ width: '260px' }}
        label="Start Date"
        focused
        value={startDate}
        size="small"
        onChange={(event: any) => {
          setStartDate(event?.target?.value);
        }}
      />
      <FieldMapper
        previewTable={previewTable}
        fieldMapping={fieldMapping}
        setFieldMapping={setFieldMapping}
        fileHeaders={fileHeaders}
        formatValidFieldName={formatValidFieldName}
      />
      <Row
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${colors.grey_01}`,
          padding: '16px 0px 0px',
        }}
      >
        <PrimaryButtonShort onClick={createMarketoPull}>Create Integration</PrimaryButtonShort>
      </Row>
    </Column>
  );
}

export default ConfigureMarketoPull;
