import { formatPercent, formatPackageName } from '../../../helpers/dataFormatter';
import moment from 'moment';

export const influence = {
  headerData: [
    {
      header: 'Journey Name',
      key: 'customName',
      sortKey: 'customName',
      formatData: (row: any) => {
        return row.customName;
      },
      bold: true,
    },
    {
      header: 'Date Created',
      key: 'dateCreated',
      sortKey: 'date_created',
      formatData: (row: any) => {
        return moment(row.dateCreated).format('MM/DD/YYYY - HH:MM A');
      },
    },
    {
      header: 'Advisor Count',
      sortKey: '',
      formatData: (row: any) => {
        return row?.runhistorySet?.[0]?.serveCount ? row?.runhistorySet?.[0]?.serveCount : 0;
      },
    },
    {
      header: 'Status',
      sortKey: '',
      formatData: (row: any) => (row?.runhistorySet?.[0]?.isActive ? 'Active' : 'Inactive'),
    },
  ],
};

export const discoverLeads = {
  headerData: [
    {
      header: 'Campaign Name',
      key: 'name',
      sortKey: 'name',
      formatData: (row: any) => {
        return row.name;
      },
      bold: true,
    },
    {
      header: 'Date Created',
      key: 'dateCreated',
      sortKey: 'date_created',
      formatData: (row: any) => {
        return moment(row.dateCreated).format('MM/DD/YYYY - HH:MM A');
      },
    },
    {
      header: 'Target Audience',
      formatData: (row: any) => formatPackageName(row?.run?.package),
    },
    {
      header: 'Open Rate',
      sortKey: 'open_rate',
      formatData: (row: any) => formatPercent(row.openRate * 100),
    },
    {
      header: 'CTR',
      sortKey: 'click_through_rate',
      formatData: (row: any) => {
        // console.log('in format email count: ', row?.nurturecampaignSet?.[0].nurtureemailSet);
        return formatPercent(row.clickThroughRate * 100);
      },
    },
  ],
};

export const nurture = {
  headerData: [
    {
      header: 'Campaign Name',
      key: 'name',
      sortKey: 'name',
      formatData: (row: any) => {
        return row.name;
      },
      bold: true,
    },
    {
      header: 'Date Created',
      key: 'dateCreated',
      sortKey: 'date_created',
      formatData: (row: any) => {
        return moment(row.dateCreated).format('MM/DD/YYYY - HH:MM A');
      },
    },
    {
      header: 'Target Audience',
      formatData: (row: any) => formatPackageName(row?.run?.package),
    },
    {
      header: 'Open Rate',
      sortKey: 'open_rate',
      formatData: (row: any) => formatPercent(row.openRate * 100),
    },
    {
      header: 'CTR',
      sortKey: 'click_through_rate',
      formatData: (row: any) => {
        // console.log('in format email count: ', row?.nurturecampaignSet?.[0].nurtureemailSet);
        return formatPercent(row.clickThroughRate * 100);
      },
    },
  ],
};

export const TablePresets = {
  influence,
  discoverLeads,
  nurture,
};
