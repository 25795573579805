import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import salesforceLogoPNG from '../../../assets/logos/Salesforce-Logo.png';
import hubspotLogoPNG from '../../../assets/logos/Hubspot-Logo.png';
import dynamicsLogo from '../../../assets/logos/dynamics-logo.png';
import csvLogoPNG from '../../../assets/logos/CSV-Logo.png';
import sendgridLogo from '../../../assets/logos/Sendgrid-Logo.png';
import marketoLogo from '../../../assets/logos/marketo-logo.svg';

// @ts-ignore
// import UploadModal from '../../components/UploadModal';
import UploadModal from '../../../components/UploadModal';
import { Row, H3, H4, CardChip } from '../../../components';
import { useLocation } from 'react-router-dom';
import GeneralTable from '../../../components/Table';
import { DELETE_ADVISORS, GET_ADVISORS, GET_ADVISOR_SETS } from '../../../api/dataAPI';
import { GET_SET_MEMBERS } from '../../../api/integrations';
import {
  formatMillionDollar,
  formatNumber,
  formatEnrichedData,
  formatDollar,
  formatLeadPoolFilters,
  formatTimestamp,
  formatPercent,
} from '../../../helpers/dataFormatter';
import ComingSoonModal from '../../../components/ComingSoonModal';
import { colors } from '../../../styles';
//@ts-ignore
import TrashIcon from '../../../assets/icons/trash-icon.svg';
//@ts-ignore
import FileDownloadIcon from '../../../assets/icons/file-download.svg';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { MultiSelect } from '../../../components/NewFilterMenu/MultiSelect';
import { exportClientsToSpreadsheet } from '../../../helpers/exportClients';
import { lplFilterOptions, advisorFilterOptions } from '../../../components/SelectAdvisors/AdvisorFilters';
import { useStoreState } from '../../../store';
import PageHeader from '../../../components/PageHeader';
import ProgressBar from '../../../components/ProgressBar';
import LoadingModal from '../../../components/LoadingModal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { AdvisorChat } from './AdvisorChat';

type Props = {
  uploadModalPage?: any;
  setUploadModalPage?: any;
  uploadType?: any;
  currentPage?: any;
};

const useURLParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: table;
`);

const MainPageHeader = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 100%;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
`);

const LeftContainer = styled('div')(`
  display: table;
  width: 400px;
  margin: 0px 20px 0 0;
`);

const Body = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;  
  color: #758790;
  width: 100%;
  margin-bottom: 6px;
`);

const Button = styled('button')(`
  background: #242B2F;
  border-radius: 5px;
  color: #fff;
  margin: 16px 0 0;
  cursor: pointer;
  border: 1px solid #242B2F;
  padding: 6px 12px;
  transition: 0.5s;
  :hover {
    opacity: 0.7
  }
`);

const TableContainer = styled('div')(`
  font-family: 'Inter';
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin: 20px auto;
  min-height: 120px;
`);

const distillDataFallbackToUploaded = (data: any, key: string) => {
  let distillData = data?.advisordistilldata;
  if (distillData) {
    return distillData[key];
  } else if (data) {
    return data[key];
  }
  return null;
};

const SpreadSheetHeaders = [
  {
    header: 'first_name',
    key: 'firstName',
  },
  {
    header: 'last_name',
    key: 'lastName',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'career_tenure',
    key: 'careerTenure',
    format: (data: any) => {
      return formatNumber(distillDataFallbackToUploaded(data, 'careerTenure'));
    },
  },
  {
    header: 'rep_crd',
    key: 'repcrd',
  },
  {
    header: 'aum_self_reported',
    key: 'aumSelfReported',
    format: (data: any) => {
      return formatNumber(distillDataFallbackToUploaded(data, 'aumSelfReported'));
    },
  },

  {
    header: 'firm_name',
    key: 'firmName',
    format: (data: any) => {
      return distillDataFallbackToUploaded(data, 'firmName');
    },
  },
  {
    header: 'firm_crd',
    key: 'firmCrd',
    format: (data: any) => {
      return formatNumber(distillDataFallbackToUploaded(data, 'firmCrd'));
    },
  },
  {
    header: 'firm_total_accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(distillDataFallbackToUploaded(data, 'firmTotalAccounts'));
    },
  },
  {
    header: 'firm_total_assets_in_millions',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(distillDataFallbackToUploaded(data, 'firmTotalAssetsInMillions')),
  },
  {
    header: 'firm_etf_assets',
    format: (data: any) => formatMillionDollar(data?.firmEtfAssetsMillions),
  },
  {
    header: 'firm_mf_assets',
    format: (data: any) => formatMillionDollar(data?.firmMfAssetsMillions),
  },
  {
    header: 'office_state',
    format: (data: any) =>
      distillDataFallbackToUploaded(data, 'officeState') ? distillDataFallbackToUploaded(data, 'officeState') : '-',
  },
  {
    header: 'office_zipcode',
    format: (data: any) =>
      distillDataFallbackToUploaded(data, 'officeZipcode') ? distillDataFallbackToUploaded(data, 'officeZipcode') : '-',
  },
];

const FilterContainer = styled('div')({
  display: 'flex',
  margin: '10px 30px',
});

export const Advisors = (props: Props) => {
  const [uploadModalPage, setUploadModalPage] = useState<any>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [pageSize, setPageSize] = useState(50);
  const [sortKey, setSortKey] = useState('first_name');
  const [dataTotal, setDataTotal] = useState(0);
  const [tablePage, setTablePage] = useState(0);
  const [selectedItem, setSelectedItem] = useState<any>(false);
  const [showEditDataModal, setShowEditDataModal] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [selections, setSelections] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [page, setPage] = useState('advisorSets');
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [advisorFilters, setAdvisorFilters] = useState<any>({});

  const [sfIntegrationId, setSfIntegrationId] = useState<string | null>(null);
  const [sfIntegrationDetails, setSfIntegrationDetails] = useState<any | null>(null);

  const appType = useStoreState((state) => state.appType);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  // const navigate = useNavigate();
  const urlParams = useURLParams();

  // console.log('params from url: ', query);

  useEffect(() => {
    const newQueryParams = { id: urlParams.get('id'), success: urlParams.get('success'), type: urlParams.get('type') };
    setQueryParams(newQueryParams);
  }, []);

  const toggleEditDataModal = (selectedItem: any) => {
    //In the future we will probably need to add the user as a state variable
    setShowEditDataModal(!showEditDataModal);
    if (selectedItem) {
      setSelectedItem(selectedItem);
    } else {
      setSelectedItem(null);
    }
  };

  const logoMapping: any = {
    MARKETO: marketoLogo,
    DYNAMICS: dynamicsLogo,
    HUBSPOT: hubspotLogoPNG,
    CSV: csvLogoPNG,
    SENDGRID: sendgridLogo,
    SALESFORCE: salesforceLogoPNG,
  };

  const headerData = [
    {
      key: 'checkbox',
      type: 'checkbox',
      sortKey: '',
    },
    {
      header: ' ',
      key: 'new',
      sortKey: '',
    },

    {
      header: 'Name',
      key: 'lastName',
      sortKey: 'last_name',
      // formatData: (data: any) =>
      //   data?.firstName && data?.lastName ? data?.firstName + ' ' + data?.lastName : 'Name not found',
    },

    {
      header: 'AUM',
      key: 'aumSelfReported',
      sortKey: 'aum_self_reported',
      //formatData: (data) => '-',
      formatData: (data: any) => formatDollar(data.advisordistilldata.aumSelfReported),
    },
    {
      header: 'Accounts',
      key: 'firmTotalAccounts',
      sortKey: 'firm_total_accounts',
      formatData: (data: any) =>
        data?.firmTotalAccounts
          ? formatNumber(data.firmTotalAccounts)
          : data?.advisordistilldata?.firmTotalAccounts
          ? formatNumber(data.advisordistilldata.firmTotalAccounts)
          : '-',
    },
    {
      header: 'Type',
      key: 'type',
      sortKey: 'is_ria',
      //formatData: (data) => '-',
      formatData: (data: any) => data.advisordistilldata.advisorType,
    },
    {
      header: 'Firm AUM',
      //formatData: (data) => '-',
      key: 'firmTotalAssetsInMillions',
      sortKey: 'firm_total_assets_in_millions',
      formatData: (data: any) =>
        data?.firmTotalAssetsInMillions
          ? formatMillionDollar(data?.firmTotalAssetsInMillions)
          : data?.advisordistilldata?.firmTotalAssetsInMillions
          ? formatMillionDollar(data?.advisordistilldata?.firmTotalAssetsInMillions)
          : '-',
    },
    {
      header: 'Enriched Data',
      key: 'enrichedData',
      sortKey: 'repcrd',
      formatData: (data: any) => formatEnrichedData(data?.advisordistilldata),
    },
  ];

  const crmHeaderData = [
    {
      header: 'Source',
      key: 'crm',
      formatData: (data: any) => {
        return (
          <img
            style={{
              height: '60px',
              width: '78px',
              objectFit: 'contain',
              margin: '0px 18px 0px 0px',
              padding: '0px 0px 0px',
              boxShadow: '',
              lineHeight: '60px',
            }}
            src={logoMapping?.[data?.integration?.type]}
            onClick={() => setUploadModalPage('Logo Mapping')}
            alt={data?.integration?.type}
          />
        );
      },
    },
    {
      header: 'Name',
      key: 'lastName',
      sortKey: 'lastName',
      formatData: (data: any) => data?.name,
    },
    {
      header: 'Date Added',
      key: 'added',
      sortKey: 'dateCreated',
      formatData: (data: any) => formatTimestamp(data?.dateCreated),
    },
    {
      header: 'Modified',
      key: 'modified',
      sortKey: 'dateUpdated',
      formatData: (data: any) => formatTimestamp(data?.dateUpdated),
    },
    {
      header: '# of Members',
      key: 'contacts',
      sortKey: 'memberCount',
      formatData: (data: any) => formatNumber(data?.memberCount),
    },
    {
      header: '',
      key: 'progress',
      type: 'nonsortable',
      formatData: (data: any, hover: boolean) => {
        // console.log('data in download set: ', data);
        return (
          <Row style={{ width: '200px' }}>
            {data?.advisorsetimportjobSet?.[0]?.status === 'failed' ? (
              <CardChip style={{ backgroundColor: colors.black, height: '26px' }}>
                <H4 style={{ color: colors.white, margin: '0px 0px', padding: '0px 0px' }}>Import Failed </H4>
              </CardChip>
            ) : data?.advisorsetimportjobSet?.[0]?.progress * 100 < 100 ? (
              <ProgressBar
                percent={data?.advisorsetimportjobSet?.[0]?.progress * 100}
                label={`${formatPercent(data?.advisorsetimportjobSet?.[0]?.progress * 100)} Import Progress`}
                labelPosition={'left'}
                roundCorners
              />
            ) : (
              <H3
                sx={{ minWidth: '140px' }}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadAdvisorSet(data?.id);
                }}
              >
                {hover && data?.leads?.length !== 0 ? 'Download Set' : <div style={{ width: '120px' }} />}
              </H3>
            )}
          </Row>
        );
      },
    },
  ];

  const updateMultipleFilters = (value: any, label: string, newValue: any) => {
    if (value.length < 1) {
      setAdvisorFilters({ ...advisorFilters, [label]: ['Any'] });
    } else {
      if (value[0] === 'Any' && value.length > 1) {
        let notAny = value.splice(1, value.length);
        setAdvisorFilters({ ...advisorFilters, [label]: { label: notAny, value: newValue } });
      } else if (value.includes('Any')) {
        let current = advisorFilters;
        delete current[label];
        setAdvisorFilters({ ...current });
      } else {
        setAdvisorFilters({ ...advisorFilters, [label]: { label: value, value: newValue } });
      }
    }
  };

  const {
    loading: advisorLoading,
    data: advisorData,
    error: advisorError,
    refetch: refetchAdvisors,
  } = useQuery(GET_ADVISORS, {
    variables: {
      page: tablePage + 1,
      pageSize,
      sortKey,
      query: searchString,
      filters: formatLeadPoolFilters(advisorFilters),
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setDataTotal(data?.myAdvisors?.totalItems ? data?.myAdvisors?.totalItems : 0);
    },
  });

  const {
    loading: advisorSetLoading,
    data: advisorSetData,
    error: advisorSetError,
    refetch: refetchSets,
  } = useQuery(GET_ADVISOR_SETS, {
    fetchPolicy: 'network-only',
  });

  const [DeleteAdvisors, { loading: deleteLoading, data: deleteData, error }] = useMutation(DELETE_ADVISORS, {});

  const [getAdvisorSetMembers, { loading: loadingDownloadModal }] = useLazyQuery(GET_SET_MEMBERS, {
    onCompleted: (data) => {
      const formattedAdvisorSet =
        data?.advisorSet?.advisorsetmemberSet?.map((person: any) => ({ ...person?.advisor })) ?? [];

      // console.log('formattedAdvisorSet: ', formattedAdvisorSet);
      exportClientsToSpreadsheet(formattedAdvisorSet, SpreadSheetHeaders, {}, 'tifin-amp-advisors');
      setShowDownloadModal(false);
    },
  });

  const downloadAdvisorSet = async (id: string) => {
    try {
      setShowDownloadModal(true);
      console.log('id in downloadAdvisorSet: ', id);
      getAdvisorSetMembers({ variables: { id: Number(id) } });
    } catch (error) {
      setShowDownloadModal(false);
      console.warn(error);
      throw error;
    }
  };

  const filters = appType === 'LPL' ? lplFilterOptions([]) : advisorFilterOptions([]);

  return (
    <Container>
      <MainPageHeader>
        Advisors
        {/* <Button sx={{ margin: '-10px 0 0' }} children={'Add Advisors'} onClick={() => setUploadModalPage('Landing')} /> */}
      </MainPageHeader>
      <div style={{ display: 'flex' }}>
        <LeftContainer>
          <Body>Here you can manage your advisor sets and your individual advisors. You can also</Body>
          <Button children={'Create New Advisor Set'} onClick={() => setUploadModalPage('Landing')} />
          <AdvisorChat />
        </LeftContainer>
      </div>
      <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, margin: '32px 0px 0px' }} />
      <PageHeader
        selectedButton={page}
        leftButtons={[
          {
            text: 'Advisor Sets',
            key: 'advisorSets',
            onPress: () => {
              setPage('advisorSets');
            },
          },
          {
            text: 'All Advisors',
            key: 'allAdvisors',
            onPress: () => {
              setPage('allAdvisors');
            },
          },
        ]}
        hideBottomBorder
        stats={[]}
      />
      {page === 'advisorSets' ? (
        <TableContainer>
          <GeneralTable
            headerData={crmHeaderData}
            search={true}
            hideShadow
            data={advisorSetData?.advisorSets}
            dataControls={{
              selections: selections,
              setSelections: setSelections,
              searchFunc: setSearchString,
              searchString,
            }}
            initSortColumn={'-dateCreated'}
            onRowPress={(row: any) => {
              console.log('Open advisor set', row);
            }}
            newItems={[]}
            loading={advisorSetLoading}
            getData={refetchSets}
            rowsInput={pageSize}
            rowsInputOption={[5, 10, 20, 50]}
          />
        </TableContainer>
      ) : (
        <TableContainer>
          <FilterContainer>
            {filters.map((param: any, index: React.Key | null | undefined) => {
              return param.component ? (
                <div style={param.style}>{param.component}</div>
              ) : (
                param.options.length > 1 && (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '0px 0px' }}>
                    <MultiSelect
                      {...param}
                      id={`filter-menu-${param.label}`}
                      label={param.label}
                      selectedFilter={advisorFilters[param.label]}
                      updateMultipleFilters={updateMultipleFilters}
                      MenuProps={{}}
                      allFilters={advisorFilters}
                    />
                  </div>
                )
              );
            })}
          </FilterContainer>
          <GeneralTable
            headerData={headerData}
            search={true}
            hideShadow
            data={advisorData?.myAdvisors?.list}
            dataControls={{
              selections: selections,
              setSelections: setSelections,
              searchFunc: setSearchString,
              searchString,
            }}
            paginationControls={{
              tablePage,
              setTablePage,
              pageSize,
              setPageSize,
              sortKey,
              setSortKey,
              totalItems: dataTotal,
            }}
            onRowPress={toggleEditDataModal}
            newItems={[]}
            loading={advisorLoading}
            getData={refetchAdvisors}
            rowsInput={pageSize}
            rowsInputOption={[50, 100, 200, 500]}
            headerFunctions={[
              {
                name: 'deleteAdvisors',
                icon: TrashIcon,
                style: {},
                action: () => setShowDeleteConfirmation(true),
                actionText: 'Delete',
              },
              {
                name: 'downloadAdvisors',
                icon: FileDownloadIcon,
                style: { height: '24px', width: '24px', margin: '0px 0px 0px 0px' },
                action: () => {
                  exportClientsToSpreadsheet(Object.values(selections), SpreadSheetHeaders, {}, 'tifin-amp-advisors');
                },
                actionText: 'Download',
              },
            ]}
          />
        </TableContainer>
      )}
      <ConfirmationModal
        action={async () => {
          try {
            setDeleteProcessing(true);
            const listOfAdvisors = Object.keys(selections);
            DeleteAdvisors({ variables: { advisorIds: listOfAdvisors } });
            setDeleteProcessing(false);
            refetchAdvisors();
            refetchSets();
            setShowDeleteConfirmation(false);
            setSelections({});
            return deleteData;
          } catch (error) {
            setDeleteProcessing(false);
            console.warn('error in delete header functions: ', error);
          }
        }}
        modalStatus={showDeleteConfirmation}
        copy={{
          title: 'Delete Selected Advisors',
          body: `Are you sure you want to permanetly delete the (${
            Object.keys(selections).length
          }) selected advisors? Once it's done its done.`,
        }}
        loading={deleteProcessing}
        closeModal={() => setShowDeleteConfirmation(false)}
      />
      <UploadModal
        modalPage={uploadModalPage}
        setModalPage={setUploadModalPage}
        integrationId={sfIntegrationId}
        setIntegrationId={setSfIntegrationId}
        integrationDetails={sfIntegrationDetails}
        setIntegrationDetails={setSfIntegrationDetails}
        urlQuery={queryParams}
        closeModal={() => setUploadModalPage(null)}
      />
      <LoadingModal
        modalStatus={showDownloadModal}
        closeModal={() => setShowDownloadModal(false)}
        headerText={'Downloading Advisor Set'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Container>
  );
};
