import React, { useState, useEffect } from 'react';
import { colors } from '../../styles';
import { H2, H3, F3, PrimaryButtonShort, MainTextInput, Row, Column, CardContainer } from '..';
import { styled } from '@mui/system';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, MenuItem } from '@mui/material';
import { formatCamelCase } from '../../helpers/dataFormatter';
import link_active from '../../assets/icons/link_active.svg';
import link_inactive from '../../assets/icons/link_inactive.svg';
import { cloneDeep, set } from 'lodash';
import { useQuery, useLazyQuery } from '@apollo/client';

import { GET_HS_FIELDS, GET_SF_FIELDS } from '../../api/integrations';

import SwitchFilter from '../SwitchFilter';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  //   height: '320px',
  margin: '0px auto 0px',
  alignItems: 'center',
  flexDirection: 'column',
});

const FieldTableHeader = styled(TableRow)({
  borderBottom: `1px solid ${colors.grey_01}`,
});

type Props = {
  setModalPage: any;
  updateIntegrationConfig: any;
  //   setInfoPageCopy: any;
  integrationId: any;
  integrationDetails: any;
  acceptedFields: any;
  type: string;
  //   uploadDataset: any;
  //   advisorSetFilter: any;
  //   datasetTypes: any;
  //   uploadFields?: any;
  //   uploadName?: string;
};

const SF = 'SALESFORCE';
const HS = 'HUBSPOT';

const CrmIntegrationConfig = ({
  setModalPage,
  updateIntegrationConfig,
  acceptedFields,
  integrationId,
  integrationDetails,
  type,
}: Props) => {
  const [errors, setErrors] = useState<any>(null);
  const [table, setTable] = useState<string>('influence');

  const [integrationName, setIntegrationName] = useState<any>('');

  const [influenceObjectType, setInfluenceObjectType] = useState('Lead');
  const [influenceFieldMapping, setInfluenceFieldMapping] = useState<any>([]);
  const [influenceCreateCampaign, setInfluenceCreateCampaign] = useState<boolean>(false);

  const [fileHeaders, setFileHeaders] = useState<any>({ Lead: [], Contact: [] });

  const [getHubspotFields] = useLazyQuery(GET_HS_FIELDS, {
    variables: { integrationId },
    onCompleted: (data) => {
      const newFileHeaders = {
        Contact: data?.data?.hubspot?.contactProperties,
        Lead: data?.data?.hubspot?.contactProperties,
      };
      setFileHeaders(newFileHeaders);
    },
  });

  const [getSfFields] = useLazyQuery(GET_SF_FIELDS, {
    variables: { integrationId },
    onCompleted: (data) => {
      const newFileHeaders = data?.sf;
      setFileHeaders(newFileHeaders);
    },
  });

  useEffect(() => {
    try {
      const newInfluenceFieldMapping: any = defaultMappings();
      setInfluenceFieldMapping(newInfluenceFieldMapping);

      const sfIntegrationDetails = integrationDetails?.sfintegrationauthdetailsSet?.[0];
      if (sfIntegrationDetails) {
        const formattedInfluenceObject = formatCamelCase(sfIntegrationDetails?.advisorLeadObjectType, true);
        setInfluenceObjectType(formattedInfluenceObject);

        const formattedInfluenceFieldMapping = formatFieldMappings(
          sfIntegrationDetails?.advisorLeadPropertiesMapping,
          sfIntegrationDetails?.advisorLeadRepCrdSfField,
        );

        setInfluenceFieldMapping(formattedInfluenceFieldMapping);

        setInfluenceCreateCampaign(sfIntegrationDetails?.advisorLeadShouldExportCampaign);
      }

      const hubspotIntegrationDetails = integrationDetails?.hubspotintegrationdetailsSet?.[0];

      if (hubspotIntegrationDetails) {
        // const formattedInfluenceObject = formatCamelCase(hubspotIntegrationDetails?.ampSignalObjectName, true);
        // setInfluenceObjectType(formattedInfluenceObject);

        const newInfluenceFieldMapping = hubspotIntegrationDetails?.contactPropertiesMapping;

        const formattedInfluenceFieldMapping = formatFieldMappings(
          newInfluenceFieldMapping,
          hubspotIntegrationDetails?.repCrdHubspotField,
        );
        setInfluenceFieldMapping(formattedInfluenceFieldMapping);

        setInfluenceCreateCampaign(hubspotIntegrationDetails?.advisorLeadShouldExportCampaign);
      }
      setIntegrationName(integrationDetails?.nickname);
      getFileHeaders();
    } catch (error: any) {
      console.warn('error formatting property mappings: ', error);
    }
  }, [integrationId]);

  const formatFieldMappings = (fieldMapping: any, repKey?: string) => {
    const newFormattedMappings: any = defaultMappings();
    if (fieldMapping) {
      const parsedMapping = JSON.parse(fieldMapping);
      for (let field of parsedMapping) {
        newFormattedMappings[field?.dst_key] = { srcKey: field?.src_key, dstKey: field?.dst_key };
      }
    }
    if (repKey) {
      newFormattedMappings['read_repcrd'] = { srcKey: repKey, dstKey: 'read_repcrd' };
    }
    return newFormattedMappings;
  };

  const defaultMappings = () => {
    const newFieldMapping: any = {};
    for (let field of acceptedFields) {
      newFieldMapping[field] = { srcKey: null, dstKey: field };
    }
    return newFieldMapping;
  };

  const pageCopy = {
    header: 'Integration Configuration',
    subheader: 'What data would you like to sync with AMP?',
    body: (
      <span>
        Match the headers that you would like to export to your CRM. <i>* Indicates Required Fields</i>
      </span>
    ),
  };

  const formatValidFieldName = (value: string) => {
    switch (value) {
      case 'firstName':
        return 'First Name';
      case 'age':
        return 'Advisor Age';
      case 'phone_number':
        return 'Phone Number';
      case 'aum_self_reported':
        return 'Assets Under Management';
      case 'career_tenure':
        return 'Career Tenure';
      case 'current_firm_tenure_years':
        return 'Current Firm Tenure';
      case 'email':
        return 'Advisor Email*';
      case 'firm_crd':
        return 'Firm CRD';
      case 'firm_etf_assets_millions':
        return 'Firm ETF Assets in Millions';
      case 'firm_mf_assets_millions':
        return 'Firm MF Assets in Millions';
      case 'firm_name':
        return 'Firm Name';
      case 'firm_number_bd_reps':
        return 'Firm Number BD Reps';
      case 'firm_number_ia_reps':
        return 'Firm Number IA Reps';
      case 'firm_aum':
        return 'Firm AUM';
      case 'firm_total_accounts':
        return 'Firm Total Accounts';
      case 'firm_percent_assets_etf':
        return 'Firm Percent Assets ETF';
      case 'firm_mf_assets':
        return 'Firm MF Assets';
      case 'firm_etf_assets':
        return 'Firm ETF Assets';
      case 'firm_percent_assets_mf':
        return 'Firm Percent Assets MF';
      case 'firm_total_assets_in_millions':
        return 'Firm Total Assets in Millions';
      case 'first_name':
        return 'First Name';
      case 'full_name':
        return 'Full Name';
      case 'is_bd':
        return 'BD Advisor';
      case 'is_ria':
        return 'RIA Advisor';
      case 'last_name':
        return 'Last Name';
      case 'office_state':
        return 'Office State';
      case 'office_zipcode':
        return 'Office Postal Code';
      case 'percent_assets_in_etf_and_mf':
        return 'Percent assets in ETFs and MFs';
      case 'read_repcrd':
        return 'Upsert CRD Key*';
      case 'rep_type':
        return 'Advisor Type';
      case 'write_repcrd':
        return 'Rep CRD';
      case 'ria_employee_ratio':
        return 'RIA Employee Ratio';
      case 'tickers_held':
        return 'Tickers Held';
      case 'themes':
        return 'Themes';
      case 'title':
        return 'Advisor Title';
      case 'amp_score':
        return 'AMP Score';
      case 'amp_score_ticker':
        return 'AMP Score Ticker';
      default:
        return '-';
    }
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  const getFileHeaders = async () => {
    try {
      if (integrationId) {
        let newFileHeaders = {};
        if (type === SF) {
          getSfFields({ variables: { integrationId } });
        } else {
          getHubspotFields({ variables: { integrationId } });
        }
      }
    } catch (e) {
      console.warn('Error getting preview data: ', e);
    }
  };

  return (
    <Column
      style={{
        flex: 1,
        margin: '0px 0px 0px',
        justifyContent: 'space-between',
        // overflowY: 'scroll',
      }}
    >
      <TextContainer>
        <H2 style={{ color: colors.black, margin: '0px 0px 16px' }}>{pageCopy.header}</H2>
        <Row>
          <MainTextInput
            label="Integration Name"
            size="small"
            sx={{ margin: '12px 0px 0px 0px', width: '300px' }}
            value={integrationName}
            focused
            onChange={(event) => setIntegrationName(event.target.value)}
          />
          {integrationDetails?.sfintegrationauthdetailsSet?.[0]?.instanceUrl && (
            <MainTextInput
              label="Integration URL"
              size="small"
              sx={{ margin: '12px 0px 0px 16px', width: '300px' }}
              value={integrationDetails?.sfintegrationauthdetailsSet?.[0]?.instanceUrl}
              focused
              disabled
              onChange={(event) => setIntegrationName(event.target.value)}
            />
          )}
        </Row>
        <F3 style={{ color: colors.grey_02, width: '660px', margin: '16px 0px' }}>{pageCopy.body}</F3>
      </TextContainer>
      <CardContainer style={{ width: '680px', margin: '0px 0px 20px', borderRadius: '5px 5px 0px 0px' }}>
        {type === SF && (
          <>
            <Row>
              <Column style={{ margin: '0px 20px 40px' }}>
                <H3 style={{ margin: '10px 10px 10px 0px' }}>Export to CRM model: </H3>
                <MainTextInput
                  // label="Data Type"
                  size="small"
                  style={{ margin: '-8px 0px 0px 0px', width: '300px', height: '12px' }}
                  value={influenceObjectType}
                  /*className={classes.fieldOverride}*/
                  select
                  focused
                  //@ts-ignore
                  onChange={(event) => {
                    setInfluenceObjectType(event.target.value);
                  }}
                >
                  <MenuItem
                    key={0}
                    value={'Lead'}
                    //sx={{ overflowX: 'auto' }}
                    sx={{
                      fontFamily: 'Inter',
                    }}
                  >
                    <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>Lead</F3>
                  </MenuItem>
                  <MenuItem
                    key={1}
                    value={'Contact'}
                    //sx={{ overflowX: 'auto' }}
                    sx={{
                      fontFamily: 'Inter',
                    }}
                  >
                    <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>Contact</F3>
                  </MenuItem>
                </MainTextInput>
              </Column>
              <Column style={{ margin: '20px 0px' }}>
                <SwitchFilter
                  value={influenceCreateCampaign}
                  setValue={setInfluenceCreateCampaign}
                  copy={'Create a Campaign with each export'}
                />
              </Column>
            </Row>
          </>
        )}
        <TableContainer style={{ width: '660px', margin: '0px 20px', height: '370px' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <FieldTableHeader>
                {previewTable.header.map((header, index) => {
                  return (
                    <TableCell
                      sx={{
                        fontSize: 14,
                        color: '#758790',
                      }}
                      key={index}
                    >
                      {header?.label}
                    </TableCell>
                  );
                })}
              </FieldTableHeader>
            </TableHead>
            <TableBody>
              {Object.values(influenceFieldMapping).map((row: any, index: number) => {
                return (
                  <TableRow style={{ height: '60px' }}>
                    {previewTable.header.map((header: any, index) => {
                      if (header?.key === 'srcKey') {
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              fontSize: 14,
                              color: '#758790',
                            }}
                          >
                            <MainTextInput
                              // label="Data Type"
                              size="small"
                              style={{ margin: '-8px 0px 0px 0px', width: '300px', height: '12px' }}
                              value={row?.srcKey ? row?.srcKey : 0}
                              /*className={classes.fieldOverride}*/
                              select
                              focused
                              //@ts-ignore
                              onChange={(event) => {
                                const newFieldMapping = cloneDeep(influenceFieldMapping);
                                newFieldMapping[row.dstKey] = { srcKey: event.target.value, dstKey: row.dstKey };
                                if (table === 'influence') {
                                  setInfluenceFieldMapping(newFieldMapping);
                                }
                              }}
                            >
                              <MenuItem
                                key={index}
                                value={0}
                                //sx={{ overflowX: 'auto' }}
                                sx={{
                                  fontFamily: 'Inter',
                                }}
                              >
                                <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{'-'} </F3>
                              </MenuItem>
                              {fileHeaders?.[influenceObjectType]?.map((item: any, index: number) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={item?.name ? item?.name : item ? item : 0}
                                    //sx={{ overflowX: 'auto' }}
                                    sx={{
                                      fontFamily: 'Inter',
                                    }}
                                  >
                                    <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>
                                      {item?.name ? item?.name : item}
                                    </F3>
                                  </MenuItem>
                                );
                              })}
                            </MainTextInput>
                          </TableCell>
                        );
                      }
                      if (header?.key === 'status') {
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              //   border: '0.5px solid #C2CBCF',
                              fontSize: 14,
                              color: '#758790',
                            }}
                          >
                            <img
                              style={{ height: '14px', width: '14px', margin: '4px 12px 0px' }}
                              src={!!row.srcKey ? link_active : link_inactive}
                              alt="link"
                            />
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            //   border: '0.5px solid #C2CBCF',
                            fontSize: 14,
                            color: '#758790',
                          }}
                        >
                          {row?.[header?.key] ? formatValidFieldName(row?.[header?.key]) : '-'}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContainer>
      {errors && <F3 style={{ margin: '16px 20px', color: colors.red }}>{errors[0]}</F3>}
      <Row
        style={{
          height: '50px',
          margin: '0px -26px 0px -26px',
          padding: '16px 16px 0px',
          // width: '110%',
          justifyContent: 'flex-end',
          alignItems: 'center',
          borderTop: `1px solid ${colors.grey_01}`,
        }}
      >
        <PrimaryButtonShort
          style={{ width: '160px' }}
          onClick={() =>
            updateIntegrationConfig(
              Object.values(influenceFieldMapping).filter((item: any) => item?.srcKey),
              influenceObjectType,
              influenceCreateCampaign,
              integrationName,
            )
          }
        >
          {'Update Integration'}
        </PrimaryButtonShort>
      </Row>
    </Column>
  );
};

export default CrmIntegrationConfig;
