import React, { useState, useMemo } from 'react';
import { colors } from '../../styles';
import { Row, Column, H2, F3 } from '..';
import { formatDate, formatDollar, formatNumber, formatPercent } from '../../helpers/dataFormatter';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  FilterFn,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table';
import SimpleTable from '../SimpleTable';

import { rankItem } from '@tanstack/match-sorter-utils';

type Props = { data: any };

type HoldingType = {
  ticker: string;
  positionSize: number;
  percentOfHoldings: number;
  lastTwelveMonthFlows: number;
  lastFlowDate: string;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const Holdings = ({ data }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const columns = useMemo<ColumnDef<HoldingType>[]>(
    () => [
      {
        // cell: (info: any) => info.getValue(),
        header: () => 'Position Start*',
        accessorKey: 'lastFlowDate',
        cell: (info: any) => {
          let formattedData = formatDate(info?.getValue(), true);
          if (formattedData === 'Invalid Date') {
            return formatDate(info?.getValue(), true);
          } else {
            return formattedData;
          }
        },
        footer: (props: any) => props?.column.id,
      },
      {
        accessorKey: 'ticker',
        header: () => 'Ticker',
        footer: (props: any) => props?.column.id,
      },
      {
        accessorKey: 'positionSize',
        header: () => 'Position Size',
        cell: (info: any) => {
          return formatDollar(info?.getValue());
        },
        footer: (props: any) => props?.column.id,
      },
      {
        accessorKey: 'lastTwelveMonthFlows',
        header: () => 'YTD Flows',
        cell: (info: any) => {
          return formatDollar(info?.getValue());
        },
        footer: (props: any) => props?.column.id,
      },
      {
        accessorKey: 'percentOfHoldings',
        header: () => '% of Holdings',
        cell: (info: any) => {
          // console.log('percent holdings: ', info?.getValue() * 100);
          return formatPercent(info?.getValue() * 100, 1);
        },
        footer: (props: any) => props?.column.id,
      },
    ],
    [],
  );

  const dataForTable: HoldingType[] = data?.holdings?.tableData?.datapoints ?? data?.tableData?.datapoints;

  // console.log('dataForTable: ', { data });

  const holdingsTable = useReactTable({
    columns,
    data: dataForTable ?? [],
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSorting: true,
    debugAll: true,
  });

  const dataAvailable =
    data?.tableData?.datapoints?.length > 0 ||
    data?.tableData?.length > 0 ||
    data?.holdings?.tableData?.datapoints?.length > 0;

  return !dataAvailable ? (
    <Column style={{ padding: '80px 0px', alignItems: 'center' }}>
      <H2>No Data Available</H2>
      <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>Please check back in future for updated data.</F3>
    </Column>
  ) : (
    <Column>
      <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '0px 0px 0px' }}>
        <SimpleTable
          tableData={holdingsTable}
          searchBar
          searchBarText={globalFilter}
          setSearchBarText={setGlobalFilter}
        />
      </Row>
      <Row>
        <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>
          *This is our best estimate given our current data set
        </F3>
      </Row>
    </Column>
  );
};
