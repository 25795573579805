import React from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { PackageNav } from '../PackageNav';

type Props = {
  title: string;
  basePath: string;
  algoType?: string;
  algoSettings?: any;
  setAlgoType?: any;
  PackageSteps: Array<any>;
  progress: number;
  setProgress?: any;
  progressTitles: Array<string>;
  setProgressTitles?: any;
  navActions?: any;
  navSteps?: any;
  packageRunName?: string | null;
  setPackageRunName?: any;
  dateRun?: string | null;
  runId?: number | null;
  journeyId?: number | null;
};

const PackageRoot = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const PackageContainer = ({
  title,
  navSteps,
  basePath,
  algoType,
  setAlgoType,
  algoSettings,
  setProgress,
  PackageSteps,
  progress,
  progressTitles,
  packageRunName,
  setPackageRunName,
  dateRun,
  runId,
  journeyId,
}: Props) => {
  return (
    <Routes>
      <Route
        element={
          <PackageNavigator
            title={title}
            basePath={basePath}
            algoType={algoType}
            setAlgoType={setAlgoType}
            algoSettings={algoSettings}
            PackageSteps={PackageSteps}
            progress={progress}
            progressTitles={progressTitles}
            setProgress={setProgress}
            navSteps={navSteps}
            packageRunName={packageRunName}
            setPackageRunName={setPackageRunName}
            dateRun={dateRun}
            runId={runId}
            journeyId={journeyId}
          />
        }
      >
        <Route path={''} element={<Navigate to={'home'} />} />
        {PackageSteps.map((Step, index) => {
          return <Route key={index + 21923401} path={Step.link} element={<Step.Component {...Step.props} />} />;
        })}
      </Route>
    </Routes>
  );
};

export const PackageNavigator = (props: Props) => {
  const location = useLocation();

  return (
    <PackageRoot>
      {!location.pathname.includes('home') && <PackageNav {...props} />}
      <Outlet />
    </PackageRoot>
  );
};
