import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '../../components/TextField';
import navLogo from '../../assets/logos/nav-logo.png';
// import distillLogoPng from '../../assets/logos/TifinAmp_Logo.png';
import Button from '@mui/material/Button';
import { PrimaryButtonShort, Row } from '../../components';
import { SET_FIRM_NAME, SET_FIRM_LOGO } from '../../api/dataAPI';
import { useStoreState, useStoreActions } from '../../store';
import { colors } from '../../styles';
import { LOAD_PROFILE } from '../../api/auth';
import { useMutation, useQuery } from '@apollo/client';

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

type Props = {};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: flex;
`);

const PageTitle = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 200px;
  margin-right: 40px;
`);

const LeftContainer = styled('div')(`
  display: table;
  width: 600px;
  margin: 0 20px 0 12px;
`);

const RightContainer = styled('div')(`
  display: table;
  margin: 22px 0;
`);

const FormTitle = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #242B2F;
`);

const FirmLogo = styled('img')(`
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  padding: 30px 40px;
  width: 300px;
`);

const Body = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;  
  color: #758790;
  width: 80%;
  margin-bottom: 6px;
`);

export const FirmDetails = (props: Props) => {
  const [selectedImg, setSelectedImg] = useState<any>({ preview: '', raw: '' });
  const [firmName, setFirmName] = useState<string>('');

  const organizationLogo = useStoreState((state: any) => state.user.organization?.logoFile);
  // const organizationName = useStoreState((state: any) => state.user.organization?.name);

  const setOrganization = useStoreActions((state: any) => state.user.setOrganization);

  const {
    loading: loadingOrg,
    error: errorOrg,
    data: profileData,
    refetch: refetchProfile,
  } = useQuery(LOAD_PROFILE, {
    onCompleted: (data) => {
      setOrganization(data?.meAssetManager?.organization);
      setFirmName(data?.meAssetManager?.organization?.name ? data?.meAssetManager?.organization?.name : '');
    },
  });

  const handleChange = async (e: any) => {
    let unformattedBase64: any = await toBase64(e.target.files[0]);
    const base64Image = unformattedBase64.split(',').pop();
    if (e.target.files.length) {
      setSelectedImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: base64Image,
      });
    }
  };

  const [setFirmLogo, { loading: firmLogoLoading, error: firmLogoError }] = useMutation(SET_FIRM_LOGO, {
    variables: { data: selectedImg.raw },
    onCompleted: (data) => {
      refetchProfile();
      setSelectedImg({ preview: '', raw: '' });
    },
  });

  return (
    <Container>
      <PageTitle>Firm Details</PageTitle>
      <div style={{ display: 'table' }}>
        <TextField inputText={firmName} setInputText={setFirmName} titleText={'Firm Name'} disabled={true} />
        {/* <PrimaryButtonShort onClick={() => updateFirmName()}>Update Firm Name</PrimaryButtonShort> */}
        <div style={{ display: 'flex', margin: '0px 0px 0px -8px' }}>
          <LeftContainer>
            <FormTitle>Firm Logo</FormTitle>
            <FirmLogo
              src={selectedImg?.preview ? selectedImg.preview : organizationLogo ? organizationLogo : navLogo}
              alt="logo"
            />
          </LeftContainer>
          <RightContainer>
            <Body>
              If you choose not to show your firms logo, then we will display the TIFIN Asset Manager Platform logo.
              However, your name will be shown in areas that can be personalized.
            </Body>
            {
              <Button
                sx={{
                  background: colors.grey_02,
                  borderRadius: '5px',
                  color: '#fff',
                  margin: 0,
                  fontFamily: 'Inter',
                  border: '1px solid #758790',
                  padding: '4px 12px',
                  fontSize: '12px',
                  boxShadow: 'None',
                  fontWeight: '400',
                  letterSpacing: '-0.5px',
                  textTransform: 'none',
                  '&:hover': {
                    opacity: 0.7,
                    background: '#758790',
                  },
                }}
                variant="contained"
                component="label"
                style={{ fontFamily: 'Inter' }}
              >
                Upload New Logo
                <input hidden accept="image/*,.pdf" type="file" onChange={handleChange} />
              </Button>
            }
            {selectedImg?.raw !== '' && (
              <PrimaryButtonShort style={{ margin: '0px 0px 0px 8px' }} onClick={() => setFirmLogo()}>
                Save Changes
              </PrimaryButtonShort>
            )}
          </RightContainer>
        </div>
      </div>
    </Container>
  );
};
