import React from 'react';
import { H2, F3, PrimaryButtonShort, MainTextInput, ModalFooter, Row, Column } from '../';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, IconButton, MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import { cloneDeep, forEach } from 'lodash';
import { colors } from '../../styles';
import link_active from '../../assets/icons/link_active.svg';
import link_inactive from '../../assets/icons/link_inactive.svg';

const FieldTableHeader = styled(TableRow)({
  borderBottom: `1px solid ${colors.grey_01}`,
});

type Props = {
  previewTable: any;
  fieldMapping: any;
  setFieldMapping: any;
  fileHeaders: any;
  formatValidFieldName: any;
};

function FieldMapper({ previewTable, fieldMapping, setFieldMapping, fileHeaders, formatValidFieldName }: Props) {
  return (
    <TableContainer style={{ width: '600px', margin: '0px 20px', maxHeight: '400px' }}>
      <Table stickyHeader size="small">
        <TableHead>
          <FieldTableHeader>
            {previewTable.header.map((header: any, index: number) => {
              return (
                <TableCell
                  sx={{
                    fontSize: 14,
                    color: '#758790',
                  }}
                  key={index}
                >
                  {header?.label}
                </TableCell>
              );
            })}
          </FieldTableHeader>
        </TableHead>
        <TableBody>
          {Object.values(fieldMapping).map((row: any, index: number) => {
            // console.log('row header: ', row);
            return (
              <TableRow style={{ height: '60px' }}>
                {previewTable.header.map((header: any, index: number) => {
                  if (header?.key === 'srcKey') {
                    console.log('row.srcKey in map Object.values: ', row?.srcKey?.name);
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          fontSize: 14,
                          color: '#758790',
                        }}
                      >
                        <MainTextInput
                          size="small"
                          style={{ margin: '-8px 0px 0px 0px', width: '300px', height: '12px' }}
                          value={row?.srcKey?.name}
                          select
                          focused
                          //@ts-ignore
                          onChange={(event) => {
                            const newFieldMapping = cloneDeep(fieldMapping);
                            // console.log('event in field mapper: ', event.target.value);
                            newFieldMapping[row.dstKey] = {
                              //@ts-ignore
                              srcKey: event.target.value,
                              dstKey: row.dstKey,
                            };
                            setFieldMapping(newFieldMapping);
                          }}
                        >
                          <MenuItem
                            key={index}
                            value={0}
                            sx={{
                              fontFamily: 'Inter',
                            }}
                          >
                            <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{'-'} </F3>
                          </MenuItem>
                          {fileHeaders.map((item: any, index: number) => (
                            <MenuItem
                              key={index}
                              value={item}
                              //sx={{ overflowX: 'auto' }}
                              sx={{
                                fontFamily: 'Inter',
                              }}
                            >
                              <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>
                                {item?.name ? item?.name : item}
                              </F3>
                            </MenuItem>
                          ))}
                        </MainTextInput>
                      </TableCell>
                    );
                  }
                  if (header?.key === 'status') {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          //   border: '0.5px solid #C2CBCF',
                          fontSize: 14,
                          color: '#758790',
                        }}
                      >
                        <img
                          style={{ height: '14px', width: '14px', margin: '4px 12px 0px' }}
                          src={!!row.srcKey ? link_active : link_inactive}
                        />
                      </TableCell>
                    );
                  }
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        //   border: '0.5px solid #C2CBCF',
                        fontSize: 14,
                        color: '#758790',
                      }}
                    >
                      {row?.[header?.key] ? formatValidFieldName(row?.[header?.key]) : '-'}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FieldMapper;
