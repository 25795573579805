const emailHeaderLogo = 'https://distill-ui-images.s3.us-east-2.amazonaws.com/defaultEmailHeader.png';

export const defaultTemplateGenerator = (headerImage?: string, customBlock?: any) => {
  return {
    counters: {
      u_row: 5,
      u_column: 5,
      u_content_menu: 1,
      u_content_text: 5,
      u_content_image: 1,
      u_content_timer: 1,
      u_content_button: 1,
      u_content_heading: 3,
    },
    body: {
      id: '2ybsFZwfbL',
      rows: [
        {
          id: 'ofgQiwCAvj',
          cells: [1],
          columns: [
            {
              id: 'c1yX6oSdPn',
              contents: [
                {
                  id: 'UHVbbzVq-q',
                  type: 'image',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    src: {
                      url: headerImage ? headerImage : emailHeaderLogo,
                      width: 1920,
                      height: 423,
                    },
                    textAlign: 'center',
                    altText: '',
                    action: {
                      name: 'web',
                      values: {
                        href: '',
                        target: '_blank',
                      },
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: 'u_content_image_1',
                      htmlClassNames: 'u_content_image',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
                {
                  id: 'EtJoCs8Sx-',
                  type: 'heading',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    headingType: 'h1',
                    fontFamily: {
                      label: 'Arial',
                      value: 'arial,helvetica,sans-serif',
                    },
                    fontSize: '22px',
                    textAlign: 'left',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#242B2F',
                      linkHoverColor: '#242B2F',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: 'u_content_heading_3',
                      htmlClassNames: 'u_content_heading',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<div>\n<div>\n<div><strong>Hello %first_name%</strong></div>\n</div>\n</div>',
                  },
                },
                {
                  id: 'YwgO02bgzv',
                  type: 'text',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    textAlign: 'left',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#242B2F',
                      linkHoverColor: '#242B2F',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: 'u_content_text_3',
                      htmlClassNames: 'u_content_text',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="font-size: 14px; line-height: 140%;">Welcome to the TIFIN AMP Newsletter. </p>',
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: 'u_column_3',
                  htmlClassNames: 'u_column',
                },
                border: {},
                padding: '0px',
                borderRadius: '0px',
                backgroundColor: '#ffffff',
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            backgroundImage: {
              url: '',
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            padding: '0px',
            anchor: '',
            hideDesktop: false,
            _meta: {
              htmlID: 'u_row_3',
              htmlClassNames: 'u_row',
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        customBlock
          ? customBlock
          : {
              id: 'dpap9uZ9Ys',
              cells: [1],
              columns: [
                {
                  id: 'ntc587uB2S',
                  contents: [],
                  values: {
                    _meta: {
                      htmlID: 'u_column_5',
                      htmlClassNames: 'u_column',
                    },
                    border: {},
                    padding: '0px',
                    borderRadius: '0px',
                    backgroundColor: '',
                  },
                },
              ],
              values: {
                displayCondition: null,
                columns: false,
                backgroundColor: '',
                columnsBackgroundColor: '',
                backgroundImage: {
                  url: '',
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false,
                },
                padding: '0px',
                anchor: '',
                hideDesktop: false,
                _meta: {
                  htmlID: 'u_row_5',
                  htmlClassNames: 'u_row',
                },
                selectable: true,
                draggable: true,
                duplicatable: true,
                deletable: true,
                hideable: true,
              },
            },
        {
          id: 'FLToCTWykD',
          cells: [1],
          columns: [
            {
              id: '5aytEfjl7P',
              contents: [
                {
                  id: '3akT5dv1b6',
                  type: 'text',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    textAlign: 'left',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#242B2F',
                      linkHoverColor: '#242B2F',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: 'u_content_text_4',
                      htmlClassNames: 'u_content_text',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="font-size: 14px; line-height: 140%;">©2022-2023 TIFIN AMP</p>',
                  },
                },
                {
                  id: 'uUSP9z2xv2',
                  type: 'text',
                  values: {
                    containerPadding: '10px',
                    anchor: '',
                    textAlign: 'left',
                    lineHeight: '140%',
                    linkStyle: {
                      inherit: true,
                      linkColor: '#242B2F',
                      linkHoverColor: '#242B2F',
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _meta: {
                      htmlID: 'u_content_text_5',
                      htmlClassNames: 'u_content_text',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="font-size: 14px; line-height: 140%;">This message was sent to %email% and intended for %first_name%.<br />Not your account? <a rel="noopener" href="{$unsubscribe_link}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6InskdW5zdWJzY3JpYmVfbGlua30iLCJ0YXJnZXQiOiJfYmxhbmsifX0=">Remove your email</a> from this account.</p>',
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: 'u_column_4',
                  htmlClassNames: 'u_column',
                },
                border: {},
                padding: '0px',
                borderRadius: '0px',
                backgroundColor: '',
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            backgroundImage: {
              url: '',
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            padding: '0px',
            anchor: '',
            hideDesktop: false,
            _meta: {
              htmlID: 'u_row_4',
              htmlClassNames: 'u_row',
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      values: {
        popupPosition: 'center',
        popupWidth: '600px',
        popupHeight: 'auto',
        borderRadius: '10px',
        contentAlign: 'center',
        contentVerticalAlign: 'center',
        contentWidth: '500px',
        fontFamily: {
          label: 'Arial',
          value: 'arial,helvetica,sans-serif',
        },
        textColor: '#242B2F',
        popupBackgroundColor: '#FFFFFF',
        popupBackgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: true,
        },
        popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
        popupCloseButton_position: 'top-right',
        popupCloseButton_backgroundColor: '#DDDDDD',
        popupCloseButton_iconColor: '#242B2F',
        popupCloseButton_borderRadius: '0px',
        popupCloseButton_margin: '0px',
        popupCloseButton_action: {
          name: 'close_popup',
          attrs: {
            onClick: "document.querySelector('.u-popup-container').style.display = 'none';",
          },
        },
        backgroundColor: '#ffffff',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        preheaderText: '',
        linkStyle: {
          body: true,
          linkColor: '#242B2F',
          linkHoverColor: '#242B2F',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: {
          htmlID: 'u_body',
          htmlClassNames: 'u_body',
        },
      },
    },
    schemaVersion: 9,
  };
};

export const htmlTemplateGenerator = (html: string) => {
  return {
    counters: {
      u_row: 1,
      u_column: 1,
      u_content_menu: 1,
      u_content_text: 1,
      u_content_image: 1,
      u_content_timer: 1,
      u_content_button: 1,
      u_content_heading: 0,
      u_content_html: 1,
    },
    body: {
      id: '2ybsFZwfbL',
      rows: [
        {
          id: 'dpap9uZ9Ys',
          cells: [1],
          columns: [
            {
              id: 'ntc587uB2S',
              contents: [
                {
                  id: 'Q6SBsQVAH6',
                  type: 'html',
                  values: {
                    html: html,
                    displayCondition: null,
                    width: '600px',
                    containerPadding: '0px',
                    anchor: '',
                    _meta: { htmlID: 'u_content_html_1', htmlClassNames: 'u_content_html' },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            backgroundColor: '',
            columnsBackgroundColor: '',
            padding: '0px',
            anchor: '',
            hideDesktop: false,
            _meta: { htmlID: 'u_row_5', htmlClassNames: 'u_row' },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
      ],
      values: {
        popupPosition: 'center',
        popupWidth: '600px',
        popupHeight: 'auto',
        borderRadius: '10px',
        contentAlign: 'left',
        contentVerticalAlign: 'center',
        fontFamily: { label: 'Arial', value: 'arial,helvetica,sans-serif' },
        textColor: '#242B2F',
        popupBackgroundColor: '#FFFFFF',
        popupBackgroundImage: {
          url: '',
          fullWidth: true,
          repeat: 'no-repeat',
          size: 'cover',
          position: 'top-center',
          customPosition: ['0%', '0%'],
        },
        popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
        popupCloseButton_position: 'top-right',
        popupCloseButton_backgroundColor: '#DDDDDD',
        popupCloseButton_iconColor: '#242B2F',
        popupCloseButton_borderRadius: '0px',
        popupCloseButton_margin: '0px',
        popupCloseButton_action: {
          name: 'close_popup',
          attrs: { onClick: "document.querySelector('.u-popup-container').style.display = 'none';" },
        },
        backgroundColor: '#ffffff',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: 'no-repeat',
          size: 'custom',
          position: 'top-center',
          customPosition: ['0%', '0%'],
        },
        preheaderText: '',
        linkStyle: {
          body: true,
          linkColor: '#242B2F',
          linkHoverColor: '#242B2F',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        _meta: { htmlID: 'u_body', htmlClassNames: 'u_body' },
      },
    },
    schemaVersion: 15,
  };
};
