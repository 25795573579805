import { styled } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import AmpScoreView from './AmpScoreView';
import { colors } from '../../styles';
import { Column, PrimaryButton } from '..';
import { formatFullName } from '../../helpers/dataFormatter';
import SummaryView from './SummaryView';
import OpportunityView from './OpportunityView';
import TellMeMoreView from './TellMeMoreView';
import FeedbackView from './FeedbackView';

type Props = {
  id: string;
  key: number;
  client: any;
  algoType?: string;
  runId: string;
  contentType: string;
  packageName: string;
  showDetails?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const RootContainer = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '0px 18px',
});

const Divider = styled('div')({
  borderTop: '1px solid #e3e5e4',
  margin: '0px 0px',
});

function ClientCard({ id, client, showDetails, algoType, runId }: Props) {
  const cardRef = useRef<any>();

  const [scrollingDivWidth, setScrollingDivWidth] = React.useState(300);
  const [clientScore, setClientScore] = React.useState(0);
  const [clientScoreChange, setClientScoreChange] = React.useState(0);

  const [summaryData, setSummaryData] = React.useState([]);
  const [faqData, setFaqData] = React.useState([]);
  const [opportunityData, setOpportunityData] = React.useState([]);

  const formatAllClientData = () => {
    let newClientScore = client?.latestScore
      ? client?.latestScore
      : client?.leadScore
        ? client?.leadScore
        : client?.score;

    if (newClientScore > 0 && newClientScore < 1) {
      newClientScore = (newClientScore * 100).toFixed(0);
    }

    if (algoType === 'Redemption') {
      newClientScore = client?.redemptionScore;
    }

    const previousServedData = client?.previousServeHistory;

    const previousScore = previousServedData?.score;

    let newClientScoreChange = client?.latestScoreChange
      ? client?.latestScoreChange
      : client?.leadScoreChange
        ? client?.leadScoreChange
        : client?.scoreChange;

    if (previousScore && newClientScore) {
      newClientScoreChange = (newClientScore - previousScore).toFixed(0);
    }

    let summarySet = client?.influenceadvisorsummarySet ?? [];

    const newFaqData = summarySet
      ?.filter((data: any) => data?.type === 'faq')
      .sort((a: any, b: any) => a.orderNumber - b?.orderNumber);

    const newOppotunityData = summarySet
      ?.filter((data: any) => data?.type === 'opportunity')
      .sort((a: any, b: any) => a.orderNumber - b?.orderNumber);

    const newSummaryData = summarySet
      ?.filter((data: any) => data?.type === 'summary' || data?.type === 'shap' || data?.type === 'fund')
      .sort((a: any, b: any) => a.orderNumber - b?.orderNumber);

    setOpportunityData(newOppotunityData);
    setFaqData(newFaqData);
    setSummaryData(newSummaryData);

    setClientScore(newClientScore);
    setClientScoreChange(newClientScoreChange);
  };

  useEffect(() => {
    if (client) {
      formatAllClientData();
    }
  }, [client, algoType]);

  useEffect(() => {
    setScrollingDivWidth(cardRef?.current?.clientWidth - 66);
  }, [cardRef?.current?.clientWidth]);

  return (
    <RootContainer ref={cardRef} id={id} key={client?.id + 'clientcard'}>
      <div id={`${id}-amp-score`}>
        <AmpScoreView
          score={clientScore}
          scoreChange={clientScoreChange}
          scoreSubtext={client?.title}
          scoreName={formatFullName(client)}
          algoType={algoType}
        />
      </div>
      <Divider />
      <Column style={{ display: 'flex', overflowY: 'scroll', margin: '0px -20px 0px 0px', padding: '0px 6px 0px 0px' }}>
        <SummaryView summaryData={summaryData} width={scrollingDivWidth} />
        <Divider />
        <OpportunityView
          opportunityData={opportunityData}
          width={scrollingDivWidth + 14}
          client={client}
          algoType={algoType}
        />
        <Divider />
        <TellMeMoreView faqData={faqData} width={scrollingDivWidth} />
        {runId && <FeedbackView client={client} runId={runId} />}
        {showDetails && (
          <PrimaryButton
            style={{
              margin: '0px 0px 16px',
              backgroundColor: colors.white_00,
              color: colors.grey_03,
              border: `1px solid ${colors.grey_02}`,
            }}
            onClick={() => showDetails(client)}
          >
            View Advisor 360
          </PrimaryButton>
        )}
      </Column>
    </RootContainer>
  );
}

export default ClientCard;
