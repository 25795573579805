import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { HeaderChip } from '..';

import { SummaryHeader } from './SummaryHeader';
import { Persona } from './Persona';
import { Holdings } from './Holdings';
import { Transactions } from './Transactions';
import { Engagement } from './Engagement';
import { CompetitorData } from './CompetitorData';
import { useQuery } from '@apollo/client';
import { GET_ADVISOR_360 } from '../../api/dataAPI';
import ComponentSwitcher from './ComponentSwitcher';
import Loader from '../Loader';
import { formatFullName } from '../../helpers/dataFormatter';
import { saveJsonToFile } from '../../helpers/saveJSONFile';

const Container = styled('div')({
  width: '800px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '10px 18px',
});

type Props = {
  repCrd: string;
  mockData: any;
  client: any;
};

export const Advisor360 = ({ repCrd, mockData, client }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [advisorData, setAdvisorData] = useState<any>(mockData);
  const [dataAvailable, setDataAvailable] = useState<any>({
    holding: true,
    transactions: true,
    engagement: true,
    competitor: true,
  });

  const { loading, error } = useQuery(GET_ADVISOR_360, {
    variables: { repCrd },
    onCompleted: (data) => {
      if (data) {
        setAdvisorData(data?.advisor360);

        const holdingDataAvailable =
          data?.advisor360?.holdings?.holdings?.tableData?.datapoints?.length > 0 ||
          data?.advisor360?.holdings?.tableData?.datapoints?.length > 0;
        const transactionsDataAvailable = data?.advisor360?.transactions?.tableData?.datapoints?.length > 0;
        const engagementDataAvailable =
          data?.advisor360?.engagements?.tableData?.datapoints?.length > 0 ||
          data?.advisor360?.engagements?.tableData?.length > 0;
        const competitorDataAvailable =
          data?.advisor360?.competitorData?.sources?.[0]?.['platform']?.datapoints?.length > 0 ||
          data?.advisor360?.competitorData?.sources?.[0]?.['platform']?.length > 0 ||
          data?.advisor360?.competitorData?.sources?.[0]?.['product']?.datapoints?.length > 0 ||
          data?.advisor360?.competitorData?.sources?.[0]?.['product']?.length > 0;
        const dataAvailable = {
          holding: holdingDataAvailable,
          transactions: transactionsDataAvailable,
          engagements: engagementDataAvailable,
          competitor: competitorDataAvailable,
        };
        setDataAvailable(dataAvailable);
      }
    },
    onError: (error) => {
      console.warn('error in getAdvisor360: ', error);
    },
  });

  const advisorName = formatFullName(client);

  const ComponentList = [
    {
      id: crypto?.randomUUID(),
      key: 'persona',
      label: 'Advisor Persona',
      Component: (
        <Persona data={advisorData?.personas ?? advisorData?.persona} advisorName={advisorName} key={1284914134} />
      ),
    },
    dataAvailable?.holding && {
      id: crypto?.randomUUID(),
      key: 'holdings',
      label: 'Current Holdings',
      Component: <Holdings data={advisorData?.holdings} key={40930234} />,
    },
    dataAvailable?.transactions && {
      id: crypto?.randomUUID(),
      key: 'transactions',
      label: 'Recent Transactions',
      Component: <Transactions data={advisorData?.transactions} key={224309401} summaries={advisorData?.summaries} />,
    },
    dataAvailable?.engagements && {
      id: crypto?.randomUUID(),
      key: 'engagements',
      label: 'Engagement Activity',
      Component: <Engagement data={advisorData?.engagements} key={340293441} />,
    },
    dataAvailable?.competitor && {
      id: crypto?.randomUUID(),
      key: 'competitorData',
      label: 'Competitive Data',
      Component: <CompetitorData data={advisorData?.competitorData} key={2394203525} />,
    },
  ].filter((component) => !!component);

  const [selectedComponent, setSelectedComponent] = useState(ComponentList[0]?.key);

  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    );
  }

  return (
    <Container>
      <HeaderChip style={{ margin: '0px 0px 18px', padding: '0px 12px', justifyContent: 'space-between' }}>
        <span>360 SUMMARY</span>
        <span
          onClick={() => {
            setExpanded(!expanded);
          }}
          style={{ cursor: 'pointer' }}
        >
          {expanded ? 'EXPANDED' : 'CONDENSED'}
        </span>
      </HeaderChip>
      <SummaryHeader data={advisorData} dataAvailable={dataAvailable} />
      {loading ? (
        <Loader />
      ) : expanded ? (
        <>
          {ComponentList.map((component: any) => {
            return (
              <>
                <ComponentSwitcher
                  ComponentList={[component]}
                  selectedComponent={component?.key}
                  setSelectedComponent={() => null}
                />
                {component?.Component}
              </>
            );
          })}
        </>
      ) : (
        <>
          <ComponentSwitcher
            ComponentList={ComponentList}
            selectedComponent={selectedComponent}
            setSelectedComponent={setSelectedComponent}
          />
          {ComponentList.map((component) => {
            return component?.key === selectedComponent ? component?.Component : null;
          })}
        </>
      )}
    </Container>
  );
};
