import React, { useEffect } from 'react';
import FieldMapper from '../FieldMapper/FieldMapper';
import { Column, H2, F3, MainTextInput, Row, PrimaryButtonShort } from '..';
import { colors } from '../../styles';
import moment from 'moment';
// import { retrieveSendgridData, createSendgridWebhookAuto, createSendgridWebhookManual } from '../../api/integrations';
import { GET_SENDGRID_DATA, CREATE_SENDGRID_WEBHOOK, IMPORT_SENDGRID_CAMPAIGNS } from '../../api/integrations';
import { style } from 'd3';
import { Select } from '@mui/joy';
import { styled } from '@mui/system';
import { QrCodeScannerOutlined } from '@mui/icons-material';
import { useQuery, useMutation } from '@apollo/client';
import Loader from '../Loader';
import { set } from 'lodash';

interface Props {
  setModalPage: any;
  setInfoPageCopy: any;
  sendgridId: any;
}

const defaultFieldMapping = {
  EMAIL_CLICK: { srcKey: '', dstKey: 'EMAIL_CLICK' },
  EMAIL_OPEN: { srcKey: '', dstKey: 'EMAIL_OPEN' },
  EMAIL_DELIVERY: { srcKey: '', dstKey: 'EMAIL_DELIVERY' },
};

const SliderFocused = styled('div')({
  padding: '8px 16px',
  width: '100px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  background: colors.grey_03,
  color: colors.white,
  borderRadius: '5px',
});

const SliderUnfocused = styled(SliderFocused)({
  background: colors.white,
  color: colors.grey_03,
});

function SendgridConfig({ setModalPage, setInfoPageCopy, sendgridId }: Props) {
  // const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [publicKey, setPublicKey] = React.useState('');
  const [autoManagement, setAutoManagement] = React.useState(true);
  const [backendUrl, setBackendUrl] = React.useState<string | null>(
    'https://pvvxa7bkh0.execute-api.us-east-2.amazonaws.com/Prod/events/distillai-backend',
  );
  const [publicSigningKey, setPublicSigningKey] = React.useState<string | null>(null);
  const [webhookDetails, setWebhookDetails] = React.useState<any>(null);

  const { loading } = useQuery(GET_SENDGRID_DATA, {
    onCompleted: (data) => {
      const newWebhookDetails = data?.sendgrid?.connections?.list?.[0];
      setPublicSigningKey(newWebhookDetails?.publicSigningKey ?? '');
      setWebhookDetails(newWebhookDetails?.urlEndpoint ? newWebhookDetails : null);
    },
  });

  const [createSendgridWebhook] = useMutation(CREATE_SENDGRID_WEBHOOK, {
    variables: {
      integrationId: sendgridId,
      publicSigningKey: publicKey,
    },
    onCompleted: (data) => {
      if (data?.sendgridCreateWebhook?.success) {
        setInfoPageCopy({
          header: 'Configuration Updated Successfully',
          subheader: ``,
          loader: false,
        });
      } else {
        setInfoPageCopy({
          header: 'Error Updating Configuration',
          subheader: `An unexpected error occured while updating your email service provider. (${data?.sendgridCreateWebhook?.traceback})`,
          loader: false,
        });
      }
    },
    onError: (error) => {
      setInfoPageCopy({
        header: 'Error Updating Configuration',
        subheader: `An unexpected error occured while updating your email service provider. (${error?.message})`,
        loader: false,
      });
      console.warn('error updating sendgrid config: ', error);
    },
  });

  const updateSendgridConfig = async () => {
    setModalPage('InfoView');
    let response: any = null;
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  return (
    <Column
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flex: 1,
        // background: colors.grey_02,
      }}
    >
      {loading ? (
        <Loader />
      ) : webhookDetails ? (
        <>
          <Column> </Column>
          <Column style={{ alignItems: 'center' }}>
            <H2 style={{ color: colors.grey_03 }}>Integration Successfully Set up</H2>
            <F3 style={{ color: colors.grey_02 }}>
              Your connection is live and will automatically push and pull data.{' '}
            </F3>
          </Column>
          <Row
            style={{
              width: '100%',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${colors.grey_01}`,
              padding: '16px 0px 0px',
              background: colors.grey_01,
            }}
          >
            <PrimaryButtonShort onClick={() => setModalPage(null)}>Close</PrimaryButtonShort>
          </Row>
        </>
      ) : (
        <>
          <H2 style={{ width: '100%', textAlign: 'center' }}>Configure Sendgrid</H2>
          <F3 style={{ color: colors.grey_02, width: '80%', textAlign: 'center', margin: '20px 0px 20px' }}>
            Please set up a webhook below to begin recieving data from sendgrid. You can automatically create a webhook
            or manually configure it.
          </F3>

          <Column style={{ flex: 1, alignItems: 'center' }}>
            <Row
              style={{ width: '200px', border: `1px solid ${colors.grey_03}`, borderRadius: '5px' }}
              onClick={() => setAutoManagement(!autoManagement)}
            >
              {autoManagement ? <SliderFocused>Auto</SliderFocused> : <SliderUnfocused>Auto</SliderUnfocused>}
              {!autoManagement ? <SliderFocused>Manual</SliderFocused> : <SliderUnfocused>Manual</SliderUnfocused>}
            </Row>
            {autoManagement ? (
              <>
                <F3 style={{ color: colors.grey_02 }}>We will automatically push and pull data to sendgrid. </F3>
                <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <div
                    style={{
                      background: autoManagement ? colors.green : colors.red,
                      borderRadius: '5px',
                      height: '10px',
                      width: '10px',
                      margin: '0px 6px',
                    }}
                  ></div>
                  <F3>{autoManagement ? 'Auto Integration Available' : 'Integration Not Available'}</F3>
                </Row>
              </>
            ) : (
              <>
                <F3 style={{ color: colors.grey_02 }}>Manually manage your connection to sendgrid.</F3>
                <MainTextInput
                  label="Backend URL"
                  size="small"
                  focused
                  sx={{ width: '360px' }}
                  value={backendUrl}
                  onChange={(event) => setBackendUrl(event.target.value)}
                />
                <MainTextInput
                  label="Public Signing Key"
                  size="small"
                  focused
                  value={publicSigningKey}
                  sx={{ width: '360px' }}
                  onChange={(event) => setPublicSigningKey(event.target.value)}
                />
              </>
            )}
          </Column>
          {publicSigningKey && (
            <Column style={{ alignItems: 'center' }}>
              <F3 style={{ color: colors.grey_02 }}>
                Your connection is live and will automatically push and pull data.{' '}
              </F3>
            </Column>
          )}
          <Row
            style={{
              width: '100%',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${colors.grey_01}`,
              padding: '16px 0px 0px',
            }}
          >
            {publicSigningKey ? (
              <PrimaryButtonShort onClick={() => setModalPage(null)}>Done</PrimaryButtonShort>
            ) : (
              <PrimaryButtonShort onClick={updateSendgridConfig}>Create Webhook</PrimaryButtonShort>
            )}
          </Row>
        </>
      )}
    </Column>
  );
}

export default SendgridConfig;
