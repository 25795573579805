import React, { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { styled } from '@mui/system';
// import thumbs_down_active from '../images/thumbs-down-active.svg';
// import thumbs_down from '../images/thumbs-down.svg';
import thumbs_up_active from '../assets/icons/thumbs-up-active.svg';
import thumbs_up from '../assets/icons/thumbs-up.svg';
// import checkbox_false from '../images/checkbox_false.svg';

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '30px',
  justifyContent: 'space-between',
  margin: '0px 28px',
});

interface Props {
  saveRating: any;
  data: any;
  ratingType: any;
}

const RatingButton = ({ saveRating, data, ratingType }: Props) => {
  const [rating, setRating] = useState('UNKNOWN');

  useEffect(() => {
    setRating(data.rating);
  }, [data]);

  const processRating = async (newRating: any) => {
    let updateRating = newRating;
    if (rating === newRating) {
      updateRating = 'UNKNOWN';
    }
    setRating(updateRating);
    const response = await saveRating(data.id, updateRating, ratingType);
    return response;
  };

  return (
    <ButtonContainer>
      <IconButton
        sx={{ height: '18px', width: '18px' }}
        onClick={(event) => {
          event.stopPropagation();
          processRating('SUCCESS');
        }}
      >
        <img src={rating === 'SUCCESS' ? thumbs_up_active : thumbs_up} alt="checkBox" />
      </IconButton>
      {/* <IconButton className={classes.container} onClick={() => processRating('FAILURE')}>
        <img
          className={classes.selectBox}
          src={rating === 'FAILURE' ? thumbs_down_active : thumbs_down}
          alt="checkBox"
        />
      </IconButton> */}
    </ButtonContainer>
  );
};

export default RatingButton;
