/* eslint-disable no-loop-func */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { concat, fromPromise } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { oktaClient } from './auth';
import store from '../store';
// import { useNavigate } from 'react-router-dom';
import { createUploadLink } from 'apollo-upload-client';

const url = process.env.REACT_APP_BACKEND_URL + 'graphql/';

let isRefreshing = false;
let pendingRequests: any[] = [];

const httpLink = createUploadLink({
  uri: url,
});

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback.resolve());
  pendingRequests = [];
};

const checkLoggedOut = (message: string) => {
  // console.log('check loggout message', message);
  if (
    message &&
    (message.includes('Error decoding signature') ||
      message.includes('You do not have permission to perform this action') ||
      message.includes('Signature has expired'))
  ) {
    return true;
  }

  return false;
};

const USER_REFRESH = `
mutation refreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    payload
    refreshExpiresIn
    refreshToken
    token
  }
}`;

export const getNewToken = async () => {
  const refreshToken = window.localStorage.getItem('refreshToken');
  try {
    if (!!refreshToken) {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query: USER_REFRESH, variables: { refreshToken } }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data.data.refreshToken) {
          window.localStorage.setItem('token', data.data.refreshToken.token);
          window.localStorage.setItem('refreshToken', data.data.refreshToken.refreshToken);
          return data.data?.refreshToken?.token;
        } else {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refreshToken');
          window.location.href = '/login';
        }
      } else {
        window.location.href = '/login';
      }
    } else {
      throw Error('No token found in local storage.');
    }
  } catch (error) {
    console.log('error in getNewToken: ', error);
    throw error;
  }
};

const errorLink = onError(({ graphQLErrors, networkError, forward, operation }) => {
  if (graphQLErrors) {
    for (let error of graphQLErrors) {
      const { message, locations, path } = error;
      console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (checkLoggedOut(message)) {
        let forward$;
        if (!isRefreshing) {
          isRefreshing = true;
          forward$ = fromPromise(
            getNewToken()
              .catch((error) => {
                console.log('error refreshing token in error link: ', error);
                pendingRequests = [];
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('refreshToken');
                window.location.href = '/login';
                return;
              })
              .finally(() => {
                isRefreshing = false;
              }),
          )
            .filter((value) => Boolean(value))
            .flatMap((accessToken) => {
              const oldHeaders = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  authorization: `Bearer ${accessToken}`,
                },
              });
              return forward(operation);
            });
        } else {
          forward$ = fromPromise(
            // eslint-disable-next-line no-loop-func
            new Promise((resolve: any) => {
              pendingRequests.push(() => resolve());
            }),
          );
        }
        return forward$.flatMap(() => forward(operation));
      }
    }
  }

  if (networkError) console.warn(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { appType } = store.getState();
  let token: string | null | undefined = oktaClient.getAccessToken();
  if (!!token) {
    return {
      headers: {
        ...headers,
        ecosystem: appType,
        'okta-authorization': token ? `Bearer ${token}` : '',
      },
    };
  } else {
    token = window.localStorage.getItem('token');
    return {
      headers: {
        ...headers,
        ecosystem: appType,
        authorization: token ? `Bearer ${token}` : '',
        // authorization: token ? `Bearer ${token}` : '',
      },
    };
  }
});

export const apolloClient = new ApolloClient({
  link: concat(errorLink, authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});
