/**
 * General utilities for use throughout the package
 *
 * @packageDocumentation
 */
import stringify from 'stringify-object';
import { chain, filter, entries, map } from './inters';
import { GraphNode, Rank } from 'd3-dag';
import { setMultimapAdd, setMultimapDelete, setNext, setPop } from './collections';

/** utility type for replacing keys with new value */
export type U<O, K extends keyof O, V> = Omit<O, K> & Record<K, V>;

function getSetArray<T>(arr: Set<T>[], ind: number): Set<T> {
  const res = arr[ind];
  if (res === undefined) {
    const ret = new Set<T>();
    arr[ind] = ret;
    return ret;
  } else {
    return res;
  }
}

function getTail<T>(arr: Set<T>[]): Set<T> | undefined {
  let last;
  while (arr.length && !(last = arr[arr.length - 1])?.size) {
    arr.pop();
  }
  return last || undefined; // in case of empty set
}

/** a callback for things with children */
export interface ChildrenCallback<T> {
  (node: T): Iterable<T>;
}

class AugmentedNode<out N, out L> {
  constructor(
    readonly node: GraphNode<N, L>,
    public indeg: number,
    public outdeg: number,
    public rank: number | undefined,
    public stat: 'ranked' | 'active' | 'top' | 'bottom' | 'inactive' = 'inactive',
  ) {}

  bucket(): number {
    const diff = this.indeg === 0 ? -Infinity : this.outdeg === 0 ? Infinity : this.indeg - this.outdeg;
    return this.stat === 'top' ? Math.min(diff, 0) : this.stat === 'bottom' ? Math.max(diff, 0) : diff;
  }

  isTop(): boolean {
    return (this.indeg <= this.outdeg && this.stat !== 'bottom') || this.stat === 'top';
  }
}

export function altTopology<N, L>(
  nodes: Iterable<GraphNode<N, L>>,
  ranker: Rank<N, L> = () => undefined,
): GraphNode<N, L>[] {
  // initial setup
  const augmented = new Map<GraphNode<N, L>, AugmentedNode<N, L>>();
  const rankMap = new Map<number, Set<AugmentedNode<N, L>>>();
  // NOTE this implementation is probably fastest in most circumstances, but
  // slower in pathological ones. It may be worth adding an implementation
  // based off of two priority-queues (since we already use one) or a good
  // binary tree library.
  const columns: GraphNode<N, L>[] = [];

  const topBucket = new Set<AugmentedNode<N, L>>();
  const bottomBucket = new Set<AugmentedNode<N, L>>();
  const topBuckets: Set<AugmentedNode<N, L>>[] = [];
  const bottomBuckets: Set<AugmentedNode<N, L>>[] = [];

  function getBucket(bucket: number): Set<AugmentedNode<N, L>> {
    if (bucket === -Infinity) {
      return topBucket;
    } else if (bucket === Infinity) {
      return bottomBucket;
    } else if (bucket <= 0) {
      return getSetArray(topBuckets, -bucket);
    } else {
      return getSetArray(bottomBuckets, bucket - 1);
    }
  }

  for (const node of nodes) {
    const indeg = node.nparentLinks();
    const outdeg = node.nchildLinks();
    const rank = ranker(node);
    const aug = new AugmentedNode(node, indeg, outdeg, rank);
    augmented.set(node, aug);
    if (rank === undefined) {
      aug.stat = 'active';
      getBucket(aug.bucket()).add(aug);
    } else {
      setMultimapAdd(rankMap, rank, aug);
    }
  }

  const ranks = [...rankMap].sort(([a], [b]) => a - b).map(([, augs]) => augs);

  let topInd = 0;
  let bottomInd = ranks.length;
  let topRank = ranks.length ? ranks[topInd++] : new Set<AugmentedNode<N, L>>();
  let bottomRank = ranks.length > 1 ? ranks[--bottomInd] : new Set<AugmentedNode<N, L>>();

  for (const taug of topRank) {
    taug.stat = 'top';
    getBucket(taug.bucket()).add(taug);
  }
  for (const baug of bottomRank) {
    baug.stat = 'bottom';
    getBucket(baug.bucket()).add(baug);
  }

  function popBuckets(): AugmentedNode<N, L> | undefined {
    let node;
    if ((node = setPop(topBucket) ?? setPop(bottomBucket))) {
      return node;
    }
    const topNodes = getTail(topBuckets);
    const bottomNodes = getTail(bottomBuckets);
    if (bottomNodes) {
      // NOTE bottomNodes implies topNodes: either there are ranks, in which
      // case something must have a topRank guaranteeing a non-negative bucket,
      // or nothing has a rank, in which indeg = outdeg guaranteeing something
      // non-negative
      return setPop(topBuckets.length > bottomBuckets.length ? topNodes! : bottomNodes);
    } else if (topNodes) {
      return setPop(topNodes);
    }
  }

  // nodes in topological order after removing cycles
  const ordered: GraphNode<N, L>[] = Array<GraphNode<N, L>>(augmented.size);
  let minRank = 0;
  let maxRank = augmented.size;

  let aug;
  while ((aug = popBuckets())) {
    const { node } = aug;
    const rank = aug.isTop() ? minRank++ : --maxRank;
    aug.stat = 'ranked';
    ordered[rank] = node;

    // process parents
    for (const [par, num] of node.parentCounts()) {
      const paug = augmented.get(par)!;
      getBucket(paug.bucket()).delete(paug);
      paug.outdeg -= num;
      if (paug.stat !== 'ranked' && paug.stat !== 'inactive') {
        getBucket(paug.bucket()).add(paug);
      }
    }

    // process children
    for (const [child, num] of node.childCounts()) {
      const caug = augmented.get(child)!;
      getBucket(caug.bucket()).delete(caug);
      caug.indeg -= num;
      if (caug.stat !== 'ranked' && caug.stat !== 'inactive') {
        getBucket(caug.bucket()).add(caug);
      }
    }

    // update ranks
    topRank.delete(aug);
    if (!topRank.size && topInd < bottomInd) {
      topRank = ranks[topInd++];
      for (const taug of topRank) {
        taug.stat = 'top';
        getBucket(taug.bucket()).add(taug);
      }
    }

    bottomRank.delete(aug);
    if (!bottomRank.size && topInd < bottomInd) {
      bottomRank = ranks[--bottomInd];
      for (const baug of bottomRank) {
        baug.stat = 'bottom';
        getBucket(baug.bucket()).add(baug);
      }
    }
  }
  return ordered;
}

/** depth first search for arbitrary types */
export function* dfs<T>(children: ChildrenCallback<T>, ...queue: T[]): IterableIterator<T> {
  const seen = new Set<T>();
  let node;
  while ((node = queue.pop()) !== undefined) {
    if (seen.has(node)) continue;
    yield node;
    seen.add(node);
    queue.push(...children(node));
  }
}

/**
 * Interleave a larger array with a smaller iterable
 *
 * common part of template formatting
 */
function interleave(larger: readonly string[], smaller: Iterable<string>): string {
  const formatted = [];
  for (const [i, val] of entries(smaller)) {
    formatted.push(larger[i]);
    formatted.push(val);
  }
  formatted.push(larger[larger.length - 1]);
  return formatted.join('');
}

/** pretty error creation */
export function err(strings: readonly string[], ...objs: unknown[]): Error {
  const stringified = map(objs, (val) =>
    stringify(val, {
      indent: '  ',
      singleQuotes: false,
      inlineCharacterLimit: 60,
    }),
  );
  return new Error(interleave(strings, stringified));
}

/** internal error template */
function wrapInternalMsg(msg: string): string {
  return `internal error: ${msg}; if you encounter this please submit an issue at: https://github.com/erikbrinkman/d3-dag/issues`;
}

/** generic internal error */
export function ierr(strings: readonly string[], ...info: (string | number | bigint | boolean)[]): Error {
  const stringified = map(info, (val) => val.toString());
  return new Error(wrapInternalMsg(interleave(strings, stringified)));
}

/** something with a name, e.g. a function */
export interface Named {
  /** the function name */
  name: string;
  /** an optional tag we use to identify builtin methods */
  d3dagBuiltin?: true;
}

/** customized error when we detect call back was internal */
export function berr(strings: readonly string[], named: Named, ...info: (string | number | bigint)[]): Error {
  const [typ, ...rest] = strings;
  const stringified = map(info, (val) => val.toString());
  const msg = interleave(rest, stringified);
  const name = named.name || 'anonymous';
  /* istanbul ignore next */
  return new Error(
    'd3dagBuiltin' in named ? wrapInternalMsg(`builtin ${typ}'${name}'${msg}`) : `custom ${typ}'${name}'${msg}`,
  );
}
