import React, { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis, LabelList, CartesianGrid } from 'recharts';
import { formatDollar, formatDate, formatPercent } from '../../helpers/dataFormatter';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  FilterFn,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { Row, Column, Title, H3, H2, F2, F3 } from '..';
import SimpleTable from '../SimpleTable';

import { rankItem } from '@tanstack/match-sorter-utils';

type Props = { data: any; summaries: any };

const TextContainer = styled(Column)({
  marginLeft: '20px',
  backgroundColor: colors.green + '30',
  borderRadius: '16px',
  flex: 1,
  padding: '8px 24px',
});

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

type TransactionType = {};

const CustomBar = (props: any) => {
  const { x, y, width, height } = props;

  const BarHeight = props?.payload?.values[1] - props?.payload?.values[0];

  console.log('bar height: ', BarHeight, props?.payload?.values[0], props?.payload?.values[1]);

  return (
    <svg>
      <rect
        x={x}
        y={height > 0 ? y : y + height}
        width={width}
        height={Math.abs(height)}
        fill={height > 0 ? colors.green : colors.red}
      />
      <text
        style={{
          fontFamily: 'Inter',
          fill: BarHeight > 0 ? colors.green : colors.red,
          fontSize: '14px',
          fontWeight: '500',
        }}
        x={x + 9}
        y={y - 8}
      >
        {BarHeight ? formatDollar(BarHeight) : ''}
      </text>
    </svg>
  );
};

export const Transactions = ({ data, summaries }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<TransactionType>[]>(
    () => [
      {
        accessorKey: 'timestamp',
        header: () => 'Date Purchased',
        cell: (info: any) => {
          return formatDate(info?.getValue(), true);
        },
      },
      {
        accessorKey: 'ticker',
        header: () => 'Ticker',
      },
      {
        accessorKey: 'amount',
        header: () => 'Flows',
        cell: (info: any) => {
          const dollarFlows = info?.getValue();
          return <H3 style={{ color: dollarFlows > 0 ? colors.green : colors.red }}>{formatDollar(dollarFlows)}</H3>;
        },
      },
      {
        accessorKey: 'percentOfHoldings',
        header: () => '% of Holdings',
        cell: (data: any) => {
          const percentHoldings = data?.getValue() * 100;
          return formatPercent(percentHoldings, 1);
        },
      },
    ],
    [],
  );

  const dataForTable: TransactionType[] = data?.tableData?.datapoints ?? data?.tableData;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const transactionsTable = useReactTable({
    columns,
    data: dataForTable ?? [],
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSorting: true,
  });

  const dataAvailable = data?.tableData?.datapoints?.length > 0 || data?.tableData?.length > 0;

  const chartData =
    data?.chartData?.datapoints?.filter(
      (point: any) => Math.abs(Math.abs(point?.values?.[0]) - Math.abs(point?.values?.[1])) > 1000,
    ) ??
    data?.chartData?.filter(
      (point: any) => Math.abs(Math.abs(point?.values?.[0]) - Math.abs(point?.values?.[1])) > 1000,
    );

  return !dataAvailable ? (
    <Column style={{ padding: '80px 0px', alignItems: 'center' }}>
      <H2>No Data Available</H2>
      <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>Please check back in future for updated data.</F3>
    </Column>
  ) : (
    <Column>
      {chartData.length > 0 ? (
        <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '10px 0px 10px' }}>
          <ResponsiveContainer width={460} height={320} id={'bubble-chart-data'}>
            <BarChart
              margin={{ left: 40, right: 20, top: 40 }}
              // @ts-ignore
              height={'80%'}
              data={chartData}
            >
              <XAxis
                dataKey={'values'}
                tick={{
                  ...typography.mainFont,
                  fontSize: '10px',
                  fontWeight: 400,
                  fill: '#758790',
                }}
                tickLine={false}
                tickFormatter={(tick: any, index: number) => {
                  return data?.chartData?.datapoints
                    ? data?.chartData?.datapoints?.[index]?.label
                    : data?.chartData?.[index]?.label;
                }}
                interval={0}
              />
              <YAxis
                dataKey={'values'}
                tick={{
                  ...typography.mainFont,
                  fontSize: '10px',
                  fontWeight: 400,
                  fill: '#758790',
                }}
                tickFormatter={(tick) => {
                  return formatDollar(tick) === '-' ? '$0' : formatDollar(tick, 1);
                }}
                tickLine={false}
                axisLine={false}
                interval={0}
              />
              <CartesianGrid strokeDasharray="4 0" vertical={false} />
              <Bar isAnimationActive={false} dataKey={'values'} shape={<CustomBar />} />
            </BarChart>
          </ResponsiveContainer>
          <TextContainer>
            <Title style={{ textAlign: 'left' }}>Net Flows</Title>
            <F3 style={{ textAlign: 'left', color: colors.grey_02 }}>{summaries?.headingData?.netFlowsSummary}</F3>
          </TextContainer>
        </Row>
      ) : null}
      <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '0px 0px 30px' }}>
        <SimpleTable
          tableData={transactionsTable}
          searchBar
          searchBarText={globalFilter}
          setSearchBarText={setGlobalFilter}
        />
      </Row>
    </Column>
  );
};
