import React, { useState, useEffect } from 'react';
import { colors } from '../styles';

import {
  F2,
  F3,
  H2,
  PrimaryButtonShort,
  SecondaryButtonShort,
  ModalRoot,
  ModalHeader,
  ModalContainer,
  MainTextInput,
  ModalFooter,
  Column,
} from '.';
import Loader from './Loader';
import { useMutation } from '@apollo/client';
import { UPDATE_JOURNEY_NAME } from '../api/integrations';

interface Props {
  modalStatus: boolean;
  closeModal: any;
  packageRunName?: string | null;
  setPackageRunName?: any | null;
  journeyId?: number | null;
}

const PackageNameModal = ({ closeModal, modalStatus, packageRunName, setPackageRunName, journeyId }: Props) => {
  // const [loading, setLoading] = useState(false);
  // const [errors, setErrors] = useState<any>(null);
  const [runName, setRunName] = useState<string>('');
  const [infoText] = useState<any>('');
  const [updateJourneyName, { loading, error }] = useMutation(UPDATE_JOURNEY_NAME, {
    onCompleted: (data) => {
      setPackageRunName(runName);
      closeModal();
    },
  });

  const savePackageName = async () => {
    try {
      if (journeyId) {
        updateJourneyName({ variables: { journeyId: String(journeyId), name: runName } });
      }
    } catch (error: any) {
      console.warn(error.message);
    }
  };

  useEffect(() => {
    setRunName(packageRunName ? packageRunName : '');
  }, [packageRunName, setRunName]);

  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer style={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '8px 16px' }}>{'Name Your Journey'}</ModalHeader>
        {infoText ? (
          <Column style={{ width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
            <H2 style={{ margin: '18px 0px 0px' }}>{infoText?.header}</H2>
            <F3 style={{ color: colors.grey_02, margin: '8px 0px 16px' }}>{infoText?.body}</F3>
          </Column>
        ) : (
          <Column style={{ width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
            <F3 style={{ color: colors.grey_02 }}>
              Give your journey a name so that you can easily find it again in the future.
            </F3>
            <MainTextInput
              label="Journey Name"
              size="small"
              style={{ margin: '22px 0' }}
              value={runName}
              /*className={classes.fieldOverride}*/
              focused
              onChange={(event) => setRunName(event.target.value)}
            />
            <F2 style={{ color: colors.red }}>{error?.message}</F2>
          </Column>
        )}
        <ModalFooter style={{ width: '100%', padding: '8px 16px', alignItems: 'flex-end' }}>
          {infoText ? (
            <PrimaryButtonShort onClick={closeModal}>Close</PrimaryButtonShort>
          ) : (
            <>
              <SecondaryButtonShort onClick={closeModal}>Cancel</SecondaryButtonShort>
              <PrimaryButtonShort onClick={savePackageName} style={{ margin: '0px 8px' }}>
                {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Save'}
              </PrimaryButtonShort>
            </>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default PackageNameModal;
