import React from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { Row } from '..';

type ComponentOption = {
  id: string;
  key: string;
  label: string;
};

type Props = {
  selectedComponent: string;
  setSelectedComponent: (component: string) => void;
  ComponentList: ComponentOption[];
};

type ComponentProps = {
  active?: boolean;
};

const Component = styled('div')((props: ComponentProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...typography?.mainFont,
  fontSize: '13px',
  height: '38px',
  width: '200px',
  fontWeight: props?.active ? 600 : 400,
  color: colors.grey_03,
  backgroundColor: colors.white,
  borderRadius: '8px 8px 0px 0px',
  padding: '12px 8px 6px',
  cursor: 'pointer',
  borderBottom: props?.active ? `2px solid ${colors.blue}` : 'none',
  background: props?.active ? colors.grey_01 : colors.white,
  transition: 'background-color 0.6s, border-bottom 0.6s ease-in-out',
  ':hover': {
    backgroundColor: props?.active ? colors.grey_01 : colors.grey_00,
  },
}));

const Container = styled(Row)({
  borderBottom: `1px solid ${colors.grey_01}`,
  margin: '8px 0px',
});

function ComponentSwitcher({ selectedComponent, setSelectedComponent, ComponentList }: Props) {
  return (
    <Container>
      {ComponentList.map((component: ComponentOption) => {
        return (
          <Component
            active={component?.key === selectedComponent}
            key={component.id}
            onClick={() => setSelectedComponent(component.key)}
          >
            {component?.label}
          </Component>
        );
      })}
    </Container>
  );
}

export default ComponentSwitcher;
