// New React Component call LinkDataBarChart
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, BarChart, XAxis, Bar } from 'recharts';
import { colors } from '../../styles';
import { mockLinkedData } from './mockData';

const ChartTitle = styled('div')({
  fontSize: 18,
  fontWeight: 500,
  width: '92%',
  margin: '22px 25px 0px',
});

const ChartSubtitle = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  color: colors.grey_02,
  width: '92%',
  padding: '7px 0',
  margin: '0px 25px',
});

interface Props {
  selectedToggle: any;
  selectedMetric: any;
}

export const LinkedDataBarChart = ({ selectedToggle, selectedMetric }: Props) => {
  return (
    <div>
      <ChartTitle>
        Percent of {selectedToggle.title}
        {!selectedToggle.title.endsWith('s') ? 's' : null} by {selectedMetric.displayText}{' '}
      </ChartTitle>
      <ChartSubtitle>
        Based upon the 7 {selectedToggle.title.replace(' Characteristics', ' Clients')}
        {!selectedToggle.title.endsWith('s') ? 's' : null} whose data we've found
      </ChartSubtitle>
      <ResponsiveContainer width={'100%'} height={300}>
        <BarChart data={mockLinkedData[selectedMetric.dataKey]} margin={{ top: 20, right: 40 }} barGap={0} barSize={30}>
          <XAxis
            dataKey={selectedMetric.dataKey}
            tick={{ stroke: '#758790', fontSize: 14, fontWeight: 100, fill: '#758790' }}
            interval={0}
          />
          {/* <YAxis
              fill="#fff"
              tick={{ stroke: '#758790', fontWeight: 100, fontSize: 14, fill: '#758790' }}
              tickFormatter={(tickItem) => tickItem + '%'}
            /> */}
          <Bar dataKey={'percent_clients'} fill={colors.black_01} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
