import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './api';
import { StoreProvider } from 'easy-peasy';
import store from './store';

//@ts-ignore
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* @ts-ignore */}
      <StoreProvider store={store}>
        <ApolloProvider client={apolloClient}>
          <CssBaseline />
          <App />
        </ApolloProvider>
      </StoreProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
