import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, MainTextInput, PrimaryButton } from '..';
import { colors } from '../../styles';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  linkHubspot?: any;
};

const HubSpot = ({ linkHubspot }: Props) => {
  const [hubspotApiKey, setHubspotApiKey] = useState('');
  const [integrationNickname, setIntegrationNickname] = useState('');

  const pageCopy = {
    header: 'HubSpot Integration',
    subheader: (
      <span>
        Please create a private app in your HubSpot instance and then enter your <b>Access Token</b> and a custom{' '}
        <b>Nickname</b> to create this integration (click{' '}
        <a
          style={{ textDecoration: 'none', color: colors.grey_03 }}
          href={'https://developers.hubspot.com/docs/api/private-apps'}
        >
          here
        </a>{' '}
        for more detailed instructions).
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 30px' }}>
          {pageCopy.subheader}
        </F3>
      </TextContainer>
      <Column>
        <MainTextInput
          id="email-field"
          label="Integration Nickname"
          size="small"
          // sx={{ margin: '12px 0', width: '100%' }}
          value={integrationNickname}
          /*className={classes.fieldOverride}*/
          focused
          onChange={(event) => setIntegrationNickname(event.target.value)}
        />
        <MainTextInput
          id="email-field"
          label="Private App Access Token"
          size="small"
          // sx={{ margin: '12px 0', width: '100%' }}
          value={hubspotApiKey}
          /*className={classes.fieldOverride}*/
          focused
          onChange={(event) => setHubspotApiKey(event.target.value)}
        />
      </Column>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton style={{ width: '160px' }} onClick={() => linkHubspot(hubspotApiKey, integrationNickname)}>
          Submit
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default HubSpot;
