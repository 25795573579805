import React from 'react';
import FieldMapper from '../FieldMapper/FieldMapper';
import { Column, H2, F3, MainTextInput, Row, PrimaryButtonShort } from '..';
import { colors } from '../../styles';
import moment from 'moment';

interface Props {
  setModalPage: any;
  pullHubspotData: any;
}

const defaultFieldMapping = [
  { srcKey: 'click', dstKey: '' },
  { srcKey: 'open', dstKey: '' },
  { srcKey: 'deliveries', dstKey: '' },
];

function ConfigureHubspotPull({ setModalPage, pullHubspotData }: Props) {
  const [fieldMapping, setFieldMapping] = React.useState(defaultFieldMapping);
  const [fileHeaders, setFileHeaders] = React.useState([]);
  const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));

  const formatValidFieldName = (value: string) => {
    switch (value) {
      case 'email':
        return 'Emails';
        break;
      case 'opens':
        return 'Opens';
        break;
      case 'deliveries':
        return 'Deliveries';
        break;
      default:
        return '-';
        break;
    }
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  return (
    <Column style={{ alignItems: 'center' }}>
      <H2 style={{ width: '100%', textAlign: 'center' }}>Configure New Hubspot Pull</H2>
      <F3 style={{ color: colors.grey_02, width: '100%', textAlign: 'center', margin: '20px 0px 20px' }}>
        Please select a start date for your data pull, and select the event ids which correspond with the events below.
      </F3>
      <MainTextInput
        type={'date'}
        style={{ width: '260px' }}
        label="Start Date"
        focused
        value={startDate}
        size="small"
        onChange={(event: any) => {
          setStartDate(event?.target?.value);
        }}
      />
      <FieldMapper
        previewTable={previewTable}
        fieldMapping={fieldMapping}
        setFieldMapping={setFieldMapping}
        fileHeaders={fileHeaders}
        formatValidFieldName={formatValidFieldName}
      />
      <Row
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${colors.grey_01}`,
          padding: '16px 0px 0px',
        }}
      >
        <PrimaryButtonShort>Create Integration</PrimaryButtonShort>
      </Row>
    </Column>
  );
}

export default ConfigureHubspotPull;
