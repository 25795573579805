import React, { useState } from 'react';
import { colors } from '../styles';

import {
  F2,
  F3,
  H2,
  PrimaryButtonShort,
  SecondaryButtonShort,
  ModalRoot,
  ModalHeader,
  ModalContainer,
  MainTextInput,
  ModalFooter,
  Column,
} from '.';
import Loader from './Loader';
import { INVITE_USER } from '../api/systemManagement';
import { useMutation } from '@apollo/client';

interface Props {
  modalStatus: boolean;
  closeModal: any;
  orgId: string;
  refreshAndSelect: any;
}

const AddUserModal = ({ closeModal, modalStatus, orgId, refreshAndSelect }: Props) => {
  const [newUserEmail, setNewUserEmail] = useState<string>('');
  const [errors, setErrors] = useState<any>(null);
  const [infoText, setInfoText] = useState<any>(null);

  const [inviteUserToOrganization, { loading, error }] = useMutation(INVITE_USER, {
    onCompleted: (data) => {
      if (data?.platformManagementInviteUserToOrganization?.success) {
        setInfoText({ header: 'Invitation Sent', body: `We have emailed an invite to ${newUserEmail}.` });
        setTimeout(() => {
          closeModal();
          refreshAndSelect(orgId);
          setInfoText(null);
          setNewUserEmail('');
        }, 2000);
      } else {
        setErrors(data?.platformManagementInviteUserToOrganization?.details);
      }
    },
  });

  const inviteUserToOrg = async () => {
    setErrors(null);
    try {
      if (
        !newUserEmail ||
        !newUserEmail.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
      ) {
        setErrors('Please enter a valid email');
      } else {
        inviteUserToOrganization({ variables: { emailAddress: newUserEmail, organizationId: orgId } });
      }
    } catch (error: any) {
      setErrors(error.message);
      console.warn(error.message);
    }
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer style={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '8px 16px' }}>{'Invite User to Organization'}</ModalHeader>
        {infoText ? (
          <Column style={{ width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
            <H2 style={{ margin: '18px 0px 0px' }}>{infoText?.header}</H2>
            <F3 style={{ color: colors.grey_02, margin: '8px 0px 16px' }}>{infoText?.body}</F3>
          </Column>
        ) : (
          <Column style={{ width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
            <MainTextInput
              label="Email"
              size="small"
              style={{ margin: '22px 0' }}
              value={newUserEmail}
              /*className={classes.fieldOverride}*/
              focused
              onChange={(event) => setNewUserEmail(event.target.value.toLowerCase())}
            />
            <F2 style={{ color: colors.red }}>{errors ? errors : error ? error.message : ''}</F2>
          </Column>
        )}
        <ModalFooter style={{ width: '100%', padding: '8px 16px', alignItems: 'flex-end' }}>
          {infoText ? (
            <PrimaryButtonShort onClick={closeModal}>Close</PrimaryButtonShort>
          ) : (
            <>
              <SecondaryButtonShort onClick={closeModal}>Cancel</SecondaryButtonShort>
              <PrimaryButtonShort onClick={inviteUserToOrg} style={{ margin: '0px 8px' }}>
                {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Invite User'}
              </PrimaryButtonShort>
            </>
          )}
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default AddUserModal;
