import React from 'react';
import { useState, useEffect } from 'react';
import { PackageContainer } from '../../../components/PackageContainer';
import { H2 } from '../../../components';
import { PackageHome } from '../../../components/PackageHome';
import { PackageFundSelector } from '../../../components/PackageFundSelector';
import { PackageCustomize } from '../../../components/PackageCustomize';
import Signals from './Signals';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from '../../../store';
import { DistillScoreLogo } from '../../../components/DistillScoreLogo';
import { PackageSelectAdvisorSet } from '../../../components/PackageSelectAdvisorSet/PackageSelectAdvisorSet';
import { styled } from '@mui/styles';
import { colors, typography } from '../../../styles';
import { formatTimestamp } from '../../../helpers/dataFormatter';

import SelectIcon from '../../../assets/icons/select.svg';
import CustomizeIcon from '../../../assets/icons/customize.svg';
import DiscoverIcon from '../../../assets/icons/discover.svg';
import moment from 'moment';
import InfoModal from '../../../components/InfoModal';
import { CREATE_JOURNEY, GET_DISCOVER_ARCHIVES } from '../../../api/driveSales/discoverLeadsAPI';
import { useMutation } from '@apollo/client';

const headerOptions = {
  standard: [
    { title: 'Advisor Characteristics', id: 'advisor' },
    { title: 'Advisor-Client Characteristics', id: 'advisedClients' },
  ],
  leadPool: [{ title: 'Screen Your Universe of Leads', id: 'advisorLeadPool' }],
};

const lplHeaderOptions = {
  standard: [{ title: 'Advisor Characteristics', id: 'lplAdvisor' }],
  leadPool: [{ title: 'Screen Your Universe of Leads', id: 'advisorLeadPool' }],
};

const ActiveChip = styled('div')({
  height: '16px',
  width: '60px',
  borderRadius: '5px',
  color: colors.white,
  background: colors.green,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...typography.mainFont,
  fontWeight: 700,
  fontSize: '10px',
});

const archiveTableHeaders = [
  {
    header: 'Journey Name',
    key: 'customName',
    formatData: (row: any) => {
      return <H2 style={{ margin: '10px 0px' }}>{row.customName ? row.customName : '-'}</H2>;
    },
    //bold: true,
  },
  {
    header: 'Date Created',
    key: 'dateTime',
    formatData: (row: any) => {
      return formatTimestamp(row.dateCreated);
    },
    //bold: true,
  },
  {
    header: '# of Prospects',
    key: 'advisorLeads',
    formatData: (row: any) => (row?.runhistorySet?.[0]?.serveCount ? row?.runhistorySet?.[0]?.serveCount : '-'),
  },
  {
    header: 'Source',
    key: '',
    formatData: (row: any) => 'CSV',
  },
  {
    header: 'Date Updated',
    key: 'dateUpdated',
    formatData: (row: any) => {
      return formatTimestamp(row.dateUpdated);
    },
  },
  {
    header: 'Status',
    key: 'isActive',
    formatData: (row: any) => {
      return row.isActive ? (
        <ActiveChip>Active</ActiveChip>
      ) : (
        <ActiveChip style={{ background: colors.red }}>Inactive</ActiveChip>
      );
    },
  },
  {
    header: 'Progress',
    key: 'progress',
    formatData: (row: any) => {
      return;
    },
    //bold: true,
  },
];
type Props = {};

export const DiscoverLeads = (props: Props) => {
  const basePath = '';
  const title = 'Discover New Leads';
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [generateError, setGenerateError] = useState<string | null>(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [activeTable, setActiveTable] = useState('standard');
  const [customLeadCount, setCustomLeadCount] = useState(20);
  const [hideCurrentFundHolders, setHideCurrentFundHolders] = useState(false);

  const [newJourneyName, setNewJourneyName] = useState<string>('Unnamed Journey');

  const appType = useStoreState((state) => state.appType);

  const subscriptionsList: any = useStoreState((state) => state.user.subscriptions);
  const discoverLeadsSubscription = subscriptionsList?.find((item: any) => item?.package === 'DISCOVER_LEADS');

  const [showInfoModal, setShowInfoModal] = useState(false);

  const {
    progress,
    progressTitles,
    advisorSetSelected,
    fundsSelected,
    filterOptions,
    characteristics,
    runId,
    packageRunName,
    runIdArray,
    journeyId,
    dateRun,
  } = useStoreState((state) => {
    return state.driveSales.discoverLeads;
  });

  const {
    setProgress,
    setProgressTitles,
    setFundsSelected,
    setAdvisorSetSelected,
    setFilterOptions,
    setCharacteristics,
    setPackageRunName,
    setDateRun,
    setRunIdArray,
    setJourneyId,
    setRunId,
    loadArchive,
  } = useStoreActions((actions) => {
    return actions.driveSales.discoverLeads;
  });

  useEffect(() => {
    setProgress(0);
  }, []);

  const openArchive = async (Archive: any) => {
    loadArchive(Archive);
    navigate('signals');
  };

  const [generateDiscoverJourney] = useMutation(CREATE_JOURNEY, {
    onCompleted: (data) => {
      if (data?.discoverLeadsCreateJourney?.journey) {
        setPackageRunName(newJourneyName);
        setDateRun(moment().format());
        setRunIdArray([data?.discoverLeadsCreateJourney?.journey?.latestRun]);
        setRunId(Number(data?.discoverLeadsCreateJourney?.journey?.latestRun?.id));
        setJourneyId(Number(data?.discoverLeadsCreateJourney?.journey?.id));
      } else if (data?.discoverLeadsCreateJourney?.code === 'INVALID_CONFIGURATION') {
        setGenerateError(
          'Invalid configuration. Please contact support to make sure your organization environment is properly configured.',
        );
      } else if (data?.discoverLeadsCreateJourney?.code === 'INSUFFICIENT_QUOTA') {
        setGenerateError(
          'Insufficient quota. Please contact support to increase your quota or reduce the number of leads you are requesting.',
        );
      }
    },
    onError: (error) => {
      setGenerateError(
        `An unknown error occured. Please try again or contact support if the issue persists.(${error.message})`,
      );
    },
  });

  const generateResults = async () => {
    setGenerateError(null);
    setRunId(null);

    try {
      if (discoverLeadsSubscription?.isDemo) {
        const selectedFunds: string[] = Object.keys(fundsSelected).map((fundId) => fundId);
        const selectedAdvisorSet: string[] = Object.keys(advisorSetSelected).map((advisorSetId) => advisorSetId);

        const allFunds = selectedFunds?.length === 0;

        generateDiscoverJourney({
          variables: {
            journeyName: newJourneyName,
            benchmarkAdvisorSetIds: selectedAdvisorSet,
            fundIds: selectedFunds,
            numberOfLeads: customLeadCount,
            allFunds,
          },
        });
      } else {
        throw new Error('');
      }
    } catch (error: any) {
      console.warn('error in generate results: ', error);
      setGenerateError(error.message);
    }
  };

  const PackageSteps = [
    {
      label: 'Home',
      link: 'home',
      Component: PackageHome,
      props: {
        name: title,
        description:
          appType === 'LPL'
            ? `This package identifies LPL advisor leads by comparing their interests to your fund's thematic exposures.`
            : `This package identifies new leads by comparing their interests to your fund's thematic exposures.`,
        stepDescriptions: [
          {
            icon: SelectIcon,
            name: 'Select funds',
            description: `for which you’d like to find advisors`,
          },
          {
            icon: SelectIcon,
            name: 'Select or upload',
            description: `advisors to create a benchmark`,
          },
          {
            icon: CustomizeIcon,
            name: 'Customize',
            description: 'characteristics for the algorithm',
          },
          {
            icon: DiscoverIcon,
            name: 'Discover',
            description: 'the most promising advisors',
          },
        ],
        packageStats: [
          { label: 'Completed Journeys', data: '527' },
          { label: 'Total Prospects', data: '2,701' },
        ],
        nextStep: () => {
          navigate('funds');
          setFundsSelected({});
          setCharacteristics({});
          setFilterOptions({});
          setSelectedFilters({});
          setRunId(null);
          setPackageRunName(null);
          setDateRun(null);
          setProgressTitles(['Current', ' ', ' ', ' ']);
          setProgress(1);
          setNewJourneyName('Unnamed Journey');
        },
        openArchive,
        GET_ARCHIVE_QUERY: GET_DISCOVER_ARCHIVES,
        tableHeaders: archiveTableHeaders,
      },
    },
    {
      label: 'Select Funds',
      link: 'funds',
      Component: PackageFundSelector,
      props: {
        setProgress,
        completed,
        fundsSelected,
        maxSelections: 3,
        setFundsSelected,
        setNewJourneyName,
        textData: {
          pageHeader:
            "Select funds for which you'd like to find advisor leads (You may select a maximum of three funds)",
          lplPageHeader:
            "Select funds for which you'd like to find LPL advisor leads (You may select a maximum of three funds)",
        },
        nextAction: () => {
          setProgressTitles(['Complete', 'Current', '', '']);
          setProgress(2);
          navigate('advisors');
        },
      },
    },
    {
      label: 'Select Advisor Set',
      link: 'advisors',
      Component: PackageSelectAdvisorSet,
      props: {
        advisorSetSelected,
        setAdvisorSetSelected,
        pageCopy: {
          header: 'Select a set of advisors to create a benchmark',
          subheader:
            'Our algorithm will try to clone the selected advisors for you. You can also proceed without a benchmark by using a predictive model. When an advisor converts, the predictive model will be updated to incorporate that new data. Only advisors with valid CRDs will appear in the table below.',
        },
        customAction: {
          label: 'Continue Without Benchmark',
          onClick: () => {
            setProgressTitles(['Complete', 'Complete', '', '']);
            setProgress(3);
            navigate('custom-criteria');
          },
        },
        nextAction: () => {
          setProgressTitles(['Complete', 'Complete', '', '']);
          setProgress(3);
          navigate('custom-criteria');
        },
      },
    },
    {
      label: 'Customize',
      link: 'custom-criteria',
      Component: PackageCustomize,
      props: {
        nextAction: async () => {
          if (discoverLeadsSubscription?.isDemo) {
            setProgressTitles(['Complete', 'Complete', 'Complete', 'Complete']);
            setProgress(4);
            setFirstTime(true);
            generateResults();
            navigate('signals');
          } else {
            setShowInfoModal(true);
            navigate('home');
          }
        },
        backAction: () => setProgress(1),
        dataFromSource: [],
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        headerOptions: appType === 'LPL' ? lplHeaderOptions : headerOptions,
        activeTable,
        setActiveTable,
        customLeadCount,
        setCustomLeadCount,
        hideCurrentFundHolders,
        setHideCurrentFundHolders,
        screenLeads: {
          standard: (
            <div style={{ cursor: 'pointer' }}>
              If you would like to screen the universe of leads,{' '}
              <b onClick={() => setActiveTable('leadPool')}>click here</b>
            </div>
          ),
          leadPool: (
            <div style={{ cursor: 'pointer' }}>
              If you would like to customize the algorithm, <b onClick={() => setActiveTable('standard')}>click here</b>
            </div>
          ),
        },
        headerData: {
          standard: {
            pageHeading: (
              <span>
                Would you like to customize characteristics to calculate the best match reflected in the{' '}
                <DistillScoreLogo packageName="advisorLeads" width={'80px'} />?
              </span>
            ),
            pageSubHeading: () => {
              return appType === 'LPL' ? (
                <span>
                  <DistillScoreLogo packageName="advisorLeads" width={'80px'} />
                  uses all available characteristics of LPL Advisors to identify Advisors most likely to purchase your
                  selected funds. This is our recommended approach. However, you can deselect certain characteristics
                  below.
                </span>
              ) : (
                <span>
                  <DistillScoreLogo packageName="advisorLeads" width={'80px'} />
                  uses all available characteristics of Advisors and Advised Clients to identify Advisors most likely to
                  purchase your selected funds. This is our recommended approach. However, you can deselect certain
                  characteristics below.
                </span>
              );
            },
          },
          leadPool: {
            pageHeading: <span>Would you like to screen the universe of leads before finding the best match?</span>,
            pageSubHeading: () => {
              return (
                <span>
                  AMP uses all available leads to identify advisors most likely to be intetrested in your selected
                  themes. This is our recommended approach however you can screen the leads below.
                </span>
              );
            },
          },
          customizeHeader: 'Characteristics',
          percentPrefix: 'Advised Clients',
          clientChartHeading: 'See Advisors Client Distribution',
        },
        dataPoints: [{ label: 'Advisors', fill: '#8380CC', id: 'percent_tifin' }],
        origin: 'Consolidated',
        fullpage: false,
        getDataForMetric: async (metric: any, setAverage: any, setClientCount: any) => {
          try {
            // let data = await AdvisorLeadsAPI.getAverages();
            // console.log('getDataForMetric: ', data);
            // setAverage(data.averages[metric.averageDataKey]);
            // setClientCount(formatNumber(data.advisorLeads));
          } catch (error: any) {
            console.warn(error.message);
          }
        },
        getExplanationForMetric: async (metric: any, setExplanationForMetric: any) => {},
        getVizData: async (setData: any) => {},
        selectedFilters,
        setSelectedFilters,
        getAdvisorData: async (setData: any) => {
          try {
            // let data = await AdvisorLeadsAPI.advisorVisualization();
            // console.log('getAdvisorData: ', data);
            // setData(data.advisorDistributions);
          } catch (error: any) {
            console.warn('error in getAdvisor data: ', error.message);
          }
        },
        showClientCount: false,
      },
    },
    {
      label: 'Signals',
      link: 'signals',
      Component: Signals,
      props: {
        textData: {
          radarHeading:
            appType === 'LPL' ? (
              <span>
                {'Advisor Pool Characteristics vs Lead Characteristics'}{' '}
                <span style={{ fontSize: '12px', color: colors.grey_02 }}>(Over Past 3 Months)</span>
              </span>
            ) : (
              'Fund Characteristics vs. Advisor Interest'
            ),
          allChartHeading: 'All Advisors',
          bestChartHeading: 'Best Advisors',
          themesHeading: 'Your Fund Themes',
          topTitle: 'Top Advisor',
          //metricName: 'Individual',
          distillExplanation:
            ' is the result of a thematic and financial analysis of each Advisor. The higher the score, the stronger their financials, and the more their thematic activity is aligned with your fund.',
        },
        progress,
        selections: fundsSelected,
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        filterOptions,
        setFilterOptions,
        generateError,
        firstTime,
        setFirstTime,
        advisorSetSelected,
        fundsSelected,
        setRunIdArray,
        packageRunName,
        runId,
        setRunId,
        journeyId,
        runsArray: runIdArray,
        radarFeatures: [
          { label: 'All Advisors', id: 'all_advisors' },
          { label: 'Selected Advisor', id: 'advisor' },
        ],
      },
    },
  ];

  const navSteps = PackageSteps.map((step) => step.link);

  return (
    <>
      <PackageContainer
        runId={runId}
        journeyId={journeyId}
        packageRunName={packageRunName}
        setPackageRunName={setPackageRunName}
        dateRun={dateRun}
        basePath={basePath}
        title={title}
        PackageSteps={PackageSteps}
        progress={progress}
        progressTitles={progressTitles}
        setProgress={setProgress}
        navSteps={navSteps}
      />
      <InfoModal
        modalStatus={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        modalCopy={{
          header: 'Information',
          subheader: `To ensure the highest quality results. One of our data scientist will work with you to create a bespoke set of results for your use case. Please contact your support representative to learn more.`,
        }}
      />
    </>
  );
};
