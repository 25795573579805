export const mockLinkedData: any = {
  mortgageHomeValue: [
    {
      homeValue: '0-250k',
      percent_clients: 10,
    },
    {
      homeValue: '250k-500k',
      percent_clients: 18,
    },
    {
      homeValue: '500k-1m',
      percent_clients: 34,
    },
    {
      homeValue: '1m-1.5m',
      percent_clients: 18,
    },
    {
      homeValue: '1.5m-3m',
      percent_clients: 14,
    },
    {
      homeValue: '3m+',
      percent_clients: 6,
    },
  ],
  mortgageLoanAmount: [
    {
      loanAmount: '0-250k',
      percent_clients: 8,
    },
    {
      loanAmount: '250k-500k',
      percent_clients: 30,
    },
    {
      loanAmount: '500k-1m',
      percent_clients: 24,
    },
    {
      loanAmount: '1m-1.5m',
      percent_clients: 16,
    },
    {
      loanAmount: '1.5m-3m',
      percent_clients: 16,
    },
    {
      loanAmount: '3m+',
      percent_clients: 6,
    },
  ],
  mortgageLoanProduct: [
    {
      loanProduct: '0-250k',
      percent_clients: 28,
    },
    {
      loanProduct: '250k-500k',
      percent_clients: 10,
    },
    {
      loanProduct: '500k-1m',
      percent_clients: 14,
    },
    {
      loanProduct: '1m-1.5m',
      percent_clients: 18,
    },
    {
      loanProduct: '1.5m-3m',
      percent_clients: 14,
    },
    {
      loanProduct: '3m+',
      percent_clients: 16,
    },
  ],
  mortgageFundedDate: [
    {
      fundedDate: '0-250k',
      percent_clients: 15,
    },
    {
      fundedDate: '250k-500k',
      percent_clients: 13,
    },
    {
      fundedDate: '500k-1m',
      percent_clients: 14,
    },
    {
      fundedDate: '1m-1.5m',
      percent_clients: 28,
    },
    {
      fundedDate: '1.5m-3m',
      percent_clients: 24,
    },
    {
      fundedDate: '3m+',
      percent_clients: 6,
    },
  ],
  mortgageDebtToIncomeRatio: [
    {
      debtToIncomeRatio: '0-250k',
      percent_clients: 10,
    },
    {
      debtToIncomeRatio: '250k-500k',
      percent_clients: 18,
    },
    {
      debtToIncomeRatio: '500k-1m',
      percent_clients: 24,
    },
    {
      debtToIncomeRatio: '1m-1.5m',
      percent_clients: 28,
    },
    {
      debtToIncomeRatio: '1.5m-3m',
      percent_clients: 8,
    },
    {
      debtToIncomeRatio: '3m+',
      percent_clients: 12,
    },
  ],
  mortgageLoanToValueRatio: [
    {
      loanToValueRatio: '0-250k',
      percent_clients: 10,
    },
    {
      loanToValueRatio: '250k-500k',
      percent_clients: 18,
    },
    {
      loanToValueRatio: '500k-1m',
      percent_clients: 34,
    },
    {
      loanToValueRatio: '1m-1.5m',
      percent_clients: 18,
    },
    {
      loanToValueRatio: '1.5m-3m',
      percent_clients: 14,
    },
    {
      loanToValueRatio: '3m+',
      percent_clients: 6,
    },
  ],
  mortgageInterestRate: [
    {
      interestRate: '0-250k',
      percent_clients: 20,
    },
    {
      interestRate: '250k-500k',
      percent_clients: 8,
    },
    {
      interestRate: '500k-1m',
      percent_clients: 34,
    },
    {
      interestRate: '1m-1.5m',
      percent_clients: 10,
    },
    {
      interestRate: '1.5m-3m',
      percent_clients: 16,
    },
    {
      interestRate: '3m+',
      percent_clients: 12,
    },
  ],
};
