import { formatThousandDollar, formatNumber } from './dataFormatter';
import { formatSelectedFunds } from './formatArchives';

export async function exportClientsToSpreadsheet(
  data: string | any[],
  headers: any,
  fundsSelected: any,
  packageName: any,
) {
  let formattedSelectedFunds = fundsSelected;
  if (fundsSelected && Object.values(fundsSelected).length === 0) {
    //@ts-ignore
    const uniqueFunds = [...new Set(data.map((items) => items.fundId))];
    formattedSelectedFunds = await formatSelectedFunds(uniqueFunds);
  }

  let currentDateTime = new Date().toLocaleDateString() + '-' + new Date().toLocaleTimeString();

  if (data[0]?.date) {
    currentDateTime = new Date(data[0]?.date).toLocaleDateString() + '-' + new Date(data[0]?.date).toLocaleTimeString();
  }
  if (data.length <= 1) return;
  let csvString = 'data:text/csv;charset=utf-8';
  for (let item of headers) {
    csvString += ',' + item.header;
  }
  csvString.slice(0, -1);
  csvString += '\n';

  for (let client of data) {
    for (let item of headers) {
      //   if (key.startsWith('__')) {
      //     continue;
      //   }
      if (item.header === 'fund') {
        csvString += formattedSelectedFunds?.[client?.[item?.key]].ticker + ',';
      } else {
        let value = '';
        if (item.format) {
          if (item.format) {
            value = `"${item.format(client)}"`;
          }
        } else {
          value = `"${client[item.key]}"`;
        }
        if (!value) {
          value = '-';
        }
        csvString += value + ',';
      }
    }
    csvString.slice(0, -1);
    csvString += '\n';
  }
  let encodedUri = encodeURI(csvString);
  let link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute(
    'download',
    `tifin-amp-results-${packageName ? packageName : 'IndividualLeads'}-${currentDateTime}.csv`,
  );
  document.body.appendChild(link);
  link.click();
}
