import React, { useState, useEffect } from 'react';
import { styled } from '@mui/styles';
// import ModalComponent from '../../../../Components/Modal';
import FilterHeader from '../../../components/FilterHeader';
import { colors } from '../../../styles';
import ComingSoonModal from '../../../components/ComingSoonModal';
import GeneralTable from '../../../components/Table';
import PageHeader from '../../../components/PageHeader';
import TopRankedCards from '../../../components/TopRankedCards';
import LoadingPage from '../../../components/LoadingPage';
import NurtureModal from '../../../components/NurtureModal';
import ClientModal from '../../../components/ClientModal';
import { useStoreState } from '../../../store';
import AdvisorScoreDistribution from '../../../components/AdvisorScoreDistribution';
import { CardContainer, H2, Row, ErrorTitle, ErrorBody, PrimaryButtonShort } from '../../../components';
import { states, firmAUM, age, firmAccounts, type } from '../../../helpers/menuOptions';
import {
  formatMillionDollar,
  formatErrorTitle,
  formatErrorBody,
  formatDollar,
  formatNumber,
  formatTimestamp,
  formatAdvisorType,
} from '../../../helpers/dataFormatter';
import { formatCharacteristics } from '../../../helpers/dataFormatter';

//@ts-ignore
import ExportModal from '../../../components/ExportModal';
import LoadingModal from '../../../components/LoadingModal';
import DownloadModal from '../../../components/DownloadModal';
import { useNavigate } from 'react-router-dom';

import NurtureIconWhite from '../../../assets/icons/nurture-icon-white.svg';
import PackageStatusCard from '../../../components/PackageStatusCard';

import {
  GET_INFLUENCE_RESULTS,
  GET_JOB_STATUS,
  GET_EXPORT_JOB_STATUS,
  EXPORT_RESULTS,
  RETRY_PROCESSING_JOB,
  GET_RUN_INTEGRATIONS,
  REFRESH_JOURNEY,
  GET_ADVISOR_SEGMENT,
  UPDATE_JOURNEY,
} from '../../../api/driveSales/influenceAPI';
import DataChangeCard from '../../../components/DataChangeCard';
import RecentEventsCard from '../../../components/RecentEventsCard';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';

import cloneDeep from 'lodash/cloneDeep';

const adjustParams = [
  {
    label: 'Age',
    options: age,
    width: '90px',
  },
  {
    label: 'Firm AUM',
    options: firmAUM,
    width: '130px',
  },
  {
    label: 'Firm Accounts',
    options: firmAccounts,
    width: '130px',
  },
  {
    label: 'Type',
    options: type,
    width: '80px',
  },
  {
    label: 'Location',
    options: states,
    width: '80px',
  },
];

const defaultSelectFields = [
  { id: 'age', label: 'Age', unit: 'yr' },
  { id: 'aumSelfReported', label: 'AUM', unit: 'K' },
  { id: 'firmTotalAssetsInMillions', label: 'Firm Size', unit: 'K' },
  { id: 'careerTenure', label: 'Total Years as Advisor', unit: 'yr' },
  { id: 'firmPercentAssetsEtf', label: 'Firm ETF Allocation', unit: 'K' },
  { id: 'firmPercentAssetsMf', label: 'Firm MF Allocation', unit: 'K' },
  { id: 'advisedInvestorsCnt', label: '# Advised Clients', unit: '$' },
];

const ResultsHeader = styled('div')({
  color: colors.grey_03,
  fontSize: 18,
  fontWeight: 500,
  cursor: 'pointer',
  margin: 0,
  padding: '0px 0px 0px 20px',
  backgroundColor: colors.white_00,
  borderBottom: `1px solid ${colors.grey_01}`,
  borderRadius: '5px 5px 0px 0px',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TableContainer = styled('div')({
  margin: '30px 0 0',
});

const Container = styled('div')({
  margin: '0px 0px',
  paddingBottom: 120,
});

const ResultsBox = styled('div')({
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
  margin: '10px 0px 10px',
  backgroundColor: colors.white_01,
  position: 'relative',
  'z-index': 6,
});

const ErrorDiv = styled('div')({
  width: '95%',
  height: 300,
  margin: '10px auto',
  padding: '20px 0px',
  background: '#fff',
  display: 'flex',
  boxShadow: colors.main_box_shadow,
  textAlign: 'center',
});
const PageBody = styled('div')({
  overflowY: 'scroll',
  height: '88%',
  padding: '10px 30px 0px 30px',
});

const ScoreText = styled('span')({
  fontSize: '24px',
  fontWeight: '600',
  lineHeight: '28px',
  letterSpacing: '-1px',
  color: colors.black,
});

const lplTableHeaders = [
  {
    header: 'AMP Score',
    key: 'leadScore',
  },
  {
    header: 'Advisor Name',
    key: 'lastName',
  },
  {
    header: 'LPL Tenure',
    formatData: (data: any) =>
      data?.lpladvisordataSet?.[0]?.advisorLplTenure ? data?.lpladvisordataSet?.[0]?.advisorLplTenure : '-',
  },
  {
    header: 'Assets Under Management',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.totalAssets),
  },
  {
    header: 'AUM Growth (Past Year)',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.trailingTwelveMonthTotalNetNewAssets),
  },
  {
    header: 'Advisor ETF Assets',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.etfAssets),
  },
];

interface Props {
  featureSelections: any;
  setFeatureSelections: any;
  textData: any;
  radarFeatures: any;
  packageRunName: string;
  filterOptions: any;
  setFilterOptions: any;
  generateError: any;
  runId: number;
  journeyId: number;
  setAlgoType: any;
  algoType?: 'Purchase' | 'Redemption';
  setAlgoSettings: any;
  setRunId: any;
  setRunIdArray: any;
}

const LeadResults = ({
  journeyId,
  featureSelections,
  setFeatureSelections,
  textData,

  radarFeatures,

  packageRunName,
  filterOptions,
  setFilterOptions,

  generateError,
  runId,
  setRunIdArray,
  setRunId,

  setAlgoSettings,
  setAlgoType,
  algoType,
}: Props) => {
  // const [data, setData] = useState<any>(null);

  const [topLeads, setTopLeads] = useState<any>([]);
  const [rankedLeads, setRankedLeads] = useState<any>([]);

  const [selectedClient, setSelectedClient] = useState<any>(null);
  // const [selectedFields, setSelectedFields] = useState<any>(0);
  const [exportFilters, setExportFilters] = useState<any>([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState<number>(1);

  const [showTopRanked, setShowTopRanked] = useState(true);
  const [expandedModal, setExpandedModal] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [showNurtureModal, setShowNurtureModal] = useState(false);
  const [nurtureStatus, setNurtureStatus] = useState('start');
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [exportModalPage, setExportModalPage] = useState<string | null>(null);
  const [integrationData, setIntegrationData] = useState<any>(null);
  const [integrationJob, setIntegrationJob] = useState<any>(null);

  const [influenceData, setInfluenceData] = useState<any>(null);

  const [infoPageCopy, setInfoPageCopy] = useState<any>(null);
  const [selectedRun, setSelectedRun] = useState<any>(null);
  const [runOptions, setRunOptions] = useState<any[] | null>([]);

  const [latestRunJob, setLatestRunJob] = useState<any>(null);
  const [showLoadingProgress, setShowLoadingProgress] = useState<boolean>(true);
  const [hideProgressBar, setHideProgressBar] = useState<boolean>(false);

  const [ampScoreDistribution, setAmpScoreDistribution] = useState<any>(null);
  const [redemptionScoreDistribution, setRedemptionScoreDistribution] = useState<any>(null);

  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('-score');
  const [totalItems, setTotalItems] = useState(25);

  const appType = useStoreState((state) => state.appType);

  const subscriptionsList = useStoreState((state) => state.user.subscriptions);
  const influenceSubscription = subscriptionsList?.find((item: any) => item?.package === 'INFLUENCE');

  const userInfo = useStoreState((state) => state.user.info);

  const tableHeaders = [
    {
      header: algoType === 'Redemption' ? 'Redemption Score' : 'AMP Score',
      key: algoType === 'Redemption' ? 'redemptionScore' : 'leadScore',
      formatData: (data: any) => {
        return (
          <ScoreText>
            {algoType === 'Redemption' ? data?.redemptionScore : data?.score}
            {/* <span style={{ fontSize: '12px', fontWeight: '500' }}> %</span> */}
          </ScoreText>
        );
      },
    },
    {
      header: 'Advisor Name',
      key: 'lastName',
    },
    {
      header: 'Firm AUM',
      key: 'firmTotalAssetsInMillions',
      type: 'dollar',
      formatData: (row: { firmTotalAssetsInMillions: any }) => formatMillionDollar(row.firmTotalAssetsInMillions),
    },
    {
      header: 'Firm Accounts',
      key: 'firmTotalAccounts',
      type: 'number',
      formatData: (row: { firmTotalAccounts: any }) => formatNumber(row.firmTotalAccounts),
    },
    {
      header: 'Type',
      key: 'advisorType',
      formatData: (row: any) => formatAdvisorType(row),
    },
  ];

  useEffect(() => {
    setError(generateError);
    setLoading(false);
  }, [generateError]);

  useEffect(() => {
    setPage(0);
  }, [pageSize]);

  useEffect(() => {
    if (algoType && algoType === 'Redemption') {
      const newFilterOptions = {
        has_redemption_results: {
          key: 'has_redemption_results',
          value: [{ key: 'has_redemption_results', value: 'True' }],
        },
      };
      // const newFilterOptions = {};
      setFilterOptions(newFilterOptions);
      setSortKey('-redemption_score');
    } else {
      const newFilterOptions = {};
      setFilterOptions(newFilterOptions);
      setSortKey('-score');
    }
  }, [algoType]);

  const debounce = (func: any, delay: number) => {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    return (...args: any) => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  };

  const closeModal = () => {
    setExportModalPage(null);
  };

  const expanderFunc = (card: React.SetStateAction<undefined>) => {
    setSelectedClient(card);
    setExpandedModal(true);
  };

  const refreshData = () => {
    // setRefresh(!refresh);
  };

  const {
    data: jobData,
    loading: jobLoading,
    refetch: refetchStatus,
    startPolling: startPollingJobData,
    stopPolling: stopPollingJobData,
  } = useQuery(GET_JOB_STATUS, {
    skip: !runId || latestRunJob?.status === 'COMPLETED' || latestRunJob?.status === 'FAILED',
    variables: { runId },
    pollInterval: 1000,
    notifyOnNetworkStatusChange: true,
    onCompleted: (jobData) => {
      // console.log('jobData: ', jobData);
      const latestJob = jobData?.influence?.results?.journey?.runhistorySet.filter(
        (job: any) => Number(job?.id) === runId,
      )?.[0]?.latestJob;

      const runOptions = jobData?.influence?.results?.journey?.runhistorySet.map((run: any) => run?.id)?.reverse();
      setRunIdArray(runOptions);
      const newRuns = jobData?.influence?.results?.journey?.runhistorySet ?? [];
      const newRunOptions = [...newRuns]
        //@ts-ignore
        ?.sort((a: any, b: any) => new Date(b?.dateRun) - new Date(a?.dateRun))
        ?.map((run: any) => ({
          label: formatTimestamp(run?.dateRun),
          value: run?.id,
        }));
      const newSelectedRun = newRunOptions?.find((item: any) => Number(item.value) === runId);
      setSelectedRun(newSelectedRun);
      setRunOptions(newRunOptions);

      setIntegrationData(jobData?.influence?.results?.journey?.integration);

      if (latestJob?.status === 'COMPLETED') {
        getInfluenceResults();
        stopPollingJobData();
      } else if (latestJob?.status === 'FAILED') {
        setError('Processing job failed.');
        setShowLoadingProgress(false);
        stopPollingJobData();
      }
    },
    onError: (error) => {
      setError(error?.message);
      setLoading(false);
    },
  });

  useEffect(() => {
    startPollingJobData(1000);
  }, [runId]);


  const [getInfluenceResults, { data: influenceResults, loading: loadingResults, refetch: refetchResults }] =
    useLazyQuery(GET_INFLUENCE_RESULTS, {
      // skip: !runId || jobLoading || !latestRunJob || latestRunJob?.status !== 'COMPLETED',
      fetchPolicy: 'no-cache',
      variables: { runId, page: page + 1, pageSize, sortKey, query: searchString, filters: [] },
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setError(null);
        setLoadingProgress(100);
        setTimeout(() => {
          setShowLoadingProgress(false);
        }, 1000);
        const formattedLeads = cloneDeep(data?.influence?.results?.advisors?.list) ?? [];

        const newAlgoSettings = {
          Purchase: formattedLeads?.length === 0 ? true : !!formattedLeads?.[0]?.hasPurchaseResults,
          Redemption: !!formattedLeads?.[0]?.hasRedemptionResults,
        };
        setAlgoType(
          algoType && newAlgoSettings?.[algoType] ? algoType : algoType === 'Purchase' ? 'Redemption' : 'Purchase',
        );
        setAlgoSettings(newAlgoSettings);
        setTotalItems(data?.influence?.results?.advisors?.totalItems ?? 0);
        setTopLeads(formattedLeads.slice(0, 3));
        setRankedLeads(formattedLeads.slice(3));
        setInfluenceData({ ...data?.influence?.results, id: runId });
        setAmpScoreDistribution(data?.influence?.results?.ampScoresHistogram);
        setRedemptionScoreDistribution(data?.influence?.results?.redemptionScoresHistogram);
        setIntegrationData(data?.influence?.results?.journey?.integration);
      },
      onError: (error) => {
        setError(error?.message);
      },
    });

  const {
    data: tableData,
    loading: tableLoading,
    refetch: refetchTableData,
  } = useQuery(GET_INFLUENCE_RESULTS, {
    variables: { runId, page: page + 1, pageSize, sortKey, query: searchString, filters: [] },
    skip: jobLoading || !runId || !latestRunJob || latestRunJob?.status !== 'COMPLETED',
    onCompleted: (data) => {
      const formattedAdvisors = data?.influence?.results?.advisors?.list ?? [];

      if (page === 0) {
        setRankedLeads(formattedAdvisors.slice(3));
      } else {
        setRankedLeads(formattedAdvisors);
      }
      const newTotalItems = data?.influence?.results?.advisors?.totalItems;
      if (!!newTotalItems) {
        setTotalItems(newTotalItems);
      }
      // setTableLoading(false);
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  const [downloadAllLeads, { loading: loadingCSV }] = useMutation(EXPORT_RESULTS, {
    variables: { runId: String(runId) },
    onCompleted: (data) => {
      // const response = await influenceAPI.exportResults(runId);
      if (data?.influenceResultsExport?.url) {
        window.open(data?.influenceResultsExport?.url, '_blank');
      }
    },
    onError: (error) => {
      setError(error?.message);
    },
  });

  const cardProps = {
    modalOpen,
    setModalOpen,
    contentType: appType === 'LPL' ? 'lplAdvisorData' : 'advisorData',
    getRadarChartData: () => { },
    close: () => setSelectedClient(undefined),
    runId,
    radarFeatures,
    textData,
    userData: userInfo,
  };

  const excludedCharacteristics = Object.entries(featureSelections)
    .filter((char: any) => char[1] === false)
    .map((char: any) => formatCharacteristics(char[0]));

  excludedCharacteristics[excludedCharacteristics?.length - 1] =
    ' and ' + excludedCharacteristics[excludedCharacteristics?.length - 1];

  const formattedEvents: any = {
    email_opened: 'Opened Email',
    bought: 'Fund Purchased',
    sold: 'Fund Sold',
    email_delivered: 'Email Delivered',
    email_clicked: 'Email Clicked',
    email_unsubscribe: 'Email Unsubscribed',
    purchased_fund: 'Fund Purchased',
  };

  const updateRunId = (runOption: any) => {
    setLoadingProgress(1);
    setError(null);
    setRunId(Number(runOption?.value));
    setSelectedRun(runOption);
  };

  const [recalculateData, { loading: recalculateLoading }] = useMutation(REFRESH_JOURNEY, {
    onCompleted: (response) => {
      setRunId(response?.influenceRefreshJourney?.run?.id);
    },
  });

  const [retryProcessingJob, { loading: retryLoading }] = useMutation(RETRY_PROCESSING_JOB, {
    variables: { runId: String(runId) },
    onCompleted: (response) => {
      setRunId(response?.influenceRetryRun?.run?.id);
    },
  });

  const exportSegment = async (filters: any) => {
    setExportFilters(filters);
    setExportModalPage('ExportStatusView');
  };

  const [getRunIntegrations, { data: integrationDetails, loading: integrationLoading }] = useLazyQuery(
    GET_RUN_INTEGRATIONS,
    {
      onCompleted: (response) => {
        setIntegrationData(response?.influence?.results?.journey?.integration);
        const job = response?.influence?.results?.exportJob;
        if (!!job) {
          if (job?.status === 'COMPLETED') {
            setIntegrationJob(job);
            if (!!exportModalPage) {
              setExportModalPage('InfoView');
              setInfoPageCopy({
                header: 'Export Complete',
                subheader: 'Your results have been successfully exported to your CRM',
                loader: false,
              });
              setTimeout(() => {
                setExportModalPage(null);
              }, 2000);
            }
          } else if (job?.status === 'FAILED') {
            setIntegrationJob(job);
            if (!!exportModalPage) {
              setExportModalPage('InfoView');
              setInfoPageCopy({
                header: 'Export Failed',
                subheader: JSON.stringify(job?.result),
                loader: false,
              });
            }
          } else if (!!job?.jobId) {
            setIntegrationJob(job);
            setTimeout(() => {
              // getJobStatus();
            }, 3000);
          }
        } else {
          // setTimeout(() => {
          //   getJobStatus();
          // }, 3000);
        }
      },
      onError: (error) => {
        console.log('error getting integration details: ', error);
        setIntegrationData({ status: 'Error', progress: 0 });
      },
    },
  );

  // console.log('campaign deets: ', runId, journeyId);
  // console.log('loading progress: ', {
  //   loadingProgress,
  //   showLoadingProgress,
  //   hideProgressBar,
  //   loadingAndHideProgress: showLoadingProgress && !hideProgressBar,
  // });

  return (
    <>
      {(loading || (!runId && !generateError)) && jobLoading && !(loadingProgress < 100 && loadingProgress > 0) ? (
        <LoadingPage />
      ) : (
        <Container>
          <div>
            <PageHeader
              title={appType === 'LPL' ? `Your best LPL leads for` : 'Your top advisors on '}
              runOptions={runOptions}
              selectedRun={selectedRun}
              setSelectedRun={updateRunId}
              rightButtons={
                appType === 'LPL'
                  ? [
                    {
                      modalText: 'Export',
                      type: 'light',
                      action: () => setShowDownloadModal(true),
                    },
                    {
                      modalText: 'Adjust Feedback Loop',
                      type: 'light',
                      action: () => setShowFeedbackModal(true),
                    },
                    {
                      modalText: (
                        <div style={{ display: 'flex', flexDirection: 'row', height: '16px' }}>
                          <img
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                            src={NurtureIconWhite}
                            alt={'Nurture'}
                          />
                          {nurtureStatus === 'results' ? 'View Nurture Results' : 'Nurture'}
                        </div>
                      ),
                      icon: NurtureIconWhite,
                      type: 'bold',
                      action: () => setShowNurtureModal(true),
                    },
                  ]
                  : [
                    {
                      modalText: 'Export',
                      type: 'light',
                      action: () => setShowDownloadModal(true),
                    },
                    {
                      modalText: 'Adjust Feedback Loop',
                      type: 'light',
                      action: () => setShowFeedbackModal(true),
                    },
                    {
                      modalText: (
                        <div style={{ display: 'flex', flexDirection: 'row', height: '16px' }}>
                          <img
                            style={{ height: '16px', width: '16px', marginRight: '5px' }}
                            src={NurtureIconWhite}
                            alt={'Nurture'}
                          />
                          {nurtureStatus === 'results' ? 'View Nurture Results' : 'Nurture'}
                        </div>
                      ),
                      icon: NurtureIconWhite,
                      type: 'bold',
                      action: () => setShowNurtureModal(true),
                    },
                  ]
              }
            />
            <FilterHeader
              featureSelections={featureSelections}
              setFeatureSelections={setFeatureSelections}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              options={defaultSelectFields}
              adjustParams={adjustParams}
            />
          </div>
          {error ? (
            <PageBody>
              <ErrorDiv>
                <div style={{ margin: 'auto', width: '100%', padding: '36px' }}>
                  <ErrorTitle>{formatErrorTitle(error)}</ErrorTitle>
                  <ErrorBody>{formatErrorBody(error)}</ErrorBody>
                  {error?.includes('Processing job failed.') && (
                    <PrimaryButtonShort onClick={() => retryProcessingJob()}>Retry Run</PrimaryButtonShort>
                  )}
                </div>
              </ErrorDiv>
            </PageBody>
          ) : showLoadingProgress && !hideProgressBar ? (
            <PageBody>
              <PackageStatusCard
                returnAction={() => navigate('/drive-sales/influence/home')}
                manualPercent={loadingProgress}
                setManualPercent={setLoadingProgress}
              />
            </PageBody>
          ) : (
            <PageBody id={'page-body'}>
              <CardContainer>
                <ResultsHeader>
                  <H2 style={{ color: colors.grey_03 }}>Package Status</H2>
                </ResultsHeader>
                <Row style={{ padding: '20px 10px 0px' }}>
                  <AdvisorScoreDistribution
                    runId={runId}
                    data={algoType === 'Redemption' ? redemptionScoreDistribution : ampScoreDistribution}
                    totalAdvisors={influenceData?.advisors?.totalItems}
                  />
                  <RecentEventsCard
                    influenceData={influenceData}
                    recalculateData={recalculateData}
                    formattedEvents={formattedEvents}
                    exportSegment={exportSegment}
                    packageRunName={packageRunName}
                    filters={filterOptions}
                    packageName={'INFLUENCE'}
                    //@ts-ignore
                    GET_SEGMENT_QUERY={GET_ADVISOR_SEGMENT}
                    integrationDetails={integrationData}
                    integrationJob={integrationJob}
                    setExportModalPage={setExportModalPage}
                    getSegment={() => { }}
                  />
                </Row>
              </CardContainer>
              <DataChangeCard data={influenceData} />
              <ResultsBox id={'client-card-container'}>
                <ResultsHeader
                  style={{ borderBottom: showTopRanked ? '1px solid #e3e5e4' : 0 }}
                  onClick={() => setShowTopRanked(!showTopRanked)}
                >
                  <H2 style={{ margin: 0 }}>Top Advisors</H2>
                </ResultsHeader>
                <Row style={{ justifyContent: 'space-evenly' }} id="theranktabletoscrollto">
                  {showTopRanked ? (
                    <TopRankedCards
                      expanderFunc={expanderFunc}
                      cardData={topLeads}
                      cardProps={cardProps}
                      selectedClient={selectedClient}
                      algoType={algoType}
                    />
                  ) : null}
                  {selectedClient !== undefined && (
                    <ClientModal
                      packageName={'advisorLeads'}
                      client={{
                        ...selectedClient,
                        leadScore:
                          selectedClient?.leadScore || selectedClient?.leadScore === 0 ? selectedClient?.leadScore : '',
                      }}
                      expandedModal={expandedModal}
                      isDemoData={influenceSubscription?.isDemo}
                      {...cardProps}
                    />
                  )}
                </Row>
              </ResultsBox>
              <TableContainer>
                <CardContainer>
                  <GeneralTable
                    hideShadow={true}
                    headerData={appType === 'LPL' ? lplTableHeaders : tableHeaders}
                    data={rankedLeads}
                    search
                    dataControls={{
                      selections: [],
                      setSelections: [],
                      searchFunc: setSearchString,
                      searchString,
                    }}
                    paginationControls={{
                      tablePage: page,
                      setTablePage: setPage,
                      pageSize,
                      setPageSize,
                      sortKey,
                      setSortKey,
                      totalItems,
                    }}
                    onRowPress={expanderFunc}
                    newItems={[]}
                    loading={tableLoading || loading || loadingResults}
                  />
                </CardContainer>
                <div style={{ height: '60px' }} />
              </TableContainer>
              <ComingSoonModal modalStatus={showComingSoon} closeModal={() => setShowComingSoon(false)} />
              <DownloadModal
                modalInfo={{
                  header: 'Export',
                  body: 'Please select one of the following options',
                  actionExport: () => {
                    setExportModalPage('ExportStatusView');
                    setShowDownloadModal(false);
                  },
                  actionDownload: () => {
                    downloadAllLeads();
                    setShowDownloadModal(false);
                  },
                  actionText: '',
                }}
                modalStatus={showDownloadModal}
                closeModal={() => setShowDownloadModal(!showDownloadModal)}
              />
              <LoadingModal
                modalStatus={loadingCSV}
                closeModal={() => console.log('hide loading modal!')}
                headerText={'Download all your leads'}
                subtext={'This may take a few moments...'}
                buttonText={'Cancel'}
              />
              <ExportModal
                //@ts-ignore
                UPDATE_JOURNEY_QUERY={UPDATE_JOURNEY}
                GET_EXPORT_STATUS={GET_EXPORT_JOB_STATUS}
                exportFilters={exportFilters}
                modalPage={exportModalPage}
                setModalPage={setExportModalPage}
                closeModal={closeModal}
                journeyId={journeyId}
                runId={runId}
                integrationDetails={integrationData}
                integrationJob={integrationJob}
                getJobStatus={refetchResults}
                packageName={'INFLUENCE'}
                setIntegrationJob={setIntegrationJob}
                infoPageCopy={infoPageCopy}
                setInfoPageCopy={setInfoPageCopy}
                exportCount={totalItems}
              />
            </PageBody>
          )}
        </Container>
      )}
      <NurtureModal
        runId={runId}
        packageName={'INFLUENCE'}
        modalStatus={showNurtureModal}
        closeModal={() => setShowNurtureModal(!showNurtureModal)}
        showComingSoon={() => setShowComingSoon(true)}
        refreshData={refreshData}
        leads={rankedLeads ? rankedLeads : []}
        nurtureStatus={nurtureStatus}
        setNurtureStatus={setNurtureStatus}
        cardProps={cardProps}
        setExportModalPage={setExportModalPage}
      />
    </>
  );
};

export default LeadResults;
