import React from 'react';
import { useState, useEffect } from 'react';
import { PackageContainer } from '../../../components/PackageContainer';
import { H2 } from '../../../components';
import { PackageHome } from '../../../components/PackageHome';
import { styled } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from '../../../store';
import { GET_INFLUENCE_ARCHIVES, CREATE_JOURNEY, GET_ADVISOR_SET_SUMMARY } from '../../../api/driveSales/influenceAPI';
import { colors, typography } from '../../../styles';
import { formatTimestamp } from '../../../helpers/dataFormatter';
import { PackageSelectAdvisorSet } from '../../../components/PackageSelectAdvisorSet/PackageSelectAdvisorSet';
import { PackageCustomizePage } from '../../../components/PackageCustomizePage/PackageCustomizePage';
import Signals from './Signals';
import InfoModal from '../../../components/InfoModal';

import SelectIcon from '../../../assets/icons/select.svg';
import CustomizeIcon from '../../../assets/icons/customize.svg';
import DiscoverIcon from '../../../assets/icons/discover.svg';
import moment from 'moment';
import PackageUsageCard from '../../../components/PackageUsageCard';
import { useMutation, useQuery } from '@apollo/client';

const headerOptions = {
  standard: [
    { title: 'Advisor Characteristics', id: 'advisor' },
    { title: 'Advisor-Client Characteristics', id: 'advisedClients' },
  ],
  leadPool: [{ title: 'Screen Your Universe of Leads', id: 'advisorLeadPool' }],
};

const lplHeaderOptions = {
  standard: [{ title: 'Advisor Characteristics', id: 'lplAdvisor' }],
  leadPool: [{ title: 'Screen Your Universe of Leads', id: 'advisorLeadPool' }],
};

const ActiveChip = styled('div')({
  height: '16px',
  width: '60px',
  borderRadius: '5px',
  color: colors.white,
  background: colors.green,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...typography.mainFont,
  fontWeight: 700,
  fontSize: '10px',
});

const archiveTableHeaders = [
  {
    header: 'Journey Name',
    key: 'customName',
    formatData: (row: any) => {
      return <H2 style={{ margin: '10px 0px' }}>{row.customName ? row.customName : '-'}</H2>;
    },
    //bold: true,
  },
  {
    header: 'Date Created',
    key: 'dateTime',
    formatData: (row: any) => {
      return formatTimestamp(row.dateCreated);
    },
    //bold: true,
  },
  {
    header: '# of Prospects',
    key: 'advisorLeads',
    formatData: (row: any) => (row?.runhistorySet?.[0]?.serveCount ? row?.runhistorySet?.[0]?.serveCount : '-'),
  },
  {
    header: 'Source',
    key: '',
    formatData: (row: any) => 'CSV',
  },
  {
    header: 'Date Updated',
    key: 'dateUpdated',
    formatData: (row: any) => {
      return formatTimestamp(row.dateUpdated);
    },
  },
  {
    header: 'Status',
    key: 'isActive',
    formatData: (row: any) => {
      return row.isActive ? (
        <ActiveChip>Active</ActiveChip>
      ) : (
        <ActiveChip style={{ background: colors.red }}>Inactive</ActiveChip>
      );
    },
  },
  {
    header: 'Progress',
    key: 'progress',
    formatData: (row: any) => {
      return;
    },
    //bold: true,
  },
];

type AlgoType = 'Purchase' | 'Redemption';

type Props = {};

export const InfluenceProspects = (props: Props) => {
  const basePath = '';
  const title = 'Influence Existing Prospects';
  const navigate = useNavigate();
  const [firstTime, setFirstTime] = useState(false);
  const [generateError, setGenerateError] = useState<string | null>(null);
  const [advisorFilters, setAdvisorFilters] = useState({} as any);

  const [algoSettings, setAlgoSettings] = useState({ Purchase: true, Redemption: true });

  const [packageUsage, setPackageUsage] = useState({ usage: 0, total: 0 });
  const [newJourneyName, setNewJourneyName] = useState<string>('Unnamed Journey');
  const [algoType, setAlgoType] = useState<AlgoType>('Purchase');

  const subscriptionsList: any = useStoreState((state) => state.user.subscriptions);
  const influenceSubscription = subscriptionsList?.find((item: any) => item?.package === 'INFLUENCE');

  const [showInfoModal, setShowInfoModal] = useState(false);

  const {
    progress,
    progressTitles,
    advisorSetSelected,
    filterOptions,
    characteristics,
    runId,
    packageRunName,
    runIdArray,
    journeyId,
    dateRun,
  } = useStoreState((state) => {
    return state.driveSales.influenceProspects;
  });

  const appType = useStoreState((state) => state.appType);

  const {
    setProgress,
    setProgressTitles,
    setAdvisorSetSelected,
    setFilterOptions,
    setCharacteristics,
    setPackageRunName,
    setDateRun,
    setRunIdArray,
    setJourneyId,
    setRunId,
    loadArchive,
  } = useStoreActions((actions) => {
    return actions.driveSales.influenceProspects;
  });

  useEffect(() => {
    setProgress(0);
    // getArchives(10, 0);
  }, []);

  const { data, loading, error, refetch } = useQuery(GET_INFLUENCE_ARCHIVES, {
    variables: { pageSize: 10, page: 1, filters: [{ key: 'package', value: 'influence' }] },
    onCompleted: (data) => {
      const newPackageUsage = {
        usage: data?.influence?.quotaTotal ?? 0,
        total: (data?.influence?.quotaTotal ?? 0) - (data?.influence?.quotaUsed ?? 0),
        percent: ((data?.influence?.quotaUsed ?? 0) / (data?.influence?.quotaTotal ?? 0)) * 100,
      };

      setPackageUsage(newPackageUsage);
    },
  });

  const openArchive = async (Archive: any) => {
    loadArchive(Archive);
    navigate('signals');
  };

  const [generateInfluenceJourney] = useMutation(CREATE_JOURNEY, {
    onCompleted: (data) => {
      if (data?.influenceCreateJourney?.journey) {
        console.log('data in grenerateInfluenceJourneyr: ', data);
        setPackageRunName(newJourneyName);
        setDateRun(moment().format());
        setRunIdArray([data?.influenceCreateJourney?.journey?.latestRun]);
        setRunId(Number(data?.influenceCreateJourney?.journey?.latestRun?.id));
        setJourneyId(Number(data?.influenceCreateJourney?.journey?.id));
      } else if (data?.influenceCreateJourney?.code === 'INVALID_CONFIGURATION') {
        setGenerateError(
          'Invalid configuration. Please contact support to make sure your organization environment is properly configured.',
        );
      } else if (data?.influenceCreateJourney?.code === 'INSUFFICIENT_QUOTA') {
        setGenerateError(
          'Insufficient quota. Please contact support to increase your quota or reduce the number of leads you are requesting.',
        );
      } else {
        setGenerateError('An unknown error occured. Please try again or contact support if the issue persists.');
      }
    },
    onError: (error) => {
      setGenerateError(
        `An unknown error occured. Please try again or contact support if the issue persists.(${error.message})`,
      );
    },
  });

  const generateResults = async () => {
    setGenerateError(null);
    setRunId(null);

    try {
      if (influenceSubscription?.isDemo) {
        const selectedAdvisorSet = Object.keys(advisorSetSelected).map((advisorSetId: string) => Number(advisorSetId));
        console.log('selectedAdvisorSet: ', selectedAdvisorSet);
        generateInfluenceJourney({
          variables: {
            advisorSetIds: selectedAdvisorSet,
            journeyName: newJourneyName,
            usePurchaseAlgorithm: algoSettings['Purchase'],
            useRedemptionAlgorithm: algoSettings['Redemption'],
          },
        });
      }
    } catch (error: any) {
      console.warn('error in generate results: ', error);
      setGenerateError(error.message);
    }
  };

  const PackageSteps = [
    {
      label: 'Home',
      link: 'home',
      Component: PackageHome,
      props: {
        name: title,
        description: `This package enriches your existing prospects with unique intelligence to improve your sales opportunities.`,
        stepDescriptions: [
          {
            icon: SelectIcon,
            name: 'Select advisor set',
            description: `which you’d like to influence`,
          },
          {
            icon: CustomizeIcon,
            name: 'Customize',
            description: 'characteristics for the algorithm',
          },
          {
            icon: DiscoverIcon,
            name: 'Discover',
            description: 'the most promising advisors',
          },
        ],
        packageStats: [
          { label: 'Completed Journeys', data: '527' },
          { label: 'Total Prospects', data: '2,701' },
        ],
        CustomStatComponent: () => (
          <PackageUsageCard
            index={0}
            label={'Influence Usage'}
            data={{ ...packageUsage, usageLabel: 'Prospects Total', totalLabel: 'Prospects Remaining' }}
          />
        ),
        nextStep: () => {
          navigate('advisors');
          setAdvisorSetSelected({});
          setCharacteristics({});
          setFilterOptions({});
          setAdvisorFilters({});
          setRunId(null);
          setPackageRunName(null);
          setDateRun(null);
          setProgressTitles(['Current', ' ', ' ']);
          setProgress(1);
          setNewJourneyName('Unnamed Journey');
        },
        openArchive,
        GET_ARCHIVE_QUERY: GET_INFLUENCE_ARCHIVES,
        tableHeaders: archiveTableHeaders,
      },
    },
    {
      label: 'Select Advisor Set',
      link: 'advisors',
      Component: PackageSelectAdvisorSet,
      props: {
        advisorSetSelected,
        setAdvisorSetSelected,
        setNewJourneyName,
        pageCopy: {
          header: 'Create an advisor set you would like to influence',
          subheader: 'The set will be analyzed and enriched to give you greater insights into these advisors.',
        },
        packageUsage: {
          usage: Object.keys(advisorSetSelected)?.length,
          usageLabel: 'Advisors Selected',
          total: packageUsage?.total,
          totalLabel: 'Prospects Remaining',
        },
        nextAction: () => {
          setProgressTitles(['Complete', 'Current', '']);
          setProgress(2);
          navigate('custom-criteria');
        },
      },
    },
    {
      label: 'Customize',
      link: 'custom-criteria',
      Component: PackageCustomizePage,
      props: {
        nextAction: async () => {
          if (true) {
            setProgressTitles(['Complete', 'Complete', 'Complete']);
            setProgress(3);
            setFirstTime(true);
            generateResults();
            navigate('signals');
          } else {
            setShowInfoModal(true);
            navigate('home');
          }
        },
        backAction: () => setProgress(1),
        advisorSetSelected,
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        // getAdvisorSetSummary: influenceAPI.getAdvisorSetSummary,
        GET_SUMMARY_QUERY: GET_ADVISOR_SET_SUMMARY,
        advisorFilters,
        setAdvisorFilters,
        algoSettings,
        setAlgoSettings,
        demoMode: influenceSubscription?.isDemo,
        headerOptions: appType === 'LPL' ? lplHeaderOptions : headerOptions,
      },
    },
    {
      label: 'Signals',
      link: 'signals',
      Component: Signals,
      props: {
        textData: {
          radarHeading:
            appType === 'LPL' ? (
              <span>
                {'Advisor Pool Characteristics vs Lead Characteristics'}{' '}
                <span style={{ fontSize: '12px', color: colors.grey_02 }}>(Over Past 3 Months)</span>
              </span>
            ) : (
              'Fund Characteristics vs. Advisor Interest'
            ),
          allChartHeading: 'All Advisors',
          bestChartHeading: 'Best Advisors',
          themesHeading: 'Your Fund Themes',
          topTitle: 'Top Advisor',
          //metricName: 'Individual',
          distillExplanation:
            ' is the result of a thematic and financial analysis of each Advisor. The higher the score, the stronger their financials, and the more their thematic activity is aligned with your fund.',
        },
        progress,
        selections: advisorSetSelected,
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        algoType,
        setAlgoType,
        filterOptions,
        setFilterOptions,
        generateError,
        firstTime,
        setFirstTime,
        setRunIdArray,
        packageRunName,
        runId,
        setRunId,
        journeyId,
        runsArray: runIdArray,
        radarFeatures: [
          { label: 'All Advisors', id: 'all_advisors' },
          { label: 'Selected Advisor', id: 'advisor' },
        ],
        algoSettings,
        setAlgoSettings,
      },
    },
  ];

  const navSteps = {
    0: 'home',
    1: 'advisors',
    2: 'custom-criteria',
    3: 'signals',
  };

  return (
    <>
      <PackageContainer
        journeyId={journeyId}
        runId={runId}
        algoType={algoType}
        setAlgoType={setAlgoType}
        algoSettings={algoSettings}
        packageRunName={packageRunName}
        setPackageRunName={setPackageRunName}
        dateRun={dateRun}
        basePath={basePath}
        title={title}
        PackageSteps={PackageSteps}
        progress={progress}
        progressTitles={progressTitles}
        setProgress={setProgress}
        navSteps={navSteps}
      />
      <InfoModal
        modalStatus={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        modalCopy={{
          header: 'Information',
          subheader: `To ensure the highest quality results. One of our data scientist will work with you to create a bespoke set of results for your use case. Please contact your support representative to learn more.`,
        }}
      />
    </>
  );
};
