import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../styles';
import { Modal, IconButton } from '@mui/material';
import { useStoreState, useStoreActions } from '../../store';
import Loader from '../Loader';
import ResultsView from './ResultsView';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton, PrimaryButtonShort } from '..';

import harborTestEmail from '../../assets/visuals/emails/test-email.png';
import DemoOrgTestEmail from '../../assets/visuals/emails/nurture-email-demo-org.png';
import ABTestEmail from '../../assets/visuals/emails/nurture-email-AB.png';

import closeIcon from '../../assets/icons/close.svg';
import nurtureIcon from '../../assets/icons/nurture-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    overflow: 'hidden',
    background: colors.white_01,
    borderRadius: '8px',
  },
  secondaryButton: {
    margin: '-20px 10px 0px 10px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: '2px 20px',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  modalTitle: {
    ...typography.mainFont,
    fontSize: '16px',
    color: colors.black_01,
    fontWeight: '500',
    cursor: 'pointer',
  },
  titleIcon: {
    height: '20px',
    width: '20px',
    margin: '0px 7px -5px 0px',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    height: '456px',
    width: '1100px',
  },
  emailContentContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: colors.black_01,
    height: '458px',
  },
  emailContainer: {
    height: '337px',
    width: '306px',
    background: colors.grey_01,
  },
  infoContentContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  infoHeader: {
    ...typography.mainFont,
    fontSize: '18px',
    color: colors.black_01,
    fontWeight: '500',
  },
  infoBody: {
    ...typography.mainFont,
    fontSize: '14px',
    color: colors.grey_02,
    width: '280px',
    textAlign: 'center',
    fontWeight: '400',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '12px 23px',
    borderTop: `1px solid ${colors.grey_01}`,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    // overflowY: 'scroll',
    marginTop: '30px',
  },
  handIcon: {
    height: '20px',
  },
}));

interface Props {
  runId: number;
  packageName: string;
  modalStatus: boolean;
  closeModal: any;
  nurtureStatus: any;
  setNurtureStatus: any;
  leads: any;
  isWealthManager?: any;
  cardProps: any;
  refreshData: any;
  showComingSoon: any;
  setExportModalPage: any;
}

const NurtureModal = ({
  runId,
  packageName,
  modalStatus,
  closeModal,
  nurtureStatus,
  setNurtureStatus,
  leads,
  isWealthManager,
  cardProps,
  refreshData,
  showComingSoon,
  setExportModalPage,
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const navigate = useNavigate();

  const organization: any = useStoreState((state) => state.user.organization);

  const { setRunId, setProgress, setProgressTitles, setCampaignInfo } = useStoreActions(
    (actions) => actions.nurtureProspects.createCampaign,
  );

  const exit = () => {
    closeModal();
    setSelectedLead(null);
    setLoading(false);
  };

  const createNurtureCampaign = async () => {
    setLoading(true);
    try {
      setProgress(1);
      setProgressTitles(['']);
      setRunId(runId);
      setCampaignInfo({ name: 'Unnamed Campaign', description: 'A new nurture campaign ' });

      navigate('/drive-sales/create-campaign/home');
      setLoading(false);
      setNurtureStatus('started');
    } catch (error: any) {
      if (error.message === 'GraphQL error: You have not purchased this package.') {
        setNurtureStatus('subscribe');
      }
      console.warn('Error in create nurture campaign: ', error.message);
      setLoading(false);
    }
    // setNurtureStatus('started');
  };

  const exportNurtureLeads = async () => {
    setExportModalPage('NurtureIntegrations');
    closeModal();
  };

  return (
    <Modal
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <div
        className={classes.modalContainer}
        style={{ minHeight: nurtureStatus === 'results' ? '0' : '580px', maxHeight: '680px' }}
      >
        <div className={classes.modalHeader}>
          <div style={{ display: 'flex' }}>
            <p className={classes.modalTitle} onClick={() => setSelectedLead(null)}>
              <img src={nurtureIcon} className={classes.titleIcon} alt="nurture" />
              Nurture{nurtureStatus === 'results' && ' Results'}
            </p>
          </div>
          <IconButton onClick={exit} id={'close-nurture-modal'}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
        {nurtureStatus === 'start' ? (
          <>
            <div className={classes.modalBody} style={{ height: '456px', width: '1100px' }}>
              <div className={classes.emailContentContainer}>
                <img
                  className={classes.emailContainer}
                  src={
                    organization?.name === 'Harbor Capital'
                      ? harborTestEmail
                      : organization?.name === 'Alliance Bernstein'
                      ? ABTestEmail
                      : DemoOrgTestEmail
                  }
                  alt="email"
                />
              </div>
              <div className={classes.infoContentContainer}>
                <img src={nurtureIcon} alt="info" />
                <h1 className={classes.infoHeader}>How Nurturing Works</h1>
                <p className={classes.infoBody}>
                  Engage these leads with co-branded emails through a data-driven email campaign driving toward a lead
                  asking to schedule a meeting with your sales team.
                </p>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <PrimaryButtonShort onClick={exportNurtureLeads} style={{ margin: '0px 16px' }}>
                {loading ? <Loader /> : <span>Export Leads to ESP</span>}
              </PrimaryButtonShort>
              <PrimaryButtonShort onClick={createNurtureCampaign}>
                {loading ? <Loader /> : <span>Begin Nurturing these Leads</span>}
              </PrimaryButtonShort>
            </div>
          </>
        ) : nurtureStatus === 'results' ? (
          <ResultsView
            runId={runId}
            leads={leads}
            paginationControls={null}
            cardProps={cardProps}
            closeModal={closeModal}
            showComingSoon={showComingSoon}
            selectedLead={selectedLead}
            setSelectedLead={setSelectedLead}
          />
        ) : nurtureStatus === 'subscribe' ? (
          <>
            <div className={classes.modalBody}>
              <div className={classes.infoContentContainer} style={{ width: '1100px' }}>
                <h1 className={classes.infoHeader}>You are not yet subscribed to Nurture!</h1>
                <p className={classes.infoBody}>Please contact support to get started with this feature.</p>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <PrimaryButton
                onClick={() => {
                  closeModal();
                }}
              >
                Ok
              </PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <div className={classes.modalBody}>
              <div className={classes.infoContentContainer} style={{ width: '1100px' }}>
                <h1 className={classes.infoHeader}>You're all set!</h1>
                <p className={classes.infoBody}>
                  We will take it from here. To view the results of your Nurture campaign going forward, visit any
                  archived results page and click View Nurture Results in the top right corner.
                </p>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <PrimaryButtonShort
                onClick={() => {
                  refreshData();
                  setNurtureStatus('results');
                  closeModal();
                }}
              >
                Ok, got it!
              </PrimaryButtonShort>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default NurtureModal;
