import React, { useState, useEffect } from 'react';
// import ContentModels from '../ClientCard/ContentModels';
import { colors } from '../../styles';
import Loader from '../Loader';
import { styled } from '@mui/material/styles';
import { formatRadarData, formatRadarDataWealth } from '../../helpers/dataFormatter';

import { Row } from '..';
import ComingSoonModal from '../ComingSoonModal';
import Advisor360 from '../Advisor360';
import ClientCard from '../ClientCard';
import OldClientCard from '../OldClientCard';

const nameMetrics = (input: any) => {
  for (let obj in input) {
    let metricName = input[obj].name;
    if (!metricName) {
      metricName = input[obj].metric;
    }
    switch (metricName) {
      case 'age':
        input[obj].name = 'Age';
        break;
      case 'income':
        input[obj].name = 'Income';
        break;
      case 'home_value':
        input[obj].name = 'Home Value';
        break;
      case 'estimated_investable_assets':
        input[obj].name = 'Assets';
        break;
      case 'percent_of_home_value_paid_off':
        input[obj].name = 'Mortgage Paid';
        break;
      case 'proxy_credit_score':
        input[obj].name = 'Credit Score Proxy';
        break;
      case 'financial_planning_tendency_score':
        input[obj].name = 'Financial Planning';
        break;
      case 'projected_retirement_assets':
        input[obj].name = 'Future Assets';
        break;
      case 'percent_assets_in_etf_and_mf':
        input[obj].name = 'Firm ETF/MF Allocation';
        break;
      case 'firm_percent_assets_etf':
        input[obj].name = 'Firm ETF Allocation';
        break;
      case 'firm_percent_assets_mf':
        input[obj].name = 'Firm MF Allocation';
        break;
      case 'career_tenure':
        input[obj].name = 'Career Tenure';
        break;
      case 'firm_total_assets_in_millions':
        input[obj].name = 'Firm AUM';
        break;
      case 'aum_self_reported':
        input[obj].name = 'AUM';
        break;
      default:
        break;
    }
  }
  return input;
};

const ContentContainer = styled('div')({
  background: colors.white_00,
  display: 'flex',
  flexDirection: 'column',
  flex: 2,
  minHeight: '400px',
  padding: '10px 0px 0px 0px',
  overflowY: 'scroll',
});

const InnerContainer = styled('div')({
  minWidth: '460px',
  display: 'flex',
  alignItems: 'space-between',
  justifyContent: 'center',
  flex: 1,
  border: `1px solid ${colors.grey_01}`,
  borderTop: `0px solid ${colors.grey_01}`,
});

const CenterContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '610px',
});

interface Props {
  client: any;
  getRadarChartData: any;
  radarFeatures: any;
  textData: any;
  contentType: any;
  width?: any;
  runId?: number;
  showNurtureScore?: boolean;
  packageName: string;
  fundChart?: any;
  isDemoData?: boolean;
  cardProps?: any;
}

export const UserProfile = ({
  client,
  getRadarChartData,
  runId,
  packageName,
  fundChart,
  isDemoData,
  cardProps,
}: Props) => {
  const [radarData, setRadarData] = useState<any>(undefined);
  const [loading, setLoading] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  const [showClientCard, setShowClientCard] = useState(true);
  const [show360, setShow360] = useState(true);

  useEffect(() => {
    setLoading(true);
    const newRunId = runId ? runId : client?.runHistory?.id;
    const updateRadarChart = async (client: any, runId: number) => {
      try {
        const data = await getRadarChartData(client.id, runId);
        // console.log('getRadarChartData: ', getRadarChartData);
        if (fundChart) {
          const formattedData = formatRadarData(data);
          // console.log('formattedData: ', formattedData);
          setRadarData(formattedData);
          setLoading(false);
        } else if (packageName === 'crossSell' || packageName === 'prioritizeProspects') {
          const formattedData = formatRadarDataWealth(data);
          let x = formattedData;
          setRadarData(nameMetrics(x));
          setLoading(false);
        } else {
          const formattedData = formatRadarData(data);
          let x = formattedData;
          setRadarData(nameMetrics(x));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    updateRadarChart(client, newRunId);
  }, []);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  console.log('user profile client: ', client);

  return (
    <Row
      style={{
        margin: '0px 0px 0px 0px',
        overflow: 'hidden',
      }}
    >
      <Row style={{ minWidth: '340px', maxWidth: '400px' }}>
        {showClientCard &&
          (packageName === 'individualLeads' ? (
            <OldClientCard
              client={client}
              id={''}
              contentType={'individualLeads'}
              packageName={'individualLeads'}
              algoType="Purchase"
              {...cardProps}
            />
          ) : (
            <ClientCard
              client={client}
              key={0}
              id={''}
              contentType={'advisorLeads'}
              packageName={'advisorLeads'}
              algoType="Purchase"
              runId={cardProps?.runId}
            />
          ))}
      </Row>
      {isDemoData && (
        <Row>
          {show360 && (
            <ContentContainer>
              <InnerContainer>
                {loading ? (
                  <CenterContainer>
                    <Loader />
                  </CenterContainer>
                ) : (
                  <Advisor360 repCrd={client.repcrd} mockData={client?.advisor360Data} client={client} />
                )}
              </InnerContainer>
            </ContentContainer>
          )}
        </Row>
      )}
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Row>
  );
};

export default UserProfile;
