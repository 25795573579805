import * as colors from './colors';

export const primary = {
  display: 'flex',
  //margin: 'auto 10px auto 30px',
  padding: '10px 50px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  // height: 40,
  background: colors.black,
  border: `1px solid ${colors.black}`,
  color: 'white',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    opacity: '0.8',
  },
  '&:disabled': {
    opacity: '0.3',
  },
};

export const secondary = {
  display: 'flex',
  //margin: 'auto 10px auto 30px',
  padding: '10px 50px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: colors.white,
  border: `1px solid ${colors.black}`,
  color: colors.black,
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    background: colors.grey_00,
  },
};

export const headerButton = {
  ...primary,
  height: '30px',
  width: '120px',
  whiteSpace: 'nowrap',
  '&:disabled': {
    opacity: '0.3',
  },
  '&:hover': {
    opacity: '0.8',
  },
};
