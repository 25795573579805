import React, { useEffect, useState } from 'react';
import { PackageContainer } from '../../../../components/PackageContainer';
import { useStoreState, useStoreActions } from '../../../../store';
import { useNavigate } from 'react-router-dom';

import LoadingModal from '../../../../components/LoadingModal';

import CampaignDetails from './CampaignDetails';
import CampaignLanding from './CampaignLanding';
import CustomizeEmail from './CustomizeEmail';
import CampaignOverview from './CampaignOverview';
import { CREATE_DRAFT_CAMPAIGN, CREATE_NURTURE_CAMPAIGN } from '../../../../api/nurtureProspects';
import ErrorModal from '../../../../components/ErrorModal';
import moment from 'moment';
import SubscriptionModal from '../../../../components/SubscriptionModal';
import { useMutation } from '@apollo/client';

const basePath = '/asset-manager/nurture/campaigns';

export const Campaigns = () => {
  const [completed, setCompleted] = useState(false);

  const [focusedEmail, setFocusedEmailInfo] = useState(0);
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [sendDatetime, setSendDatetime] = useState(moment().add(6, 'minute').format());

  // const [loading, setLoading] = useState(false);
  const [hideLoading, setHideLoading] = useState(false);
  const [savingDraft, setSavingDraft] = useState(false);
  const [seedArticle, setSeedArticle] = useState(null);
  const [error, setError] = useState<any>(null);
  const [packageFirstStep, setPackageFirstStep] = useState('Target');

  const [lastSavedChange, setLastSavedChange] = useState<any>(null);

  const articleArray = useStoreState((state) => state.nurtureProspects.articlePdfs);
  const setArticleArray = useStoreActions((state) => state.nurtureProspects.setArticlePdfs);

  const unformattedSubscriptionList: any = useStoreState((state) => state.user.subscriptions);

  const subscriptionsList: any = unformattedSubscriptionList.map((item: any) => item.package);
  const unformattedName = 'NURTURE_LEADS';

  const navigate = useNavigate();

  const { runId, progress, progressTitles, campaignInfo, emailArray } = useStoreState(
    (state) => state.nurtureProspects.createCampaign,
  );
  const { setRunId, setProgress, setProgressTitles, setCampaignInfo, setEmailArray } = useStoreActions(
    (actions) => actions.nurtureProspects.createCampaign,
  );

  const formatEmail = (email: any) => {
    console.log('formatting email: ', sendDatetime);
    const newFormattedEmail = {
      name: email.name,
      description: email.description,
      html: email.html,
      sendDatetime: moment(sendDatetime).format(),
      subject: email.subject,
      editorJson: JSON.stringify(email.json),
    };
    return newFormattedEmail;
  };

  const unformatEmail = (email: any) => {
    // console.log('unformatting email: ', email);
    const newFormattedEmail = {
      name: email.name,
      description: email.description,
      html: email.html,
      sendDatetime: moment(email.sendDatetime).format(),
      subject: email.subject,
      editorJson: email?.editorJson ? JSON.parse(email.editorJson) : null,
    };
    return newFormattedEmail;
  };

  useEffect(() => {
    setupPackagePages(packageFirstStep);
  }, [articleArray, emailArray, runId, seedArticle]);

  const [createDraftCampaign] = useMutation(CREATE_DRAFT_CAMPAIGN, {
    onCompleted: (data) => {
      setLastSavedChange(moment().format('LT'));
      setProgress(0);
      setSavingDraft(false);
    },
  });

  const saveDraft = async (newEmailArray: any) => {
    try {
      setSavingDraft(true);
      console.log('savingDraft: ', emailArray);
      const formattedEmail = newEmailArray.map((item: any) => formatEmail(item));

      createDraftCampaign({
        variables: {
          runId: String(runId),
          emails: formattedEmail,
          name: campaignInfo?.name,
          description: campaignInfo?.description,
        },
      });
    } catch (error: any) {
      setSavingDraft(false);
      setError({ ...error, body: `An unknown error occured while saving your draft (${error?.message})` });
      console.warn('error creating campaign: ', error);
    }
  };

  const openDraft = async (draftCampaign: any) => {
    setRunId(draftCampaign?.run?.id);
    setCampaignInfo({
      name: draftCampaign.name,
      description: draftCampaign.description,
      target: { ...draftCampaign.run, serveCount: draftCampaign?.recipientCount },
    });
    const unformattedEmailArray = draftCampaign?.nurtureemailSet.map((email: any) => unformatEmail(email));

    setEmailArray(unformattedEmailArray);
    setProgress(2);
    setProgressTitles(['Complete', 'Complete', 'Current']);
    navigate('delivery-details');
  };

  const [createNurtureCampaign, { loading }] = useMutation(CREATE_NURTURE_CAMPAIGN, {
    onCompleted: (data) => {
      setProgress(0);
      navActions('home');
      navigate('/drive-sales/create-campaign/home');
    },
  });

  const createCampaign = async (finalEmailArray: any) => {
    try {
      const formattedEmail = finalEmailArray.map((item: any) => formatEmail(item));

      createNurtureCampaign({
        variables: {
          runId: String(runId),
          emails: formattedEmail,
          name: campaignInfo?.name,
          description: campaignInfo?.description,
        },
      });
    } catch (error: any) {
      setError({ ...error, body: `An unknown error occured while scheduling your campaign (${error?.message})` });
      console.warn('error creating campaign: ', error.message);
    }
  };

  const clearCampaignData = () => {
    setRunId(null);
    setProgress(0);
    setEmailArray([]);
    setLastSavedChange(null);
    setCampaignInfo({});
    setSeedArticle(null);
    setShowCreateModal(false);
    setProgressTitles(['', '', '']);
  };

  const navActions = (type: string) => {
    switch (type) {
      case 'home':
        clearCampaignData();
        navigate('home');
        break;
      case 'add-email':
        setRunId(null);
        setProgress(2);
        setEmailArray([]);
        setShowCreateModal(false);
        setProgressTitles(['Complete', '', '']);
        navigate('customize-email');
        break;
      case 'overview':
        break;
      default:
        break;
    }
  };

  const setupPackagePages = (firstStep: any) => {
    let newPackagePages = null;

    // setPackageFirstStep(firstStep);

    if (firstStep === 'Target') {
      newPackagePages = [
        generateHome(),
        generateCampaignDetails(true),
        generateCustomizeEmail(false),
        generateSchedulePage,
      ];
      setPackageFirstStep('Target');
    } else {
      setPackageFirstStep('Email');
      newPackagePages = [
        generateHome(),
        generateCustomizeEmail(true),
        generateCampaignDetails(false),
        generateSchedulePage,
      ];
    }

    return newPackagePages;
  };

  const generateHome = () => ({
    label: 'Home',
    link: 'home',
    Component: CampaignLanding,
    props: {
      nextAction: (firstStep: any) => {
        setupPackagePages(firstStep);
        setProgressTitles(['Current', '', '', '']);
        setPackageFirstStep(firstStep);
        setProgress(1);
        setEmailArray([{}]);
        navigate(firstStep === 'Target' ? 'target' : 'select-content');
      },
      clearCampaignData,
      showCreateModal,
      setShowCreateModal,
      articleArray,
      campaignInfo,
      setCampaignInfo,
      setSeedArticle,
      setRunId,
      openDraft,
      // setProgress,
      // completed,
      // setupPackagePages,
      // openDraft,
      // runId,
      page: 'createCampaigns',
      inputData: {
        headingText: 'Create Campaign',
      },
    },
  });

  const generateCampaignDetails = (firstStep?: boolean) => {
    return {
      label: 'Select Target',
      link: 'target',
      Component: CampaignDetails,
      props: {
        setProgress,
        completed,
        campaignInfo,
        setCampaignInfo,
        runId,
        setRunId,
        emailArray,
        setEmailArray,
        saveDraft,
        textData: {
          pageHeader: "Select funds for which you'd like to find advisor leads",
        },
        nextAction: () => {
          setProgress(firstStep ? 2 : 3);
          setProgressTitles(firstStep ? ['Complete', 'Current', ''] : ['Complete', 'Complete', 'Current']);
          navigate(firstStep ? 'select-content' : 'delivery-details');
        },
        // updateSelectedFunds: (selections) => {
        //   AdvisorLeadsAPI.setTaggedFunds(selections).catch((e) => console.log('Failed', e));
        // },
      },
    };
  };

  const generateCustomizeEmail = (firstStep?: boolean) => {
    return {
      label: 'Select Content',
      link: 'select-content',
      Component: CustomizeEmail,
      props: {
        nextAction: async () => {
          setProgressTitles(firstStep ? ['Complete', 'Current', ''] : ['Complete', 'Complete', 'Current']);
          setProgress(firstStep ? 2 : 3);
          navigate(firstStep ? 'target' : 'delivery-details');
        },
        backAction: () => setProgress(1),
        dataFromSource: [],
        firstStep,
        focusedEmail,
        seedArticle,
        setSeedArticle,
        emailArray,
        setEmailArray,
        saveDraft,
        lastSavedChange,
      },
      topBarIndex: 1,
    };
  };

  const generateSchedulePage = {
    label: 'Review & Schedule',
    link: 'delivery-details',
    Component: CampaignOverview,
    props: {
      navActions,
      nextAction: async (finalEmailInfo: any) => {
        setProgressTitles(['Complete', 'Complete', 'Complete']);
        createCampaign(finalEmailInfo);
      },
      emailArray,
      setEmailArray,
      campaignInfo,
      seedArticle,
      sendDatetime,
      setSendDatetime,
      basePath,
      saveDraft,
      backAction: () => setProgress(2),
      dataFromSource: [],
    },
  };

  const defaultStepData = [
    generateHome(),
    generateCampaignDetails(true),
    generateCustomizeEmail(false),
    generateSchedulePage,
  ];

  const alternateStepData = [
    generateHome(),
    generateCustomizeEmail(true),
    generateCampaignDetails(false),
    generateSchedulePage,
  ];

  const defaultNavSteps = {
    0: 'home',
    1: 'target',
    2: 'select-content',
    3: 'delivery-details',
  };

  return (
    <>
      <PackageContainer
        basePath={''}
        title={'Create Campaign'}
        runId={runId}
        packageRunName={campaignInfo?.name}
        setPackageRunName={(name: string) => {
          setCampaignInfo({ ...campaignInfo, name: name });
        }}
        PackageSteps={packageFirstStep === 'Target' ? defaultStepData : alternateStepData}
        progress={progress}
        setProgress={setProgress}
        progressTitles={progressTitles}
        setProgressTitles={setProgress}
        navActions={navActions}
        navSteps={defaultNavSteps}
      />
      <LoadingModal
        modalStatus={(loading || savingDraft) && !hideLoading}
        closeModal={() => setHideLoading(true)}
        headerText={savingDraft ? 'Saving Draft' : 'Creating Campaign'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <SubscriptionModal
        modalStatus={!subscriptionsList.includes(unformattedName)}
        closeModal={() => navigate('/drive-sales')}
        requestNote={`User would like to activate the Nurture Prospects`}
        requestType={'Subscription Request'}
      />
      <ErrorModal modalStatus={!!error} closeModal={() => setError(null)} header={'Error'} body={error?.body} />
    </>
  );
};
