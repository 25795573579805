import { Action, action, Thunk, thunk } from 'easy-peasy';
import { formatFilterOptions, formatFeatureSelections, formatSelectedFunds } from '../../../helpers/formatArchives';

export interface DiscoverLeadsModel {
  progress: number;
  progressTitles: Array<string>;
  fundsSelected: object;
  advisorSetSelected: object;
  filterOptions: object;
  characteristics: object;
  packageRunName: string | null;
  dateRun: string | null;
  runId: number | null;
  runIdArray: Array<string> | null;
  journeyId: number | null;

  setProgress: Action<DiscoverLeadsModel, any>;
  setProgressTitles: Action<DiscoverLeadsModel, any>;
  setFundsSelected: Action<DiscoverLeadsModel, any>;
  setAdvisorSetSelected: Action<DiscoverLeadsModel, any>;
  setFilterOptions: Action<DiscoverLeadsModel, any>;
  setCharacteristics: Action<DiscoverLeadsModel, any>;
  setPackageRunName: Action<DiscoverLeadsModel, any>;
  setDateRun: Action<DiscoverLeadsModel, any>;
  setRunId: Action<DiscoverLeadsModel, any>;
  setRunIdArray: Action<DiscoverLeadsModel, any>;
  setJourneyId: Action<DiscoverLeadsModel, any>;

  loadArchive: Thunk<DiscoverLeadsModel, any>;
}

export const discoverLeads: DiscoverLeadsModel = {
  progress: 0,
  progressTitles: ['', '', ''],
  fundsSelected: {},
  advisorSetSelected: {},
  filterOptions: {},
  characteristics: {},
  packageRunName: null,
  dateRun: null,
  runId: null,
  runIdArray: [],
  journeyId: null,

  setProgress: action((state, payload) => {
    state.progress = payload;
  }),
  setProgressTitles: action((state, payload) => {
    state.progressTitles = payload;
  }),
  setFundsSelected: action((state, payload) => {
    state.fundsSelected = payload;
  }),
  setAdvisorSetSelected: action((state, payload) => {
    state.advisorSetSelected = payload;
  }),
  setFilterOptions: action((state, payload) => {
    state.filterOptions = payload;
  }),
  setCharacteristics: action((state, payload) => {
    state.characteristics = payload;
  }),

  setPackageRunName: action((state, payload) => {
    state.packageRunName = payload;
  }),
  setDateRun: action((state, payload) => {
    state.dateRun = payload;
  }),
  setRunId: action((state, payload) => {
    state.runId = payload;
  }),
  setRunIdArray: action((state, payload) => {
    state.runIdArray = payload;
  }),
  setJourneyId: action((state, payload) => {
    state.journeyId = payload;
  }),

  loadArchive: thunk(async (actions: any, payload: any) => {
    const advisorLeadParams = payload?.advisorleadsrunparamsSet?.[0] ? payload?.advisorleadsrunparamsSet?.[0] : null;

    // const advisorFilterOptions = formatFilterOptions(advisorLeadParams);
    // const advisorFeatureSelections = formatFeatureSelections(advisorLeadParams, 'advisor');
    // const advisorFundSelections = await formatSelectedFunds(advisorLeadParams.taggedFunds);

    // actions.setFilterOptions(advisorFilterOptions);
    // actions.setCharacteristics(advisorFeatureSelections);
    actions.setProgressTitles(['Complete', 'Complete', 'Complete', 'Complete']);
    // actions.setFundsSelected(advisorFundSelections);

    // console.log('payload in loadArchive: ', payload);

    actions.setFundsSelected({});

    actions.setJourneyId(payload?.id);
    actions.setRunId(Number(payload?.runhistorySet?.[0].id));
    actions.setRunIdArray(payload?.runhistorySet);

    actions.setPackageRunName(payload?.customName);
    actions.setDateRun(payload?.dateRun);
    actions.setProgress(3);
  }),
};
