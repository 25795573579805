import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navigation from './pages';
import { styled } from '@mui/material/styles';
import { datadogRum } from '@datadog/browser-rum';
import appPackage from '../package.json';

const Root = styled('div')(`
    display: flex;
    flex: 1;
`);

const env = process.env.REACT_APP_ENV;
const appType = process.env.REACT_APP_THEME === 'TIFIN' ? 'TIFIN' : 'LPL';
const appId: string = process.env.REACT_APP_DD_APP_ID ? process.env.REACT_APP_DD_APP_ID : '-';
const clientToken: string = process.env.REACT_APP_DD_CLIENT_TOKEN ? process.env.REACT_APP_DD_CLIENT_TOKEN : '-';
const backendUrl: string = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : '-';

// if (env === 'dev' || env === 'stage' || env === 'prod') {
datadogRum.init({
  applicationId: appId,
  clientToken: clientToken,
  site: 'datadoghq.com',
  service: 'tifin-amp',
  version: appPackage?.version,
  env: appType + '-' + env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
  proxy: backendUrl + 'proxy',
});

if (env === 'prod') {
  datadogRum.startSessionReplayRecording();
}
// }

function App() {
  return (
    <Root>
      <Navigation />
    </Root>
  );
}

export default App;
