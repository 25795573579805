import React, { useState, useEffect } from 'react';
import { H2, Row } from '..';
import Loader from '../../components/Loader';
import { SelectionTable } from '../../components/Table/SelectionTable';
import { colors } from '../../styles';
import { useStoreState } from '../../store';
import { styled } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { GET_FUNDS } from '../../api/dataAPI';

const Container = styled('div')({
  width: '100%',
  margin: '0px auto 0px auto',
  overflow: 'hidden',
  paddingBottom: 120,
});

const PageHeaderContainer = styled('div')({
  //width: '90%',
  margin: '30px auto 40px',
});

const PageHeaderStyle = styled('h2')({
  fontSize: 16,
  fontWeight: 500,
  color: '#404B50',
  margin: '0px auto 10px',
  width: '95%',
});

const PageSubHeaderStyle = styled('h2')({
  // width: '60%',
  fontWeight: 400,
  fontSize: 14,
  color: '#758790',
  margin: '0px 30px 0px 36px',
});

const MainButton = styled('button')({
  color: 'white',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '0px 20px',
  background: '#242B2F',
  height: 30,
  margin: '10px 10px',
  cursor: 'pointer',
  lineHeight: 1,
  fontSize: 14,
  border: 0,
  '&:hover': {
    opacity: 0.7,
  },
  '&:disabled': {
    color: 'white',
    background: '#D2D7DA',
  },
});

const TableContainer = styled('div')({
  width: '95%',
  borderRadius: 5,
  margin: '20px auto',
  boxShadow: colors.main_box_shadow,
});

const HeaderContainer = styled('div')({
  display: 'flex',
  width: '100%',
  height: 50,
  margin: '0 auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #e3e5e4',
});

interface Props {
  nextAction: any;
  textData: any;
  setProgress: any;
  completed: any;
  fundsSelected: any;
  setFundsSelected: any;
  maxSelections: any;
  setNewJourneyName: any;
}

export const PackageFundSelector = ({
  nextAction,
  textData,
  fundsSelected,
  setFundsSelected,
  maxSelections,
  setNewJourneyName,
}: Props) => {
  const [searchString, setSearchString] = useState('');
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('vehicle');
  const [dataTotal, setDataTotal] = useState(500);

  const appType = useStoreState((state) => state.appType);

  const {
    loading,
    data: fundData,
    error,
    refetch,
  } = useQuery(GET_FUNDS, {
    fetchPolicy: 'network-only',
    variables: { query: searchString, pageSize, page: tablePage + 1, sortKey },
  });

  // const fetchFunds = async () => {
  //   setLoading(true);
  //   let result = null;
  //   const newFilters = appType === 'LPL' ? [{ key: 'is_lpl_approved_fund', value: 'True' }] : [];
  //   try {
  //     if (getData) {
  //       result = await getData(searchString, tablePage + 1, pageSize, sortKey);
  //     } else {
  //       result = await DataAPI.getFunds(searchString, tablePage + 1, pageSize, sortKey, newFilters);
  //     }
  //     setFunds(result.list);
  //     setDataTotal(result.totalItems);
  //     setLoading(false);
  //   } catch (error: any) {
  //     console.warn(error.message);
  //     setLoading(false);
  //     setError(error);
  //   }
  // };

  const chooseFunds = async () => {
    nextAction();
  };

  const selectionCount = Object.keys(fundsSelected).length;
  //   const selectionCount = 0;

  const selectFund = (fund: any) => {
    const fundTickers = Object.values(fund).map((fundObject: any) => fundObject?.ticker);
    const fundTickerCount = fundTickers?.length;
    let fundName = 'Lead set for ';
    for (let i = 0; i < fundTickers.length; i++) {
      fundName = fundName + fundTickers[i];
      if (i === fundTickerCount - 2) {
        fundName = fundName + ', and ';
      } else if (fundTickerCount > 1 && i < fundTickerCount - 1) {
        fundName = fundName + ', ';
      }
    }
    // console.log('fundName: ', fundName);
    setFundsSelected(fund);
    setNewJourneyName(fundName);
  };

  return (
    <Container>
      <PageHeaderContainer>
        <PageHeaderStyle>{appType === 'LPL' ? textData?.lplPageHeader : textData.pageHeader}</PageHeaderStyle>
        <PageSubHeaderStyle>{textData.pageSubHeader}</PageSubHeaderStyle>
      </PageHeaderContainer>
      {fundData?.funds?.list ? (
        <TableContainer>
          <HeaderContainer>
            <H2 style={{ margin: '0px 30px' }}>Funds</H2>
            <Row>
              <MainButton children={'Select All Funds'} onClick={chooseFunds} id={'select-all-funds-button'} />
              <MainButton
                children={
                  selectionCount > 0
                    ? `${selectionCount} Fund` + (selectionCount > 1 ? 's' : '') + ' Selected'
                    : 'Please Select Funds'
                }
                disabled={selectionCount <= 0}
                onClick={chooseFunds}
                id={'select-funds-button'}
              />
            </Row>
          </HeaderContainer>
          <SelectionTable
            data={fundData?.funds?.list}
            loading={loading}
            dataControls={{
              selections: fundsSelected,
              setSelections: selectFund,
              searchFunc: setSearchString,
              searchString,
              searchPlaceholder: 'Search for a fund...',
            }}
            initSortColumn="vehicle"
            maxSelections={maxSelections ? maxSelections : 10}
            paginationControls={{
              tablePage,
              setTablePage,
              pageSize,
              setPageSize,
              sortKey,
              setSortKey,
              totalItems: fundData?.funds?.totalItems,
            }}
            headerData={[
              {
                type: 'checkbox',
                key: '',
                sortKey: '',
              },
              {
                header: 'Fund Symbol',
                key: 'ticker',
                sortkey: 'ticker',
              },
              {
                header: 'Type',
                key: 'vehicle',
                sortkey: 'vehicle',
              },
              {
                header: 'Fund Name',
                key: 'name',
                sortkey: 'name',
              },
            ]}
          />
        </TableContainer>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
