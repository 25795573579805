import React, { useLayoutEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ClientCard from './ClientCard';
import OldClientCard from './OldClientCard';
import Loader from './Loader';
import { Column, H2, F3 } from '.';
import { colors } from '../styles';

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#fff',
    display: 'flex',
    borderLeft: '1px solid #e3e5e4',
    padding: '0px 0px',
    flex: 1,
  },
  errorDiv: {
    height: 300,
    margin: '10px auto',
    padding: '20px 0px',
    background: '#fff',
    display: 'flex',
    boxShadow: colors.main_box_shadow,
    textAlign: 'center',
  },
}));

/**
 * Mock Data model for TellMeMore View
 */
export type TellMeMoreContent = {
  title: string;
  details: string;
  tag?: string;
};

interface Props {
  expanderFunc: any;
  cardData: any;
  cardProps: any;
  selectedClient: any;
  algoType?: any;
  packageName?: any;
}

const TopRankedCards = ({ expanderFunc, cardData, cardProps, selectedClient, algoType, packageName }: Props) => {
  const [width, setWidth] = useState(0);
  const cardIndexArray = [0, 1, 2];

  const classes = useStyles();

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const topThreeCards = (index: number, newCard: any) => {
    return (
      <div key={index} className={classes.container} style={{ borderLeft: index > 0 ? '1px solid #e3e5e4' : 0 }}>
        {packageName === 'individualLeads' ? (
          <OldClientCard
            id={`client-card-${index}`}
            key={index}
            client={newCard}
            showDetails={expanderFunc}
            algoType={algoType}
            {...cardProps}
          />
        ) : (
          <ClientCard
            id={`client-card-${index}`}
            key={index}
            client={newCard}
            showDetails={expanderFunc}
            algoType={algoType}
            {...cardProps}
          />
        )}
      </div>
    );
  };

  const topTwoCards = (index: number, newCard: any) => {
    if (index < 2) {
      return (
        <div key={index} className={classes.container} style={{ borderLeft: index > 0 ? '1px solid #e3e5e4' : 0 }}>
          {packageName === 'individualLeads' ? (
            <OldClientCard
              id={`client-card-${index}`}
              key={index}
              client={newCard}
              showDetails={expanderFunc}
              algoType={algoType}
              {...cardProps}
            />
          ) : (
            <ClientCard key={index} client={newCard} showDetails={expanderFunc} {...cardProps} algoType={algoType} />
          )}
        </div>
      );
    }
  };

  return (
    <>
      {cardData ? (
        cardData.length ? (
          cardIndexArray.map((index: number) => {
            let newCard = cardData?.[index];
            if (newCard) {
              return (
                <React.Fragment key={index}>
                  {width > 1200 ? topThreeCards(index, newCard) : topTwoCards(index, newCard)}
                </React.Fragment>
              );
            } else {
              return <div key={index} className={classes.container} />;
            }
          })
        ) : (
          <Column style={{ width: '100%', margin: '60px 32px' }}>
            <H2 style={{ color: colors.grey_03, margin: '0px 0px 6px' }}>No Leads Found</H2>
            <F3 style={{ color: colors.grey_02, margin: '0px 0px 0px' }}>
              An unknown error has occured. Please try again later.
            </F3>
          </Column>
        )
      ) : selectedClient === undefined ? (
        <Loader />
      ) : undefined}
    </>
  );
};

export default TopRankedCards;
