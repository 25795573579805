import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, MainTextInput, PrimaryButton } from '..';
import { colors } from '../../styles';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  // height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  linkSendgrid?: any;
};

const SendgridNewIntegration = ({ linkSendgrid }: Props) => {
  const [integrationNickname, setIntegrationNickname] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [subuser, setSubuser] = useState('');

  const pageCopy = {
    header: 'Sendgrid Integration',
    subheader: (
      <span>
        To create a new integration please enter your <b>API key</b>, your sendgrid <b>subuser</b> and a custom{' '}
        <b>nickname</b> (click{' '}
        <a
          style={{ textDecoration: 'none', color: colors.grey_03 }}
          href={'https://docs.sendgrid.com/ui/account-and-settings/api-keys#maincontent'}
        >
          here
        </a>{' '}
        for more detailed instructions).
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 30px' }}>
          {pageCopy.subheader}
        </F3>
        <MainTextInput
          id="integration-nickname"
          label="Integration Nickname"
          size="small"
          value={integrationNickname}
          focused
          onChange={(event) => setIntegrationNickname(event.target.value)}
        />
        <MainTextInput
          id="api-key"
          label="API Key"
          size="small"
          value={apiKey}
          focused
          onChange={(event) => setApiKey(event.target.value)}
        />
        <MainTextInput
          id="subuser"
          label="Subuser"
          size="small"
          value={subuser}
          focused
          onChange={(event) => setSubuser(event.target.value)}
        />
      </TextContainer>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton style={{ width: '160px' }} onClick={() => linkSendgrid(apiKey, integrationNickname, subuser)}>
          Submit
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default SendgridNewIntegration;
