import { Action, action, Thunk, thunk } from 'easy-peasy';
import { formatFeatureSelections, formatFilterOptions, formatSelectedFunds } from '../../../helpers/formatArchives';

export interface IndividualLeadsModel {
  progress: number;
  progressTitles: Array<string>;
  fundsSelected: object;
  filterOptions: object;
  characteristics: object;
  packageRunName: string | null;
  dateRun: string | null;
  runId: number | null;

  setProgress: Action<IndividualLeadsModel, any>;
  setProgressTitles: Action<IndividualLeadsModel, any>;
  setFundsSelected: Action<IndividualLeadsModel, any>;
  setFilterOptions: Action<IndividualLeadsModel, any>;
  setCharacteristics: Action<IndividualLeadsModel, any>;
  setPackageRunName: Action<IndividualLeadsModel, any>;
  setDateRun: Action<IndividualLeadsModel, any>;
  setRunId: Action<IndividualLeadsModel, any>;

  loadArchive: Thunk<IndividualLeadsModel, any>;
}

export const individualLeads: IndividualLeadsModel = {
  progress: 0,
  progressTitles: ['', '', ''],
  fundsSelected: {},
  filterOptions: {},
  characteristics: {},
  packageRunName: null,
  dateRun: null,
  runId: null,

  setProgress: action((state, payload) => {
    state.progress = payload;
  }),
  setProgressTitles: action((state, payload) => {
    state.progressTitles = payload;
  }),
  setFundsSelected: action((state, payload) => {
    state.fundsSelected = payload;
  }),
  setFilterOptions: action((state, payload) => {
    state.filterOptions = payload;
  }),
  setCharacteristics: action((state, payload) => {
    state.characteristics = payload;
  }),
  setPackageRunName: action((state, payload) => {
    state.packageRunName = payload;
  }),
  setDateRun: action((state, payload) => {
    state.dateRun = payload;
  }),
  setRunId: action((state, payload) => {
    state.runId = payload;
  }),

  loadArchive: thunk(async (actions: any, payload: any) => {
    const individualLeadParams = payload?.investorleadsrunparamsSet?.[0]
      ? payload?.investorleadsrunparamsSet?.[0]
      : null;
    //console.log('individualLeads params: ', individualLeadParams);
    const IndividualFilterOptions = formatFilterOptions(individualLeadParams);
    const IndividualFeatureSelections = formatFeatureSelections(individualLeadParams, 'individual');
    const IndividualFundSelections = await formatSelectedFunds(individualLeadParams.taggedFunds);
    console.log('IndividualFundSelections: ', IndividualFundSelections);

    actions.setFilterOptions(IndividualFilterOptions);
    actions.setFundsSelected(IndividualFeatureSelections);
    actions.setProgressTitles(['Complete', 'Complete', 'Complete']);
    actions.setFundsSelected(IndividualFundSelections);

    actions.setPackageRunName(payload.customName);
    actions.setDateRun(payload.dateRun);
    actions.setRunId(payload.id);
    actions.setProgress(3);
  }),
};
