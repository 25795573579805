import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { CardHeader, Divider, H2, F3, F4, Row, Column, PrimaryButtonShort, CardContainer } from '..';
import purchase_light from '../../assets/icons/purchase_light.svg';
import redemption_light from '../../assets/icons/redemption_light.svg';
import { colors } from '../../styles';
import Checkbox from '../Checkbox';
import { CustomBarChart } from './CustomBarChart';
import { MultiSelect } from '../NewFilterMenu/MultiSelect';
import { algoFeatures, screenAdvisorFilters } from './dataFields';
import AlgoCard from '../AlgoCard';
import { unformatCamelCase } from '../../helpers/dataFormatter';
import { useQuery } from '@apollo/client';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  padding: '0px 16px',
});

const ChartContainer = styled(Column)({
  flex: 1,
  backgroundColor: colors.light_purple + '30',
  border: `1px solid ${colors.grey_01}`,
  borderTop: `0px solid ${colors.grey_01}`,
  borderRadius: '0px 0px 8px 0px',
  overflow: 'scroll',
});

const NewCardContainer = styled(CardContainer)({
  margin: '16px 0px',
});

const Subheader = styled(F4)({
  color: colors.grey_02,
  margin: '0px 0px 16px',
});

type Props = {
  nextAction: any;
  featureSelections: any;
  setFeatureSelections: any;
  advisorFilters: any;
  setAdvisorFilters: any;
  algoSettings: any;
  setAlgoSettings: any;
  advisorSetSelected: any;
  GET_SUMMARY_QUERY: any;
  getAdvisorSetSummary: any;
  demoMode: boolean;
};

export const PackageCustomizePage = ({
  nextAction,
  featureSelections,
  setFeatureSelections,
  advisorFilters,
  setAdvisorFilters,
  getAdvisorSetSummary,
  GET_SUMMARY_QUERY,
  advisorSetSelected,
  algoSettings,
  setAlgoSettings,
  demoMode,
}: Props) => {
  const [allChartData, setAllChartData] = React.useState<any>({});
  const [selectedFeature, setSelectedFeature] = React.useState<string>('age');
  // const [loading, setLoading] = React.useState<any>([]);

  console.log('advisorSetSelected in PackageCustomizePage: ', selectedFeature);

  const algoInfo = [
    {
      title: 'Purchase',
      icon: purchase_light,
      description: 'Advisors scored by likelihood to purchase',
    },
    {
      title: 'Redemption',
      icon: redemption_light,
      description: 'Advisors scored by likelihood to redeem',
    },
  ];

  //@ts-ignore
  const setId = Number(Object.values(advisorSetSelected)?.[0]?.id ?? '0');

  const { loading, data } = useQuery(GET_SUMMARY_QUERY, {
    variables: {
      advisorSetId: setId,
      filters: [],
    },
    onCompleted: (data) => {
      console.log('data in getAdvisorSetSummary: ', data?.advisorSetSummary);
      setAllChartData(data?.advisorSetSummary);
    },
  });

  // useEffect(() => {
  //   const getData = async () => {
  //     // setLoading(true);
  //     //@ts-ignore
  //     const advisorSetId = Object.values(advisorSetSelected)?.[0]?.id;
  //     const formattedFilters: any[] = [];
  //     const response = await getAdvisorSetSummary(advisorSetId, formattedFilters);
  //     setAllChartData(response);
  //     // setLoading(false);
  //   };
  //   getData();
  // }, []);

  const updateMultipleFilters = (value: any, label: string) => {
    if (value.length < 1) {
      setAdvisorFilters({ ...advisorFilters, [label]: ['Any'] });
    } else {
      if (value[0] === 'Any' && value.length > 1) {
        let notAny = value.splice(1, value.length);
        setAdvisorFilters({ ...advisorFilters, [label]: { label: notAny } });
      } else if (value.includes('Any')) {
        let current: any = advisorFilters;
        delete current[label];
        setAdvisorFilters({ ...current });
      } else {
        setAdvisorFilters({ ...advisorFilters, [label]: { label: value } });
      }
    }
  };

  const dataLegend = [
    {
      label: 'value',
      fill: colors.grey_02,
    },
  ];

  const updateFeatureSelections = (id: string) => () => {
    setFeatureSelections({ ...featureSelections, [id]: !featureSelections[id] });
    const acceptedIds: string[] = ['age', 'aum', 'type', 'firmSize', 'advisorTenure', 'etfMfAllocation'];
    if (acceptedIds.includes(id)) {
      setSelectedFeature(id);
    }
  };

  const toggleAlgos = (id: string) => () => {
    const newAlgoSettings = { ...algoSettings, [id]: !algoSettings[id] };
    const algoSettingsArray = Object.entries(newAlgoSettings).filter((item) => item[1]);
    if (algoSettingsArray?.length >= 1) {
      setAlgoSettings(newAlgoSettings);
    }
  };

  return (
    <Root>
      <Row style={{ justifyContent: 'flex-end', margin: '16px 0px 0px' }}>
        <PrimaryButtonShort id={'submit-selections'} onClick={nextAction}>
          Submit Selections
        </PrimaryButtonShort>
      </Row>
      <NewCardContainer>
        <CardHeader style={{ justifyContent: 'space-between' }}>
          <span>Select Algorithm(s)</span>
        </CardHeader>
        <Row>
          {algoInfo.map((algo: any) => (
            <AlgoCard {...algo} active={algoSettings[algo?.title]} onClick={toggleAlgos(algo?.title)} />
          ))}
        </Row>
        <Divider />
        <Row style={{ margin: '-20px 0px 0px' }}>
          <Column
            style={{
              margin: '0px 0px 0px 16px',
              padding: '0px 0px 60px',
              width: '420px',
              height: '500px',
              overflowY: 'scroll',
            }}
          >
            <H2 style={{ margin: '20px 0px 0px' }}>Customize Algorithm(s)</H2>
            <Subheader>Modify the AMP algorithms to suit your goals</Subheader>
            {algoFeatures.map((catagory: any) => (
              <Column style={{ margin: '8px 8px 16px' }}>
                <H2 style={{ color: colors.grey_02 }}>{catagory?.label}</H2>
                {catagory?.value?.map((feature: any) => (
                  <Row style={{ margin: '8px' }} onClick={updateFeatureSelections(feature?.id)}>
                    <Checkbox checked={!featureSelections[feature?.id]} />
                    <F3
                      style={{
                        margin: '0px 8px',
                        color: !featureSelections[feature?.id] ? colors.grey_03 : colors.grey_02,
                        cursor: 'pointer',
                      }}
                    >
                      {feature?.label}
                    </F3>
                  </Row>
                ))}
              </Column>
            ))}
            <H2 style={{}}>Screen Advisors</H2>
            <Subheader>Filter for the Advisors You'd Like to Target</Subheader>
            {screenAdvisorFilters?.map((param: any, index: React.Key | null | undefined) => {
              return param.component ? (
                <div style={param.style}>{param.component}</div>
              ) : (
                param.options.length > 1 && (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '2px 0px' }}>
                    <MultiSelect
                      {...param}
                      selectedFilter={advisorFilters?.[param.label]}
                      updateMultipleFilters={updateMultipleFilters}
                      allFilters={advisorFilters}
                    />
                  </div>
                )
              );
            })}
          </Column>
          <ChartContainer>
            <CustomBarChart
              title={`Breakdown of Advisors by ${unformatCamelCase(selectedFeature)}`}
              subheader={`Based upon the ${allChartData?.advisorCount} advisors in your selected set.`}
              selectedMetric={selectedFeature}
              chartData={allChartData?.[selectedFeature + 'Histogram']}
              dataLegend={dataLegend}
              showLinkedData={true}
              demoMode={demoMode}
            />
          </ChartContainer>
        </Row>
      </NewCardContainer>
    </Root>
  );
};
