import React, { useState, useLayoutEffect, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  ListItemText,
  ListItemIcon,
  Box,
  Select,
  OutlinedInput,
} from '@mui/material';
import { colors } from '../styles';
import selectArrow from '../assets/icons/select-arrow.svg';
import selectRestart from '../assets/icons/select-restart.svg';
import Checkbox from './Checkbox';

import _ from 'lodash';
import SearchBar from './SearchBar';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      minWidth: 180,
      margin: '-16px 0px 0px 10px',
    },
  },
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 50,
    position: 'relative',
    'z-index': 100,
    boxShadow: colors.main_box_shadow,
    background: colors.white_01,
    color: colors.grey_02,
    orderBottom: `1px solid ${colors.grey_00}`,
  },
  menuContainer: {
    height: '42px',
    //width: '230px',
    'z-index': 100,
    backgroundColor: colors.white_01,
    '&:hover': {
      fontWeight: '600',
    },
  },
  menu: {
    backgroundColor: colors.white_01,
  },
  menuText: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: colors.grey_03,
    margin: '0px 8px',
  },
  fieldOverride: {
    '& label': {
      fontSize: '12px',
      background: colors.grey_02,
    },
    '& label.Mui-focused': {
      borderColor: '#404B50',
    },
    '& label.Mui': {
      color: '#404B50',
    },
    '& .MuiInputBase-input': {
      //height: '36px',
      marginTop: '-3px',
      fontSize: 12,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey_00,
        border: `1px solid ${colors.grey_00}`,
        height: '34px',
      },
      '&:hover fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
      '&.Mui-focused': {
        border: `1px solid ${colors.grey_02}`,
        backgroundColor: '#404B50',
      },
    },
  },
  fieldOverride2: {
    color: '#242B2F',
    borderColor: '#ECECEC',
    '& .MuiSelect-select': {
      zIndex: 100,
      '& .MuiPaper-root': {
        zIndex: 100,
        background: '#fff',
        boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
      },
    },
    '& label': {
      fontSize: '13px',
      margin: '3px 0px 0px 4px',
      color: colors.grey_03,
      '&.Mui-focused': {
        color: '#404B50',
      },
    },
    '& .MuiOutlinedInput-root': {
      height: 30,
      paddingBottom: 0,
      margin: '0 4px',
      fontSize: 12,
      '& fieldset': {
        color: '#404B50',
      },
      '&:hover fieldset': {
        borderColor: '#404B50',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#404B50',
        border: `1px solid #404B50`,
        color: '#242B2F',
      },
    },
  },
  editTextField: {
    width: '70%',
    color: '#242B2F',
    borderColor: '#ECECEC',
    '& .MuiSelect-select': {
      zIndex: 9000,
      '& .MuiPaper-root': {
        zIndex: 9000,
        background: '#fff',
        boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
      },
    },
    '& label.Mui-focused': {
      color: '#404B50',
    },
    '& .MuiOutlinedInput-root': {
      height: 30,
      paddingBottom: 0,
      margin: '0 4px',
      fontSize: 12,
      '& fieldset': {
        color: '#404B50',
      },
      '&:hover fieldset': {
        borderColor: '#404B50',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#404B50',
        border: `1px solid #404B50`,
        color: '#242B2F',
      },
    },
  },
  restart: {
    position: 'absolute',
    background: 'white',
    cursor: 'pointer',
    width: 10,
    left: '89%',
  },
}));

interface Props {
  id?: string;
  filterOptions: any;
  label: string;
  selectedFilter: any;
  updateFilters: any;
  updateMultipleFilters?: any;
  widthInput?: string;
  disabled?: boolean;
  multiple?: boolean;
  allFilters?: any;
  input?: boolean;
  search?: boolean;
  setSelectedFilters?: any;
}

const FilterMenu = ({
  id,
  filterOptions,
  label,
  selectedFilter,
  updateFilters,
  updateMultipleFilters,
  widthInput,
  disabled,
  multiple,
  allFilters,
  input,
  search,
  setSelectedFilters,
}: Props) => {
  const [customAnchors, setCustomAnchors] = useState({ Age: false, Income: false, Wealth: false, State: false });
  const [ageLow, setAgeLow] = useState(0);
  const [ageHigh, setAgeHigh] = useState(150);
  const [variableWidth, setVariableWidth] = useState(180);
  const [width, setWidth] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const classes = useStyles();

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (width < 1210 && variableWidth >= 180) {
      setVariableWidth(130);
    } else if (width > 1210 && variableWidth <= 180) {
      setVariableWidth(180);
    } else if (width > 1800 && variableWidth < 240) {
      setVariableWidth(200);
    }
  }, [width]);

  const handleCloseCustom = (label: string) => {
    setCustomAnchors({ ...customAnchors, [label]: false });
  };

  const updateInputs = () => {
    setSelectedFilters({ ...allFilters, 'Age': { label: `${ageLow}, ${ageHigh}` } });
  };

  const resetAges = (label: any) => {
    setAgeLow(0);
    setAgeHigh(150);
    updateMultipleFilters('Any', label);
  };

  const restartFilter = (selectedFilter: any, ageReset: boolean) => {
    if (ageReset) {
      if (ageLow > 0 || (ageHigh > 0 && ageHigh < 150)) {
        return (
          <img
            src={selectRestart}
            alt="select"
            className={classes.restart}
            onClick={() => {
              resetAges(label);
            }}
          />
        );
      }
      return <img src={selectArrow} alt="select" style={{ marginRight: 10 }} />;
    } else {
      if (!selectedFilter || selectedFilter[0] === 'Any') {
        return <img src={selectArrow} alt="select" style={{ marginRight: 10 }} />;
      }
      return (
        <img
          src={selectRestart}
          alt="select"
          className={classes.restart}
          onClick={() => {
            updateMultipleFilters('Any', label);
          }}
        />
      );
    }
  };

  const multiselect = () => {
    return (
      <div style={{ marginTop: 4 }}>
        <FormControl
          sx={{
            fontFamily: 'Inter',
            margin: '5px 0px 5px 0px',
          }}
          className={classes.fieldOverride2}
        >
          <InputLabel
            sx={{
              width: 'fit-content',
              background: colors.white_00,
              // marginTop: 0.4,
              // marginLeft: 0.5,
              margin: '0px 0px 0px 0px',
              padding: '0px 6px 0px 0px',
              // paddingRight: 1,
              fontSize: 12,
            }}
            id="demo-multiple-chip-label"
          >
            {label}
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id={id ? id : 'demo-multiple-chip'}
            multiple
            label={label}
            value={selectedFilter && selectedFilter.label ? selectedFilter.label : ['Any']}
            onChange={(event) => updateMultipleFilters(event.target.value, label)}
            size="small"
            sx={{ height: 30, width: variableWidth, fontSize: 14 }}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            IconComponent={() => restartFilter(selectedFilter, false)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', gap: 0.5, overflowX: 'auto' }}>
                {selected.map((value: any, index: number) => (
                  <p key={value} style={{ color: value === 'Any' ? '#758790' : '#404B50' }}>
                    {value}
                    {index < selected.length - 1 && ','}
                  </p>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            {filterOptions.map((option: any, index: number) => {
              if (option.label !== 'Any') {
                return (
                  <MenuItem
                    className={classes.menuContainer}
                    key={index}
                    value={option.label}
                    //sx={{ overflowX: 'auto' }}
                    sx={{
                      fontFamily: 'Inter',
                    }}
                  >
                    <Checkbox
                      checked={
                        allFilters &&
                        allFilters[label] &&
                        allFilters[label]['label'] &&
                        allFilters[label]['label'].indexOf(option.label) > -1
                      }
                      size={'14px'}
                      onClick={() => null}
                    />
                    <div className={classes.menuText}>{option.label} </div>
                  </MenuItem>
                );
              }
            })}
          </Select>
        </FormControl>
      </div>
    );
  };
  const searchSelect = () => {
    return (
      <div style={{ marginTop: 4 }}>
        <FormControl
          sx={{
            fontFamily: 'Inter',
            margin: '5px 0px 5px 0px',
          }}
          className={classes.fieldOverride2}
        >
          <InputLabel
            sx={{
              width: 'fit-content',
              background: 'white',
              marginTop: 0.4,
              marginLeft: 0.5,
              paddingRight: 1,
              fontSize: 12,
            }}
            id="demo-multiple-chip-label"
          >
            {label}
          </InputLabel>
          <Select
            labelId="demo-multiple-chip-label"
            id={id ? id : 'demo-multiple-chip'}
            multiple
            label={label}
            value={selectedFilter && selectedFilter.label ? selectedFilter.label : ['Any']}
            onChange={(event) => {
              updateMultipleFilters(event.target.value, label);
            }}
            size="small"
            sx={{ height: 30, width: variableWidth, fontSize: 14 }}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            IconComponent={() => restartFilter(selectedFilter, false)}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', gap: 0.5, overflowX: 'auto' }}>
                {selected.map((value: any, index: number) => (
                  <p key={value} style={{ color: value === 'Any' ? '#758790' : '#404B50' }}>
                    {value}
                    {index < selected.length - 1 && ','}
                  </p>
                ))}
              </Box>
            )}
            MenuProps={MenuProps}
          >
            <SearchBar searchText={searchTerm} setSearchText={setSearchTerm} />
            {searchTerm.length > 0 &&
              filterOptions
                .filter((item: any) => item.label.includes(searchTerm))
                .slice(0, 10)
                .map((option: any, index: number) => {
                  if (option.label !== 'Any') {
                    return (
                      <MenuItem
                        className={classes.menuContainer}
                        key={index}
                        value={option.label}
                        sx={{
                          fontFamily: 'Inter',
                        }}
                      >
                        <Checkbox
                          checked={
                            allFilters &&
                            allFilters[label] &&
                            allFilters[label]['label'] &&
                            allFilters[label]['label'].indexOf(option.label) > -1
                          }
                          size={'14px'}
                          onClick={() => null}
                        />
                        <div className={classes.menuText}>{option.label} </div>
                      </MenuItem>
                    );
                  }
                })}{' '}
          </Select>
        </FormControl>
      </div>
    );
  };

  const renderLabel = () => {
    if (ageLow && ageHigh && ageLow > 0 && ageHigh > 0 && ageHigh < 150) {
      return `Min ${ageLow}, Max ${ageHigh}`;
    } else if (ageLow < 1 && ageHigh > 0 && ageHigh < 150) {
      return `Max ${ageHigh}`;
    } else if (ageHigh >= 150 && ageLow > 0) {
      return `Min ${ageLow}`;
    } else return 'Any';
  };

  const inputSelect = () => {
    return (
      <div style={{ marginTop: 4 }}>
        <FormControl
          sx={{
            fontFamily: 'Inter',
            margin: '5px 0px 5px 0px',
          }}
          className={classes.fieldOverride2}
        >
          <InputLabel
            sx={{
              width: 'fit-content',
              background: 'white',
              marginTop: 0.4,
              marginLeft: 0.5,
              paddingRight: 1,
              fontSize: 12,
            }}
            id="demo-select-small"
          >
            {label}
          </InputLabel>
          <Select
            labelId="demo-select-small"
            id={id ? id : 'demo-select-small'}
            label={label}
            value={selectedFilter && selectedFilter.label ? selectedFilter.label : ['Any']}
            onClose={() => updateInputs()}
            size="small"
            IconComponent={() => restartFilter(selectedFilter, true)}
            sx={{ height: 30, width: variableWidth, fontSize: 14, color: ageLow && ageHigh ? '#404B50' : '#758790' }}
            renderValue={(selected) => <Box sx={{ display: 'flex', gap: 0.5, overflowX: 'auto' }}>{renderLabel()}</Box>}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  width: 180,
                  margin: '-4px 0px 0px 10px',
                },
              },
            }}
          >
            {
              <div style={{ display: 'flex', padding: 10 }}>
                <TextField
                  id="outlined-select"
                  className={classes.editTextField}
                  sx={{ height: 50, zIndex: 50000 }}
                  label={'Age Min'}
                  size="small"
                  value={ageLow}
                  onChange={(event: any) => setAgeLow(event.target.value)}
                />
                <TextField
                  id="outlined-select"
                  className={classes.editTextField}
                  label={'Age Max'}
                  size="small"
                  value={ageHigh}
                  onChange={(event: any) => setAgeHigh(event.target.value > 0 ? event.target.value : 150)}
                  sx={{ zIndex: 50000 }}
                />
              </div>
            }
          </Select>
        </FormControl>
      </div>
    );
  };

  if (search) {
    return searchSelect();
  } else if (multiple) {
    return multiselect();
  } else if (input) {
    return inputSelect();
  }
  return (
    <>
      <TextField
        id={id ? id : 'basic-menu'}
        // onClose={() => handleCloseCustom(label)}
        className={classes.fieldOverride2}
        label={label}
        value={selectedFilter ? selectedFilter.label : 'Any'}
        size="small"
        select
        //@ts-ignore
        inputProps={{ classes: { input: classes.input1 } }}
        sx={{
          fontFamily: 'Inter',
          margin: '5px 4px 5px 0px',
          'z-index': 0,
          width: widthInput ? widthInput : '230px',
        }}
        disabled={disabled}
      >
        {filterOptions.map((field: any, index: number) => {
          return (
            <MenuItem
              key={index}
              className={classes.menuContainer}
              sx={{ fontFamily: 'Inter', width: widthInput ? widthInput : '230px' }}
              id={`${id}-${field.label.replace(' ', '-')}`}
              onClick={() => updateFilters(label, field)}
              value={field.label}
            >
              <div
                style={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: colors.grey_03,
                  fontWeight: _.isEqual(filterOptions[label], field.value) ? '600' : undefined,
                  display: 'flex',
                  margin: '0 0px',
                  cursor: 'pointer',
                }}
              >
                {field.label}
              </div>
            </MenuItem>
          );
        })}
      </TextField>
    </>
  );
};

export default FilterMenu;
