import * as React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppContainer } from './AppContainer';
import { Home } from './Home';
import { Welcome } from './Home/Welcome';

import { UnifyData } from './UnifyData';
import { Integrations } from './UnifyData/Integrations';
import { Advisors } from './UnifyData/Advisors';
import Datapacks from './UnifyData/Datapacks';

// import OptimizeEngagement from './OptimizeMarketing/OptimizeEngagement';
import { ProductPlacements } from './OptimizeMarketing/ProductPlacement';
import { Webinars } from './OptimizeMarketing/Webinars';

import { DriveSales } from './DriveSales';
import { DiscoverLeads } from './DriveSales/DiscoverLeads';
import { InfluenceProspects } from './DriveSales/InfluenceProspects';

// import { NurtureProspects } from './DriveSales/NurtureProspects';
import { IndividualLeads } from './DriveSales/IndividualLeads';
import { Campaigns } from './DriveSales/NurtureProspects/Campaigns';
import { Engagement } from './DriveSales/NurtureProspects/Engagement';
import { Outcomes } from './DriveSales/Optimize/Outcomes';
import { Feedback } from './DriveSales/FeedbackLoop';

import { Settings } from './Settings';
import { FirmDetails } from './Settings/FirmDetails';
import { AccountDetails } from './Settings/AccountDetails';
import { Funds } from './Settings/Funds';
import { Subscriptions } from './Settings/Subscriptions';

import { SystemManagement } from './SystemManagement';

import { Login } from './Auth/Login';
import { Logout } from './Auth/Logout';
import { ResetPassword } from './Auth/ResetPassword';
import { LoginCallback } from './Auth/LoginCallback';

type Props = {};

const Navigation = (props: Props) => {
  return (
    <Routes>
      <Route path="/">
        {/* AUTH ROUTES */}
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/callback" element={<LoginCallback />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="*" element={<Navigate to="/login" replace />}></Route>
        {/* APP ROUTES */}
        <Route path="/" element={<AppContainer />}>
          <Route path="/home" element={<Home />}></Route>

          {/* Updated Routes */}

          <Route path="/unify/*" element={<UnifyData />}></Route>
          {/* <Route path="/unify" element={<Settings label={'Integrations'} />}> */}
          <Route path="/unify/integrations" element={<Integrations />} />
          <Route path="/unify/advisors" element={<Advisors />} />
          {/* <Route path="/unify/marketing" element={<MarketingIntegrations />} /> */}
          <Route path="/unify/datapacks" element={<Datapacks />}></Route>
          {/* <Route
            path="/unify/crm/link-sf"
            element={<CRMIntegrations uploadType={'CRMImport'} currentPage={'ProcessingScreen'} />}
          /> */}

          {/* </Route> */}
          <Route path="/drive-sales/*" element={<DriveSales />}></Route>
          <Route path="/drive-sales/discover-leads/*" element={<DiscoverLeads />} />
          <Route path="/drive-sales/influence-prospects/*" element={<InfluenceProspects />} />
          <Route path="/drive-sales/individual-leads/*" element={<IndividualLeads />} />

          {/* <Route path="/drive-sales/events/*" element={<DiscoverEvents />}></Route> */}
          {/* <Route path="/drive-sales/analytics/*" element={<DiscoverAnalytics />}></Route> */}

          <Route path="/drive-sales/create-campaign/*" element={<Campaigns />}></Route>
          <Route path="/drive-sales/nurture/*" element={<Engagement />}></Route>

          <Route path="/drive-sales/outcomes/*" element={<Outcomes />}></Route>
          <Route path="/drive-sales/feedback/*" element={<Feedback />}></Route>

          {/* <Route path="/optimize/" element={<OptimizeEngagement />}></Route>  */}
          {/* {/* <Route path="/optimize/" element={<Navigate to="/optimize/engagement" replace />} /> */}
          {/* <Route path="/optimize/engagement/*" element={<OptimizeEngagement />} /> */}
          <Route path="/optimize/" element={<Navigate to="/optimize/product-placements/" replace />} />
          <Route path="/optimize/product-placements/*" element={<ProductPlacements />}></Route>
          <Route path="/optimize/webinars/*" element={<Webinars />}></Route>

          <Route path="/profile" element={<Settings label={'Profile'} />}>
            <Route path="/profile/firm-details" element={<FirmDetails />} />
            <Route path="/profile/account-details" element={<AccountDetails />} />
            <Route path="/profile/funds" element={<Funds />} />
            <Route path="/profile/subscriptions" element={<Subscriptions />} />
          </Route>
          {/* Deprecated Routes */}
          <Route path="/settings" element={<Settings label={'Settings & Profile'} />}>
            <Route path="/settings/firm-details" element={<FirmDetails />} />
            <Route path="/settings/account-details" element={<AccountDetails />} />
            <Route path="/settings/advisors" element={<Advisors />} />
            <Route path="/settings/funds" element={<Funds />} />
            {/* <Route path="/settings/marketing-integrations" element={<MarketingIntegrations />} /> */}
            <Route path="/settings/subscriptions" element={<Subscriptions />} />
          </Route>

          <Route path="/system-management" element={<SystemManagement />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  );
};

export default Navigation;
