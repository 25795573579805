import { Action, Thunk, action, thunk } from 'easy-peasy';
import { createCampaign, CreateCampaignModel } from './Campaigns';

interface PdfArticleModel {
  data: string;
  filename: string;
  title: string;
  description: string;
  imageData: string;
}

export interface NurtureProspectsModel {
  createCampaign: CreateCampaignModel;
  articlePdfs: PdfArticleModel[];
  setArticlePdfs: Action<NurtureProspectsModel, any>;
}

export const nurtureProspects: NurtureProspectsModel = {
  createCampaign,
  articlePdfs: [],
  setArticlePdfs: action((state, payload) => {
    state.articlePdfs = payload;
  }),
};
