import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Check from '../assets/icons/checkbox.svg';

type Props = {
  themeName: any;
  selected: any;
  onClick: any;
};


const useStyles = makeStyles((theme) => ({
  selectedContainer: {
    height: 30,
    borderRadius: 20,
    color: '#fff',
    backgroundColor: '#242B2F',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 12px',
    margin: '5px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  unselectedContainer: {
    backgroundColor: '#ececec',
    color: '#758790',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
    padding: '0px 12px',
    borderRadius: '20px',
    height: '30px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
  },
  check: {
    marginRight: '4px',
  },
}));

const ThemeChip = ({ themeName, selected, onClick }: Props) => {
  const classes = useStyles();

  return (
    <>
      {selected ? (
        <div className={classes.selectedContainer} onClick={() => onClick(themeName)}>
          <img src={Check} className={classes.check} />
          {themeName}
        </div>
      ) : (
        <div className={classes.unselectedContainer} onClick={() => onClick(themeName)}>
          {themeName}
        </div>
      )}
    </>
  );
};

export default ThemeChip;
