import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../../../styles';
import { H1, H2, H3, F3, SecondaryButtonShort, Row, CardContainer, PrimaryButtonShort } from '../../../../components';
import LoadingModal from '../../../../components/LoadingModal';
import ComingSoonModal from '../../../../components/ComingSoonModal';
import Loader from '../../../../components/Loader';
import UploadArticle from '../../../../components/UploadArticle';
import CreateCampaignModal from '../../../../components/CreateCampaignModal';
import CampaignDraftModal from '../../../../components/CampaignDraftModal';
import { SectionPageHeader } from '../../../../components/SectionPageHeader';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { formatTimestamp, formatPackageName } from '../../../../helpers/dataFormatter';
import {
  GET_CAMPAIGNS,
  GET_PACKAGE_RUNS,
  DELETE_DRAFT_CAMPAIGN,
  CANCEL_CAMPAIGN,
} from '../../../../api/nurtureProspects';
import { useQuery, useMutation } from '@apollo/client';
import NextStepIcon from '../../../../assets/icons/next-step.svg';
import SimpleTable from '../../../../components/SimpleTable';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  FilterFn,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table';
import moment from 'moment';

const Container = styled('div')({
  width: '100%',
  margin: '0px auto 0px auto',
  overflowY: 'auto',
});

const HeaderStyle = styled('div')({
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});

const SectionFooter = styled('div')({
  display: 'flex',
  flex: 1,
  fontSize: 16,
  fontWeight: 500,
  color: '#404B50',
  // margin: '0px auto 0px',
  textAlign: 'left',
  margin: '0px 20px 4px',
  minHeight: '10px',
  lineHeight: 1,
  borderBottom: '1px solid #ECECEC',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PageBody = styled('div')({
  padding: '20px 30px 100px 30px',
});

const SectionHeader = styled('div')({
  display: 'flex',
  flex: 1,
  fontSize: 16,
  fontWeight: 500,
  color: '#404B50',
  // margin: '0px auto 0px',
  textAlign: 'left',
  padding: '0px 20px',
  lineHeight: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ArticleCard = styled('div')({
  // width: '350px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  height: '210px',
  borderRadius: '5px',
  boxShadow: colors.main_box_shadow,
  margin: '16px',
  overflow: 'hidden',
  border: `1px solid ${colors.white}`,
  cursor: 'pointer',
  '&:hover': {
    // border: `1px solid ${colors.green}`,
    // opacity: 0.8,
  },
});

const TopContentCards = styled('div')({
  display: 'flex',
  flex: 1,
  // justifyContent: 'space-between',
  minHeight: 50,
  // padding: '0px 0px 10px 20px',
  fontSize: 18,
  fontWeight: 500,
  color: colors.grey_02,
  flexDirection: 'row',
  //width: 'calc(100% - 80px)',
});

const PackageRunCard = styled('div')({
  //width: '350px',
  //height: '140px',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  borderRadius: '5px',
  boxShadow: colors.main_box_shadow,
  margin: '16px',
  overflow: 'hidden',
  padding: '10px 20px 10px',
  border: `1px solid ${colors.white}`,
  cursor: 'pointer',
  '&:hover': {
    // border: `1px solid ${colors.green}`,
    // opacity: 0.8,
  },
});

const CardTarget = styled('div')({
  borderTop: `1px solid ${colors.grey_01}`,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '12px 0px 0px',
  fontSize: '14px',
});

const CardSampleSize = styled('div')({
  margin: '5px 0px 0px',
  ...typography.mainFont,
  fontSize: '12px',
  color: colors.grey_02,
});

const CardIcon = styled('img')({
  margin: '5px 0px 0px 6px',
  width: '20px',
  height: '20px',
  // borderRadius: '5px',
  '&:hover': {
    // backgroundColor: colors.grey_00,
    opacity: '0.7',
  },
});

const campaignOptions = [
  {
    title: 'One Time',
    key: 'oneTime',
    subTitle: 'Create a one-time campaign',
  },
  {
    title: 'Series',
    key: 'series',
    subTitle: 'Create a series of campaigns which you would group together logically.',
    disabled: true,
  },
];

interface Props {
  nextAction: any;
  showCreateModal: any;
  setShowCreateModal: any;
  articleArray: any;
  campaignInfo: any;
  setCampaignInfo: any;
  setSeedArticle: any;
  setRunId: any;
  openDraft: any;
  clearCampaignData: any;
}

const CampaignLanding = ({
  nextAction,
  showCreateModal,
  setShowCreateModal,
  articleArray,
  campaignInfo,
  setCampaignInfo,
  setSeedArticle,
  setRunId,
  openDraft,
  clearCampaignData,
}: Props) => {
  const navigate = useNavigate();

  const [resultsPage, setResultsPage] = useState('campaigns');
  const [data, setData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);

  // const [packageRuns, setPackageRuns] = useState([]);
  const [tablePage, setTablePage] = useState(0);

  const [draftSearchTerm, setDraftSearchTerm] = useState('');
  const [draftTablePage, setDraftTablePage] = useState(0);
  const [draftPageSize, setDraftPageSize] = useState(25);
  const [draftSortKey, setDraftSortKey] = useState('date_created');
  const [draftTotal, setDraftTotal] = useState(0);
  const [selectedDrafts, setSelectedDrafts] = useState<any>({});

  const [modalCopy, setModalCopy] = useState('Create Campaign');
  const [showCampaignDraftModal, setShowCampaignDraftModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // const [cancelLoading, setCancelLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [campaignType, setCampaignType] = useState(null);
  const [showUploadArticle, setShowUploadArticle] = useState(false);
  const [selectedPackageRun, setSelectedPackageRun] = useState(null);
  const [packageSeed, setPackageSeed] = useState('Target');

  const [targetSlice, setTargetSlice] = useState(0);

  const [emptyCards, setEmptyCards] = useState<any>([]);

  useEffect(() => {
    if (articleArray?.length < 3 && articleArray.length > 0) {
      const articleCount = articleArray.slice(0, 3).length;
      let emptySpaces = 3 - articleCount;
      const newEmptyCards = [];
      // console.log('empty spaces: ', emptySpaces);
      for (let i = 0; i < emptySpaces; i++) {
        newEmptyCards.push(' ');
      }
      setEmptyCards(newEmptyCards);
    }
  }, [articleArray]);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const refreshData = () => {
    setRefresh(!refresh);
  };

  useEffect(() => {
    // fetchDraftCampaigns(draftSearchTerm);
    // fetchPackageRuns();
    setTablePage(0);
  }, [refresh]);

  useEffect(() => {
    // fetchDraftCampaigns(draftSearchTerm);
  }, [draftPageSize, draftTablePage]);

  const { data: draftCampaigns, loading: loadingDraftCampaigns } = useQuery(GET_CAMPAIGNS, {
    variables: {
      pageSize: draftPageSize,
      page: draftTablePage + 1,
      query: draftSearchTerm,
      sortKey: '-date_created',
      filters: [{ key: 'status', value: 'DRAFT' }],
    },
    onCompleted: (data: any) => {
      setDraftTotal(data?.nurtureProspects?.campaigns.totalItems);
    },
    onError: (error: any) => {
      console.warn('Error fetching Archive: ', error);
    },
  });

  const [deleteDraft, { loading: deleteLoading }] = useMutation(DELETE_DRAFT_CAMPAIGN, {
    onCompleted: (data: any) => {
      setShowConfirmationModal(false);
    },
  });

  const deleteDraftCampaign = async () => {
    try {
      const campaignIds = Object.keys(selectedDrafts);
      // console.log('campaign id in delete draft campaign: ', campaignId);
      for (let id of campaignIds) {
        if (id) {
          deleteDraft({ variables: { campaignId: id } });
        }
      }
      setShowConfirmationModal(false);
      setRefresh(!refresh);
    } catch (error: any) {
      console.warn('Error in delete draft campaign ', error.message);
    }
  };

  const { data: packageRuns, loading: loadingPackageRuns } = useQuery(GET_PACKAGE_RUNS, {
    variables: {
      pageSize: 3,
      page: tablePage + 1,
      query: '',
      sortKey: '-date_run',
      // filters: [],
    },
    onError: (error: any) => {
      console.warn('Error fetching Archive: ', error);
    },
  });

  const {
    data: recentCampaigns,
    loading: loadingRecentCampaigns,
    error,
  } = useQuery(GET_CAMPAIGNS, {
    variables: {
      page: 1,
      pageSize: 5,
      sortKey: '-date_created',
      filters: [
        { key: 'run__package', value: 'INFLUENCE' },
        { key: 'run__package', value: 'DISCOVER_LEADS' },
        { key: 'run__package', value: 'NEWSLETTER_SEGMENTS' },
        { key: 'status', value: 'ACTIVE' },
      ],
    },
    onCompleted: (data: any) => {},
    onError: (error: any) => {
      console.warn('Error fetching Archive: ', error);
    },
  });

  const [cancelCampaign, { loading: cancelLoading }] = useMutation(CANCEL_CAMPAIGN, {
    onCompleted: (data: any) => {},
  });

  const columns = [
    {
      header: 'Campaign Name',
      accessorKey: 'name',
      cell: (row: any) => {
        return row?.getValue();
        // return 'test';
      },
    },
    {
      header: 'Email Subject',
      accessorKey: 'nurtureemailSet',
      cell: (row: any) => {
        return row?.getValue()?.[0]?.subject ? row?.getValue()?.[0]?.subject : '-';
      },
    },
    {
      header: 'Send Time',
      accessorKey: 'nurtureemailSet',
      cell: (row: any) => {
        return row?.getValue()?.[0]?.sendDatetime
          ? moment(row?.getValue()?.[0]?.sendDatetime).format('MM/DD/YY - LT')
          : '-';
      },
    },
    {
      header: 'Campaign Status',
      accessorKey: 'isCancellable',
      cell: (row: any) => {
        return row?.getValue() ? 'Scheduled' : 'Delivered';
      },
    },
    {
      header: '',
      accessorKey: 'isCancellable',
      cell: (info: any) => {
        console.log('info in isCancellable: ', info);
        return (
          <Row style={{ justifyContent: 'flex-end' }}>
            {info?.getValue() && (
              <PrimaryButtonShort
                onClick={async () => {
                  try {
                    cancelCampaign({ variables: { campaignId: info?.id } });
                    setRefresh(!refresh);
                  } catch (error: any) {
                    console.warn('error in cancel campaign: ', error.message);
                  }
                }}
                disabled={cancelLoading}
              >
                {cancelLoading ? <Loader color={colors.white} hideText size={'10px'} /> : 'Cancel'}
              </PrimaryButtonShort>
            )}
          </Row>
        );
      },
    },
  ];

  const startTarget = (item: any) => {
    // console.log('item in start with target: ', item);
    clearCampaignData();
    setRunId(item?.id);
    setSelectedPackageRun(item?.package);
    setPackageSeed('Target');
    // nextAction('Target');
    setModalCopy('Create a Campaign - Start with Target Audience');
    toggleCreateModal();
  };

  const toggleUploadArticleModal = (article: any) => {
    setShowUploadArticle(!showUploadArticle);
  };

  const [sorting, setSorting] = useState<SortingState>([]);

  const campaignTable = useReactTable({
    columns,
    data: recentCampaigns?.nurtureLeads?.campaigns?.list ?? [],
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableSorting: true,
    // debugAll: true,
  });

  return (
    <>
      <LoadingModal
        modalStatus={loadingCSV}
        closeModal={() => setLoadingCSV(false)}
        headerText={'Downloading all results'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <Container>
        <HeaderStyle>
          <SectionPageHeader
            buttonText={'Zoom Out'}
            buttonAction={() => navigate('/nurture')}
            subtitle={'Nurture Prospects'}
            title={'Create Campaign'}
            description={'Select your content or target audience to begin creating your nurture campaign'}
            primaryButton={{
              text: 'Create Campaign',
              action: () => {
                clearCampaignData();
                setPackageSeed('Target');
                setModalCopy('Create a Campaign');
                toggleCreateModal();
              },
            }}
            secondaryButton={{
              text: 'Continue Draft Campaign',
              action: () => {
                setShowCampaignDraftModal(true);
              },
            }}
          />
        </HeaderStyle>
        <PageBody>
          {resultsPage === 'campaigns' && (
            <>
              <SectionHeader>
                <H2>Recently Created Campaigns</H2>
              </SectionHeader>
              <TopContentCards>
                {recentCampaigns ? (
                  <>
                    {recentCampaigns?.nurtureLeads?.campaigns?.totalItems === 0 ? (
                      <>
                        <ArticleCard style={{ height: '160px', display: 'flex', alignItems: 'center' }}>
                          <H2 sx={{ margin: '50px 16px 8px' }}>You haven't created any campaigns yet</H2>
                          <F3 sx={{ margin: '0px 16px 8px', color: colors.grey_02 }}>
                            Please create a campaign to get started.
                          </F3>
                        </ArticleCard>
                      </>
                    ) : (
                      <CardContainer style={{ margin: '8px 16px', padding: '8px 24px' }}>
                        <SimpleTable tableData={campaignTable} searchBar={false} />
                      </CardContainer>
                    )}
                    {emptyCards.map(() => (
                      <ArticleCard style={{ opacity: 0 }} />
                    ))}
                  </>
                ) : (
                  <Loader />
                )}
              </TopContentCards>
              <SectionFooter>
                {articleArray?.length > 3 && (
                  <>
                    <span />
                    <F3
                      // className={classes.sectionAction}
                      style={{
                        margin: '0px 0px 10px',
                        borderBottom: `1px solid ${colors.black_01}`,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate('/nurture/content');
                      }}
                    >
                      See more
                    </F3>
                  </>
                )}
              </SectionFooter>
            </>
          )}
          <>
            <SectionHeader style={{ margin: '20px 20px 0px 0px' }}>
              <H2>Start with Target Audience</H2>
              <SecondaryButtonShort
                // className={classes.sectionAction}
                style={{ margin: '0px -20px 0px 0px ' }}
                onClick={() => startTarget('Target')}
              >
                Create a Custom Target
              </SecondaryButtonShort>
            </SectionHeader>
            <TopContentCards>
              {packageRuns ? (
                packageRuns?.packageRuns?.runs?.totalItems === 0 ? (
                  <ArticleCard style={{ height: '160px', display: 'flex', alignItems: 'center' }}>
                    <H2 sx={{ margin: '50px 16px 8px' }}>You don't have any package runs yet</H2>
                    <F3 sx={{ margin: '0px 16px 8px', color: colors.grey_02 }}>
                      Please generate a signal so that you have results to nurture.
                    </F3>
                  </ArticleCard>
                ) : (
                  <>
                    {packageRuns?.packageRuns?.runs?.list
                      ?.slice(targetSlice * 3, (targetSlice + 1) * 3)
                      ?.map((item: any, index: number) => (
                        <PackageRunCard
                          key={index + 234894285392}
                          onClick={() => startTarget(item)}
                          id={`package-run-card-${index}`}
                        >
                          <H1 style={{ color: colors.grey_03, margin: '8px 0px 0px' }}>
                            {item?.customName ? item?.customName : 'Unnamed Journey'}
                          </H1>
                          <F3 style={{ margin: '4px 0px 22px', color: colors.grey_02 }}>
                            {formatTimestamp(item?.dateRun)}
                          </F3>
                          <H3 style={{ margin: '0px 0px', color: colors.grey_03 }}>
                            {formatPackageName(item?.package)}
                          </H3>
                          <div />
                          <CardTarget style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <CardSampleSize>{item?.serveCount} Recipients</CardSampleSize>
                            <CardIcon
                              src={NextStepIcon}
                              onClick={() => {
                                startTarget(item);
                              }}
                              alt="icon"
                            />
                          </CardTarget>
                        </PackageRunCard>
                      ))}
                    {Array(
                      3 -
                        (packageRuns?.packageRuns?.runs?.list?.slice(targetSlice * 3, (targetSlice + 1) * 3).length ??
                          0),
                    )
                      .fill(' ')
                      .map(() => (
                        <PackageRunCard style={{ opacity: 0, height: '100px' }} />
                      ))}
                  </>
                )
              ) : (
                <Loader />
              )}
            </TopContentCards>
            <SectionFooter>
              <span />
              <F3
                // className={classes.sectionAction}
                style={{ margin: '0px 0px 10px', borderBottom: `1px solid ${colors.black_01}`, cursor: 'pointer' }}
                onClick={() => {
                  const dataLength = data.length;
                  if (dataLength <= (targetSlice + 1) * 3) setTargetSlice(0);
                  else setTargetSlice(targetSlice + 1);
                }}
              >
                See more
              </F3>
            </SectionFooter>
          </>
        </PageBody>
      </Container>
      <CampaignDraftModal
        loading={loadingDraftCampaigns}
        closeModal={() => setShowCampaignDraftModal(false)}
        openDraft={openDraft}
        draftCampaigns={draftCampaigns?.nurtureLeads?.campaigns?.list ?? []}
        showCampaignDraftModal={showCampaignDraftModal}
        draftSearchTerm={draftSearchTerm}
        setDraftSearchTerm={setDraftSearchTerm}
        draftTablePage={draftTablePage}
        setDraftTablePage={setDraftTablePage}
        draftPageSize={draftPageSize}
        setDraftPageSize={setDraftPageSize}
        draftTotal={draftTotal}
        draftSortKey={draftSortKey}
        setDraftSortKey={setDraftSortKey}
        selections={selectedDrafts}
        setSelections={setSelectedDrafts}
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
      />
      <CreateCampaignModal
        modalCopy={modalCopy}
        modalStatus={showCreateModal}
        closeModal={toggleCreateModal}
        setCampaignType={setCampaignType}
        campaignOptions={campaignOptions}
        campaignInfo={campaignInfo}
        setCampaignInfo={setCampaignInfo}
        nextAction={() => nextAction(packageSeed)}
      />
      <ConfirmationModal
        modalStatus={showConfirmationModal}
        closeModal={() => setShowConfirmationModal(!showConfirmationModal)}
        action={deleteDraftCampaign}
        copy={{
          title: 'Delete Draft',
          header: 'Delete this draft campaign',
          body: 'Are you sure that you want to continue? This action cannot be undone.',
        }}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
      <UploadArticle modalStatus={showUploadArticle} closeModal={toggleUploadArticleModal} refresh={refreshData} />
    </>
  );
};

export default CampaignLanding;
