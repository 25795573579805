import React, { useState, useLayoutEffect, useEffect } from 'react';
import { colors, typography } from '../styles';
import { Row } from '.';
import { Tooltip } from '@mui/material';
import rightArrow from '../assets/icons/arrow-right.svg';
import { styled } from '@mui/styles';
import { BasicFilterMenu } from './NewFilterMenu/BasicFilterMenu';
import { select } from 'd3';

const Container = styled('div')({
  backgroundColor: colors.white_00,
  borderBottom: `1px solid ${colors.grey_01}`,
  position: 'relative',
  'z-index': 6,
});

const InnerContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  //width: '%',
  margin: 'auto 46px auto 30px',
  height: '50px',
});

const Title = styled('p')({
  fontSize: 14,
  // display: 'table',
  margin: 0,
  padding: 0,
  lineHeight: 1,
  fontWeight: 700,
  color: colors.grey_03,
});

const TitleSolo = styled('p')({
  ...typography.mainFont,
  fontSize: 16,
  fontWeight: 600,
  margin: '0px -16px 0px 0px',
  display: 'flex',
  color: colors.grey_03,
});

const LeftItems = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const Subheader = styled('h3')({
  color: '#758790',
  margin: '2px 0 0',
  fontSize: 12,
  fontWeight: 500,
  padding: 0,
});

const MainIcon = styled('img')({
  marginRight: '10px',
  height: 28,
  width: 28,
});

const SelectorBorder = styled('div')({
  height: '24px',
  margin: '0 0px 0 18px',
});

const TitleSelector = styled('p')({
  fontWeight: 600,
  display: 'flex',
  color: colors.grey_03,
  paddingLeft: 6,
  //borderLeft: `2px solid ${colors.grey_03}`,
  overflowX: 'auto',
});

const SelectorOption = styled('p')({
  fontSize: '16px',
  fontWeight: 600,
  display: 'flex',
  margin: '0px 0px 0px',
  cursor: 'pointer',
  color: colors.grey_03,
  padding: '0px 2px 0px 4px',
  borderBottom: `1px solid ${colors.white_01}`,
  '&:hover': {
    fontWeight: 600,
    color: colors.grey_03,
    background: colors.grey_00,
    borderRadius: '5px 5px 0px 0px',
  },
});

const RightArrow = styled('img')({
  marginLeft: 10,
  cursor: 'pointer',
  width: 8,
});

const LeftButtons = styled('div')({
  whiteSpace: 'nowrap',
});

const LeftButton = styled('button')({
  fontFamily: 'Inter',
  height: '30px',
  borderRadius: '30px',
  border: `0px solid ${colors.black_01}`,
  color: colors.grey_02,
  fontWeight: '500',
  backgroundColor: colors.white_00,
  padding: '0px 0px',
  cursor: 'pointer',
  letterSpacing: '0px',
  fontSize: '16px',
  //width: '92px',
  marginRight: '20px',
  //fontWeight: 'bold',
});

const RightItems = styled('div')({
  // width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
});

const StatLabel = styled('p')({
  ...typography.mainFont,
  fontWeight: '400',
  color: colors.grey_03,
  margin: '0px 8px',
});

const Stat = styled('span')({
  color: colors.green,
  fontWeight: '500',
  margin: '0px 4px',
});

const NavigationButton = styled('button')({
  fontSize: 14,
  fontWeight: 500,
  margin: '0 2px',
  cursor: 'pointer',
  color: colors.grey_03,
  padding: '8px 3px 8px 12px',
  borderRadius: '5px',
  background: colors.white_00,
  border: 0,
  '&:hover': {
    background: colors.grey_00,
  },
});

const NavIcons = styled('img')({
  height: 14,
  width: 14,
  margin: '0px 7px -1px 0px',
});

const RightButton = styled('button')({
  padding: '8px 20px',
  height: '30px',
  borderRadius: '5px',
  color: colors.white_01,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${colors.black_01}`,
  backgroundColor: colors.black_01,
  margin: '0px 5px',
  cursor: 'pointer',
  opacity: '1',
  '&:hover': {
    opacity: '0.8',
  },
});

const MainButton = styled('button')({
  color: 'white',
  fontWeight: 400,
  padding: '8px 20px',
  margin: '0px 4px',
  background: colors.black_01,
  height: '30px',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: 14,
  border: 0,
  '&:hover': {
    opacity: 0.7,
  },
  '&:disabled': {
    color: 'grey',
    background: 'white',
    borderColor: 'grey',
  },
});

const MainButtonInverted = styled('button')({
  color: colors.white_01,
  fontWeight: 400,
  display: 'flex',
  padding: '8px 20px 0',
  margin: '0px 4px',
  background: colors.grey_02,
  borderRadius: '5px',
  height: '30px',
  cursor: 'pointer',
  lineHeight: 1,
  fontSize: 14,
  border: 0,
  '&:hover': {
    border: 0,
    background: '#404B50',
    color: 'white',
  },
  '&:disabled': {
    color: 'grey',
    background: 'white',
    borderColor: 'grey',
  },
});

const NavIconSmall = styled('img')({
  height: 14,
  width: 14,
  margin: '0px 10px -1px 10px',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
});

interface Props {
  title?: string;
  rightButtons?: any;
  funds?: any;
  selectedFund?: any;
  setSelectedFund?: any;
  icon?: any;
  selectedButton?: any;
  stats?: any;
  runOptions?: any;
  selectedRun?: any;
  setSelectedRun?: any;
  leftButtons?: any;
  rightDropdown?: any;
  bold?: any;
  subHeader?: any;
  selectedProduct?: any;
  selectedBenchmark?: any;
  flipEmphasis?: any;
  hideBottomBorder?: any;
  customFilters?: any;
  updateFilters?: any;
}

const PageHeader = ({
  icon,
  title,
  selectedButton,
  stats = [],
  leftButtons = [],
  rightButtons = [],
  bold,
  funds,
  runOptions,
  selectedRun,
  setSelectedRun,
  selectedFund,
  setSelectedFund,
  selectedProduct,
  subHeader,
  flipEmphasis,
  hideBottomBorder,
  customFilters,
  updateFilters,
}: Props) => {
  const [width, setWidth] = useState(0);
  const [formattedSelectedFund, setFormattedSelectedFund] = useState('');

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const arrayOfProduct = Object.values(selectedProduct);
      const tickers = arrayOfProduct.map((product: any) => product.ticker).join(', ');
      setFormattedSelectedFund(tickers);
    }
  }, []);

  // console.log('selectedProduct ', selectedProduct, selectedBenchmark);

  return (
    <Container
      style={
        hideBottomBorder
          ? { borderBottom: `0px solid ${colors.grey_01}`, marginBottom: -20 }
          : { borderBottom: `1px solid ${colors.grey_01}` }
      }
    >
      <InnerContainer>
        <LeftItems>
          {icon && (
            <MainIcon
              alt="icon"
              // className={subHeader ? classes.iconStyle : classes.iconStyleSolo}
              src={icon}
            />
          )}
          {title && subHeader && !flipEmphasis && !runOptions ? (
            <Title id={'header-nav-button-home'}>
              {bold && <p style={{ fontWeight: 700, margin: '2px 0 0', fontSize: 14 }}>{bold} &nbsp;</p>}
              <p style={{ margin: 0, padding: 0 }} id={'page-header-title'}>
                {title}
              </p>
              {subHeader && <Subheader>{subHeader}</Subheader>}
            </Title>
          ) : title && subHeader && flipEmphasis ? (
            <Title id={'header-nav-button-home'}>
              {bold && <p style={{ fontWeight: 700, margin: '2px 0 0', fontSize: 14 }}>{bold} &nbsp;</p>}
              {title && <Subheader>{title}</Subheader>}
              <p style={{ margin: 0, padding: 0 }} id={'page-header-title'}>
                {subHeader}
              </p>
            </Title>
          ) : null}
          {runOptions && (
            <Row>
              <Title style={{ lineHeight: '40px', margin: '0px 8px 0px 0px' }} id={'page-header-title'}>
                {title} {formattedSelectedFund && formattedSelectedFund + ' on'}
              </Title>
              {/* and {selectedBenchmark?.name} on */}
              <BasicFilterMenu
                id={'12940141'}
                label="Runs"
                filterOptions={runOptions}
                updateFilters={(label: string, value: any) => setSelectedRun(value)}
                selectedFilter={selectedRun}
              />
            </Row>
          )}

          {title && !subHeader && !runOptions && (
            <TitleSolo>
              {bold && <p style={{ fontWeight: 700, fontSize: '18px' }}>{bold} &nbsp;</p>}
              <p style={{ lineHeight: 1 }} id={'page-header-title'}>
                {title}
              </p>
            </TitleSolo>
          )}
          {customFilters && (
            <Row style={{ margin: '0px 0px 0px 22px' }}>
              {customFilters.map((filter: any, index: number) => (
                <BasicFilterMenu
                  id={'12940141'}
                  label={filter.label}
                  filterOptions={filter.options}
                  updateFilters={(label: string, value: any) => updateFilters(label, value)}
                  selectedFilter={filter.value}
                  widthInput={filter?.width ? filter?.width : 180}
                />
              ))}
            </Row>
          )}
          {funds && Object.keys(funds).length > 0 && (
            <>
              <SelectorBorder />
              <TitleSelector style={{ maxWidth: width > 1200 ? 320 : width > 900 ? 360 : 150 }}>
                {Object.keys(funds).map((fundObj, index) => (
                  <SelectorOption
                    onClick={() => setSelectedFund(funds[fundObj])}
                    style={{
                      fontWeight:
                        funds[fundObj] &&
                        selectedFund.ticker &&
                        funds[fundObj].ticker &&
                        funds[fundObj].ticker === selectedFund.ticker
                          ? 600
                          : 600,
                      borderBottom:
                        funds[fundObj] &&
                        selectedFund.ticker &&
                        funds[fundObj].ticker &&
                        funds[fundObj].ticker === selectedFund.ticker
                          ? '1px solid #404B50'
                          : 0,
                      color:
                        funds[fundObj] &&
                        selectedFund.ticker &&
                        funds[fundObj].ticker &&
                        funds[fundObj].ticker === selectedFund.ticker
                          ? colors.grey_03
                          : colors.grey_02,
                    }}
                    key={index}
                  >
                    {funds[fundObj] && funds[fundObj].ticker ? funds[fundObj].ticker : '-'}&nbsp;
                  </SelectorOption>
                ))}
              </TitleSelector>
            </>
          )}
          {funds && Object.keys(funds).length > 3 && <RightArrow alt="rightArrow" src={rightArrow} />}
          <LeftButtons>
            {leftButtons &&
              leftButtons.map((item: any, index: number) => {
                if (item && item.disabled) {
                  return (
                    <Tooltip key={index + 1231244} placement="right" title="Coming Soon!">
                      <LeftButton
                        id={`header-button-${item.modalText.replaceAll(' ', '-')}`}
                        children={item.modalText}
                      />
                    </Tooltip>
                  );
                } else if (item.text) {
                  return (
                    <LeftButton
                      key={index + 342421}
                      id={`header-button-${item.text.replaceAll(' ', '-')}`}
                      onClick={() => item.onPress(item.key ? item.key : item.text)}
                      style={
                        selectedButton === item.key || selectedButton === item.text
                          ? { fontWeight: '500', color: colors.black_01 }
                          : { fontWeight: '500' }
                      }
                      children={item.text}
                    />
                  );
                }
              })}
          </LeftButtons>
        </LeftItems>
        <RightItems>
          {stats.map((item: any, index: number) => (
            <StatLabel key={index + 202331}>
              <Stat style={item.label === '' ? { color: colors.grey_03 } : {}}>{item.stat}</Stat>
              {item.label}
            </StatLabel>
          ))}
          {rightButtons.map((item: any, index: number) => {
            if (item.dropdown) {
              if (width > 1100) {
                return (
                  <React.Fragment key={index}>
                    {item.menuItems.map((menuItem: any, index: number) => (
                      <NavigationButton
                        key={index}
                        onClick={menuItem.onClick}
                        style={{ fontSize: width > 1250 ? 14 : 12 }}
                        id={`header-nav-buttons-${index}`}
                      >
                        {menuItem.icon && <NavIcons alt="nav" src={menuItem.icon} />}

                        {menuItem.text}
                      </NavigationButton>
                    ))}
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={index}>
                  {item.menuItems.map((menuItem: any, index: number) => (
                    <Tooltip title={menuItem.text} key={index}>
                      {menuItem.icon && (
                        <NavIconSmall
                          id={`header-nav-buttons-${index}`}
                          onClick={menuItem.onClick}
                          alt="nav"
                          src={menuItem.icon}
                        />
                      )}
                    </Tooltip>
                  ))}
                </React.Fragment>
              );
            } else if (item.type) {
              return item.type === 'bold' ? (
                <MainButton key={index} children={item.modalText} onClick={() => item.action()} />
              ) : (
                <MainButtonInverted key={index} children={item.modalText} onClick={() => item.action()} />
              );
            }
            return (
              <RightButton
                key={index + 3923042}
                onClick={() => item.action(item)}
                style={index === 0 ? { marginLeft: '20px' } : {}}
              >
                {item.modalText}
              </RightButton>
            );
          })}
        </RightItems>
      </InnerContainer>
    </Container>
  );
};

export default PageHeader;
