import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { PrimaryButtonShort } from '..';
import { colors } from '../../styles';
import Loader from '../Loader';

type Props = {
  loading: boolean;
  onClick?: () => void;
  style?: any;
  children: ReactNode;
};

const PrimaryButton = ({ loading, children, style, onClick }: Props) => {
  return (
    <PrimaryButtonShort style={style} onClick={onClick}>
      {loading ? <Loader hideText={true} size={'10px'} color={colors.white} /> : children}
    </PrimaryButtonShort>
  );
};

export default PrimaryButton;
