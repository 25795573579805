import React, { useState } from 'react';
import { colors, typography } from '../styles';

import {
  F2,
  PrimaryButtonShort,
  F3,
  SecondaryButtonShort,
  ModalRoot,
  ModalHeader,
  ModalContainer,
  ModalFooter,
  Column,
  Row,
} from '.';
import Loader from './Loader';
import { CREATE_SUBSCRIPTION } from '../api/systemManagement';
import Checkbox from './Checkbox';
import { MenuItem, Select, InputLabel } from '@mui/material';
import { useMutation } from '@apollo/client';

interface Props {
  modalStatus: boolean;
  closeModal: any;
  orgId: string;
  refreshAndSelect: any;
}

const AddSubscriptionModal = ({ closeModal, modalStatus, orgId, refreshAndSelect }: Props) => {
  const [newPackageSub, setNewPackageSub] = useState<string>('INFLUENCE');
  const newPackageQuota = 1_000_000;
  const [demoMode, setDemoMode] = useState<boolean>(false);

  const [createSubscription, { loading, error }] = useMutation(CREATE_SUBSCRIPTION, {
    variables: {
      organizationId: orgId,
      packageName: newPackageSub,
      quota: newPackageQuota,
    },
    onCompleted: (data) => {
      closeModal();
      refreshAndSelect(orgId);
    },
  });

  const subscriptionOptions = [
    { label: 'Influence', value: 'INFLUENCE' },
    // { label: 'Individual Leads', value: 'INVESTOR_LEADS' },
    { label: 'Discover Leads', value: 'DISCOVER_LEADS' },
    { label: 'Nurture Leads', value: 'NURTURE_LEADS' },
    { label: 'Newsletter Segments', value: 'NEWSLETTER_SEGMENTS' },
  ];

  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer style={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '8px 16px' }}>{'Create new subscription'}</ModalHeader>
        <Column style={{ width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
          <Row
            style={{ width: '470px', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0px 20px' }}
          >
            <Column style={{}}>
              <InputLabel
                sx={{
                  width: 'fit-content',
                  background: colors.white_00,
                  // marginTop: 0.4,
                  // marginLeft: 0.5,
                  margin: '0px 0px 0px 0px',
                  padding: '0px 6px 0px 0px',
                  // paddingRight: 1,
                  fontSize: 12,
                }}
                id="demo-multiple-chip-label"
              >
                {'Package Name'}
              </InputLabel>
              <Select
                style={{ width: '300px', ...typography.mainFont }}
                size={'small'}
                value={newPackageSub}
                onChange={(event) => setNewPackageSub(event.target.value)}
              >
                {subscriptionOptions.map((item: any, index: number) => (
                  <MenuItem style={{ ...typography.mainFont }} key={index + 2129041} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </Column>
            <Row style={{ margin: '0px 0px' }}>
              <Checkbox checked={demoMode} onClick={() => setDemoMode(!demoMode)} />
              <F3 style={{ margin: '0px 0px 0px 6px' }}>Demo Mode</F3>
            </Row>
          </Row>
          <F2 style={{ color: colors.red }}>{error?.message}</F2>
        </Column>
        <ModalFooter style={{ width: '100%', padding: '8px 16px', alignItems: 'flex-end' }}>
          <SecondaryButtonShort onClick={closeModal}>Cancel</SecondaryButtonShort>
          <PrimaryButtonShort onClick={() => createSubscription()} style={{ margin: '0px 8px' }}>
            {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Create Subscription'}
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default AddSubscriptionModal;
