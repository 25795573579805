import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import { Row } from '../../components';
import DriveSalesWheel from '../../components/DriveSalesWheel';
import StatsArray from '../../components/StatsArray';

type Props = {};

export const Root = styled('div')({
  flex: 1,
  display: 'flex',
  height: '100vh',
  // width: 100vw,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  background: colors.gradient_01,
});

export const DriveSales = (props: Props) => {
  const [hoverSection, setHoverSection] = useState<string>('default');
  const [screenSize, setScreenSize] = useState<string>('sm');
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (width <= 1550) setScreenSize('sm');
    else if (width > 1850) setScreenSize('lg');
    else if (screenSize !== 'md') setScreenSize('md');
  }, [width, screenSize]);

  return (
    <Root>
      <DriveSalesWheel hoverSection={hoverSection} setHoverSection={setHoverSection} />
      <Row style={{ position: 'absolute', top: screenSize === 'sm' ? '85%' : '75%', zIndex: '-50' }}>
        <StatsArray focusedStep={hoverSection} sx={{}} />
      </Row>
    </Root>
  );
};
