import React, { useState } from 'react';
import { ModalRoot, ModalHeader, ModalContainer } from '.';

interface Props {
  closeModal: any;
  modalCopy?: modalCopyType;
}

interface modalCopyType {
  header: string;
  body: any;
  actions: any;
}

// const ModalContainer = styled('div')({
//   height: '100%',
//   width: '100%',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// });

const AddDataModal = ({ closeModal, modalCopy }: Props) => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>(null);

  const AddUser = async () => {
    //setArticleTitle(newArticleTitle);
    setLoading(true);

    setErrors(null);
    const newErrors: any[] = [];
    try {
      setLoading(false);
      closeModal();
    } catch (error: any) {
      setLoading(false);
      newErrors.push('Unknown Error');
      setErrors(newErrors);
    }
  };

  return (
    <ModalRoot
      open={!!modalCopy}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <ModalHeader>{modalCopy?.header}</ModalHeader>
        {modalCopy?.body()}
        {modalCopy?.actions()}
      </ModalContainer>
    </ModalRoot>
  );
};

export default AddDataModal;
