import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import GeneralTable from '../../components/Table';
import Loader from '../../components/Loader';
import { SelectedOrganization } from './SelectedOrganization';
import { Row, PrimaryButtonShort, F3, CardContainer, CardBody, CardHeader } from '../../components';
import { useStoreState, useStoreActions, AppType } from '../../store';
import { formatFullName, formatSubscriptions } from '../../helpers/dataFormatter';
import { LOAD_PROFILE } from '../../api/auth';
import AddOrganizationModal from '../../components/AddOrganizationModal';
import { GET_ORGANIZATION, REVERT_USER } from '../../api/systemManagement';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';

const Container = styled('div')(`
  width: 100%;
  height: 100vh;
  display: table;
  overflowY: auto;
  margin: 0;
`);

const HeaderBlock = styled('div')(`
  width: 100%;
  background: ${colors.gradient_01};
  height: 60px;
  margin: 0;
`);

const HeaderText = styled('h2')(`
  width: 95%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.09em;
  color: #242B2F;
  padding: 16px 0 0;
  margin: 0 auto;
`);

const BodyContainer = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
`);

const PageTitle = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 200px;
  margin-right: 40px;
`);

const TableContainer = styled('div')(`
  font-family: 'Inter';
  width:100%;
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin: 20px auto;
`);

const appType = process.env.REACT_APP_THEME === 'LPL' ? 'LPL' : 'TIFIN';

export const SystemManagement = () => {
  const [tablePage, setTablePage] = useState<number>(0);
  const [searchString, setSearchString] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(15);
  const [dataTotal, setDataTotal] = useState<any>(0);
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const [selectedOrgId, setSelectedOrgId] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);

  const { organization, info } = useStoreState((state) => state.user);

  const { setUser, setSubscriptions, setDemoAccount } = useStoreActions((actions) => actions.user);

  const { setAppType } = useStoreActions((actions) => actions);

  const [showAddOrganization, setShowAddOrganization] = useState<boolean>(false);

  const headerData = [
    {
      header: 'Name',
      key: 'name',
    },
    {
      header: 'ID',
      key: 'id',
    },
    {
      header: 'Demo Organization',
      key: 'isDemoOrg',
      formatData: (data: any) => (data?.isDemoOrg ? 'Yes' : 'No'),
    },
  ];

  const {
    loading: orgLoading,
    data: orgData,
    client,
  } = useQuery(GET_ORGANIZATION, {
    variables: { query: searchString, pageSize, page: tablePage + 1, sortKey },
    onCompleted: (data) => {
      const newTableData = data?.platformManagement?.organizations?.list?.filter((item: any) => !!item);
      console.log('newTableData: ', newTableData);
      setTableData(newTableData);
      setDataTotal(newTableData?.length);
      setSelectedOrg(null);
    },
    errorPolicy: 'all',
    onError: (error) => {
      console.log('error data: ', error);
      // console.log('data: ', data);
    },
  });

  // console.log('data in getOrganization: ', orgData);

  const [selectCreatedOrg, { loading: orgCreationLoading }] = useLazyQuery(GET_ORGANIZATION, {
    variables: { query: '', pageSize: dataTotal, page: 1, sortKey },
    onCompleted: (data) => {
      const newSelectedOrg = data?.platformManagement?.organizations?.list?.find(
        (item: any) => item.id === selectedOrgId,
      );
      if (newSelectedOrg) {
        setSelectedOrg(newSelectedOrg);
      }
    },
  });

  const [revertToDefault, { loading: revertLoading }] = useMutation(REVERT_USER, {
    onCompleted: () => {
      // console.log('data: ', data);
      refreshAppData();
    },
  });

  const [refreshAppData, { loading: loadingProfile }] = useLazyQuery(LOAD_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (profileData) => {
      console.log('reloading profile data!', profileData);
      client.resetStore();
      const userOrganization = profileData?.meAssetManager?.organization;
      // console.log('userOrganization: ', profileAM);
      let loginInfo = {
        user: { ...profileData.meAssetManager, ...profileData?.user },
        success: true,
        organization: userOrganization,
      };
      setDemoAccount(userOrganization?.isDemoOrg);
      setUser(loginInfo);

      let newAppType: AppType = userOrganization?.name === 'Franklin Templeton' ? 'FT' : appType;

      setAppType(newAppType);
      if (profileData?.mySubscriptions) {
        setSubscriptions(formatSubscriptions(profileData?.mySubscriptions));
      }
      setSelectedOrg(null);
    },
  });

  const refreshAndSelect = async (orgId: string) => {
    setSelectedOrgId(orgId);
    selectCreatedOrg();
  };

  const userDetails = [
    { label: 'Name', key: 'lastName', format: (row: any) => formatFullName(row) },
    {
      label: 'Email',
      key: 'email',
      format: (row: any) => {
        return row?.user?.username;
      },
    },
    { label: 'Phone', key: 'phone' },
    { label: 'Organization', key: 'organization' },
    { label: 'Organization Id', key: 'organization', format: () => organization?.id },
  ];

  const toggleShowAddOrganization = () => {
    setShowAddOrganization(!showAddOrganization);
  };

  return (
    <Container>
      <HeaderBlock>
        <HeaderText>System Management</HeaderText>
      </HeaderBlock>
      <BodyContainer>
        {selectedOrg ? (
          <SelectedOrganization
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            refreshAppData={refreshAppData}
            refreshAndSelect={refreshAndSelect}
          />
        ) : (
          <>
            <Row style={{ margin: '0px 0px 10px' }}>
              <CardContainer style={{ maxWidth: '600px', margin: '0px 0px 30px' }}>
                <CardHeader style={{ justifyContent: 'space-between' }}>
                  <span>Current User</span>
                  <PrimaryButtonShort
                    style={{
                      width: '200px',
                      margin: '0px 0px',
                    }}
                    onClick={() => {
                      revertToDefault();
                    }}
                  >
                    Revert to default user
                  </PrimaryButtonShort>
                </CardHeader>
                {loadingProfile || orgCreationLoading || revertLoading ? (
                  <CardBody>
                    <Loader inputMargin={'66px 250px'} />
                  </CardBody>
                ) : (
                  <CardBody>
                    {userDetails.map((item: any, index: number) => (
                      <Row
                        key={129314 + index}
                        style={{ justifyContent: 'space-between', height: '36px', padding: '0px 0px' }}
                      >
                        <F3 style={{ color: colors.grey_02 }}>{item.label}</F3>
                        {/* @ts-ignore */}
                        <F3 style={{ color: colors.grey_02 }}>{item.format ? item.format(info) : info?.[item.key]}</F3>
                      </Row>
                    ))}
                  </CardBody>
                )}
              </CardContainer>
            </Row>
            <PageTitle>Organizations</PageTitle>
            <F3 style={{ color: colors.grey_02, margin: '10px 0px' }}>
              Below are listed all of the different organizations currently using the platform
            </F3>
            <PrimaryButtonShort
              style={{
                width: '200px',
              }}
              onClick={toggleShowAddOrganization}
            >
              Create New Organization
            </PrimaryButtonShort>
            <TableContainer>
              <GeneralTable
                headerData={headerData}
                search={true}
                hideShadow
                data={orgData?.platformManagement?.organizations?.list?.filter((item: any) => !!item)}
                dataControls={{
                  selections: [],
                  setSelections: [],
                  searchFunc: setSearchString,
                  searchString,
                }}
                paginationControls={{
                  tablePage,
                  setTablePage,
                  pageSize,
                  setPageSize,
                  sortKey,
                  setSortKey,
                  totalItems: orgData?.platformManagement?.organizations?.totalItems,
                }}
                onRowPress={(row: any) => setSelectedOrg(row)}
                newItems={[]}
                loading={orgLoading}
                rowsInput={pageSize}
                rowsInputOption={[5, 15, 25]}
              />
            </TableContainer>
          </>
        )}
      </BodyContainer>
      <AddOrganizationModal
        modalStatus={showAddOrganization}
        closeModal={toggleShowAddOrganization}
        refreshAndSelect={refreshAndSelect}
      />
    </Container>
  );
};
