import React, { useState } from 'react';
import { colors } from '../../styles';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import {
  Column,
  H1,
  H2,
  H3,
  H4,
  ModalRoot,
  ModalContainer,
  ModalHeader,
  ModalFooter,
  F3,
  PrimaryButtonShort,
  Title,
  Divider,
  Row,
} from '..';
import closeIcon from '../../assets/icons/close.svg';
import ComingSoonModal from '../ComingSoonModal';
import { styled } from '@mui/material/styles';
import { formatPackageName, formatNumber } from '../../helpers/dataFormatter';

export const ModalBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '20px 32px',
});

const TableCell = styled('div')({
  display: 'flex',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: colors.grey_03,
  padding: '8px 16px',
  border: '1px solid #E5E5E5',
  backgroundColor: colors.grey_00,
});

const CloseButton = styled(IconButton)({
  marginRight: '10px',
});

interface Props {
  nodeData: any;
  setNodeData: any;
}

export const DataNodeModal = ({ nodeData, setNodeData }: Props) => {
  const copy = { title: 'Data Table Details', header: nodeData?.data?.name, body: 'This is the body of the modal' };

  return (
    <ModalRoot
      open={!!nodeData}
      onClose={() => setNodeData(null)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer sx={{ padding: '4px 0px', width: '800px' }}>
        <ModalBody style={{ width: '100%' }}>
          <H1 style={{ color: colors.grey_03 }}>{copy?.header}</H1>
          <Divider style={{ margin: '16px 0px' }} />
          <F3 style={{ color: colors.grey_02 }}>Table Category: {formatPackageName(nodeData?.data?.category)}</F3>
          <F3 style={{ color: colors.grey_02 }}>Data Source: {formatPackageName(nodeData?.data?.source)}</F3>
          <F3 style={{ color: colors.grey_02 }}>Data Type: {formatPackageName(nodeData?.data?.type)}</F3>
          <F3 style={{ color: colors.grey_02 }}>Row Count: {formatNumber(nodeData?.data?.rowCount)}</F3>
          {nodeData?.data?.columns?.length > 0 ? (
            <>
              <Divider style={{ margin: '16px 0px' }} />
              <H3 style={{ color: colors.grey_03 }}>Table Columns</H3>
              <Row style={{ overflowX: 'scroll', maxWidth: '740px' }}>
                {nodeData?.data?.columns?.map((name: string, index: number) => (
                  <TableCell key={index + 129843}>{name}</TableCell>
                ))}
              </Row>
            </>
          ) : null}
        </ModalBody>
        <ModalFooter style={{ width: '100%', padding: '10px 16px 8px' }}>
          <PrimaryButtonShort
            onClick={() => setNodeData(null)}
            style={{ background: colors.grey_02, border: `1px solid ${colors.grey_02}`, margin: 'auto 4px auto 0px' }}
          >
            Close
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default DataNodeModal;
