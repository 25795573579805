import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import Loader from '../../components/Loader';
import GeneralTable from '../../components/Table';
import CheckIcon from '@mui/icons-material/Check';
import { useStoreState } from '../../store';
import { useQuery, useMutation } from '@apollo/client';
import EditDataModal from '../../components/EditDataModal/index';
import { GET_FUNDS, UPDATE_FUND_PREFS } from '../../api/dataAPI';

type Props = {};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: table;
`);

const PageHeader = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 100%;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
`);

const Body = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #758790;
  width: 50%;
  min-width: 200px;
`);

const TableContainer = styled('div')(`
  font-family: 'Inter';
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin: 20px auto;
`);

export const Funds = (props: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [sortKey, setSortKey] = useState('');
  const [showEditDataModal, setShowEditDataModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(false);
  const appType = useStoreState((state) => state.appType);

  const {
    loading,
    data: fundData,
    error,
    refetch,
  } = useQuery(GET_FUNDS, {
    fetchPolicy: 'network-only',
    variables: { query: searchString, pageSize, page: tablePage + 1, sortKey },
  });

  const [updateFundPrefs] = useMutation(UPDATE_FUND_PREFS);

  const toggleEditDataModal = (selectedItem: any) => {
    //In the future we will probably need to add the user as a state variable
    setShowEditDataModal(!showEditDataModal);
    if (selectedItem) {
      setSelectedItem(selectedItem);
    } else {
      setSelectedItem(null);
    }
  };

  const headerData = [
    {
      header: 'Symbol',
      key: 'ticker',
    },
    {
      header: 'Type',
      key: 'vehicle',
    },
    {
      header: 'Name',
      key: 'name',
    },
    {
      header: 'Ignore This Fund',
      key: 'hidden',
      sortKey: null,
      action: async (funds: any, status: any) => {
        updateFundPrefs({ variables: { fundIds: funds, hidden: status } });
        refetch();
      },
    },
  ];

  const lplHeaderData = [
    {
      header: 'Symbol',
      key: 'ticker',
    },
    {
      header: 'Type',
      key: 'vehicle',
    },
    {
      header: 'Name',
      key: 'name',
    },
    {
      header: 'Available On Platform',
      key: 'available',
      sortKey: null,
      formatData: () => {
        return (
          <div
            style={{
              borderRadius: '5px',
              margin: '0px 60px 0px',
              padding: '0px 5px 0px 6px',
              height: '26px',
              width: '36px',
              background: colors.green,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckIcon style={{ color: colors.white }} />
          </div>
        );
      },
    },
    {
      header: 'Ignore This Fund',
      key: 'hidden',
      sortKey: null,
      action: async (funds: any, status: any) => {
        updateFundPrefs({ variables: { fundIds: funds, hidden: status } });
        refetch();
      },
    },
  ];

  return (
    <Container>
      <PageHeader>Funds</PageHeader>
      <Body>
        Our algorithms link funds to themes based on several factors such as name, description, exposures, and holdings.
        These themes are used in several packages to drive engagement with investors based on their interests. Select a
        fund to view or edit themes associated with it.
      </Body>
      <TableContainer>
        {fundData ? (
          <GeneralTable
            headerData={appType === 'LPL' ? lplHeaderData : headerData}
            search={true}
            hideShadow
            data={fundData?.funds?.list}
            dataControls={{
              selections: [],
              setSelections: [],
              searchFunc: setSearchString,
              searchString,
            }}
            paginationControls={{
              tablePage,
              setTablePage,
              pageSize,
              setPageSize,
              sortKey,
              setSortKey,
              totalItems: fundData?.funds?.totalItems,
            }}
            onRowPress={toggleEditDataModal}
            newItems={[]}
            loading={loading}
            rowsInput={pageSize}
            rowsInputOption={[5, 15, 25]}
            getData={refetch}
          />
        ) : (
          <Loader inputMargin={'20% 46%'} />
        )}
      </TableContainer>
      <EditDataModal
        dataType={'Funds'}
        modalStatus={showEditDataModal}
        closeModal={toggleEditDataModal}
        selectedData={selectedItem}
        // refresh={refreshData}
      />
    </Container>
  );
};
