import {
  states,
  firmAUM,
  age,
  firmAccounts,
  type,
  custodian,
  aum,
  careerTenure,
  lplAum,
  lplTenure,
  lplCashAssets,
  lplEtfAssets,
  lplNetNewAssets,
  lplClientFlows,
  lplMorningstarCategories,
  lplCareerTenure,
} from '../../helpers/menuOptions';

export const advisorFilterOptions = (formattedAdvisorTags: any[]) => [
  {
    label: 'AUM',
    options: aum,
    list: true,
    multiple: true,
    width: '150px',
  },
  {
    label: 'Firm Size',
    options: firmAccounts,
    list: true,
    multiple: true,
    width: '100px',
  },
  {
    label: 'Career Tenure',
    options: careerTenure,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Type (BD/RIA)',
    options: type,
    list: true,
    multiple: true,
    width: '100px',
  },
  {
    label: 'Location',
    options: states,
    list: true,
    multiple: true,
    width: '100px',
  },
  {
    label: 'Custodian',
    options: custodian,
    list: true,
    multiple: true,
    width: '100px',
  },
  {
    label: 'Custom Tag',
    options: formattedAdvisorTags,
    list: true,
    multiple: true,
    width: '140px',
  },
];

export const lplClientFilterOptions = (formattedAdvisorTags: any[]) => [
  {
    label: 'AUM',
    options: lplAum,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Cash Assets',
    options: lplCashAssets,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'ETF Assets',
    options: lplEtfAssets,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Net New Assets (Past Year)',
    options: lplNetNewAssets,
    list: true,
    input: true,
    width: '150px',
  },
  {
    label: 'LPL Tenure',
    options: lplTenure,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Location',
    options: states,
    list: true,
    multiple: true,
    width: '100px',
  },
  {
    label: 'Morningstar Categories',
    options: lplMorningstarCategories,
    list: true,
    multiple: true,
    width: '100px',
  },
];

export const lplFilterOptions = (formattedAdvisorTags: any[]) => [
  {
    label: 'AUM',
    options: lplAum,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Cash Assets',
    options: lplCashAssets,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'ETF Assets',
    options: lplEtfAssets,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Net New Assets (Past Year)',
    options: lplNetNewAssets,
    list: true,
    input: true,
    width: '150px',
  },
  {
    label: 'Career Tenure',
    options: lplCareerTenure,
    list: true,
    input: true,
    width: '110px',
  },
  {
    label: 'Location',
    options: states,
    list: true,
    multiple: true,
    width: '70px',
  },
  {
    label: 'Client Flows (Past Month)',
    type: 'lpl',
    options: lplClientFlows,
    list: true,
    multiple: false,
    singleSelect: true,
    width: '140px',
  },
  {
    label: 'Morningstar Categories',
    type: 'lpl',
    options: lplMorningstarCategories,
    list: true,
    multiple: true,
    width: '200px',
  },
];
