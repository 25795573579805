import React, { useState, useEffect } from 'react';
import { colors } from '../../styles';
import { H2, H3, F3, PrimaryButtonShort, MainTextInput, Row, Column } from '..';
import { styled } from '@mui/system';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, MenuItem } from '@mui/material';
import link_active from '../../assets/icons/link_active.svg';
import link_inactive from '../../assets/icons/link_inactive.svg';
import { cloneDeep } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import GeneralTable from '../Table';
// import { getIntegrationHeaders, previewAdvisorSet } from '../../api/integrations';
import { GET_INTEGRATION_HEADERS, GET_ADVISOR_PREVIEW } from '../../api/integrations';
import SwitchFilter from '../SwitchFilter';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  //   height: '320px',
  margin: '0px auto 0px',
  alignItems: 'center',
  flexDirection: 'column',
});

const FieldTableHeader = styled(TableRow)({
  borderBottom: `1px solid ${colors.grey_01}`,
});

type Props = {
  setModalPage: any;
  setInfoPageCopy: any;
  integrationId: any;
  acceptedFields: any;
  uploadDataset: any;
  advisorSetFilter: any;
  uploadName?: string;
};

const CrmMatchHeaders = ({
  setModalPage,
  setInfoPageCopy,
  uploadDataset,
  integrationId,
  advisorSetFilter,
  acceptedFields,
  uploadName,
}: Props) => {
  const [errors, setErrors] = useState<any>(null);
  const [datasetName, setDatasetName] = useState<any>(uploadName ? uploadName : 'Salesforce Advisor List');
  const [fieldMapping, setFieldMapping] = useState<any>([]);
  // const [fileHeaders, setFileHeaders] = useState<string[]>([]);

  const [crmPreviewData, setCrmPreviewData] = useState<any>([]);
  const [previewTableHeaders, setPreviewTableHeaders] = useState<any>([]);

  const [keepUpToDate, setKeepUpToDate] = useState<boolean>(false);

  useEffect(() => {
    const defaultFields = ['Email', 'FirstName', 'LastName'];
    const uniqueFields = Object.values(fieldMapping).filter(
      (field: any) => field.srcKey !== null && !defaultFields.includes(field.srcKey),
    );
    if (uniqueFields.length > 0) {
      getPreviewData();
    }
    // setFileHeaders(uploadFields);
  }, [fieldMapping]);

  useEffect(() => {
    // getSalesforceHeaders();
    const newFieldMapping: any = defaultMappings();
    getPreviewData();
    setFieldMapping(newFieldMapping);
  }, []);

  const defaultMappings = () => {
    const newFieldMapping: any = {};
    for (let field of acceptedFields) {
      newFieldMapping[field] = { srcKey: null, dstKey: field };
    }
    return newFieldMapping;
  };

  const pageCopy = {
    header: 'Mapping',
    subheader: 'What data would you like to sync with AMP?',
    body: (
      <span>
        Match the headers from your upload to valid fields that can be processby by the AMP algorithms.{' '}
        <i>* Indicates Required Fields</i>
      </span>
    ),
    buttons: [{ text: 'Create Dataset', action: () => uploadDataset(datasetName, Object.values(fieldMapping)) }],
  };

  const formatValidFieldName = (value: string) => {
    switch (value) {
      case 'firstName':
        return 'First Name';
      case 'age':
        return 'Advisor Age';
      case 'aum_self_reported':
        return 'Assets Under Management';
      case 'career_tenure':
        return 'Career Tenure';
      case 'current_firm_tenure_years':
        return 'Current Firm Tenure';
      case 'email':
        return 'Advisor Email*';
      case 'firm_crd':
        return 'Firm CRD';
      case 'firm_etf_assets_millions':
        return 'Firm ETF Assets in Millions';
      case 'firm_mf_assets_millions':
        return 'Firm MF Assets in Millions';
      case 'firm_name':
        return 'Firm Name';
      case 'firm_number_bd_reps':
        return 'Firm Number BD Reps';
      case 'firm_number_ia_reps':
        return 'Firm Number IA Reps';
      case 'firm_total_accounts':
        return 'Firm Total Accounts';
      case 'firm_total_assets_in_millions':
        return 'Firm Total Assets in Millions';
      case 'first_name':
        return 'First Name';
      case 'full_name':
        return 'Full Name';
      case 'is_bd':
        return 'BD Advisor';
      case 'is_ria':
        return 'RIA Advisor';
      case 'last_name':
        return 'Last Name';
      case 'office_state':
        return 'Firm State';
      case 'office_zipcode':
        return 'Firm Postal Code';
      case 'percent_assets_in_etf_and_mf':
        return 'Percent assets in ETFs and MFs';
      case 'repcrd':
        return 'Advisor CRD';
      case 'ria_employee_ratio':
        return 'RIA Employee Ratio';
      case 'tickers_held':
        return 'Tickers Held';
      case 'title':
        return 'Advisor Title';
      default:
        return '-';
    }
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  const checkForErrors = (fieldMapping: any) => {
    setErrors(null);
    const newErrors = [];
    let srcKeys: string[] = [];
    let dstKeys: string[] = [];
    console.log('field mappings: ', fieldMapping);
    for (let map of fieldMapping) {
      if (srcKeys.includes(map?.srcKey)) {
        newErrors.push(`You have ${map?.srcKey} mapped to multiple fields.`);
      } else {
        srcKeys.push(map?.srcKey);
        dstKeys.push(map?.dstKey);
      }
    }
    if (!dstKeys.includes('email')) {
      newErrors.push('You must map advisor email to a field.');
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return newErrors;
    } else {
      return newErrors;
    }
  };

  const [getAdvisorPreview, { loading: loadingPreview }] = useMutation(GET_ADVISOR_PREVIEW, {
    onCompleted: (data) => {
      let previewData = data?.validateAdvisorSetFilter?.preview?.map((item: any) => ({ ...item, ...item?.Contact }));

      if (previewData?.length === 0) {
        previewData = data?.validateAdvisorSetFilter?.preview?.map((item: any) => ({ ...item?.Contact }));
      }

      const newFileHeaders = Object.keys(previewData?.[0]);
      const newTableHeaders = newFileHeaders.map((item: any) => ({ header: item, key: item }));

      setPreviewTableHeaders(newTableHeaders);

      setCrmPreviewData(previewData);
    },
  });

  const getPreviewData = async () => {
    try {
      // const defaultHeaders = ['FirstName', 'LastName', 'Email'];
      const crmHeaders = Object.values(fieldMapping)
        .map((item: any) => item?.srcKey)
        .filter((item: any) => !!item);

      // console.log('preview advisor set: ', {
      //   integrationId,
      //   filterType: advisorSetFilter?.filterType,
      //   filterValue: advisorSetFilter?.filterValue?.filter((item: any) => !!item),
      //   crmHeaders,
      // });
      getAdvisorPreview({
        variables: {
          integrationId: Number(integrationId),
          filterType: advisorSetFilter?.filterType,
          filterConfig: advisorSetFilter?.filterValue?.filter((item: any) => !!item),
          crmProperties: crmHeaders,
        },
      });
    } catch (e) {
      console.warn('Error getting preview data: ', e);
    }
  };

  const { data: fileHeaders } = useQuery(GET_INTEGRATION_HEADERS, { variables: { integrationId } });

  // const getSalesforceHeaders = async () => {
  //   try {
  //     const newFileHeaders = await getIntegrationHeaders(integrationId);
  //     setFileHeaders(newFileHeaders);
  //   } catch (e) {
  //     console.warn('Error getting preview data: ', e);
  //   }
  // };

  return (
    <Column
      style={{
        flex: 1,
        margin: '0px 0px 0px',
        justifyContent: 'space-between',
        // overflowY: 'scroll',
      }}
    >
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <Row>
          <MainTextInput
            label="Name"
            size="small"
            sx={{ margin: '12px 0px 0px -16px', width: '300px' }}
            value={datasetName}
            focused
            onChange={(event) => setDatasetName(event.target.value)}
          />
        </Row>
        <F3 style={{ color: colors.grey_02, width: '660px', margin: '8px 0px' }}>{pageCopy.subheader}</F3>
        <div
          style={{
            // width: '100%',
            width: '660px',
            boxShadow: '',
            border: `1px solid ${colors.grey_01}`,
            borderRadius: '8px',
            padding: '10px 0px 0px',
            overflowX: 'scroll',
            overflowY: 'scroll',
          }}
        >
          <H3 style={{ margin: '10px 16px', color: colors.grey_02 }}>Preview</H3>
          <GeneralTable
            headerData={previewTableHeaders}
            data={crmPreviewData.slice(0, 3)}
            dataControls={{
              selections: {},
              setSelections: () => {},
            }}
            hideShadow
            singleSelect
            onRowPress={(row: any) => {
              //   setSelectedReport(row);
            }}
            // initSortColumn={'-Email'}
            rowsInput={3}
            rowsInputOption={[3, 6, 10]}
            search={false}
            loading={loadingPreview}
            hidePaginationControls
          />
        </div>
        <F3 style={{ color: colors.grey_02, width: '660px' }}>{pageCopy.body}</F3>
      </TextContainer>
      <TableContainer style={{ width: '660px', margin: '0px 20px', height: '500px' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <FieldTableHeader>
              {previewTable.header.map((header, index) => {
                return (
                  <TableCell
                    sx={{
                      fontSize: 14,
                      color: '#758790',
                    }}
                    key={index}
                  >
                    {header?.label}
                  </TableCell>
                );
              })}
            </FieldTableHeader>
          </TableHead>
          <TableBody>
            {Object.values(fieldMapping).map((row: any, index: number) => {
              return (
                <TableRow style={{ height: '60px' }}>
                  {previewTable.header.map((header: any, index) => {
                    if (header?.key === 'srcKey') {
                      return (
                        <TableCell
                          key={index + 21401234}
                          sx={{
                            fontSize: 14,
                            color: '#758790',
                          }}
                        >
                          <MainTextInput
                            // label="Data Type"
                            size="small"
                            style={{ margin: '-8px 0px 0px 0px', width: '300px', height: '12px' }}
                            value={row?.srcKey ? row?.srcKey : 0}
                            /*className={classes.fieldOverride}*/
                            select
                            focused
                            //@ts-ignore
                            onChange={(event) => {
                              const newFieldMapping = cloneDeep(fieldMapping);
                              newFieldMapping[row.dstKey] = { srcKey: event.target.value, dstKey: row.dstKey };
                              setFieldMapping(newFieldMapping);
                            }}
                          >
                            <MenuItem
                              key={index + 149304}
                              value={0}
                              //sx={{ overflowX: 'auto' }}
                              sx={{
                                fontFamily: 'Inter',
                              }}
                            >
                              <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{'-'} </F3>
                            </MenuItem>
                            {fileHeaders?.crm?.contactProperties?.map((item: any, index: number) => (
                              <MenuItem
                                key={index + 1249104124}
                                value={item}
                                //sx={{ overflowX: 'auto' }}
                                sx={{
                                  fontFamily: 'Inter',
                                }}
                              >
                                <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{item} </F3>
                              </MenuItem>
                            ))}
                          </MainTextInput>
                        </TableCell>
                      );
                    }
                    if (header?.key === 'status') {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            //   border: '0.5px solid #C2CBCF',
                            fontSize: 14,
                            color: '#758790',
                          }}
                        >
                          <img
                            style={{ height: '14px', width: '14px', margin: '4px 12px 0px' }}
                            src={!!row.srcKey ? link_active : link_inactive}
                            alt={'link'}
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          //   border: '0.5px solid #C2CBCF',
                          fontSize: 14,
                          color: '#758790',
                        }}
                      >
                        {row?.[header?.key] ? formatValidFieldName(row?.[header?.key]) : '-'}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {errors && <F3 style={{ margin: '16px 20px', color: colors.red }}>{errors[0]}</F3>}
      {pageCopy.buttons && (
        <Row
          style={{
            height: '50px',
            margin: '0px -26px 0px -26px',
            padding: '16px 16px 0px',
            // width: '110%',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderTop: `1px solid ${colors.grey_01}`,
          }}
        >
          <SwitchFilter copy={'Keep List Up to Date'} value={keepUpToDate} setValue={setKeepUpToDate} />
          <PrimaryButtonShort style={{ width: '160px' }} onClick={() => uploadDataset(datasetName, fieldMapping)}>
            {'Create Advisor List'}
          </PrimaryButtonShort>
        </Row>
      )}
    </Column>
  );
};

export default CrmMatchHeaders;
