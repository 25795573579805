import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../styles';

import { styled } from '@mui/system';
import downArrowGray from '../../assets/icons/arrow-down-grey.svg';
import { formatTimestamp, formatPackageName, formatPercent } from '../../helpers/dataFormatter';
import { useStoreState } from '../../store';
import moment from 'moment';
import { SectionPageHeader } from '../SectionPageHeader';
import EventChart from '../EventChart';
import ClickChart from '../ClickChart';
import { useNavigate } from 'react-router-dom';
import { IconButton, Modal } from '@mui/material';
import close_icon from '../../assets/icons/close.svg';
import { CardHeader } from '..';
import AmplitudeChart from '../AmplitudeChart';
import CampaignPerformanceCard from '../CampaignPerformanceCard';

const useStyles = makeStyles((theme) => ({
  depthIcon: {
    height: '12px',
    width: '12px',
    margin: '20px 8px 0px',
  },
  cardContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: colors.white_00,
    border: `1px solid ${colors.grey_00}`,
    borderRadius: '8px',
    boxShadow: colors.main_box_shadow,
  },
  pageTitle: {
    '&:hover': {
      borderBottom: '1px solid ${colors.grey_03}',
      cursor: 'pointer',
    },
  },
  pageHeader: {
    // margin: '10px 0px 4px',
  },
  header: {
    ...typography.mainFont,
    display: 'flex',
    padding: '16px 16px',
    color: colors.grey_03,
    fontWeight: '500',
    fontSize: '16px',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  campaignInfoHeader: {
    ...typography.mainFont,
    fontSize: '14px',
    fontWeight: '500',
    color: colors.grey_03,
  },
  campaignInfoP: {
    ...typography.mainFont,
    fontSize: '12px',
    fontWeight: '400',
    color: colors.grey_02,
  },
  campaignInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
  },
  campaignInfoTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
    padding: '0px 4px',
    '&:hover': {
      backgroundColor: colors.grey_01,
      borderRadius: '5px',
      cursor: 'pointer',
    },
  },
  campaignInfoCellRight: {
    ...typography.mainFont,
    fontSize: '12px',
    minWidth: '200px',
    display: 'flex',
    flex: 1,
    fontWeight: '400',
    textAlign: 'right',
    justifyContent: 'flex-end',
    color: colors.grey_02,
  },
  campaignInfoCellLeft: {
    ...typography.mainFont,
    fontSize: '12px',
    minWidth: '200px',
    display: 'flex',
    flex: 1,
    fontWeight: '400',
    textAlign: 'left',
    justifyContent: 'flex-start',
    color: colors.grey_02,
  },
  cardBody: {
    padding: '8px 16px',
  },
}));

const ModalContainer = styled('div')({
  height: '680px',
  margin: '90px',
  padding: '0px 24px 20px',
  background: colors.white_01,
  overflow: 'scroll',
  borderRadius: '5px',
});

interface Props {
  campaign: any;
  openArchive?: any;
  setCampaign: any;
}

const CampaignEngagement = ({ campaign, openArchive, setCampaign }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  const sendDate = moment(campaign?.dateCreated).add(20, 'm');

  const demoAccount = useStoreState((state) => state.user.demoAccount);

  const exit = () => {
    setError(null);
    setCampaign(null);
  };

  const campaignDetails = [
    { label: 'Name', key: 'name' },
    { label: 'Description', key: 'description' },
    {
      label: 'Status',
      key: 'status',
      formatData: (row: any) => {
        console.log('dateCreated: ', moment().diff(moment(row.dateCreated), 'days'));
        return moment().diff(moment(row.dateCreated), 'days') > 5 ? 'Complete' : formatPackageName(row.status);
      },
    },
    // { label: 'Platforms', key: 'platforms', formatData: (row:any) => 'Email' },
    { label: 'Target', key: 'package', formatData: (row: any) => formatPackageName(row.run.package) },
    { label: 'Date Created', key: 'dateCreated', formatData: (row: any) => formatTimestamp(row.dateCreated) },
  ];

  const emailDetails = [
    {
      label: 'Subject',
      key: 'subject',
      formatData: (row: any) => (row.nurtureemailSet?.[0]?.subject ? row.nurtureemailSet?.[0]?.subject : '-'),
    },
    {
      label: 'Status',
      align: 'Right',
      key: 'status',
      //   formatData: (row:any) => formatPackageName(row.nurtureemailSet?.[0]?.status),
      formatData: (row: any) => (moment().isBefore(sendDate) ? 'Pending' : 'Sent'),
    },
    {
      label: 'Send Date',
      key: 'sendDatetime',
      align: 'Right',
      formatData: (row: any) => (moment().isBefore(sendDate) ? '-' : formatTimestamp(sendDate)),
    },
    // { label: 'Platforms', key: 'platforms', formatData: (row:any) => 'Email' },
    // { label: 'Target', key: 'package', formatData: (row:any) => 'Custom Target' },
  ];

  console.log('in engagement modal: ', campaign);

  return (
    <Modal
      open={!!campaign}
      onClose={() => setCampaign(null)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {!!campaign ? (
        <ModalContainer>
          <CardHeader
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${colors.grey_01}`,
              margin: '0px -24px 10px',
              padding: '0px 24px',
            }}
          >
            <div style={{ display: 'flex' }}>
              <p className={classes.pageTitle} onClick={() => setCampaign(null)}>
                Engagement for {campaign?.name}
              </p>
            </div>
            <div>
              <IconButton sx={{ margin: '12px 0px 0px' }}>
                <img src={close_icon} onClick={exit} />
              </IconButton>
            </div>
          </CardHeader>
          <div style={{ display: 'flex' }}>
            <div className={classes.cardContainer} style={{ margin: '16px 8px 0px 0px' }}>
              <div className={classes.header}>
                <span>Campaign Details</span>
              </div>
              <div className={classes.cardBody}>
                {campaignDetails.map((item, index) => (
                  <div
                    key={index + 479724}
                    className={classes.campaignInfoRow}
                    style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
                  >
                    <p className={classes.campaignInfoP}>{item.label}</p>
                    <p className={classes.campaignInfoP}>
                      {item.formatData ? item.formatData(campaign) : campaign[item.key]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.cardContainer} style={{ margin: '16px 0px 0px 8px' }}>
              <div className={classes.header}>
                <span>Most Recent Email</span>
              </div>
              <div className={classes.cardBody}>
                {emailDetails.map((item, index) => (
                  <div
                    key={index + 492304}
                    className={classes.campaignInfoRow}
                    style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
                  >
                    <p className={classes.campaignInfoP}>{item.label}</p>
                    <p className={classes.campaignInfoP}>
                      {item.formatData ? item.formatData(campaign) : campaign[item.key]}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <CampaignPerformanceCard campaign={campaign} />
            <AmplitudeChart
              title={'Recent events in this campaign'}
              style={{ margin: '16px 0px 0px 8px' }}
              chartData={campaign?.eventsOverTime}
              demoMode={demoAccount}
            />
          </div>
          <div>
            <div className={classes.cardContainer} style={{ margin: '16px 0px 0px 0px' }}>
              <div className={classes.header}>
                <span>Emails for Campaign</span>
              </div>
              <div className={classes.cardBody}>
                <div key={8394492304} className={classes.campaignInfoRow} style={{ margin: '-16px 0px 0px' }}>
                  {emailDetails.map((item) => (
                    <p
                      className={item.align === 'Right' ? classes.campaignInfoCellRight : classes.campaignInfoCellLeft}
                      style={{
                        fontWeight: '500',
                        margin: '20px 0px 0px',
                        padding: '10px 0px',
                        borderBottom: `1px solid ${colors.grey_01}`,
                      }}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>
                {campaign.nurtureemailSet.map((item: any, index: number) => (
                  <div className={classes.campaignInfoTableRow} style={{}}>
                    {emailDetails.map((item) => (
                      <>
                        <p
                          className={
                            item.align === 'Right' ? classes.campaignInfoCellRight : classes.campaignInfoCellLeft
                          }
                        >
                          {item.formatData ? item.formatData(campaign) : campaign[item.key]}
                        </p>{' '}
                      </>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalContainer>
      ) : (
        <div />
      )}
    </Modal>
  );
};

export default CampaignEngagement;
