import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, H3, PrimaryButton } from '..';
import { colors } from '../../styles';
import { formatPackageName } from '../../helpers/dataFormatter';
import { GET_INTEGRATIONS } from '../../api/integrations';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

const TableRow = styled('div')({
  width: '600px',
  display: 'flex',
  height: '46px',
  padding: '0px 12px',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: `1px solid ${colors.grey_01}`,
  ':hover': {
    background: colors.grey_01,
  },
});

type Props = {
  updateIntegrationId: any;
  setModalPage: any;
  pageCopy?: any;
};

const defaultPageCopy = {
  header: 'Salesforce Integrations',
  subheader: (
    <span>
      Here we have listed the Salesforce instances that you have previously connected. You may click on an existing
      instance to intiate a new data pull.
    </span>
  ),
};

const SetJourneyIntegration = ({ updateIntegrationId, setModalPage, pageCopy = defaultPageCopy }: Props) => {
  const [integrationsList, setIntegrationList] = useState<any[]>([]);
  const [listSize, setListSize] = useState<number>(1);
  const navigate = useNavigate();

  const { loading } = useQuery(GET_INTEGRATIONS, {
    onCompleted: (data) => {
      const salesforceIntegrations = data?.integrations
        ?.filter((integration: any) => integration?.type !== 'CSV')
        .reverse();
      setIntegrationList(salesforceIntegrations ?? []);
    },
  });

  // useEffect(() => {
  //   const getIntegrations = async () => {
  //     try {
  //       const response = await listIntegrations();
  //       // console.log('response in getIntegrations: ', response);
  //       const salesforceIntegrations = response?.filter((integration: any) => integration?.type !== 'CSV').reverse();
  //       setIntegrationList(salesforceIntegrations);
  //     } catch (error: any) {
  //       console.warn('error getting hubspot integrations: ', error?.message);
  //     }
  //   };

  //   getIntegrations();
  // }, []);

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 40px' }}>
          {pageCopy.subheader}
        </F3>
      </TextContainer>
      <Row style={{ margin: '0px 0px 6px', width: '600px', justifyContent: 'space-between' }}>
        <H3 style={{ width: '280px', margin: '0px 10px', color: colors.grey_02 }}>Nickname</H3>
        <H3 style={{ color: colors.grey_02, width: '160px' }}>Type</H3>
        <H3 style={{ color: colors.grey_02, width: '180px' }}>Date Created</H3>
      </Row>
      <Column
        style={{
          maxHeight: '320px',
          borderTop: `1px solid ${colors.grey_01}`,
          borderBottom: `1px solid ${colors.grey_01}`,
          overflowY: 'scroll',
        }}
      >
        {integrationsList
          ?.slice(-5 * listSize)
          .reverse()
          ?.map((integration: any, index: number) => (
            <TableRow
              style={{
                justifyContent: 'space-between',
              }}
              onClick={() => {
                updateIntegrationId(integration?.id);
              }}
              key={index + 12940138541}
            >
              <H3 style={{ width: '280px', overflowX: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {integration?.nickname}
              </H3>
              <H3 style={{ width: '160px' }}>{formatPackageName(integration?.type)}</H3>
              <F3 style={{ color: colors.grey_02, width: '160px' }}>
                {moment(integration?.dateCreated).format('M/DD/YYYY - LT')}
              </F3>
            </TableRow>
          ))}
        <TableRow onClick={() => setListSize(listSize + 1)}>
          <F3 style={{ color: colors.grey_02 }}>Show More...</F3>
        </TableRow>
      </Column>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton style={{ width: '160px' }} onClick={() => navigate('/unify/integrations')}>
          Create New Integration
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default SetJourneyIntegration;
