import { Action, Thunk, action, thunk } from 'easy-peasy';

export interface IntegrationsModel {
  sfLinked: boolean;
  tifinLinked: boolean;
  showUploadModal: boolean;
  salesforceImport: boolean;
  jobUpdate: any;
  uploadType: any;
  sfIntegrationData: Array<string>;
  newAdvisors: Array<any>;
  activeIntegrationJobs: Array<any>;

  setSalesforceImport: Action<IntegrationsModel, any>;
  setShowUploadModal: Action<IntegrationsModel, any>;
  setSfLinked: Action<IntegrationsModel, any>;
  setTifinLinked: Action<IntegrationsModel, any>;
  setSfIntegrationData: Action<IntegrationsModel, any>;
  setNewAdvisors: Action<IntegrationsModel, any>;
  setUploadType: Action<IntegrationsModel, any>;
  setJobUpdate: Action<IntegrationsModel, any>;
  setActiveIntegrationJobs: Action<IntegrationsModel, any>;
}

export const integrations: IntegrationsModel = {
  //This needs clean up! Don't know if any of this is actually used.
  showUploadModal: false,
  sfLinked: false,
  tifinLinked: false,
  salesforceImport: false,
  sfIntegrationData: [],
  newAdvisors: [],
  activeIntegrationJobs: [],

  jobUpdate: null,
  uploadType: null,

  setSalesforceImport: action((state, payload) => {
    state.salesforceImport = payload;
  }),

  setSfLinked: action((state, payload) => {
    state.sfLinked = payload;
  }),

  setTifinLinked: action((state, payload) => {
    state.tifinLinked = payload;
  }),

  setShowUploadModal: action((state, payload) => {
    state.showUploadModal = payload;
  }),

  setSfIntegrationData: action((state, payload) => {
    state.sfIntegrationData = payload;
  }),

  setNewAdvisors: action((state, payload) => {
    state.newAdvisors = payload;
  }),

  setUploadType: action((state, payload) => {
    state.uploadType = payload;
  }),
  setJobUpdate: action((state, payload) => {
    state.jobUpdate = payload;
  }),
  setActiveIntegrationJobs: action((state, payload) => {
    state.activeIntegrationJobs = payload;
  }),
};
