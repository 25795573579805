import React, { useEffect } from 'react';
import { ModalContainer, ModalRoot } from '..';
import { IconButton } from '@mui/material';

import Landing from '../UploadModal/Landing';
import { EXPORT_TO_INTEGRATION } from '../../api/integrations';
import InfoView from '../UploadModal/InfoView';
//@ts-ignore
import close_icon from '../../assets/icons/close.svg';
import NurtureIntegrations from './NurtureIntegrations';
import SetJourneyIntegration from './SetJourneyIntegration';
import CrmStatusCard from '../CrmStatusCard';
import { useMutation, useQuery } from '@apollo/client';

type Props = {
  UPDATE_JOURNEY_QUERY: any;
  GET_EXPORT_STATUS: any;
  modalPage: string | null;
  setModalPage: any;
  closeModal: any;
  runId: number;
  journeyId?: number;
  getJobStatus?: any;
  integrationDetails?: any;
  integrationJob?: any;
  setIntegrationJob?: any;
  // getIntegrationDetails?: any;
  packageName: string;
  infoPageCopy?: infoPageCopyType;
  setInfoPageCopy?: any;
  exportFilters?: any;
  exportCount?: number;
};

type infoPageCopyType = {
  header: string;
  subheader?: string;
  loader: boolean;
};

function ExportModal({
  UPDATE_JOURNEY_QUERY,
  GET_EXPORT_STATUS,
  modalPage,
  setModalPage,
  closeModal,
  runId,
  getJobStatus,
  packageName,
  integrationDetails,
  integrationJob,
  setIntegrationJob,
  journeyId,
  setInfoPageCopy,
  infoPageCopy,
  exportFilters,
  exportCount,
}: Props) {
  useEffect(() => {
    if (!modalPage) {
      setInfoPageCopy(null);
    }
  }, []);

  const [pushToIntegration] = useMutation(EXPORT_TO_INTEGRATION, {
    onCompleted: (data) => {
      if (data?.exportRunHistory?.jobId) {
        setInfoPageCopy({
          header: 'Lead Export Job Created',
          subheader: 'The leads from your run should populate in Sendgrid soon.',
          loader: false,
        });
      } else {
        setInfoPageCopy({
          header: 'Error exporting leads',
          subheader: `An unknown error occurred while exporting leads to Sendgrid (${data?.exportRunHistory?.errors?.join(
            ', ',
          )})`,
          loader: false,
        });
      }
    },
    onError: (error) => {
      setInfoPageCopy({
        header: 'Error exporting leads',
        subheader: `An unknown error occurred while exporting leads to Sendgrid (${error?.message})`,
        loader: false,
      });
    },
  });

  const [saveJourneyIntegration] = useMutation(UPDATE_JOURNEY_QUERY, {
    onCompleted: (data) => {
      if (data?.updateJourney?.success) {
        setInfoPageCopy({
          header: 'Journey Linked to your CRM',
          subheader: 'The leads from your run should populate in your instance soon.',
          loader: false,
        });
        // setJobId(integrationResponse?.details);
        setTimeout(() => {
          getJobStatus();
        }, 1000);
      } else {
        setInfoPageCopy({
          header: 'Error exporting leads',
          subheader: `An unknown error occurred while exporting leads to integration (${data?.updateJourney?.traceback})`,
          loader: false,
        });
      }
    },
    onError: (error) => {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Error linking Journey to your CRM',
        subheader: `An unknown error occurred during the linking process (${error?.message})`,
        loader: false,
      });
    },
  });

  const refreshData = async () => {};

  const updateJourneyIntegration = async (id: string) => {
    try {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Linking Journey to your CRM',
        subheader: 'The leads from your journey will automatically be exported to your CRM.',
        loader: true,
      });
      if (journeyId) {
        console.log('journeyId: ', journeyId, ' integrationId: ', id);
        saveJourneyIntegration({ variables: { journeyId, integrationId: Number(id) } });
        // console.log('integrationResponse: ', integrationResponse);
      }
    } catch (error: any) {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Error linking Journey to your CRM',
        subheader: `An unknown error occurred during the linking process (${error?.message})`,
        loader: false,
      });
    }
  };

  const exportNurtureLeads = async (id: string) => {
    try {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Sendgrid export job created',
        subheader: 'The leads from your run should begin populating in Sendgrid soon.',
        loader: true,
      });
      pushToIntegration({ variables: { runHistoryId: String(runId), integrationId: id } });
      // console.log('integrationResponse: ', integrationResponse);
    } catch (error: any) {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Error exporting leads',
        subheader: `An unknown error occurred while exporting leads to Sendgrid (${error?.message})`,
        loader: false,
      });
    }
  };

  const PageSelector = (page?: string | null) => {
    switch (page) {
      case 'Landing':
        return <Landing setModalPage={setModalPage} />;
      case 'InfoView':
        return <InfoView pageCopy={infoPageCopy} />;
      case 'SetJourneyIntegration':
        return (
          <SetJourneyIntegration
            pageCopy={{
              header: 'Export to CRM',
              subheader: (
                <span>
                  Here are the CRM instances that you have previously connected. Please select an existing instance to
                  export your results.
                </span>
              ),
            }}
            updateIntegrationId={updateJourneyIntegration}
            setModalPage={setModalPage}
          />
        );
      case 'ExportStatusView':
        return (
          <div style={{ padding: '40px 0px 0px', display: 'flex', flex: 1, width: '500px' }}>
            <CrmStatusCard
              runId={runId}
              getJobStatus={getJobStatus}
              GET_EXPORT_STATUS={GET_EXPORT_STATUS}
              packageName={packageName}
              integrationDetails={integrationDetails}
              exportFilters={exportFilters}
              integrationJob={integrationJob}
              setIntegrationJob={setIntegrationJob}
              setInfoPageCopy={setInfoPageCopy}
              setExportModalPage={setModalPage}
              refresh={refreshData}
              exportCount={exportCount}
            />
          </div>
        );
      case 'NurtureIntegrations':
        return (
          <NurtureIntegrations
            pageCopy={{
              header: 'Export to Sendgrid',
              subheader: (
                <span>
                  Here are the Sendgrid instances that you have previously connected. Please select an existing instance
                  to export your results.
                </span>
              ),
            }}
            exportNurtureLeads={exportNurtureLeads}
            setModalPage={setModalPage}
          />
        );
      case null:
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <ModalRoot open={!!modalPage} onClose={closeModal}>
      <ModalContainer
        style={{
          minHeight: '440px',
          maxHeight: '800px',
          minWidth: '740px',
          overflowY: 'scroll',
          padding: '12px 16px',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            height: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <IconButton onClick={closeModal} sx={{ margin: '4px 0px 0px', height: '16px', width: '16px' }}>
            <img src={close_icon} alt={'Close'} />
          </IconButton>
        </div>
        {PageSelector(modalPage)}
      </ModalContainer>
    </ModalRoot>
  );
}

export default ExportModal;
