import React, { useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';

import hubspotLogo from '../../../assets/logos/hubspot-logo.svg';
import salesforceLogo from '../../../assets/logos/salesforce-logo.svg';
import salesforceLogoPNG from '../../../assets/logos/Salesforce-Logo.png';
import hubspotLogoPNG from '../../../assets/logos/Hubspot-Logo.png';
import dynamicsLogo from '../../../assets/logos/dynamics-logo.png';
import csvLogoPNG from '../../../assets/logos/CSV-Logo.png';
import sendgridLogo from '../../../assets/logos/Sendgrid-Logo.png';
import marketoLogo from '../../../assets/logos/marketo-logo.svg';

import UploadModal from '../../../components/UploadModal';
import { Row } from '../../../components';
import { useLocation } from 'react-router-dom';
import GeneralTable from '../../../components/Table';
import { formatNumber, formatTimestamp } from '../../../helpers/dataFormatter';
import MarketingIntegrationModal from '../../../components/MarketingIntegrationModal';
import ComingSoonModal from '../../../components/ComingSoonModal';
import { colors } from '../../../styles';
import { GET_INTEGRATIONS } from '../../../api/integrations';
import PageHeader from '../../../components/PageHeader';
import { useQuery } from '@apollo/client';
import { GET_UPLOADED_FILES } from '../../../api/dataAPI';

type Props = {
  uploadModalPage?: any;
  setUploadModalPage?: any;
  uploadType?: any;
  currentPage?: any;
};

const useURLParams = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: table;
`);

const MainPageHeader = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 100%;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
`);

const LeftContainer = styled('div')(`
  display: table;
  width: 400px;
  margin: 0px 20px 0 0;
`);

const RightContainer = styled('div')(`
  margin: 20px 0 0 12px;
  display: flex;
  align-items:flex-start;
  justify-content: flex-start;
`);

const Body = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;  
  color: #758790;
  width: 100%;
  margin-bottom: 6px;
`);

const Button = styled('button')(`
  background: #242B2F;
  border-radius: 5px;
  color: #fff;
  margin: 16px 0 0;
  cursor: pointer;
  border: 1px solid #242B2F;
  padding: 6px 12px;
  transition: 0.5s;
  :hover {
    opacity: 0.7
  }
`);

const CRMLogos = styled('img')(`
  cursor: pointer;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px -6px; 
  background: white;
  :hover {
    opacity: 0.7
  }
`);

const TableContainer = styled('div')(`
  font-family: 'Inter';
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin: 20px auto;
  min-height: 120px;
`);

export const Integrations = (props: Props) => {
  const [uploadModalPage, setUploadModalPage] = useState<any>(null);
  const [marketingModalPage, setMarketingModalPage] = useState<any>(null);
  // const [loading, setLoading] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');
  const [pageSize, setPageSize] = useState(5);
  const [refresh, setRefresh] = useState(false);
  const [dataTotal, setDataTotal] = useState(0);
  const [integrationData, setIntegrationData] = useState<any[]>([]);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [queryParams, setQueryParams] = useState({});
  const [selections, setSelections] = useState({});
  const [selectedTable, setSelectedTable] = useState('integrations');

  const [integrationId, setIntegrationId] = useState<string | null>(null);
  const [integrationDetails, setIntegrationDetails] = useState<any | null>(null);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const urlParams = useURLParams();

  useEffect(() => {
    const newQueryParams = { id: urlParams.get('id'), success: urlParams.get('success'), type: urlParams.get('type') };
    setQueryParams(newQueryParams);
  }, []);

  const logoMapping: any = {
    MARKETO: marketoLogo,
    DYNAMICS: dynamicsLogo,
    HUBSPOT: hubspotLogoPNG,
    CSV: csvLogoPNG,
    SENDGRID: sendgridLogo,
    SALESFORCE: salesforceLogoPNG,
  };

  const crmHeaderData = [
    {
      header: 'Type',
      key: 'crm',
      formatData: (data: any) => {
        return (
          <img
            style={{
              height: '60px',
              width: '78px',
              // padding: '0px 16px 0px',
              objectFit: 'contain',
              margin: '0px 18px 0px 0px',
              padding: '0px 0px 0px',
              boxShadow: '',
              lineHeight: '60px',
            }}
            src={logoMapping?.[data?.type]}
            onClick={() => console.log('Logo Clicked')}
            alt={data?.type}
          />
        );
      },
    },
    {
      header: 'Name',
      key: 'nickname',
      sortKey: 'nickname',
      formatData: (data: any) => data?.nickname,
    },
    {
      header: 'Date Added',
      key: 'added',
      sortKey: 'dateCreated',
      formatData: (data: any) => formatTimestamp(data?.dateCreated),
    },
    {
      header: 'Modified',
      key: 'modified',
      sortKey: 'dateUpdated',
      formatData: (data: any) => formatTimestamp(data?.dateUpdated),
    },
    {
      header: 'Contacts',
      key: 'contacts',
      sortKey: 'memberCount',
      formatData: (data: any) => formatNumber(data?.memberCount),
    },
  ];

  const fileHeaderData = [
    {
      header: ' ',
      key: 'file',
      formatData: (data: any) => {
        return (
          <img
            style={{
              height: '60px',
              width: '78px',
              // padding: '0px 16px 0px',
              objectFit: 'contain',
              margin: '0px 18px 0px 0px',
              padding: '0px 0px 0px',
              boxShadow: '',
              lineHeight: '60px',
            }}
            src={logoMapping?.['CSV']}
            onClick={() => console.log('Logo Clicked')}
            alt={'FILE'}
          />
        );
      },
    },
    {
      header: 'Name',
      key: 'fileName',
      sortKey: 'fileName',
    },
    {
      header: 'Data Type',
      key: 'fileDataType',
      sortKey: 'fileDataType',
    },
    {
      header: 'Date Added',
      key: 'added',
      sortKey: 'dateCreated',
      formatData: (data: any) => formatTimestamp(data?.dateCreated),
    },
  ];

  const { loading, refetch } = useQuery(GET_INTEGRATIONS, {
    onCompleted: (data) => {
      const activeIntegrations = data?.integrations?.filter(
        (integration: any) => integration?.type !== 'CSV' && integration?.type !== 'SENDGRID',
      );
      setIntegrationData(activeIntegrations ?? []);
      setDataTotal(activeIntegrations?.length ?? 0);
    },
  });

  const { data: uploadedFiles, refetch: refetchFiles } = useQuery(GET_UPLOADED_FILES, { fetchPolicy: 'network-only' });

  return (
    <Container>
      <MainPageHeader>CRM Integrations</MainPageHeader>
      <div style={{ display: 'flex' }}>
        <LeftContainer>
          <Body>
            Here you can upload advisors, and link TIFIN AMP to your CRM. We use this link to ingest data on your
            prospects, and marketing activities to update your models and improve your outcomes
          </Body>
          <Button children={'Create CRM Integration'} onClick={() => setUploadModalPage('CRMSelection')} />
        </LeftContainer>
        <RightContainer>
          <CRMLogos
            src={salesforceLogo}
            onClick={() => setUploadModalPage('SalesforceIntegrations')}
            alt="salesforce"
          />
          <CRMLogos src={hubspotLogo} onClick={() => setUploadModalPage('Hubspot')} alt="hubspot" />
          <CRMLogos
            style={{
              height: '80px',
              width: '140px',
              padding: '0px 16px 0px',
              background: colors.white,
              objectFit: 'contain',
              margin: '25px 18px 0px',
              boxShadow: colors.main_box_shadow,
            }}
            src={dynamicsLogo}
            onClick={() => setUploadModalPage('Dynamics')}
            alt="dynamics"
          />
        </RightContainer>
      </div>
      <MainPageHeader style={{ marginTop: '50px' }}>Marketing Integrations</MainPageHeader>
      <div style={{ display: 'flex' }}>
        <LeftContainer>
          <Body>
            Here you can upload your marketing activity, and link TIFIN AMP to your MAS. We use this link to ingest data
            on your marketing activities to update your models and improve your outcomes
          </Body>
          <Button children={'Create Marketing Integration'} onClick={() => setMarketingModalPage('CRMSelection')} />
        </LeftContainer>
        <RightContainer>
          <CRMLogos
            style={{
              height: '80px',
              width: '140px',
              padding: '0px 16px 0px',
              background: colors.white,
              objectFit: 'contain',
              margin: '25px 18px 0px',
              boxShadow: colors.main_box_shadow,
            }}
            src={sendgridLogo}
            onClick={() => setMarketingModalPage('SendgridIntegrations')}
            alt="Sendgrid"
          />
          <CRMLogos
            style={{
              height: '80px',
              width: '140px',
              padding: '0px 16px 0px',
              background: colors.white,
              objectFit: 'contain',
              margin: '25px 18px 0px',
              boxShadow: colors.main_box_shadow,
            }}
            src={marketoLogo}
            onClick={() => setMarketingModalPage('MarketoIntegrations')}
            alt="Marketo"
          />
          <CRMLogos src={hubspotLogo} onClick={() => setMarketingModalPage('HubspotIntegrations')} alt="Hubspot" />
        </RightContainer>
      </div>
      <MainPageHeader style={{ marginTop: '50px' }}>File Upload</MainPageHeader>
      <div style={{ display: 'flex' }}>
        <LeftContainer>
          <Body>Here you can upload any other data you have (e.g. transactions, data packs) manually</Body>
          <Button children={'Upload Flat File'} onClick={() => setUploadModalPage('UploadFlatFile')} />
        </LeftContainer>
      </div>
      <>
        <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, margin: '32px 0px 0px' }} />
        <PageHeader
          selectedButton={selectedTable}
          leftButtons={[
            {
              text: 'My Integrations',
              key: 'integrations',
              onPress: () => {
                setSelectedTable('integrations');
              },
            },
            {
              text: 'Uploaded Files',
              key: 'uploadFiles',
              onPress: () => {
                setSelectedTable('uploadFiles');
              },
            },
          ]}
          hideBottomBorder
          stats={[]}
        />
        <TableContainer>
          <GeneralTable
            headerData={selectedTable === 'integrations' ? crmHeaderData : fileHeaderData}
            search={true}
            hideShadow
            data={
              selectedTable === 'integrations'
                ? integrationData
                : uploadedFiles?.clientDataUploads?.uploadHistory?.list ?? []
            }
            dataControls={{
              selections: selections,
              setSelections: setSelections,
              searchFunc: setSearchString,
              searchString,
            }}
            initSortColumn={'-dateCreated'}
            onRowPress={(row: any) => {
              if (selectedTable === 'integrations') {
                setIntegrationId(row?.id);
                setUploadModalPage('CrmStatus');
                setIntegrationDetails(row);
              } else {
                setShowComingSoon(true);
              }
            }}
            newItems={[]}
            loading={loading}
            getData={refetch}
            rowsInput={pageSize}
            rowsInputOption={[5, 10, 20]}
          />
        </TableContainer>
      </>
      <UploadModal
        modalPage={uploadModalPage}
        setModalPage={setUploadModalPage}
        urlQuery={queryParams}
        integrationId={integrationId}
        setIntegrationId={setIntegrationId}
        integrationDetails={integrationDetails}
        setIntegrationDetails={setIntegrationDetails}
        enableUploads={!!uploadedFiles?.clientDataUploads?.isConfigured}
        closeModal={() => {
          setRefresh(!refresh);
          setIntegrationDetails(null);
          setUploadModalPage(null);
        }}
      />
      <MarketingIntegrationModal modalPage={marketingModalPage} setModalPage={setMarketingModalPage} />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Container>
  );
};
