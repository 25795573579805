import React, { useEffect } from 'react';
import { Divider, F3, H3, Row } from '.';
import { keyframes } from '@mui/system';
import { colors } from '../styles';
import { RoundedCorner } from '@mui/icons-material';

type BarColors = {
  primaryColor?: string;
  backgroundColor?: string;
  textColor?: string;
};

interface Props {
  percent: number;
  label: string;
  height?: number;
  labelPosition?: string;
  barColors?: BarColors;
  backgroundColor?: string;
  roundCorners?: boolean;
  disableAnimation?: boolean;
}

const ProgressBar = ({
  percent,
  label,
  labelPosition,
  barColors,
  roundCorners,
  height = 24,
  disableAnimation,
}: Props) => {
  const { primaryColor, backgroundColor, textColor } = {
    primaryColor: colors.black_01,
    backgroundColor: colors.grey_01,
    textColor: colors.white,
    ...barColors,
  };

  const [currentPercent, setCurrentPercent] = React.useState(0);
  const [previousPercent, setPreviousPercent] = React.useState(0);

  const labelPercent = percent + 1;

  useEffect(() => {
    setPreviousPercent(currentPercent);
    setCurrentPercent(percent);
  }, [percent]);

  const grow = keyframes`
  from {
    width:${previousPercent}%;
  }
  to {
    width:${currentPercent}%;
  }
`;

  const move = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`;

  return (
    <Row style={{ width: '100%', height: '24px', position: 'relative' }}>
      <Row
        sx={{
          height,
          margin: '0px 0px 0px',
          width: `100%`,
          position: 'absolute',
          background: backgroundColor,
          //   background: `linear-gradient(0.25turn, #242B2F 0 ${percent}%, #e3e5e4 ${percent}% 100%)`,
          transition: '1s ease',
          borderRadius: roundCorners ? '5px' : '0px',
        }}
      />
      <Row
        sx={{
          height,
          margin: '0px 0px 0px',
          position: 'absolute',
          width: `${percent}%`,
          background: primaryColor,
          // animation: `${grow} 2s ease`,
          transition: '1s ease',
          animation: `${grow} 2s ease`,
          borderRadius: roundCorners ? '5px' : '0px',
          //   background: `linear-gradient(0.25turn, #242B2F 0 ${percent}%, #e3e5e4 ${percent}% 100%)`,
          //   transition: '1s ease',
        }}
      />
      <H3
        style={{
          position: 'absolute',
          //   marginLeft: `${labelPercent}`,
          color: textColor,
          animation: disableAnimation ? '' : `${move} 2s ease`,
          mixBlendMode: 'difference',
          // background: primaryColor,
          fontSize: height * 0.7,
          lineHeight: `${height}px`,
          // width: '100%',
          // margin: `${height / 4}px 0px ${height / 4}px`,
          // '@property':{
          // syntax: '<integer>',
          // transition: '--num 1s',
          // }
          transform: `translateX(14px)`,
          // ? labelPercent < 10
          // ? `translateX(10px)`
          // : `translateX(${labelPercent}%)`
          borderRadius: roundCorners ? '5px' : '0px',
          //   animation: `${grow} 2s ease`,
        }}
      >
        {label}
      </H3>
    </Row>
  );
};
export default ProgressBar;
