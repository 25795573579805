import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import { colors, typography } from '../styles';
import { IconButton } from '@mui/material';
import Loader from './Loader';
import closeIcon from '../assets/icons/close.svg';
import { ButtonUnstyled as Button } from '@mui/core';
import { ModalContainer, ModalHeader } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: colors.white_01,
    borderRadius: '5px',
    width: '400px',
    //height: '204px',
  },
  primaryButton: {
    display: 'flex',
    padding: '10px 50px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 40,
    background: '#242B2F',
    border: `1px solid #242B2F`,
    color: 'white',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.8',
    },
    '&:disabled': {
      background: '#fff',
    },
    width: '140px',
    whiteSpace: 'nowrap',
    margin: '0px 4px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    width: '92%',
    padding: '2px 15px',
    borderBottom: `1px solid ${colors.grey_00}`,
  },
  header: {},
  headerStyle: {
    fontSize: '18px',
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    color: '#404B50',
    marginTop: '24px',
  },
  subHeader: {
    color: '#758790',
    fontSize: 14,
    fontWeight: 400,
    margin: '24px',
    maxWidth: 500,
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: '44px',
  },
}));

interface Props {
  modalInfo: any;
  modalStatus: boolean;
  closeModal: any;
}

const DownloadModal = ({ modalInfo, modalStatus, closeModal }: Props) => {
  const { header, body, actionExport, actionDownload } = modalInfo;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <ModalContainer style={{ padding: '0px' }}>
        <ModalHeader style={{ width: '100%' }}>
          <p className={classes.header}>{header}</p>
          <IconButton onClick={closeModal}>
            <img src={closeIcon} />
          </IconButton>
        </ModalHeader>
        <div>
          <p className={classes.subHeader}>{body}</p>
        </div>
        <div className={classes.buttonContainer}>
          {actionExport && (
            <Button className={classes.primaryButton} onClick={actionExport}>
              Export to CRM
            </Button>
          )}
          <Button className={classes.primaryButton} onClick={actionDownload}>
            Download
          </Button>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default DownloadModal;
