import { styled } from '@mui/system';
import React, { useEffect } from 'react';
import { colors, typography } from '../../styles';
import { Bar, BarChart, ResponsiveContainer, XAxis, LabelList } from 'recharts';
import { F3, H2, H1, Column } from '..';
import Loader from '../Loader';
import { chartDataCache } from './chartDataCache';

const ChartTitle = styled(H2)({
  margin: '22px 25px 0px',
});

const ChartSubtitle = styled('div')({
  fontSize: 12,
  fontWeight: 400,
  color: colors.grey_02,
  width: '92%',
  padding: '0px 0',
  margin: '0px 25px',
});
const BarContainer = styled('div')({
  bottom: '5%',
  minHeight: 300,
  margin: 'auto 24px',
});

const LegendContainer = styled('div')({
  display: 'flex',
  position: 'absolute',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  //bottom: '5%',
  height: 40,
  width: '95%',
  margin: 'auto',
});

interface Props {
  title: string;
  subheader: string;
  selectedMetric: any;
  chartData: any;
  dataLegend: point[];
  showLinkedData: boolean;
  demoMode: boolean;
}

type point = {
  fill: string;
  label: string;
};

export const CustomBarChart = ({ title, subheader, selectedMetric, chartData, dataLegend, demoMode }: Props) => {
  const [chartDataMax, setChartDataMax] = React.useState(0);
  const [chartDataOverride, setChartDataOverride] = React.useState<boolean>(false);

  useEffect(() => {
    let newChartDataMax = 0;
    if (!!chartData) {
      const chartValues = chartData?.map((item: any) => {
        return item?.value;
      });
      newChartDataMax = Math.max(...chartValues);
    }
    setChartDataMax(newChartDataMax);
    if (demoMode && newChartDataMax === 0) {
      setChartDataOverride(true);
    } else {
      setChartDataOverride(false);
    }
  }, [chartData]);

  return (
    <>
      <ChartTitle>{title}</ChartTitle>
      <ChartSubtitle>{subheader}</ChartSubtitle>
      <BarContainer>
        {chartData?.length === 0 ? (
          <div style={{ display: 'flex' }}>
            <Loader />
          </div>
        ) : chartDataMax === 0 && !chartDataOverride ? (
          <Column style={{ margin: '90px 0px 0px' }}>
            <H1>No Advisor data found</H1>
            <F3 style={{ color: colors.grey_02 }}>Please select a different set of features</F3>
          </Column>
        ) : (
          <ResponsiveContainer width={'100%'} height={300}>
            <BarChart
              //@ts-ignore
              data={chartDataOverride ? chartDataCache?.[selectedMetric] : chartData}
              margin={{ top: 20, right: 40 }}
              barGap={0}
              barSize={30}
            >
              <XAxis
                dataKey={'label'}
                tick={{
                  ...typography.mainFont,
                  // color: '#758790',
                  fontSize: '13px',
                  fontWeight: 400,
                  fill: '#758790',
                }}
                stroke={colors.grey_01}
                interval={0}
              />
              <Bar isAnimationActive={false} dataKey={dataLegend[0].label} fill={colors.black_01}>
                <LabelList
                  position={'top'}
                  style={{ ...typography.mainFont, fontSize: '12px', fill: colors.black_01 }}
                  formatter={(value: any) => {
                    return value === 0 ? '' : value;
                  }}
                />
              </Bar>
              {dataLegend.length > 1 ? (
                <Bar isAnimationActive={false} dataKey={dataLegend[1].label} fill={dataLegend[1].fill}>
                  <LabelList
                    position={'top'}
                    style={{ ...typography.mainFont, fontSize: '12px', fill: dataLegend[1].fill }}
                    formatter={(value: any) => {
                      return value === 0 ? '' : value;
                    }}
                  />
                </Bar>
              ) : null}
            </BarChart>
          </ResponsiveContainer>
        )}
      </BarContainer>
      {dataLegend.length > 1 && (
        <LegendContainer style={{ margin: '-40px 0px 0px' }}>
          {dataLegend.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <div
                  style={{
                    background: item.fill,
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    margin: '18px 8px 0 20px',
                  }}
                />
                <F3>{item.label}</F3>
              </React.Fragment>
            );
          })}
        </LegendContainer>
      )}
    </>
  );
};
