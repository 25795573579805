import React, { useEffect, useLayoutEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { useNavigate } from 'react-router-dom';
import Line1 from '../../assets/visuals/stats-array/Line_1.svg';
import Line2 from '../../assets/visuals/stats-array/Line_2.svg';
import Line3 from '../../assets/visuals/stats-array/Line_3.svg';
import Line4 from '../../assets/visuals/stats-array/Line_4.svg';
// import HomeAPI from '../../api/Home';
import { useStoreState } from '../../store';
import { formatNumber, formatPercent } from '../../helpers/dataFormatter';

type Props = {
  focusedStep?: string;
  sx: any;
};

const Root = styled('div')(`
    position:relative;
    z-index:0;
`);
const Container = styled('div')(`
    width:1100px;
    @media (min-width: 1550px) {
      width:1300px;
      margin-left: auto;
    };
`);

const StatLabel = styled('div')(`
    margin: 4px 0px 2px;
    ${typography.footer_04};
    color:${colors.grey_02};
    line-height:8px;
    @media (min-width: 1550px) {
      width: fit-content;
    };
`);

const StatValue = styled('div')(`
    margin: 6px 0px;
    ${typography.footer_02};
    color:${colors.grey_02};
    line-height:14px;
    font-weight: 600;
`);

const Row = styled('div')(`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
`);
const Data = styled('div')(`
    display:flex;
    flex-direction: column;
    width:230px;
    padding: 6px 11px 6px;
    border-radius: 5px 0px 0px 0px;
    cursor: pointer;
`);

const LineImage = styled('img')(`
    width: 1100px;
    @media (min-width: 1550px) {
      width: 1300px;
    }
    @media (min-width: 1850px) {
      width: 1300px;
    }
`);

const StatsArray = ({ focusedStep, sx }: Props) => {
  const [width, setWidth] = useState(0);
  const [screenSize, setScreenSize] = useState('sm');
  const [hoveredRow, setHoveredRow] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const demoAccount = useStoreState((state) => state.user.demoAccount);
  const appType = useStoreState((state) => state.appType);

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    getStatData();
  }, []);

  const formatMetrics = (rawMetrics: any) => {
    // console.log('rawMetrics: ', rawMetrics);
    const eventCount = formatNumber(rawMetrics?.dashboard?.countEvents);
    const themesTrending = formatNumber(4);
    const sponsoredKeywords = formatNumber(rawMetrics?.myProducts?.funds?.length);
    const preTradeActions = formatNumber(
      rawMetrics?.myProducts?.funds?.reduce((total: number, newItem: any) => total + newItem?.ptcData?.total?.count, 0),
    );
    const discoverLeadJourneys = formatNumber(rawMetrics?.discoverLeadsJourneys?.totalItems);
    const discoverLeadQuotaUsed = formatNumber(rawMetrics?.discoverLeads?.quotaUsed);
    const influenceJourneys = formatNumber(rawMetrics?.influenceJourneys?.totalItems);
    const influenceQuotaUsed = formatNumber(rawMetrics?.influence?.quotaUsed);
    // const preTradeActions = formatNumber(3);
    // const packageRuns: any = formatNumber(
    //   rawMetrics?.advisorLeads?.runs?.totalItems +
    //     rawMetrics?.investorLeads?.runs?.totalItems +
    //     rawMetrics?.generateWaitlist?.runs?.totalItems +
    //     rawMetrics?.prioritizeAdvisors?.runs?.totalItems,
    // );
    const packageRuns: any = formatNumber(rawMetrics?.packageRuns?.runs?.totalItems);
    // const leadsGenerated = formatNumber(rawMetrics?.quotaByPackage);
    const activeCampaigns = rawMetrics?.nurtureLeads?.campaigns?.totalItems;
    const averageScoreIncrease =
      rawMetrics?.discoverIntent?.averageDistillScoreIncrease &&
      rawMetrics?.discoverIntent?.averageDistillScoreIncrease > 0
        ? formatPercent(rawMetrics?.discoverIntent?.averageDistillScoreIncrease)
        : '-';

    return {
      eventCount,
      themesTrending,
      sponsoredKeywords,
      preTradeActions,
      packageRuns,
      discoverLeadJourneys,
      influenceJourneys,
      influenceQuotaUsed,
      discoverLeadQuotaUsed,
      activeCampaigns,
      averageScoreIncrease,
    };
  };

  const getStatData = async () => {
    try {
      // const response = await HomeAPI.GetDistributionStats();
      const response = {};
      const statData = formatMetrics(response);
      setData(statData);
      const newDataArray = generateDataArray(statData);
      setDataArray(newDataArray);
      getCampaignData(newDataArray);
    } catch (error: any) {
      console.log('Error in HomeAPI: ', error.message);
    }
  };

  const getCampaignData = async (newDataArray: any) => {
    try {
      const response = {};

      const activeCampaigns = '-';

      newDataArray[3].leftStatValue = activeCampaigns;

      setDataArray(newDataArray);
    } catch (error: any) {
      console.log('Error in HomeAPI: ', error.message);
    }
  };

  const navigate = useNavigate();

  const optional_state = {
    background: `${colors.gradient_03}`,
    color: `${colors.black_01}`,
    transition: `background-color 3s ease`,
  };
  const hover_state = {
    background: `${colors.gradient_02}`,
    color: `${colors.black_01}`,
    transition: `background-color 3s ease`,
  };

  const default_state = {
    background: `#FFFFFF00`,
    color: `${colors.grey_02}`,
    transition: `background-color 3s ease`,
  };

  const formatFallbackValue = (value?: any) => {
    return value ? value : '-';
  };

  const generateDataArray = (newData: any) => {
    const defaultDataArray = [
      {
        leftStatLabel: ' ',
        leftStatValue: ' ',
        line: Line4,
        rightStatLabel: ' ',
        rightStatValue: ' ',
        style: {
          sm: { margin: '-186px 0px 0px 8px' },
          md: { margin: '-80px 0px 0px 6%' },
          lg: { margin: '-80px 0px 0px 6%' },
        },
        hover_keys: [],
        link: '/drive-sales',
        optional_keys: [],
      },
      {
        leftStatLabel: 'Active Influence Journeys',
        leftStatValue: formatFallbackValue(newData?.influenceJourneys),
        line: Line3,
        rightStatLabel: 'Advisors Influenced',
        rightStatValue: formatFallbackValue(newData?.influenceQuotaUsed),
        style: {
          sm: { margin: '-182px 0px 0px 0px' },
          md: { margin: '-202px 0px 0px 6%' },
          lg: { margin: '-202px 0px 0px 6%' },
        },
        link: '/influence-prospects/home',
        hover_keys: ['identify'],
        optional_keys: [],
      },
      {
        leftStatLabel: 'Active Lead Journeys',
        leftStatValue: formatFallbackValue(newData?.discoverLeadJourneys),
        line: Line2,
        rightStatLabel: 'Leads Generated',
        rightStatValue: formatFallbackValue(newData?.discoverLeadQuotaUsed),
        link: '/discover-leads/home',
        style: {
          sm: { margin: '-137px 0px 0px 0px' },
          md: { margin: '-149px 0px 0px 6%' },
          lg: { margin: '-149px 0px 0px 6%' },
        },
        hover_keys: ['identify'],
        optional_keys: [''],
      },
      {
        leftStatLabel: 'Active Campaigns',
        leftStatValue: newData?.activeCampaigns ? newData?.activeCampaigns : demoAccount ? 1 : '-',
        line: Line1,
        rightStatLabel: 'Average AMP Score Increase',
        rightStatValue: formatFallbackValue(newData?.averageScoreIncrease),
        link: '/create-campaign/home',
        style: {
          sm: { margin: '-91px 0px 0px 0px' },
          md: { margin: '-97px 0px 0px 6%' },
          lg: { margin: '-97px 0px 0px 6%' },
        },
        hover_keys: ['learn'],
        optional_keys: [],
      },
    ];

    if (appType === 'LPL') {
      return [
        {
          leftStatLabel: ' ',
          leftStatValue: ' ',
          line: Line3,
          rightStatLabel: ' ',
          rightStatValue: ' ',
          style: {
            sm: { margin: '-132px 0px 0px 0px' },
            md: { margin: '-12px 0px 0px 6%' },
            lg: { margin: '-12px 0px 0px 6%' },
          },
          link: '/build',
          hover_keys: [],
          optional_keys: [],
        },
        ...defaultDataArray.slice(2),
      ];
    } else {
      return defaultDataArray;
    }
  };

  const [dataArray, setDataArray] = useState<any>(generateDataArray({}));

  return (
    <Root sx={sx}>
      {dataArray.map((item: any, index: number) => (
        // @ts-ignore
        <Container sx={item.style[screenSize]} key={index + 12930123414}>
          <Row onMouseOver={() => setHoveredRow(index)} onMouseLeave={() => setHoveredRow(null)}>
            <Data
              onClick={() => navigate(item.link)}
              sx={
                item.hover_keys.includes(focusedStep)
                  ? hover_state
                  : item.optional_keys.includes(focusedStep)
                  ? optional_state
                  : { ...default_state }
              }
            >
              <StatLabel
                sx={
                  item.optional_keys.includes(focusedStep) || item.hover_keys.includes(focusedStep)
                    ? {
                        color: colors.black_01,
                      }
                    : {
                        color: hoveredRow === index ? colors.black_01 : colors.grey_02,
                      }
                }
              >
                {item.leftStatLabel}
              </StatLabel>
              <StatValue
                sx={
                  item.optional_keys.includes(focusedStep) || item.hover_keys.includes(focusedStep)
                    ? {
                        color: colors.black_01,
                      }
                    : {
                        color: hoveredRow === index ? colors.black_01 : colors.grey_02,
                      }
                }
              >
                {item.leftStatValue}
              </StatValue>
            </Data>

            <Data
              onClick={() => navigate(item.link)}
              sx={
                item.hover_keys.includes(focusedStep)
                  ? { ...hover_state, transform: 'rotate(-180deg)', borderRadius: '0px 0px 0px 5px' }
                  : item.optional_keys.includes(focusedStep)
                  ? { ...optional_state, transform: 'rotate(-180deg)', borderRadius: '0px 0px 0px 5px' }
                  : { ...default_state, transform: 'rotate(-180deg)', borderRadius: '0px 0px 0px 5px' }
              }
            >
              <StatValue
                sx={
                  item.optional_keys.includes(focusedStep) || item.hover_keys.includes(focusedStep)
                    ? {
                        color: colors.black_01,
                        transform: 'rotate(-180deg)',
                        textAlign: 'right',
                      }
                    : {
                        color: hoveredRow === index ? colors.black_01 : colors.grey_02,
                        transform: 'rotate(-180deg)',
                        textAlign: 'right',
                      }
                }
              >
                {item.rightStatValue}{' '}
              </StatValue>
              <StatLabel
                sx={
                  item.optional_keys.includes(focusedStep) || item.hover_keys.includes(focusedStep)
                    ? {
                        color: colors.black_01,
                        transform: 'rotate(-180deg)',
                        textAlign: 'right',
                      }
                    : {
                        color: hoveredRow === index ? colors.black_01 : colors.grey_02,
                        transform: 'rotate(-180deg)',
                        textAlign: 'right',
                      }
                }
              >
                {item.rightStatLabel}
              </StatLabel>
            </Data>
          </Row>
          <LineImage src={item.line} alt="statsArray" />
        </Container>
      ))}
    </Root>
  );
};

export default StatsArray;
