import React, { useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import { Row, H1, H2, H3, F3, Column } from '..';
import { formatDate, formatDollar, formatNumber, formatPercent } from '../../helpers/dataFormatter';
import SimpleTable from '../SimpleTable';
import { SimplePieChart } from '../SimplePieChart';
import { useReactTable, getCoreRowModel, getSortedRowModel, SortingState, ColumnDef } from '@tanstack/react-table';
import arrowDownGrey from '../../assets/icons/arrow-down-grey.svg';

type Props = { data: any };

const Container = styled(Column)({
  display: 'flex',
  flex: 1,
});

const TableLabel = styled(H3)({
  textAlign: 'left',
  margin: '20px 0px 4px',
  color: colors.grey_03,
});

const ChartLabels = styled(H3)({
  margin: '20px 0px 0px',
  color: colors.grey_03,
});

type AssetType = {
  title: string;
  flows: number;
  pctChange: number;
};

export const CompetitorData = ({ data }: Props) => {
  const [productSorting, setProductSorting] = React.useState<SortingState>([]);
  const [platformSorting, setPlatformSorting] = React.useState<SortingState>([]);
  const [selectedSource, setSelectedSource] = React.useState<number>(0);

  const productPieChart = useMemo(
    () =>
      data?.sources?.[selectedSource]?.product?.datapoints?.map((item: any) => ({
        ...item,
        value: Math.abs(item['flows']),
      })) ??
      data?.sources?.[selectedSource]?.product?.map((item: any) => ({
        ...item,
        value: Math.abs(item['flows']),
      })) ??
      data?.[selectedSource]?.advisorHoldingsByProduct,
    [data],
  );

  const platformPieChart = useMemo(
    () =>
      data?.sources?.[selectedSource]?.platform?.datapoints?.map((item: any) => ({
        ...item,
        value: Math.abs(item['flows']),
      })) ??
      data?.sources?.[selectedSource]?.platform?.map((item: any) => ({
        ...item,
        value: Math.abs(item['flows']),
      })) ??
      data?.[selectedSource]?.advisorHoldingsByPlatform,
    [data],
  );

  const PlatformColumns = useMemo<ColumnDef<AssetType>[]>(
    () => [
      {
        accessorKey: platformPieChart?.[0]?.title ? 'title' : 'platform',
        header: 'Platform',
        cell: (data: any) => {
          return data?.getValue();
        },
      },
      {
        accessorKey: 'flows',
        header: 'Flows',
        cell: (data: any) => {
          return formatDollar(data?.getValue());
        },
      },
      {
        accessorKey: 'percentChange',
        header: 'Percent Change',
        cell: (data: any) => {
          return formatPercent(data?.getValue() ?? 0);
        },
      },
    ],
    [platformPieChart],
  );

  const ProductColumns = useMemo<ColumnDef<AssetType>[]>(
    () => [
      {
        accessorKey: platformPieChart?.[0]?.title ? 'title' : 'product',
        header: 'Asset Class',
        cell: (data: any) => {
          return data?.getValue();
        },
      },
      {
        accessorKey: 'flows',
        header: 'Flows',
        cell: (data: any) => {
          return formatDollar(data?.getValue());
        },
      },
      {
        accessorKey: 'percentChange',
        header: 'Percent Change',
        cell: (data: any) => {
          return formatPercent(data?.getValue() ?? 0);
        },
      },
    ],
    [],
  );

  const dataAvailable =
    data?.sources?.[selectedSource]?.['platform']?.datapoints?.length > 0 ||
    data?.sources?.[selectedSource]?.['platform']?.length > 0 ||
    data?.sources?.[selectedSource]?.['product']?.datapoints?.length > 0 ||
    data?.sources?.[selectedSource]?.['product']?.length > 0;

  const productTableData = useReactTable({
    columns: ProductColumns,
    data: productPieChart,
    state: {
      sorting: productSorting,
    },
    onSortingChange: setProductSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  const platformTableData = useReactTable({
    columns: PlatformColumns,
    data: platformPieChart,
    state: {
      sorting: platformSorting,
    },
    onSortingChange: setPlatformSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
  });

  return !dataAvailable ? (
    <Column style={{ padding: '80px 0px', alignItems: 'center' }}>
      <H2>No Data Available</H2>
      <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>Please check back in future for updated data.</F3>
    </Column>
  ) : (
    <Column>
      <Row
        style={{
          alignItems: 'center',
          borderBottom: `1px solid ${colors.grey_02}`,
          padding: '8px 0px',
          cursor: 'pointer',
        }}
      >
        <H3>Source</H3>
        <F3 style={{ color: colors.grey_02, margin: '0px 16px' }}>
          {data?.sources?.[selectedSource]?.title ?? 'LPL Data Pack'}
        </F3>
        <img style={{ height: '10px', width: '10px' }} src={arrowDownGrey} alt={'Down Arrow'} />
      </Row>
      <Row>
        <Container style={{ height: '300px', background: colors.black + '00' }}>
          <ChartLabels>Advisor Flows By Platform</ChartLabels>
          <SimplePieChart
            legend={
              data?.sources?.[selectedSource]?.platform?.datapoints?.[0]?.title
                ? { dataKey: 'value', nameKey: 'title' }
                : data?.sources?.[selectedSource]?.platform
                ? { dataKey: 'value', nameKey: 'platform' }
                : { dataKey: 'positionSize', nameKey: 'name' }
            }
            data={platformPieChart}
          />
        </Container>
        <Container style={{ height: '300px', background: colors.black + '00' }}>
          <ChartLabels>Advisor Flows By Product</ChartLabels>
          <SimplePieChart
            legend={
              data?.sources?.[selectedSource]?.product?.datapoints?.[0]?.title
                ? { dataKey: 'value', nameKey: 'title' }
                : data?.sources?.[selectedSource]?.product
                ? { dataKey: 'value', nameKey: 'product' }
                : { dataKey: 'positionSize', nameKey: 'assetClass' }
            }
            data={productPieChart}
          />
        </Container>
      </Row>
      <TableLabel>Breakdown of transactions by platform (Last 12 months)</TableLabel>
      <SimpleTable tableData={platformTableData} />
      <TableLabel>Breakdown of transactions by product (Last 12 months)</TableLabel>
      <SimpleTable tableData={productTableData} />
    </Column>
  );
};
