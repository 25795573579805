import React, { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
// import TextField, { TextFieldProps } from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { colors } from '../../styles';

type Props = {
  inputText: string;
  placeholder?: string;
  setInputText: any;
  titleText: any;
  hidePassword?: boolean;
  disabled?: boolean;
};

const MainFormControl = styled(FormControl)(({ theme }) => ({
  color: colors.black_01,
  fontFamily: 'Inter',
  '& .Mui-focused': {
    color: colors.black,
    // fontWeight: '500',
  },
}));

const MainInputLabel = styled(InputLabel)(({ theme }) => ({
  color: colors.black_01,
  fontFamily: 'Inter',
}));

const MainTextInput = styled(InputBase)(({ theme }) => ({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    background: colors.gradient_01,
    // border: '1px solid #ced4da',
    fontSize: 14,
    width: '300px',
    margin: '20px 0px',
    padding: '10px 12px',
    fontFamily: 'Inter',
    '&:focus': {
      borderColor: colors.grey_02,
      color: colors.black_01,
    },
  },
}));

const TextField = ({ inputText, setInputText, titleText, placeholder, hidePassword, disabled }: Props) => {
  return (
    // <Container>
    //   {titleText && <InputTitle>{titleText}</InputTitle>}
    //   <FormInput
    //     type="search"
    //     placeholder={placeholder ? placeholder : 'Type your firm name...'}
    //     value={inputText}
    //     onChange={(e) => setInputText(e.target.value)}
    //   ></FormInput>
    // </Container>
    <MainFormControl variant="standard">
      <MainInputLabel shrink htmlFor="bootstrap-input">
        {titleText}
      </MainInputLabel>
      <MainTextInput
        disabled={disabled}
        value={inputText}
        type={hidePassword ? 'password' : 'text'}
        onChange={(event) => setInputText(event.target.value)}
        placeholder={placeholder}
        id="bootstrap-input"
      />
    </MainFormControl>
  );
};

export default TextField;
