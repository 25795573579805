import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { formatNumber } from '../../helpers/dataFormatter';
import { StatType } from '../../types';
import Loader from '../Loader';
import { CardBody, CardHeader, H2, SecondaryButtonShort, Row, Title, Column, F4, F3, PageTitle, Divider } from '..';
import ProgressBar from '../ProgressBar';
import { use } from 'chai';
import { set } from 'lodash';

const CardRoot = styled('div')({
  background: '#fff',
  borderRadius: '5px',
  boxShadow: colors.main_box_shadow,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: '0px 0px 32px',
});

const CustomTitle = styled(Title)({
  fontSize: '48px',
  color: colors.grey_03,
  margin: '16px 0px 24px',
});

type Props = {
  returnAction: any;
  manualPercent: number;
  setManualPercent: any;
};

export const PackageStatusCard = ({ returnAction, manualPercent, setManualPercent }: Props) => {
  const [pendingTimeout, setPendingTimeout] = useState<any>(null);
  const incrementPercent = (percent: number, setPercent: any) => {
    // console.log('manualPercent in increment percent: ', manualPercent);
    if (percent < 90) {
      const increment = Math.floor(Math.random() * 8);
      setPercent(percent + increment);
    }
  };

  useEffect(() => {
    if (manualPercent < 90) {
      const newPendingTimeout = setTimeout(incrementPercent, 1000, manualPercent, setManualPercent);
      setPendingTimeout(newPendingTimeout);
    } else {
      clearTimeout(pendingTimeout);
    }
  }, [manualPercent]);

  return (
    <CardRoot id={`package-status-card`}>
      <CardHeader style={{ justifyContent: 'space-between' }}>
        <H2 style={{ margin: '0px', padding: '0px' }}>Package Status</H2>
      </CardHeader>
      <CardBody>
        <H2 style={{ color: colors.grey_03, margin: '0px 0px 6px' }}>Prospect Enrichment & Analysis</H2>
        <F4 style={{ color: colors.grey_02, margin: '0px 0px 16px' }}>
          {/* {latestJob?.processingDetails?.process_count && latestJob?.processingDetails?.total_count */}
          {/* ? `${latestJob?.processingDetails?.process_count} prospects analyzed - ${latestJob?.processingDetails?.total_count} prospects remaining` */}
          Processing data
        </F4>
        <ProgressBar
          //   barColors={{ primaryColor: colors.red, backgroundColor: colors.green }}
          disableAnimation
          roundCorners
          percent={manualPercent}
          height={36}
          label={`${manualPercent}%`}
        />
        <Divider />
        <H2 style={{ color: colors.grey_03, margin: '0px 0px 6px' }}>Your results are generating</H2>
        <F3 style={{ color: colors.grey_02 }}>Please check back soon</F3>
        <Row style={{ margin: '16px 0px' }}>
          <SecondaryButtonShort //style={{ width: '200px' }}
            onClick={returnAction}
          >
            Return Home
          </SecondaryButtonShort>
        </Row>
      </CardBody>
    </CardRoot>
  );
};
