import { states, firmAUM, firmAccounts, type, custodian } from '../../helpers/menuOptions';
import { firmNames } from '../../helpers/firmNameMenuOptions';

export const algoFeatures = [
  {
    id: 'firmographics',
    key: crypto.randomUUID(),
    label: 'Firmographics',
    value: [
      {
        key: crypto.randomUUID(),
        label: 'Age',
        id: 'age',
      },
      {
        key: crypto.randomUUID(),
        label: 'AUM',
        id: 'aum',
      },
      {
        key: crypto.randomUUID(),
        label: 'Type (BD/RIA)',
        id: 'type',
      },
      {
        key: crypto.randomUUID(),
        label: 'Firm Size',
        id: 'firmSize',
      },
      {
        key: crypto.randomUUID(),
        label: 'Advisor Tenure',
        id: 'advisorTenure',
      },
      {
        key: crypto.randomUUID(),
        label: 'ETF/MF Allocation',
        id: 'etfMfAllocation',
      },
    ],
  },
  {
    id: 'behavioral',
    key: crypto.randomUUID(),
    label: 'Behavioral',
    value: [
      {
        key: crypto.randomUUID(),
        label: 'Email Engagement',
        id: 'emailEngagement',
      },
      {
        key: crypto.randomUUID(),
        label: 'Website and Webinar Engagement',
        id: 'tifinEngagement',
      },
      {
        key: crypto.randomUUID(),
        label: 'Social Media Engagement',
        id: 'socialMediaEngagement',
      },
      {
        key: crypto.randomUUID(),
        label: 'Morningstar Proposals',
        id: 'morningstarProposal',
      },
    ],
  },
  {
    id: 'transactional',
    key: crypto.randomUUID(),
    label: 'Transactional',
    value: [
      {
        id: 'advisorInflows',
        label: 'Advisor Inflows',
        key: crypto.randomUUID(),
      },
      {
        id: 'advisorOutflows',
        label: 'Advisor Outflows',
        key: crypto.randomUUID(),
      },
      {
        id: 'categoryInflows',
        label: 'Category Inflows',
        key: crypto.randomUUID(),
      },
      {
        id: 'categoryOutflows',
        label: 'Category Outflows',
        key: crypto.randomUUID(),
      },
    ],
  },
  {
    id: 'fundPerformance',
    key: crypto.randomUUID(),
    label: 'Fund Performance',
    value: [
      {
        id: 'shortTermNAV',
        label: 'Short-Term NAV Performance',
        key: crypto.randomUUID(),
      },
      {
        id: 'Adjusted NAV Highs and Lows',
        label: 'Adjusted Nav Highs And Lows',
        key: crypto.randomUUID(),
      },
      {
        id: 'Morningstar Ratings',
        label: 'Morningstar Ratings',
        key: crypto.randomUUID(),
      },
      {
        id: 'fundManagementData',
        label: 'Fund Management Data',
        key: crypto.randomUUID(),
      },
      {
        id: 'fundAnnualVolatility',
        label: 'Fund Annual Volatility',
        key: crypto.randomUUID(),
      },
      {
        id: 'morninstarRiskFeatures',
        label: 'Morningstar Risk Features',
        key: crypto.randomUUID(),
      },
      {
        id: 'fundCategoryPerformance',
        label: 'Fund vs. Category Performance',
        key: crypto.randomUUID(),
      },
    ],
  },
];

export const screenAdvisorFilters = [
  {
    id: 'firmAum',
    key: crypto.randomUUID(),
    list: true,
    label: 'Firm AUM',
    options: firmAUM,
    width: '240px',
    multiple: true,
  },
  {
    id: 'firmAccounts',
    key: crypto.randomUUID(),
    list: true,
    label: 'Firm Accounts',
    options: firmAccounts,
    width: '240px',
    multiple: true,
  },
  {
    id: 'type',
    key: crypto.randomUUID(),
    list: true,
    label: 'Type (BD/RIA)',
    options: type,
    multiple: true,
    width: '240px',
  },
  {
    id: 'location',
    key: crypto.randomUUID(),
    list: true,
    label: 'Location',
    options: states,
    width: '240px',
    multiple: true,
  },
  {
    id: 'firmNames',
    key: crypto.randomUUID(),
    //   list: true,
    searchable: false,
    label: 'Firm name',
    options: firmNames,
    width: '240px',
    multiple: true,
  },
  {
    id: 'custodian',
    key: crypto.randomUUID(),
    //   list: true,
    searchable: false,
    label: 'Custodian',
    options: custodian,
    width: '240px',
    multiple: true,
  },
];
