import React from 'react';
import { colors } from '../../styles';
import {
  ModalRoot,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  PrimaryButtonShort,
  H2,
  Column,
  Row,
  F4,
  H4,
  H3,
} from '..';
import { formatTimestamp } from '../../helpers/dataFormatter';

type Props = { showModal: boolean; setShowModal: any; data: any };

function RecentEventsModal({ showModal, setShowModal, data }: Props) {
  const [tableTotal, setTableTotal] = React.useState<number>(5);

  const formattedEvents: any = {
    email_opened: 'Opened Email',
    bought: 'Fund Purchased',
    sold: 'Fund Sold',
    email_delivered: 'Email Delivered',
    email_clicked: 'Email Clicked',
    email_unsubscribe: 'Email Unsubscribed',
    purchased_fund: 'Fund Purchased',
  };
  return (
    <ModalRoot open={showModal} onClose={() => setShowModal(false)}>
      <ModalContainer style={{ width: '560px' }}>
        <ModalHeader style={{}}>
          <H2 style={{ color: colors.grey_03, margin: '12px 0px 8px' }}>Recent Events</H2>
        </ModalHeader>
        <Column style={{ margin: '10px 16px 0px', padding: '0px 30px' }}>
          {data?.length === 0 ? (
            <Column>
              <H3
                style={{
                  margin: '30px 0px 6px',
                  color: colors.grey_03,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                No Events Found
              </H3>
              <F4
                style={{
                  color: colors.grey_02,
                  margin: '0px 0px 6px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                Please check back soon
              </F4>
            </Column>
          ) : (
            data?.slice(0, tableTotal).map((event: any, index: number) => {
              return (
                <Row
                  key={index}
                  style={{
                    justifyContent: 'space-between',
                    borderBottom: `1px solid ${colors.grey_01}`,
                    padding: '6px 8px 0px',
                  }}
                >
                  <H4
                    style={{
                      color: colors.grey_03,
                      margin: '0px 0px 6px',
                      width: '120px',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {event?.name}
                  </H4>
                  <F4
                    style={{
                      color: colors.grey_02,
                      margin: '0px 0px 6px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '240px',
                    }}
                  >
                    {`${formattedEvents?.[event?.type] ? formattedEvents?.[event?.type] : event?.type} "${
                      event?.subject
                    }"`}
                  </F4>
                  <F4
                    style={{
                      color: colors.grey_02,
                      margin: '0px 0px 6px',
                      width: '140px',
                      textAlign: 'right',
                    }}
                  >
                    {formatTimestamp(event?.date)}
                  </F4>
                </Row>
              );
            })
          )}
          <Row
            sx={{
              padding: '0px 8px 8px',
              //   '&:hover': {
              //     backgroundColor: colors.grey_01,
              //   },
              opacity: '0.5',
            }}
            onClick={() => setTableTotal(tableTotal + 5)}
          >
            <H4 style={{ marginTop: '6px' }}> Show more...</H4>
          </Row>
        </Column>
        <ModalFooter>
          <PrimaryButtonShort onClick={() => setShowModal(false)}>Close</PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
}

export default RecentEventsModal;
