import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import navLogo from '../assets/logos/nav-logo.png';
import Button from '@mui/material/Button';
import { colors } from '../styles';
import {
  F2,
  PrimaryButtonShort,
  SecondaryButtonShort,
  ModalRoot,
  ModalHeader,
  ModalContainer,
  MainTextInput,
  ModalFooter,
  FreeTextArea,
  Column,
} from '.';
import Loader from './Loader';
import { CREATE_ORGANIZATION } from '../api/systemManagement';
import { useMutation } from '@apollo/client';
import { toBase64 } from '../helpers/fileHelper';

interface Props {
  modalStatus: boolean;
  closeModal: any;
  refreshAndSelect: any;
}

const FormTitle = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #242B2F;
`);

const FirmLogo = styled('img')(`
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  padding: 30px 40px;
  width: 300px;
`);

const UploadLogoButton = styled(Button)({
  background: colors.grey_02,
  borderRadius: '5px',
  color: '#fff',
  margin: 0,
  fontFamily: 'Inter',
  border: '1px solid #758790',
  padding: '4px 12px',
  fontSize: '12px',
  boxShadow: 'None',
  fontWeight: '400',
  letterSpacing: '-0.5px',
  textTransform: 'none',
  '&:hover': {
    opacity: 0.7,
    background: '#758790',
  },
});

type OrganizationInfoType = {
  orgName: string;
  sponsorClean: string;
  insightsEmail?: string;
  adminEmail?: string;
  logoData: string;
};

const AddOrganizationModal = ({ closeModal, modalStatus, refreshAndSelect }: Props) => {
  const [selectedImg, setSelectedImg] = useState<any>({ preview: navLogo, raw: navLogo });

  const [newOrganizationInfo, setNewOrganizationInfo] = useState<OrganizationInfoType>({
    orgName: '',
    sponsorClean: '',
    insightsEmail: '',
    adminEmail: '',
    logoData: '',
  });

  const [createOrganization, { loading, error }] = useMutation(CREATE_ORGANIZATION, {
    variables: {
      ...newOrganizationInfo,
    },
    onCompleted: (data) => {
      refreshAndSelect(data?.platformManagementCreateAmOrganization?.details);
      closeModal();
    },
  });

  const handleChange = async (e: any) => {
    let unformattedBase64: any = await toBase64(e.target.files[0]);
    const base64Image = unformattedBase64.split(',').pop();
    if (e.target.files.length) {
      setSelectedImg({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: base64Image,
      });
      setNewOrganizationInfo({ ...newOrganizationInfo, logoData: base64Image });
    }
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer style={{ padding: '8px 0px' }}>
        <ModalHeader style={{ width: '100%' }}>{'Create Organization'}</ModalHeader>
        <Column style={{ height: '500px', width: '460px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}>
          <MainTextInput
            label="Organization Name"
            size="small"
            // sx={{ margin: '12px 0', width: '100%' }}
            value={newOrganizationInfo?.orgName}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setNewOrganizationInfo({ ...newOrganizationInfo, orgName: event.target.value })}
          />
          <FormTitle>Firm Logo</FormTitle>
          <FirmLogo src={selectedImg?.preview} alt="logo" />
          <UploadLogoButton
            variant="contained" //component="label"
            style={{ fontFamily: 'Inter' }}
          >
            Upload New Logo
            <input hidden accept="image/*,.pdf" type="file" onChange={handleChange} />
          </UploadLogoButton>
          <MainTextInput
            label="Sponsor Clean"
            size="small"
            // sx={{ margin: '12px 0', width: '100%' }}
            value={newOrganizationInfo?.sponsorClean}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setNewOrganizationInfo({ ...newOrganizationInfo, sponsorClean: event.target.value })}
          />
          <MainTextInput
            label="Admin Email"
            size="small"
            // sx={{ margin: '12px 0', width: '100%' }}
            value={newOrganizationInfo?.adminEmail}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setNewOrganizationInfo({ ...newOrganizationInfo, adminEmail: event.target.value })}
          />
          <MainTextInput
            label="Insights Email"
            size="small"
            // sx={{ margin: '12px 0', width: '100%' }}
            value={newOrganizationInfo?.insightsEmail}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setNewOrganizationInfo({ ...newOrganizationInfo, insightsEmail: event.target.value })}
          />
          <F2 style={{ color: colors.red }}>{error?.message}</F2>
        </Column>
        <ModalFooter style={{ width: '100%', alignItems: 'flex-end' }}>
          <SecondaryButtonShort onClick={closeModal}>Cancel</SecondaryButtonShort>
          <PrimaryButtonShort onClick={() => createOrganization()} style={{ margin: '0px 8px' }}>
            {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Create Organization'}
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default AddOrganizationModal;
