import React from 'react';
import { styled } from '@mui/styles';
import { formatPercent, unformatSnakeCase, formatNumber } from '../../helpers/dataFormatter';
import { CardContainer, Column, Row, H2, H3 } from '..';
import { colors, typography } from '../../styles';
import UpArrowIcon from '../../assets/icons/arrow-black.svg';
import { generateKey } from '../../helpers/numberGenerator';

type Props = {
  data: any;
};

const ResultsHeader = styled('div')({
  color: colors.grey_03,
  fontSize: 18,
  fontWeight: 500,
  cursor: 'pointer',
  margin: 0,
  padding: '0px 0px 0px 20px',
  backgroundColor: colors.white_00,
  borderBottom: `1px solid ${colors.grey_01}`,
  borderRadius: '5px',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const EventCountBox = styled('div')({
  background: colors.gradient_01,
  display: 'flex',
  height: '40px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 10px',
  margin: '6px 0px',
  ...typography.mainFont,
  fontWeight: 500,
  fontSize: 14,
  color: colors.grey_03,
  borderRadius: '5px',
});

export const DataChangeCard = ({ data }: Props) => {
  const AVG_PURCHASE_LIKELIHOOD = 'avg_purchase_likelihood';
  const THEME_RANKING = 'theme_ranking';
  const ADVISOR_ENGAGEMENT = 'advisor_engagement';
  const EXPERTISE = 'expertise';

  const dataLabels = [AVG_PURCHASE_LIKELIHOOD, THEME_RANKING, ADVISOR_ENGAGEMENT, EXPERTISE];

  const dataFormatter = (subItem: any, type: string) => {
    switch (type) {
      case AVG_PURCHASE_LIKELIHOOD:
        return (
          <>
            <span style={{ margin: '0px 4px 0px 0px' }}>{formatPercent(subItem?.percent)}</span>
            {subItem?.delta ? (
              <>
                (
                <img
                  src={UpArrowIcon}
                  style={{
                    height: '10px',
                    width: '10px',
                    margin: '5px 2px 0px 2px',
                    transform: subItem?.delta < 0 || subItem?.position_delta < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                  alt={'arrow'}
                />
                +{formatPercent(subItem?.delta)})
              </>
            ) : null}
          </>
        );
      case THEME_RANKING:
        return (
          <span>
            {subItem?.delta ? (
              <>
                <img
                  src={UpArrowIcon}
                  style={{
                    height: '10px',
                    width: '10px',
                    margin: '5px 8px 0px 4px',
                    transform: subItem?.delta < 0 || subItem?.position_delta < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                  alt={'arrow'}
                />
                {formatNumber(subItem?.delta)}
              </>
            ) : null}
          </span>
        );
      case ADVISOR_ENGAGEMENT:
        return (
          <>
            <span style={{ margin: '0px 4px 0px 0px' }}>{formatNumber(subItem?.total)}</span>
            {subItem?.delta && subItem?.delta !== subItem?.total ? (
              <>
                (
                <img
                  src={UpArrowIcon}
                  style={{
                    height: '10px',
                    width: '10px',
                    margin: '5px 3px 0px 2px',
                    transform: subItem?.delta < 0 || subItem?.position_delta < 0 ? 'rotate(180deg)' : 'rotate(0deg)',
                  }}
                  alt="arrow"
                />
                <span>{formatNumber(subItem?.delta)}</span>)
              </>
            ) : null}
          </>
        );
      case EXPERTISE:
        return formatPercent(subItem?.percent);
    }
  };

  return (
    <CardContainer style={{ margin: '24px 0px 24px' }}>
      <ResultsHeader>
        <H2 style={{ color: colors.grey_03 }}>Change Since Last Visit</H2>
      </ResultsHeader>
      <Row style={{ padding: '0px 10px' }}>
        {dataLabels?.map((label: any, index: number) => {
          return (
            <Column
              style={{
                margin: '0px 0px',
                padding: '20px 20px',
                // borderRadius: '5px',
                borderLeft: index > 0 ? `1px solid ${colors.grey_01}` : `0px solid ${colors.grey_01}`,
                flex: 1,
              }}
              key={generateKey()}
            >
              <H3 style={{ color: colors.grey_02, margin: '0px 0px 8px' }}>
                {label === 'expertise' ? 'Fee Sensitivity' : unformatSnakeCase(label)}
              </H3>
              {data?.changeData?.[label]?.map((subItem: any, subIndex: number) => {
                return (
                  <EventCountBox key={generateKey()}>
                    <Row style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {label === THEME_RANKING && <H3 style={{ margin: '0px 4px 0px 0px' }}>{subIndex + 1}. </H3>}
                      <span> {unformatSnakeCase(subItem?.label)}</span>
                    </Row>
                    <Row>{dataFormatter(subItem, label)}</Row>
                  </EventCountBox>
                );
              })}
            </Column>
          );
        })}
      </Row>
    </CardContainer>
  );
};
