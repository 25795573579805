import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, MainTextInput, PrimaryButton } from '..';
import { colors } from '../../styles';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  // height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  linkMarketo?: any;
};

const MarketoNewIntegration = ({ linkMarketo }: Props) => {
  const [integrationNickname, setIntegrationNickname] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [instanceURL, setInstanceURL] = useState('');
  const [clientId, setClientId] = useState('');

  const pageCopy = {
    header: 'Marketo Integration',
    subheader: (
      <span>
        To create a new integrations please enter your <b>Access Token</b> and a custom <b>Nickname</b> to create this
        integration (click{' '}
        <a
          style={{ textDecoration: 'none', color: colors.grey_03 }}
          href={'https://developers.hubspot.com/docs/api/private-apps'}
        >
          here
        </a>{' '}
        for more detailed instructions).
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 30px' }}>
          {pageCopy.subheader}
        </F3>
        <MainTextInput
          id="email-field"
          label="Integration Nickname"
          size="small"
          value={integrationNickname}
          focused
          onChange={(event) => setIntegrationNickname(event.target.value)}
        />
        <MainTextInput
          id="email-field"
          label="Instance URL"
          size="small"
          value={instanceURL}
          focused
          onChange={(event) => setInstanceURL(event.target.value)}
        />
        <MainTextInput
          id="client-id"
          label="Client ID"
          size="small"
          value={clientId}
          focused
          onChange={(event) => setClientId(event.target.value)}
        />
        <MainTextInput
          id="client-secret-field"
          label="Client Secret"
          size="small"
          value={clientSecret}
          focused
          onChange={(event) => setClientSecret(event.target.value)}
        />
      </TextContainer>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton
          style={{ width: '160px' }}
          onClick={() => linkMarketo(integrationNickname, instanceURL, clientId, clientSecret)}
        >
          Submit
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default MarketoNewIntegration;
