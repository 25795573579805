import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { RotateLoader } from 'react-spinners';
import { PrimaryButton } from '.';
import { colors, typography } from '../styles';

const useStyles = makeStyles((theme) => ({
  distillScoreBox: {
    width: '350px',
    margin: 'auto',
  },
}));

interface Props {
  goAction?: any;
  pageText?: any;
}

const LoadingPage = ({ goAction, pageText }: Props) => {
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (goAction) {
      setTimeout(() => goAction(), 2000);
    }
  }, []);

  if (loading) {
    return (
      <div style={{ textAlign: 'center' }}>
        <div style={{ margin: '300px auto 30px auto' }}>
          <RotateLoader />
        </div>
        <div style={{ margin: 'auto', color: colors.grey_02 }}>
          <p>{pageText ? pageText : 'One moment while we analyze the data'}</p>
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <div style={{ margin: '220px auto 0px auto' }}>
        <p>Analysis Complete!</p>
      </div>
      <div>
        Before we jump into it, what's a <p style={{ display: 'inline', color: '#5B8CFD' }}>AMP Score?</p>
      </div>
      <div>
        <div>
          The <b>AMP Score</b> is a synthesis of the signals from our proprietary algorithm, personalized for you. The
          higher the AMP Score, the better this prospect will fit into your book.
        </div>
      </div>
      <PrimaryButton onClick={goAction} children={'Ok, got it!'} />
    </div>
  );
};

export default LoadingPage;
