import React from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

type Props = {
  screenSize?: string;
  hoverSection: string;
  setHoverSection: any;
};

const Root = styled('div')({});

const TextContainer = styled('div')({
  position: 'absolute',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
});

const TitleText = styled('h1')({
  position: 'relative',
  letterSpacing: '-2px',
  lineHeight: '60px',
  ...typography.mainFont,
  margin: 'auto',
  color: colors.black_01,
  transition: '0.5s ease-in-out',
  //   backgroundColor: colors.grey_01,
  fontSize: '64px',
  fontWeight: '700px',
  zIndex: 100,
  // backgroundColor: colors.grey_02,
});

const Subheader = styled(TitleText)({
  fontSize: '16px',
  fontWeight: '400',
  color: colors.grey_02,
  letterSpacing: '0px',
  margin: '20px 0px 0px',
  lineHeight: '20px',
  width: '360px',
  zIndex: 100,
});

const arcSections = {
  unfocused: { opacity: 0.1 },
  focused: { opacity: 1 },
};

const arcTypography = {
  unfocused: { fill: colors.grey_02 },
  focused: { fill: colors.white },
};

export const DriveSalesWheel = ({ hoverSection, setHoverSection }: Props) => {
  const navigate = useNavigate();

  const toggleSection = (sectionKey: string) => () => {
    setTimeout(() => {
      setHoverSection(sectionKey);
    }, 100);
  };

  const generateBackgroundConfig = () => {
    const sectionConfig = {
      onHoverStart: toggleSection('default'),
    };
    return sectionConfig;
  };

  const generateSectionConfig = (sectionKey: string) => {
    const sectionConfig = {
      onClick: () => {
        navigate(pageCopy[sectionKey]?.link);
      },
      onHoverStart: toggleSection(sectionKey),
      variants: arcSections,
      initial: 'unfocused',
      // onHoverEnd: resetSection,
      animate: hoverSection === sectionKey ? 'focused' : 'unfocused',
      style: { cursor: 'pointer' },
    };
    return sectionConfig;
  };

  const generateSectionLabelConfig = (sectionKey: string) => {
    const labelConfig = {
      onClick: () => {
        navigate(pageCopy[sectionKey]?.link);
      },
      onHoverStart: toggleSection(sectionKey),
      variants: arcTypography,
      initial: 'unfocused',
      animate: hoverSection === sectionKey ? 'focused' : 'unfocused',
      style: { cursor: 'pointer' },
    };
    return labelConfig;
  };

  const pageCopy: any = {
    default: {
      header: 'Drive Sales',
      subheader: '',
    },
    identify: {
      header: 'Identify',
      subheader: `Empower your sales strategies by generating actionable signals tailor-made to enhance your sales team's distribution efforts, and facilitating the creation of new leads.`,
      link: '/drive-sales/influence-prospects',
    },
    learn: {
      header: 'Learn',
      subheader: `Gather feedback data to feed into the algorithms, thereby enabling a process of continuous learning and improvement. Allocate your marketing and sales resources strategically to engage potential customers.`,
      link: '/drive-sales/create-campaign',
    },
    optimize: {
      header: 'Optimize',
      subheader:
        'Get a comprehensive understanding of the performance of your distribution efforts. Observe how the algorithms learn and evolve based on the data points you provide and utilize this intelligence to fine-tune your strategies further.',
      link: '/drive-sales/outcomes',
    },
  };

  return (
    <Root>
      <TextContainer onMouseEnter={toggleSection('default')}>
        <TitleText>{pageCopy?.[hoverSection]?.header}</TitleText>
        <Subheader>{pageCopy?.[hoverSection]?.subheader}</Subheader>
      </TextContainer>
      <motion.svg
        width="1327"
        height="900"
        viewBox="0 0 1127 800"
        // opacity={0}
        // visibility={'hidden'}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...generateBackgroundConfig()}
      >
        {/* Wheel Sections */}
        {/* Learn */}
        <motion.path
          {...generateSectionConfig('learn')}
          d="M583.58 656.428C627.034 653.756 669.099 640.099 705.834 616.738C742.569 593.376 772.772 561.073 793.614 522.856C814.455 484.639 825.253 441.758 824.996 398.23C824.738 354.702 813.435 311.951 792.143 273.983L781.678 280.024L771.344 285.989C790.567 320.322 800.784 358.961 801.043 398.306C801.302 437.652 791.594 476.422 772.824 511.005C754.054 545.587 726.833 574.855 693.698 596.082C660.563 617.308 622.592 629.802 583.325 632.399L570.969 644.873L583.58 656.428Z"
          fill="#242B2F"
        />
        {/* Identify */}
        <motion.path
          {...generateSectionConfig('identify')}
          d="M782.34 258.153C758.298 221.863 725.437 192.269 686.834 172.142C648.231 152.015 605.15 142.014 561.627 143.077C518.103 144.139 475.562 156.23 437.987 178.217C400.413 200.204 369.035 231.367 346.794 268.786L357.259 274.827L367.593 280.793C387.72 246.982 416.079 218.815 450.03 198.918C483.981 179.022 522.417 168.042 561.756 167.004C601.095 165.965 640.057 174.901 675.011 192.978C709.964 211.056 739.771 237.686 761.654 270.388L778.637 274.85L782.34 258.153Z"
          fill="#242B2F"
        />
        {/* Optimize */}
        <motion.path
          {...generateSectionConfig('optimize')}
          d="M337.986 285.188C318.573 324.15 309.369 367.401 311.237 410.89C313.105 454.379 325.983 496.682 348.665 533.837C371.347 570.991 403.091 601.781 440.923 623.323C478.754 644.864 521.436 656.452 564.969 657L564.969 644.918L564.969 632.987C525.619 632.466 487.042 621.993 452.833 602.544C418.623 583.096 389.895 555.305 369.326 521.761C348.757 488.218 337.016 450.014 335.197 410.709C333.379 371.405 341.542 332.281 358.926 296.982L354.3 280.046L337.986 285.188Z"
          fill="#242B2F"
        />
        <motion.path
          opacity="0.1"
          {...generateBackgroundConfig()}
          d="M568 610C683.98 610 778 515.98 778 400C778 284.02 683.98 190 568 190C452.02 190 358 284.02 358 400C358 515.98 452.02 610 568 610Z"
          stroke="#242B2F"
          fill={'none'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* LEARN */}
        <motion.path
          d="M797.118 570.38L787.694 563.555L788.724 562.133L796.924 568.072L800.017 563.801L801.241 564.688L797.118 570.38Z"
          fill="#242B2F"
        />
        <motion.path
          d="M804.708 559.683L794.978 553.301L798.979 547.201L800.242 548.03L797.204 552.662L800.169 554.606L802.998 550.292L804.257 551.118L801.428 555.432L804.407 557.386L807.482 552.697L808.746 553.526L804.708 559.683Z"
          fill="#242B2F"
        />
        <motion.path
          d="M812.571 547.384L811.629 548.992L803.71 539.493L804.736 537.743L816.892 540.018L815.949 541.626L806.071 539.646L806.025 539.724L812.571 547.384ZM808.799 544.809L811.857 539.595L813.132 540.342L810.073 545.557L808.799 544.809Z"
          fill="#242B2F"
        />
        <motion.path
          d="M820.328 533.696L809.989 528.356L811.892 524.671C812.306 523.87 812.787 523.276 813.336 522.891C813.887 522.501 814.474 522.301 815.097 522.291C815.717 522.279 816.346 522.437 816.982 522.765C817.614 523.092 818.103 523.513 818.447 524.027C818.789 524.537 818.956 525.126 818.947 525.795C818.94 526.461 818.73 527.194 818.316 527.995L816.874 530.787L815.531 530.093L816.9 527.443C817.161 526.938 817.301 526.49 817.319 526.099C817.34 525.704 817.25 525.362 817.051 525.071C816.851 524.781 816.558 524.535 816.171 524.335C815.78 524.134 815.403 524.035 815.039 524.039C814.676 524.039 814.337 524.16 814.02 524.401C813.702 524.638 813.41 525.013 813.144 525.528L812.132 527.487L821.133 532.136L820.328 533.696ZM818.299 526.183L824.283 526.037L823.365 527.814L817.407 527.909L818.299 526.183Z"
          fill="#242B2F"
        />
        <motion.path
          d="M819.818 507.924L830.428 512.704L829.765 514.175L819.555 516.063L819.511 516.161L827.292 519.666L826.57 521.267L815.961 516.488L816.628 515.006L826.851 513.118L826.896 513.019L819.104 509.509L819.818 507.924Z"
          fill="#242B2F"
        />
        {/* Capture Feedback */}
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M751.542 560.231L750.652 561.219C750.407 561.066 750.157 560.97 749.904 560.928C749.65 560.887 749.4 560.891 749.153 560.94C748.906 560.989 748.671 561.081 748.446 561.215C748.224 561.347 748.019 561.517 747.833 561.724C747.496 562.097 747.289 562.516 747.21 562.981C747.134 563.444 747.2 563.924 747.409 564.422C747.619 564.918 747.986 565.402 748.51 565.874C749.037 566.349 749.558 566.666 750.074 566.824C750.591 566.981 751.075 566.995 751.527 566.867C751.979 566.739 752.371 566.49 752.704 566.121C752.889 565.916 753.036 565.697 753.147 565.464C753.258 565.227 753.325 564.984 753.351 564.736C753.376 564.487 753.356 564.24 753.291 563.993C753.226 563.743 753.108 563.505 752.937 563.279L753.831 562.294C754.102 562.634 754.303 563.001 754.434 563.394C754.565 563.784 754.621 564.184 754.602 564.595C754.583 565.003 754.49 565.405 754.323 565.802C754.157 566.199 753.913 566.575 753.591 566.932C753.085 567.493 752.502 567.873 751.84 568.073C751.176 568.27 750.481 568.271 749.755 568.075C749.031 567.877 748.324 567.467 747.634 566.845C746.942 566.221 746.462 565.559 746.194 564.859C745.924 564.157 745.854 563.467 745.984 562.788C746.112 562.108 746.428 561.488 746.932 560.929C747.242 560.585 747.581 560.309 747.948 560.1C748.315 559.888 748.701 559.749 749.106 559.681C749.509 559.612 749.917 559.622 750.33 559.712C750.74 559.799 751.145 559.972 751.542 560.231Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M758.831 560.966C758.564 561.283 758.264 561.521 757.931 561.679C757.596 561.835 757.252 561.893 756.9 561.853C756.549 561.811 756.21 561.652 755.882 561.376C755.599 561.138 755.412 560.888 755.321 560.626C755.229 560.363 755.208 560.095 755.257 559.821C755.306 559.546 755.399 559.272 755.536 558.998C755.674 558.724 755.828 558.457 755.999 558.196C756.216 557.866 756.393 557.599 756.528 557.394C756.662 557.187 756.738 557.019 756.757 556.89C756.776 556.761 756.727 556.648 756.609 556.549L756.587 556.53C756.302 556.29 756.013 556.185 755.721 556.214C755.431 556.242 755.154 556.413 754.889 556.728C754.613 557.057 754.468 557.377 754.455 557.688C754.441 557.996 754.492 558.26 754.608 558.48L753.629 559.221C753.416 558.856 753.304 558.491 753.292 558.125C753.28 557.755 753.347 557.397 753.492 557.052C753.636 556.705 753.834 556.38 754.088 556.078C754.257 555.878 754.459 555.686 754.695 555.502C754.931 555.314 755.193 555.175 755.481 555.083C755.771 554.989 756.085 554.976 756.421 555.044C756.756 555.11 757.106 555.296 757.471 555.603L760.799 558.4L759.998 559.352L759.313 558.776L759.28 558.816C759.353 558.985 759.398 559.183 759.413 559.412C759.429 559.64 759.394 559.887 759.308 560.153C759.222 560.418 759.063 560.689 758.831 560.966ZM758.227 560.096C758.453 559.826 758.594 559.551 758.648 559.27C758.704 558.986 758.688 558.723 758.6 558.478C758.512 558.23 758.369 558.022 758.171 557.856L757.526 557.313C757.531 557.377 757.507 557.472 757.453 557.598C757.399 557.719 757.33 557.852 757.246 557.997C757.16 558.139 757.075 558.278 756.992 558.412C756.906 558.544 756.835 558.655 756.779 558.745C756.644 558.954 756.538 559.161 756.463 559.365C756.39 559.567 756.368 559.76 756.396 559.943C756.423 560.122 756.524 560.284 756.698 560.431C756.939 560.634 757.197 560.698 757.471 560.624C757.742 560.548 757.994 560.372 758.227 560.096Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M763.628 558.816L756.585 553.213L757.359 552.24L758.19 552.9L758.256 552.817C758.195 552.674 758.138 552.492 758.085 552.272C758.031 552.052 758.029 551.803 758.079 551.527C758.126 551.248 758.276 550.95 758.529 550.632C758.857 550.219 759.256 549.933 759.723 549.774C760.19 549.616 760.694 549.601 761.234 549.73C761.776 549.856 762.324 550.139 762.878 550.58C763.431 551.02 763.832 551.49 764.081 551.989C764.328 552.487 764.43 552.981 764.387 553.474C764.342 553.964 764.155 554.416 763.826 554.829C763.578 555.141 763.324 555.354 763.062 555.469C762.802 555.581 762.557 555.637 762.329 555.637C762.1 555.637 761.908 555.623 761.751 555.597L761.703 555.657L764.421 557.819L763.628 558.816ZM759.923 554.273C760.283 554.56 760.641 554.759 760.995 554.87C761.349 554.981 761.682 554.999 761.993 554.923C762.301 554.846 762.57 554.663 762.798 554.377C763.035 554.079 763.154 553.767 763.155 553.441C763.154 553.114 763.058 552.79 762.866 552.47C762.675 552.148 762.409 551.851 762.066 551.579C761.728 551.31 761.384 551.12 761.034 551.008C760.685 550.894 760.353 550.876 760.036 550.955C759.721 551.031 759.445 551.219 759.206 551.52C758.976 551.809 758.856 552.112 758.846 552.431C758.837 552.747 758.926 553.063 759.111 553.377C759.297 553.692 759.567 553.991 759.923 554.273Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M763.397 544.431L764.214 545.046L762.065 547.903L761.247 547.288L763.397 544.431ZM760.571 545.58L761.337 544.561L766.285 548.284C766.482 548.432 766.653 548.515 766.797 548.531C766.939 548.545 767.064 548.519 767.17 548.454C767.276 548.384 767.37 548.294 767.454 548.182C767.516 548.101 767.564 548.025 767.598 547.955C767.633 547.885 767.66 547.831 767.679 547.791L768.658 548.24C768.637 548.316 768.596 548.417 768.537 548.544C768.48 548.671 768.391 548.818 768.27 548.983C768.073 549.254 767.838 549.468 767.564 549.624C767.29 549.781 766.994 549.852 766.678 549.838C766.361 549.824 766.045 549.698 765.73 549.461L760.571 545.58Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M770.207 541.535L767.085 539.316L767.826 538.274L773.16 542.067L772.434 543.088L771.51 542.431L771.471 542.486C771.668 542.812 771.766 543.177 771.763 543.583C771.76 543.985 771.615 544.386 771.331 544.787C771.087 545.129 770.796 545.379 770.458 545.536C770.119 545.689 769.747 545.735 769.341 545.673C768.937 545.609 768.515 545.42 768.075 545.107L764.682 542.695L765.42 541.656L768.689 543.98C769.052 544.238 769.413 544.344 769.772 544.295C770.13 544.247 770.424 544.062 770.653 543.74C770.791 543.545 770.88 543.318 770.919 543.056C770.96 542.793 770.928 542.527 770.821 542.26C770.717 541.99 770.512 541.749 770.207 541.535Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M774.59 539.966L769.155 536.318L769.842 535.295L770.705 535.875L770.743 535.818C770.517 535.523 770.404 535.194 770.404 534.831C770.404 534.465 770.511 534.122 770.724 533.804C770.769 533.738 770.823 533.662 770.888 533.575C770.955 533.486 771.009 533.418 771.051 533.371L772.063 534.051C772.022 534.085 771.959 534.152 771.872 534.251C771.783 534.348 771.701 534.451 771.629 534.56C771.461 534.81 771.364 535.068 771.339 535.335C771.313 535.598 771.355 535.849 771.465 536.087C771.573 536.324 771.745 536.522 771.981 536.68L775.3 538.908L774.59 539.966Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M779.231 533.117C778.886 533.662 778.473 534.057 777.991 534.304C777.508 534.546 776.986 534.639 776.426 534.584C775.865 534.525 775.298 534.314 774.724 533.95C774.158 533.592 773.725 533.17 773.427 532.685C773.13 532.198 772.982 531.687 772.981 531.152C772.981 530.614 773.144 530.089 773.47 529.575C773.667 529.263 773.91 528.993 774.199 528.766C774.488 528.538 774.816 528.378 775.184 528.285C775.552 528.192 775.956 528.19 776.393 528.279C776.829 528.366 777.298 528.569 777.799 528.886L778.181 529.128L775.398 533.524L774.591 533.014L776.707 529.672C776.423 529.493 776.136 529.392 775.845 529.369C775.551 529.344 775.274 529.396 775.015 529.524C774.757 529.65 774.54 529.852 774.364 530.131C774.172 530.433 774.079 530.744 774.085 531.064C774.09 531.38 774.173 531.675 774.333 531.947C774.492 532.216 774.71 532.438 774.986 532.613L775.616 533.012C775.986 533.246 776.341 533.38 776.682 533.415C777.025 533.447 777.34 533.389 777.625 533.24C777.909 533.09 778.15 532.858 778.349 532.543C778.478 532.339 778.567 532.135 778.616 531.93C778.662 531.724 778.665 531.523 778.625 531.326C778.585 531.129 778.499 530.944 778.367 530.769L779.196 529.867C779.444 530.138 779.615 530.451 779.707 530.805C779.798 531.155 779.805 531.528 779.729 531.924C779.653 532.316 779.487 532.714 779.231 533.117Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M783.614 525.673L776.034 521.347L778.717 516.647L779.701 517.209L777.671 520.766L779.981 522.084L781.819 518.864L782.799 519.424L780.962 522.644L784.267 524.53L783.614 525.673Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M789.107 516.013C788.807 516.584 788.426 517.011 787.965 517.295C787.503 517.575 786.991 517.71 786.428 517.7C785.864 517.685 785.282 517.52 784.681 517.204C784.088 516.892 783.623 516.506 783.287 516.047C782.952 515.585 782.763 515.087 782.72 514.554C782.677 514.018 782.798 513.481 783.081 512.943C783.253 512.616 783.474 512.328 783.743 512.078C784.013 511.828 784.327 511.642 784.687 511.52C785.046 511.398 785.448 511.364 785.892 511.417C786.333 511.47 786.816 511.634 787.342 511.911L787.741 512.121L785.318 516.725L784.474 516.281L786.315 512.781C786.019 512.625 785.724 512.547 785.432 512.548C785.137 512.547 784.865 512.62 784.617 512.769C784.37 512.915 784.17 513.134 784.016 513.425C783.85 513.742 783.782 514.06 783.814 514.378C783.844 514.693 783.95 514.98 784.131 515.239C784.311 515.494 784.546 515.698 784.835 515.85L785.495 516.197C785.882 516.401 786.247 516.506 786.59 516.514C786.935 516.518 787.243 516.435 787.516 516.264C787.787 516.092 788.008 515.841 788.182 515.512C788.294 515.298 788.367 515.087 788.399 514.879C788.429 514.67 788.416 514.469 788.36 514.276C788.304 514.083 788.204 513.905 788.058 513.742L788.812 512.776C789.082 513.027 789.276 513.325 789.396 513.671C789.515 514.012 789.552 514.384 789.508 514.784C789.463 515.181 789.33 515.591 789.107 516.013Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M792.577 509.15C792.294 509.729 791.927 510.168 791.475 510.466C791.021 510.76 790.513 510.91 789.951 510.917C789.387 510.92 788.799 510.772 788.189 510.474C787.587 510.18 787.111 509.809 786.761 509.36C786.412 508.908 786.208 508.416 786.148 507.885C786.09 507.35 786.194 506.81 786.461 506.264C786.623 505.932 786.835 505.637 787.096 505.379C787.358 505.121 787.667 504.926 788.023 504.793C788.378 504.66 788.779 504.613 789.224 504.653C789.666 504.692 790.155 504.842 790.688 505.103L791.094 505.301L788.812 509.977L787.954 509.558L789.689 506.004C789.387 505.857 789.091 505.788 788.798 505.797C788.504 505.805 788.235 505.887 787.991 506.043C787.748 506.197 787.555 506.421 787.41 506.718C787.253 507.039 787.195 507.359 787.236 507.676C787.276 507.989 787.391 508.273 787.58 508.527C787.768 508.776 788.008 508.973 788.302 509.116L788.972 509.443C789.365 509.635 789.733 509.73 790.076 509.726C790.421 509.72 790.727 509.628 790.994 509.449C791.259 509.268 791.474 509.011 791.637 508.677C791.743 508.46 791.809 508.247 791.835 508.038C791.858 507.828 791.839 507.628 791.778 507.436C791.716 507.245 791.61 507.07 791.46 506.912L792.184 505.923C792.461 506.166 792.665 506.458 792.795 506.8C792.924 507.138 792.973 507.508 792.941 507.909C792.908 508.307 792.787 508.721 792.577 509.15Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M795.853 502.131C795.638 502.613 795.322 502.989 794.907 503.257C794.49 503.522 794.004 503.66 793.448 503.67C792.892 503.676 792.29 503.535 791.644 503.247C790.999 502.958 790.494 502.604 790.131 502.183C789.769 501.76 789.551 501.305 789.477 500.817C789.402 500.33 789.472 499.846 789.686 499.366C789.852 498.995 790.048 498.725 790.273 498.555C790.497 498.381 790.718 498.268 790.937 498.217C791.157 498.163 791.345 498.128 791.501 498.114L791.533 498.044L788.572 496.721L789.091 495.558L797.06 499.118L796.552 500.254L795.622 499.839L795.579 499.936C795.674 500.063 795.775 500.229 795.879 500.434C795.984 500.637 796.047 500.879 796.067 501.162C796.087 501.445 796.016 501.768 795.853 502.131ZM794.976 501.431C795.126 501.096 795.164 500.774 795.09 500.464C795.016 500.151 794.847 499.864 794.584 499.603C794.322 499.34 793.981 499.114 793.56 498.927C793.145 498.741 792.756 498.639 792.391 498.619C792.027 498.599 791.706 498.666 791.429 498.819C791.152 498.972 790.938 499.219 790.786 499.559C790.629 499.909 790.59 500.241 790.667 500.556C790.744 500.87 790.913 501.157 791.174 501.417C791.435 501.674 791.763 501.89 792.158 502.067C792.557 502.245 792.943 502.346 793.315 502.369C793.688 502.392 794.019 502.329 794.31 502.179C794.6 502.025 794.822 501.776 794.976 501.431Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M798.123 496.616L790.039 493.328L790.52 492.148L793.523 493.37L793.552 493.299C793.454 493.179 793.348 493.021 793.235 492.824C793.121 492.628 793.049 492.39 793.019 492.111C792.986 491.831 793.046 491.502 793.199 491.126C793.398 490.636 793.699 490.25 794.103 489.966C794.506 489.683 794.986 489.526 795.54 489.498C796.096 489.466 796.701 489.583 797.357 489.85C798.012 490.117 798.529 490.454 798.909 490.863C799.286 491.271 799.523 491.717 799.62 492.202C799.715 492.685 799.663 493.171 799.464 493.661C799.314 494.029 799.13 494.305 798.911 494.489C798.693 494.67 798.474 494.793 798.255 494.857C798.036 494.921 797.847 494.963 797.689 494.981L797.649 495.08L798.592 495.464L798.123 496.616ZM795.562 494.227C795.989 494.4 796.388 494.49 796.759 494.497C797.13 494.504 797.454 494.427 797.731 494.267C798.005 494.106 798.212 493.855 798.35 493.516C798.493 493.163 798.52 492.83 798.429 492.518C798.336 492.204 798.152 491.92 797.878 491.668C797.604 491.412 797.265 491.202 796.86 491.037C796.46 490.875 796.076 490.789 795.708 490.78C795.342 490.769 795.018 490.846 794.736 491.011C794.456 491.173 794.243 491.431 794.098 491.786C793.959 492.128 793.93 492.454 794.01 492.762C794.091 493.068 794.265 493.346 794.531 493.595C794.798 493.845 795.141 494.055 795.562 494.227Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M801.806 487.692C801.661 488.081 801.459 488.405 801.198 488.666C800.935 488.926 800.63 489.096 800.285 489.176C799.941 489.254 799.567 489.218 799.165 489.069C798.819 488.94 798.559 488.767 798.385 488.551C798.211 488.334 798.101 488.089 798.055 487.814C798.009 487.539 798.004 487.25 798.042 486.945C798.079 486.641 798.135 486.337 798.208 486.034C798.302 485.651 798.379 485.34 798.437 485.101C798.493 484.861 798.509 484.677 798.483 484.55C798.458 484.422 798.374 484.331 798.23 484.278L798.202 484.267C797.853 484.138 797.546 484.136 797.281 484.261C797.017 484.384 796.813 484.639 796.67 485.025C796.52 485.427 796.491 485.777 796.583 486.075C796.674 486.369 796.81 486.6 796.993 486.769L796.32 487.796C795.997 487.524 795.768 487.217 795.635 486.877C795.499 486.532 795.442 486.173 795.463 485.799C795.481 485.424 795.559 485.051 795.697 484.681C795.788 484.436 795.914 484.187 796.075 483.935C796.234 483.679 796.434 483.459 796.675 483.276C796.917 483.09 797.208 482.973 797.547 482.923C797.884 482.873 798.277 482.931 798.724 483.098L802.797 484.614L802.363 485.78L801.524 485.468L801.507 485.516C801.632 485.651 801.741 485.823 801.833 486.033C801.924 486.243 801.974 486.487 801.982 486.766C801.991 487.045 801.932 487.354 801.806 487.692ZM800.944 487.075C801.067 486.745 801.107 486.439 801.064 486.156C801.022 485.87 800.918 485.627 800.753 485.427C800.587 485.222 800.383 485.075 800.14 484.985L799.35 484.69C799.376 484.749 799.386 484.846 799.377 484.982C799.367 485.115 799.347 485.264 799.316 485.428C799.282 485.591 799.249 485.75 799.216 485.904C799.18 486.058 799.15 486.186 799.127 486.29C799.07 486.532 799.04 486.762 799.038 486.98C799.037 487.195 799.08 487.383 799.168 487.547C799.254 487.706 799.404 487.825 799.617 487.905C799.913 488.015 800.177 487.989 800.41 487.827C800.64 487.664 800.818 487.414 800.944 487.075Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M804.534 479.99C804.331 480.59 804.02 481.06 803.602 481.401C803.182 481.739 802.691 481.942 802.131 482.01C801.571 482.079 800.981 482.007 800.359 481.797C799.729 481.584 799.214 481.277 798.812 480.877C798.408 480.476 798.143 480.015 798.017 479.492C797.891 478.97 797.927 478.416 798.125 477.832C798.284 477.362 798.514 476.971 798.814 476.662C799.111 476.352 799.454 476.136 799.842 476.016C800.232 475.893 800.642 475.881 801.074 475.979L800.676 477.153C800.364 477.12 800.064 477.183 799.775 477.343C799.488 477.501 799.279 477.772 799.149 478.157C799.035 478.493 799.024 478.818 799.116 479.131C799.206 479.44 799.39 479.72 799.668 479.97C799.944 480.22 800.302 480.419 800.744 480.568C801.196 480.722 801.609 480.785 801.984 480.759C802.359 480.733 802.678 480.625 802.943 480.436C803.209 480.244 803.4 479.977 803.516 479.636C803.593 479.407 803.622 479.186 803.601 478.972C803.579 478.754 803.51 478.555 803.394 478.375C803.279 478.192 803.118 478.037 802.912 477.911L803.31 476.736C803.697 476.915 804.01 477.167 804.251 477.491C804.492 477.816 804.641 478.192 804.697 478.619C804.755 479.043 804.7 479.5 804.534 479.99Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('learn')}
          d="M803.909 474.316L802.421 473.864L802.487 473.652L800.613 470.341L801.064 468.884L803.196 472.659L803.137 472.85L803.909 474.316ZM805.683 476.093L797.347 473.509L797.724 472.292L806.06 474.876L805.683 476.093ZM806.907 472.144L803.241 473.462L802.651 472.36L807.37 470.651L806.907 472.144Z"
          fill="#758790"
        />
        {/* IDENTIFY */}
        <motion.path
          d="M307.042 500.784C305.874 501.207 304.792 501.358 303.797 501.235C302.805 501.11 301.948 500.758 301.227 500.177C300.509 499.599 299.98 498.838 299.638 497.894C299.295 496.947 299.213 496.02 299.392 495.113C299.576 494.208 300.011 493.39 300.697 492.658C301.382 491.927 302.308 491.35 303.472 490.928C304.64 490.505 305.72 490.355 306.712 490.48C307.707 490.603 308.563 490.953 309.281 491.531C310.002 492.112 310.535 492.876 310.878 493.823C311.22 494.767 311.299 495.691 311.115 496.596C310.936 497.503 310.503 498.322 309.818 499.054C309.136 499.784 308.21 500.361 307.042 500.784ZM306.45 499.15C307.34 498.827 308.038 498.411 308.542 497.902C309.052 497.395 309.373 496.836 309.506 496.224C309.643 495.614 309.599 494.996 309.372 494.369C309.143 493.738 308.781 493.233 308.285 492.853C307.792 492.471 307.187 492.247 306.47 492.181C305.758 492.116 304.957 492.246 304.066 492.568C303.176 492.891 302.476 493.305 301.967 493.812C301.462 494.321 301.141 494.88 301.003 495.49C300.869 496.099 300.916 496.718 301.145 497.349C301.372 497.976 301.732 498.48 302.224 498.861C302.722 499.245 303.327 499.469 304.039 499.533C304.756 499.6 305.56 499.472 306.45 499.15Z"
          fill="#242B2F"
        />
        <motion.path
          d="M304.008 508.789L314.719 504.242L316.34 508.06C316.694 508.893 316.835 509.648 316.764 510.324C316.693 511 316.452 511.586 316.041 512.082C315.634 512.576 315.102 512.962 314.447 513.24C313.788 513.52 313.137 513.634 312.493 513.582C311.855 513.532 311.265 513.297 310.724 512.876C310.189 512.457 309.745 511.833 309.392 511.003L308.278 508.378L309.648 507.796L310.7 510.275C310.924 510.802 311.196 511.19 311.516 511.441C311.84 511.69 312.193 511.824 312.576 511.843C312.958 511.862 313.352 511.785 313.756 511.614C314.161 511.442 314.487 511.213 314.736 510.926C314.985 510.639 315.127 510.293 315.165 509.886C315.203 509.483 315.109 509.013 314.881 508.476L314.019 506.447L304.694 510.405L304.008 508.789Z"
          fill="#242B2F"
        />
        <motion.path
          d="M318.207 516.033L319.57 515.379L323.468 523.497L322.105 524.152L320.534 520.879L311.407 525.261L310.649 523.684L319.776 519.301L318.207 516.033Z"
          fill="#242B2F"
        />
        <motion.path
          d="M327.127 530.417L316.859 535.891L316.033 534.342L326.301 528.867L327.127 530.417Z"
          fill="#242B2F"
        />
        <motion.path
          d="M329.883 535.492L330.97 537.324L325.083 545.127L325.152 545.244L334.822 543.813L335.909 545.645L325.903 551.585L325.05 550.148L332.291 545.85L332.236 545.757L323.258 547.095L322.55 545.903L328.03 538.66L327.975 538.567L320.729 542.868L319.876 541.432L329.883 535.492Z"
          fill="#242B2F"
        />
        <motion.path
          d="M340.032 551.895L330.307 558.285L329.343 556.817L339.068 550.427L340.032 551.895Z"
          fill="#242B2F"
        />
        <motion.path
          d="M334.04 563.791L334.99 563.108L345.833 562.853L342.233 557.841L343.46 556.96L348.314 563.715L347.363 564.398L336.52 564.653L340.121 569.664L338.893 570.546L334.04 563.791Z"
          fill="#242B2F"
        />
        <motion.path
          d="M341.997 574.619L351.103 567.376L355.645 573.085L354.462 574.026L351.014 569.691L348.239 571.898L351.45 575.935L350.272 576.872L347.06 572.835L344.272 575.053L347.763 579.441L346.581 580.382L341.997 574.619Z"
          fill="#242B2F"
        />
        {/* Enhance Alogrithms */}
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M326.89 464.213L335.299 461.88L336.762 467.153L335.67 467.456L334.559 463.452L331.997 464.163L333.031 467.892L331.943 468.194L330.909 464.465L328.334 465.179L329.458 469.232L328.366 469.535L326.89 464.213Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M333.09 471.747L329.379 472.901L329.001 471.685L335.251 469.741L335.614 470.909L334.597 471.225L334.621 471.303C334.996 471.343 335.332 471.486 335.628 471.73C335.925 471.977 336.147 472.335 336.293 472.804C336.425 473.23 336.451 473.631 336.372 474.007C336.295 474.382 336.109 474.712 335.814 474.997C335.519 475.282 335.114 475.505 334.598 475.665L330.623 476.901L330.244 475.685L334.073 474.494C334.526 474.353 334.844 474.125 335.025 473.81C335.21 473.494 335.238 473.129 335.11 472.717C335.022 472.435 334.883 472.203 334.692 472.021C334.503 471.842 334.27 471.725 333.995 471.671C333.722 471.619 333.421 471.644 333.09 471.747Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M335.438 479.046L331.764 480.314L331.349 479.109L339.598 476.262L340.008 477.451L336.939 478.51L336.965 478.586C337.348 478.617 337.689 478.747 337.988 478.977C338.287 479.208 338.52 479.563 338.686 480.044C338.832 480.468 338.873 480.869 338.807 481.246C338.743 481.626 338.568 481.963 338.282 482.257C337.999 482.553 337.6 482.79 337.084 482.968L333.149 484.326L332.733 483.121L336.524 481.813C336.978 481.657 337.289 481.418 337.458 481.099C337.63 480.778 337.644 480.409 337.5 479.993C337.402 479.708 337.254 479.474 337.055 479.29C336.857 479.109 336.619 478.994 336.341 478.946C336.067 478.9 335.766 478.933 335.438 479.046Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M334.517 488.425C334.37 488.037 334.308 487.659 334.333 487.291C334.36 486.922 334.476 486.594 334.683 486.306C334.89 486.02 335.195 485.801 335.596 485.649C335.941 485.518 336.25 485.476 336.524 485.524C336.797 485.572 337.042 485.684 337.258 485.861C337.474 486.037 337.668 486.252 337.84 486.505C338.013 486.759 338.171 487.024 338.316 487.3C338.497 487.651 338.645 487.935 338.758 488.153C338.874 488.371 338.984 488.519 339.087 488.598C339.19 488.678 339.313 488.69 339.457 488.636L339.485 488.625C339.832 488.493 340.065 488.292 340.181 488.023C340.299 487.757 340.284 487.431 340.138 487.046C339.986 486.645 339.777 486.362 339.511 486.199C339.249 486.037 338.994 485.953 338.745 485.947L338.575 484.731C338.997 484.723 339.371 484.803 339.696 484.971C340.025 485.141 340.305 485.373 340.536 485.668C340.769 485.963 340.956 486.294 341.096 486.663C341.189 486.908 341.258 487.178 341.303 487.474C341.353 487.771 341.347 488.068 341.286 488.364C341.227 488.664 341.086 488.944 340.863 489.205C340.642 489.465 340.309 489.679 339.863 489.849L335.8 491.393L335.358 490.23L336.194 489.912L336.176 489.864C335.993 489.845 335.798 489.787 335.59 489.69C335.383 489.592 335.184 489.441 334.994 489.237C334.804 489.033 334.645 488.762 334.517 488.425ZM335.571 488.32C335.697 488.65 335.869 488.906 336.088 489.091C336.307 489.278 336.546 489.392 336.802 489.435C337.062 489.479 337.312 489.455 337.554 489.363L338.343 489.063C338.284 489.037 338.213 488.97 338.129 488.862C338.05 488.755 337.967 488.63 337.882 488.486C337.799 488.341 337.72 488.2 337.643 488.062C337.569 487.923 337.507 487.807 337.456 487.714C337.339 487.494 337.21 487.301 337.068 487.136C336.927 486.974 336.77 486.86 336.597 486.796C336.427 486.733 336.236 486.741 336.023 486.822C335.729 486.934 335.547 487.128 335.478 487.403C335.412 487.677 335.443 487.983 335.571 488.32Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M340.761 493.297L337.173 494.789L336.684 493.612L342.728 491.1L343.197 492.23L342.214 492.639L342.245 492.713C342.622 492.719 342.97 492.83 343.287 493.046C343.606 493.265 343.859 493.601 344.048 494.055C344.219 494.466 344.282 494.863 344.238 495.245C344.196 495.625 344.041 495.971 343.774 496.282C343.506 496.593 343.123 496.852 342.625 497.059L338.78 498.657L338.291 497.48L341.994 495.942C342.432 495.76 342.727 495.503 342.879 495.173C343.033 494.841 343.028 494.475 342.862 494.077C342.749 493.804 342.589 493.586 342.382 493.422C342.177 493.261 341.935 493.167 341.655 493.138C341.379 493.111 341.081 493.164 340.761 493.297Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M340.746 503.402C340.485 502.825 340.391 502.269 340.464 501.734C340.541 501.201 340.761 500.718 341.124 500.286C341.486 499.854 341.967 499.502 342.565 499.232C343.171 498.958 343.757 498.831 344.323 498.849C344.892 498.866 345.401 499.023 345.849 499.319C346.298 499.615 346.649 500.044 346.903 500.605C347.108 501.058 347.206 501.5 347.198 501.931C347.193 502.361 347.089 502.752 346.887 503.105C346.687 503.461 346.395 503.75 346.012 503.973L345.502 502.843C345.753 502.654 345.929 502.403 346.031 502.089C346.133 501.778 346.101 501.437 345.933 501.067C345.787 500.743 345.574 500.499 345.293 500.333C345.016 500.168 344.69 500.089 344.316 500.096C343.945 500.102 343.547 500.201 343.122 500.392C342.687 500.589 342.342 500.825 342.085 501.1C341.829 501.374 341.669 501.671 341.604 501.99C341.541 502.312 341.583 502.637 341.732 502.966C341.831 503.186 341.962 503.367 342.123 503.51C342.287 503.654 342.474 503.752 342.681 503.804C342.89 503.86 343.113 503.863 343.35 503.815L343.861 504.945C343.457 505.078 343.056 505.108 342.658 505.035C342.26 504.963 341.895 504.789 341.563 504.516C341.231 504.245 340.959 503.874 340.746 503.402Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M343.982 510.255C343.699 509.676 343.579 509.117 343.621 508.577C343.668 508.039 343.861 507.545 344.202 507.097C344.546 506.65 345.023 506.278 345.633 505.979C346.235 505.685 346.821 505.537 347.39 505.537C347.961 505.539 348.474 505.68 348.93 505.96C349.388 506.242 349.75 506.656 350.017 507.202C350.18 507.534 350.282 507.882 350.325 508.247C350.367 508.612 350.332 508.976 350.218 509.338C350.105 509.701 349.895 510.045 349.59 510.372C349.288 510.697 348.87 510.99 348.336 511.251L347.931 511.45L345.643 506.776L346.501 506.357L348.239 509.909C348.54 509.761 348.777 509.569 348.949 509.333C349.124 509.096 349.225 508.833 349.251 508.545C349.279 508.259 349.221 507.969 349.076 507.673C348.918 507.351 348.702 507.109 348.426 506.947C348.155 506.785 347.861 506.702 347.544 506.695C347.232 506.69 346.929 506.759 346.635 506.903L345.965 507.231C345.572 507.423 345.272 507.655 345.064 507.928C344.857 508.203 344.742 508.501 344.719 508.823C344.698 509.143 344.77 509.47 344.933 509.804C345.04 510.021 345.167 510.204 345.316 510.352C345.467 510.5 345.637 510.608 345.825 510.677C346.014 510.746 346.217 510.77 346.435 510.749L346.77 511.928C346.408 511.997 346.053 511.978 345.703 511.871C345.357 511.765 345.035 511.576 344.738 511.304C344.444 511.034 344.192 510.684 343.982 510.255Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M348.447 518.663L347.771 517.44L356.929 515.969L357.664 517.301L351.543 524.27L350.867 523.046L355.926 517.433L355.893 517.374L348.447 518.663ZM351.551 517.216L353.744 521.185L352.774 521.721L350.582 517.752L351.551 517.216Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M360.366 522.028L352.825 526.421L352.184 525.32L359.725 520.927L360.366 522.028Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M353.136 531.97C352.865 531.527 352.689 531.11 352.609 530.719C352.531 530.33 352.526 529.977 352.594 529.658C352.662 529.34 352.779 529.062 352.946 528.823L353.904 529.52C353.837 529.651 353.778 529.807 353.726 529.989C353.673 530.174 353.662 530.388 353.694 530.629C353.726 530.872 353.834 531.144 354.019 531.444C354.271 531.856 354.58 532.134 354.946 532.28C355.31 532.427 355.709 532.367 356.145 532.099L357.242 531.427L357.2 531.358C357.041 531.365 356.851 531.352 356.63 531.317C356.411 531.285 356.182 531.195 355.945 531.048C355.708 530.9 355.484 530.655 355.275 530.314C355.005 529.873 354.864 529.413 354.853 528.933C354.846 528.454 354.982 527.988 355.26 527.534C355.542 527.081 355.98 526.672 356.573 526.308C357.166 525.945 357.736 525.733 358.284 525.674C358.832 525.618 359.327 525.697 359.769 525.913C360.214 526.127 360.574 526.458 360.848 526.906C361.061 527.252 361.174 527.567 361.189 527.852C361.205 528.135 361.174 528.382 361.094 528.595C361.016 528.809 360.939 528.985 360.862 529.122L360.911 529.202L361.816 528.647L362.469 529.711L356.765 533.209C356.286 533.503 355.824 533.633 355.38 533.599C354.936 533.564 354.523 533.402 354.142 533.113C353.763 532.826 353.428 532.445 353.136 531.97ZM356.323 530.001C356.514 530.313 356.749 530.533 357.027 530.659C357.308 530.787 357.62 530.822 357.962 530.766C358.307 530.71 358.673 530.564 359.058 530.328C359.433 530.098 359.73 529.84 359.947 529.553C360.165 529.266 360.287 528.966 360.315 528.653C360.345 528.338 360.263 528.021 360.068 527.704C359.868 527.377 359.616 527.157 359.313 527.043C359.012 526.927 358.683 526.902 358.327 526.967C357.972 527.035 357.616 527.178 357.26 527.397C356.895 527.621 356.608 527.873 356.4 528.154C356.192 528.435 356.078 528.732 356.056 529.045C356.037 529.36 356.125 529.679 356.323 530.001Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M359.773 537.811C359.435 537.299 359.257 536.774 359.239 536.238C359.222 535.701 359.354 535.188 359.637 534.698C359.919 534.208 360.342 533.777 360.906 533.404C361.473 533.03 362.038 532.808 362.601 532.738C363.165 532.669 363.69 532.747 364.177 532.974C364.664 533.2 365.077 533.57 365.415 534.081C365.753 534.593 365.931 535.118 365.949 535.654C365.967 536.191 365.833 536.705 365.548 537.196C365.263 537.688 364.838 538.121 364.271 538.495C363.707 538.868 363.145 539.088 362.583 539.156C362.022 539.224 361.498 539.145 361.011 538.918C360.524 538.692 360.112 538.323 359.773 537.811ZM360.668 537.224C360.887 537.556 361.157 537.773 361.476 537.875C361.796 537.977 362.136 537.984 362.496 537.896C362.858 537.81 363.214 537.651 363.565 537.419C363.914 537.189 364.198 536.924 364.419 536.625C364.644 536.326 364.774 536.009 364.807 535.673C364.841 535.338 364.748 535.004 364.529 534.672C364.308 534.338 364.036 534.12 363.712 534.017C363.39 533.916 363.047 533.91 362.683 533.997C362.322 534.086 361.968 534.245 361.62 534.475C361.269 534.707 360.982 534.972 360.76 535.269C360.539 535.568 360.412 535.885 360.381 536.219C360.351 536.555 360.447 536.89 360.668 537.224Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M362.749 541.978L368.113 538.226L368.819 539.235L367.967 539.831L368.006 539.887C368.363 539.783 368.71 539.797 369.048 539.928C369.39 540.06 369.671 540.283 369.891 540.597C369.936 540.662 369.988 540.741 370.045 540.832C370.104 540.926 370.148 541.001 370.177 541.058L369.178 541.756C369.16 541.706 369.121 541.623 369.06 541.506C369.001 541.387 368.935 541.274 368.86 541.167C368.687 540.921 368.481 540.737 368.241 540.617C368.005 540.498 367.756 540.447 367.494 540.464C367.234 540.479 366.988 540.568 366.755 540.731L363.48 543.022L362.749 541.978Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M365.44 545.783L370.747 541.951L371.493 542.984L366.186 546.816L365.44 545.783ZM371.942 541.881C371.812 541.702 371.761 541.504 371.788 541.289C371.818 541.074 371.918 540.906 372.086 540.784C372.256 540.661 372.447 540.62 372.658 540.66C372.873 540.701 373.046 540.811 373.176 540.991C373.305 541.17 373.355 541.368 373.324 541.582C373.298 541.798 373.199 541.967 373.029 542.09C372.861 542.212 372.669 542.252 372.454 542.211C372.242 542.171 372.072 542.061 371.942 541.881Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M374.693 547.287L373.875 547.902L371.728 545.043L372.546 544.428L374.693 547.287ZM374.376 544.253L375.141 545.272L370.19 548.99C369.992 549.139 369.866 549.28 369.81 549.414C369.757 549.547 369.747 549.673 369.78 549.793C369.818 549.914 369.878 550.031 369.962 550.142C370.023 550.224 370.083 550.291 370.14 550.344C370.198 550.397 370.243 550.438 370.275 550.466L369.572 551.283C369.504 551.241 369.419 551.174 369.314 551.082C369.207 550.991 369.091 550.865 368.966 550.703C368.76 550.439 368.62 550.153 368.546 549.846C368.472 549.539 368.486 549.236 368.587 548.936C368.689 548.636 368.898 548.367 369.213 548.13L374.376 544.253Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M374.577 551.481L371.531 553.895L370.74 552.896L377.579 547.475L378.36 548.46L375.815 550.477L375.866 550.541C376.237 550.442 376.602 550.452 376.96 550.57C377.319 550.689 377.656 550.947 377.972 551.346C378.25 551.697 378.422 552.062 378.485 552.439C378.55 552.819 378.497 553.195 378.324 553.567C378.156 553.94 377.858 554.296 377.43 554.635L374.168 557.221L373.376 556.222L376.519 553.732C376.895 553.433 377.11 553.105 377.163 552.748C377.219 552.388 377.11 552.036 376.837 551.691C376.65 551.455 376.432 551.283 376.184 551.175C375.937 551.07 375.674 551.04 375.396 551.087C375.122 551.134 374.849 551.265 374.577 551.481Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M375.568 558.953L380.543 554.699L381.338 555.629L380.528 556.321L380.581 556.383C380.944 556.252 381.302 556.239 381.657 556.343C382.014 556.445 382.332 556.661 382.613 556.989C382.897 557.321 383.058 557.666 383.094 558.024C383.133 558.383 383.062 558.734 382.882 559.074L382.927 559.126C383.294 559.018 383.667 559.026 384.046 559.15C384.427 559.272 384.771 559.513 385.078 559.871C385.464 560.323 385.637 560.812 385.598 561.338C385.561 561.867 385.257 562.376 384.687 562.863L381.351 565.716L380.523 564.747L383.768 561.972C384.105 561.684 384.271 561.384 384.265 561.071C384.259 560.758 384.147 560.475 383.929 560.22C383.66 559.905 383.353 559.743 383.009 559.734C382.667 559.723 382.345 559.847 382.043 560.106L378.872 562.817L378.047 561.852L381.354 559.024C381.624 558.793 381.769 558.524 381.789 558.215C381.809 557.906 381.704 557.617 381.473 557.347C381.316 557.163 381.122 557.036 380.892 556.963C380.666 556.891 380.427 556.88 380.175 556.931C379.925 556.984 379.688 557.107 379.464 557.299L376.397 559.922L375.568 558.953Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('optimize')}
          d="M389.95 567.736L389.017 567.025C389.093 566.89 389.145 566.738 389.172 566.57C389.2 566.404 389.187 566.228 389.13 566.041C389.073 565.853 388.955 565.663 388.776 565.468C388.532 565.203 388.268 565.036 387.986 564.968C387.705 564.899 387.472 564.949 387.286 565.121C387.125 565.269 387.05 565.448 387.062 565.657C387.073 565.867 387.17 566.133 387.352 566.456L387.881 567.383C388.186 567.92 388.333 568.395 388.32 568.809C388.307 569.223 388.13 569.586 387.79 569.9C387.501 570.166 387.167 570.319 386.788 570.36C386.412 570.401 386.024 570.332 385.623 570.152C385.224 569.975 384.846 569.692 384.488 569.304C383.991 568.765 383.701 568.219 383.617 567.667C383.535 567.112 383.672 566.6 384.027 566.13L384.999 566.909C384.815 567.183 384.743 567.463 384.784 567.751C384.826 568.036 384.977 568.319 385.237 568.602C385.52 568.909 385.81 569.095 386.107 569.162C386.406 569.226 386.648 569.173 386.834 569.002C386.985 568.863 387.059 568.69 387.058 568.483C387.058 568.278 386.978 568.036 386.819 567.759L386.247 566.779C385.935 566.236 385.792 565.753 385.817 565.332C385.845 564.913 386.03 564.546 386.372 564.23C386.656 563.968 386.978 563.818 387.337 563.781C387.697 563.743 388.065 563.81 388.442 563.98C388.821 564.148 389.177 564.413 389.51 564.775C389.99 565.295 390.254 565.808 390.304 566.315C390.356 566.82 390.238 567.293 389.95 567.736Z"
          fill="#758790"
        />
        {/* Deploy Alogorithms */}
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M520.121 164.634L517.352 165.194L515.622 156.64L518.479 156.063C519.317 155.893 520.072 155.919 520.742 156.14C521.413 156.358 521.972 156.753 522.422 157.326C522.873 157.896 523.188 158.624 523.368 159.513C523.548 160.404 523.54 161.202 523.344 161.909C523.15 162.615 522.782 163.202 522.24 163.671C521.696 164.138 520.99 164.459 520.121 164.634ZM518.415 163.806L519.822 163.521C520.474 163.389 520.991 163.157 521.373 162.825C521.754 162.49 522.005 162.067 522.125 161.555C522.244 161.041 522.235 160.446 522.098 159.77C521.963 159.098 521.74 158.551 521.431 158.126C521.125 157.701 520.737 157.41 520.268 157.253C519.798 157.096 519.252 157.08 518.628 157.206L517.141 157.507L518.415 163.806Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M529.186 163.094C528.55 163.2 527.979 163.155 527.475 162.96C526.972 162.761 526.555 162.435 526.223 161.98C525.893 161.522 525.673 160.959 525.562 160.289C525.452 159.627 525.478 159.024 525.641 158.479C525.806 157.933 526.088 157.481 526.487 157.124C526.888 156.766 527.388 156.538 527.988 156.438C528.352 156.377 528.716 156.379 529.078 156.442C529.439 156.506 529.778 156.644 530.093 156.857C530.407 157.069 530.677 157.369 530.903 157.755C531.128 158.138 531.289 158.622 531.386 159.208L531.461 159.653L526.328 160.507L526.171 159.565L530.072 158.916C530.017 158.586 529.901 158.304 529.724 158.071C529.547 157.836 529.324 157.664 529.055 157.556C528.79 157.448 528.494 157.421 528.169 157.475C527.816 157.534 527.522 157.672 527.288 157.889C527.056 158.104 526.891 158.361 526.794 158.663C526.7 158.96 526.68 159.271 526.733 159.593L526.855 160.328C526.927 160.76 527.064 161.115 527.266 161.392C527.47 161.669 527.723 161.865 528.024 161.979C528.325 162.09 528.659 162.115 529.026 162.054C529.264 162.014 529.476 161.945 529.661 161.845C529.845 161.742 529.998 161.61 530.118 161.449C530.238 161.288 530.319 161.1 530.361 160.886L531.586 160.902C531.549 161.269 531.429 161.604 531.226 161.908C531.026 162.209 530.753 162.464 530.407 162.671C530.064 162.875 529.657 163.016 529.186 163.094Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M533.963 164.683L532.76 155.763L533.994 155.597L534.135 156.649L534.241 156.634C534.296 156.489 534.38 156.319 534.494 156.123C534.608 155.927 534.779 155.746 535.006 155.581C535.233 155.413 535.548 155.302 535.95 155.247C536.474 155.177 536.959 155.246 537.406 155.455C537.853 155.665 538.226 156.003 538.527 156.469C538.831 156.936 539.03 157.52 539.124 158.221C539.219 158.922 539.183 159.539 539.017 160.071C538.85 160.601 538.581 161.029 538.209 161.354C537.837 161.676 537.389 161.873 536.866 161.944C536.472 161.997 536.14 161.974 535.872 161.875C535.606 161.777 535.391 161.648 535.226 161.489C535.062 161.331 534.932 161.188 534.837 161.06L534.761 161.071L535.225 164.512L533.963 164.683ZM534.435 158.84C534.496 159.296 534.617 159.687 534.795 160.013C534.974 160.338 535.201 160.581 535.478 160.742C535.755 160.899 536.074 160.954 536.438 160.905C536.815 160.854 537.117 160.711 537.343 160.477C537.569 160.241 537.724 159.94 537.807 159.576C537.892 159.212 537.906 158.814 537.847 158.38C537.79 157.952 537.673 157.576 537.498 157.253C537.325 156.93 537.097 156.687 536.815 156.524C536.535 156.361 536.205 156.305 535.824 156.356C535.458 156.406 535.162 156.542 534.934 156.765C534.708 156.987 534.554 157.276 534.47 157.631C534.386 157.987 534.374 158.389 534.435 158.84Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M541.665 152.446L542.631 161.12L541.365 161.261L540.399 152.587L541.665 152.446Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M547.533 160.772C546.922 160.826 546.376 160.732 545.896 160.492C545.416 160.252 545.028 159.892 544.731 159.41C544.435 158.928 544.257 158.351 544.198 157.677C544.139 157.001 544.212 156.398 544.42 155.87C544.627 155.341 544.946 154.916 545.377 154.596C545.808 154.276 546.329 154.089 546.94 154.035C547.552 153.981 548.097 154.074 548.578 154.314C549.058 154.554 549.446 154.917 549.743 155.401C550.039 155.885 550.217 156.466 550.277 157.142C550.336 157.816 550.262 158.415 550.055 158.941C549.847 159.467 549.528 159.89 549.097 160.211C548.666 160.531 548.145 160.718 547.533 160.772ZM547.444 159.706C547.84 159.671 548.159 159.537 548.401 159.305C548.643 159.073 548.811 158.777 548.905 158.418C549.001 158.059 549.031 157.67 548.994 157.251C548.958 156.835 548.861 156.458 548.703 156.121C548.547 155.781 548.33 155.517 548.051 155.328C547.772 155.138 547.434 155.061 547.038 155.096C546.639 155.131 546.317 155.266 546.073 155.502C545.831 155.737 545.662 156.036 545.566 156.398C545.472 156.757 545.443 157.144 545.48 157.56C545.517 157.979 545.613 158.357 545.768 158.694C545.926 159.031 546.144 159.293 546.423 159.479C546.705 159.665 547.045 159.741 547.444 159.706Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M553.299 162.656C553.109 162.667 552.935 162.662 552.777 162.639C552.62 162.62 552.501 162.595 552.423 162.566L552.67 161.506C552.906 161.555 553.114 161.57 553.295 161.551C553.476 161.532 553.633 161.455 553.765 161.319C553.899 161.184 554.012 160.968 554.103 160.673L554.236 160.23L551.466 153.747L552.828 153.669L554.773 158.645L554.841 158.641L556.206 153.475L557.571 153.397L555.302 160.958C555.196 161.306 555.054 161.603 554.875 161.849C554.695 162.099 554.476 162.29 554.216 162.425C553.956 162.559 553.651 162.636 553.299 162.656Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M563.356 159.764L561.958 159.78L565.001 151.018L566.522 151.001L569.761 159.692L568.363 159.708L565.815 152.594L565.747 152.595L563.356 159.764ZM563.552 156.344L568.086 156.293L568.098 157.401L563.565 157.452L563.552 156.344Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M572.171 151.02L572.069 159.747L570.795 159.732L570.897 151.005L572.171 151.02Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M576.693 162.455C576.174 162.437 575.729 162.353 575.359 162.204C574.992 162.055 574.694 161.864 574.466 161.632C574.238 161.399 574.071 161.148 573.963 160.877L575.074 160.463C575.146 160.591 575.244 160.726 575.367 160.87C575.492 161.016 575.664 161.143 575.882 161.25C576.103 161.357 576.39 161.417 576.742 161.429C577.224 161.446 577.627 161.341 577.95 161.117C578.274 160.895 578.444 160.528 578.462 160.017L578.506 158.731L578.425 158.728C578.344 158.865 578.228 159.016 578.077 159.181C577.929 159.346 577.728 159.487 577.474 159.604C577.22 159.72 576.893 159.771 576.492 159.757C575.975 159.74 575.514 159.603 575.108 159.347C574.705 159.089 574.39 158.718 574.165 158.236C573.943 157.751 573.844 157.16 573.868 156.465C573.892 155.769 574.031 155.177 574.283 154.688C574.539 154.2 574.879 153.83 575.302 153.581C575.726 153.328 576.201 153.211 576.726 153.229C577.132 153.243 577.457 153.322 577.703 153.467C577.948 153.609 578.137 153.772 578.27 153.956C578.406 154.14 578.51 154.301 578.582 154.44L578.675 154.443L578.712 153.383L579.96 153.426L579.729 160.112C579.71 160.674 579.563 161.131 579.29 161.483C579.016 161.834 578.653 162.089 578.201 162.247C577.753 162.405 577.25 162.474 576.693 162.455ZM576.81 158.711C577.176 158.724 577.488 158.649 577.747 158.487C578.008 158.323 578.21 158.083 578.352 157.766C578.496 157.447 578.576 157.062 578.592 156.611C578.607 156.17 578.555 155.781 578.436 155.441C578.317 155.102 578.134 154.834 577.888 154.638C577.642 154.439 577.333 154.333 576.961 154.32C576.578 154.307 576.255 154.395 575.993 154.585C575.73 154.772 575.528 155.032 575.386 155.366C575.246 155.699 575.169 156.075 575.155 156.492C575.14 156.921 575.193 157.299 575.312 157.627C575.432 157.956 575.616 158.215 575.865 158.405C576.117 158.596 576.432 158.698 576.81 158.711Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M584.948 160.421C584.336 160.379 583.812 160.202 583.375 159.89C582.938 159.578 582.61 159.161 582.393 158.639C582.175 158.117 582.089 157.519 582.136 156.844C582.182 156.167 582.349 155.583 582.636 155.093C582.923 154.603 583.305 154.234 583.78 153.984C584.256 153.735 584.8 153.632 585.412 153.674C586.024 153.716 586.549 153.893 586.986 154.205C587.423 154.517 587.75 154.935 587.967 155.46C588.185 155.984 588.27 156.585 588.224 157.263C588.177 157.937 588.011 158.518 587.724 159.005C587.437 159.492 587.056 159.861 586.58 160.11C586.105 160.359 585.561 160.463 584.948 160.421ZM585.026 159.354C585.423 159.381 585.759 159.299 586.034 159.107C586.309 158.915 586.521 158.649 586.67 158.309C586.821 157.969 586.911 157.59 586.94 157.17C586.969 156.754 586.931 156.367 586.828 156.009C586.727 155.649 586.554 155.354 586.308 155.123C586.061 154.893 585.74 154.764 585.343 154.737C584.944 154.709 584.605 154.793 584.326 154.987C584.051 155.182 583.838 155.451 583.686 155.793C583.537 156.133 583.449 156.512 583.42 156.928C583.391 157.348 583.427 157.736 583.528 158.093C583.631 158.451 583.806 158.743 584.053 158.971C584.302 159.199 584.626 159.326 585.026 159.354Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M589.84 160.675L590.461 154.159L591.687 154.276L591.588 155.311L591.656 155.317C591.808 154.978 592.044 154.722 592.363 154.55C592.685 154.375 593.037 154.306 593.419 154.342C593.498 154.35 593.591 154.362 593.698 154.378C593.808 154.394 593.893 154.409 593.955 154.423L593.839 155.637C593.789 155.618 593.7 155.594 593.572 155.564C593.444 155.532 593.314 155.51 593.184 155.497C592.885 155.469 592.611 155.507 592.364 155.612C592.121 155.714 591.92 155.87 591.763 156.081C591.606 156.289 591.515 156.534 591.488 156.817L591.108 160.796L589.84 160.675Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M594.339 161.126L595.058 154.62L596.325 154.76L595.605 161.266L594.339 161.126ZM595.809 153.687C595.589 153.662 595.408 153.568 595.266 153.404C595.127 153.237 595.069 153.05 595.092 152.844C595.115 152.635 595.212 152.466 595.384 152.336C595.558 152.204 595.756 152.15 595.976 152.175C596.196 152.199 596.376 152.295 596.515 152.461C596.657 152.626 596.716 152.812 596.693 153.021C596.67 153.227 596.571 153.397 596.397 153.529C596.225 153.658 596.029 153.711 595.809 153.687Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M601.758 155.437L601.626 156.451L598.081 155.991L598.212 154.977L601.758 155.437ZM599.365 153.545L600.628 153.709L599.832 159.85C599.8 160.095 599.813 160.284 599.87 160.417C599.928 160.548 600.011 160.643 600.121 160.703C600.234 160.761 600.359 160.799 600.497 160.817C600.599 160.83 600.688 160.834 600.766 160.83C600.844 160.826 600.905 160.822 600.948 160.819L601.041 161.893C600.964 161.911 600.856 161.926 600.717 161.937C600.578 161.95 600.407 161.945 600.204 161.922C599.87 161.884 599.568 161.785 599.297 161.624C599.026 161.462 598.818 161.241 598.674 160.958C598.53 160.676 598.484 160.339 598.534 159.948L599.365 153.545Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M604.645 158.543L604.044 162.382L602.785 162.185L604.134 153.563L605.376 153.757L604.874 156.965L604.954 156.978C605.16 156.654 605.435 156.413 605.779 156.257C606.122 156.101 606.545 156.062 607.048 156.141C607.491 156.21 607.864 156.362 608.167 156.596C608.472 156.831 608.688 157.144 608.814 157.534C608.943 157.923 608.965 158.386 608.881 158.925L608.237 163.038L606.978 162.841L607.598 158.88C607.673 158.405 607.608 158.019 607.405 157.719C607.202 157.417 606.883 157.232 606.448 157.164C606.15 157.118 605.874 157.139 605.618 157.228C605.365 157.318 605.153 157.475 604.98 157.698C604.81 157.919 604.698 158.2 604.645 158.543Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M610.104 163.341L611.357 156.917L612.558 157.151L612.354 158.196L612.433 158.212C612.636 157.884 612.909 157.651 613.251 157.512C613.594 157.37 613.978 157.341 614.402 157.424C614.831 157.507 615.171 157.678 615.422 157.935C615.676 158.193 615.838 158.511 615.909 158.89L615.976 158.903C616.196 158.59 616.493 158.364 616.868 158.226C617.243 158.085 617.661 158.059 618.124 158.15C618.707 158.263 619.147 158.539 619.444 158.976C619.743 159.413 619.821 160 619.678 160.736L618.837 165.044L617.587 164.801L618.404 160.61C618.489 160.175 618.432 159.836 618.233 159.595C618.034 159.354 617.77 159.201 617.441 159.137C617.034 159.057 616.693 159.121 616.418 159.328C616.144 159.532 615.969 159.829 615.893 160.22L615.094 164.314L613.848 164.071L614.681 159.801C614.749 159.452 614.694 159.151 614.518 158.897C614.342 158.642 614.08 158.481 613.731 158.413C613.494 158.367 613.263 158.387 613.038 158.473C612.816 158.557 612.622 158.697 612.457 158.894C612.294 159.091 612.184 159.334 612.127 159.624L611.355 163.585L610.104 163.341Z"
          fill="#758790"
        />
        <motion.path
          {...generateSectionLabelConfig('identify')}
          d="M626.773 161.832L625.602 161.768C625.588 161.613 625.546 161.459 625.474 161.305C625.405 161.152 625.294 161.013 625.142 160.89C624.99 160.767 624.785 160.676 624.528 160.615C624.177 160.533 623.865 160.543 623.593 160.646C623.322 160.746 623.157 160.919 623.1 161.165C623.05 161.378 623.088 161.568 623.216 161.735C623.343 161.902 623.572 162.068 623.904 162.233L624.862 162.702C625.417 162.975 625.805 163.286 626.027 163.636C626.249 163.985 626.307 164.385 626.201 164.836C626.112 165.218 625.922 165.532 625.63 165.779C625.343 166.024 624.983 166.185 624.55 166.262C624.121 166.339 623.649 166.318 623.134 166.197C622.421 166.03 621.874 165.742 621.494 165.332C621.115 164.92 620.941 164.419 620.97 163.831L622.212 163.929C622.214 164.259 622.312 164.532 622.507 164.746C622.702 164.958 622.987 165.108 623.36 165.196C623.767 165.291 624.111 165.282 624.394 165.171C624.678 165.056 624.848 164.876 624.906 164.63C624.953 164.43 624.917 164.246 624.799 164.075C624.685 163.905 624.483 163.75 624.195 163.61L623.172 163.121C622.608 162.846 622.219 162.527 622.003 162.165C621.791 161.803 621.737 161.395 621.843 160.941C621.931 160.565 622.114 160.26 622.39 160.028C622.666 159.795 623.008 159.643 623.416 159.572C623.824 159.498 624.267 159.517 624.746 159.629C625.434 159.79 625.942 160.066 626.267 160.458C626.594 160.846 626.762 161.304 626.773 161.832Z"
          fill="#758790"
        />
        <motion.path
          d="M522.95 117.732L524.851 129.212L523.119 129.498L521.218 118.018L522.95 117.732Z"
          fill="#242B2F"
        />
        <motion.path
          d="M533.977 127.902L530.238 128.359L528.826 116.809L532.683 116.337C533.815 116.199 534.815 116.311 535.684 116.674C536.552 117.034 537.255 117.617 537.793 118.426C538.334 119.23 538.678 120.231 538.824 121.431C538.971 122.634 538.878 123.695 538.543 124.613C538.213 125.531 537.663 126.274 536.893 126.841C536.122 127.405 535.15 127.758 533.977 127.902ZM531.794 126.623L533.695 126.391C534.575 126.283 535.286 126.028 535.828 125.626C536.37 125.22 536.747 124.684 536.959 124.017C537.171 123.346 537.221 122.554 537.11 121.64C536.999 120.734 536.76 119.983 536.393 119.387C536.03 118.79 535.545 118.363 534.937 118.105C534.33 117.847 533.605 117.77 532.762 117.873L530.755 118.118L531.794 126.623Z"
          fill="#242B2F"
        />
        <motion.path
          d="M544.935 126.707L544.103 115.1L551.38 114.579L551.488 116.086L545.962 116.482L546.216 120.019L551.362 119.65L551.469 121.152L546.324 121.521L546.578 125.074L552.172 124.673L552.28 126.18L544.935 126.707Z"
          fill="#242B2F"
        />
        <motion.path
          d="M567.184 114.044L567.436 125.677L565.823 125.712L559.725 117.308L559.617 117.31L559.801 125.842L558.046 125.88L557.794 114.247L559.419 114.212L565.523 122.627L565.631 122.625L565.446 114.081L567.184 114.044Z"
          fill="#242B2F"
        />
        <motion.path
          d="M573.738 115.609L573.786 114.099L582.787 114.386L582.739 115.897L579.11 115.781L578.787 125.901L577.038 125.845L577.361 115.725L573.738 115.609Z"
          fill="#242B2F"
        />
        <motion.path
          d="M590.097 114.889L589.283 126.496L587.532 126.374L588.346 114.766L590.097 114.889Z"
          fill="#242B2F"
        />
        <motion.path
          d="M594.339 126.908L595.592 115.34L602.766 116.117L602.604 117.619L597.175 117.031L596.793 120.556L601.708 121.089L601.545 122.586L596.631 122.053L596.084 127.097L594.339 126.908Z"
          fill="#242B2F"
        />
        <motion.path
          d="M609.14 116.94L611.108 117.259L613.262 122.967L613.386 122.987L617.233 118.252L619.202 118.571L613.903 124.873L613.172 129.382L611.444 129.102L612.176 124.592L609.14 116.94Z"
          fill="#242B2F"
        />
      </motion.svg>
    </Root>
  );
};
