import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { H2, H3, F3, PrimaryButton, ModalContainer, ModalRoot } from '..';
import { colors } from '../../styles';
import { CSVToArray } from '../../helpers/csvHelper';
import { styled } from '@mui/system';
import closeIcon from '../../assets/icons/close.svg';
import { formatFirstName, formatLastName } from '../../helpers/dataFormatter';
import { ConstructionOutlined } from '@mui/icons-material';
import close_icon from '../../assets/icons/close.svg';

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  margin: '15px 10px 0px 10px',
  padding: '10px 12px',
  '&:hover': {
    opacity: 0.7,
  },
});

const OuterBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#EFF6F5',
  borderRadius: '4px',
  padding: '10px 10px',
});

const FileUploadBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '640px',
  height: '150px',
  backgroundColor: '#EFF6F5',
  border: `1px dashed #758790`,
  borderRadius: '4px',
  padding: '4px',
  cursor: 'pointer',
});

const FileInfoRow = styled('div')({
  display: 'flex',
  width: '640px',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: '#758790',
});

const FileInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '90px',
  alignItems: 'center',
});

const BrowseFiles = styled('label')({
  fontWeight: '600',
  cursor: 'pointer',
});

const UploadButton = styled('button')({
  display: 'flex',
  padding: '10px 50px',
  width: 145,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: '#242B2F',
  border: `1px solid #242B2F`,
  color: 'white',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  backgroundPosition: 'left',
  '&:hover': {
    opacity: 0.7,
    backgroundPosition: 'right',
  },
  '&:disabled': {
    opacity: '0.5',
    background: '#242B2F',
    border: `1px solid #242B2F`,
  },
  '&:focus': {
    background: 'linear-gradient(to left, #B1CEE8 50%, #242B2F 50%)',
    backgroundSize: '200%',
    transition: '1s ease-out',
  },
  margin: '0px 0px 0px 16px',
});

type Props = {
  modalStatus: boolean;
  closeModal: () => void;
  createTemplateFromHtml: (data: any) => void;
};

export const UploadHtmlModal = ({ modalStatus, closeModal, createTemplateFromHtml }: Props) => {
  const [error, setError] = useState<null | string>(null);
  const [emphasis, setEmphasis] = useState({});
  const [buttonText, setButtonText] = useState('Upload');
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  useEffect(() => {
    setButtonText('Upload');
  }, [uploadedFile]);

  const allowDrop = (event: any) => {
    setEmphasis(!emphasis);
    event.preventDefault();
  };

  const drop = (event: any) => {
    setError(null);
    event.preventDefault();
    const data = event.dataTransfer.files[0];
    if (data.type === 'text/html') {
      setUploadedFile(data);
    } else {
      setError('Please only upload files in the .csv format');
      setUploadedFile(null);
    }
    setEmphasis(false);
  };

  const extractHtml = () => {
    const reader = new FileReader();
    reader.onload = function (e: any) {
      const html = e.target.result;
      const div = document.createElement('div');
      div.innerHTML = html;
      const text = div.textContent || div.innerText || '';
      const data = {
        type: 'html',
        content: text,
      };
      createTemplateFromHtml(html);
      closeModal();
    };
    reader.readAsText(uploadedFile);
  };

  return (
    <ModalRoot open={modalStatus} onClose={closeModal}>
      <ModalContainer style={{ minHeight: '440px', width: '700px', justifyContent: 'flex-start' }}>
        <div style={{ width: '660px', height: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <IconButton sx={{ margin: '4px 0px 0px', height: '16px', width: '16px' }}>
            <img src={close_icon} onClick={closeModal} alt={'close'} />
          </IconButton>
        </div>
        <div
          style={{
            margin: '30px 0px 0px',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <H2 sx={{ color: colors.grey_03, margin: '30px 0px' }}>Upload an HTML File</H2>
          <OuterBox>
            <FileUploadBox
              onDrop={drop}
              onDragOver={allowDrop}
              style={{
                border: emphasis ? `1px dashed #758790` : `2px dashed #758790`,
              }}
            >
              <F3 sx={{ color: colors.grey_03, margin: '10px 0px -4px' }}>
                Drop your file(s) here, or{' '}
                <input
                  type="file"
                  id="upload-file-button"
                  accept=".html"
                  hidden
                  onChange={(e: any) => {
                    setUploadedFile(e.target.files[0]);
                  }}
                />
                {/* @ts-ignore */}
                <BrowseFiles htmlFor={'upload-file-button'}>Browse files</BrowseFiles>
              </F3>
              <F3 sx={{ color: colors.grey_02 }}>Max file size 1MB</F3>
            </FileUploadBox>
          </OuterBox>
          {error && <F3 style={{ color: colors.red }}>{error}</F3>}
          {uploadedFile && (
            <FileInfoRow>
              <p>{uploadedFile.name}</p>
              <FileInfo>
                {/* @ts-ignore */}
                <p>{Number.parseFloat(uploadedFile?.size / 1000).toFixed(1)} KB</p>
                <IconButton
                  onClick={() => {
                    setError(null);
                    setUploadedFile(null);
                  }}
                >
                  <img src={closeIcon} alt={'close'} />
                </IconButton>
              </FileInfo>
            </FileInfoRow>
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              width: '100%',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              margin: '16px 0px',
            }}
          >
            <UploadButton
              style={{ width: '140px', margin: uploadedFile ? '-10px 0px 0px' : '0px 0px 0px' }}
              onClick={extractHtml}
              disabled={!uploadedFile}
            >
              {buttonText}
            </UploadButton>
          </div>
        </div>
      </ModalContainer>
    </ModalRoot>
  );
};
