import React from 'react';
import * as d3 from 'd3';
import * as d3dag from 'd3-dag';
import { testData } from './testData';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import { useEffect } from 'react';
import { formatPackageName, formatNumber } from '../../helpers/dataFormatter';
import { Column, PrimaryButtonShort } from '..';
import moment from 'moment';
import FirmsFabric from '../../assets/icons/Firms-Fabric.svg';
import FlowsFabric from '../../assets/icons/Flows-Fabric.svg';
import FundFabric from '../../assets/icons/Fund-Fabric.svg';
import HubSpotFabric from '../../assets/icons/HubSpot-Fabric.svg';
import MagnifiFabric from '../../assets/icons/Magnifi-Fabric.svg';
import MarketingFabric from '../../assets/icons/Marketing-Fabric.svg';
import AdvisorProFabric from '../../assets/icons/AdvisorPro-Fabric.svg';
import MorningStarFabric from '../../assets/icons/MorningStar-Fabric.svg';
import ArrowRight from '../../assets/icons/arrow-right.svg';
import SfFabric from '../../assets/icons/SF-Fabric.svg';
import { useQuery } from '@apollo/client';
import { GET_DATA_FABRIC } from '../../api/dataAPI';
import Loader from '../Loader';
import { GraphNode, Rank } from 'd3-dag';
import { BasicFilterMenu } from '../NewFilterMenu/BasicFilterMenu';
import { nodeGrid } from '../../helpers/DataFabricBuilder';
import DataNodeModal from '../DataNodeModal';

type Props = {};

const RotationContainer = styled('div')({
  transform: 'rotate(-90deg)',
  transformOrigin: 'center',
});

const SvgContainer = styled(motion.svg)({
  position: 'relative',
  left: '50%',
  top: '50%',
  zIndex: 10,
});

const ImageContainer = styled('div')({
  height: '100vh',
  width: '100%',
  border: `1px solid ${colors.grey_01}`,
  overflow: 'hidden',
  background: colors.grey_00,
});

const arrowTransform = ({ points }: { points: readonly (readonly [number, number])[] }): string => {
  const [[x1, y1], [x2, y2]] = points.slice(-2);
  let angle = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI + 90;
  angle = angle > 180 ? 270 : 180;
  return `translate(${x2}, ${y2}) rotate(${angle})`;
};

const iconMap: any = {
  firms: { href: FirmsFabric },
  transactions: { href: FlowsFabric },
  funds: { href: FundFabric },
  magnifi: { href: MagnifiFabric, height: '36px', width: '38px' },
  advisorpro: { href: AdvisorProFabric, height: '36px', width: '38px' },
  hubSpot: { href: HubSpotFabric },
  marketing: { href: MarketingFabric },
  salesforce: { href: SfFabric },
  morningstar: { href: MorningStarFabric },
};

export const DataFabric = () => {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [scale, setScale] = React.useState(0.4);
  const [processingGraph, setProcessingGraph] = React.useState<any>(false);
  const [graph, setGraph] = React.useState<null | d3dag.MutGraph<
    {
      id: string;
      parentIds: string[];
      name: string;
      category: string;
      type: string;
      source: string;
      rowCount: number;
      columns: string[];
      updatedAt: string;
    },
    undefined
  >>(null);

  type LayoutTypes = { label: 'Grid'; value: 'grid' } | { label: 'Directed Graph'; value: 'tree' };

  type GraphDataType = {
    label: string;
    value: string;
    grid: { startX: string; startY: string };
    tree: { startX: string; startY: string };
  };

  const graphData: GraphDataType[] = [
    {
      label: 'Overview',
      value: 'overview',
      grid: { startX: '-20%', startY: '-10%' },
      tree: { startX: '-20%', startY: '-10%' },
    },
    {
      label: 'Complete Lineage',
      value: 'lineage',
      grid: { startX: '-16%', startY: '-8%' },
      tree: { startX: '-12%', startY: '-10%' },
    },
  ];

  type TopologyTypes = 'detailed' | 'grouped';

  const [graphLayout, setGraphLayout] = React.useState<LayoutTypes>({ label: 'Grid', value: 'grid' });
  const [selectedGraphData, setSelectedGraphData] = React.useState<GraphDataType>(graphData?.[1]);
  const [graphTopology, setGraphTopology] = React.useState<TopologyTypes>('grouped');

  const [selectedNode, setSelectedNode] = React.useState<any>(null);

  const builder = d3dag.graphStratify();

  // const { loading, data, error } = useQuery(GET_DATA_FABRIC, {});
  const { loading, data, error } = useQuery(GET_DATA_FABRIC, {});

  // const data = testData?.data;
  // const loading = false;

  useEffect(() => {}, [data, selectedGraphData]);

  function memberRank({
    data: rankData,
  }: GraphNode<{
    id: string;
    parentIds: string[];
    name: string;
    category: string;
    type: string;
    source: string;
    rowCount: number;
    columns: string[];
    updatedAt: string;
  }>): number | undefined {
    // console.log('ranking member: ', rankData);
    let rank = undefined;
    if (rankData?.category === 'integration') {
      rank = 0;
    } else if (rankData?.category === 'base' || rankData?.name === 'Base Tables') {
      rank = 1;
    } else if (rankData?.category === 'feature' || rankData?.name === 'Feature Tables') {
      rank = 2;
    } else if (rankData?.category === 'output' || rankData?.name === 'AI Algorithms') {
      rank = 3;
    } else {
      rank = 4;
    }
    return rank;
  }

  const nodeRadius = 50;
  const nodeSize = [nodeRadius * 4, nodeRadius * 8] as const;
  // this truncates the edges so we can render arrows nicely

  const y = 18;

  const shape = d3dag.tweakShape(nodeSize, d3dag.shapeRect);
  // const gridTweak = d3dag.tweakGrid([nodeRadius, nodeRadius]);
  const flip = d3dag.tweakFlip('diagonal');
  // use this to render our edges
  const line = d3.line().curve(d3.curveBumpX);

  const getNodeIcon = (nodeData: any): { href: string; height?: string; width?: string } => {
    let newIconInfo: { href: string; height: string; width: string } = iconMap?.[nodeData?.source];
    if (!newIconInfo) {
      newIconInfo = iconMap?.[nodeData?.type];
    }
    if (!newIconInfo) {
      newIconInfo = iconMap?.['funds'];
    }
    return newIconInfo;
  };

  const showNodeDetails = (nodeData: any) => {
    console.log('node data: ', nodeData);
    setSelectedNode(nodeData);
    return 'Success';
  };

  useEffect(() => {
    // actually perform the layout and get the final size
    const renderNewGraph = () => {
      //@ts-ignore
      const newGraph = builder(data?.dataFabric?.[selectedGraphData?.value]?.[graphTopology]);

      //@ts-ignore
      setGraph(newGraph);
      let layout: any =
        // .sugiyama()
        nodeGrid()
          // .layering(d3dag.layeringTopological())
          // .coord(d3dag.coordGreedy())
          // .coord(d3dag.coordGreedy())
          // .coord(d3dag.coordTopological())
          //@ts-ignore
          .nodeSize(nodeSize)
          .gap([nodeRadius * 1, nodeRadius * 1])
          .rank(memberRank as Rank)
          .tweaks([flip]);
      if (graphLayout.value === 'grid') {
      } else {
        layout = d3dag
          .sugiyama()
          //@ts-ignore
          .nodeSize(nodeSize)
          .layering(d3dag.layeringSimplex())
          .coord(d3dag.coordGreedy())
          //@ts-ignore
          .tweaks([flip]);
      }

      //@ts-ignore
      let { width, height } = layout(newGraph);

      if (graphLayout.value === 'grid') {
        width = nodeRadius * 35 + 200;
        height = height + 100;
      } else {
        width = width + 150;
        height = height + 100;
      }

      setWidth(width + 200);
      setHeight(height + 100);

      const svg = d3
        .select('#svg')
        // pad a little for link thickness
        .style('width', width + 200)
        .style('height', height);
      const trans = svg.transition().duration(750);

      const nodeCategoryMap: any = [
        {
          label: 'Integration',
          y,
          x: 1,
          width: '120px',
          height: Array.from(newGraph.nodes())?.filter((node: any) => node?.data?.category === 'integration').length,
        },
        {
          label: 'Base',
          y,
          x: 2,
          width: '60px',
          height: Array.from(newGraph.nodes())?.filter((node: any) => node?.data?.category === 'base').length + 1,
        },
        {
          label: 'Feature',
          y,
          x: 3,
          width: '100px',
          height: Array.from(newGraph.nodes())?.filter((node: any) => node?.data?.category === 'feature').length + 1,
        },
        {
          label: 'Output',
          y,
          x: 4,
          width: '80px',
          height: Array.from(newGraph.nodes())?.filter((node: any) => node?.data?.category === 'output').length + 1,
        },
      ];

      if (graphLayout.value === 'grid') {
        svg
          .select('#labels')
          .selectAll('g')
          .data(nodeCategoryMap)
          .join((enter: any): any => {
            enter
              .append('rect')
              .attr('height', (d: any) => d?.height * nodeRadius * 5 - 50)
              .attr('width', nodeRadius * 7)
              .attr('stroke', colors.grey_02)
              .attr('stroke-width', 0.5)
              .attr('rx', 5)
              .attr('overflow', 'hidden')
              .attr('fill', colors.grey_00)
              .attr('transform', (d: any) => `translate(${d?.x * nodeRadius * 9 - 340}, ${d?.y - 5})`)
              .attr('filter', `url(#shadow)`);
            enter
              .append('rect')
              .attr('height', nodeRadius * 1)
              .attr('width', (d: any) => d?.width)
              .attr('rx', 5)
              .attr('overflow', 'hidden')
              .attr('fill', colors.grey_00)
              .attr('transform', (d: any) => `translate(${d?.x * nodeRadius * 9 - 320}, ${d?.y - 15})`)
              .attr('filter', `url(#shadow)`);
            enter
              .append('text')
              .text((d: any) => d?.label)
              .attr('font-weight', '400')
              .attr('background', colors.white)
              .attr('font-family', 'Inter')
              .attr('font-size', '16px')
              .attr('fill', colors.grey_02)
              .attr('width', '140px')
              .attr('transform', (d: any) => `translate(${d?.x * nodeRadius * 9 - 310}, ${d?.y})`);
          });
      }

      svg
        .select('#nodes')
        .selectAll('g')
        .data(newGraph.nodes())
        .join((enter): any =>
          enter
            .append('g')
            .attr('transform', ({ x, y }) => `translate(${x}, ${y})`)
            .attr('opacity', 0)
            .call((enter) => {
              enter
                .append('rect')
                .attr('height', nodeRadius * 3)
                .attr('width', nodeRadius * 6)
                .attr('stroke', colors.grey_01)
                .attr('stroke-width', 1)
                .attr('rx', 5)
                .attr('overflow', 'hidden')
                .attr('fill', colors.white)
                .attr('transform', ({ x, y }) => `translate(${-nodeRadius - 16}, ${-60})`)
                .attr('filter', `url(#shadow)`);
              enter
                .append('image')
                .attr('transform', `translate(${-48}, ${-48})`)
                .attr('width', (d) => {
                  const nodeIcon = getNodeIcon(d.data);
                  return nodeIcon?.width ?? '36px';
                })
                .attr('height', (d) => {
                  const nodeIcon = getNodeIcon(d.data);
                  return nodeIcon?.height ?? '30px';
                })
                .attr('href', (d) => {
                  const nodeIcon = getNodeIcon(d.data);
                  return nodeIcon.href;
                })
                .attr('alignment-baseline', 'center');
              enter
                .append('text')
                //@ts-ignore
                .text((d) => d.data.name)
                .attr('transform', `translate(${-48}, ${8})`)
                .attr('font-weight', '500')
                .attr('font-family', 'Inter')
                .attr('width', '100px')
                .attr('text-anchor', 'left')
                .attr('alignment-baseline', 'left')
                .attr('fill', colors.grey_03);
              enter
                .append('text')
                //@ts-ignore
                .text((d) => formatPackageName(d.data.category))
                .attr('transform', ({ x, y }) => `translate(${-48}, ${30})`)
                .attr('font-size', '12')
                .attr('font-weight', '400')
                .attr('font-family', 'Inter')
                .attr('text-anchor', 'left')
                .attr('alignment-baseline', 'middle')
                .attr('fill', colors.grey_02);
              enter
                .append('text')
                //@ts-ignore
                .text((d) => 'Row Count: ' + formatNumber(d?.data?.rowCount))
                .attr('transform', ({ x, y }) => `translate(${-48}, ${50})`)
                .attr('font-size', '12')
                .attr('font-weight', '400')
                .attr('font-family', 'Inter')
                .attr('text-anchor', 'left')
                .attr('alignment-baseline', 'middle')
                .attr('fill', colors.grey_02);
              enter
                .append('text')
                //@ts-ignore
                .text((d) => 'Last Updated: ' + moment(d.data.updatedAt).format('M/DD/YY'))
                .attr('transform', ({ x, y }) => `translate(${-48}, ${70})`)
                .attr('font-size', '12')
                .attr('font-weight', '400')
                .attr('font-family', 'Inter')
                .attr('text-anchor', 'left')
                .attr('alignment-baseline', 'middle')
                .attr('fill', colors.grey_02);
              enter
                .append('text')
                .text('View')
                .attr('transform', ({ x, y }) => `translate(${178}, ${68})`)
                .attr('font-size', '12')
                .attr('font-weight', '400')
                .attr('font-family', 'Inter')
                .attr('text-anchor', 'left')
                .attr('alignment-baseline', 'middle')
                .attr('cursor', 'pointer')
                .attr('hover', 'opacity: 0.8')
                .attr('fill', colors.grey_02)
                .on('click', (event, data) => showNodeDetails(data));
              enter
                .append('image')
                .attr('href', ArrowRight)
                .attr('height', '10px')
                .attr('width', '10px')
                .attr('cursor', 'pointer')
                .attr('transform', `translate(${210}, ${62})`);
              enter.transition(trans).attr('opacity', 1);
            }),
        );

      // link paths
      svg
        .select('#links')
        .selectAll('path')
        .data(newGraph.links())
        .join((enter) => {
          console.log('name: ', enter.data.name);
          return enter
            .append('path')
            .attr('d', ({ points }) => line(points))
            .attr('fill', 'none')
            .attr('stroke-width', 2)
            .attr('stroke', ({ source, target }) => colors.grey_02)
            .attr('opacity', 1);
        });
    };
    if (data) {
      setProcessingGraph(true);
      setTimeout(() => {
        setProcessingGraph(false);
        renderNewGraph();
      }, 500);
    }
  }, [data, selectedGraphData, graphLayout]);

  const resetPosition = () => {
    setProcessingGraph(true);
    setTimeout(() => {
      setProcessingGraph(false);
    }, 100);
  };

  return (
    <ImageContainer>
      {loading || processingGraph ? (
        <Loader color={colors.black} size={'16px'} />
      ) : (
        <>
          <Column
            style={{
              position: 'absolute',
              width: '120px',
              right: '10px',
              margin: '20px 10px',
              padding: '10px 20px',
              zIndex: '1000',
              border: `1px solid ${colors.grey_01}`,
              borderRadius: '5px',
              background: colors.white,
            }}
          >
            <PrimaryButtonShort style={{ margin: '8px 0px 0px' }} onClick={() => setScale(scale + 0.1)}>
              +
            </PrimaryButtonShort>
            <PrimaryButtonShort style={{ margin: '8px 0px' }} onClick={() => setScale(scale - 0.1)}>
              -
            </PrimaryButtonShort>
          </Column>
          <Column
            style={{
              position: 'absolute',
              width: '270px',
              left: '10px',
              margin: '20px 10px',
              padding: '10px 20px',
              zIndex: '1000',
              border: `1px solid ${colors.grey_01}`,
              borderRadius: '5px',
              background: colors.white,
            }}
          >
            <BasicFilterMenu
              filterOptions={graphData}
              label={'Selected Data'}
              selectedFilter={selectedGraphData}
              updateFilters={(value: any, data: any) => {
                console.log('update filters with value: ', value, data);
                setSelectedGraphData(data);
              }}
              id={'Selected Graph'}
            />
            <BasicFilterMenu
              filterOptions={[
                { label: 'Grid', value: 'grid' },
                { label: 'Directed Graph', value: 'tree' },
              ]}
              label={'Layout'}
              selectedFilter={graphLayout}
              updateFilters={(value: any, data: any) => {
                setGraphLayout(data);
              }}
              id={'Selected Graph'}
            />
          </Column>
          <SvgContainer
            id={'svg'}
            drag
            dragConstraints={{
              left: -width * scale,
              right: (width / 2) * scale,
              top: (-height / 4) * 3 * scale,
              bottom: (height / 6) * scale,
            }}
            animate={{
              scale: scale,
              translateX: selectedGraphData?.[graphLayout?.value]?.startX,
              translateY: selectedGraphData?.[graphLayout?.value]?.startY,
            }}
          >
            <defs id={'defs'} />
            <motion.g id={'labels'} />
            <motion.g id={'links'} />
            <motion.g id={'nodes'} />
            <motion.g id={'arrows'} />
          </SvgContainer>
        </>
      )}
      <DataNodeModal nodeData={selectedNode} setNodeData={setSelectedNode} />
    </ImageContainer>
  );
};
