import { gql } from '../../__generated__';
import graphql from 'graphql-tag';

export const GET_ARCHIVES = gql(`
    query getArchives($filters: [FilterType], $pageSize: Int, $page: Int) {
        journeys(filters: $filters, showAll: true, pageSize: $pageSize, page: $page) {
            page
            totalItems
            list {
            customName
            dateCreated
            dateUpdated
            id
            isActive
            package
            runhistorySet {
                id
                customName
                averageScore
                dateRun
                package
                serveCount
            }
          }
        }
      }
`);
