import React, { useState } from 'react';
import { styled } from '@mui/system';
import { colors, typography } from '../styles';
import SearchIcon from '@mui/icons-material/Search';
import searchIcon from '../assets/icons/search.svg';
import close from '../assets/icons/close.svg';

const Input = styled('input')({
  height: 40,
  borderRadius: 50,
  color: colors.grey_03,
  backgroundColor: '#F6F9FB',
  border: 0,
  width: '100%',
  padding: '4px 8px 0 8px',
  outline: 'none',
});

const SearchBox = styled('div')({
  //width: '80%',
  display: 'flex',
  background: '#fff',
  borderBottom: `1px solid #F6F9FB`,
  backgroundColor: '#F6F9FB',
  margin: '16px 12px 8px 12px',
  padding: '0px 8px',
  borderRadius: '5px',
});

const SearchContainer = styled('div')(`
    height: 30px;
    width:220px;
    margin: 10px 0px 10px; 
    border: 1px solid #b6c4cb;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`);

const SearchInput = styled('input')(`
    color: #758790;
    font-size: 14px;
    padding: 4px 8px;
    height: 27px;
    width:184px;
    font-weight: 400;
    cursor: pointer;
    z-index: 0;
    border-radius: 5px 0px 0px 5px;
    border: 0;
    border-right: 1px solid #b6c4cb;
    :focus {
      outline:1px solid ${colors.grey_02};
    }
`);

const CustomSearchIcon = styled('img')(`
    height: 15px;
    width: 15px;
    margin: -4px -2px 0px 6px;
    cursor: pointer;
`);

const Icon = styled('img')(`
    height: 15px;
    width: 15px;
    margin: 0px 8px 0px 0px;
    cursor: pointer;
    z-index: 1000;
`);

interface Props {
  id?: string;
  searchText: string;
  setSearchText: any;
  customWidth?: string;
  style?: any;
  debounce?: number;
  placeholder?: string;
  condensed?: boolean;
}

const SearchBar = ({
  id,
  searchText,
  setSearchText,
  debounce = 500,
  customWidth,
  style,
  placeholder,
  condensed,
}: Props) => {
  const [value, setValue] = useState(searchText);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchText(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return condensed ? (
    <SearchContainer>
      <SearchInput
        placeholder="Search"
        value={value}
        onChange={(event) => {
          event.stopPropagation();
          setValue(event.target.value);
        }}
      />
      {searchText.length > 1 && (
        <Icon
          sx={{ height: '10px', width: '10px', margin: '0px 10px 0px -28px', zIndex: 10000 }}
          onClick={() => setValue('')}
          src={close}
        />
      )}
      <Icon src={searchIcon} alt="search" />
    </SearchContainer>
  ) : (
    <SearchBox id={id} sx={{ width: customWidth ? customWidth : '300px', ...style }}>
      <SearchIcon
        sx={{
          margin: '10px 6px',
          color: colors.grey_03,
        }}
      />
      <Input
        id={`search-bar-input`}
        type="text"
        color="#797D83"
        placeholder={placeholder ? placeholder : 'Search for a name...'}
        value={value}
        onChange={(event) => {
          event.stopPropagation();
          setValue(event.target.value);
        }}
      />
    </SearchBox>
  );
};

export default SearchBar;
