import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { oktaClient } from '../../api/auth';
import Loader from '../../components/Loader';

const Root = styled('div')(`
    display: flex;
    flex-direction: row; 
    flex: 1;
`);

//TODO error handling, loader, etc
export const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const navigateHome = async () => {
      try {
        await oktaClient.storeTokensFromRedirect();
        navigate('/home');
      } catch (error) {
        console.warn('Error with okta client redirecting: ', error);
        try {
          try {
            const exists = await oktaClient.session.exists();
            if (!exists) {
              await oktaClient.token.getWithRedirect();
              // console.log('response in oktaClient getWithRedirect: ', response);
            } else {
              const response = await oktaClient.token.getWithoutPrompt();
              oktaClient.tokenManager.setTokens(response.tokens);
              // console.log('response in oktaClient getWithoutPrompt: ', response);
            }
          } catch (err) {
            await oktaClient.token.getWithRedirect();
            // console.log('response in oktaClient getWithRedirect: ', response);
          }
          navigate('/home');
        } catch (error) {
          console.log('Error with okta client getWithoutPrompt: ', error);
        }
      }
    };
    navigateHome();
  }, []);

  return (
    <Root>
      <div style={{ margin: '300px auto' }}>
        <Loader />
      </div>
    </Root>
  );
};
