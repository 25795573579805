import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { F3, Row } from '../../components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import navLogo from '../../assets/logos/nav-logo.png';
import lplNavLogo from '../../assets/logos/lpl-nav-logo.png';
import { useStoreState } from '../../store';
import EcosystemSwitcher from '../EcosystemSwitcher';

type Props = {
  menuOptions: any;
};

const Root = styled('div')(`
  position: fixed;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 17px 20px;
  background:${colors.white_00};
  z-index: 999;
  border-right: 1px solid ${colors.grey_01};
`);

const MenuContainer = styled('div')(`
    border-top: 1px solid ${colors.grey_01};
    padding: 16px 0px;
`);

const LogoContainer = styled('div')(`
    display: flex;
    margin: 0px 16px 16px 0px;
    align-items: center;
    justify-content: center;
    max-height: 28px;
    flex: 1;
`);

const MainLogo = styled('img')(`
    display: flex;
    // margin: 0px 0px 16px 32px;
    width: 180px;
    height: 28px;
    cursor: pointer;
    object-fit: contain;
    object-position: 0 0;
`);

const MenuButton = styled('button')(`
    display: flex;
    height:52px;
    width: 220px;
    background:${colors.white_00};
    border: 0px solid;
    text-decoration: none;
    align-items:center;
    line-height:22px;
    text-align:center;
    border-left: 2px solid ${colors.white_00};
    cursor: pointer;
    :link {
        text-decoration: none;
    };
    :hover {
        background:${colors.grey_00};
        border-radius:0px 5px 5px 0px;
    };
    ${typography.footer_03};
    font-weight: 400;
`);

const MenuButtonFocused = styled('button')(`
    display: flex;
    width: 220px;
    height: 52px;
    border: 0px solid;
    align-items:center;
    background:${colors.gradient_03};
    border-radius:0px 5px 5px 0px;
    border-left: 2px solid ${colors.black_01};
    color: ${colors.black_01};
    cursor: pointer;
    ${typography.footer_03};
    font-weight: 500;
`);

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

const ButtonIcon = styled('img')(`
    height: 30px;
    width: 30px;
    padding: 0px 0px 0px 0px;
`);

export const ParentNav = ({ menuOptions }: Props) => {
  const logoFile = useStoreState((state: any) => state?.user?.organization?.logoFile);
  const organization = useStoreState((state: any) => state.user.organization);

  const appType = useStoreState((state) => state.appType);

  const location = useLocation();
  const navigate = useNavigate();
  const defaultNavLogo = appType === 'LPL' ? lplNavLogo : navLogo;

  return (
    <Root>
      <LogoContainer>
        <MainLogo
          src={logoFile && appType !== 'LPL' ? logoFile : defaultNavLogo}
          style={appType === 'LPL' ? { margin: '0px 0px 0px 16px' } : { margin: '0px 0px 0px 52px' }}
          onClick={() => navigate('/home')}
        />
      </LogoContainer>
      <MenuContainer id="MenuContainer">
        {menuOptions.map((item: any, index: number) => (
          <Link
            to={item.link}
            style={{ textDecoration: 'none', margin: item.topBorder ? '8px 0 0px' : 0 }}
            key={index + 8329834}
          >
            {location.pathname.includes(item.link) ? (
              <MenuButtonFocused
                id={`menuButton-${item.link}`}
                sx={{
                  borderTop: item.topBorder ? `1px solid ${colors.grey_01}` : 0,
                  margin: item.topBorder ? '0px 0 0px' : 0,
                  paddingTop: item.topBorder ? 0 : 0,
                  height: item.topBorder ? 60 : 52,
                }}
              >
                <IconContainer>
                  <ButtonIcon src={item.icon} sx={item.iconStyle ? item.iconStyle : {}} />
                </IconContainer>
                {item.label}
              </MenuButtonFocused>
            ) : (
              <MenuButton
                id={`menuButton-${item.link}`}
                sx={{
                  borderTop: item.topBorder ? `1px solid ${colors.grey_01}` : 0,
                  margin: item.topBorder ? '0px 0 0px' : 0,
                  height: item.topBorder ? 60 : 52,
                }}
              >
                <IconContainer>
                  <ButtonIcon src={item.icon} sx={item.iconStyle ? item.iconStyle : {}} />
                </IconContainer>
                {item.label}
              </MenuButton>
            )}
          </Link>
        ))}
        {organization?.name === 'TIFIN' && <EcosystemSwitcher />}
      </MenuContainer>
    </Root>
  );
};
