import { gql } from '../../__generated__';

// export const FETCH_SPONSORSHIPS = gql(`
//   query getSponsorships($platform: String, $ticker: String) {
//     myProducts(platform: $platform, ticker: $ticker) {
//       availableSponsorships: sponsorships(filters: [{ key: "status", value: "available" }]) {
//         keyword
//         suggestedTickers
//         trend
//         recentViewCount
//         status
//       }
//       upcomingSponsorships: sponsorships(filters: [{ key: "status", value: "upcoming" }]) {
//         keyword
//         suggestedTickers
//         trend
//         recentViewCount
//         status
//         availableDate
//       }
//     }
//   }
// `);

export const GET_FUNDS = gql(`
  query GetFunds($query: String) {
    funds(pageSize: 100, query: $query, mustHaveTraits: true, excludeHiddenFunds: true) {
      list {
        id
        ticker
        isLplApprovedFund
        sponsorClean
        sponsor
        excludedTraits
        countOfValidLeadGenThemes
        name
        vehicle
        hidden
      }
    }
  }
`);
