import { Action, action } from 'easy-peasy';

interface OrganizationModel {
  name: string;
  logoFile: string;
  id: string;
}

export interface UserModel {
  info: object;
  staff: boolean;
  packages: object;
  loggedIn: boolean;
  demoAccount: boolean;
  organization: OrganizationModel;
  subscriptions: any;

  setDemoAccount: Action<UserModel, any>;
  setUser: Action<UserModel, any>;
  setInfo: Action<UserModel, any>;
  setSubscriptions: Action<UserModel, Array<string>>;
  setOrganization: Action<UserModel, any>;
}

export const user: UserModel = {
  info: {
    firstName: 'Demo',
    lastName: 'User',
    type: 'Asset Manager',
    email: 'test@distill.com',
  },
  staff: false,
  demoAccount: false,
  packages: {
    marketplaceLeadBudget: 0,
    marketplaceSubscriptionActive: true,
    prospectSubscriptionActive: true,
    sowSubscriptionActive: true,
  },
  organization: {
    name: '',
    id: '',
    logoFile: '',
  },
  subscriptions: [],
  loggedIn: false,

  setInfo: action((state, payload) => {
    state.info = { ...state.info, ...payload };
  }),

  setSubscriptions: action((state, payload) => {
    state.subscriptions = [...payload];
  }),

  setDemoAccount: action((state, payload) => {
    state.demoAccount = payload;
  }),

  setOrganization: action((state, payload) => {
    state.organization = payload;
  }),

  setUser: action((state, payload) => {
    state.organization = payload.organization;
    state.info = {
      firstName: payload.user.firstName,
      lastName: payload.user.lastName,
      type: payload.user.type,
      email: payload.user.email,
      timezone: payload.user.timezone,
      phone: payload.user.phone,
      organization: payload.organization?.name,
    };
    state.staff = payload?.user?.isStaff;
    state.loggedIn = payload.success;
  }),
};

export default user;
