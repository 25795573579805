import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import GeneralTable from '../../components/Table';
import { H2, H3, F3, Row, PrimaryButtonShort, Column, ContentTitle } from '../../components';
import navLogo from '../../assets/logos/nav-logo.png';
import downArrowGrey from '../../assets/icons/arrow-left.svg';
import { formatFullName, formatPackageName, formatTimestamp, formatNumber } from '../../helpers/dataFormatter';
import UploadJourneyModal from '../../components/UploadJourneyModal';
import ConfirmationModal from '../../components/ConfirmationModal';
import AddDataModal from '../../components/AddDataModal';
import AddUserModal from '../../components/AddUserModal';
import AddSubscriptionModal from '../../components/AddSubscriptionModal';
import Checkbox from '../../components/Checkbox';
import { useMutation, useQuery } from '@apollo/client';
import { IMPERSONATE_USER, GET_JOB_BY_ID } from '../../api/systemManagement';

type Props = {
  selectedOrg: any;
  setSelectedOrg: any;
  refreshAppData: any;
  refreshAndSelect: any;
};

const TableContainer = styled('div')(`
  font-family: 'Inter';
  width:100%;
  background: #FFFFFF;
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  margin: 20px auto;
`);

const SubscriptionBlock = styled('div')(`
  background: ${colors.black_01};
  color:${colors.white};
  border-radius: 5px;
  padding: 4px 10px;
  margin:4px 4px;
  font-size: 14px;
  padding:6px 10px;
  height: 32px;
`);

const FirmLogo = styled('img')(`
  border: 1px solid #C0C0C0;
  border-radius: 5px;
  padding: 20px; 
  width: 200px;
`);

const FormTitle = styled('p')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #242B2F;
`);

const JobRow = styled(Row)({
  margin: '8px 0px',
  padding: '0px 8px',
  borderBottom: `1px solid ${colors.grey_01}`,
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const SelectedOrganization = ({ selectedOrg, setSelectedOrg, refreshAppData, refreshAndSelect }: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  const [showAddUser, setShowAddUser] = useState<boolean>(false);
  const [showAddSub, setShowAddSub] = useState<boolean>(false);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [UserToImpersonate, setUserToImpersonate] = useState<any>(null);
  const [addDataCopy, setAddDataCopy] = useState<any>(null);
  const [jobId, setJobId] = useState<any>(null);
  const [activeJob, setActiveJob] = useState<any>(null);

  const filteredProfiles = selectedOrg.assetmanagerprofileSet.filter((row: any) => row?.user);

  const headerData = [
    {
      header: 'ID',
      key: 'id',
    },
    {
      header: 'Username',
      formatData: (row: any) => row?.user?.username,
    },
    {
      header: 'Name',
      formatData: (row: any) => formatFullName(row),
    },
    {
      header: 'Date Joined',
      key: 'name',
      formatData: (row: any) => formatTimestamp(row?.user?.dateJoined),
    },
    {
      header: ' ',
      key: 'uniqueCrdCount',
      // sortKey: 'attendedCount',
      formatData: (row: any, hover: boolean) => {
        return (
          <H3
            sx={{ minWidth: '140px' }}
            onClick={(event) => {
              event.stopPropagation();
              setUserToImpersonate(row);
            }}
          >
            {hover && row?.leads?.length !== 0 ? 'Impersonate User' : <div style={{ width: '120px' }} />}
          </H3>
        );
      },
    },
  ];

  const [impersonateUser, { loading: loadingImpersonate }] = useMutation(IMPERSONATE_USER, {
    variables: { amProfileId: UserToImpersonate?.id },
    onCompleted: (data) => {
      setUserToImpersonate(null);
      refreshAppData();
    },
  });

  const {
    loading: jobLoading,
    data: jobData,
    error: jobError,
    stopPolling,
  } = useQuery(GET_JOB_BY_ID, {
    variables: { jobId },
    pollInterval: 1000,
    skip: !jobId,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setActiveJob(data?.jobs?.fromDramatiqId);
      if (data?.jobs?.fromDramatiqId?.status === 'DONE' || data?.jobs?.fromDramatiqId?.status === 'FAILED') {
        stopPolling();
        setJobId(null);
      }
    },
  });

  const toggleAddUser = () => {
    setShowAddUser(!showAddUser);
  };

  return (
    <>
      <p
        id={'back-button'}
        onClick={() => setSelectedOrg(null)}
        style={{ margin: '-14px 0px 0px 0px', color: colors.grey_02, cursor: 'pointer' }}
      >
        <img
          src={downArrowGrey}
          alt="expand"
          style={{
            transition: '0.3s',
            height: '14px',
            padding: '2px 6px 0px',
          }}
        />
        Back
      </p>
      <Row style={{ margin: '10px 0px 0px' }}>
        <Column>
          <FirmLogo src={selectedOrg?.logoFile ? selectedOrg?.logoFile : navLogo} alt="logo" />
        </Column>
        <Column style={{ justifyContent: 'flex-start', margin: '0px 16px' }}>
          <ContentTitle style={{ margin: '10px 0px 0px', textAlign: 'left' }} id={'organization-title'}>
            {selectedOrg?.name}
          </ContentTitle>
          <F3 style={{ color: colors.grey_02 }}>{selectedOrg?.id}</F3>
        </Column>
      </Row>
      <Row style={{ margin: '10px 0px', alignItems: 'center' }}>
        {selectedOrg?.isDemoOrg && (
          <Row>
            <Checkbox checked={selectedOrg?.isDemoOrg} />
            <F3 style={{ margin: '0px 8px' }}>Demo Organization</F3>
          </Row>
        )}
      </Row>
      <H2>Subscriptions</H2>
      <Row
        style={{
          flexWrap: 'wrap',
          border: `1px solid #c0c0c0`,
          borderRadius: '5px',
          padding: '8px',
          margin: '4px 0px 16px 0px',
        }}
      >
        {selectedOrg?.organizationpackagesubscriptionSet?.map((item: any, index: number) => (
          <SubscriptionBlock key={index + 19340134}>
            {formatPackageName(item?.package)} | {formatNumber(item?.quota)}
          </SubscriptionBlock>
        ))}
        <PrimaryButtonShort
          style={{ width: '180px', margin: '4px 0px 0px', height: '32px ', fontSize: '14px' }}
          onClick={() => setShowAddSub(true)}
        >
          + Add Subscription
        </PrimaryButtonShort>
      </Row>
      <H2>Upload Bespoke Journey</H2>
      <Row
        style={{
          border: `1px solid #c0c0c0`,
          borderRadius: '5px',
          padding: '8px',
          margin: '6px 0px 16px 0px',
        }}
      >
        <Column style={{ width: '100%' }}>
          {!!activeJob && (
            <JobRow>
              <H2>New Job Created</H2>
              <F3> {activeJob?.id}</F3>
              <H3>{activeJob?.status}</H3>
            </JobRow>
          )}
          <PrimaryButtonShort
            style={{ width: '180px', margin: '6px 4px', height: '32px ', fontSize: '14px' }}
            onClick={() => setShowUploadModal(true)}
          >
            Upload Journey
          </PrimaryButtonShort>
        </Column>
      </Row>
      <TableContainer>
        <Row style={{ margin: '20px 30px -10px', justifyContent: 'space-between' }}>
          <H2>Organization Users</H2>
          <PrimaryButtonShort style={{ width: '160px' }} onClick={() => setShowAddUser(true)}>
            + Add User
          </PrimaryButtonShort>
        </Row>
        <GeneralTable
          headerData={headerData}
          search={true}
          hideShadow
          headerFunctions={[
            {
              format: () => <PrimaryButtonShort onClick={() => toggleAddUser()}>Add User</PrimaryButtonShort>,
            },
          ]}
          data={filteredProfiles}
          dataControls={{
            selections: [],
            setSelections: [],
            searchFunc: setSearchString,
            searchString,
          }}
          paginationControls={false}
          onRowPress={() => null}
          newItems={[]}
          rowsInput={15}
          rowsInputOption={[5, 15, 25]}
          loading={false}
        />
      </TableContainer>
      <ConfirmationModal
        modalStatus={!!UserToImpersonate}
        copy={{
          title: 'Impersonate User',
          header: 'Would you like to impersonate this user?',
          body: 'This will allow you to view the app as this user sees it.',
        }}
        loading={loadingImpersonate}
        closeModal={() => setUserToImpersonate(null)}
        action={impersonateUser}
      />
      <AddDataModal closeModal={() => setAddDataCopy(null)} modalCopy={addDataCopy} />
      <UploadJourneyModal
        closeModal={() => setShowUploadModal(false)}
        modalStatus={showUploadModal}
        uploadInfo={selectedOrg}
        refreshAndSelect={refreshAndSelect}
        setJobId={setJobId}
      />
      <AddSubscriptionModal
        closeModal={() => setShowAddSub(false)}
        modalStatus={showAddSub}
        orgId={selectedOrg?.id}
        refreshAndSelect={refreshAndSelect}
      />
      <AddUserModal
        closeModal={() => setShowAddUser(false)}
        modalStatus={showAddUser}
        orgId={selectedOrg?.id}
        refreshAndSelect={refreshAndSelect}
      />
    </>
  );
};
