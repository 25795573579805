import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LOAD_PROFILE, USER_LOGIN, oktaClient } from '../../api/auth';
import { colors, typography } from '../../styles';
import { formatSubscriptions } from '../../helpers/dataFormatter';
import loginLogo from '../../assets/logos/TifinAmp_Logo.png';
import { PrimaryButton, F4 } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from '../../store';
import { datadogRum } from '@datadog/browser-rum';
import lplNavLogo from '../../assets/logos/lpl-nav-logo.png';
import Loader from '../../components/Loader';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { apolloClient } from '../../api';
import { useStoreState } from '../../store';
import { useLazyQuery, useMutation } from '@apollo/client';

//images
const loginRock = 'https://distill-ui-images.s3.us-east-2.amazonaws.com/loginRock.svg';

const Root = styled('div')`
  height: 100vh;
  display: flex;
  flex: 1;
  padding: 0;
  background: ${colors.white_01};
`;

const Form = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  flex:4;
    margin: auto,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
`;

const Content = styled('div')`
  max-width: 400px;
`;

const MainLogo = styled('img')`
  height: 60px;
  margin: '0 -20px 10px 0';
`;

const MainImage = styled('img')`
  display: flex;
  flex: 1;
`;

const Header = styled('h1')`
  ${typography.header_01};
  width: 500px;
`;

const Subheader = styled('p')`
  ${typography.footer_03};
  color: ${colors.grey_02};
`;

const ActionText = styled('p')`
  ${typography.footer_03};
  cursor: pointer;
`;

export const MainTextInput = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
  },
});

const loginCopy = {
  header: 'Welcome to the TIFIN Asset Manager Platform',
  subHeader: 'Login to get started',
};

const lplLoginCopy = {
  header: 'Welcome to the LPL Asset Manager Platform',
  subHeader: 'Login to get started',
};

export const Login = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState<any>(null);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [oktaLoading, setOktaLoading] = useState(false);
  const navigate = useNavigate();
  const appType = useStoreState((state) => state?.appType);

  const { setUser, setSubscriptions } = useStoreActions((actions) => actions.user);
  const setAppType = useStoreActions((actions) => actions.setAppType);

  const [login, { loading: loginLoading }] = useMutation(USER_LOGIN, {
    fetchPolicy: 'network-only',
    onCompleted: async (response) => {
      window.localStorage.setItem('refreshToken', response?.tokenAuth?.refreshToken ?? '');
      window.localStorage.setItem('token', response?.tokenAuth?.token ?? '');
      loadProfileData();
    },
    onError: (error) => {
      setErrors([error.message.replace('GraphQL error: ', error?.message)]);
    },
  });

  const [loadProfileData, { loading: profileLoading, data, error: profileError }] = useLazyQuery(LOAD_PROFILE, {
    onCompleted: async (data) => {
      let loginInfo = {
        user: { email: email, ...data?.meAssetManager, ...data?.user },
        success: true,
        organization: data?.meAssetManager?.organization,
      };
      datadogRum.setUser({ email });
      setUser(loginInfo);
      setSubscriptions(formatSubscriptions(data?.mySubscriptions ?? []));
      // Sentry.setUser({ username: email, email: email });
      navigate('/home');
      if (data?.meAssetManager?.organization?.name === 'Franklin Templeton') {
        setAppType('FT');
      }
    },
  });

  useEffect(() => {
    window.localStorage.clear();
    apolloClient.cache.reset();

    const passwordField = document.getElementById('password-field');
    passwordField?.addEventListener('keyup', function (event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        const loginButton = document.getElementById('login-button');
        loginButton?.click();
      }
    });
    return passwordField?.removeEventListener('keyup', function (event) {
      event.preventDefault();
      if (event.keyCode === 13) {
        const loginButton = document.getElementById('login-button');
        loginButton?.click();
      }
    });
  }, []);

  const loginWithOkta = async () => {
    setOktaLoading(true);
    try {
      await oktaClient.signInWithRedirect();
    } catch (error) {
      setOktaLoading(false);
    }
  };

  const attemptLogin = async () => {
    setErrors(null);
    const newErrors: any[] = [];
    if (
      (!email ||
        !String(email).match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )) &&
      email !== 'admin'
    ) {
      newErrors.push('Please enter a valid email.');
    }
    if (!password || password.length < 4) {
      newErrors.push('Please enter a password');
    }

    if (newErrors.length === 0) {
      try {
        setLoading(true);
        const formattedEmail = email.toLowerCase();
        login({ variables: { username: formattedEmail, password } });
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        console.warn('error in attemptLogin: ', e?.message);
        setErrors([e.message.replace('GraphQL error: ', e?.message)]);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <Root>
      <Form>
        <Content>
          <MainLogo
            style={appType === 'LPL' ? { height: '43px' } : {}}
            src={appType === 'LPL' ? lplNavLogo : loginLogo}
            alt="login"
          />
          <Header>{appType === 'LPL' ? lplLoginCopy?.header : loginCopy.header}</Header>
          <Subheader>{loginCopy.subHeader}</Subheader>
          <MainTextInput
            id="email-field"
            label="Email"
            size="small"
            // sx={{ margin: '12px 0', width: '100%' }}
            value={email}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setEmail(event.target.value)}
          />
          <MainTextInput
            id="password-field"
            label="Password"
            size="small"
            type={showPassword ? 'text' : 'password'}
            /*className={classes.fieldOverride}*/
            focused
            // sx={{ margin: '12px 0', width: '100%' }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            error={errors?.length > 0}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    size={'small'}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors?.[0] && <F4 sx={{ color: colors.red, margin: '-4px 0px 0px' }}>{errors?.[0]} </F4>}
          <ActionText id={'forgot-pass-button'} onClick={() => navigate('/reset-password')}>
            Forgot Password?
          </ActionText>
          {/* <div /*className={classes.newAccount} onClick={() => history.push(ROUTE_SPLASH)}>
            Create New Account
          </div> */}
          <div /*className={classes.submitContainer}*/>
            <PrimaryButton
              id={'login-button'}
              type="submit"
              /*className={classes.loginButton}*/
              onClick={attemptLogin}
              disabled={loginLoading || profileLoading}
            >
              {loginLoading || profileLoading ? (
                <Loader hideText={true} size={'10px'} color={colors.white_01} inputMargin={'0px 0px 0px'} />
              ) : (
                'Login'
              )}
            </PrimaryButton>
            <PrimaryButton
              style={{ color: colors.white_01, background: colors.okta_blue }}
              id={'login-button'}
              type="submit"
              onClick={loginWithOkta}
              disabled={oktaLoading}
            >
              {oktaLoading ? (
                <Loader hideText={true} size={'10px'} color={colors.white_01} inputMargin={'0px 0px 0px'} />
              ) : (
                <>
                  <span>Sign in with Okta</span>
                </>
              )}
            </PrimaryButton>
          </div>
        </Content>
      </Form>
      <MainImage src={loginRock} alt="DistillImg" />
    </Root>
  );
};
