import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, MainTextInput, PrimaryButton } from '..';
import { colors } from '../../styles';
import hubspotLogo from '../../assets/logos/hubspot-logo.svg';
import salesforceLogo from '../../assets/logos/salesforce-logo.svg';
import dynamicsLogo from '../../assets/logos/dynamics-logo.png';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  setModalPage?: any;
  linkDynamics: any;
};

const Dynamics = ({ linkDynamics }: Props) => {
  const [instanceURL, setInstanceURL] = useState('');

  const pageCopy = {
    header: 'Microsoft Dynamics Integration',
    subheader: (
      <span>
        Please enter the <b>Instance URL</b> for your dynamics account
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02 }}>{pageCopy.subheader}</F3>
      </TextContainer>
      <Column>
        <MainTextInput
          id="email-field"
          label="Instance URL"
          size="small"
          // sx={{ margin: '12px 0', width: '100%' }}
          value={instanceURL}
          /*className={classes.fieldOverride}*/
          focused
          onChange={(event) => setInstanceURL(event.target.value)}
        />
      </Column>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton style={{ width: '160px' }} onClick={() => linkDynamics(instanceURL)}>
          Submit
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default Dynamics;
