import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import { colors } from '../styles';
import { H2, F3, PrimaryButton } from '.';
import { useStoreState } from '../store';

const Root = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const ModalContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  width: '500px',
  backgroundColor: colors.white_01,
  borderRadius: '5px',
  padding: '16px 32px',
});

interface Props {
  modalStatus: boolean;
  closeModal: any;
  requestNote: string;
  requestType: string;
}

const ContactUsModal = ({ modalStatus, closeModal, requestNote, requestType }: Props) => {
  const demoAccount = useStoreState((state) => state.user.demoAccount);

  useEffect(() => {
    if (modalStatus) {
      sendAlertMessage();
    }
  }, [modalStatus]);

  const sendAlertMessage = async () => {
    if (!demoAccount) {
      // await systemManagement.notifyRep(requestNote, requestType);
    }
  };

  return (
    <Root
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <div>
          <H2>Thanks for your Interest!</H2>
          <F3 sx={{ color: colors.grey_02 }}>{'We have let our sales team know, and they will contact you soon.'}</F3>
        </div>
        <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
      </ModalContainer>
    </Root>
  );
};

export default ContactUsModal;
