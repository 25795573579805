import React, { useState, useEffect } from 'react';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
  formatDistillScore,
  formatNumber,
  formatThousandDollar,
  formatPercent,
  formatAdvisorType,
  formatFullName,
  formatFirmName,
} from '../../helpers/dataFormatter';
import { PrimaryButton, H2, F3, CardHeader } from '..';
import { colors, typography } from '../../styles';
import SearchBar from '../SearchBar';
import { DistillScoreLogo } from '../DistillScoreLogo';
import { sortingHelper } from '../../helpers/tableUtils';

import sortable from '../../assets/icons/sortable.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 80,
    left: 450,
    // width: '65%',
    overflowY: 'scroll',
  },
  tableContainer: {
    background: colors.white,
    boxShadow: colors.main_box_shadow,
    borderRadius: '5px',
    paddingBottom: 40,
  },
  tableStyle: {
    maxHeight: '700px',
  },
  innerContainer: {
    margin: '15px',
  },
  topDetailsContainer: {
    padding: '15px 0px 0px 96px',
  },
  searchBox: {
    width: '80%',
    display: 'flex',
    background: '#fff',
    // border: `1px solid ${theme.palette.primary.light}`,
    borderTop: 'hidden',
    borderRight: 'hidden',
    borderLeft: 'hidden',
    margin: '16px auto 8px auto',
  },
  emailLink: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    color: colors.grey_02,
    fontSize: 12,
    padding: '0px',
    margin: '0px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  dataCardContainer: {
    width: '95%',
    margin: '40px auto',
    padding: '20px 0px',
    minHeight: 400,
    background: '#fff',
    boxShadow: colors.main_box_shadow,
  },
  dataLeftContainer: {
    margin: 'auto',
  },
  dataRightContainer: {
    width: '28%',
  },
  searchContainer: {
    width: '100%',
    padding: '10px 0 0px',
  },
  toggleInnerContainer: {
    display: 'flex',
    overflowX: 'auto',
    width: '100%',
    fontSize: 14,
    minHeight: 55,
    margin: 'auto',
    // [theme.breakpoints.down('md')]: {
    //   fontSize: 13,
    // },
  },
  selectedHeaderLabel: {
    ...typography.mainFont,
    color: colors.grey_03,
    fontWeight: '600',
    fontSize: '14px',
    cursor: 'pointer',
  },
  headerLabel: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontSize: '14px',
    fontWeight: '400',
    margin: '-10px 0',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  selectedToggle: {
    color: colors.grey_03,
    cursor: 'pointer',
    margin: '0 15px',
    padding: '20px 0 8px 0',
    fontWeight: 600,
    fontSize: 14,
    textAlign: 'left',
    borderBottom: `3px solid ${colors.grey_03}`,
    '&:hover': {
      opacity: 0.7,
    },
    // [theme.breakpoints.down('md')]: {
    //   margin: '0 8px -2px 8px',
    //   minWidth: 80,
    // },
  },
  toggleStyle: {
    margin: '0 15px',
    padding: '20px 0 8px 0',
    cursor: 'pointer',
    textAlign: 'left',
    color: '#838383',
    fontSize: 14,
    '&:hover': {
      opacity: 0.7,
    },
    // [theme.breakpoints.down('md')]: {
    //   margin: '0 8px -2px 8px',
    //   minWidth: 80,
    // },
  },
  dataTopContainer: {
    padding: '0 16px',
  },
  tableRow: {
    height: '50px',
    cursor: 'pointer',
    '& .MuiTableRow-root': {
      border: `1px solid ${colors.white_01}`,
      borderRadius: '8px',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: colors.grey_01,
    },
  },
  tableHeader: {
    margin: '0px 36px',
  },
  rankHelper: {
    color: '#FF007A',
    fontSize: 10,
    margin: '-8px 0 -16px 0',
    textAlign: 'left',
    cursor: 'pointer',
  },
  resultsHeader: {
    color: colors.grey_03,
    fontSize: 18,
    fontWeight: 500,
    cursor: 'pointer',
    margin: 0,
    padding: '0px 0px 0px 20px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconStyle: {
    margin: '-4px 0px 0px 4px',
  },
  buttonStyleInverted: {
    color: '#242B2F',
    fontWeight: 400,
    display: 'flex',
    padding: '0px 20px',
    background: '#fafafa',
    height: '50px',
    margin: '0px auto',
    cursor: 'pointer',
    borderLeft: '0.5px solid #e3e5e4',
    lineHeight: '50px',
    fontSize: 14,
    border: 0,
    '&:hover': {
      //border: 0,
      background: '#404B50',
      //borderLeft: '0.5px solid #e3e5e4',
      color: 'white',
    },
    '&:disabled': {
      color: 'grey',
      background: 'white',
      borderColor: 'grey',
    },
  },
  activePlatformRow: {
    display: 'flex',
    height: '34px',
    maxWidth: '300px',
    overflowX: 'auto',
    overflowY: 'hidden',
    //marginBottom: '24px',
  },
  platformIndicator: {
    display: 'flex',
    height: '20px',
    width: '25px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    padding: '5px 2px',
    borderRadius: '5px',
    margin: '3px',
    backgroundColor: colors.white_01,
    overflow: 'hidden',
  },
  themeIndicator: {
    display: 'flex',
    //height: '25px',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    padding: '5px 14px',
    borderRadius: '5px',
    margin: '3px',
    backgroundColor: colors.grey_06,
  },
  platformText: {
    color: colors.grey_02,
    fontWeight: 400,
    fontSize: 14,
  },
  advisorName: {
    maxWidth: '160px',
    color: colors.grey_03,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  eventCountIndicator: {
    height: '25px',
    width: '25px',
    borderRadius: '5px',
    backgroundColor: colors.grey_00,
    color: colors.grey_02,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
}));

interface Props {
  data: any;
  view: any;
  headerButtons: any;
  chartTitle: any;
  tableHeaders: any;
  searchString: string;
  setSearchString: any;
  hideHeader?: boolean;
  setModalOpen?: any;
  containerStyle?: any;
  innerContainerStyle?: any;
  headerStyle?: any;
  initSortColumn?: any;
}

export const LeadsTable = ({
  data,
  view,
  headerButtons,
  chartTitle,
  tableHeaders,
  searchString,
  setSearchString,
  hideHeader,
  containerStyle,
  innerContainerStyle,
  setModalOpen,
  headerStyle,
  initSortColumn,
}: Props) => {
  const classes = useStyles();
  const [sortColumn, setSortColumn] = useState(initSortColumn ? initSortColumn : tableHeaders[0].key);
  const [filteredData, setFilteredData] = useState(data);
  const [ascending, setAscending] = useState(true);

  console.log('data, filteredData: ', data, filteredData);

  useEffect(() => {
    const newFilteredData = data.filter(
      (item: any) =>
        searchString === '' ||
        item?.firstName?.match(searchString) ||
        item?.lastName?.match(searchString) ||
        item?.email?.match(searchString) ||
        item?.firmName?.match(searchString),
    );
    setFilteredData(newFilteredData);
  }, [searchString]);

  return (
    <div className={classes.tableContainer} style={containerStyle}>
      {!hideHeader && (
        <>
          <CardHeader>
            <H2 style={{ margin: '4px 8px' }}>{chartTitle}</H2>
          </CardHeader>
          <div className={classes.dataTopContainer}>
            <div className={classes.searchContainer}>
              <SearchBar searchText={searchString} setSearchText={setSearchString} />
            </div>
          </div>
        </>
      )}
      <div className={classes.innerContainer} style={innerContainerStyle}>
        <TableContainer>
          <Table
            stickyHeader
            size="small"
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                //height: '20px',
                padding: '2px auto 2px auto',
              },
            }}
          >
            <TableHead>
              <TableRow
                className={classes.tableHeader}
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: `1px solid ${colors.grey_00}`,
                    //height: '20px',
                    padding: '2px auto 2px auto',
                    ...headerStyle,
                  },
                  padding: '0px 36px 0px 0px',
                }}
              >
                {tableHeaders.map((e: any, index: number) => {
                  if (e.header === 'AMP Score') {
                    return (
                      <TableCell
                        key={index}
                        onClick={() => {
                          if (e.key !== sortColumn) {
                            setSortColumn(e.key);
                            setAscending(true);
                          } else {
                            setAscending(!ascending);
                          }
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <DistillScoreLogo
                            packageName={chartTitle === 'Individual Leads' ? 'individualLeads' : 'advisorLeads'}
                            // style={{ margin: '-2px -14px 0px 18px' }}
                          />
                        </div>
                        {/* <p className={classes.rankHelper} onClick={()=>setModalOpen(true)} >What's this?</p> */}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        key={index}
                        onClick={() => {
                          if (e.header !== 'View Details') {
                            if (e.key !== sortColumn) {
                              setSortColumn(e.key);
                              setAscending(true);
                            } else {
                              setAscending(!ascending);
                            }
                          }
                        }}
                      >
                        <div style={{ display: 'flex' }}>
                          <h1 className={classes.headerLabel}>{e.header}</h1>
                          {e.header !== 'Enriched Data' && e.header !== ' ' && e.header !== 'View Details' && (
                            <img className={classes.iconStyle} src={sortable} alt="info" />
                          )}
                        </div>
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortingHelper(sortColumn, filteredData, ascending).map((contact: any, index: number) => {
                return (
                  <TableRow
                    key={index}
                    className={classes.tableRow}
                    sx={{ padding: '20px 0px' }}
                    onClick={() => view(contact)}
                  >
                    {tableHeaders.map((headerInfo: any, headerIndex: number) => {
                      if (headerInfo.key === 'lastName') {
                        return (
                          <TableCell key={headerIndex} className={classes.advisorName}>
                            <div style={{ display: 'table' }}>
                              <F3 className={classes.advisorName} style={{ padding: '0px', margin: '0px' }}>
                                {' '}
                                {formatFullName(contact)}
                              </F3>
                              <p className={classes.emailLink}>{formatFirmName(contact.firmName)}</p>
                            </div>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'opcodes') {
                        return (
                          <TableCell key={headerIndex}>
                            <div className={classes.activePlatformRow} style={{}}>
                              <div className={classes.platformIndicator}>
                                <div className={classes.eventCountIndicator}>
                                  {headerInfo.format(contact).length ? headerInfo.format(contact).length : 1}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'topThemes') {
                        return (
                          <TableCell key={headerIndex}>
                            <div className={classes.activePlatformRow} style={{}}>
                              <div className={classes.platformIndicator}>
                                <div className={classes.eventCountIndicator}>
                                  {headerInfo.formatData(contact).length}
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        );
                      } else if (headerInfo.formatData) {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: '#758790', padding: '0px', margin: '0px' }}>
                              {headerInfo.formatData(contact)}
                            </F3>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'leadScore') {
                        return (
                          <TableCell key={headerIndex}>
                            <H2 style={{ fontSize: 24, fontWeight: 600, margin: '0px 0px 0px 0px', color: '#404B50' }}>
                              {formatDistillScore(JSON.parse(contact['leadScore']))}
                              <span style={{ fontSize: 16 }}>%</span>
                            </H2>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'leadScoreIndividual') {
                        return (
                          <TableCell key={headerIndex}>
                            <H2 style={{ fontSize: 20, fontWeight: 600, color: '#404B50', margin: '10px 0px' }}>
                              {contact.score}
                              <span style={{ fontSize: 16 }}>%</span>
                            </H2>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'view') {
                        return (
                          <TableCell key={headerIndex}>
                            <PrimaryButton style={{ margin: '0px' }} onClick={() => view(contact)}>
                              View
                            </PrimaryButton>
                          </TableCell>
                        );
                      } else if (headerInfo.key === 'type') {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: '#758790', padding: '0px', margin: '0px' }}>
                              {formatAdvisorType(contact)}
                            </F3>
                          </TableCell>
                        );
                      } else if (headerInfo.type === 'number') {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: '#758790', padding: '0px', margin: '0px' }}>
                              {formatNumber(contact[headerInfo.key])}
                            </F3>
                          </TableCell>
                        );
                      } else if (headerInfo.type === 'percent') {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: '#758790', padding: '0px', margin: '0px' }}>
                              {formatPercent(contact[headerInfo.key])}
                            </F3>
                          </TableCell>
                        );
                      } else if (headerInfo.type === 'dollar') {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: '#758790', padding: '0px', margin: '0px' }}>
                              {formatThousandDollar(contact[headerInfo.key])}
                            </F3>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={headerIndex}>
                            <F3 style={{ color: colors.grey_03, padding: '0px', margin: '0px' }}>
                              {contact[headerInfo.key]}
                            </F3>
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default LeadsTable;
