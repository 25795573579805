import { gql } from '../__generated__';

export const GET_ADVISORS = gql(`
      query GetAdvisors(
          $page: Int
          $pageSize: Int!
          $sortKey: String
          $query: String
          $hashedEmailsToExclude: [String]
          $filters: [FilterType]
          $lplFilters: [FilterType]
          $limitToTag: String
        ) {
          myAdvisors(
            page: $page
            pageSize: $pageSize
            sortKey: $sortKey
            query: $query
            hashedEmailsToExclude: $hashedEmailsToExclude
            filters: $filters
            lplFilters: $lplFilters
            limitToTag: $limitToTag
          ) {
            totalItems
            list {
              id
              manager {
                id
              }
              advisordistilldata {
                careerTenure
                firmEtfAssetsMillions
                firmMfAssetsMillions
                firmTotalAccounts
                firmTotalAssetsInMillions
                isBd
                isRia
                aumSelfReported
              }
              lplData {
                advisorAge
                advisorClubLevel
                advisorEmail
                advisorFirstName
                advisorIndustryTenure
                advisorLastName
                advisorLplTenure
                advisorMasterChannel
                cashAssets
                etfAssets
                firmName
                repCrd
                totalAssets
                trailingTwelveMonthTotalNetNewAssets
              }
              email
              hashedEmail
              repcrd
              firstName
              lastName
              title
              age
              isRia
              isBd
              aumSelfReported
              officeZipcode
              officeState
              currentFirmTenureYears
              careerTenure
              firmName
              firmCrd
              firmTotalAccounts
              firmTotalAssetsInMillions
              firmEtfAssetsMillions
              firmMfAssetsMillions
              firmPercentAssetsMf
              firmPercentAssetsEtf
              prospectScore
            }
          }
        }
      `);

export const GET_ADVISOR_SETS = gql(`
        query getAdvisorSetData($integrationId: Int) {
          advisorSets(integrationId: $integrationId) {
            dateCreated
            dateUpdated
            dynamicRefresh
            filterConfig
            id
            lastPull
            lastPush
            distinctMemberCount
            memberCount
            migratedFromTag
            name
            propertiesMapping
            integration {
              type
              dateCreated
              dateUpdated
              id
              nickname
            }
          }
        }
      `);

export const GET_FUNDS = gql(`
query GetFundsData($query: String, $page: Int, $pageSize: Int!, $sortKey: String, $filters: [FilterType]) {
  funds(query: $query, page: $page, pageSize: $pageSize, sortKey: $sortKey, filters: $filters) {
    totalItems
    list {
      id
      ticker
      themes
      name
      vehicle
      hidden
      excludedTraits
      isLplApprovedFund
      fundpreferencesSet {
        excludedTraits
        hidden
        id
      }
    }
  }
}`);

export const GET_ADVISOR_360 = gql(`
  query a360($repCrd: String!) {
    advisor360(repCrd: $repCrd) {
      summaries
      persona
      competitorData
      holdings
      transactions
      engagements
    }
  }
`);

export const UPLOAD_FLAT_FILE = gql(`
  mutation importClientDataFile($file: Upload!, $fileDataType: String!, $fileName: String) {
  importDataFile(file:$file, fileDataType:$fileDataType, fileName: $fileName) {
    success     
    uploadResult {
      dateCreated
      fileName
      fileSize
      }
    }
  }
`);

export const GET_ADVISOR_360_DATA = gql(`
  query getAdvisor360($repCrd: String!) {
    advisor360(repCrd: $repCrd) {
      holdings
      transactions
      engagements
    }
  }
`);

export const GET_UPLOADED_FILES = gql(`
  query uploads {
    clientDataUploads{
      isConfigured
      uploadHistory(sortKey:"-date_created") {
        list {
          id
          dateCreated
          fileName
          fileSize
          fileDataType
            uploadedBy {
              firstName
              lastName
            }
          }
        }
      }
    }
`);

export const SAVE_FEEDBACK = gql(`
  mutation SaveFeedback ($runId: ID!, $leadId: ID!, $feedbackFreeForm: String, $feedbackNumStars: Int!, $feedbackItems: [String]!, $feedbackDecisionMaker: String, $feedbackEmail:String ) {
    leaveSignalFeedback(runId: $runId, leadId: $leadId, feedbackFreeForm: $feedbackFreeForm, feedbackNumStars: $feedbackNumStars, feedbackItems: $feedbackItems, feedbackDecisionMaker: $feedbackDecisionMaker, feedbackEmail: $feedbackEmail) {
      success
    }
  }
`);

//not used
export const SET_ADVISOR_PREFS = gql(`
        mutation SetAdvisorPreferences(
          $advisorId: ID!
          $careerTenure: Int
          $firmEtfAssetsMillions: Int
          $firmMfAssetsMillions: Int
          $firmTotalAccounts: Int
          $firmTotalAssetsInMillions: Int
          $isBd: Boolean
          $isRia: Boolean
        ) {
          updateAdvisorData(
            advisorId: $advisorId
            careerTenure: $careerTenure
            firmEtfAssetsMillions: $firmEtfAssetsMillions
            firmMfAssetsMillions: $firmMfAssetsMillions
            firmTotalAccounts: $firmTotalAccounts
            firmTotalAssetsInMillions: $firmTotalAssetsInMillions
            isBd: $isBd
            isRia: $isRia
          ) {
            success
          }
        }
      `);

export const UPDATE_FUND_PREFS = gql(`
        mutation UpdateFundPreferences($fundIds: [ID]!, $hidden: Boolean!, $excludedTraits: JSONString) {
          setFundPreferences(fundIds: $fundIds, hidden: $hidden, excludedTraits: $excludedTraits) {
            fundsUpdated
          }
        }
      `);

export const DELETE_ADVISORS = gql(`
          mutation DeleteAdvisors($advisorIds: [ID]!) {
            deleteAdvisors(advisorIds: $advisorIds) {
              success
            }
          }
        `);

export const GET_USERS = gql(`
          query getUsers {
            users {
              id
              lastName
              firstName
              admin
              organization {
                id
                name
              }
            }
          }
        `);

export const SET_FIRM_NAME = gql(`
        mutation setFirmName($firmName: String!) {
          settingsSetFirmName(firmName: $firmName) {
            success
          }
        }
    `);

export const SET_FIRM_LOGO = gql(`
  mutation MyMutation($data: String!) {
    settingsSetLogo(data: $data) {
      details
      success
    }
  }
`);

export const GET_DATAPACKS = gql(`
  query getDatapacks {
    lpl {
      leapDatapacks {
        id
        name
        dataType
        fileData
        datePublished
        numDatapoints
        sponsorClean
      }
    }
  }
`);

export const GET_FUND_DETAILS = gql(`
  query GetFundDetails($id: ID) {
    funds(pageSize: 1000, id: $id) {
      list {
        id
        ticker
        name
      }
    }
  }
`);

export const GET_ROI_REPORTS = gql(`
  query getRoiReports {
    roiReports {
      organization
      reportDate
      reportFileName
      reportKey
      sponsorClean
    }
  }
`);

export const DOWNLOAD_ROI_REPORT = gql(`
  mutation downloadRoiReport($reportCompany: SupportedReportCompanies!, $reportDate: Date!) {
    exportRoiReport(reportCompany: $reportCompany, reportDate: $reportDate) {
      reportLink
      success
      traceback
    }
  }
`);

export const GET_DATA_FABRIC = gql(`
   query getDataFabric {
       dataFabric {
           lineage
           overview
       }
    }
`);
