// import { graphql } from 'graphql-tag';
import { gql } from '../../__generated__';
import { apolloClient } from '..';

export const GET_OUTCOME_ARCHIVES = gql(`
  query getJourneys($packageName: String, $journeyId: Int) {
      discoverLeadJourneys: journeys(filters: [{ key: "package", value: "DISCOVER_LEADS" }], showAll: true) {
        list {
          customName
          dateCreated
          dateUpdated
          id
        }
      }
      influenceJourneys: journeys(filters: [{ key: "package", value: "INFLUENCE" }], showAll: true) {
        list {
          customName
          dateCreated
          dateUpdated
          id
        }
      }
      outcomes(package: $packageName, journeyId: $journeyId) {
        totalAdvisorsInfluenced
        totalNewLeads
        totalEvents
        conversions
      }
    }
`);
