export const custodian = [
  { label: 'Any', value: [] },
  { label: 'National Financial Services', value: [{ value: 'Fidelity/NFS/National Financial', key: 'custodian' }] },
  {
    label: 'Charles Schwab & Company',
    value: [
      { value: 'Charles Schwab & Company', key: 'custodian' },
      { value: 'Schwab', key: 'custodian' },
    ],
  },
  { label: 'Pershing', value: [{ value: 'Pershing', key: 'custodian' }] },
  { label: 'TD Ameritrade', value: [{ value: 'TD Ameritrade', key: 'custodian' }] },
  {
    label: 'ETrade',
    value: [{ value: 'ETrade', key: 'custodian' }],
  },
  { label: 'Morgan Stanley', value: [{ value: 'Morgan Stanley', key: 'custodian' }] },
  { label: 'LPL Financial', value: [{ value: 'LPL Financial', key: 'custodian' }] },
  { label: 'Wells Fargo', value: [{ value: 'Wells Fargo', key: 'custodian' }] },
  { label: 'Edward Jones', value: [{ value: 'Edward Jones', key: 'custodian' }] },
  { label: 'Edward Jones Trust Company', value: [{ value: 'Edward Jones Trust Company', key: 'custodian' }] },
  { label: 'JP Morgan', value: [{ value: 'JP Morgan', key: 'custodian' }] },
  { label: 'Raymond James', value: [{ value: 'Raymond James', key: 'custodian' }] },
  {
    label: 'American Enterprise Investment Services',
    value: [{ value: 'American Enterprise Investment Services', key: 'custodian' }],
  },
  {
    label: 'Fidelity',
    value: [{ value: 'Fidelity', key: 'custodian' }],
  },
  {
    label: 'TD Ameritrade',
    value: [{ value: 'td ameritrade', key: 'custodian' }],
  },
  {
    label: 'RBC Capital Markets',
    value: [
      { value: 'rbc capital markets corporation', key: 'custodian' },
      { value: 'rbc capital markets', key: 'custodian' },
    ],
  },
  // {
  //   label: 'Ameriprise Financial Services',
  //   value: [{ value: 'Ameriprise Financial Services', key: 'custodian' }],
  // },
  { label: 'UBS Financial Services', value: [{ value: 'ubs financial services', key: 'custodian' }] },
  {
    label: 'State Street Bank  & Trust Company',
    value: [{ value: 'State Street Bank and Trust Company', key: 'custodian' }],
  },
];

export const age = [
  { label: 'Any', value: [] },
  {
    label: '18-40',
    value: [
      { value: '18', key: 'age__gte' },
      { value: '40', key: 'age__lte' },
    ],
  },
  {
    label: '40-60',
    value: [
      { value: '40', key: 'age__gte' },
      { value: '60', key: 'age__lte' },
    ],
  },
  {
    label: '60-80',
    value: [
      { value: '60', key: 'age__gte' },
      { value: '80', key: 'age__lte' },
    ],
  },
  { label: '80+', value: [{ value: '80', key: 'age__gte' }] },
];

export const type = [
  { label: 'Any', value: [] },
  { label: 'RIA', value: [{ value: 'True', key: 'is_ria' }] },
  { label: 'BD', value: [{ value: 'True', key: 'is_bd' }] },
];

export const timespan = [
  // { label: 'Any', value: [] },
  // { label: 'Last 3 Days', value: [{ value: '72', key: 'hour_range' }] },
  { label: 'Last Week', value: [{ value: '168', key: 'hour_range' }] },
  { label: 'Last 2 Weeks', value: [{ value: '336', key: 'hour_range' }] },
  { label: 'Last Month', value: [{ value: '720', key: 'hour_range' }] },
  // { label: '3 Months', value: [{ value: '2160', key: 'hour_range' }] },
  // { label: '6 Months', value: [{ value: '4320', key: 'hour_range' }] },
];

export const overviewTimespan = [
  // { label: 'Any', value: [] },
  // { label: 'Last 3 Days', value: [{ value: '72', key: 'hour_range' }] },
  { label: 'Last 8 hours', value: [{ value: '8', key: 'hour_range' }] },
  { label: 'Last 24 hours', value: [{ value: '24', key: 'hour_range' }] },
  { label: 'Last 72 hours', value: [{ value: '72', key: 'hour_range' }] },
  // { label: '3 Months', value: [{ value: '2160', key: 'hour_range' }] },
  // { label: '6 Months', value: [{ value: '4320', key: 'hour_range' }] },
];

export const careerTenure = [
  { label: 'Any', value: [] },
  {
    label: '0-5 years',
    value: [
      { key: 'career_tenure__gte', value: 0 },
      { key: 'career_tenure__lte', value: 5 },
    ],
  },
  {
    label: '5-10 years',
    value: [
      { key: 'career_tenure__gte', value: 5 },
      { key: 'career_tenure__lte', value: 10 },
    ],
  },
  {
    label: '10-15 years',
    value: [
      { key: 'career_tenure__gte', value: 5 },
      { key: 'career_tenure__lte', value: 10 },
    ],
  },
  {
    label: '15-20 years',
    value: [
      { key: 'career_tenure__gte', value: 15 },
      { key: 'career_tenure__lte', value: 20 },
    ],
  },
  {
    label: 'Greater than 20 years',
    value: [{ key: 'career_tenure__gte', value: 20 }],
  },
];

export const lplEtfAssets = [
  { label: 'Any', value: [] },
  {
    label: '<$10M',
    value: [
      { key: 'lpl__etf_assets__gte', value: '0' },
      { key: 'lpl__etf_assets__lte', value: '10' },
    ],
  },
  {
    label: '$10M-$25M',
    value: [
      { key: 'lpl__etf_assets__gte', value: '10000000' },
      { key: 'lpl__etf_assets__lte', value: '25000000' },
    ],
  },
  {
    label: '$25M-$50M',
    value: [
      { key: 'lpl__etf_assets__gte', value: '25000000' },
      { key: 'lpl__etf_assets__lte', value: '50000000' },
    ],
  },
  {
    label: '$50M-$100M',
    value: [
      { key: 'lpl__etf_assets__gte', value: '50000000' },
      { key: 'lpl__etf_assets__lte', value: '100000000' },
    ],
  },
  {
    label: '$100M-$500M',
    value: [
      { key: 'lpl__etf_assets__gte', value: '100000000' },
      { key: 'lpl__etf_assets__lte', value: '500000000' },
    ],
  },
  { label: '>$500M', value: [{ key: 'lpl__etf_assets__gte', value: '500000000' }] },
];

export const lplCashAssets = [
  { label: 'Any', value: [] },
  {
    label: '<$10M',
    value: [
      { key: 'lpl__cash_assets__gte', value: '0' },
      { key: 'lpl__cash_assets__lte', value: '10000000' },
    ],
  },
  {
    label: '$10M-$25M',
    value: [
      { key: 'lpl__cash_assets__gte', value: '10000000' },
      { key: 'lpl__cash_assets__lte', value: '25000000' },
    ],
  },
  {
    label: '$25M-$50M',
    value: [
      { key: 'lpl__cash_assets__gte', value: '25000000' },
      { key: 'lpl__cash_assets__lte', value: '50000000' },
    ],
  },
  {
    label: '$50M-$100M',
    value: [
      { key: 'lpl__cash_assets__gte', value: '50000000' },
      { key: 'lpl__cash_assets__lte', value: '100000000' },
    ],
  },
  {
    label: '$100M-$500M',
    value: [
      { key: 'lpl__cash_assets__gte', value: '100000000' },
      { key: 'lpl__cash_assets__lte', value: '500000000' },
    ],
  },
  { label: '>$500M', value: [{ key: 'lpl__cash_assets__gte', value: '500000000' }] },
];

export const lplAum = [
  { label: 'Any', value: [] },
  {
    label: '<$10M',
    value: [
      { key: 'lpl__total_assets__gte', value: '0' },
      { key: 'lpl__total_assets__lte', value: '10' },
    ],
  },
  {
    label: '$10M-$25M',
    value: [
      { key: 'lpl__total_assets__gte', value: '10000000' },
      { key: 'lpl__total_assets__lte', value: '25000000' },
    ],
  },
  {
    label: '$25M-$50M',
    value: [
      { key: 'lpl__total_assets__gte', value: '25000000' },
      { key: 'lpl__total_assets__lte', value: '50000000' },
    ],
  },
  {
    label: '$50M-$100M',
    value: [
      { key: 'lpl__total_assets__gte', value: '50000000' },
      { key: 'lpl__total_assets__lte', value: '100000000' },
    ],
  },
  {
    label: '$100M-$500M',
    value: [
      { key: 'lpl__total_assets__gte', value: '100000000' },
      { key: 'lpl__total_assets__lte', value: '500000000' },
    ],
  },
  { label: '>500M', value: [{ key: 'lpl__total_assets__gte', value: '500000000' }] },
];

// export const;

export const lplTenure = [
  { label: 'Any', value: [] },
  {
    label: '0-5 years',
    value: [
      { key: 'lpl__advisor_lpl_tenure__gte', value: 0 },
      { key: 'lpl__advisor_lpl_tenure__lte', value: 5 },
    ],
  },
  {
    label: '5-10 years',
    value: [
      { key: 'lpl__advisor_lpl_tenure__gte', value: 5 },
      { key: 'lpl__advisor_lpl_tenure__lte', value: 10 },
    ],
  },
  {
    label: '10-15 years',
    value: [
      { key: 'lpl__advisor_lpl_tenure__gte', value: 10 },
      { key: 'lpl__advisor_lpl_tenure__lte', value: 15 },
    ],
  },
  {
    label: '15-20 years',
    value: [
      { key: 'lpl__advisor_lpl_tenure__gte', value: 15 },
      { key: 'lpl__advisor_lpl_tenure__lte', value: 20 },
    ],
  },
  {
    label: 'Greater than 20 years',
    value: [{ key: 'lpl__advisor_lpl_tenure__gte', value: 20 }],
  },
];

export const lplCareerTenure = [
  { label: 'Any', value: [] },
  {
    label: '0-5 years',
    value: [
      { key: 'lpl__advisor_industry_tenure__gte', value: 0 },
      { key: 'lpl__advisor_industry_tenure__lte', value: 5 },
    ],
  },
  {
    label: '5-10 years',
    value: [
      { key: 'lpl__advisor_industry_tenure__gte', value: 5 },
      { key: 'lpl__advisor_industry_tenure__lte', value: 10 },
    ],
  },
  {
    label: '10-15 years',
    value: [
      { key: 'lpl__advisor_industry_tenure__gte', value: 10 },
      { key: 'lpl__advisor_industry_tenure__lte', value: 15 },
    ],
  },
  {
    label: '15-20 years',
    value: [
      { key: 'lpl__advisor_industry_tenure__gte', value: 15 },
      { key: 'lpl__advisor_industry_tenure__lte', value: 20 },
    ],
  },
  {
    label: 'Greater than 20 years',
    value: [{ key: 'lpl__advisor_industry_tenure__gte', value: 20 }],
  },
];

export const lplNetNewAssets = [
  { label: 'Any', value: [] },
  {
    label: '<-$2M',
    value: [{ key: 'lpl__trailing_twelve_month_total_net_new_assets__lte', value: '-2000000' }],
  },
  {
    label: '-$2M-$0',
    value: [
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__gte', value: '-2000000' },
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__lte', value: '0' },
    ],
  },
  {
    label: '$0-$2M',
    value: [
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__gte', value: '0' },
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__lte', value: '2000000' },
    ],
  },
  {
    label: '$2M-$5M',
    value: [
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__gte', value: '2000000' },
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__lte', value: '5000000' },
    ],
  },
  {
    label: '$5M-$10M',
    value: [
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__gte', value: '5000000' },
      { key: 'lpl__trailing_twelve_month_total_net_new_assets__lte', value: '10000000' },
    ],
  },
  {
    label: '>$10M',
    value: [{ key: 'lpl__trailing_twelve_month_total_net_new_assets__gte', value: '10000000' }],
  },
];

export const lplClientFlows = [
  {
    label: 'Any',
    value: [{ key: 'lpl__client', value: 'either' }],
  },
  {
    label: 'Purchased Your Funds',
    value: [{ key: 'lpl__client', value: 'inflows' }],
  },
  {
    label: 'Sold Your Funds',
    value: [{ key: 'lpl__client', value: 'outflows' }],
  },
  {
    label: 'Purchased and Sold Your Funds',
    value: [{ key: 'lpl__client', value: 'both' }],
  },
];

export const lplMorningstarCategories = [
  {
    label: 'Any',
    value: [],
  },
  { label: 'Financial', value: [{ key: 'lpl__mstar_categories', value: 'Financial' }] },
  { label: 'Japan Stock', value: [{ key: 'lpl__mstar_categories', value: 'Japan Stock' }] },
  { label: 'Digital Assets', value: [{ key: 'lpl__mstar_categories', value: 'Digital Assets' }] },
  { label: 'Target-Date 2000-2010', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2000-2010' }] },
  {
    label: 'Intermediate Core-Plus Bond',
    value: [{ key: 'lpl__mstar_categories', value: 'Intermediate Core-Plus Bond' }],
  },
  { label: 'Muni Massachusetts', value: [{ key: 'lpl__mstar_categories', value: 'Muni Massachusetts' }] },
  { label: 'Global Large-Stock Value', value: [{ key: 'lpl__mstar_categories', value: 'Global Large-Stock Value' }] },
  {
    label: 'Muni New York Intermediate',
    value: [{ key: 'lpl__mstar_categories', value: 'Muni New York Intermediate' }],
  },
  { label: 'Foreign Small/Mid Growth', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Small/Mid Growth' }] },
  { label: 'Mid-Cap Value', value: [{ key: 'lpl__mstar_categories', value: 'Mid-Cap Value' }] },
  { label: 'Latin America Stock', value: [{ key: 'lpl__mstar_categories', value: 'Latin America Stock' }] },
  { label: 'Infrastructure', value: [{ key: 'lpl__mstar_categories', value: 'Infrastructure' }] },
  {
    label: 'Energy Limited Partnership',
    value: [{ key: 'lpl__mstar_categories', value: 'Energy Limited Partnership' }],
  },
  { label: 'Technology', value: [{ key: 'lpl__mstar_categories', value: 'Technology' }] },
  { label: 'Money Market-Taxable', value: [{ key: 'lpl__mstar_categories', value: 'Money Market-Taxable' }] },
  { label: 'Target-Date 2060', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2060' }] },
  { label: 'Equity Energy', value: [{ key: 'lpl__mstar_categories', value: 'Equity Energy' }] },
  { label: 'Muni Pennsylvania', value: [{ key: 'lpl__mstar_categories', value: 'Muni Pennsylvania' }] },
  { label: 'Utilities', value: [{ key: 'lpl__mstar_categories', value: 'Utilities' }] },
  { label: 'Preferred Stock', value: [{ key: 'lpl__mstar_categories', value: 'Preferred Stock' }] },
  { label: 'Commodities Broad Basket', value: [{ key: 'lpl__mstar_categories', value: 'Commodities Broad Basket' }] },
  { label: 'Target-Date 2065+', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2065+' }] },
  { label: 'Short-Term Bond', value: [{ key: 'lpl__mstar_categories', value: 'Short-Term Bond' }] },
  { label: 'Global Bond', value: [{ key: 'lpl__mstar_categories', value: 'Global Bond' }] },
  { label: 'Global Real Estate', value: [{ key: 'lpl__mstar_categories', value: 'Global Real Estate' }] },
  { label: 'Trading--Inverse Debt', value: [{ key: 'lpl__mstar_categories', value: 'Trading--Inverse Debt' }] },
  { label: 'Muni New York Long', value: [{ key: 'lpl__mstar_categories', value: 'Muni New York Long' }] },
  { label: 'Inflation-Protected Bond', value: [{ key: 'lpl__mstar_categories', value: 'Inflation-Protected Bond' }] },
  { label: 'Tactical Allocation', value: [{ key: 'lpl__mstar_categories', value: 'Tactical Allocation' }] },
  { label: 'Equity Precious Metals', value: [{ key: 'lpl__mstar_categories', value: 'Equity Precious Metals' }] },
  { label: 'Options Trading', value: [{ key: 'lpl__mstar_categories', value: 'Options Trading' }] },
  { label: 'Ultrashort Bond', value: [{ key: 'lpl__mstar_categories', value: 'Ultrashort Bond' }] },
  {
    label: 'Emerging-Markets Local-Currency Bond',
    value: [{ key: 'lpl__mstar_categories', value: 'Emerging-Markets Local-Currency Bond' }],
  },
  { label: 'Europe Stock', value: [{ key: 'lpl__mstar_categories', value: 'Europe Stock' }] },
  { label: 'Miscellaneous Sector', value: [{ key: 'lpl__mstar_categories', value: 'Miscellaneous Sector' }] },
  { label: 'Equity Market Neutral', value: [{ key: 'lpl__mstar_categories', value: 'Equity Market Neutral' }] },
  {
    label: 'Allocation--30% to 50% Equity',
    value: [{ key: 'lpl__mstar_categories', value: 'Allocation--30% to 50% Equity' }],
  },
  { label: 'Global Large-Stock Growth', value: [{ key: 'lpl__mstar_categories', value: 'Global Large-Stock Growth' }] },
  { label: 'Target-Date Retirement', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date Retirement' }] },
  { label: 'Mid-Cap Blend', value: [{ key: 'lpl__mstar_categories', value: 'Mid-Cap Blend' }] },
  { label: 'Systematic Trend', value: [{ key: 'lpl__mstar_categories', value: 'Systematic Trend' }] },
  { label: 'Small Growth', value: [{ key: 'lpl__mstar_categories', value: 'Small Growth' }] },
  { label: 'Target-Date 2055', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2055' }] },
  { label: 'Target-Date 2050', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2050' }] },
  { label: 'Muni New Jersey', value: [{ key: 'lpl__mstar_categories', value: 'Muni New Jersey' }] },
  { label: 'Intermediate Government', value: [{ key: 'lpl__mstar_categories', value: 'Intermediate Government' }] },
  {
    label: 'Allocation--70% to 85% Equity',
    value: [{ key: 'lpl__mstar_categories', value: 'Allocation--70% to 85% Equity' }],
  },
  { label: 'Global Bond-USD Hedged', value: [{ key: 'lpl__mstar_categories', value: 'Global Bond-USD Hedged' }] },
  { label: 'Convertibles', value: [{ key: 'lpl__mstar_categories', value: 'Convertibles' }] },
  { label: 'Muni Single State Long', value: [{ key: 'lpl__mstar_categories', value: 'Muni Single State Long' }] },
  { label: 'Long Government', value: [{ key: 'lpl__mstar_categories', value: 'Long Government' }] },
  { label: 'Muni Minnesota', value: [{ key: 'lpl__mstar_categories', value: 'Muni Minnesota' }] },
  {
    label: 'Allocation--15% to 30% Equity',
    value: [{ key: 'lpl__mstar_categories', value: 'Allocation--15% to 30% Equity' }],
  },
  { label: 'Health', value: [{ key: 'lpl__mstar_categories', value: 'Health' }] },
  { label: 'Miscellaneous Region', value: [{ key: 'lpl__mstar_categories', value: 'Miscellaneous Region' }] },
  { label: 'Muni California Long', value: [{ key: 'lpl__mstar_categories', value: 'Muni California Long' }] },
  { label: 'Emerging Markets Bond', value: [{ key: 'lpl__mstar_categories', value: 'Emerging Markets Bond' }] },
  { label: 'High Yield Muni', value: [{ key: 'lpl__mstar_categories', value: 'High Yield Muni' }] },
  { label: 'Mid-Cap Growth', value: [{ key: 'lpl__mstar_categories', value: 'Mid-Cap Growth' }] },
  { label: 'Foreign Large Value', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Large Value' }] },
  { label: 'Short Government', value: [{ key: 'lpl__mstar_categories', value: 'Short Government' }] },
  { label: 'Muni Single State Short', value: [{ key: 'lpl__mstar_categories', value: 'Muni Single State Short' }] },
  { label: 'Muni Target Maturity', value: [{ key: 'lpl__mstar_categories', value: 'Muni Target Maturity' }] },
  { label: 'Industrials', value: [{ key: 'lpl__mstar_categories', value: 'Industrials' }] },
  { label: 'Diversified Emerging Mkts', value: [{ key: 'lpl__mstar_categories', value: 'Diversified Emerging Mkts' }] },
  {
    label: 'Allocation--50% to 70% Equity',
    value: [{ key: 'lpl__mstar_categories', value: 'Allocation--50% to 70% Equity' }],
  },
  { label: 'Trading--Inverse Equity', value: [{ key: 'lpl__mstar_categories', value: 'Trading--Inverse Equity' }] },
  { label: 'Real Estate', value: [{ key: 'lpl__mstar_categories', value: 'Real Estate' }] },
  { label: 'Consumer Defensive', value: [{ key: 'lpl__mstar_categories', value: 'Consumer Defensive' }] },
  { label: 'Pacific/Asia ex-Japan Stk', value: [{ key: 'lpl__mstar_categories', value: 'Pacific/Asia ex-Japan Stk' }] },
  { label: 'Target-Date 2035', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2035' }] },
  { label: 'Small Value', value: [{ key: 'lpl__mstar_categories', value: 'Small Value' }] },
  { label: 'Consumer Cyclical', value: [{ key: 'lpl__mstar_categories', value: 'Consumer Cyclical' }] },
  { label: 'Multisector Bond', value: [{ key: 'lpl__mstar_categories', value: 'Multisector Bond' }] },
  { label: 'Muni Single State Interm', value: [{ key: 'lpl__mstar_categories', value: 'Muni Single State Interm' }] },
  { label: 'Muni Ohio', value: [{ key: 'lpl__mstar_categories', value: 'Muni Ohio' }] },
  { label: 'Target-Date 2040', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2040' }] },
  { label: 'Target-Date 2025', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2025' }] },
  {
    label: 'Muni California Intermediate',
    value: [{ key: 'lpl__mstar_categories', value: 'Muni California Intermediate' }],
  },
  { label: 'Target-Date 2045', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2045' }] },
  { label: 'Long-Short Equity', value: [{ key: 'lpl__mstar_categories', value: 'Long-Short Equity' }] },
  { label: 'High Yield Bond', value: [{ key: 'lpl__mstar_categories', value: 'High Yield Bond' }] },
  { label: 'Nontraditional Bond', value: [{ key: 'lpl__mstar_categories', value: 'Nontraditional Bond' }] },
  { label: 'Global Small/Mid Stock', value: [{ key: 'lpl__mstar_categories', value: 'Global Small/Mid Stock' }] },
  { label: 'Trading--Miscellaneous', value: [{ key: 'lpl__mstar_categories', value: 'Trading--Miscellaneous' }] },
  { label: 'Target-Date 2015', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2015' }] },
  { label: 'Event Driven', value: [{ key: 'lpl__mstar_categories', value: 'Event Driven' }] },
  { label: 'Bank Loan', value: [{ key: 'lpl__mstar_categories', value: 'Bank Loan' }] },
  { label: 'Intermediate Core Bond', value: [{ key: 'lpl__mstar_categories', value: 'Intermediate Core Bond' }] },
  { label: 'Single Currency', value: [{ key: 'lpl__mstar_categories', value: 'Single Currency' }] },
  { label: 'Large Blend', value: [{ key: 'lpl__mstar_categories', value: 'Large Blend' }] },
  { label: 'Target Maturity', value: [{ key: 'lpl__mstar_categories', value: 'Target Maturity' }] },
  { label: 'Natural Resources', value: [{ key: 'lpl__mstar_categories', value: 'Natural Resources' }] },
  { label: 'Foreign Large Growth', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Large Growth' }] },
  { label: 'Target-Date 2030', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2030' }] },
  { label: 'Diversified Pacific/Asia', value: [{ key: 'lpl__mstar_categories', value: 'Diversified Pacific/Asia' }] },
  { label: 'Relative Value Arbitrage', value: [{ key: 'lpl__mstar_categories', value: 'Relative Value Arbitrage' }] },
  { label: 'Money Market-Tax-Free', value: [{ key: 'lpl__mstar_categories', value: 'Money Market-Tax-Free' }] },
  { label: 'Macro Trading', value: [{ key: 'lpl__mstar_categories', value: 'Macro Trading' }] },
  { label: 'China Region', value: [{ key: 'lpl__mstar_categories', value: 'China Region' }] },
  { label: 'Foreign Large Blend', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Large Blend' }] },
  { label: 'Small Blend', value: [{ key: 'lpl__mstar_categories', value: 'Small Blend' }] },
  { label: 'Global Large-Stock Blend', value: [{ key: 'lpl__mstar_categories', value: 'Global Large-Stock Blend' }] },
  { label: 'Large Value', value: [{ key: 'lpl__mstar_categories', value: 'Large Value' }] },
  { label: 'Prime Money Market', value: [{ key: 'lpl__mstar_categories', value: 'Prime Money Market' }] },
  { label: 'Muni National Interm', value: [{ key: 'lpl__mstar_categories', value: 'Muni National Interm' }] },
  { label: 'Multistrategy', value: [{ key: 'lpl__mstar_categories', value: 'Multistrategy' }] },
  { label: 'Muni National Long', value: [{ key: 'lpl__mstar_categories', value: 'Muni National Long' }] },
  { label: 'Trading--Leveraged Equity', value: [{ key: 'lpl__mstar_categories', value: 'Trading--Leveraged Equity' }] },
  { label: 'Allocation--85%+ Equity', value: [{ key: 'lpl__mstar_categories', value: 'Allocation--85%+ Equity' }] },
  { label: 'Communications', value: [{ key: 'lpl__mstar_categories', value: 'Communications' }] },
  { label: 'Target-Date 2020', value: [{ key: 'lpl__mstar_categories', value: 'Target-Date 2020' }] },
  { label: 'Foreign Small/Mid Value', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Small/Mid Value' }] },
  { label: 'Large Growth', value: [{ key: 'lpl__mstar_categories', value: 'Large Growth' }] },
  { label: 'Commodities Focused', value: [{ key: 'lpl__mstar_categories', value: 'Commodities Focused' }] },
  { label: 'Global Allocation', value: [{ key: 'lpl__mstar_categories', value: 'Global Allocation' }] },
  { label: 'India Equity', value: [{ key: 'lpl__mstar_categories', value: 'India Equity' }] },
  { label: 'Corporate Bond', value: [{ key: 'lpl__mstar_categories', value: 'Corporate Bond' }] },
  { label: 'Derivative Income', value: [{ key: 'lpl__mstar_categories', value: 'Derivative Income' }] },
  { label: 'Foreign Small/Mid Blend', value: [{ key: 'lpl__mstar_categories', value: 'Foreign Small/Mid Blend' }] },
  { label: 'Long-Term Bond', value: [{ key: 'lpl__mstar_categories', value: 'Long-Term Bond' }] },
  { label: 'Muni National Short', value: [{ key: 'lpl__mstar_categories', value: 'Muni National Short' }] },
];

export const income = [
  { label: 'Any', value: [] },
  {
    label: '0-$75k',
    value: [
      { key: 'income__gte', value: 0 },
      { key: 'income__lte', value: 75 },
    ],
  },
  {
    label: '75k-$150k',
    value: [
      { key: 'income__gte', value: 75 },
      { key: 'income__lte', value: 150 },
    ],
  },
  {
    label: '150k-300k',
    value: [
      { key: 'income__gte', value: 150 },
      { key: 'income__lte', value: 300 },
    ],
  },
  {
    label: '300k-500k',
    value: [
      { key: 'income__gte', value: 300 },
      { key: 'income__lte', value: 500 },
    ],
  },
  {
    label: '500k-750k',
    value: [
      { key: 'income__gte', value: 500 },
      { key: 'income__lte', value: 750 },
    ],
  },
  {
    label: '750k-1m',
    value: [
      { key: 'income__gte', value: 750 },
      { key: 'income__lte', value: 1000 },
    ],
  },
  { label: '1m+', value: [{ key: 'income__gte', value: 1000 }] },
];

export const incomeWealth = [
  { label: 'Any', value: [] },
  {
    label: '0-75k',
    value: [
      { key: 'income__gte', value: 0 },
      { key: 'income__lte', value: 75000 },
    ],
  },
  {
    label: '75k-150k',
    value: [
      { key: 'income__gte', value: 75000 },
      { key: 'income__lte', value: 150000 },
    ],
  },
  {
    label: '150k-300k',
    value: [
      { key: 'income__gte', value: 150000 },
      { key: 'income__lte', value: 300000 },
    ],
  },
  {
    label: '300k-500k',
    value: [
      { key: 'income__gte', value: 300000 },
      { key: 'income__lte', value: 500000 },
    ],
  },
  {
    label: '500k-750k',
    value: [
      { key: 'income__gte', value: 500 },
      { key: 'income__lte', value: 750 },
    ],
  },
  {
    label: '750k-1m',
    value: [
      { key: 'income__gte', value: 750000 },
      { key: 'income__lte', value: 1000000 },
    ],
  },
  { label: '1m+', value: [{ key: 'income__gte', value: 1000 }] },
];

export const wealth = [
  { label: 'Any', value: [] },
  {
    label: '0-250k',
    value: [
      { key: 'wealth__gte', value: 0 },
      { key: 'wealth__lte', value: 250 },
    ],
  },
  {
    label: '250k-500k',
    value: [
      { key: 'wealth__gte', value: 250 },
      { key: 'wealth__lte', value: 500 },
    ],
  },
  {
    label: '500k-1m',
    value: [
      { key: 'wealth__gte', value: 500 },
      { key: 'wealth__lte', value: 1000 },
    ],
  },
  {
    label: '1m-1.5m',
    value: [
      { key: 'wealth__gte', value: 1000 },
      { key: 'wealth__lte', value: 1500 },
    ],
  },
  {
    label: '1.5m-3m',
    value: [
      { key: 'wealth__gte', value: 1500 },
      { key: 'wealth__lte', value: 3000 },
    ],
  },
  {
    label: '3m-5m',
    value: [
      { key: 'wealth__gte', value: 3000 },
      { key: 'wealth__lte', value: 5000 },
    ],
  },
  { label: '5m+', value: [{ key: 'wealth__gte', value: 5000 }] },
];

export const estimatedInvestableAssets = [
  { label: 'Any', value: [] },
  {
    label: '0-250k',
    value: [
      { key: 'estimated_investable_assets__gte', value: 0 },
      { key: 'estimated_investable_assets__lte', value: 250 },
    ],
  },
  {
    label: '250k-500k',
    value: [
      { key: 'estimated_investable_assets__gte', value: 250 },
      { key: 'estimated_investable_assets__lte', value: 500 },
    ],
  },
  {
    label: '500k-1m',
    value: [
      { key: 'estimated_investable_assets__gte', value: 500 },
      { key: 'estimated_investable_assets__lte', value: 1000 },
    ],
  },
  {
    label: '1m-1.5m',
    value: [
      { key: 'estimated_investable_assets__gte', value: 1000 },
      { key: 'estimated_investable_assets__lte', value: 1500 },
    ],
  },
  {
    label: '1.5m-3m',
    value: [
      { key: 'estimated_investable_assets__gte', value: 1500 },
      { key: 'estimated_investable_assets__lte', value: 3000 },
    ],
  },
  {
    label: '3m-5m',
    value: [
      { key: 'estimated_investable_assets__gte', value: 3000 },
      { key: 'estimated_investable_assets__lte', value: 5000 },
    ],
  },
  { label: '5m+', value: [{ key: 'estimated_investable_assets__gte', value: 5000 }] },
];

export const firmAccounts = [
  { label: 'Any', value: [] },
  {
    label: '0-1K',
    value: [
      { key: 'firm_total_accounts__gte', value: '0' },
      { key: 'firm_total_accounts__lte', value: '1000' },
    ],
  },
  {
    label: '1K-10K',
    value: [
      { key: 'firm_total_accounts__gte', value: '1000' },
      { key: 'firm_total_accounts__lte', value: '10000' },
    ],
  },
  {
    label: '10K-100K',
    value: [
      { key: 'firm_total_accounts__gte', value: '10000' },
      { key: 'firm_total_accounts__lte', value: '100000' },
    ],
  },
  {
    label: '100K-1,000K',
    value: [
      { key: 'firm_total_accounts__gte', value: '100000' },
      { key: 'firm_total_accounts__lte', value: '1000000' },
    ],
  },
  { label: '>1,000K', value: [{ key: 'firm_total_accounts__gte', value: '1000000' }] },
];

export const aum = [
  { label: 'Any', value: [] },
  {
    label: '<10M',
    value: [
      { key: 'aum_self_reported__gte', value: '0' },
      { key: 'aum_self_reported__lte', value: '10000000' },
    ],
  },
  {
    label: '10M-25M',
    value: [
      { key: 'aum_self_reported__gte', value: '10000000' },
      { key: 'aum_self_reported__lte', value: '25000000' },
    ],
  },
  {
    label: '25M-50M',
    value: [
      { key: 'aum_self_reported__gte', value: '25000000' },
      { key: 'aum_self_reported__lte', value: '50000000' },
    ],
  },
  {
    label: '50M-100M',
    value: [
      { key: 'aum_self_reported__gte', value: '50000000' },
      { key: 'aum_self_reported__lte', value: '100000000' },
    ],
  },
  {
    label: '100M-500M',
    value: [
      { key: 'aum_self_reported__gte', value: '100000000' },
      { key: 'aum_self_reported__lte', value: '500000000' },
    ],
  },
  { label: '>500M', value: [{ key: 'aum_self_reported__gte', value: '500' }] },
];

export const firmAUM = [
  { label: 'Any', value: [] },
  {
    label: '<10M',
    value: [
      { key: 'firm_aum__gte', value: '0' },
      { key: 'firm_aum__lte', value: '10' },
    ],
  },
  {
    label: '10M-25M',
    value: [
      { key: 'firm_aum__gte', value: '10' },
      { key: 'firm_aum__lte', value: '25' },
    ],
  },
  {
    label: '25M-50M',
    value: [
      { key: 'firm_aum__gte', value: '25' },
      { key: 'firm_aum__lte', value: '50' },
    ],
  },
  {
    label: '50M-100M',
    value: [
      { key: 'firm_aum__gte', value: '50' },
      { key: 'firm_aum__lte', value: '100' },
    ],
  },
  {
    label: '100M-500M',
    value: [
      { key: 'firm_aum__gte', value: '100' },
      { key: 'firm_aum__lte', value: '500' },
    ],
  },
  { label: '>500M', value: [{ key: 'firm_aum__gte', value: '500' }] },
];

// export const firmAUM = [
//   { label: 'Any', value: [] },
//   {
//     label: '<10M',
//     value: [
//       { key: 'firm_total_assets_in_millions__gte', value: '0' },
//       { key: 'firm_total_assets_in_millions__lte', value: '10' },
//     ],
//   },
//   {
//     label: '10M-25M',
//     value: [
//       { key: 'firm_total_assets_in_millions__gte', value: '10' },
//       { key: 'firm_total_assets_in_millions__lte', value: '25' },
//     ],
//   },
//   {
//     label: '25M-50M',
//     value: [
//       { key: 'firm_total_assets_in_millions__gte', value: '25' },
//       { key: 'firm_total_assets_in_millions__lte', value: '50' },
//     ],
//   },
//   {
//     label: '50M-100M',
//     value: [
//       { key: 'firm_total_assets_in_millions__gte', value: '50' },
//       { key: 'firm_total_assets_in_millions__lte', value: '100' },
//     ],
//   },
//   {
//     label: '100M-500M',
//     value: [
//       { key: 'firm_total_assets_in_millions__gte', value: '100' },
//       { key: 'firm_total_assets_in_millions__lte', value: '500' },
//     ],
//   },
//   { label: '>500M', value: [{ key: 'firm_total_assets_in_millions__gte', value: '500' }] },
// ];

export const states = [
  { value: [], label: 'Any' },
  { value: [{ value: 'AK', key: 'state' }], label: 'AK' },
  { value: [{ value: 'AL', key: 'state' }], label: 'AL' },
  { value: [{ value: 'AR', key: 'state' }], label: 'AR' },
  { value: [{ value: 'AZ', key: 'state' }], label: 'AZ' },
  { value: [{ value: 'CA', key: 'state' }], label: 'CA' },
  { value: [{ value: 'CO', key: 'state' }], label: 'CO' },
  { value: [{ value: 'CT', key: 'state' }], label: 'CT' },
  { value: [{ value: 'DC', key: 'state' }], label: 'DC' },
  { value: [{ value: 'DE', key: 'state' }], label: 'DE' },
  { value: [{ value: 'FL', key: 'state' }], label: 'FL' },
  { value: [{ value: 'GA', key: 'state' }], label: 'GA' },
  { value: [{ value: 'HI', key: 'state' }], label: 'HI' },
  { value: [{ value: 'IA', key: 'state' }], label: 'IA' },
  { value: [{ value: 'ID', key: 'state' }], label: 'ID' },
  { value: [{ value: 'IL', key: 'state' }], label: 'IL' },
  { value: [{ value: 'IN', key: 'state' }], label: 'IN' },
  { value: [{ value: 'KS', key: 'state' }], label: 'KS' },
  { value: [{ value: 'KY', key: 'state' }], label: 'KY' },
  { value: [{ value: 'LA', key: 'state' }], label: 'LA' },
  { value: [{ value: 'MA', key: 'state' }], label: 'MA' },
  { value: [{ value: 'MD', key: 'state' }], label: 'MD' },
  { value: [{ value: 'ME', key: 'state' }], label: 'ME' },
  { value: [{ value: 'MH', key: 'state' }], label: 'MH' },
  { value: [{ value: 'MI', key: 'state' }], label: 'MI' },
  { value: [{ value: 'MN', key: 'state' }], label: 'MN' },
  { value: [{ value: 'MO', key: 'state' }], label: 'MO' },
  { value: [{ value: 'MS', key: 'state' }], label: 'MS' },
  { value: [{ value: 'MT', key: 'state' }], label: 'MT' },
  { value: [{ value: 'NC', key: 'state' }], label: 'NC' },
  { value: [{ value: 'ND', key: 'state' }], label: 'ND' },
  { value: [{ value: 'NE', key: 'state' }], label: 'NE' },
  { value: [{ value: 'NH', key: 'state' }], label: 'NH' },
  { value: [{ value: 'NJ', key: 'state' }], label: 'NJ' },
  { value: [{ value: 'NM', key: 'state' }], label: 'NM' },
  { value: [{ value: 'NV', key: 'state' }], label: 'NV' },
  { value: [{ value: 'NY', key: 'state' }], label: 'NY' },
  { value: [{ value: 'OH', key: 'state' }], label: 'OH' },
  { value: [{ value: 'OK', key: 'state' }], label: 'OK' },
  { value: [{ value: 'OR', key: 'state' }], label: 'OR' },
  { value: [{ value: 'PA', key: 'state' }], label: 'PA' },
  { value: [{ value: 'RI', key: 'state' }], label: 'RI' },
  { value: [{ value: 'SC', key: 'state' }], label: 'SC' },
  { value: [{ value: 'SD', key: 'state' }], label: 'SD' },
  { value: [{ value: 'TN', key: 'state' }], label: 'TN' },
  { value: [{ value: 'TX', key: 'state' }], label: 'TX' },
  { value: [{ value: 'UT', key: 'state' }], label: 'UT' },
  { value: [{ value: 'VA', key: 'state' }], label: 'VA' },
  { value: [{ value: 'VT', key: 'state' }], label: 'VT' },
  { value: [{ value: 'WA', key: 'state' }], label: 'WA' },
  { value: [{ value: 'WI', key: 'state' }], label: 'WI' },
  { value: [{ value: 'WV', key: 'state' }], label: 'WV' },
  { value: [{ value: 'WY', key: 'state' }], label: 'WY' },
];
