import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { formatNumber } from '../../helpers/dataFormatter';
import { H2 } from '../../components';
import Loader from '../Loader';
import { typography } from '../../styles';
import { useNavigate } from 'react-router-dom';

type Props = {
  cardData?: any;
  buttonCopy?: any;
  sectionTitle?: string;
  reserveThemeAction?: any;
  subscriptionsList?: any;
};

const CardItem = styled('div')(`
    border-radius: 5px;
    flex:1;
    max-width: 360px;
    background: #fff;
    padding: 20px 20px;
    cursor: pointer;
    transition: 1s;
    margin: 10px 10px;
    border: 1px solid #C0C0C0;
    min-height: 220px;
    &:hover {
        box-shadow: 0px 0px 25px rgba(63, 106, 194, 0.35);
    }
`);

const GreenText = styled('p')(`
    ${typography.main_font};
    color: #00CFAA;
    font-size: 14px;
    font-weight: 400px;
    margin: 0;
`);

const RowTitle = styled('p')(`
    ${typography.main_font};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #242B2F;
`);

const RowBody = styled('p')(`
    ${typography.main_font};
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #758790;
    min-height: 60px;
`);

const CardButton = styled('button')(`
    ${typography.main_font};
    background: #242B2F;
    border-radius: 5px;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    border: 1px solid #242B2F;
    :hover {
        opacity: 0.7;
    }
`);

const ThemeStyle = styled('p')(`
    ${typography.main_font};
    color: #898BB7;
    font-size: 20px;
    font-weight: 500px;
    margin: 0;
`);

const SubscriptionCard = ({ cardData, buttonCopy }: Props) => {
  const navigate = useNavigate();

  return (
    <CardItem key={cardData?.index} onClick={() => cardData.link && navigate(cardData.link)}>
      <RowTitle>{cardData.title}</RowTitle>
      {cardData?.active ? (
        <GreenText>Activated</GreenText>
      ) : (
        // <RowTitle>${formatNumber(card.price)}/mo</RowTitle>
        <div style={{ height: '20px' }} />
      )}
      <ThemeStyle>{cardData.keyword}</ThemeStyle>
      <RowBody>{cardData.description}</RowBody>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
        {!cardData?.active && <CardButton onClick={() => cardData.action(cardData)} children={buttonCopy} />}
      </div>
    </CardItem>
  );
};

export default SubscriptionCard;
