import {
  advisorDefaultFeatures,
  individualDefaultFeatures,
  prioritizeDefaultFeatures,
  prioritizeProspectsDefaultFeatures,
  crossSellDefaultFeatures,
} from './defaultCharacteristics';
import { apolloClient } from '../api';
import { GET_FUND_DETAILS } from '../api/dataAPI';
import _ from 'lodash';

export const formatSelectedFunds = async (taggedFunds: any) => {
  const formattedSelections: any = {};
  try {
    for (let fundId of taggedFunds) {
      const response = await apolloClient.query({ query: GET_FUND_DETAILS, variables: { id: fundId } });
      const fundDetails = response?.data?.funds?.list?.[0];
      if (fundDetails) {
        formattedSelections[fundId] = fundDetails;
      } else {
        formattedSelections[fundId] = { id: fundId, name: 'Not Found', ticker: '-' };
      }
    }
    return formattedSelections;
  } catch (error: any) {
    console.warn('error in format selectedFunds: ', error.message);
    return {};
  }
};

export const formatFundTicker = async (fundId: string) => {
  //const formattedSelections = {};
  const response = await apolloClient.query({ query: GET_FUND_DETAILS, variables: { id: fundId } });

  const details = response?.data?.funds?.list?.[0];

  return details?.ticker;
};

// export const findSelectedTheme = async (themeId: string) => {
//   const response = await GenerateWaitlistAPI.searchThemes('', themeId);
//   const formattedTheme = response?.themes?.[0];
//   return formattedTheme;
// };

export const formatSelectedAdvisors = (selectedAdvisors: any, allAdvisors: any) => {
  let formattedSelections: any = {};
  for (let selection of selectedAdvisors) {
    if (formattedSelections?.[selection]) {
      formattedSelections[selection] = allAdvisors?.filter((advisor: any) => selection?.toString() === advisor?.id);
    }
  }
  return formattedSelections;
};

export const formatSelectedClients = (selectedClients: any, allClients: any) => {
  let formattedSelections: any = {};
  for (let selection of selectedClients) {
    formattedSelections[selection] = allClients.filter((advisor: any) => selection === advisor.id);
  }
  return formattedSelections;
};

export const formatFilterOptions = (archive: {
  accountsMax: any;
  accountsMin: any;
  advisorType: any;
  ageMax: any;
  ageMin: any;
  aumMax: any;
  aumMin: any;
  location: any;
  state: any;
  incomeMax: any;
  incomeMin: any;
  wealthMax: any;
  wealthMin: any;
}) => {
  let newFilterOptions: any = {};
  const {
    accountsMax,
    accountsMin,
    advisorType,
    ageMax,
    ageMin,
    aumMax,
    aumMin,
    location,
    state,
    incomeMax,
    incomeMin,
    wealthMax,
    wealthMin,
  } = archive;

  if (archive.accountsMax || archive.accountsMin) {
    newFilterOptions['Firm Accounts'] = { accountsMax, accountsMin };
  }
  if (archive.advisorType) {
    newFilterOptions['Advisor Type'] = advisorType;
  }
  if (archive.ageMax || archive.ageMin) {
    newFilterOptions['Age'] = { ageMin, ageMax };
  }
  if (archive.aumMax || archive.aumMin) {
    newFilterOptions['Firm AUM'] = { aumMin, aumMax };
  }
  if (archive.location || archive.state) {
    newFilterOptions['Location'] = location ? location : state ? state : null;
  }
  if (archive.incomeMax || archive.incomeMin) {
    newFilterOptions['Income'] = { incomeMin, incomeMax };
  }
  if (archive.wealthMax || archive.wealthMin) {
    newFilterOptions['Wealth'] = { wealthMin, wealthMax };
  }

  return newFilterOptions;
};

export const formatFeatureSelections = (archive: { featuresToExclude: any }, type: string) => {
  let newFeatureSelections: any = {};
  if (type === 'advisor') {
    newFeatureSelections = _.cloneDeep(advisorDefaultFeatures);
  } else if (type === 'waitlist') {
    newFeatureSelections = _.cloneDeep(advisorDefaultFeatures);
  } else if (type === 'individual') {
    newFeatureSelections = _.cloneDeep(individualDefaultFeatures);
  } else if (type === 'prioritize') {
    newFeatureSelections = _.cloneDeep(prioritizeDefaultFeatures);
  }

  if (archive.featuresToExclude) {
    for (let feature of archive.featuresToExclude) {
      newFeatureSelections[feature] = false;
    }
  }

  return newFeatureSelections;
};

export const formatFeatureSelectionsWealth = (archive: { featuresToExclude: string }, type: string) => {
  let newFeatureSelections: any = {};
  if (type === 'prioritizeProspects') {
    newFeatureSelections = _.cloneDeep(prioritizeProspectsDefaultFeatures);
  } else if (type === 'crossSell') {
    newFeatureSelections = _.cloneDeep(crossSellDefaultFeatures);
  }

  if (archive.featuresToExclude) {
    for (let feature of JSON.parse(archive.featuresToExclude)) {
      newFeatureSelections[feature] = false;
    }
  }

  return newFeatureSelections;
};
