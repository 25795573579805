import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, buttons, typography } from '../../../../styles';
import CustomEmailEditor from '../../../../components/CustomUnlayerEditor';
import { TextField } from '@mui/material';
import { generateContentColumn, initCustomBlock, initBlocks } from '../../../../helpers/customEmailBlocks';
import { defaultTemplateGenerator, htmlTemplateGenerator } from '../../../../helpers/defaultEmailTemplate';
import Loader from '../../../../components/Loader';
import { useStoreState } from '../../../../store';
import UploadHtmlModal from '../../../../components/UploadHtmlModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0px 0px',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: '8px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: '2px 20px',
    borderBottom: `1px solid ${colors.grey_00}`,
  },
  modalTitle: {
    ...typography.mainFont,
    fontSize: '16px',
    color: colors.black,
    fontWeight: '500',
    cursor: 'pointer',
  },
  titleIcon: {
    height: '20px',
    width: '20px',
    margin: '0px 7px -5px 0px',
  },
  subheader: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontSize: '16px',
  },
  modalBody: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
  },
  previewBox: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: colors.white,
    borderRadius: '8px',
    // width: '1170px',
    boxShadow: colors.main_box_shadow,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 16px',
    color: colors.grey_03,
    borderBottom: `1px solid ${colors.grey_00}`,
  },
  primaryButton: {
    ...buttons.primary,
  },
  headerButton: {
    ...buttons.primary,
    height: '30px',
    width: '120px',
    whiteSpace: 'nowrap',
  },
  cardBody: {
    display: 'flex',
  },
  emptyTemplate: {
    height: '100px',
    width: '80px',
    borderRadius: '5px',
    margin: '20px 16px 10px',
    boxShadow: colors.main_box_shadow,
  },
  fieldOverride: {
    margin: '16px 4px',
    '& label.Mui-focused': {
      color: colors.grey_02,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
    },
  },
}));

interface Props {
  seedArticle: any;
  setSeedArticle: any;
  refreshData: any;
  nextAction: any;
  emailArray: any;
  focusedEmail: any;
  setEmailArray: any;
  saveDraft: any;
  firstStep: boolean;
  lastSavedChange: any;
}

const CustomizeEmail = ({
  seedArticle,
  setSeedArticle,
  refreshData,
  nextAction,
  emailArray,
  focusedEmail,
  setEmailArray,
  firstStep,
  saveDraft,
  lastSavedChange,
}: Props) => {
  const classes = useStyles();
  // const [showSelectArticle, setShowSelectArticle] = useState(false);
  // const [showCreateTitle, setShowCreateTitle] = useState(false);
  const organizationLogo = useStoreState((state) => state.user.organization.logoFile);
  const [formattedContent, setFormattedContent] = useState<any>(null);
  const [selectedTemplate, setSelectedTemplate] = useState('temp1');
  const [showHtmlUpload, setShowHtmlUpload] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [blocks, setBlocks] = useState(initBlocks());
  const [htmlOverride, setHtmlOverride] = useState<any>(null);
  const [error, setError] = useState(false);
  const containerRef = useRef<any>(null);

  useEffect(() => {
    if (emailArray && emailArray?.[focusedEmail]?.editorJson) {
      // console.log('Setting formatted content based on email array: ', emailArray);
      setFormattedContent(emailArray?.[focusedEmail]?.editorJson);
    } else if (seedArticle) {
      // console.log('Setting formatted content based on seed article: ', seedArticle);
      createEmailBlock([seedArticle]);
      setSeedArticle(null);
    } else {
      // console.log('Setting content bassed on default template.');
      setFormattedContent(defaultTemplateGenerator(organizationLogo));
    }
  }, []);

  const createEmailBlock = (selectedArticles: any, selectedLayout?: any) => {
    const articleBlocks = selectedArticles.map((article: any) =>
      generateContentColumn(article?.coverImage, article?.title, article?.description, 'Read more', article?.file),
    );
    const newBlock = initCustomBlock('Custom Email Block', articleBlocks);
    setBlocks([newBlock]);
    const newTemplate = defaultTemplateGenerator(organizationLogo, newBlock.data);
    setFormattedContent(newTemplate);
  };

  const createEmailFromHTML = (html: string) => {
    setHtmlOverride(html);
    const newTemplate = htmlTemplateGenerator(html);
    setFormattedContent(newTemplate);
  };

  const saveChanges = async (newEmail: any) => {
    setError(false);
    const newEmailArray = [...emailArray];
    newEmailArray[focusedEmail] = newEmail;
    setEmailArray(newEmailArray);
    nextAction();
  };

  const saveDraftEmail = async (newEmail: any) => {
    const newEmailArray = [...emailArray];
    newEmailArray[focusedEmail] = newEmail;
    setEmailArray(newEmailArray);
    // console.log('in save draft email: ', newEmailArray);
    saveDraft(newEmailArray);
  };

  const EmailDetails = (localEmailInfo: any, setLocalEmailInfo: any) => {
    return (
      <div className={classes.cardBody} style={{ margin: '0px -4px' }}>
        <TextField
          id="email-field"
          label="Name"
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={localEmailInfo.name}
          className={classes.fieldOverride}
          focused
          onChange={(event) => setLocalEmailInfo({ ...localEmailInfo, name: event.target.value })}
        />
        <TextField
          id="email-field"
          label="Email Subject"
          variant="outlined"
          size="small"
          sx={{ width: '100%' }}
          value={localEmailInfo.subject}
          className={classes.fieldOverride}
          focused
          onChange={(event) => setLocalEmailInfo({ ...localEmailInfo, subject: event.target.value })}
        />
      </div>
    );
  };

  return (
    <div className={classes.modalBody} style={{ padding: '10px 30px 30px' }} ref={containerRef}>
      {/* <p className={classes.subheader}>Select content and create an email for your campaign.</p> */}
      <div style={{ margin: '10px' }} />
      {/* 
        moved to button and main page per Mike --> {emailTemplates()} 
      */}
      {true ? (
        <div
          className={classes.previewBox}
          style={{
            padding: '16px',
            width: '100%',
          }}
        >
          <CustomEmailEditor
            HeaderButtons={
              [
                // { buttonText: 'Add Content', action: () => setShowContentModal(!showContentModal), noDisable: true },
                // { buttonText: 'Save ', action: () => setShowContentModal(!showContentModal), noDisable: true },
                // { buttonText: 'Load Template', action: () => console.log('new template!') },
              ]
            }
            HeaderComponent={EmailDetails}
            type={'email'}
            toggleHtmlUpload={() => setShowHtmlUpload(!showHtmlUpload)}
            content={formattedContent}
            saveChanges={saveChanges}
            generate={() => null}
            currentEmail={emailArray?.[focusedEmail]}
            emailBlocks={blocks}
            htmlOverride={htmlOverride}
            setContent={setFormattedContent}
            saveDraft={saveDraftEmail}
            firstStep={firstStep}
            lastSavedChange={lastSavedChange}
            title={'New Email'}
            emailInfo={undefined}
            focusedEmail={undefined}
          />
        </div>
      ) : (
        <Loader />
      )}
      <UploadHtmlModal
        modalStatus={showHtmlUpload}
        closeModal={() => setShowHtmlUpload(!showHtmlUpload)}
        createTemplateFromHtml={createEmailFromHTML}
      />
      {/* <AddContentModal
        modalStatus={showContentModal}
        closeModal={() => setShowContentModal(!showContentModal)}
        createEmailBlock={createEmailBlock}
        setSeedArticle={setSeedArticle}
        articleArray={articleArray}
      /> */}
    </div>
  );
};

export default CustomizeEmail;
