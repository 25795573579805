import React, { useState, useEffect } from 'react';
import { colors } from '../../styles';
import { H2, F3, PrimaryButtonShort, MainTextInput, Row, Column } from '../';
import { styled } from '@mui/system';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, MenuItem } from '@mui/material';
//@ts-ignore
import link_active from '../../assets/icons/link_active.svg';
//@ts-ignore
import link_inactive from '../../assets/icons/link_inactive.svg';
import { cloneDeep } from 'lodash';
import { CSVToArray } from '../../helpers/csvHelper';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  //   height: '320px',
  margin: '0px auto 0px',
  alignItems: 'center',
  flexDirection: 'column',
});

const FieldTableHeader = styled(TableRow)({
  borderBottom: `1px solid ${colors.grey_01}`,
});

type Props = {
  uploadedFile: any;
  setUploadedFile: any;
  uploadDataset: any;
  setModalPage: any;
  exampleTemplate: any;
  acceptedFields: any;
  datasetTypes: any;
};

const MatchHeaders = ({ setModalPage, uploadedFile, uploadDataset, acceptedFields }: Props) => {
  const [errors, setErrors] = useState<any>(null);
  const [datasetName, setDatasetName] = useState<any>(uploadedFile?.name);
  const [fieldMapping, setFieldMapping] = useState<any>([]);
  const [fileHeaders, setFileHeaders] = useState<string[]>([]);

  useEffect(() => {
    if (uploadedFile) {
      readFile();
      const newDatasetName = uploadedFile?.name?.split('.')?.[0];
      setDatasetName(newDatasetName);
    }
    defaultMappings();
  }, [uploadedFile]);

  const defaultMappings = () => {
    const newFieldMapping: any = {};
    for (let field of acceptedFields) {
      newFieldMapping[field] = { srcKey: null, dstKey: field };
    }
    return newFieldMapping;
  };

  const pageCopy = {
    header: 'Complete Upload',
    subheader: 'We have processed your file.',
    body: `In the following table please match the headers from your ${
      false ? 'CRM' : 'upload'
    } to valid fields that can be processed by our algorithms. (* fields are required)`,
    buttons: [{ text: 'Create Dataset', action: () => uploadDataset(datasetName, Object.values({})) }],
  };

  const readFile = () => {
    let fileReader = new FileReader();
    const handleFileRead = async (e: any) => {
      try {
        const content: any = fileReader.result;
        let csv = CSVToArray(content);
        let lines = [];
        let unformattedFields: any = csv[0];
        setFileHeaders(unformattedFields);
        const newFieldMapping: any = defaultMappings();
        for (let unformattedField of unformattedFields) {
          if (acceptedFields.includes(unformattedField)) {
            newFieldMapping[unformattedField] = { srcKey: unformattedField, dstKey: unformattedField };
          }
        }
        setFieldMapping(newFieldMapping);
        // const fields = unformattedFields.map((item: any) => ({ field: item, formattedField: formatField(item) }));

        // console.log('fields: ', fields);
      } catch (error: any) {
        setErrors([error.message]);
        setModalPage('ReviewData');
      }
    };

    fileReader.onloadend = handleFileRead;
    if (uploadedFile) {
      const file = uploadedFile;
      fileReader.readAsText(file);
    }
  };

  const formatValidFieldName = (value: string) => {
    switch (value) {
      case 'firstName':
        return 'First Name';
      case 'age':
        return 'Advisor Age';
      case 'aum_self_reported':
        return 'Assets Under Management';
      case 'career_tenure':
        return 'Career Tenure';
      case 'current_firm_tenure_years':
        return 'Current Firm Tenure';
      case 'email':
        return 'Advisor Email*';
      case 'firm_crd':
        return 'Firm CRD';
      case 'firm_etf_assets_millions':
        return 'Firm ETF Assets in Millions';
      case 'firm_mf_assets_millions':
        return 'Firm MF Assets in Millions';
      case 'firm_name':
        return 'Firm Name';
      case 'firm_number_bd_reps':
        return 'Firm Number BD Reps';
      case 'firm_number_ia_reps':
        return 'Firm Number IA Reps';
      case 'firm_total_accounts':
        return 'Firm Total Accounts';
      case 'firm_total_assets_in_millions':
        return 'Firm Total Assets in Millions';
      case 'first_name':
        return 'First Name';
      case 'full_name':
        return 'Full Name';
      case 'is_bd':
        return 'BD Advisor';
      case 'is_ria':
        return 'RIA Advisor';
      case 'last_name':
        return 'Last Name';
      case 'office_state':
        return 'Firm State';
      case 'office_zipcode':
        return 'Firm Zipcode';
      case 'percent_assets_in_etf_and_mf':
        return 'Percent assets in ETFs and MFs';
      case 'repcrd':
        return 'Advisor CRD';
      case 'ria_employee_ratio':
        return 'RIA Employee Ratio';
      case 'tickers_held':
        return 'Tickers Held';
      case 'title':
        return 'Advisor Title';
      default:
        return '-';
    }
  };

  const previewTable = {
    header: [
      { label: 'Valid Fields', key: 'dstKey' },
      {
        label: 'Status',
        key: 'status',
      },
      { label: 'My Equivalent Fields', key: 'srcKey' },
    ],
  };

  const checkForErrors = (fieldMapping: any) => {
    setErrors(null);
    const newErrors: string[] = [];
    let srcKeys: string[] = [];
    let dstKeys: string[] = [];
    console.log('field mappings: ', fieldMapping);
    for (let map of fieldMapping) {
      if (srcKeys.includes(map?.srcKey)) {
        newErrors.push(`You have ${map?.srcKey} mapped to multiple fields.`);
      } else {
        srcKeys.push(map?.srcKey);
        dstKeys.push(map?.dstKey);
      }
    }
    if (!dstKeys.includes('email')) {
      newErrors.push('You must map advisor email to a field.');
    }
    if (newErrors.length > 0) {
      setErrors(newErrors);
      return newErrors;
    } else {
      return newErrors;
    }
  };

  const createDataset = () => {
    const finalFieldMapping = Object.values(fieldMapping).filter((map: any) => !!map.srcKey);
    const newErrors = checkForErrors(finalFieldMapping);

    if (newErrors.length === 0) {
      uploadDataset(datasetName, finalFieldMapping);
    }
  };

  return (
    <Column style={{ flex: 1, margin: '0px 0px 0px', justifyContent: 'space-between' }}>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, width: '600px' }}>{pageCopy.subheader}</F3>
        <Row>
          <MainTextInput
            label="Name"
            size="small"
            sx={{ margin: '12px 0px 0px -16px', width: '300px' }}
            value={datasetName}
            /*className={classes.fieldOverride}*/
            focused
            onChange={(event) => setDatasetName(event.target.value)}
          />
        </Row>
        <F3 style={{ color: colors.grey_02, width: '600px' }}>{pageCopy.body}</F3>
      </TextContainer>
      <TableContainer style={{ width: '600px', margin: '0px 20px', maxHeight: '400px' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <FieldTableHeader>
              {previewTable.header.map((header, index) => {
                return (
                  <TableCell
                    sx={{
                      fontSize: 14,
                      color: '#758790',
                    }}
                    key={index}
                  >
                    {header?.label}
                  </TableCell>
                );
              })}
            </FieldTableHeader>
          </TableHead>
          <TableBody>
            {Object.values(fieldMapping).map((row: any, index: number) => {
              // console.log('row header: ', row);
              return (
                <TableRow key={index + 21023981} style={{ height: '60px' }}>
                  {previewTable.header.map((header: any, index) => {
                    if (header?.key === 'srcKey') {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            fontSize: 14,
                            color: '#758790',
                          }}
                        >
                          <MainTextInput
                            // label="Data Type"
                            size="small"
                            style={{ margin: '-8px 0px 0px 0px', width: '300px', height: '12px' }}
                            value={row?.srcKey ? row?.srcKey : 0}
                            /*className={classes.fieldOverride}*/
                            select
                            focused
                            //@ts-ignore
                            onChange={(event) => {
                              const newFieldMapping = cloneDeep(fieldMapping);
                              newFieldMapping[row.dstKey] = { srcKey: event.target.value, dstKey: row.dstKey };
                              setFieldMapping(newFieldMapping);
                            }}
                          >
                            <MenuItem
                              key={index}
                              value={0}
                              //sx={{ overflowX: 'auto' }}
                              sx={{
                                fontFamily: 'Inter',
                              }}
                            >
                              <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{'-'} </F3>
                            </MenuItem>
                            {fileHeaders.map((item: any, index: number) => (
                              <MenuItem
                                key={index}
                                value={item}
                                //sx={{ overflowX: 'auto' }}
                                sx={{
                                  fontFamily: 'Inter',
                                }}
                              >
                                <F3 style={{ margin: '2px 0px 0px', padding: '0px 0px' }}>{item} </F3>
                              </MenuItem>
                            ))}
                          </MainTextInput>
                        </TableCell>
                      );
                    }
                    if (header?.key === 'status') {
                      return (
                        <TableCell
                          key={index}
                          sx={{
                            //   border: '0.5px solid #C2CBCF',
                            fontSize: 14,
                            color: '#758790',
                          }}
                        >
                          <img
                            style={{ height: '14px', width: '14px', margin: '4px 12px 0px' }}
                            src={!!row.srcKey ? link_active : link_inactive}
                            alt={'link'}
                          />
                        </TableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          //   border: '0.5px solid #C2CBCF',
                          fontSize: 14,
                          color: '#758790',
                        }}
                      >
                        {row?.[header?.key] ? formatValidFieldName(row?.[header?.key]) : '-'}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {errors && <F3 style={{ margin: '16px 20px', color: colors.red }}>{errors[0]}</F3>}
      {pageCopy.buttons && (
        <Row
          style={{
            height: '50px',
            margin: '0px -26px 0px -26px',
            padding: '16px 16px 0px',
            // width: '110%',
            justifyContent: 'flex-end',
            borderTop: `1px solid ${colors.grey_01}`,
          }}
        >
          <PrimaryButtonShort style={{ width: '140px' }} onClick={createDataset}>
            {'Create Dataset'}
          </PrimaryButtonShort>
        </Row>
      )}
    </Column>
  );
};

export default MatchHeaders;
