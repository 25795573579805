import { Action, action, Thunk, thunk } from 'easy-peasy';

export interface InfluenceProspectsModel {
  progress: number;
  progressTitles: Array<string>;
  advisorSetSelected: object;
  filterOptions: object;
  characteristics: object;
  packageRunName: string | null;
  dateRun: string | null;
  runId: number | null;
  runIdArray: Array<string> | null;
  journeyId: number | null;

  setProgress: Action<InfluenceProspectsModel, any>;
  setProgressTitles: Action<InfluenceProspectsModel, any>;
  setAdvisorSetSelected: Action<InfluenceProspectsModel, any>;
  setFilterOptions: Action<InfluenceProspectsModel, any>;
  setCharacteristics: Action<InfluenceProspectsModel, any>;
  setPackageRunName: Action<InfluenceProspectsModel, any>;
  setDateRun: Action<InfluenceProspectsModel, any>;
  setRunId: Action<InfluenceProspectsModel, any>;
  setRunIdArray: Action<InfluenceProspectsModel, any>;
  setJourneyId: Action<InfluenceProspectsModel, any>;

  loadArchive: Thunk<InfluenceProspectsModel, any>;
}

export const influenceProspects: InfluenceProspectsModel = {
  progress: 0,
  progressTitles: ['', '', ''],
  advisorSetSelected: {},
  filterOptions: {},
  characteristics: {},
  packageRunName: null,
  dateRun: null,
  runId: null,
  runIdArray: [],
  journeyId: null,

  setProgress: action((state, payload) => {
    state.progress = payload;
  }),
  setProgressTitles: action((state, payload) => {
    state.progressTitles = payload;
  }),
  setAdvisorSetSelected: action((state, payload) => {
    state.advisorSetSelected = payload;
  }),
  setFilterOptions: action((state, payload) => {
    state.filterOptions = payload;
  }),
  setCharacteristics: action((state, payload) => {
    state.characteristics = payload;
  }),
  setPackageRunName: action((state, payload) => {
    state.packageRunName = payload;
  }),
  setDateRun: action((state, payload) => {
    state.dateRun = payload;
  }),
  setRunId: action((state, payload) => {
    state.runId = payload;
  }),
  setRunIdArray: action((state, payload) => {
    state.runIdArray = payload;
  }),
  setJourneyId: action((state, payload) => {
    state.journeyId = payload;
  }),

  loadArchive: thunk(async (actions: any, payload: any) => {
    actions.setProgressTitles(['Complete', 'Complete', 'Complete']);

    actions.setJourneyId(Number(payload?.id));
    actions.setPackageRunName(payload?.customName);
    actions.setRunId(Number(payload?.runhistorySet?.[0].id));
    actions.setRunIdArray(payload?.runhistorySet);
    actions.setPackageRunName(payload?.customName);
    actions.setDateRun(payload?.dateRun);
    actions.setProgress(3);
  }),
};
