import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import Checkbox from '../Checkbox';
import { FormControl, InputLabel, Select, OutlinedInput, Box, MenuItem } from '@mui/material';
import { GeneralFormControl, GeneralMenuItem } from '..';
import { any } from 'cypress/types/bluebird';
import { cloneDeep } from 'lodash';
import SearchBar from '../SearchBar';

interface Props {
  id: string;
  label: string;
  options: any;
  width?: string;
  searchable?: boolean;
  selectedFilter: any;
  updateMultipleFilters: any;
  allFilters: any;
  MenuProps: any;
  singleSelect?: boolean;
  disabled?: boolean;
}

const MenuText = styled('p')({
  fontFamily: 'Inter',
  fontSize: '14px',
  color: colors.grey_03,
  margin: '0px 8px',
});

const CustomInputLabel = styled(InputLabel)({
  width: 'fit-content',
  background: colors.white_00,
  color: colors.black,
  // marginTop: 0.4,
  // marginLeft: 0.5,
  margin: '0px 0px 0px 0px',
  padding: '0px 6px 0px 0px',
  // paddingRight: 1,
  fontSize: 12,
});

const CustomSelect = styled(Select)({
  overflow: 'hidden',
  alignText: 'center',
  whiteSpace: 'nowrap',
});

export const MultiSelect = ({
  id,
  label,
  selectedFilter,
  updateMultipleFilters,
  searchable,
  MenuProps,
  width,
  allFilters,
  singleSelect,
  options,
  disabled,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const getFilterOptionValue = (passedOptions: any) => {
    try {
      let newOptions = [...passedOptions];
      if (singleSelect) {
        newOptions = newOptions.filter((option: any) => !selectedFilter?.label?.includes(option));
      }
      const filterValue = options
        .filter((option: any) => newOptions.includes(option.label))
        .flatMap((option: any) => option.value);

      return { formattedValue: newOptions, newValue: filterValue };
    } catch (error: any) {
      console.warn('error in getFilterOption value: ', error.message);
    }
  };

  // console.log('selectedFilter in multiselect: ', selectedFilter);

  return (
    <div style={{ marginTop: 4 }}>
      <GeneralFormControl
        sx={{
          fontFamily: 'Inter',
          margin: '5px 0px 5px 0px',
        }}
      >
        <CustomInputLabel sx={{}} id="demo-multiple-chip-label">
          {label}
        </CustomInputLabel>
        <CustomSelect
          labelId="demo-multiple-chip-label"
          id={id ? id : 'demo-multiple-chip'}
          label={label}
          multiple
          value={selectedFilter && selectedFilter.label ? selectedFilter.label : ['Any']}
          disabled={disabled}
          onChange={(event) => {
            const result = getFilterOptionValue(event.target.value);
            updateMultipleFilters(result?.formattedValue, label, result?.newValue);
          }}
          size="small"
          style={{ height: '30px', width, fontSize: 14 }}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          // IconComponent={() => restartFilter(selectedFilter, false)}
          renderValue={(selected: any) => (
            <Box sx={{ display: 'flex', overflow: 'hidden' }}>
              {selected.map((value: any, index: number) => (
                <p key={value} style={{ color: value === 'Any' ? '#758790' : '#404B50' }}>
                  {value}
                  {index < selected.length - 1 && ','}
                </p>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {searchable ? (
            <SearchBar searchText={searchTerm} setSearchText={setSearchTerm} />
          ) : (
            <GeneralMenuItem
              key={239402394}
              value={'Any'}
              //sx={{ overflowX: 'auto' }}
              sx={{
                fontFamily: 'Inter',
              }}
            >
              <MenuText>{'Any'} </MenuText>
            </GeneralMenuItem>
          )}
          {options.slice(0, 20).map((option: any, index: number) => {
            if (option.label !== 'Any') {
              return (
                <GeneralMenuItem
                  key={index}
                  value={option.label}
                  //sx={{ overflowX: 'auto' }}
                  sx={{
                    fontFamily: 'Inter',
                  }}
                >
                  <Checkbox
                    checked={allFilters?.[label]?.['label']?.indexOf(option.label) > -1}
                    size={'14px'}
                    onClick={() => null}
                  />
                  <MenuText>{option.label} </MenuText>
                </GeneralMenuItem>
              );
            }
          })}
        </CustomSelect>
      </GeneralFormControl>
    </div>
  );
};
