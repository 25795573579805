import React, { useEffect } from 'react';
import { Column, F3, H2, F2, Row, MainTextInput, FreeTextArea } from '..';
import { styled } from '@mui/material';
import { colors, typography } from '../../styles';
import { SAVE_FEEDBACK } from '../../api/dataAPI';
import { useMutation } from '@apollo/client';
import PrimaryButtonShort from '../PrimaryButtonShort';
import StarIcon from '../StarIcon';

type Props = {
  client: any;
  runId: string;
};

const ViewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  margin: '16px 0px 6px 0px',
});

const OpportunityChip = styled('div')({
  height: '25px',
  width: '100%',
  background: colors.grey_02,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '25px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  fontSpacing: '0.1px',
  flex: 1,
});

const ProductChip = styled('div')({
  height: '29px',
  padding: '0px 12px',
  borderRadius: '5px',
  background: colors.white,
  color: colors.grey_02,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '29px',
  ...typography.mainFont,
  fontWeight: 400,
  fontSize: '14px',
  margin: '0px 8px 8px 0px',
  fontSpacing: '0.1px',
  cursor: 'pointer',
});

const OverlayContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  background: colors.grey_01 + 'F0',
  zIndex: 100,
});

type divProps = {
  background?: string;
};

const FlexButton = styled('div')((props: divProps) => ({
  display: 'flex',
  background: props?.background ?? colors.white,
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '4px 0px',
  margin: '0px 8px',
}));

const MainContainer = styled('div')({
  display: 'flex',
  padding: '8px 16px',
  flex: 1,
  flexDirection: 'column',
  background: colors.grey_01,
  borderRadius: '5px',
  margin: '8px 0px',
});

type MainFeedbackOptions = 'positive' | 'negative' | null;
type InfoTextType = {
  header: string;
  body: string;
};

function FeedbackView({ client, runId }: Props) {
  const [feedbackNumStars, setFeedbackNumStars] = React.useState<number>(0);
  const [feedbackItems, setFeedbackItems] = React.useState<string[]>([]);
  const [feedbackFreeForm, setFeedbackFreeForm] = React.useState<string>('');
  const [decisionMaker, setDecisionMaker] = React.useState<string>('');
  const [infoText, setInfoText] = React.useState<InfoTextType | null>(null);
  const [hideAdditional, setHideAdditional] = React.useState<boolean>(false);

  const formattedProducts = JSON.parse(client?.productsOfInterest) ?? [];
  const productFeedback = formattedProducts.map((product: string) => `Interested in ${product}`);

  const [saveFeedback, { loading, data, error }] = useMutation(SAVE_FEEDBACK, {
    onCompleted: (data) => {
      setInfoText({ header: 'Response Recorded', body: 'Feedback has been saved successfully' });
    },
    onError: (error) => {
      setInfoText({ header: 'Error Saving Feedback', body: "Feedback couldn't be saved" });
    },
  });

  const additionFeedbackItems: any = {
    5: [
      'Purchase Potential',
      'Decision Maker',
      ...productFeedback,
      'Interested in Other Vehicles',
      'Good Performance of our Strategies',
    ],
    4: [
      'Purchase Potential',
      'Decision Maker',
      ...productFeedback,
      'Interested in Other Vehicles',
      'Good Performance of our Strategies',
    ],
    3: [
      'Purchase Potential',
      'Decision Maker',
      ...productFeedback,
      'Interested in Our Other Funds',
      'Valuable AMP Intelligence',
      'Product Not Available',
      'Not Interested in Our Funds',
      'Contact Details Incorrect',
      'Unable to Reach Advisor',
      'Outsources Decision-Making',
      "Our Strategies Don't Fit Current Needs",
      'They Prefer a Different Vehicle',
      'They Only Use Models',
      'They Only Use SMAs',
      'Interested But Not Now',
    ],
    2: [
      'Product Not Available',
      'Contact Details Incorrect',
      'Unable to Reach Advisor',
      'Outsources Decision-Making',
      'Poor Performance of Our Strategies',
      "Our Strategies Don't Fit Current Needs",
      'They Prefer a Different Vehicle',
      'They Only Use Models',
      'They Only Use SMAs',
      'Interested But Not Now',
    ],
    1: [
      'Product Not Available',
      'Contact details incorrect',
      'Unable to Reach Advisor',
      'Outsources Decision-Making',
      'Poor Performance of Our Strategies',
      "Our Strategies Don't Fit Current Needs",
      'They Prefer a Different Vehicle',
      'They Only use Models',
      'They Only use SMAs',
      'Interested But Not Now',
    ],
  };

  const stars = [
    { key: '209301', value: 1 },
    { key: '694043', value: 2 },
    { key: '903442', value: 3 },
    { key: '318904', value: 4 },
    { key: '329301', value: 5 },
  ];

  useEffect(() => {}, [client]);

  return (
    <ViewContainer>
      <OpportunityChip>FEEDBACK</OpportunityChip>
      <MainContainer>
        <F3 style={{ color: colors.grey_03, margin: '4px 0px 8px' }}>
          Please rate this AMP intelligence. Five stars is the highest rating, one star is the lowest rating.
        </F3>
        <Row style={{ padding: '4px 0px', margin: '0px -8px', backgroundColor: colors.white, borderRadius: '5px' }}>
          {stars.map((star) => (
            <StarIcon
              key={star.key}
              onClick={() => {
                setHideAdditional(false);
                setFeedbackNumStars(star?.value);
              }}
              style={{ margin: '0px auto' }}
              fill={feedbackNumStars >= star.value ? colors.blue : colors.grey_15}
            />
          ))}
        </Row>
        {!!feedbackNumStars && !hideAdditional && (
          <Column style={{ position: 'relative' }}>
            {!!infoText && (
              <OverlayContainer>
                <Column>
                  <H2>{infoText?.header}</H2>
                  <F3 style={{ margin: '0px 0px 6px', color: colors.grey_02 }}>{infoText?.body}</F3>
                  <PrimaryButtonShort
                    style={{ margin: '4px 0px', width: '100px' }}
                    onClick={() => {
                      setInfoText(null);
                      setHideAdditional(true);
                    }}
                    loading={false}
                  >
                    Close
                  </PrimaryButtonShort>
                </Column>
              </OverlayContainer>
            )}
            <F3 style={{ color: colors.grey_03, margin: '12px 0px 8px' }}>
              Please select additional items which apply
            </F3>
            <Row style={{ flexWrap: 'wrap' }}>
              {additionFeedbackItems?.[feedbackNumStars].map((item: string) => (
                <ProductChip
                  style={{
                    background: feedbackItems.includes(item) ? colors.blue : colors.white,
                    border: `1px solid ${colors.grey_15}`,
                    color: feedbackItems.includes(item) ? colors.white : colors.grey_02,
                  }}
                  onClick={() => {
                    if (feedbackItems.includes(item)) {
                      setFeedbackItems(feedbackItems.filter((i) => i !== item));
                    } else {
                      setFeedbackItems([...feedbackItems, item]);
                    }
                  }}
                >
                  {item}
                </ProductChip>
              ))}
            </Row>
            {feedbackItems?.includes('Outsources Decision-Making') && (
              <>
                <F3 style={{ color: colors.grey_03, margin: '12px 0px 2px' }}>
                  Please enter the name of the decision maker if possible
                </F3>
                <FreeTextArea
                  value={decisionMaker}
                  onChange={(event) => setDecisionMaker(event.target.value)}
                  rows={1}
                  size="small"
                  multiline
                />
              </>
            )}
            <F3 style={{ color: colors.grey_03, margin: '12px 0px 2px' }}>Please add any additional feedback</F3>
            <FreeTextArea
              value={feedbackFreeForm}
              style={{ border: `1px solid ${colors.grey_15}` }}
              onChange={(event) => setFeedbackFreeForm(event.target.value)}
              rows={3}
              size="small"
              multiline
            />
            <Row style={{ justifyContent: 'flex-end' }}>
              <PrimaryButtonShort
                onClick={() =>
                  saveFeedback({
                    variables: {
                      runId,
                      leadId: client?.id,
                      feedbackItems,
                      feedbackNumStars,
                      feedbackFreeForm,
                      feedbackDecisionMaker: decisionMaker,
                    },
                  })
                }
                loading={loading}
              >
                Submit
              </PrimaryButtonShort>
            </Row>
          </Column>
        )}
      </MainContainer>
    </ViewContainer>
  );
}

export default FeedbackView;
