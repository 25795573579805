import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../../styles';
import { H2, F3, PrimaryButton } from '..';
import { styled } from '@mui/system';
import { useStoreState } from '../../store';

type Props = {
  uploadType?: any;
  jobUpdate?: any;
  setUploadType?: any;
  setModalPage: any;
  setJobUpdate?: any;
};

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '0px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  minWidth: '200px',
  cursor: 'pointer',
  textDecoration: 'none',
  margin: '15px 10px 0px 10px',
  padding: '10px 12px',
  '&:hover': {
    opacity: 0.7,
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '500px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-20px 0 0',
});

const LandingPage = ({ setModalPage }: Props) => {
  const appType = useStoreState((state) => state.appType);

  const landingOptions = {
    header: 'Upload Advisors',
    subHeader: 'How would you like to upload advisors?',
    buttons: [
      {
        text: 'Pull from CRM',
        action: () => setModalPage('CRMSelection'),
      },
      {
        text: 'With a spreadsheet',
        action: () => setModalPage('UploadFile'),
      },
      {
        text: 'Enter CRDs Manually',
        action: () => setModalPage('ManualInput'),
      },
    ],
  };

  return (
    <div style={{ flex: 1, margin: '80px 0px 0px' }}>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{landingOptions.header}</H2>
        <F3 style={{ color: colors.grey_02 }}>{landingOptions.subHeader}</F3>
      </TextContainer>
      {landingOptions.buttons && (
        <ButtonContainer>
          {landingOptions &&
            landingOptions.buttons.map((button, index) => (
              <PrimaryButton style={{ margin: '0px 8px', width: '260px' }} key={index} onClick={button.action}>
                {button.text}
              </PrimaryButton>
            ))}
        </ButtonContainer>
      )}
    </div>
  );
};

export default LandingPage;
