import React, { useEffect } from 'react';
import { Row, Column, F3, H3 } from '..';
import { styled } from '@mui/material';
import { colors, typography } from '../../styles';
import { formatPercent, formatNumber } from '../../helpers/dataFormatter';
import ScrollingDiv from '../ScrollingDiv';
import { format } from 'path';

type Props = {
  client: any;
  opportunityData?: any;
  algoType?: string;
  width?: number;
};

const ViewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  margin: '16px 0px 6px 0px',
});

const OpportunityChip = styled('div')({
  height: '25px',
  width: '100%',
  background: colors.grey_02,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '25px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  fontSpacing: '0.1px',
  flex: 1,
});

const ProductChip = styled('div')((props: any) => ({
  // height: '40px',
  padding: '8px 8px 16px',
  borderRadius: '8px 8px 0px 0px',
  background: props?.focused ? colors.white : colors.grey_01,
  display: 'flex',
  ...typography.mainFont,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  fontSize: '14px',
  fontSpacing: '0.1px',
  cursor: 'pointer',
  transition: 'background 0.3s',
  '&:hover': {
    background: colors.grey_00,
    color: colors.white,
  },
}));

const ProductContainer = styled('div')({
  display: 'flex',
  padding: '12px',
  flex: 1,
  flexDirection: 'column',
  background: colors.grey_01,
  // height: '160px',
  borderRadius: '5px',
  margin: '12px 0px 0px',
});

const InfoRow = styled('div')((props: any) => ({
  display: 'flex',
  justifyContent: props?.alignItems ? 'center' : 'space-between',
  borderRadius: '5px',
  margin: '4px 0px 0px',
  background: props?.backgroundColor ? props?.backgroundColor : colors.white,
}));

const InfoText = styled('div')({
  ...typography.mainFont,
  fontSize: '12px',
  fontWeight: 600,
  color: colors.grey_03,
  margin: '3px 0px',
});

const LabelText = styled(InfoText)({
  margin: '3px 0px',
  fontWeight: 400,
});

const SummaryText = styled(F3)({
  margin: '4px 0px',
  textAlign: 'left',
  '& span.highlight': {
    fontWeight: 600,
    color: colors.lilac,
  },
  '& span.bold': {
    fontWeight: 600,
    color: colors.grey_02,
  },
});

const InnerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  lineHeight: '20px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  backgroundColor: colors.green,
  color: colors.white,
  borderRadius: '5px',
  fontSpacing: '0.1px',
  whiteSpace: 'nowrap',
  padding: '12px',
  margin: '0px 0px 0px 0px',
});

const InnerProductColumn = styled(Column)({
  backgroundColor: colors.white,
  margin: '-16px 0px 0px 0px',
  zIndex: 100,
  borderRadius: '5px',
  padding: '8px',
});

const PerformanceChip = styled('div')((props: any) => ({
  // height: '40px',
  padding: '5px 10px',
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  background: props?.color,
  display: 'flex',
  ...typography.mainFont,
  fontWeight: 700,
  whiteSpace: 'nowrap',
  fontSize: '12px',
  textTransform: 'uppercase',
  color: colors.white,
  fontSpacing: '0.1px',
  cursor: 'pointer',
}));

const mStarRating: any = {
  '1': '★',
  '2': '★★',
  '3': '★★★',
  '4': '★★★★',
  '5': '★★★★★',
};

function OpportunityView({ client, opportunityData, algoType, width }: Props) {
  const [selectedProduct, setSelectedProduct] = React.useState<any>(null);
  const [productsOfInterest, setProductsOfInterest] = React.useState<any>(null);
  const [likehood, setLikehood] = React.useState<any>(null);
  const [timing, setTiming] = React.useState<any>(null);
  const [size, setSize] = React.useState<any>(null);

  const headers = ['label', 'Holdings', selectedProduct];

  const formatSpan = (string: string) => {
    const numberValue = Number(string);

    if (string) return <span>{string}</span>;
  };

  const rows = [
    {
      key: 'morningstarRating',
      label: 'M* Rating',
      format: (data: any) => (data?.morningstarRating ? mStarRating?.[data?.morningstarRating] : ''),
    },
    {
      key: 'returnRates',
      label: '1/3/5',
      format: (data: any) => {
        const oneYear = data?.returnRates?.oneYear ? Math.round(data?.returnRates?.oneYear * 100) : '-';
        const threeYear = data?.returnRates?.threeYear ? Math.round(data?.returnRates?.threeYear * 100) : '-';
        const fiveYear = data?.returnRates?.fiveYear ? Math.round(data?.returnRates?.fiveYear * 100) : '-';
        return `${oneYear}/${threeYear}/${fiveYear}`;
      },
    },
    {
      key: 'stdDev',
      label: 'Std. Dev',
      format: (data: any) => (data?.stdDev ? formatPercent(data?.stdDev * 100, 1) + '(Avg.)' : '-'),
    },
    { key: 'sharpeRatio', label: 'Sharpe', format: (data: any) => formatNumber(data?.sharpeRatio, 2) },
    {
      key: 'mgmtFeeBps',
      label: 'Mgmt Fee',
      format: (data: any) => (data?.mgmtFeeBps ? formatNumber(data?.mgmtFeeBps, 2) + 'bps' : '-'),
    },
    {
      key: 'totalExpenseRatio',
      label: 'TER',
      format: (data: any) => (data?.totalExpenseRatio ? formatPercent(data?.totalExpenseRatio * 100, 2) : '-'),
    },
  ];

  let productData = [
    {
      title: 'Likelihood',
      formatData: (data: any) => <span>{formatPercent(likehood?.[selectedProduct]?.value * 100)}</span>,
    },
    { title: 'Timing', formatData: (data: any) => timing },
  ];

  if (!!size) {
    productData = [
      ...productData,
      {
        title: 'Size',
        formatData: (data: any) => {
          return <span>{size?.[selectedProduct]}</span>;
        },
      },
    ];
  }

  const PerformanceInsights = client?.performanceInsights;

  useEffect(() => {
    const previousServedData = client?.previousServeHistory;
    let parsedPreviousScores: any = {};
    if (previousServedData?.purchaseLikelihood) {
      parsedPreviousScores = JSON.parse(previousServedData?.purchaseLikelihood);
      if (algoType === 'Redemption') {
        parsedPreviousScores = client?.redemptionLikelihood ? JSON.parse(previousServedData?.redemptionLikelihood) : {};
      }
    }

    let parsedProductLikehood = client?.purchaseLikelihood ? JSON.parse(client?.purchaseLikelihood) : {};
    if (algoType === 'Redemption') {
      parsedProductLikehood = client?.redemptionLikelihood ? JSON.parse(client?.redemptionLikelihood) : {};
    }
    const productLikehoodArray = Object.entries(parsedProductLikehood).map(([key, value]: any) => {
      const previousScore = parsedPreviousScores?.[key];
      let change = null;
      if (previousScore) {
        change = value - previousScore;
      }

      return { key, value: value, change };
    });

    let newProductLikehood: any = {};

    for (let i = 0; i < productLikehoodArray.length; i++) {
      const product = productLikehoodArray[i];
      newProductLikehood[product.key] = product;
    }

    let newProductsOfInterest: any = [];
    if (client?.productsOfInterest && JSON.parse(client?.productsOfInterest)) {
      newProductsOfInterest = JSON.parse(client?.productsOfInterest)?.sort((productA: any, productB: any) => {
        if (newProductLikehood?.[productB]?.value > newProductLikehood?.[productA]?.value) {
          return 1;
        } else if (newProductLikehood?.[productB]?.value < newProductLikehood?.[productA]?.value) {
          return -1;
        } else {
          return 0;
        }
      });
    }

    let newProductSize = client?.purchaseSize ? JSON.parse(client?.purchaseSize) : {};

    if (algoType === 'Redemption') {
      newProductSize = JSON.parse(client?.redemptionSize);
    }

    if (Object.keys(newProductSize)?.length > 0) {
      setSize(newProductSize);
    } else {
      setSize(null);
    }

    const newProductTiming = algoType === 'Redemption' ? client?.redemptionTiming : client?.purchaseTiming;

    setLikehood(newProductLikehood);
    setTiming(newProductTiming);
    setProductsOfInterest(newProductsOfInterest);
    setSelectedProduct(newProductsOfInterest[0]);
  }, [client]);

  // console.log('performance overview: ', PerformanceInsights);

  return (
    <ViewContainer>
      <OpportunityChip>OPPORTUNITY</OpportunityChip>
      <ProductContainer>
        <ScrollingDiv
          style={{ height: '50px', margin: '2px 0px 0px', padding: '0px 0px' }}
          width={width ? width - 16 : null}
          height={40}
          backgroundColor={colors.grey_01}
        >
          {productsOfInterest?.slice(0, 3).map((item: any, index: number) => (
            <ProductChip
              //@ts-ignore
              focused={item === selectedProduct}
              key={index + 1841349}
              onClick={() => setSelectedProduct(item)}
            >
              <InnerContainer>{item}</InnerContainer>
            </ProductChip>
          ))}
        </ScrollingDiv>
        <InnerProductColumn style={{}}>
          {opportunityData?.map((item: any, index: number) => (
            <SummaryText dangerouslySetInnerHTML={{ __html: item?.key ?? item?.value }} />
          ))}
          {!!PerformanceInsights?.Holdings && (
            <>
              <H3
                style={{ color: colors.grey_02, margin: '10px 0px 0px', borderBottom: `2px solid ${colors.grey_01}` }}
              >
                PERFORMANCE INSIGHTS
              </H3>
              <Row>
                {headers.map((header: any, index: number) => {
                  return header === 'label' ? (
                    <Column
                      style={{
                        flex: 1,
                        borderRight: `1px solid ${colors.grey_01}`,
                        padding: '6px',
                      }}
                    >
                      {<PerformanceChip color={colors.white + '00'}>{header}</PerformanceChip>}
                      {rows.map((row: any, index: number) => (
                        <InfoRow key={index + 1841349} style={{ width: '80px' }}>
                          <LabelText>{row.label}</LabelText>
                        </InfoRow>
                      ))}
                    </Column>
                  ) : (
                    <Column
                      style={{
                        flex: 1,
                        padding: '6px',
                      }}
                    >
                      {
                        <PerformanceChip color={header === 'label' ? colors.white : colors.grey_02}>
                          {header === 'label' ? ' ' : header}
                        </PerformanceChip>
                      }
                      {rows.map((row: any, index: number) => {
                        const rowValue = row?.format
                          ? row?.format(PerformanceInsights?.[header])
                          : PerformanceInsights?.[header]?.[row?.key];

                        const background = colors.white;
                        const textColor = false ? colors.white : colors.grey_03;
                        return (
                          <InfoRow
                            key={index + 1841349}
                            style={{ alignItems: 'center', justifyContent: 'center', background }}
                          >
                            <InfoText style={{ textAlign: 'center', color: textColor }}>{rowValue}</InfoText>
                          </InfoRow>
                        );
                      })}
                    </Column>
                  );
                })}
              </Row>
            </>
          )}
        </InnerProductColumn>
      </ProductContainer>
    </ViewContainer>
  );
}

export default OpportunityView;
