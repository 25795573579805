import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { colors, typography } from '../styles';
import { styled } from '@mui/material/styles';
import { fontSize } from '@mui/system';
import { color } from 'd3';

const Header = styled('h1')({
  ...typography.mainFont,
  fontSize: '16px',
  fontWeight: '500',
  color: colors.grey_03,
  marginBottom: '-8px',
});

const Body = styled('p')({
  ...typography.mainFont,
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '16px',
  color: colors.grey_02,
});

const ToolTipContainer = styled('div')({
  // maxHeight: '60vh',
  // backgroundColor: colors.white_01,
  // overflowY: 'auto',
  // width: '100%',
  // // border: '2px solid rgba(63, 106, 194, 0.3)',
  // borderRadius: 5,
  // padding: 0,
  // margin: 0,
  // [`& .${tooltipClasses.tooltip}`]: {
  //   backgroundColor: colors.white_01,
  //   color: colors.grey_03,
  //   borderRadius: '5px',
  //   padding: '0px 20px',
  // },
});

//@ts-ignore
const CustomTooltip = styled(({ className, ...props }: any) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    maxHeight: '60vh',
    overflowY: 'auto',
    borderRadius: 5,
    backgroundColor: colors.white_01,
    margin: 0,
    boxShadow: colors.main_box_shadow,
    border: `1px solid ${colors.grey_01}`,
    [`& .${tooltipClasses.tooltip}`]: {
      background: colors.white_01,
      color: colors.white_01,
      maxWidth: 320,
      fontSize: theme.typography.pxToRem(12),
      padding: '0px 16px',
    },
  }),
);

interface Props {
  headerText?: string;
  bodyText?: string;
  children?: any;
}

const DetailedTooltip = ({ headerText, bodyText, children }: Props): JSX.Element => {
  return (
    //@ts-ignore
    <CustomTooltip
      key={1231244}
      //@ts-ignore
      placement="bottom"
      sx={{ backgroundColor: colors.white_01 }}
      title={
        <ToolTipContainer>
          {headerText ? <Header>{headerText}</Header> : <div style={{ margin: '8px 0px 0px' }} />}
          <Body>{bodyText}</Body>
        </ToolTipContainer>
      }
    >
      {children}
    </CustomTooltip>
  );
};

export default DetailedTooltip;
