import { Action, Thunk, action, thunk } from 'easy-peasy';
import { InfluenceProspectsModel } from './influenceProspects';
import { DiscoverLeadsModel } from './discoverLeads';
import { discoverLeads } from './discoverLeads';
import { influenceProspects } from './influenceProspects';

export interface DriveSalesModel {
  discoverLeads: DiscoverLeadsModel;
  influenceProspects: InfluenceProspectsModel;
}

export const driveSales: DriveSalesModel = {
  discoverLeads,
  influenceProspects,
};
