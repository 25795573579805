import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { formatNumber } from '../helpers/dataFormatter';
import { colors, typography } from '../styles';
import _, { cloneDeep } from 'lodash';
import EventChartPlaceholder from '../../images/event-chart-placeholder.png';

const useStyles = makeStyles((theme) => ({
  campaignInfoHeader: {
    ...typography.mainFont,
    fontSize: '14px',
    fontWeight: '600',
    color: colors.grey_03,
  },
  campaignInfoP: {
    ...typography.mainFont,
    fontSize: '12px',
    fontWeight: '400',
    color: colors.grey_02,
  },
  campaignInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
  },
}));

const defaultRange = [8, 24, 72];

interface Props {
  stats: any;
}

const ClickChart = ({ stats }: any) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ height: '10px', width: '100%', background: colors.grey_01, overflowX: 'hidden' }}>
        <div
          style={{ height: '10px', width: stats[0].value > 100 ? 100 : stats[0].value, background: colors.black_01 }}
        />
      </div>
      {stats.map((stat: any, index: number) => (
        <div
          key={index + 492304}
          className={classes.campaignInfoRow}
          style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
        >
          <p className={classes.campaignInfoP}>{stat.label}</p>
          <p className={classes.campaignInfoP}>{stat.value}</p>
        </div>
      ))}
    </>
  );
};

export default ClickChart;
