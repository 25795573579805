import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { USER_LOGOUT, oktaClient } from '../../api/auth';
import { PageContainer } from '../../components';
import Loader from '../../components/Loader';
import { apolloClient } from '../../api';
import { useMutation } from '@apollo/client';

type Props = {};

export const Logout = (props: Props) => {
  const navigate = useNavigate();
  const [logout] = useMutation(USER_LOGOUT, {
    onCompleted: (data) => {
      window.localStorage.clear();
      navigate('/login');
    },
    onError: (error) => {
      console.warn('error logging out: ', error);
      window.localStorage.clear();
      navigate('/login');
    },
  });

  useEffect(() => {
    const clearData = async () => {
      apolloClient.cache.reset();
      const refreshToken = localStorage.getItem('refreshToken');
      if (!!refreshToken) {
        logout({ variables: { refreshToken } });
      } else {
        await oktaClient.signOut({
          postLogoutRedirectUri: `${window.location.origin}/login`,
        });
        window.localStorage.clear();
      }
    };
    clearData();
  }, []);

  return (
    <PageContainer>
      <Loader />
    </PageContainer>
  );
};
