import React from 'react';
import { Column, Row, F3, F4 } from '..';
import { styled } from '@mui/material';
import { colors, typography } from '../../styles';
import ScrollingDiv from '../ScrollingDiv';
import { formatPackageName } from '../../helpers/dataFormatter';

import rightArrow from '../../assets/icons/arrow-right.svg';
import { SummaryType } from '../../types';
import { generateKey } from '../../helpers/numberGenerator';

type Props = {
  width?: number;
  summaryData: SummaryType[];
};

const ViewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  margin: '16px 0px 6px 0px',
});

const SummaryChip = styled('div')({
  height: '25px',
  width: '100%',
  background: colors.grey_02,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '25px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  fontSpacing: '0.1px',
  flex: 1,
});

const ProductChip = styled('div')({
  height: '29px',
  width: '100%',
  padding: '0px 14px',
  borderRadius: '5px',
  background: colors.white,
  color: colors.grey_02,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '29px',
  ...typography.mainFont,
  fontWeight: 400,
  fontSize: '14px',
  margin: '2px 8px 0px 0px',
  fontSpacing: '0.1px',
  cursor: 'pointer',
  // overflow: 'hidden',
  whiteSpace: 'nowrap',
  // textOverflow: 'ellipsis ellipsis'
});

const ProductContainer = styled('div')({
  display: 'flex',
  padding: '8px 16px',
  flex: 1,
  flexDirection: 'column',
  background: colors.grey_01,
  //height: '160px',
  borderRadius: '5px',
  margin: '8px 0px',
  //lineHeight: 'normal'
});

const ArrowIcons = styled('img')({
  height: 14,
  width: 14,
  margin: '9px 0px 0 0',
});

const SummaryText = styled(F3)({
  textAlign: 'left',
  '& span.highlight': {
    fontWeight: 600,
    color: colors.lilac,
  },
  '& span.bold': {
    fontWeight: 600,
    color: colors.grey_02,
  },
});

const RowTitle = styled(F4)({
  margin: '5px 0',
  fontWeight: 400,
  fontSize: '14px',
  whiteSpace: 'nowrap',
  minWidth: '70px',
  textAlign: 'left',
});

function SummaryView({ summaryData, width }: Props) {
  const [showMeMore, setShowMeMore] = React.useState<boolean>(false);

  const newSummaryData = showMeMore ? summaryData : summaryData?.slice(0, 2);
  const maxHeight = showMeMore ? 'auto' : '160px';

  return (
    <ViewContainer>
      <SummaryChip>AI SUMMARY</SummaryChip>
      <ProductContainer>
        {newSummaryData?.map((item: SummaryType, index: number) => {
          const itemValue = item?.value?.replaceAll(`'`, `\"`);
          const summaryData: string | string[] = item?.value.includes('[') ? JSON.parse(itemValue) : item?.value;
          return (
            <Column key={generateKey()}>
              {typeof summaryData === 'object' ? (
                <Column
                  style={{
                    borderBottom: `2px solid ${colors.grey_07}`,
                    padding: '10px 0px',
                    margin: '0px 0px',
                    // height: '80px',
                  }}
                >
                  <SummaryText dangerouslySetInnerHTML={{ __html: item?.key }} />
                  <ScrollingDiv
                    width={width ? width - 10 : null}
                    style={{ padding: '22px 0px 0px 0px', height: '30px' }}
                    backgroundColor={colors.grey_01}
                  >
                    {summaryData?.map((data: any) => (
                      <ProductChip
                        key={generateKey()}
                        style={{ margin: index === 0 ? '0px 24px 0px 0px' : '0px 8px 0px 0px' }}
                      >
                        {formatPackageName(data)}
                      </ProductChip>
                    ))}
                  </ScrollingDiv>
                </Column>
              ) : (
                <Row
                  style={{
                    borderBottom: `2px solid ${colors.grey_07}`,
                    padding: '10px 0px',
                    margin: '0px 0px',
                  }}
                >
                  <SummaryText dangerouslySetInnerHTML={{ __html: item?.value }} />
                </Row>
              )}
            </Column>
          );
        })}
        <Row
          style={{ color: colors.grey_03, justifyContent: 'space-between', padding: '8px 0px 0px', cursor: 'pointer' }}
          onClick={() => setShowMeMore(!showMeMore)}
        >
          <RowTitle sx={{ color: colors.grey_03 }}>Show me {showMeMore ? 'less' : 'more'}</RowTitle>
          <ArrowIcons
            src={rightArrow}
            style={{ transform: showMeMore ? 'rotate(270deg)' : 'rotate(90deg)' }}
            alt="arrows"
          />
        </Row>
      </ProductContainer>
    </ViewContainer>
  );
}

export default SummaryView;
