import { gql } from '../../__generated__';

export const GET_DISCOVER_ARCHIVES = gql(`
  query getDiscoverArchives($filters: [FilterType], $pageSize: Int, $page: Int) {
    discoverLeads {
      quotaUsed #Total prospects
      quotaTotal
    }
    journeys(filters: $filters, showAll: true, pageSize: $pageSize, page: $page) {
      page
      totalItems
      list {
        customName
        dateCreated
        dateUpdated
        id
        isActive
        package
        runhistorySet {
          id
          dateRun
          customName
          averageScore
          package
          serveCount
        }
        latestRun {
          id
          latestJob {
            dateCreated
            dateUpdated
            id
            processingDetails
            status
          }
        }
      }
    }
  }
`);

export const CREATE_JOURNEY = gql(`
  mutation createJourney(
    $benchmarkAdvisorSetIds: [ID]!
    $journeyName: String!
    $fundIds: [ID]!
    $numberOfLeads: Int!
    $allFunds: Boolean!
  ) {
    discoverLeadsCreateJourney(
      allFunds: $allFunds
      benchmarkAdvisorSetIds: $benchmarkAdvisorSetIds
      fundIds: $fundIds
      journeyName: $journeyName
      numberOfLeads: $numberOfLeads
    ) {
      traceback
      code
      success
      journey {
        id
        latestRun {
          id
          latestJob {
            processingDetails
            id
            dateUpdated
            dateCreated
            status
          }
        }
      }
    }
  }
`);

export const REFRESH_JOURNEY = gql(`
  mutation refreshJourney($journeyId: ID!) {
    discoverLeadsRefreshJourney(journeyId: $journeyId) {
      run {
        id
      }
      success
    }
  }
`);

export const UPDATE_JOURNEY = gql(`
  mutation refreshDiscoverJourney($journeyId: ID!, $isActive: Boolean, $integrationId: Int) {
         updateJourney(journeyId: $journeyId, integrationId: $integrationId, isActive: $isActive) {
           code
           success
           traceback
         }
       }
`);

export const EXPORT_RESULTS = gql(`
  mutation exportDiscoverResults($runId: ID!) {
    discoverLeadsResultsExport(runId: $runId) {
      url
      traceback
      success
    }
  }
`);

export const RETRY_PROCESSING_JOB = gql(`
  mutation retryProcessingJob($runId: ID!) {
    discoverLeadsRetryRun(runId: $runId) {
      success
      traceback
      run {
        id
      }
    }
  }
`);

export const GET_ADVISOR_SEGMENT = gql(`
  query getDiscoverAdvisorSegment($runId: Int!, $filters: [FilterType]) {
    discoverLeads {
      results(runId: $runId) {
        advisors(filters: $filters) {
          totalItems
        }
      }
    }
  }
`);

export const GET_RUN_INTEGRATIONS = gql(`
  query getDiscoverRunIntegrations($runId: Int!) {
    discoverLeads {
      results(runId: $runId) {
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
        journey {
          id
          integration {
            id
            dateUpdated
            dateCreated
            nickname
            status
            type
            sfintegrationauthdetailsSet {
              instanceUrl
            }
          }
          runhistorySet {
            id
            latestJob {
              status
              processingDetails
              id
              dateUpdated
              dateCreated
            }
          }
        }
      }
    }
  }
`);

export const GET_JOB_STATUS = gql(` 
  query getDiscoverExportJob($runId: Int!) {
    discoverLeads {
      results(runId: $runId, filters: []) {
        journey {
          # isDemo
          integration {
            id
            dateUpdated
            dateCreated
            nickname
            status
            type
          }
          latestRun {
            id
            latestJob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
          runhistorySet {
            id
            dateRun
            latestJob {
              status
              processingDetails
              id
              dateUpdated
              dateCreated
            }
            runhistoryprocessingjob {
              id
              dateCreated
              dateUpdated
              processingDetails
              status
            }
          }
        }
      }
    }
  }
`);

export const GET_RADAR_CHART_DATA = gql(`
  query getDiscoverRadarChart($runId: Int!, $advisorId: ID!) {
    discoverLeads {
      results(runId: $runId) {
        advisorRadarChart(advisorId: $advisorId)
      }
    }
  }
`);

export const GET_THEMES_RADAR_CHART = gql(`
  query getThemesRadarChart($runId: Int!) {
    discoverLeads {
      results(runId: $runId) {
        themesRadarChart
      }
    }
  }
`);

export const GET_EXPORT_JOB_STATUS = gql(`
  query getDiscoverExportJobStatus($runId: Int!) {
    discoverLeads {
      results(runId: $runId) {
        eventData
        # recentEvent
        ampScoresHistogram
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
      }
    }
  }
`);

export const GET_DISCOVER_RESULTS = gql(`
  query getResults(
    $runId: Int!
    $page: Int!
    $pageSize: Int!
    $sortKey: String!
    $query: String!
    $filters: [FilterType]!
  ) {
    discoverLeads {
      results(runId: $runId, filters: $filters) {
        eventData
        # recentEvent
        ampScoresHistogram
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
        advisors(page: $page, pageSize: $pageSize, sortKey: $sortKey, query: $query, filters: $filters) {
          totalItems
          page
          pages
          list {
            advisorExpertise
            advisor360Data
            age
            aumSelfReported
            careerTenure
            custodian1
            currentFirmTenureYears
            custodian2
            custodian3
            custodian4
            custodian5
            firmAvgAccountSize
            email
            firmCrd
            firmEtfAssetsMillions
            firmMfAssetsMillions
            firmName
            firmNumberBdReps
            firmNumberEmployees
            firmPercentAssetsEtf
            firmNumberIaReps
            firmPercentAssetsMf
            firmPercentClientsCharities
            firmPercentClientsCorporations
            firmTotalAccounts
            firmPercentClientsHnwIndividuals
            firmTotalAssetsInMillions
            firstName
            fullName
            id
            isBd
            isRia
            lastName
            networkToAdvisor
            officeState
            officeZipcode
            pathToEngage
            productsOfInterest
            purchaseLikelihood
            purchaseSize
            purchaseTiming
            rating
            recordUuid
            repcrd
            riaEmployeeRatio
            score
            scoreDetails
            title
            topThemes
            discoverleadsadvisorsummarySet {
              id
              key
              orderNumber
              type
              value
            }
          }
        }
        journey {
          isDemo
          latestRun {
            id
            latestJob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
          runhistorySet {
            id
            latestJob {
              status
              processingDetails
              id
              dateUpdated
              dateCreated
            }
            runhistoryprocessingjob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
        }
      }
    }
  }
`);
