import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../styles';
import ArchiveTable from '../../../components/Table';
import { TablePresets } from './tableSettings';
import { useStoreState } from '../../../store';
import { CardContainer, H2, F3, CardHeader, Row } from '../../../components';
import { HeaderTextButton } from '../../../components';
import { randomNumber } from '../../../helpers/numberGenerator';
import { MenuItem } from '@mui/material';
import CampaignCard from '../../../components/CampaignCard';
import { styled } from '@mui/material/styles';
import { CampaignAmpScoreCard } from '../../../components/CampaignAmpScoreCard';
import { GET_CAMPAIGNS } from '../../../api/nurtureProspects';
import { GET_ARCHIVES } from '../../../api/driveSales';
import { useQuery } from '@apollo/client';
import Loader from '../../../components/Loader';

const PageBody = styled('div')({
  overflowY: 'scroll',
  height: '100%',
  padding: '0px 20px',
});

const HeaderContainer = styled('div')({
  margin: '0px',
});
const BodyContainer = styled('div')({
  margin: '0px auto 20px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});
const TableHeader = styled('div')({
  height: '50px',
  padding: '0px 32px',
  backgroundColor: colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0px 0px',
  borderBottom: `1px solid ${colors.grey_01}`,
  zIndex: '500',
});

const ScoreContainer = styled('div')({
  display: 'flex',
  height: '40px',
  width: '140px',
  alignItems: 'center',
  padding: '5px 14px',
  borderRadius: '5px',
  margin: '3px',
  backgroundColor: colors.grey_06,
  fontWeight: '900',
  fontSize: '24px',
  color: colors.grey_03,
  whiteSpace: 'nowrap',
  cursor: 'default',
});

const InfoBadge = styled('div')({
  display: 'flex',
  height: '25px',
  alignItems: 'center',
  padding: '5px 14px',
  borderRadius: '5px',
  margin: '3px',
  fontSize: '14px',
  backgroundColor: colors.grey_06,
  color: colors.grey_02,
  whiteSpace: 'nowrap',
  cursor: 'default',
});

const DetailsButton = styled('button')({
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: 5,
  height: 40,
  background: 'white',
  border: `1px solid ${colors.black_01}`,
  color: colors.black_01,
  fontWeight: 400,
  fontSize: 14,
  cursor: 'pointer',
  lineHeight: 1,
  textDecoration: 'none',
  '&:hover': {
    background: colors.black_01,
    color: colors.white_01,
    border: `1px solid ${colors.black_01}`,
  },
  // [theme.breakpoints.down(1170)]: {
  //   fontSize: 13,
  //   padding: '7px 0px',
  // },
});

const CampaignMenuItem = styled(MenuItem)({
  fontFamily: 'Inter',
  fontSize: '16px',
  color: colors.black_01,
});

const EmptyCard = styled('div')({
  flex: 1,
  minWidth: '200px',
});

interface Props {
  setSelectedCampaign?: any;
  resultsPage: string;
  setResultsPage: any;
}

export const ScoreImpactView = ({ setSelectedCampaign, resultsPage, setResultsPage }: Props) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [emptyCards, setEmptyCards] = useState<any>(null);

  const [focusedCampaignEngagement, setFocusedCampaignEngagement] = useState(null);

  const showDemoData = useStoreState((state) => state.user.demoAccount);

  const [sortKey, setSortKey] = useState('date_created');

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  useEffect(() => {
    fetchData(searchString);
    setTablePage(0);
  }, [searchString, sortKey]);

  useEffect(() => {
    //@ts-ignore
    if (TablePresets?.[resultsPage]?.apiPagination) {
      fetchData(searchString);
    }
  }, [tablePage, pageSize]);

  // const fetchData = async (searchTerm: any) => {
  //   try {
  //     setLoading(true);
  //     //@ts-ignore
  //     // console.log('fetching data in score impact: ', resultsPage, TablePresets?.[resultsPage]);
  //     const resultFromGetData = await TablePresets?.[resultsPage]?.getData(
  //       tablePage + 1,
  //       pageSize,
  //       searchTerm,
  //       sortKey,
  //     );

  //     console.log('resultFromGetData: ', resultFromGetData);

  //     setLoading(false);
  //   } catch (error: any) {
  //     console.warn('Error fetching Archive: ', error?.message);
  //     setLoading(false);
  //   }
  // };

  const {
    data: influenceArchives,
    loading: influenceLoading,
    error: influenceError,
  } = useQuery(GET_ARCHIVES, {
    variables: {
      pageSize,
      page: tablePage + 1,
      filters: [{ key: 'package', value: 'INFLUENCE' }],
    },
  });
  const {
    data: discoverArchives,
    loading: discoverLoading,
    error: discoverError,
  } = useQuery(GET_ARCHIVES, {
    variables: {
      pageSize,
      page: tablePage + 1,
      filters: [{ key: 'package', value: 'DISCOVER_LEADS' }],
    },
  });

  const { data: nurtureCampaigns, loading: loadingNurture } = useQuery(GET_CAMPAIGNS, {
    variables: {
      pageSize,
      page: tablePage + 1,
      query: searchString,
      sortKey: sortKey,
      filters: [{ key: 'status', value: 'DRAFT' }],
    },
    onCompleted: (data: any) => {
      // setDraftTotal(data?.nurtureProspects?.campaigns.totalItems);
    },
    onError: (error: any) => {
      console.warn('Error fetching Archive: ', error);
    },
  });

  useEffect(() => {
    setTablePage(0);
    fetchData('');
  }, [resultsPage, influenceArchives, discoverArchives, nurtureCampaigns]);

  const fetchData = async (searchTerm: any) => {
    try {
      setLoading(true);
      //@ts-ignore
      let dataList = [];
      let newDataTotal = 0;

      console.log('results page: ', resultsPage);

      if (resultsPage === 'influence') {
        dataList = influenceArchives?.journeys?.list ?? [];
        newDataTotal = influenceArchives?.journeys?.totalItems ?? 0;
      } else if (resultsPage === 'discoverLeads') {
        dataList = discoverArchives?.journeys?.list ?? [];
        newDataTotal = discoverArchives?.journeys?.totalItems ?? 0;
      } else {
        dataList = nurtureCampaigns?.nurtureLeads?.campaigns?.list ?? [];
        newDataTotal = nurtureCampaigns?.nurtureLeads?.campaigns?.totalItems ?? 0;
      }

      dataList = dataList?.map((dataItem: any) => {
        const historySetLength = dataItem?.runhistorySet?.length;

        let currentAverageScore = dataItem?.runhistorySet?.[historySetLength - 1]?.averageScore
          ? dataItem?.runhistorySet?.[historySetLength - 1]?.averageScore
          : 0;
        let previousAverageScore = dataItem?.runhistorySet?.[historySetLength - 2]?.averageScore
          ? dataItem?.runhistorySet?.[historySetLength - 2]?.averageScore
          : currentAverageScore;

        let averageScoreIncrease = currentAverageScore - previousAverageScore;

        // console.log({
        //   ...dataItem,
        //   averageScoreIncrease,
        //   currentAverageScore,
        //   previousAverageScore,
        //   // openRate,
        // });

        if (showDemoData) {
          averageScoreIncrease = randomNumber(10, 8);
          previousAverageScore = currentAverageScore - averageScoreIncrease;
        }

        let clickThroughRate: any = randomNumber(0.177, 0.378);
        let openRate: any = randomNumber(0.177, 0.378);

        return {
          ...dataItem,
          averageScoreIncrease,
          currentAverageScore,
          previousAverageScore,
          // clickThroughRate,
          // openRate,
        };
      });

      // console.log('datalist in nuture outcomes: ', dataList);

      setData(dataList);
      if (dataList.length < 3) {
        const newEmptyCards = Array.from(Array(3 - dataList.length).keys());
        setEmptyCards(newEmptyCards);
      } else {
        setEmptyCards(null);
      }

      // setDataTotal(resultFromGetData.totalItems);
      setDataTotal(newDataTotal);

      setLoading(false);
    } catch (error) {
      console.warn('Error fetching Archive: ', error);
      setLoading(false);
    }
  };

  const openEngagementDetails = async (Archive: any) => {
    try {
      setFocusedCampaignEngagement(Archive);
    } catch (error: any) {
      console.log('error opening archive:', error?.message);
    }
  };

  const selectCampaign = (newCampaign: any) => {
    setSelectedCampaign(newCampaign);
  };

  const dataType = resultsPage === 'discoverLeads' || resultsPage === 'influence' ? 'Journeys' : 'Campaigns';

  const tableHeaders = [
    //{ text: 'Individuals', onPress: setDataType },
    {
      text: dataType,
      key: 'nurtureCampaigns',
      onPress: () => console.log('nurture stuff'),
      packageName: 'NURTURE_CAMPAIGNS',
    },
  ];

  console.log('data in score impact view:', data, tableHeaders);

  return (
    <PageBody>
      {loading || discoverLoading || influenceLoading || loadingNurture ? (
        <Loader inputMargin={'100px 46%'} />
      ) : data?.length > 0 ? (
        <>
          <HeaderContainer>
            {/* <h1 className={classes.mainHeader}>{packages[archiveType].title} Archive</h1> */}
            <F3 sx={{ margin: '30px 0px', color: colors.grey_02 }}>
              {`See below the impact of optimizing algorithms on your AMP scores.`}
            </F3>
          </HeaderContainer>
          <CampaignAmpScoreCard
            id={'campaign-score-card'}
            campaigns={data}
            selectCampaign={selectCampaign}
            loading={loading}
            type={dataType}
          />
          <CardContainer style={{ margin: '10px 0px' }}>
            <CardHeader>Top {dataType} by Improvement in AMP Score</CardHeader>
            <Row>
              {loading ? (
                <Loader inputMargin={'100px 46%'} />
              ) : (
                <>
                  {data
                    ?.slice(0, 3)
                    .map((campaignSlice, index: number) => (
                      <CampaignCard
                        campaign={campaignSlice}
                        selectCampaign={selectCampaign}
                        id={`campaign-card-${index.toString()}`}
                        showBorders={index === 1}
                      />
                    ))}
                  {emptyCards?.map((item: any, index: number) => {
                    return (
                      <EmptyCard
                        style={
                          emptyCards.length > 1 && index === 0
                            ? { borderLeft: `1px solid ${colors.grey_01}`, borderRight: `1px solid ${colors.grey_01}` }
                            : {}
                        }
                      />
                    );
                  })}
                </>
              )}
            </Row>
          </CardContainer>
          {data.slice(3).length > 0 && (
            <BodyContainer>
              <TableHeader>
                <div>
                  {tableHeaders.map((item: any, index) => (
                    <HeaderTextButton
                      key={index + 342421}
                      id={`table-header-button-${item.text.replace(' ', '-')}`}
                      onClick={() => item.onPress(item.key ? item.key : item.text)}
                      style={{ fontWeight: '500', color: colors.black }}
                      children={item.text}
                      disabled={loading}
                    />
                  ))}
                </div>
              </TableHeader>
              <ArchiveTable
                //@ts-ignore
                headerData={TablePresets?.[resultsPage]?.headerData}
                data={data.slice(3)}
                hideShadow={true}
                dataControls={{
                  selections: {},
                  setSelections: () => {},
                  searchFunc: setSearchString,
                  searchString,
                  searchPlaceholder: 'Search for a journey...',
                }}
                // initSortColumn={sortKey}
                paginationControls={
                  //@ts-ignore
                  TablePresets?.[resultsPage]?.apiPagination
                    ? {
                        tablePage,
                        setTablePage,
                        pageSize,
                        setPageSize,
                        totalItems: dataTotal,
                        sortKey,
                        setSortKey,
                      }
                    : null
                }
                search={resultsPage !== 'archivedResults'}
                loading={loading}
                //@ts-ignore
                setRating={TablePresets?.[resultsPage]?.setRating}
                onRowPress={resultsPage === 'scoreImpact' ? selectCampaign : openEngagementDetails}
                newItems={[]}
              />
            </BodyContainer>
          )}
        </>
      ) : (
        <>
          <CardContainer style={{ padding: '44px 26px', margin: '30px 0px 30px 0px' }}>
            <H2 style={{ margin: '8px 0px 8px', textAlign: 'center' }}>No Journeys Found</H2>
            <F3 style={{ color: colors.grey_02, margin: '8px 0px 0px', textAlign: 'center' }}>
              Start a campaign to see their impact here.
            </F3>
          </CardContainer>
        </>
      )}
    </PageBody>
  );
};
