import React, { useState, useEffect } from 'react';
// import IndividualLeadsAPI from '../../../api/driveSales/individualLeadsAPI';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../../styles';
import ClientModal from '../../../components/ClientModal';
import Loader from '../../../components/Loader';
// import ModalComponent from '../../../../Components/Modal';
import ComingSoonModal from '../../../components/ComingSoonModal';
import FilterHeader from '../../../components/FilterHeader';
import PageHeader from '../../../components/PageHeader';
import GeneralTable from '../../../components/Table';
import TopRankedCards from '../../../components/TopRankedCards';
import FeedbackModal from '../../../components/FeedbackModal';
import { exportClientsToSpreadsheet } from '../../../helpers/exportClients';
import { useStoreActions, useStoreState } from '../../../store';
import { states, age, income, estimatedInvestableAssets } from '../../../helpers/menuOptions';
import { H3 } from '../../../components';
import { formatErrorTitle, formatErrorBody } from '../../../helpers/dataFormatter';
import { individualSpreadsheetHeader } from '../../../helpers/exportHeaders';
import LoadingModal from '../../../components/LoadingModal';
import LoadingPage from '../../../components/LoadingPage';
import DownloadModal from '../../../components/DownloadModal';
// import RadarCharts from '@tifin-ui/radar-charts';
import RadarCharts from '../../../components/RadarCharts';

//images
import closeOut from '../../../assets/icons/close.svg';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { GET_INDIVIDUAL_LEADS_RESULTS, GET_RUN_INDIVIDUALS } from '../../../api/driveSales/individualLeadsAPI';

const adjustParams = [
  {
    label: 'Age',
    options: age,
    width: '90px',
  },
  {
    label: 'Income',
    options: income,
    width: '120px',
  },
  {
    label: 'Wealth',
    options: estimatedInvestableAssets,
    width: '120px',
  },
  {
    label: 'Location',
    options: states,
    width: '74px',
  },
];

const tableHeaders = [
  {
    header: 'AMP Score',
    key: 'score',
    big: true,
    bold: true,
  },
  {
    header: 'Name',
    key: 'lastName',
  },
  {
    header: 'Age',
    key: 'age',
  },
  {
    header: 'State',
    key: 'state',
  },
  {
    header: 'Income',
    key: 'income',
    type: 'dollar',
  },
  {
    header: 'Wealth',
    key: 'estimatedInvestableAssets',
    type: 'dollar',
  },
  {
    header: 'Mortgage Paid',
    key: 'percentOfHomeValuePaidOff',
    type: 'percent',
  },
];

const SpreadSheetHeaders = [
  {
    header: 'Fund',
    key: 'fundId',
  },
  ...individualSpreadsheetHeader,
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    margin: '0px auto 0px auto',
    overflow: 'hidden',
    padding: '0 0 40px',
  },
  headerStyle: {
    boxShadow: colors.main_box_shadow,
    borderRadius: '5px',
    zIndex: 2000,
  },
  pageBody: {
    overflowY: 'scroll',
    height: '88%',
    padding: '10px 50px 0px 30px',
    margin: 0,
  },
  topRankedCards: {
    //width: '95%',
    margin: '0px auto 0px auto',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    position: 'relative',
    'z-index': 6,
  },
  tableContainer: {
    margin: '30px 0px 0',
  },
  prospectModal: {
    width: '40%',
    margin: '160px auto',
    padding: '10px 0',
    background: 'white',
    boxShadow: colors.main_box_shadow,
  },
  errorTitle: {
    ...typography.mainFont,
    fontSize: '24px',
    color: colors.grey_03,
  },
  errorBody: {
    ...typography.mainFont,
    fontSize: '16px',
    color: colors.grey_02,
  },
  modalHeader: {
    width: '100%',
    borderBottom: '1px solid #C4C4C4',
  },
  headers: {
    fontSize: 24,
    fontWeight: 400,
    color: '#404B50',
    padding: 0,
  },
  innerModalHeader: {
    display: 'flex',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
  },
  leadButton: {
    color: 'white',
    fontWeight: 300,
    display: 'flex',
    padding: '10px 40px',
    background: 'linear-gradient(90deg, #5B8CFD 0%, #2C4DC3 100%)',
    height: 'fit-content',
    margin: '10px auto',
    lineHeight: 1,
    borderRadius: 30,
    fontSize: 12,
    // border: `1px solid ${theme.palette.primary.light}`,
    '&:hover': {
      //   border: `1px solid ${theme.palette.primary.light}`,
      background: 'white',
      //   color: theme.palette.primary.dark,
    },
    '&:disabled': {
      color: 'grey',
      background: 'white',
      borderColor: 'grey',
    },
  },
  errorDiv: {
    borderRadius: '5px',
    width: '95%',
    height: 300,
    margin: '10px auto',
    padding: '20px 0px',
    background: '#fff',
    display: 'flex',
    boxShadow: colors.main_box_shadow,
    textAlign: 'center',
  },
  cardHeaderStyle: {
    width: '95%',
    margin: 'auto',
    fontSize: 24,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardExports: {
    display: 'flex-end',
    marginRight: 10,
  },
  exportButtons: {
    borderRadius: 5,
    margin: '25px 0px 0 40px',
    padding: '0 20px',
    color: colors.black,
    background: 'white',
    border: `1px solid ${colors.black}`,
    height: 40,
    cursor: 'pointer',
    '&:hover': {
      color: 'white',
      background: colors.black,
    },
    // [theme.breakpoints.up('lg')]: {
    //   margin: '25px 0px 0 20px',
    // },
    // [theme.breakpoints.down('md')]: {
    //   margin: '25px 0px 0 5px',
    // },
    // [theme.breakpoints.down(1073)]: {
    //   margin: '5px 0px 0 5px',
    // },
  },
  resultsBox: {
    boxShadow: colors.main_box_shadow,
    borderRadius: '5px',
    margin: '-10px 0px 30px',
    backgroundColor: colors.white,
    position: 'relative',
    'z-index': 6,
  },
  resultsHeader: {
    color: colors.grey_03,
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    margin: 0,
    padding: '0px 0px 0px 20px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  expandIcon: {
    marginRight: 40,
    width: 20,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  titleStyle: {
    fontSize: 16,
    fontWeight: 400,
    display: 'flex',
    color: colors.grey_03,
  },
  fundSelectTitle: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    display: 'flex',
    color: colors.grey_03,
  },
  fundSelectContainer: {
    margin: '20px auto 10px',
    width: '90%',
    display: 'flex',
  },
  clientCardContainer: {
    background: '#fff',
    width: '90%',
    margin: 'auto',
    display: 'flex',
  },
}));

const metricParser = (input: any) => {
  switch (input) {
    case 'Consumer':
      return 'consumer';
    case 'ESG':
      return 'esg';
    case 'Financials':
      return 'financials';
    case 'Other':
      return 'other';
    case 'Real Estate':
      return 'real_estate';
    case 'Tech':
      return 'tech';
  }
};

interface Props {
  featureSelections: any;
  setFeatureSelections: any;
  textData: any;
  getRadarChartData: any;
  radarFeatures: any;
  filterOptions: any;
  setFilterOptions: any;
  generateError: any;
  fundsSelected: any;
  firstTime: boolean;
  setFirstTime: any;
  runId: number;
}

const LeadResults = ({
  featureSelections,
  setFeatureSelections,
  textData,
  getRadarChartData,
  radarFeatures,
  fundsSelected,
  filterOptions,
  setFilterOptions,
  runId,
  firstTime,
  setFirstTime,
  generateError,
}: Props) => {
  const classes = useStyles();

  const [data, setData] = useState<any>(null);
  const [taggedFunds, setTaggedFunds] = useState<string[] | null>(null);

  const [topDataForFund, setTopDataForFund] = useState<any>(null);
  const [dataForFund, setDataForFund] = useState<any>(null);

  const [selectedClient, setSelectedClient] = useState<any>(null);

  // const [toggleItems, setToggleItems] = useState([]);
  // const [selectedFields, setSelectedFields] = useState<any>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [showComingSoon, setComingSoonOpen] = useState(false);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [twoSecondTimeout, setTwoSecondTimeout] = useState(false);

  const [showRadar, setShowRadar] = useState(true);
  const [showTopRanked, setShowTopRanked] = useState(true);
  const [selectedFund, setSelectedFund] = useState<any>({});
  const [expandedModal, setExpandedModal] = useState(false);
  const [radarFundData, setRadarFundData] = useState({});
  const [showExportModal, setShowExportModal] = useState(false);
  const [nurtureStatus, setNurtureStatus] = useState('start');
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const [investorQuery, setInvestorQuery] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [totalItems, setTotalItems] = useState(0);
  const [sortKey, setSortKey] = useState('-score');

  const clientCount = 10;
  const userInfo = useStoreState((state) => state.user.info);

  const sfIntegrationData = useStoreState((state) => state.integrations.sfIntegrationData);

  useEffect(() => {
    setTwoSecondTimeout(true);
    setTimeout(() => setTwoSecondTimeout(false), firstTime ? 2000 : 500);
    setFirstTime(false);
    setLoading(true);
  }, []);

  useEffect(() => {
    if (runId) {
      debounce(loadTableData(), 500);
    }
  }, [page, pageSize, investorQuery]);

  function debounce(func: any, delay: number) {
    let timeoutId: string | number | NodeJS.Timeout | undefined;

    return (...args: any) => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        //@ts-ignore
        func.apply(this, args);
      }, delay);
    };
  }

  useEffect(() => {
    setError(generateError);
    if (generateError) {
      setLoading(false);
    }
  }, [generateError]);

  useEffect(() => {
    setTaggedFunds(Object.keys(fundsSelected));
    if (fundsSelected && Object.values(fundsSelected).length > 0) {
      setSelectedFund(Object.values(fundsSelected)[0]);
    }
  }, [fundsSelected]);

  const apiFilters = Object.values(filterOptions).flatMap((item: any) => item.value);

  const [downloadAllLeads, { loading: loadingCSV }] = useLazyQuery(GET_RUN_INDIVIDUALS, {
    onCompleted: (data) => {
      const currentSpreadsheetHeaders = SpreadSheetHeaders;
      exportClientsToSpreadsheet(
        data?.investorLeads?.results?.allInvestors,
        currentSpreadsheetHeaders,
        fundsSelected,
        'IndividualLeads',
      );
    },
  });

  const { data: resultsData, loading: loadingResults } = useQuery(GET_INDIVIDUAL_LEADS_RESULTS, {
    variables: {
      filters: apiFilters,
      runId: runId,
      fundId: selectedFund?.id,
      page: 1,
      pageSize: pageSize,
      sortKey: '-score',
    },
    onCompleted: (data) => {
      setData(data?.investorLeads?.results?.investors?.list);
      let parsedData = [];
      parsedData = Object.entries(data?.investorLeads?.results.themesRadarChart)
        .map((item: any) => ({ name: item[0], ...item[1] }))
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      setRadarFundData(parsedData);

      setTopDataForFund(
        data?.investorLeades?.results?.investors?.list?.slice(0, 3)
          ? data?.investorLeades?.results?.investors?.list?.slice(0, 3)
          : [],
      );
      setDataForFund(
        data?.investorLeads?.results?.investors?.list?.slice(3)
          ? data?.investorLeades?.results?.investors?.list?.slice(3)
          : [],
      );
      setTotalItems(data?.investorLeads?.results.investors?.totalItems);
      if (data?.investorLeads?.results?.investors?.length === 0) {
        setError('No Results');
      } else if (data?.investorLeads?.results?.investors?.[0]?.latestScore) {
        setNurtureStatus('results');
      }
    },
  });

  const [loadTableData, { data: tableData, loading: tableLoading }] = useLazyQuery(GET_INDIVIDUAL_LEADS_RESULTS, {
    variables: {
      filters: apiFilters,
      runId,
      fundId: selectedFund.id,
      page: page + 1,
      pageSize,
      sortKey: '-score',
      query: investorQuery,
    },
    onCompleted: (data) => {
      if (page === 0) {
        setDataForFund(data?.investorLeads?.results.investors?.list?.slice(3) ?? []);
      } else {
        setDataForFund(data?.investorLeads?.results.investors?.list ?? []);
      }
    },
  });

  const distillDescription = () => {
    return (
      <div className={classes.prospectModal}>
        <div className={classes.modalHeader}>
          <div className={classes.innerModalHeader}>
            <h2 className={classes.headerStyle}>AMP Score</h2>
            <img
              style={{ width: 14, marginRight: 10, cursor: 'pointer' }}
              onClick={() => setModalOpen(false)}
              src={closeOut}
              alt="close"
            />
          </div>
        </div>
        <p style={{ width: '90%', margin: 'auto', padding: '10px 0', fontSize: 18 }}>
          The <b>AMP Score</b> is a synthesis of the signals from our proprietary algorithm. It harnesses our Data
          Signals to determine which of our investors would be the best for your funds.
        </p>
        <button className={classes.leadButton} children="OK, got it!" onClick={() => setModalOpen(false)} />
      </div>
    );
  };

  const cardProps = {
    modalOpen,
    setModalOpen,
    contentType: 'individualData',
    packageName: 'individualLeads',
    getRadarChartData,
    close: () => setSelectedClient(undefined),
    radarFeatures,
    textData,
    userData: userInfo,
    //fundChart: true,
  };

  const expanderFunc = (card: any) => {
    setSelectedClient(card);
    setExpandedModal(true);
  };

  const radarFields = {
    fields: {
      first: {
        name: 'Fund',
        dataKey: 'fund',
      },
      second: {
        name: 'Investors',
        dataKey: 'investors',
      },
    },
    labels: {
      first: selectedFund.ticker,
      second: 'Top Leads',
      third: 'Overlap',
    },
  };

  return (
    <>
      {loading || twoSecondTimeout || (!runId && !generateError) ? (
        <LoadingPage />
      ) : (
        <div className={classes.container}>
          <div className={classes.headerStyle}>
            <PageHeader
              title={'Your best leads for'}
              funds={fundsSelected}
              selectedFund={selectedFund}
              setSelectedFund={setSelectedFund}
              rightButtons={[
                {
                  modalText: 'Export',
                  type: 'light',
                  action: () => setShowDownloadModal(true),
                },
                {
                  modalText: 'Adjust Feedback Loop',
                  type: 'light',
                  action: () => setShowFeedbackModal(true),
                },
              ]}
            />
            <FilterHeader
              featureSelections={featureSelections}
              setFeatureSelections={setFeatureSelections}
              filterOptions={filterOptions}
              setFilterOptions={setFilterOptions}
              adjustParams={adjustParams}
              //   clientCount={clientCount}
              options={null}
            />
          </div>
          {error ? (
            <div className={classes.pageBody}>
              <div className={classes.errorDiv}>
                <div style={{ margin: 'auto', width: '100%', padding: '36px' }}>
                  <h1 className={classes.errorTitle}>{formatErrorTitle(error)}</h1>
                  <p className={classes.errorBody}>{formatErrorBody(error)}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.pageBody}>
              <RadarCharts
                radarFundData={radarFundData}
                radarFields={radarFields}
                showRadar={showRadar}
                setShowRadar={setShowRadar}
                textData={textData}
              />
              <div className={classes.resultsBox} id={'client-card-container'}>
                <div
                  className={classes.resultsHeader}
                  style={{ borderBottom: showTopRanked ? '1px solid #e3e5e4' : 0 }}
                  onClick={() => setShowTopRanked(!showTopRanked)}
                >
                  <H3>Top Leads</H3>
                </div>
                <div className={classes.topRankedCards}>
                  {showTopRanked ? (
                    <TopRankedCards
                      packageName={'individualLeads'}
                      expanderFunc={expanderFunc}
                      cardData={topDataForFund}
                      cardProps={cardProps}
                      selectedClient={selectedClient}
                    />
                  ) : null}
                  {selectedClient !== undefined && (
                    <ClientModal
                      client={{
                        ...selectedClient,
                        leadScore: selectedClient?.score || selectedClient?.score === 0 ? selectedClient?.score : '',
                      }}
                      expandedModal={expandedModal}
                      //   setExpandedModal={setExpandedModal}
                      {...cardProps}
                      cardProps={cardProps}
                    />
                  )}
                </div>
              </div>
              <div className={classes.tableContainer}>
                {dataForFund && taggedFunds && selectedFund ? (
                  <GeneralTable
                    // hideShadow={true}
                    headerData={tableHeaders}
                    data={dataForFund}
                    // initSortColumn={'-score'}
                    search
                    dataControls={{
                      selections: [],
                      setSelections: [],
                      searchFunc: setInvestorQuery,
                      investorQuery,
                    }}
                    paginationControls={{
                      tablePage: page,
                      setTablePage: setPage,
                      pageSize,
                      setPageSize,
                      sortKey,
                      setSortKey,
                      totalItems,
                    }}
                    onRowPress={expanderFunc}
                    newItems={[]}
                    loading={tableLoading}
                  />
                ) : (
                  <Loader />
                )}
                <div style={{ height: '120px' }} />
              </div>
            </div>
          )}
          <ComingSoonModal modalStatus={showComingSoon} closeModal={() => setComingSoonOpen(false)} />
          <FeedbackModal
            // packageName={'INVESTOR_LEADS'}
            modalStatus={showFeedbackModal}
            // API={IndividualLeadsAPI}
            closeModal={() => setShowFeedbackModal(!showFeedbackModal)}
          />
          <DownloadModal
            modalInfo={{
              header: 'Export',
              body: 'Please select one of the following options',
              actionExport: () => {
                setShowExportModal(true);
                setShowDownloadModal(false);
              },
              actionDownload: () => {
                downloadAllLeads();
                setShowDownloadModal(false);
              },
              actionText: '',
            }}
            modalStatus={showDownloadModal}
            closeModal={() => setShowDownloadModal(!showDownloadModal)}
          />
          <LoadingModal
            modalStatus={loadingCSV}
            closeModal={() => {}}
            headerText={'Download all your leads'}
            subtext={'This may take a few moments...'}
            buttonText={'Cancel'}
          />
        </div>
      )}
    </>
  );
};

export default LeadResults;
