import { styled } from '@mui/material/styles';
import { colors, typography } from '../styles';
import { keyframes } from '@mui/system';
import { IconButton, TextField, MenuItem } from '@mui/material';
import { FormControl } from '@mui/material';
import Modal from '@mui/material/Modal';
import { BorderColor } from '@mui/icons-material';

const fade = keyframes`
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
`;

export const PageContainer = styled('div')`
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${colors.gradient_01};
`;

export const ModalRoot = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0px 0px 0px;
`;

export const ModalContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  width: '500px',
  // height: '200px',
  backgroundColor: colors.white,
  //paddingBottom: 120,
  borderRadius: '5px',
});

export const CloseButton = styled(IconButton)({});

export const ContentContainer = styled('div')`
  height: 100vh;
  width: 100%;
  overflow: auto;
`;

export const ContentHeader = styled('div')`
  display: table;
  width: 100%;
  padding: 10px 0 60px;
  background: ${colors.gradient_01};
`;

export const FadeContainer = styled('div')`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // animation: ${fade} 0.5s ease;
  // transition: 1s ease;
`;

export const ContentTitle = styled('h1')`
  text-align: center;
  width: 100%;
  letter-spacing: -5px;
  font-size: 50px;
  height: 50px;
  font-weight: 600;
  line-height: 60px;
  margin: 0 auto;
  ${typography.main_font};
  color: ${colors.grey_03};
`;

export const ContentSubTitle = styled('h2')`
  text-align: center;
  letter-spacing: -0.09em;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  height: 24px;
  margin: 0 auto;
  ${typography.main_font};
  color: ${colors.grey_03};
`;

export const ContentDescription = styled('p')`
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 20px auto 0;
  color: ${colors.grey_02};
  ${typography.main_font};
`;

export const PageTitle = styled('h1')`
  text-align: center;
  letter-spacing: -9%;
  font-size: 50px;
  height: 50px;
  font-weight: 600;
  line-height: 60px;
  ${typography.main_font};
`;

export const PageSubtitle = styled('h2')`
  text-align: center;
  width: 900px;
  letter-spacing: -2px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  height: 24px;
  ${typography.main_font};
`;

export const PageDescription = styled('p')`
  text-align: center;
  width: 500px;
  font-size: 14px;
  font-weight: 400;
  color: ${colors.grey_02};
  ${typography.main_font};
`;

export const PrimaryButton = styled('button')`
    margin: 20px 0;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    background: ${colors.black_01};
    border: 2px solid ${colors.black_01};
    color: white;
    height: 40;
    font-weight: 500;
    font-size: 14;
    :hover {
      opacity: 0.7
    },
    :disabled {
      opacity:0.6;
      background: ${colors.grey_02};
      color: ${colors.white_01};
      padding: 9px 20px;
      border: 1px solid ${colors.grey_02};
    },
`;

export const ErrorTitle = styled('p')({
  ...typography.mainFont,
  fontWeight: 600,
  margin: '32px 0px 0px',
  fontSize: '24px',
  color: colors.grey_03,
});

export const ErrorBody = styled('p')({
  ...typography.mainFont,
  fontSize: '16px',
  color: colors.grey_02,
});

export const SecondaryButton = styled('button')`
    margin: 20px 0;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    width: 100%;
    background: ${colors.white_01}00;
    border: 1px solid ${colors.black_01};
    color: ${colors.black_01};
    height: 40;
    font-weight: 500;
    font-size: 14;
    :hover {
      opacity: 0.7
    },
    :disabled {
      background: ${colors.grey_02};
      color: ${colors.white_01};
      padding: 9px 20px;
      border: 1px solid ${colors.grey_02};
    },
`;

export const PrimaryButtonShort = styled('button')`
  padding: 0px 20px;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  background: ${colors.black_01};
  border: 2px solid ${colors.black_01};
  color: white;
  height: 30px;
  font-weight: 400;
  font-size: 14;
  :hover {
    opacity: 0.7;
  }
  :disabled {
    opacity: 0.5;
  }
`;

export const SecondaryButtonShort = styled('button')`
    padding: 0px 20px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    background: ${colors.grey_02};
    border: 2px solid ${colors.grey_02};
    color: white;
    height: 30px;
    font-weight: 400;
    font-size: 14;
    :hover {
      opacity: 0.7;
    },
    :disabled {
      opacity: 0.5;
      // background: ${colors.grey_02};
      // color: ${colors.white_01};
      // padding: 9px 20px;
      // border: 1px solid ${colors.grey_02};
    },
`;

export const BlackDivider = styled('div')`
  margin: 0px;
  background: ${colors.black};
  height: 40px;
  width: 100%;
`;

export const ZoomOutButton = styled('div')`
  margin: 20px 0px;
  ${typography.header_03};
  color: ${colors.grey_02};
  cursor: pointer;
  z-index: 100000;
`;

export const MainTextInput = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  fontFamily: 'Inter',
  backgroundColor: colors.white,
  '& label': {
    fontFamily: 'Inter',
    fontSize: '15px',
  },
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Inter',
    '& fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
  },
});

export const FreeTextArea = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  fontFamily: 'Inter',
  backgroundColor: colors.white,
  fontSize: '12px',
  borderRadius: '5px',
  '& label': {
    fontFamily: 'Inter',
    fontSize: '15px',
  },
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Inter',
    fontSize: '13px',
    color: colors.grey_03,
    borderColor: colors.white,
    borderRadius: '5px',
    '& fieldset': {
      borderColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
    '&.Mui-hover fieldset': {
      borderColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
  },
});

export const FreeTextInput = styled(TextField)({
  width: '100%',
  fontFamily: 'Inter',
  backgroundColor: colors.white,
  fontSize: '12px',
  borderRadius: '5px 0px 5px 0px',
  '& label': {
    fontFamily: 'Inter',
    fontSize: '15px',
  },
  '& label.Mui-focused': {
    color: colors.white,
    BorderColor: colors.white,
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Inter',
    fontSize: '14px',
    padding: '4px 4px',
    color: colors.grey_03,
    borderColor: colors.white,
    borderRadius: '5px',
    '& fieldset': {
      borderColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
    '&.Mui-hover fieldset': {
      borderColor: colors.blue,
      border: `1px solid ${colors.white}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.white,
      border: `1px solid ${colors.white}`,
    },
  },
});

export const ModalHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
  width: '100%',
  maxHeight: '60px',
  padding: '2px 20px',
  borderBottom: `1px solid #ececec`,
});

export const ModalFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  width: '100%',
  borderTop: `1px solid ${colors.grey_01}`,
  justifyContent: 'flex-end',
  padding: '6px 12px',
  margin: 'auto',
});

export const Title = styled('h1')`
  ${typography.title};
  letter-spacing: -2px;
`;

export const H1 = styled('span')`
  margin: 0px 0px 0px;
  ${typography.header_01}
`;

export const H2 = styled('span')`
  margin: 0px 0px 0px;
  ${typography.header_02}
`;

export const H3 = styled('span')`
  margin: 0px 0px 0px;
  ${typography.header_03}
`;

export const H4 = styled('h4')`
  margin: 0px 0px 0px;
  ${typography.header_04}
`;

export const F1 = styled('h1')`
  margin: 0px 0px 0px;
  ${typography.footer_01}
`;

export const F2 = styled('h2')`
  margin: 0px 0px 0px;
  ${typography.footer_02}
`;

export const F3 = styled('h3')`
  margin: 0px 0px 0px;
  ${typography.footer_03}
`;

export const F4 = styled('h4')`
  margin: 0px 0px 0px;
  ${typography.footer_04}
`;

export const CardContainer = styled('div')({
  display: 'flex',
  flex: '1',
  margin: '16px 8px 0px 0px',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  backgroundColor: colors.white_00,
  overflow: 'scroll',
  // border: `1px solid ${colors.grey_01}`,
  borderRadius: '8px',
  boxShadow: colors.main_box_shadow,
});

export const CardHeader = styled('div')({
  ...typography.mainFont,
  fontWeight: '500',
  display: 'flex',
  padding: '16px 16px',
  color: colors.grey_03,
  borderBottom: `1px solid ${colors.grey_01}`,
});

export const CardBody = styled('div')({
  padding: '8px 16px',
});

export const InfoRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px 0px 0px',
});

export const Icon = styled('img')({
  height: '12px',
  width: '12px',
});

export const HeaderTextButton = styled('button')({
  fontFamily: 'Inter',
  height: '30px',
  borderRadius: '30px',
  border: `0px solid ${colors.black}`,
  color: colors.grey_02,
  fontWeight: '500',
  fontSize: '16px',
  backgroundColor: colors.white,
  padding: '0px 0px',
  cursor: 'pointer',
  letterSpacing: '0px',
  //width: '92px',
  marginRight: '20px',
  //fontWeight: 'bold',
});

export const Row = styled('div')`
  display: flex;
`;

export const Column = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const Divider = styled('div')`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${colors.grey_01};
  margin: 30px 0px 20px;
`;

export const GeneralFormControl = styled(FormControl)({
  color: '#242B2F',
  borderColor: '#ECECEC',
  '& .MuiSelect-select': {
    zIndex: 100,
    '& .MuiPaper-root': {
      zIndex: 100,
      background: '#fff',
      boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
    },
  },
  '& label': {
    fontSize: '13px',
    margin: '3px 0px 0px 4px',
    color: colors.grey_03,
    '&.Mui-focused': {
      color: '#404B50',
    },
  },
  '& .MuiOutlinedInput-root': {
    height: 30,
    paddingBottom: 0,
    margin: '0 4px',
    fontSize: 12,
    '& fieldset': {
      color: '#404B50',
    },
    '&:hover fieldset': {
      borderColor: '#404B50',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#404B50',
      border: `1px solid #404B50`,
      color: '#242B2F',
    },
  },
});

export const GeneralMenuItem = styled(MenuItem)({
  height: '42px',
  //width: '230px',
  'z-index': 100,
  backgroundColor: colors.white_01,
  '&:hover': {
    fontWeight: '600',
  },
});

export const GeneralMenuText = styled('div')({});

export const CardChip = styled('div')({
  display: 'flex',
  //height: '25px',
  color: colors.grey_02,
  alignItems: 'center',
  padding: '5px 14px',
  borderRadius: '5px',
  fontSize: '14px',
  margin: '3px',
  backgroundColor: colors.grey_06,
  whiteSpace: 'nowrap',
  cursor: 'default',
});

export const ScrollingDivLabel = styled('h1')({
  ...typography.mainFont,
  margin: '0px 0px 0px',
  fontSize: 14,
  fontWeight: '400',
  color: colors.grey_02,
  whiteSpace: 'nowrap',
  cursor: 'default',
  minWidth: '70px',
  textAlign: 'left',
});

export const ImageLg = styled('img')(`
  width: 100%;
  margin: 0px;
`);

export const BottomContent = styled('div')(`
  width: 100%;
  margin: 0px 0 0;
  display: flex;
`);

export const ActionContainer = styled('div')(`
  width: 50%;
  margin: 0px;
  background: #fafafa;
`);

export const ImageMd = styled('img')(`
  width: 50%;
  margin: 0px;
`);

export const ContentBody = styled('p')(`
  font-size: 14px;
  font-weight: 400px;
  margin: 10px 0 0 20px;
  color: #404B50; 
  width: 90%;
`);

export const EmailButton = styled('button')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
  background: #242B2F;
  border: 1px solid #242B2F;
  border-radius: 5px;
  cursor: pointer;
  padding: 6px 32px;
  margin: 20px 0 0 20px;
  &:hover {
    opacity: 0.7;
  }
`);

export const PreviewContainer = styled('div')(`
  width: 95%;
  font-weight: 500px;
  font-size: 24px;
  padding: 40px 0px 50px;
  display: flex;
  margin: 0px auto 0px;
  justify-content: space-between;
  border-bottom: 0.5px solid #ececec;
`);

export const HeaderChip = styled('div')({
  height: '25px',
  width: '100%',
  background: colors.grey_02,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '25px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  fontSpacing: '0.1px',
});

export const ResultsHeader = styled('div')({
  color: colors.grey_03,
  fontSize: 18,
  fontWeight: 500,
  cursor: 'pointer',
  margin: 0,
  padding: '0px 0px 0px 20px',
  backgroundColor: colors.white_00,
  borderBottom: `1px solid ${colors.grey_01}`,
  borderRadius: '5px 5px 0px 0px',
  height: '50px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const TableContainer = styled('div')({
  margin: '30px 0 0',
});

export const Container = styled('div')({
  margin: '0px 0px',
  paddingBottom: 120,
});

export const ResultsBox = styled('div')({
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
  margin: '10px 0px 10px',
  backgroundColor: colors.white_01,
  position: 'relative',
  'z-index': 6,
});

export const ErrorDiv = styled('div')({
  width: '95%',
  height: 300,
  margin: '10px auto',
  padding: '20px 0px',
  background: '#fff',
  display: 'flex',
  boxShadow: colors.main_box_shadow,
  textAlign: 'center',
});
export const PageBody = styled('div')({
  overflowY: 'scroll',
  height: '88%',
  padding: '10px 30px 0px 30px',
});

export const EventCountIndicator = styled('div')({
  height: '36px',
  width: '170px',
  borderRadius: '5px',
  background: colors.gradient_01,
  padding: '6px 0px 6px 10px',
  margin: '0px 0px 20px 0px',
});
