import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../../styles';
import magnifiPreview from '../../../assets/misc/magnifiPreview.svg';
import tifinWealthPreview from '../../../assets/misc/tifinWealthPreview.svg';
import magnifiTifinPreview from '../../../assets/misc/magnifiTifinPreview.svg';
import ComingSoonModal from '../../../components/ComingSoonModal';
import { Title } from '../../../components';
import { GET_FUNDS } from '../../../api/optimizeEngagement';
import { F3 } from '../../../components';
import ContactUsModal from '../../../components/ContactUsModal';
import { useQuery } from '@apollo/client';

const PreviewContainer = styled('div')(`
  width: 95%;
  font-weight: 500px;
  font-size: 24px;
  padding: 40px 0px 50px;
  display: flex;
  margin: 0px auto 0px;
  justify-content: space-between;
  border-bottom: 0.5px solid #ececec;
`);

const previewOptions = {
  Magnifi: magnifiPreview,
  LPL: magnifiPreview,
  'TIFIN Wealth': tifinWealthPreview,
  'Magnifi + TIFIN Wealth': magnifiTifinPreview,
};

const bodyCopy = {
  Magnifi:
    'Make your products visible to the people who are most interested in them. Take your fund from the third page to the top result. Purchase a theme today. ',
  'TIFIN Wealth':
    'Place your fund front and center across TIFIN Wealth. Make your products visible to the people who are most interested in the relevant themes of that fund. Purchase a theme today. ',
  'Magnifi + TIFIN Wealth':
    'Promote your fund across TIFIN to drive flows. You will own a theme across TIFIN’s platforms to reinforce thematic association with your product. ',
  LPL: 'Make your products visible to the people who are most interested in them. Take your fund from the third page to the top result. Purchase a theme today. ',
};

type Props = {
  selectedDropDown: string;
  setSelectedDropDown: any;
  platformOptions: any;
};

// const platformOptions = [
//   {
//     label: 'Magnifi',
//     value: 'magnifi',
//   },
//   {
//     label: 'TIFIN Wealth',
//     value: 'wealth',
//   },
//   {
//     label: 'Magnifi + TIFIN Wealth',
//     value: '',
//   },
// ];

export const Funds = ({ selectedDropDown, setSelectedDropDown, platformOptions }: Props) => {
  const [searchString, setSearchString] = useState<string>('');
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [availableSponsorships, setAvailableSponsorships] = useState([]);
  const [upcomingSponsorships, setUpcomingSponsorships] = useState([]);
  const [selectedFund, setSelectedFund] = useState<any>();
  const [filterOptions, setFilterOptions] = useState({ Platform: platformOptions[0] });

  // const [showReportsModal, setShowReportsModal] = useState(false);
  const [extendSelectedKeyword, setExtendSelectedKeyword] = useState<string | null>(null);

  // console.log('selected drop down: ', selectedDropDown);

  // useEffect(() => {
  //   getSponsorships();
  // }, [selectedFund, filterOptions]);

  useEffect(() => {
    setSelectedDropDown(filterOptions.Platform.label);
  }, [filterOptions]);

  // useEffect(() => {
  //   getFundList();
  //   // setSelectedDropDown(platformOptions[0]);
  // }, [searchString]);

  // const { loading, error } = useQuery(FETCH_SPONSORSHIPS, {
  //   variables: {
  //     platform: filterOptions?.Platform?.value,
  //     ticker: selectedFund?.ticker,
  //   },
  //   onCompleted: (data) => {
  //     setAvailableSponsorships(
  //       data?.products?.availableSponsorships
  //         ?.map((item: any) => ({ ...item, keyword: formatFundKeyword(item?.keyword) }))
  //         .filter((item: any) => !productPlacementBlacklist.includes(item?.keyword)),
  //     );
  //     setUpcomingSponsorships(
  //       data?.products?.upcomingSponsorships?.filter((item: any) => !productPlacementBlacklist.includes(item?.keyword)),
  //     );
  //   },
  // });

  const { loading: fundsLoading, data } = useQuery(GET_FUNDS, {
    variables: {
      query: searchString,
    },
  });

  // const getSponsorships = async () => {
  //   try {
  //     setLoading(true);
  //     // console.log('feature selections: ', filterOptions);
  //     // console.log('get sponsorships: ', response);
  //     const response = await FundsAPI.fetchSponsorships(filterOptions?.Platform?.value, selectedFund?.ticker);
  //     setAvailableSponsorships(
  //       response.availableSponsorships
  //         ?.map((item: any) => ({ ...item, keyword: formatFundKeyword(item?.keyword) }))
  //         .filter((item: any) => !productPlacementBlacklist.includes(item?.keyword)),
  //     );
  //     setUpcomingSponsorships(
  //       response.upcomingSponsorships?.filter((item: any) => !productPlacementBlacklist.includes(item?.keyword)),
  //     );
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     console.warn('getSponsorships Error:', err);
  //   }
  // };

  // const getFundList = async () => {
  //   try {
  //     const response = await FundsAPI.getFunds(searchString);
  //     setFundsList(response.funds);
  //   } catch (err) {
  //     console.warn('getFundList Error:', err);
  //   }
  // };

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  return (
    <div style={{ paddingBottom: 30 }}>
      {/* @ts-ignore */}
      <PreviewContainer>
        <div style={{ minWidth: 250, width: '33%' }}>
          <Title style={{ color: colors.grey_03 }}>Shelf Space on other TIFIN Products</Title>
          {/* @ts-ignore */}
          <F3>{bodyCopy[selectedDropDown]}</F3>
        </div>
        {/* @ts-ignore */}
        <img style={{ width: '66%' }} src={previewOptions[selectedDropDown]} alt="previewImg" />
      </PreviewContainer>
      <ComingSoonModal
        modalStatus={showComingSoon}
        closeModal={toggleComingSoon}
        custom={'This feature is currently being built, please check back soon for updates.'}
      />
      <ContactUsModal
        modalStatus={!!extendSelectedKeyword}
        closeModal={() => setExtendSelectedKeyword(null)}
        requestNote={`Sponsorship extension requested for the word: ${extendSelectedKeyword}`}
        requestType={'Sponsorship Extension'}
      />
    </div>
  );
};
