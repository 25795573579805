import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import { Row, H3, H4, F4, Title } from '..';
import { formatDollar, formatPercent } from '../../helpers/dataFormatter';
import EngagementIcon from '../../assets/icons/engagement-light.svg';
import SalesIcon from '../../assets/icons/sales-light.svg';
import HoldingsIcon from '../../assets/icons/holdings-light.svg';
import PersonaIcon from '../../assets/icons/persona-light.svg';
import { randomNumber } from '../../helpers/numberGenerator';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';

const SummaryCard = styled('div')({
  backgroundColor: colors.red_01,
  flex: 1,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  height: '184px',
  borderRadius: '15px',
  padding: '20px 16px',
  // maxWidth: '300px',
});

const IconContainer = styled('div')({
  height: '40px',
  width: '40px',
  borderRadius: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

type Props = { data: any; dataAvailable: any };

export const SummaryHeader = ({ data, dataAvailable }: Props) => {
  const randomIndexBase = randomNumber(1000000);

  const ProfileArray = useMemo(
    () => [
      {
        id: crypto.randomUUID(),
        key: 'persona',
        label: 'Persona',
        icon: PersonaIcon,
        color: colors.dark_purple,
        formatValue: (data: any) => {
          const personaName = data?.persona?.type?.name ?? data?.persona?.advisorData?.persona;
          return personaName ? personaName : '-';
        },
        formatSubValue: (data: any) => {
          const similarPercentage =
            data?.persona?.insights?.comparison ?? data?.summaries?.headingData?.personaSimilarity;
          // console.log('similarPercentage: ', similarPercentage);
          return !similarPercentage
            ? '-'
            : typeof similarPercentage === 'string'
            ? similarPercentage
            : `Similar to ${similarPercentage}% of Advisors`;
        },
      },
      dataAvailable?.holding &&
        data?.summaries?.headingData?.totalHoldings > 0 && {
          id: crypto.randomUUID(),
          key: 'holdings',
          label: 'Holdings',
          icon: SalesIcon,
          color: colors.red,
          formatValue: (data: any) => {
            return data?.holdings?.metrics?.totalHoldings
              ? formatDollar(data?.holdings?.metrics?.totalHoldings)
              : formatDollar(data?.summaries?.headingData?.totalHoldings);
          },
          formatSubValue: (data: any) => {
            const holdingChange = data?.insights?.comparison ?? data?.summaries?.headingData?.holdingsChange;
            return !holdingChange
              ? '-'
              : typeof holdingChange === 'string'
              ? holdingChange
              : `${holdingChange}% change`;
          },
        },
      dataAvailable?.transactions &&
        data?.summaries?.headingData?.netFlows > 0 && {
          id: crypto.randomUUID(),
          key: 'transactions',
          label: 'Net Flows (Last month)',
          icon: HoldingsIcon,
          color: colors.green,
          formatValue: (data: any) => {
            return data?.transactions?.metrics?.netFlowsLastQuarter
              ? formatDollar(data?.transactions?.metrics?.netFlowsLastQuarter)
              : formatDollar(data?.summaries?.headingData?.netFlows);
          },
          formatSubValue: (data: any) => {
            const comparisonValue =
              data?.transactions?.insights?.comparison ?? data?.summaries?.headingData?.netFlowsMultiple;
            return !comparisonValue
              ? '-'
              : typeof comparisonValue === 'string'
              ? comparisonValue
              : `${comparisonValue}X average `;
          },
        },
      dataAvailable?.engagements &&
        data?.summaries?.headingData?.openRate !== 0 && {
          id: crypto.randomUUID(),
          key: 'engagements',
          label: 'Open Rate (Last year)',
          icon: EngagementIcon,
          color: colors.yellow,
          formatValue: (data: any) => {
            return data?.engagements?.metrics?.openRate
              ? formatPercent(data?.engagements?.metrics?.openRate * 100)
              : formatPercent(data?.summaries?.headingData?.openRate) !== '0%'
              ? formatPercent(data?.summaries?.headingData?.openRate)
              : '-';
          },
          formatSubValue: (data: any) => {
            const comparisonValue =
              data?.engagements?.insights?.comparison ?? data?.summaries?.headingData?.openRateMultiple;

            return !comparisonValue
              ? '-'
              : typeof comparisonValue === 'string'
              ? comparisonValue
              : `${comparisonValue}X average `;
          },
        },
    ],
    [dataAvailable],
  );

  return (
    <Row>
      {ProfileArray.map((card, index) => {
        return (
          card && (
            <SummaryCard
              key={index + randomIndexBase}
              style={{ background: card?.color + '20', margin: index === 0 ? '0px' : '0px 0px 0px 5px' }}
            >
              <IconContainer style={{ background: card?.color }}>
                <img src={card?.icon} alt="icon" />
              </IconContainer>
              <Title
                style={{
                  color: colors.black,
                  textAlign: 'left',
                  fontSize: '22px',
                  lineHeight: '26px',
                  // whiteSpace: 'nowrap',
                }}
              >
                {card?.formatValue && card?.formatValue(data)}
              </Title>
              <H3
                style={{
                  color: colors.grey_03,
                  margin: '-6px 0px 10px',
                  display: 'flex',
                  flex: 1,
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                }}
              >
                {card?.label}
              </H3>
              <H4 style={{ color: colors.dark_purple, textAlign: 'left' }}>
                {card?.formatSubValue && card?.formatSubValue(data)}
              </H4>
            </SummaryCard>
          )
        );
      })}
    </Row>
  );
};
