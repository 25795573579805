import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import { H3, F2, F3, F4 } from '../../components';
import { colors, typography } from '../../styles';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, LabelList } from 'recharts';

const Root = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  background: colors.purple,
  margin: '-20px 0px 0px -10px',
  border: '1px solid #ECECEC',
  borderTop: '0px solid #ECECEC',
  borderRadius: '0px 0px 0px 5px',
  padding: '20px 0px 20px 40px',
  minWidth: '460px',
});

type Props = {
  runId: number;
  data: any;
  totalAdvisors: number;
};

export const AdvisorScoreDistribution = ({ runId, data, totalAdvisors }: Props) => {
  return (
    <Root>
      <H3 style={{ color: colors.black }}>Breakdown of Results by AMP Score</H3>
      <F4 style={{ color: colors.grey_02 }}>Based on the {totalAdvisors} advisors analyzed</F4>
      <ResponsiveContainer width={'98%'} height={320} id={'bubble-chart-data'}>
        {/* @ts-ignore */}
        <BarChart margin={{ left: 0, right: 20, top: 40 }} height={'80%'} data={data}>
          {/* <Tooltip /> */}
          <XAxis
            dataKey={'score'}
            tick={{
              ...typography.mainFont,
              // color: '#758790',
              fontSize: '10px',
              fontWeight: 400,
              fill: '#758790',
            }}
            stroke={colors.grey_01}
            interval={0}
          />
          <Bar isAnimationActive={false} dataKey={'count'} fill={colors.black}>
            <LabelList
              position={'top'}
              style={{ ...typography.mainFont, fontSize: '12px', fill: colors.black }}
              formatter={(value: any) => {
                return value === 0 ? '' : value + '';
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </Root>
  );
};

export default AdvisorScoreDistribution;
