//@ts-ignore
import { themes } from './themes';
const appType = process.env.REACT_APP_THEME === 'LPL' ? 'LPL' : 'TIFIN';

//Neutals

export const white = '#FFFFFF';
export const white_00 = '#FFFFFF';
export const white_01 = '#FAFAFA';
export const white_02 = '#F6F9FB';
export const white_03 = '#FCFAFF';

export const grey_00 = '#F9F9F9';
export const grey_01 = '#ECECEC';
export const grey_15 = '#BBBBBB';
export const grey_02 = '#758790';
// export const grey_03 = '#404B50';
export const grey_03 = themes?.[appType] ? themes[appType]?.primary_color : '#404B50';
export const grey_04 = '#9A9A9A';
export const grey_05 = '#E4EBEF';
export const grey_06 = '#E7EDF5';

export const grey_07 = 'rgba(196, 196, 196, 0.50)';

export const black = themes?.[appType] ? themes[appType]?.primary_color : '#242B2F';
export const black_01 = themes?.[appType] ? themes[appType]?.primary_color : '#242B2F';
export const black_02 = '#151A1D';
export const black_03 = '#404B50';

// Primary
export const yellow = '#FF7715';
export const yellow_01 = '#FFCF52';
export const light_blue = '#5B8CFD';
export const sheer_blue = '#CCDBFF';
export const blue = '#8AB9F8';
export const dark_blue = '#005985';
export const okta_blue = '#00297A';

export const green = '#00CFAA';
export const green_01 = '#007843';

export const light_green = '#A8F9EA';
export const light_purple = '#B7B5E4';
export const lilac = '#93ABFF';
export const purple = '#F1F2FB';
export const dark_purple = '#8280C7';
export const red = '#FD0379';
export const red_01 = '#FF6752';

export const red_02 = '#EE768D';
export const orange = '#FF7715';

export const main_box_shadow = '0px 0px 25px 0px rgba(63, 106, 194, 0.15)';
export const box_shadow_override = '0px 0px 0px 0px #FFFFFF';

export const gradient_01 = themes?.[appType]
  ? themes?.[appType].primary_gradient
  : 'linear-gradient(90deg, #EAE9F57F 0%, #E6F0FF7F 100%)';
// export const gradient_01 = 'linear-gradient(180deg, #4A89F5 0%, #36F45F 100%)';
export const gradient_02 = 'linear-gradient(90.04deg, rgba(36, 43, 47, 0.2) 0.04%, rgba(36, 43, 47, 0) 99.98%);';
export const gradient_03 = themes?.[appType]
  ? themes?.[appType].secondary_gradient
  : 'linear-gradient(90.04deg, #CDCDF7 0.04%, rgba(205, 205, 247, 0) 99.98%)';

export const gradient_04 = 'linear-gradient(90deg, #AE94FF 0%, #AACBF8 100%)';

export const primary = themes[appType] ? themes[appType]?.primary_color : black_01;
export const secondary = themes[appType] ? themes[appType]?.secondary_color : light_purple;
