import React, { useState, useRef, useEffect } from 'react';
import { colors } from '../styles';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import rightArrow from '../assets/icons/arrow-right.svg';
import leftArrow from '../assets/icons/arrow-left.svg';

const left_slide = keyframes`
    from {
        transform: translateX(-400%);
    }
    to {
        transform: translateX(0%);
    }
`;

const right_slide = keyframes`
    from {
        transform: translateX(400%);
    }
    to {
        transform: translateX(0%);
    }
`;

const LeftArrow = styled('div')({
  animation: `${left_slide} 1s ease alternate`,
  background: `linear-gradient(90deg, ${colors.white_00 + 'FF'} 70%, ${colors.white_00 + '00'}) 100%`,
  zIndex: '5',
  height: '52px',
  display: 'flex',
  margin: '0px -20px 0px 0px',
  alignItems: 'center',
  justifyContent: 'center',
});

const ArrowStyle = styled('img')({
  //margin: '0px 10px',
  cursor: 'pointer',
  width: 8,
});

const RightArrow = styled('div')({
  animation: `${right_slide} 1s ease`,
  background: `linear-gradient(270deg, ${colors.white_00 + 'FF'} 70%, ${colors.white_00 + '00'}) 100%`,
  zIndex: '5',
  height: '52px',
  display: 'flex',
  margin: '0px 0px 0px -28px',
  alignItems: 'center',
  justifyContent: 'center',
});

const ActivePlatformRow = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'space-between',
  height: '34px',
  maxWidth: '307px',
  zIndex: '0',
  overflowX: 'scroll',
  '&::-webkit-scrollbar': {
    height: '0px',
  },
});

interface Props {
  children: any;
  width?: number | null;
  height?: number | null;
  style?: any;
  backgroundColor?: string;
}

const ScrollingDiv = ({ children, width, height, style, backgroundColor }: Props) => {
  const row = useRef<any>();
  const [rowOverflow, setRowOverflow] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const id = Math.floor(Math.random() * 1000);

  useEffect(() => {
    setTimeout(() => {
      if (!!row?.current) {
        setRowOverflow(row?.current?.scrollWidth > row?.current?.clientWidth);
        updateArrows();
      }
    }, 200);
  }, [width]);

  const updateArrows = () => {
    setShowLeftArrow(row?.current?.scrollLeft !== 0);
    setShowRightArrow(row?.current?.scrollLeft < row?.current?.scrollWidth - row?.current?.clientWidth - 10);
  };

  const scrollDiv = (itemRef: any, direction: number) => (event: any) => {
    const offset = direction === 0 ? -280 : 300;
    const destination = row.current.scrollLeft + offset;
    itemRef.current.scrollTo({ behavior: 'smooth', left: destination });
  };

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent:
          showRightArrow && showLeftArrow ? 'space-between' : !showRightArrow ? 'space-between' : 'flex-end',
        margin: '8px 0px 0px -3px',
        height: '46px',
        // overflowX: 'hidden',
        // overflowY: 'hidden',
        ...style,
      }}
    >
      {rowOverflow && showLeftArrow && (
        <>
          <LeftArrow
            style={{
              background: backgroundColor
                ? `linear-gradient(90deg, ${backgroundColor + 'FF'} 70%, ${backgroundColor + '00'}) 100%`
                : `linear-gradient(90deg, ${colors.white_00 + 'FF'} 70%, ${colors.white_00 + '00'}) 100%`,
            }}
          >
            <ArrowStyle
              alt="leftArrow"
              style={{ margin: '2px 20px 20px 3px' }}
              src={leftArrow}
              onClick={scrollDiv(row, 0)}
            />
          </LeftArrow>
          <div style={{ marginLeft: '-10px' }} />
        </>
      )}
      <ActivePlatformRow
        ref={row}
        id={'cardRow' + id}
        style={{ margin: '0px 0px 18px 0px', maxWidth: width ? width : undefined, height: height ? height : undefined }}
        onScroll={() => updateArrows()}
      >
        {children}
      </ActivePlatformRow>
      {rowOverflow && showRightArrow && (
        <RightArrow
          style={{
            background: backgroundColor
              ? `linear-gradient(270deg, ${backgroundColor + 'FF'} 70%, ${backgroundColor + '00'}) 100%`
              : `linear-gradient(270deg, ${colors.white_00 + 'FF'} 70%, ${colors.white_00 + '00'}) 100%`,
          }}
        >
          <ArrowStyle
            alt="rightArrow"
            style={{ margin: '2px 0px 20px 20px' }}
            src={rightArrow}
            onClick={scrollDiv(row, row.current.scrollWidth)}
          />
        </RightArrow>
      )}
    </div>
  );
};

export default ScrollingDiv;
