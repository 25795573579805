import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../../../styles';
import { useStoreState } from '../../../../store';

import downArrowGray from '../../../../assets/icons/arrow-down-grey.svg';
import { formatTimestamp, formatPackageName } from '../../../../helpers/dataFormatter';
import { useNavigate } from 'react-router-dom';
import AmplitudeChart from '../../../../components/AmplitudeChart';
import { CardContainer, CardHeader, H2 } from '../../../../components';
import CampaignPerformanceCard from '../../../../components/CampaignPerformanceCard';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  depthIcon: {
    height: '12px',
    width: '12px',
    margin: '20px 8px 0px',
  },
  cardContainer: {
    display: 'flex',
    flex: '1',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: colors.white_00,
    border: `1px solid ${colors.grey_00}`,
    borderRadius: '8px',
    boxShadow: colors.main_box_shadow,
  },
  pageTitle: {
    '&:hover': {
      borderBottom: `1px solid ${colors.grey_03}`,
      cursor: 'pointer',
    },
  },
  pageHeader: {
    margin: '10px 0px 4px',
  },
  header: {
    display: 'flex',
    padding: '16px 16px',
    color: colors.grey_03,
    borderBottom: `1px solid ${colors.grey_00}`,
  },
  campaignInfoHeader: {
    ...typography.mainFont,
    fontSize: '14px',
    fontWeight: '600',
    color: colors.grey_03,
  },
  campaignInfoP: {
    ...typography.mainFont,
    fontSize: '12px',
    fontWeight: '400',
    color: colors.grey_02,
  },
  campaignInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
  },
  campaignInfoTableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
    padding: '0px 4px',
    '&:hover': {
      backgroundColor: colors.grey_01,
      borderRadius: '5px',
      cursor: 'pointer',
    },
  },
  campaignInfoCellRight: {
    ...typography.mainFont,
    fontSize: '12px',
    minWidth: '200px',
    display: 'flex',
    flex: 1,
    fontWeight: '400',
    textAlign: 'right',
    justifyContent: 'flex-end',
    color: colors.grey_02,
  },
  campaignInfoCellLeft: {
    ...typography.mainFont,
    fontSize: '12px',
    minWidth: '200px',
    display: 'flex',
    flex: 1,
    fontWeight: '400',
    textAlign: 'left',
    justifyContent: 'flex-start',
    color: colors.grey_02,
  },
  cardBody: {
    padding: '8px 16px',
  },
  noDataContainer: {
    position: 'absolute',
    zIndex: 1000,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '140px 80px',
    backgroundColor: colors.white_00,
    backdropFilter: 'blur(35px)',
    opacity: 0.8,
  },
}));

interface Props {
  campaign: any;
  openArchive: any;
  setCampaign: any;
}

const CampaignEngagement = ({ campaign, openArchive, setCampaign }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const sendDate = moment(campaign?.dateCreated).add(20, 'm');
  // const sendDate = moment().add(20, 'm');

  const demoAccount = useStoreState((state) => state.user.demoAccount);

  const exit = () => {
    setError(null);
    setCampaign(null);
  };

  // console.log('campaign in campaignEngagement: ', campaign);

  const campaignDetails = [
    { label: 'Name', key: 'name', formatData: (row: any) => (row?.name ? row?.name : row?.campaignName) },
    { label: 'Description', key: 'description' },
    { label: 'Status', key: 'status', formatData: (row: any) => formatPackageName(row?.status) },
    // { label: 'Platforms', key: 'platforms', formatData: (row:any) => 'Email' },
    { label: 'Target', key: 'package', formatData: (row: any) => formatPackageName(row?.run?.package) },
    {
      label: 'Date Created',
      key: 'dateCreated',
      formatData: (row: any) => formatTimestamp(row?.dateCreated ? row?.dateCreated : row?.startDate),
    },
  ];

  const activeEmailSet = campaign?.nurtureemailSet ? campaign?.nurtureemailSet : campaign?.marketingemailSet;

  const emailDetails = [
    {
      label: 'Subject',
      key: 'subject',
      formatData: (row: any) => (row.subject ? row?.subject : '-'),
    },
    {
      label: 'Status',
      align: 'Right',
      key: 'status',
      //   formatData: (row:any) => formatPackageName(row.nurtureemailSet?.[0]?.status),
      formatData: (row: any) => (moment().isBefore(sendDate) ? 'Pending' : 'Sent'),
    },
    {
      label: 'Send Date',
      key: 'sendDatetime',
      align: 'Right',
      formatData: (row: any) => (moment().isBefore(sendDate) ? '-' : formatTimestamp(sendDate)),
    },
  ];

  return (
    <div>
      <div className={classes.pageHeader}>
        <div style={{ display: 'flex' }}>
          <H2 className={classes.pageTitle} onClick={() => setCampaign(null)}>
            View Engagement
          </H2>
          <img
            src={downArrowGray}
            alt="expand"
            className={classes.depthIcon}
            style={{
              transform: 'rotate(-90deg)',
              transition: '0.3s',
              margin: '6px 8px 0px',
            }}
          />
          <H2>{campaign?.name ? campaign?.name : campaign?.campaignName}</H2>
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <CardContainer>
          <CardHeader>Campaign Details</CardHeader>
          <div className={classes.cardBody}>
            {campaignDetails.map((item, index) => (
              <div
                key={index + 479724}
                className={classes.campaignInfoRow}
                style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
              >
                <p className={classes.campaignInfoP}>{item.label}</p>
                <p className={classes.campaignInfoP}>
                  {item.formatData ? item.formatData(campaign) : campaign[item.key]}
                </p>
              </div>
            ))}
          </div>
        </CardContainer>
        <CardContainer className={classes.cardContainer} style={{ margin: '16px 0px 0px 8px' }}>
          <CardHeader>Most Recent Email</CardHeader>
          <div className={classes.cardBody}>
            {emailDetails?.map((item, index) => (
              <div
                key={index + 492304}
                className={classes.campaignInfoRow}
                style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
              >
                <p className={classes.campaignInfoP}>{item.label}</p>
                <p className={classes.campaignInfoP}>
                  {item.formatData ? item.formatData(activeEmailSet?.[0]) : activeEmailSet?.[0]?.[item.key]}
                </p>
              </div>
            ))}
          </div>
        </CardContainer>
      </div>
      <div style={{ display: 'flex' }}>
        <CampaignPerformanceCard campaign={campaign} />
        <AmplitudeChart
          title={'Recent events in this campaign'}
          style={{ margin: '16px 0px 0px 8px' }}
          chartData={campaign?.eventsOverTime}
          demoMode={demoAccount}
        />
      </div>
      <div>
        <div className={classes.cardContainer} style={{ margin: '16px 0px 0px 0px' }}>
          <CardHeader>Emails for Campaign</CardHeader>
          <div className={classes.cardBody}>
            <div key={8394492304} className={classes.campaignInfoRow} style={{ margin: '-16px 0px 0px' }}>
              {emailDetails.map((item) => (
                <p
                  className={item.align === 'Right' ? classes.campaignInfoCellRight : classes.campaignInfoCellLeft}
                  style={{
                    fontWeight: '600',
                    margin: '20px 0px 0px',
                    padding: '10px 0px',
                    borderBottom: `1px solid ${colors.grey_00}`,
                  }}
                >
                  {item.label}
                </p>
              ))}
            </div>
            {activeEmailSet?.map((email: any, index: number) => (
              <div className={classes.campaignInfoTableRow} style={{}}>
                {emailDetails.map((item) => (
                  <>
                    <p
                      className={item.align === 'Right' ? classes.campaignInfoCellRight : classes.campaignInfoCellLeft}
                    >
                      {item.formatData ? item.formatData(email) : email[item.key]}
                    </p>{' '}
                  </>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignEngagement;
