import { Action, action, Thunk, thunk } from 'easy-peasy';

export interface PrioritizeEngagementModel {
  progress: number;
  progressTitles: Array<string>;
  marketingDatasetIds: Array<string>;
  flowDatasetIds: Array<string>;
  featuresToExclude: Array<string>;
  filterOptions: object;
  packageRunName: string | null;
  dateRun: string | null;
  runId: number | null;

  setProgress: Action<PrioritizeEngagementModel, any>;
  setProgressTitles: Action<PrioritizeEngagementModel, any>;
  setMarketingDatasetIds: Action<PrioritizeEngagementModel, any>;
  setFlowDatasetIds: Action<PrioritizeEngagementModel, any>;
  setFeaturesToExclude: Action<PrioritizeEngagementModel, any>;
  setPackageRunName: Action<PrioritizeEngagementModel, any>;
  setDateRun: Action<PrioritizeEngagementModel, any>;
  setRunId: Action<PrioritizeEngagementModel, any>;

  loadArchive: Thunk<PrioritizeEngagementModel, any>;
}

export const prioritizeEngagement: PrioritizeEngagementModel = {
  progress: 0,
  progressTitles: ['', '', ''],

  marketingDatasetIds: [],
  flowDatasetIds: [],

  featuresToExclude: [],

  filterOptions: {},
  packageRunName: null,
  dateRun: null,
  runId: null,

  setProgress: action((state, payload) => {
    state.progress = payload;
  }),
  setProgressTitles: action((state, payload) => {
    state.progressTitles = payload;
  }),
  setMarketingDatasetIds: action((state, payload) => {
    state.marketingDatasetIds = payload;
  }),

  setFlowDatasetIds: action((state, payload) => {
    state.flowDatasetIds = payload;
  }),

  setFeaturesToExclude: action((state, payload) => {
    state.featuresToExclude = payload;
  }),
  setPackageRunName: action((state, payload) => {
    state.packageRunName = payload;
  }),
  setDateRun: action((state, payload) => {
    state.dateRun = payload;
  }),
  setRunId: action((state, payload) => {
    state.runId = payload;
  }),

  loadArchive: thunk(async (actions: any, payload: any) => {
    const prioritizeEngagementParams = payload?.attributionrunparamsSet?.[0];

    // console.log('payload in loadArchive: ', payload);

    actions.setMarketingDatasetIds(JSON.parse(prioritizeEngagementParams?.marketingDatasetIds));
    actions.setFlowDatasetIds(JSON.parse(prioritizeEngagementParams?.flowDatasetIds));
    actions.setFeaturesToExclude(payload?.featuresToExclude);

    actions.setProgressTitles(['Complete', 'Complete', 'Complete', 'Complete']);

    actions.setRunId(payload.id);
    actions.setProgress(4);
    actions.setPackageRunName(payload.customName);
    actions.setDateRun(payload.dateRun);
  }),
};
