import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../styles';
import { TextField } from '@mui/material';
import closeIcon from '../assets/icons/close.svg';
import { Modal, IconButton } from '@mui/material';
import { ModalRoot, PrimaryButtonShort } from '.';

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '8px',
    minWidth: '400px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    padding: '2px 20px',
    borderBottom: `1px solid #ececec`,
  },
  modalTitle: {
    ...typography.mainFont,
    fontSize: 16,
    color: colors.black,
    fontWeight: 500,
    cursor: 'pointer',
    margin: '12px 0',
  },
  modalBody: {
    padding: '10px 48px 20px 30px',
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 250,
  },
  mainHeader: {
    ...typography.mainFont,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 500,
    color: colors.grey_03,
    cursor: 'default',
    margin: '20px 0px 0px',
  },
  mainSubheader: {
    ...typography.mainFont,
    fontSize: 14,
    lineHeight: 1.5,
    fontWeight: 400,
    color: colors.grey_02,
    cursor: 'default',
    margin: '6px 0px 20px',
  },
  fieldOverride: {
    margin: '16px',
    '& label.Mui-focused': {
      color: colors.grey_02,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
    },
  },
  primaryButton: {
    width: '25%',
    margin: '10px 8px 10px 0',
    fontWeight: 400,
  },
  secondaryButton: {
    width: '25%',
    margin: '10px 8px 10px 0',
    fontWeight: 400,
    background: '#7C8489',
    border: '2px solid #7C8489',
  },
}));

interface Props {
  modalCopy: string;
  modalStatus: any;
  closeModal: any;
  campaignInfo: any;
  setCampaignType?: any;
  setCampaignInfo: any;
  campaignOptions: any;
  nextAction: any;
}

const CreateModal = ({
  modalCopy,
  modalStatus,
  closeModal,
  campaignInfo,
  setCampaignInfo,
  campaignOptions,
  nextAction,
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(null);

  const exit = () => {
    closeModal();
    setCampaignInfo({ title: '', description: '' });
    setLoading(null);
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modalContainer}>
        <div className={classes.modalHeader}>
          <h4 className={classes.modalTitle}>{modalCopy ? modalCopy : 'Create a Campaign'}</h4>
          <IconButton onClick={exit}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
        <div className={classes.modalBody}>
          <div style={{ width: '100%', margin: 0 }}>
            <h3 className={classes.mainHeader}>Please name your campaign and describe its purpose</h3>
            <h3 className={classes.mainSubheader}>This information will be used to identify your campaign</h3>
            <div style={{ margin: '0 0 0 -20px' }}>
              <TextField
                id="email-field"
                label="Campaign Name"
                variant="outlined"
                size="small"
                sx={{ margin: '16px 0', width: '100%' }}
                value={campaignInfo?.name ? campaignInfo?.name : ''}
                className={classes.fieldOverride}
                focused
                onChange={(event) => setCampaignInfo({ ...campaignInfo, name: event.target.value })}
              />
              <TextField
                id="email-field"
                label="Campaign Purpose"
                variant="outlined"
                size="small"
                sx={{ margin: '16px 0', width: '100%' }}
                value={campaignInfo?.description ? campaignInfo?.description : ''}
                className={classes.fieldOverride}
                focused
                onChange={(event) => setCampaignInfo({ ...campaignInfo, description: event.target.value })}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '6px 12px',
            width: '96%',
            margin: 'auto',
          }}
        >
          <PrimaryButtonShort className={classes.secondaryButton} onClick={closeModal}>
            Cancel
          </PrimaryButtonShort>
          <PrimaryButtonShort className={classes.primaryButton} onClick={nextAction}>
            Create
          </PrimaryButtonShort>
        </div>
      </div>
    </ModalRoot>
  );
};

export default CreateModal;
