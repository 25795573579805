import { Action, Thunk, action, thunk } from 'easy-peasy';
import { individualLeads, IndividualLeadsModel } from './individualLeads';
import { prioritizeEngagement, PrioritizeEngagementModel } from './prioritizeEngagement';

export interface GenerateSignalsModel {
  individualLeads: IndividualLeadsModel;
  prioritizeEngagement: PrioritizeEngagementModel;

  clientCount: number;
  allAdvisors: Array<any>;

  setClientCount: Action<GenerateSignalsModel, any>;
  setAllAdvisors: Action<GenerateSignalsModel, any>;
}

export const generateSignals: GenerateSignalsModel = {
  individualLeads,
  prioritizeEngagement,

  clientCount: 0,
  allAdvisors: [],

  setClientCount: action((state, payload) => {
    state.clientCount = payload;
  }),

  setAllAdvisors: action((state, payload) => {
    state.clientCount = payload;
  }),
};
