import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import funnel1 from '../../assets/icons/funnel-1.svg';
import funnel1Dark from '../../assets/icons/funnel-1-dark.svg';
import funnel2 from '../../assets/icons/funnel-2.svg';
import funnel2Dark from '../../assets/icons/funnel-2-dark.svg';
import funnel3 from '../../assets/icons/funnel-3.svg';
import funnel3Dark from '../../assets/icons/funnel-3-dark.svg';

import home from '../../assets/icons/home.svg';
import settings from '../../assets/icons/settings.svg';
import settingsDark from '../../assets/icons/settings-dark.svg';
import infoDark from '../../assets/icons/info-dark.svg';
import info from '../../assets/icons/info-light.svg';

import { ParentNav } from './ParentNav';
import { SectionNav } from './SectionNav';
import { styled } from '@mui/material/styles';
import { useStoreState } from '../../store';

type Props = {};

const NavContainer = styled('div')({
  display: 'flex',
  flex: 1,
  zIndex: 50,
});

const lplMenuOptions = [
  {
    label: 'Home',
    link: 'home',
    icon: home,
    iconStyle: { height: '15px', width: '15px', margin: '2px 0px 2px 8px' },
  },
  {
    label: 'Unify Data',
    link: 'unify',
    icon: funnel1,
    iconSelected: funnel1Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Integrations', link: 'unify/integrations' },
      // { label: 'CRM Integrations', link: 'unify/crm' },
      { label: 'Advisors', link: 'unify/advisors' },
      { label: 'Datapacks', link: 'unify/datapacks' },
    ],
  },
  {
    label: 'Optimize Marketing',
    link: 'optimize',
    icon: funnel2,
    iconSelected: funnel2Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0x' },
    children: [
      { label: 'Optimize Engagement', link: 'optimize/engagement' },
      { label: 'Marketing' },
      { label: 'Product Placements', link: 'optimize/product-placements' },
      { label: 'Webinars', link: 'optimize/webinars' },
    ],
  },
  {
    label: 'Drive Sales',
    link: 'drive-sales',
    icon: funnel3,
    iconSelected: funnel3Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Identify' },
      { label: 'Influence Prospects', link: 'drive-sales/influence-prospects' },
      { label: 'Discover New Leads', link: 'drive-sales/discover-leads' },
      { label: 'Learn' },
      { label: 'Create Campaign', link: 'drive-sales/create-campaign' },
      { label: 'View Engagement', link: 'drive-sales/nurture' },
      { label: 'Optimize' },
      { label: 'Outcomes', link: 'drive-sales/outcomes' },
      { label: 'Feedback Loop', link: 'drive-sales/feedback' },
    ],
  },
  // {
  //   label: 'Datapacks',
  //   link: 'datapacks',
  //   icon: datapacks,
  //   iconSelected: datapacksDark,
  //   topBorder: true,
  //   divider: true,
  //   iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
  // },
  {
    label: 'Profile & Settings',
    link: 'profile',
    icon: settings,
    divider: true,
    iconSelected: settingsDark,
    iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
    children: [
      { label: 'Firm Details', link: 'profile/firm-details' },
      { label: 'Account Details', link: 'profile/account-details' },
      { label: 'Funds', link: 'profile/funds' },
      { label: 'Subscriptions', link: 'profile/subscriptions' },
      { label: 'Feedback Loop', link: 'profile/feedback-loop' },
    ],
    topBorder: true,
    showLogout: true,
    smallIcon: true,
  },
];

const ftMenuOptions = [
  {
    label: 'Home',
    link: 'home',
    icon: home,
    iconStyle: { height: '15px', width: '15px', margin: '2px 0px 2px 0px' },
  },
  {
    label: 'Unify Data',
    link: 'unify',
    icon: funnel1,
    iconSelected: funnel1Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Integrations', link: 'unify/integrations' },
      // { label: 'CRM Integrations', link: 'unify/crm' },
      // { label: 'Marketing Integrations', link: 'unify/marketing' },
      { label: 'Advisors', link: 'unify/advisors' },
    ],
  },
  {
    label: 'Optimize Marketing',
    link: 'optimize',
    icon: funnel2,
    iconSelected: funnel2Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0x' },
    children: [
      { label: 'Optimize Engagement', link: 'optimize/engagement' },
      { label: 'Marketing' },
      { label: 'Product Placements', link: 'optimize/product-placements' },
      { label: 'Webinars', link: 'optimize/webinars' },
    ],
  },
  {
    label: 'Drive Sales',
    link: 'drive-sales',
    icon: funnel3,
    iconSelected: funnel3Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Identify' },
      { label: 'Individual Leads', link: 'drive-sales/individual-leads' },
      { label: 'Influence Prospects', link: 'drive-sales/influence-prospects' },
      { label: 'Discover New Leads', link: 'drive-sales/discover-leads' },
      { label: 'Learn' },
      { label: 'Create Campaign', link: 'drive-sales/create-campaign' },
      { label: 'View Engagement', link: 'drive-sales/nurture' },
      { label: 'Optimize' },
      { label: 'Outcomes', link: 'drive-sales/outcomes' },
      { label: 'Feedback Loop', link: 'drive-sales/feedback' },
    ],
  },
  {
    label: 'Profile & Settings',
    link: 'profile',
    icon: settings,
    divider: true,
    iconSelected: settingsDark,
    iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
    children: [
      { label: 'Firm Details', link: 'profile/firm-details' },
      { label: 'Account Details', link: 'profile/account-details' },
      { label: 'Funds', link: 'profile/funds' },
      { label: 'Subscriptions', link: 'profile/subscriptions' },
      { label: 'Feedback Loop', link: 'profile/feedback-loop' },
    ],
    topBorder: true,
    showLogout: true,
    smallIcon: true,
  },
];

const menuOptions = [
  {
    label: 'Home',
    link: 'home',
    icon: home,
    iconStyle: { height: '15px', width: '15px', margin: '2px 0px 2px 0px' },
  },
  {
    label: 'Unify Data',
    link: 'unify',
    icon: funnel1,
    iconSelected: funnel1Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Integrations', link: 'unify/integrations' },
      // { label: 'CRM Integrations', link: 'unify/crm' },
      // { label: 'Marketing Integrations', link: 'unify/marketing' },
      { label: 'Advisors', link: 'unify/advisors' },
    ],
  },
  {
    label: 'Optimize Marketing',
    link: 'optimize',
    icon: funnel2,
    iconSelected: funnel2Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0x' },
    children: [
      // { label: 'Optimize Engagement', link: 'optimize/engagement' },
      { label: 'Marketing' },
      { label: 'Product Placements', link: 'optimize/product-placements' },
      { label: 'Webinars', link: 'optimize/webinars' },
    ],
  },
  {
    label: 'Drive Sales',
    link: 'drive-sales',
    icon: funnel3,
    iconSelected: funnel3Dark,
    iconStyle: { height: '26px', width: '26px', margin: '0px 0px 0px 0px' },
    children: [
      { label: 'Identify' },
      { label: 'Influence Prospects', link: 'drive-sales/influence-prospects' },
      { label: 'Discover New Leads', link: 'drive-sales/discover-leads' },
      { label: 'Learn' },
      { label: 'Create Campaign', link: 'drive-sales/create-campaign' },
      { label: 'View Engagement', link: 'drive-sales/nurture' },
      { label: 'Optimize' },
      { label: 'Outcomes', link: 'drive-sales/outcomes' },
      { label: 'Feedback Loop', link: 'drive-sales/feedback' },
    ],
  },
  {
    label: 'Profile & Settings',
    link: 'profile',
    icon: settings,
    divider: true,
    iconSelected: settingsDark,
    iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
    children: [
      { label: 'Firm Details', link: 'profile/firm-details' },
      { label: 'Account Details', link: 'profile/account-details' },
      { label: 'Funds', link: 'profile/funds' },
      { label: 'Subscriptions', link: 'profile/subscriptions' },
    ],
    topBorder: true,
    showLogout: true,
    smallIcon: true,
  },
  // {
  //   label: 'Integrations',
  //   link: 'integrations',
  //   icon: integrations_icon,
  //   iconSelected: settingsDark,
  //   iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
  //   children: [
  //     { label: 'CRM Integrations', link: 'integrations/crm' },
  //     { label: 'Marketing Integrations', link: 'integrations/marketing' },
  //     { label: 'Advisors', link: 'integrations/advisors' },
  //   ],
  //   smallIcon: true,
  // },
];

export const NavBar = (props: Props) => {
  const location = useLocation();
  const [focusedSection, setFocusedSection] = useState<any>(null);

  const [focusedMenuOptions, setFocusedMenuOptions] = useState<any>(menuOptions);

  const user = useStoreState((state) => state.user);
  const isStaff = useStoreState((state) => state.user.staff);
  const appType = useStoreState((state) => state.appType);

  const generateMenuOptions = () => {
    const systemSettings = {
      label: 'System Settings',
      link: 'system-management',
      icon: info,
      iconSelected: infoDark,
      topBorder: true,
      iconStyle: { height: '18', width: '18px', margin: '0px 14px 0px ' },
      children: [],
    };

    let newMenuOptions: any = menuOptions;

    if (appType === 'LPL') {
      newMenuOptions = lplMenuOptions;
    } else if (appType === 'FT') {
      newMenuOptions = ftMenuOptions;
    }
    if (isStaff) {
      newMenuOptions = [...newMenuOptions, systemSettings];
    }
    setFocusedMenuOptions(newMenuOptions);
  };

  useEffect(() => {
    generateMenuOptions();
  }, [isStaff, appType]);

  useEffect(() => {
    const splitPath = location.pathname.split('/');

    const focusedOptionArray = focusedMenuOptions.filter((option: any) => {
      return splitPath?.[1] === option.link;
    });
    const newFocusedSection = focusedOptionArray?.[0];
    setFocusedSection(newFocusedSection);
  }, [location]);

  return (
    <NavContainer>
      {focusedSection?.children ? (
        <SectionNav sectionMenuOptions={focusedSection} menuOptions={focusedMenuOptions} />
      ) : (
        <ParentNav menuOptions={focusedMenuOptions} />
      )}
    </NavContainer>
  );
};
