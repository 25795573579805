export const exampleTemplate = [
  [
    'email',
    'repcrd',
    'firstName',
    'lastName',
    'title',
    'age',
    'isRia',
    'isBd',
    'aumSelfReported',
    'officeZipcode',
    'officeState',
    'currentFirmTenureYears',
    'careerTenure',
    'firmName',
    'firmCrd',
    'firmTotalAccounts',
    'firmTotalAssetsInMillions',
    'firmEtfAssetsMillions',
    'firmMfAssetsMillions',
    'percentAssetsInEtfAndMf',
  ],
  [
    'joe.montana@tifin.com',
    '',
    'Joe',
    'Montana',
    '',
    '40',
    '',
    '',
    '33',
    '',
    '',
    '',
    '',
    '',
    'RIA',
    '320',
    '',
    '',
    '',
    '',
  ],
];
