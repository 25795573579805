import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { CREATE_CHAT, REPLY_TO_CHAT } from '../../../api/systemManagement';
import SimpleTable from '../../../components/ChatTable';
import { getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { colors } from '../../../styles';
import Loader from '../../../components/Loader';
import { H2, H4, Row, Column, PrimaryButtonShort, FreeTextInput, F2, F3 } from '../../../components';

export const ChatMessage = ({ message }: any) => {
  const [columns, setColumns] = React.useState([]);
  const [responseData, setResponseData] = React.useState<any[]>([]);
  const [showDataTable, setShowDataTable] = React.useState(false);

  useEffect(() => {
    const cleanedData = [];
    if (Array.isArray(message.queriedData)) {
      const rawColumns = message.queriedData[0];
      const myColumns = message.queriedData[0].map((accessorKey: string, index: number) => ({
        accessorKey: accessorKey,
        innerWidth: 400,
      }));
      const myData = message.queriedData.slice(1);
      if (myData.length > 0) {
        setShowDataTable(true);
      } else {
        setShowDataTable(false);
      }

      for (let datapoint of myData) {
        let myDp: any = {};
        for (let dpIndex in datapoint) {
          myDp[rawColumns[dpIndex]] = datapoint[dpIndex];
        }
        cleanedData.push(myDp);
      }
      setColumns(myColumns);
      setResponseData(cleanedData);
    }
  }, [message]);

  const responseTable = useReactTable({
    columns,
    data: responseData ?? [],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Column
      style={{
        backgroundColor:
          message.chatType === 'USER_MESSAGE'
            ? colors.purple
            : message.chatType === 'AMP_ERROR'
            ? colors.red
            : colors.white,
        // overflowX: 'scroll',
        padding: '10px 20px',
        borderRadius: '5px',
        border: `1px solid ${colors.grey_01}`,
        margin: '10px 0px',
      }}
    >
      <H4 style={{ color: colors.grey_02 }}>{message.chatType === 'USER_MESSAGE' ? 'YOU' : 'AMP CHAT'}</H4>
      <F2 style={{ color: colors.grey_03, border: `0px solid ${colors.grey_01}` }}>
        {Array.isArray(message.queriedData) ? message.message.split('RAW QUERY')[0] : message?.message}
      </F2>
      {showDataTable && (
        <Column
          style={{
            backgroundColor: colors.grey_01,
            padding: '8px 10px',
            margin: '8px 0px 6px',
            borderRadius: '5px',
            overflowX: 'scroll',
          }}
        >
          <SimpleTable tableData={responseTable} searchBar={false} />
        </Column>
      )}
    </Column>
  );
};

export const AdvisorChat = () => {
  const [userInput, setUserInput] = React.useState('');
  const [currentChatId, setCurrentChatId] = React.useState<string>('');
  const [chatMessages, setChatMessages] = React.useState<any[]>([]);

  useEffect(() => {
    const handleSubmit = (event: any) => {
      //   console.log('handle submit: ', event);
      if (event?.keyCode === 13 && !updateLoading) {
        // Prevent the default action to stop from submitting a form if in one
        event.preventDefault();
        // Get the button element by its ID and click it
        sendMessage();
      }
    };
    window?.addEventListener('keydown', handleSubmit);
    return () => {
      window?.removeEventListener('keydown', handleSubmit);
    };
  });

  const [submitChat, { data: myData, loading: submitLoading }] = useMutation(CREATE_CHAT, {
    variables: { prompt: userInput },
    onCompleted: (data) => {
      setChatMessages(data.createChat?.chat?.segmentationchatmessageSet || []);
      setUserInput('');
      setCurrentChatId(myData?.createChat?.chat?.id);
      scrollToBottom();
    },
  });

  const [updateChat, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(REPLY_TO_CHAT, {
    variables: { message: userInput, chatId: currentChatId },
    onCompleted: (data) => {
      setChatMessages(data.replyToChat?.chat?.segmentationchatmessageSet || []);
      setUserInput('');
    },
  });

  function sendMessage() {
    if (currentChatId) {
      updateChat();
    } else {
      submitChat();
    }
  }

  const scrollToBottom = () => {
    const chatDiv = document.getElementById('scrollable-chat');
    chatDiv?.scrollTo(0, chatDiv.scrollHeight);
  };

  const formatErrorMessage = (input: any) => {
    return {
      chatType: 'AMP_ERROR',
      message: input?.message,
      queriedData: [],
    };
  };

  return (
    <div
      style={{
        padding: '20px 0px',
        borderColor: colors.grey_15,
        borderWidth: 1,
        borderStyle: 'solid',
        margin: '20px 0px',
        width: '800px',
        borderRadius: '5px',
      }}
    >
      <Column style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '0px 20px 8px' }}>
        <H2 style={{ color: colors.grey_03 }}>What can I help you with today?</H2>
        <F3 style={{ color: colors.grey_02 }}>I can help you analyze and answer questions about your advisors.</F3>
      </Column>
      <Column
        id={'scrollable-chat'}
        style={{
          padding: '28px',
          overflowY: 'scroll',
          maxHeight: '500px',
          minHeight: '300px',
          backgroundColor: colors.grey_00 + '50',
        }}
      >
        {chatMessages.map((message, index) => {
          return <ChatMessage key={index + 1291410349} message={message} />;
        })}
        {updateError && <ChatMessage key={34893452} message={formatErrorMessage(updateError)} />}
      </Column>
      <Column style={{ borderTop: `1px solid ${colors.grey_01}`, padding: '20px 20px 0px' }}>
        <Row
          style={{
            border: `1px solid ${colors.grey_15}`,
            borderRadius: '5px',
            alignItems: 'center',
            padding: '0px 8px 0px 0px',
          }}
        >
          <FreeTextInput
            id={'chat-input'}
            value={userInput}
            onChange={(event) => setUserInput(event.target.value)}
            rows={1}
            size="small"
            placeholder="Type your query here..."
            style={{ borderRadius: '5px 0px 0px 5px', border: `0px solid ${colors.grey_15}` }}
          />
          <Row style={{ padding: '0px 0px 0px 8px', borderLeft: `1px solid ${colors.grey_01}` }}>
            <PrimaryButtonShort
              id={'submit-chat-button'}
              onClick={(e) => sendMessage()}
              type="submit"
              disabled={updateLoading || submitLoading}
            >
              {updateLoading || submitLoading ? <Loader color={colors.white} hideText size={'10px'} /> : 'Query'}
            </PrimaryButtonShort>
          </Row>
        </Row>
      </Column>
    </div>
  );
};
