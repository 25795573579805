import React from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../styles';
import {
  ZoomOutButton,
  ContentDescription,
  ContentContainer,
  ContentTitle,
  ContentHeader,
  ContentSubTitle,
  PrimaryButtonShort,
  SecondaryButtonShort,
  Row,
} from '.';
import { ButtonType } from '../types';

type Props = {
  buttonText: string;
  buttonAction: any;
  subtitle: string;
  title: string;
  description: string;
  secondaryButton?: ButtonType;
  primaryButton?: ButtonType;
  backgroundImage?: any;
};

const SectionBlock = styled('div')`
  height: 40px;
  width: 100%;
  background-color: ${colors.black_01};
`;

export const ZoomOutButtonGray = styled('div')`
  color: #acacac;
  cursor: pointer;
  z-index: 10000;
  font-size: 14px;
  font-weight: 500px;
  margin: 20px auto 50px;
  display: flex;
  width: fit-content;
  justifycontent: center;
`;

export const ContentImageHeader = styled('div')`
  display: table;
  width: 100%;
  padding: 0px 0 60px;
  min-height: 360px;
  background: #191a1d;
  position: relative;
  z-index: 999;
`;

export const ContentInnerHeader = styled('div')`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  z-index: -1;
`;

export const BackgroundImage = styled('img')`
  height: 360px;
  display: flex;
  margin: 0;
`;

export const SectionPageHeader = ({
  buttonText,
  buttonAction,
  subtitle,
  title,
  description,
  secondaryButton,
  primaryButton,
  backgroundImage,
}: Props) => {
  if (backgroundImage) {
    return (
      <ContentImageHeader
      // sx={{
      //   backgroundImage: `url(${backgroundImage})`,
      //   resize: 'cover',
      //   backgroundSize: '100% auto',
      //   backgroundRepeat: 'no-repeat',
      //   minHeight: 360
      // }}
      >
        <ContentInnerHeader>
          <BackgroundImage src={backgroundImage} alt="background" />
        </ContentInnerHeader>
        <div style={{ zIndex: 999 }}>
          <ZoomOutButtonGray id="ZoomOutButtonGray" onClick={buttonAction}>
            {buttonText}
          </ZoomOutButtonGray>
          <ContentSubTitle sx={{ color: ' #ACACAC' }}>{subtitle}</ContentSubTitle>
          <ContentTitle sx={{ color: ' #ACACAC' }}>{title}</ContentTitle>
          <ContentDescription sx={{ width: '28%', color: ' #ACACAC' }}>{description}</ContentDescription>
        </div>
      </ContentImageHeader>
    );
  }
  return (
    <>
      <ContentHeader>
        <ZoomOutButton
          id="ZoomOutButton"
          sx={{ margin: '10px auto 50px', display: 'flex', justifyContent: 'center' }}
          onClick={buttonAction}
        >
          {buttonText}
        </ZoomOutButton>
        <ContentSubTitle>{subtitle}</ContentSubTitle>
        <ContentTitle>{title}</ContentTitle>
        <ContentDescription sx={{ width: '28%' }}>{description}</ContentDescription>
        <Row style={{ justifyContent: 'center' }}>
          {secondaryButton && (
            <SecondaryButtonShort
              style={{
                background: '#FFFFFF00',
                color: colors.black,
                border: `1px solid ${colors.black}`,
                margin: '20px 4px 0px',
              }}
              onClick={secondaryButton.action}
            >
              {secondaryButton.text}
            </SecondaryButtonShort>
          )}
          {primaryButton && (
            <PrimaryButtonShort style={{ margin: '20px 4px 0px' }} onClick={primaryButton.action}>
              {primaryButton.text}
            </PrimaryButtonShort>
          )}
        </Row>
      </ContentHeader>
      <SectionBlock />
    </>
  );
};
