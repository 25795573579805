export const firmNames = [
  {
    label: 'Piper Sandler & Co.',
    value: [{ value: 'Piper Sandler & Co.', key: 'firm_name' }],
  },
  {
    label: 'BSW Wealth Partners',
    value: [{ value: 'BSW Wealth Partners', key: 'firm_name' }],
  },
  {
    label: 'Janney Capital Management LLC',
    value: [{ value: 'Janney Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Janney Montgomery Scott LLC',
    value: [{ value: 'Janney Montgomery Scott LLC', key: 'firm_name' }],
  },
  {
    label: 'Detterbeck Wealth Management',
    value: [{ value: 'Detterbeck Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Northshire Consulting, LLC',
    value: [{ value: 'Northshire Consulting, LLC', key: 'firm_name' }],
  },
  {
    label: 'Commerce Advisors, LLC',
    value: [{ value: 'Commerce Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'SG Americas Securities, LLC',
    value: [{ value: 'SG Americas Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Halcyon Financial Planning, LLC',
    value: [{ value: 'Halcyon Financial Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'NYLIFE Distributors LLC',
    value: [{ value: 'NYLIFE Distributors LLC', key: 'firm_name' }],
  },
  {
    label: 'Resolute Partners Group',
    value: [{ value: 'Resolute Partners Group', key: 'firm_name' }],
  },
  {
    label: 'Summit Wealth Partners, LLC',
    value: [{ value: 'Summit Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cetera Advisor Networks LLC',
    value: [{ value: 'Cetera Advisor Networks LLC', key: 'firm_name' }],
  },
  {
    label: 'Kestra Advisory Services, LLC',
    value: [{ value: 'Kestra Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kingsview Wealth Management, LLC',
    value: [{ value: 'Kingsview Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'LPL Financial LLC',
    value: [{ value: 'LPL Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Dempsey Lord Smith, LLC',
    value: [{ value: 'Dempsey Lord Smith, LLC', key: 'firm_name' }],
  },
  {
    label: 'Main Street Financial Life Advisors, LLC',
    value: [{ value: 'Main Street Financial Life Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Radvanyi Wealth Management LLC',
    value: [{ value: 'Radvanyi Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Vida Private Wealth LLC',
    value: [{ value: 'Vida Private Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Physicians Thrive Investment Advisors, LLC',
    value: [{ value: 'Physicians Thrive Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'BTIG, LLC',
    value: [{ value: 'BTIG, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vantage Financial Group, Inc.',
    value: [{ value: 'Vantage Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Dixon Hughes Goodman Wealth Advisors LLC',
    value: [{ value: 'Dixon Hughes Goodman Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'UBS Financial Services Inc.',
    value: [{ value: 'UBS Financial Services Inc.', key: 'firm_name' }],
  },
  {
    label: 'Snowden Capital Advisors LLC',
    value: [{ value: 'Snowden Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'F3Logic, LLC',
    value: [{ value: 'F3Logic, LLC', key: 'firm_name' }],
  },
  {
    label: 'Summit Financial, LLC',
    value: [{ value: 'Summit Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kovack Advisors, Inc.',
    value: [{ value: 'Kovack Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mutual of Omaha Investor Services, Inc.',
    value: [{ value: 'Mutual of Omaha Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wescott Financial Advisory Group LLC',
    value: [{ value: 'Wescott Financial Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Corrigan Financial Inc',
    value: [{ value: 'Corrigan Financial Inc', key: 'firm_name' }],
  },
  {
    label: 'Wealthwise Financial Solutions, LLC',
    value: [{ value: 'Wealthwise Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Park Avenue Securities LLC',
    value: [{ value: 'Park Avenue Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Golden Trail Advisers',
    value: [{ value: 'Golden Trail Advisers', key: 'firm_name' }],
  },
  {
    label: 'Royal Alliance Associates, Inc.',
    value: [{ value: 'Royal Alliance Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'MML Investors Services, LLC',
    value: [{ value: 'MML Investors Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'PFS Investments Inc.',
    value: [{ value: 'PFS Investments Inc.', key: 'firm_name' }],
  },
  {
    label: 'YHB Investment Advisors Inc',
    value: [{ value: 'YHB Investment Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Aries Wealth Management, LLC',
    value: [{ value: 'Aries Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Correll Co. Investment Services Corp.',
    value: [{ value: 'Correll Co. Investment Services Corp.', key: 'firm_name' }],
  },
  {
    label: 'Mullooly Asset Management, Inc.',
    value: [{ value: 'Mullooly Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Transamerica Financial Advisors, Inc',
    value: [{ value: 'Transamerica Financial Advisors, Inc', key: 'firm_name' }],
  },
  {
    label: 'NetWorth Financial Services, LLC',
    value: [{ value: 'NetWorth Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Certis Capital Management, Inc.',
    value: [{ value: 'Certis Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kingsbury Capital Investment Advisors LLC',
    value: [{ value: 'Kingsbury Capital Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Gradient Advisors, LLC',
    value: [{ value: 'Gradient Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'J. W. Cole Advisors, Inc.',
    value: [{ value: 'J. W. Cole Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Franklin Distributors, LLC',
    value: [{ value: 'Franklin Distributors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Houston Wealth Management',
    value: [{ value: 'Houston Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Dechtman Wealth Management',
    value: [{ value: 'Dechtman Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Accurate Wealth Management, LLC',
    value: [{ value: 'Accurate Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'McGuire Financial Solutions',
    value: [{ value: 'McGuire Financial Solutions', key: 'firm_name' }],
  },
  {
    label: 'Avantax Investment Services, Inc.',
    value: [{ value: 'Avantax Investment Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'F L Putnam Investment Management Co.',
    value: [{ value: 'F L Putnam Investment Management Co.', key: 'firm_name' }],
  },
  {
    label: 'Emergent Global Investments',
    value: [{ value: 'Emergent Global Investments', key: 'firm_name' }],
  },
  {
    label: 'Baron Capital, Inc.',
    value: [{ value: 'Baron Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Texas Legacy Wealth Management',
    value: [{ value: 'Texas Legacy Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Tocqueville Securities L.P.',
    value: [{ value: 'Tocqueville Securities L.P.', key: 'firm_name' }],
  },
  {
    label: 'Marcus Capital Management, Inc.',
    value: [{ value: 'Marcus Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Hefren-Tillotson, Inc.',
    value: [{ value: 'Hefren-Tillotson, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Washington Crossing Advisors LLC',
    value: [{ value: 'Washington Crossing Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Pyramid Funds Corporation',
    value: [{ value: 'Pyramid Funds Corporation', key: 'firm_name' }],
  },
  {
    label: 'Midwestern Securities Trading Company, LLC',
    value: [{ value: 'Midwestern Securities Trading Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Atticus Wealth Management',
    value: [{ value: 'Atticus Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'MLN Investments LLC',
    value: [{ value: 'MLN Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Sage Capital Management LLC',
    value: [{ value: 'Sage Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Analysts',
    value: [{ value: 'Capital Analysts', key: 'firm_name' }],
  },
  {
    label: 'Steinmetz Financial Planning',
    value: [{ value: 'Steinmetz Financial Planning', key: 'firm_name' }],
  },
  {
    label: 'Holderness Investments Co',
    value: [{ value: 'Holderness Investments Co', key: 'firm_name' }],
  },
  {
    label: 'EMFO, LLC',
    value: [{ value: 'EMFO, LLC', key: 'firm_name' }],
  },
  {
    label: 'NBC Securities, Inc.',
    value: [{ value: 'NBC Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wealthspire Advisors',
    value: [{ value: 'Wealthspire Advisors', key: 'firm_name' }],
  },
  {
    label: 'Hantz Financial Services, Inc.',
    value: [{ value: 'Hantz Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Thrivent Advisor Network, LLC',
    value: [{ value: 'Thrivent Advisor Network, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kalos Management',
    value: [{ value: 'Kalos Management', key: 'firm_name' }],
  },
  {
    label: 'RiverFront Investment Group, LLC',
    value: [{ value: 'RiverFront Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Granite Group Advisors, LLC',
    value: [{ value: 'Granite Group Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rareview Capital LLC',
    value: [{ value: 'Rareview Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Apexium Financial LP',
    value: [{ value: 'Apexium Financial LP', key: 'firm_name' }],
  },
  {
    label: 'Bell Rock Capital, LLC',
    value: [{ value: 'Bell Rock Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'FBL Wealth Management, LLC',
    value: [{ value: 'FBL Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Foreside Fund Services, LLC',
    value: [{ value: 'Foreside Fund Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'BlackRock Investments, LLC',
    value: [{ value: 'BlackRock Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Strategic Financial Alliance, Inc.',
    value: [{ value: 'The Strategic Financial Alliance, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ashton Thomas Private Wealth',
    value: [{ value: 'Ashton Thomas Private Wealth', key: 'firm_name' }],
  },
  {
    label: 'JQR Capital Management, LLC',
    value: [{ value: 'JQR Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'API Financial Advisors, LLC',
    value: [{ value: 'API Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vanguard Advisers, Inc.',
    value: [{ value: 'Vanguard Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'NFP Retirement Inc.',
    value: [{ value: 'NFP Retirement Inc.', key: 'firm_name' }],
  },
  {
    label: 'Esplanade Capital LLC',
    value: [{ value: 'Esplanade Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'FSC Securities Corporation',
    value: [{ value: 'FSC Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Capital Financial Group, Inc.',
    value: [{ value: 'Capital Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: '20/20 Capital Management, Inc',
    value: [{ value: '20/20 Capital Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'John Hancock Investment Management Distributors LLC',
    value: [{ value: 'John Hancock Investment Management Distributors LLC', key: 'firm_name' }],
  },
  {
    label: 'myCIO Wealth Partners, LLC',
    value: [{ value: 'myCIO Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Tri-Star Advisors, Inc.',
    value: [{ value: 'Tri-Star Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Evergreen Wealth Solutions, LLC',
    value: [{ value: 'Evergreen Wealth Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Companions On Your Journey',
    value: [{ value: 'Companions On Your Journey', key: 'firm_name' }],
  },
  {
    label: 'Navigoe, LLC',
    value: [{ value: 'Navigoe, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sequoia Wealth Advisors & Investment Management, Inc.',
    value: [{ value: 'Sequoia Wealth Advisors & Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Investment Advisors, Inc.',
    value: [{ value: 'Investment Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Castle Investment Management, LLC',
    value: [{ value: 'Castle Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Core Financial',
    value: [{ value: 'Core Financial', key: 'firm_name' }],
  },
  {
    label: 'Alpha Asset Advisors LLC',
    value: [{ value: 'Alpha Asset Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'IBN Financial Services, Inc.',
    value: [{ value: 'IBN Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SL Advisors, LLC',
    value: [{ value: 'SL Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'OceanIQ Capital, LLC',
    value: [{ value: 'OceanIQ Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Union Capital Company',
    value: [{ value: 'Union Capital Company', key: 'firm_name' }],
  },
  {
    label: 'StillPoint Capital, LLC',
    value: [{ value: 'StillPoint Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rockefeller Financial LLC',
    value: [{ value: 'Rockefeller Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'The Absolute Return, LLC',
    value: [{ value: 'The Absolute Return, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fisher Investments',
    value: [{ value: 'Fisher Investments', key: 'firm_name' }],
  },
  {
    label: 'Trans-Asset, LLC',
    value: [{ value: 'Trans-Asset, LLC', key: 'firm_name' }],
  },
  {
    label: 'XML Financial Group',
    value: [{ value: 'XML Financial Group', key: 'firm_name' }],
  },
  {
    label: 'First Financial Advisory Services Inc',
    value: [{ value: 'First Financial Advisory Services Inc', key: 'firm_name' }],
  },
  {
    label: 'Integrity Wealth Management LLC',
    value: [{ value: 'Integrity Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Summa Global Advisors, LLC',
    value: [{ value: 'Summa Global Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Watch Advisors, LLC',
    value: [{ value: 'Wealth Watch Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'High Note Wealth, LLC',
    value: [{ value: 'High Note Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Investment Advisors',
    value: [{ value: 'Pinnacle Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Future Benefits Planning Corporation',
    value: [{ value: 'Future Benefits Planning Corporation', key: 'firm_name' }],
  },
  {
    label: 'Wealth Advisors Group',
    value: [{ value: 'Wealth Advisors Group', key: 'firm_name' }],
  },
  {
    label: 'Bay Colony Advisors',
    value: [{ value: 'Bay Colony Advisors', key: 'firm_name' }],
  },
  {
    label: 'Presidio Financial, LLC',
    value: [{ value: 'Presidio Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fortis Capital Management LLC',
    value: [{ value: 'Fortis Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Christopher Weil & Co Inc',
    value: [{ value: 'Christopher Weil & Co Inc', key: 'firm_name' }],
  },
  {
    label: 'Excel Securities & Associates, Inc.',
    value: [{ value: 'Excel Securities & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Conestoga Wealth Partners',
    value: [{ value: 'Conestoga Wealth Partners', key: 'firm_name' }],
  },
  {
    label: 'Island Wealth Management',
    value: [{ value: 'Island Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Crews & Associates, Inc.',
    value: [{ value: 'Crews & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'NewEdge Advisors',
    value: [{ value: 'NewEdge Advisors', key: 'firm_name' }],
  },
  {
    label: 'Harmony Asset Management LLC',
    value: [{ value: 'Harmony Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Putnam Investment Management, LLC',
    value: [{ value: 'Putnam Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kelly Financial Services LLC',
    value: [{ value: 'Kelly Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Money Concepts Capital Corp',
    value: [{ value: 'Money Concepts Capital Corp', key: 'firm_name' }],
  },
  {
    label: 'Cornerstone Financial',
    value: [{ value: 'Cornerstone Financial', key: 'firm_name' }],
  },
  {
    label: 'D.S. Brown Wealth Advisors, LLC',
    value: [{ value: 'D.S. Brown Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rubicon Advisors',
    value: [{ value: 'Rubicon Advisors', key: 'firm_name' }],
  },
  {
    label: 'Phillips Capital Advisors, Inc.',
    value: [{ value: 'Phillips Capital Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'FWI Wealth Management LLC',
    value: [{ value: 'FWI Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Mutual Advisors, LLC',
    value: [{ value: 'Mutual Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Boenning & Scattergood, Inc.',
    value: [{ value: 'Boenning & Scattergood, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Advisory Services Network',
    value: [{ value: 'Advisory Services Network', key: 'firm_name' }],
  },
  {
    label: 'U. S. Boston Capital Corporation',
    value: [{ value: 'U. S. Boston Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Vicus Capital, Inc.',
    value: [{ value: 'Vicus Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Benchmark Wealth Management, LLC',
    value: [{ value: 'Benchmark Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Waterford Group, LLC',
    value: [{ value: 'The Waterford Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Safe Harbor Financial Advisors, LLC',
    value: [{ value: 'Safe Harbor Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Trust Advisory Group Ltd',
    value: [{ value: 'Trust Advisory Group Ltd', key: 'firm_name' }],
  },
  {
    label: 'ZWJ Investment Counsel Inc',
    value: [{ value: 'ZWJ Investment Counsel Inc', key: 'firm_name' }],
  },
  {
    label: 'Arcus Capital Partners, LLC',
    value: [{ value: 'Arcus Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Intercarolina Financial Services, Inc.',
    value: [{ value: 'Intercarolina Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kisco Capital, LLC',
    value: [{ value: 'Kisco Capital, LLC', key: 'firm_name' }],
  },
  {
    label: '4612 Group, LLC',
    value: [{ value: '4612 Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Garmark Advisors II L.L.C.',
    value: [{ value: 'Garmark Advisors II L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Midwest Financial Partners',
    value: [{ value: 'Midwest Financial Partners', key: 'firm_name' }],
  },
  {
    label: 'ICAP Corporates LLC',
    value: [{ value: 'ICAP Corporates LLC', key: 'firm_name' }],
  },
  {
    label: 'Janus Henderson Distributors US LLC',
    value: [{ value: 'Janus Henderson Distributors US LLC', key: 'firm_name' }],
  },
  {
    label: 'Baer Wealth Management',
    value: [{ value: 'Baer Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'TJT Capital Group, LLC',
    value: [{ value: 'TJT Capital Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'OJM Group, LLC',
    value: [{ value: 'OJM Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Roundview Capital, LLC',
    value: [{ value: 'Roundview Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hennion & Walsh Asset Management, Inc.',
    value: [{ value: 'Hennion & Walsh Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Capitol Securities Management, Inc.',
    value: [{ value: 'Capitol Securities Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'AE Wealth Management, LLC',
    value: [{ value: 'AE Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Centennial Advisors, LLC',
    value: [{ value: 'Centennial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Roseline Capitol Advisors, LLC',
    value: [{ value: 'Roseline Capitol Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Allstate Financial Advisors, LLC',
    value: [{ value: 'Allstate Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lazard Asset Management Securities LLC',
    value: [{ value: 'Lazard Asset Management Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Encompass Wealth Advisors, LLC',
    value: [{ value: 'Encompass Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Baker Street Advisors, LLC',
    value: [{ value: 'Baker Street Advisors, LLC', key: 'firm_name' }],
  },
  //   {
  // label:' Financial Services of America A Limited Partner",
  // value:[{value:' Financial Services of America A Limited Partner",key:'firm_name'}]
  //   },
  {
    label: 'FocalPoint Securities, LLC',
    value: [{ value: 'FocalPoint Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sheth Group',
    value: [{ value: 'Sheth Group', key: 'firm_name' }],
  },
  {
    label: 'FAI Advisors, Inc.',
    value: [{ value: 'FAI Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Synergy Investment Group, LLC',
    value: [{ value: 'Synergy Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: '1st Financial Investment, Inc.',
    value: [{ value: '1st Financial Investment, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Preferred Wealth Advisors, LLC',
    value: [{ value: 'Preferred Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'JT Stratford, LLC',
    value: [{ value: 'JT Stratford, LLC', key: 'firm_name' }],
  },
  {
    label: 'WNY Asset Management, LLC',
    value: [{ value: 'WNY Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Credit Suisse Securities (USA) LLC',
    value: [{ value: 'Credit Suisse Securities (USA) LLC', key: 'firm_name' }],
  },
  {
    label: 'Plaisier Financial Group LLC',
    value: [{ value: 'Plaisier Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Chestnut Street Investment Counsel, LLC',
    value: [{ value: 'Chestnut Street Investment Counsel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Allen Capital Group, LLC',
    value: [{ value: 'Allen Capital Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'R Gregory Souder',
    value: [{ value: 'R Gregory Souder', key: 'firm_name' }],
  },
  {
    label: 'Your Best Path, LLC',
    value: [{ value: 'Your Best Path, LLC', key: 'firm_name' }],
  },
  {
    label: 'LCM Capital Management Inc',
    value: [{ value: 'LCM Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Cash Flow Investment Services LLC',
    value: [{ value: 'Cash Flow Investment Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Deschutes Investment Consulting, LLC',
    value: [{ value: 'Deschutes Investment Consulting, LLC', key: 'firm_name' }],
  },
  {
    label: 'Straightway Financial LLC',
    value: [{ value: 'Straightway Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Physicians Wealth Solutions, LLC',
    value: [{ value: 'Physicians Wealth Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sandy Cove Advisors, LLC',
    value: [{ value: 'Sandy Cove Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rabenold Advisors, Inc.',
    value: [{ value: 'Rabenold Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'CornerCap Investment Counsel Inc',
    value: [{ value: 'CornerCap Investment Counsel Inc', key: 'firm_name' }],
  },
  {
    label: 'Mottet Wealth',
    value: [{ value: 'Mottet Wealth', key: 'firm_name' }],
  },
  {
    label: 'Edgewood Capital Advisors, LLC',
    value: [{ value: 'Edgewood Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sweeney & Michel, LLC',
    value: [{ value: 'Sweeney & Michel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Chelsea Advisory Services, Inc',
    value: [{ value: 'Chelsea Advisory Services, Inc', key: 'firm_name' }],
  },
  {
    label: 'Ganim Financial',
    value: [{ value: 'Ganim Financial', key: 'firm_name' }],
  },
  {
    label: 'Stash Invest',
    value: [{ value: 'Stash Invest', key: 'firm_name' }],
  },
  {
    label: 'Financial Synergies',
    value: [{ value: 'Financial Synergies', key: 'firm_name' }],
  },
  {
    label: 'Elser Financial Planning, Inc.',
    value: [{ value: 'Elser Financial Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Guardian Asset Advisors, LLC',
    value: [{ value: 'Guardian Asset Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Icon Wealth Partners, LLC',
    value: [{ value: 'Icon Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Saling, Simms, Associates Inc.',
    value: [{ value: 'Saling, Simms, Associates Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fidelis Capital, LLC',
    value: [{ value: 'Fidelis Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Turning Point Financial',
    value: [{ value: 'Turning Point Financial', key: 'firm_name' }],
  },
  {
    label: 'Beckett Collective',
    value: [{ value: 'Beckett Collective', key: 'firm_name' }],
  },
  {
    label: 'Cobblestone Wealth Advisors',
    value: [{ value: 'Cobblestone Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'National Asset Management, Inc.',
    value: [{ value: 'National Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Charter Oak Capital Management',
    value: [{ value: 'Charter Oak Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Cape Investment Advisory, Inc.',
    value: [{ value: 'Cape Investment Advisory, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bird Dog Trading Corporation',
    value: [{ value: 'Bird Dog Trading Corporation', key: 'firm_name' }],
  },
  {
    label: 'Summit Financial Wealth Advisors',
    value: [{ value: 'Summit Financial Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Orion Portfolio Solutions, LLC',
    value: [{ value: 'Orion Portfolio Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fifth Avenue Wealth Advisors LLC',
    value: [{ value: 'Fifth Avenue Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Grey Investment Planning',
    value: [{ value: 'Grey Investment Planning', key: 'firm_name' }],
  },
  {
    label: 'Better Money Decisions',
    value: [{ value: 'Better Money Decisions', key: 'firm_name' }],
  },
  {
    label: 'ACG Wealth Inc.',
    value: [{ value: 'ACG Wealth Inc.', key: 'firm_name' }],
  },
  {
    label: 'Plotkin Financial Advisors, LLC',
    value: [{ value: 'Plotkin Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CUSO Financial Services, L.P.',
    value: [{ value: 'CUSO Financial Services, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Peak Asset Management, LLC',
    value: [{ value: 'Peak Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'TimeScale Financial, Inc.',
    value: [{ value: 'TimeScale Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First Advisors National, LLC',
    value: [{ value: 'First Advisors National, LLC', key: 'firm_name' }],
  },
  {
    label: 'Northwest Wealth Management, LLC',
    value: [{ value: 'Northwest Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pierson Wealth Management',
    value: [{ value: 'Pierson Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Schmetter & Associates LLC',
    value: [{ value: 'Schmetter & Associates LLC', key: 'firm_name' }],
  },
  {
    label: 'Regal Bay Investment Group, LLC',
    value: [{ value: 'Regal Bay Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rina Wealth Management Services LLC',
    value: [{ value: 'Rina Wealth Management Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Cargile Investments',
    value: [{ value: 'Cargile Investments', key: 'firm_name' }],
  },
  {
    label: 'Frasca & Associates LLC',
    value: [{ value: 'Frasca & Associates LLC', key: 'firm_name' }],
  },
  {
    label: 'Ardent Capital Management, Inc.',
    value: [{ value: 'Ardent Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Security Distributors',
    value: [{ value: 'Security Distributors', key: 'firm_name' }],
  },
  {
    label: 'BBR Partners, LLC',
    value: [{ value: 'BBR Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'RCM Wealth Advisors',
    value: [{ value: 'RCM Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Yukon Wealth Management, Inc.',
    value: [{ value: 'Yukon Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Pragmatic Financial Strategies, Inc',
    value: [{ value: 'Pragmatic Financial Strategies, Inc', key: 'firm_name' }],
  },
  {
    label: 'P.J. Robb Variable Corporation',
    value: [{ value: 'P.J. Robb Variable Corporation', key: 'firm_name' }],
  },
  {
    label: 'Logan Capital Management, Inc.',
    value: [{ value: 'Logan Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Independence Capital Co., Inc.',
    value: [{ value: 'Independence Capital Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Integrated Management Services SPV LLC',
    value: [{ value: 'Integrated Management Services SPV LLC', key: 'firm_name' }],
  },
  {
    label: 'Gateway Advisory, LLC',
    value: [{ value: 'Gateway Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Godshalk Welsh Capital Management, Inc.',
    value: [{ value: 'Godshalk Welsh Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sage Advisory Services Ltd Co',
    value: [{ value: 'Sage Advisory Services Ltd Co', key: 'firm_name' }],
  },
  {
    label: 'Arcadia Investment Management Corp',
    value: [{ value: 'Arcadia Investment Management Corp', key: 'firm_name' }],
  },
  {
    label: 'Equilibrium Wealth LLC',
    value: [{ value: 'Equilibrium Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Adaptive Financial Consulting, LLC',
    value: [{ value: 'Adaptive Financial Consulting, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Pinnacle Financial Group',
    value: [{ value: 'The Pinnacle Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Trinity Capital Management, LLC',
    value: [{ value: 'Trinity Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Campbell Wealth Advisors, LLC',
    value: [{ value: 'Campbell Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'EP Wealth Advisors',
    value: [{ value: 'EP Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Credit Agricole Securities (USA) Inc.',
    value: [{ value: 'Credit Agricole Securities (USA) Inc.', key: 'firm_name' }],
  },
  {
    label: 'Blue Diamond Financial Coaching LLC',
    value: [{ value: 'Blue Diamond Financial Coaching LLC', key: 'firm_name' }],
  },
  {
    label: 'Copeland Wealth Management, LLC',
    value: [{ value: 'Copeland Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Henley & Company LLC',
    value: [{ value: 'Henley & Company LLC', key: 'firm_name' }],
  },
  {
    label: 'E*TRADE Capital Management, LLC',
    value: [{ value: 'E*TRADE Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gagni Asset Management LLC',
    value: [{ value: 'Gagni Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Mitchell, McLeod, Pugh & Williams, Inc.',
    value: [{ value: 'Mitchell, McLeod, Pugh & Williams, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Peak Wealth Solutions',
    value: [{ value: 'Peak Wealth Solutions', key: 'firm_name' }],
  },
  {
    label: 'Kaydan Wealth Management, Inc.',
    value: [{ value: 'Kaydan Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WT Wealth Management, LLC',
    value: [{ value: 'WT Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cereus Financial Advisors, LLC',
    value: [{ value: 'Cereus Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Perkins Fund Marketing, L.L.C.',
    value: [{ value: 'Perkins Fund Marketing, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Fortress Wealth Management, Inc.',
    value: [{ value: 'Fortress Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Billow Butler & Company, L.L.C.',
    value: [{ value: 'Billow Butler & Company, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'World Equity Group, Inc.',
    value: [{ value: 'World Equity Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'All One Wealth, LLC',
    value: [{ value: 'All One Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Legacy Associates',
    value: [{ value: 'Financial Legacy Associates', key: 'firm_name' }],
  },
  {
    label: 'Confluence Investment Management LLC',
    value: [{ value: 'Confluence Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Hazard & Siegel Advisory Services LLC',
    value: [{ value: 'Hazard & Siegel Advisory Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Destra Capital Advisors LLC',
    value: [{ value: 'Destra Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Partners Group, Inc.',
    value: [{ value: 'Financial Partners Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ballast, Inc.',
    value: [{ value: 'Ballast, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ally Invest Securities LLC',
    value: [{ value: 'Ally Invest Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Sadoff Investment Management LLC',
    value: [{ value: 'Sadoff Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'W10 Investment Advisers, LLC',
    value: [{ value: 'W10 Investment Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Future Finances Inc',
    value: [{ value: 'Future Finances Inc', key: 'firm_name' }],
  },
  {
    label: 'Grange Capital, LLC',
    value: [{ value: 'Grange Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Thor Wealth Management Inc',
    value: [{ value: 'Thor Wealth Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Probity Advisors, Inc.',
    value: [{ value: 'Probity Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Geder Investment Management, Inc.',
    value: [{ value: 'Geder Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Narus Financial Partners, LLC',
    value: [{ value: 'Narus Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'SunRift Capital Partners LLC',
    value: [{ value: 'SunRift Capital Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Sowell Management',
    value: [{ value: 'Sowell Management', key: 'firm_name' }],
  },
  {
    label: 'Capital Planning LLC',
    value: [{ value: 'Capital Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'Axiom Capital Management, Inc.',
    value: [{ value: 'Axiom Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Pacific Portfolio Consulting, LLC',
    value: [{ value: 'Pacific Portfolio Consulting, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pension Maxima Investment Advisory, Inc.',
    value: [{ value: 'Pension Maxima Investment Advisory, Inc.', key: 'firm_name' }],
  },
  {
    label: 'DSW Investment, LLC',
    value: [{ value: 'DSW Investment, LLC', key: 'firm_name' }],
  },
  {
    label: 'Level Four Advisory Services',
    value: [{ value: 'Level Four Advisory Services', key: 'firm_name' }],
  },
  {
    label: 'Aspire Wealth Management Corporation',
    value: [{ value: 'Aspire Wealth Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'Charitable Foundation Services LLC',
    value: [{ value: 'Charitable Foundation Services LLC', key: 'firm_name' }],
  },
  {
    label: 'ElderAdo Financial, Inc',
    value: [{ value: 'ElderAdo Financial, Inc', key: 'firm_name' }],
  },
  {
    label: 'The Advisors Group',
    value: [{ value: 'The Advisors Group', key: 'firm_name' }],
  },
  {
    label: 'Smith, Brown & Groover, Inc.',
    value: [{ value: 'Smith, Brown & Groover, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Illuminate Wealth Management',
    value: [{ value: 'Illuminate Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Nationwide Planning Associates Inc.',
    value: [{ value: 'Nationwide Planning Associates Inc.', key: 'firm_name' }],
  },
  {
    label: 'MRP Capital Investments, LLC',
    value: [{ value: 'MRP Capital Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Reliable Alliance Financial',
    value: [{ value: 'Reliable Alliance Financial', key: 'firm_name' }],
  },
  {
    label: 'Pope Capital, LLC',
    value: [{ value: 'Pope Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'SFMG Wealth Advisors',
    value: [{ value: 'SFMG Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Blue Bell Private Wealth Management, LLC',
    value: [{ value: 'Blue Bell Private Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Geneva Partners, LLC',
    value: [{ value: 'Geneva Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'BCG Securities, Inc.',
    value: [{ value: 'BCG Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: '5C Capital Management, LLC',
    value: [{ value: '5C Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'ACR Alpine Capital Research, LLC',
    value: [{ value: 'ACR Alpine Capital Research, LLC', key: 'firm_name' }],
  },
  {
    label: 'Detalus Advisors, LLC',
    value: [{ value: 'Detalus Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Carousel Capital Management Company, L.P.',
    value: [{ value: 'Carousel Capital Management Company, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Flood, Bumstead, McCready & McCarthy, Inc.',
    value: [{ value: 'Flood, Bumstead, McCready & McCarthy, Inc.', key: 'firm_name' }],
  },
  {
    label: 'VPWM Advisors',
    value: [{ value: 'VPWM Advisors', key: 'firm_name' }],
  },
  {
    label: 'EQM Capital LLC',
    value: [{ value: 'EQM Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Datatex Investment Services Inc',
    value: [{ value: 'Datatex Investment Services Inc', key: 'firm_name' }],
  },
  {
    label: 'Sequoia Financial Group, L.L.C.',
    value: [{ value: 'Sequoia Financial Group, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Benchmark Advisory Services, LLC',
    value: [{ value: 'Benchmark Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Verity Asset Management',
    value: [{ value: 'Verity Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Spraker West Wealth Management, Inc',
    value: [{ value: 'Spraker West Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Foster & Motley Inc',
    value: [{ value: 'Foster & Motley Inc', key: 'firm_name' }],
  },
  {
    label: 'Lion Street Advisors, LLC',
    value: [{ value: 'Lion Street Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advantage Wealth Management, L.L.C.',
    value: [{ value: 'Advantage Wealth Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Cribstone Capital Management, LLC',
    value: [{ value: 'Cribstone Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pensionmark Financial Group, LLC',
    value: [{ value: 'Pensionmark Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Charter Advisory Services, LLC',
    value: [{ value: 'Charter Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Holloway Wealth Management, LLC',
    value: [{ value: 'Holloway Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Redwood Investments, LLC',
    value: [{ value: 'Redwood Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Miramar Capital',
    value: [{ value: 'Miramar Capital', key: 'firm_name' }],
  },
  {
    label: 'The WealthGarden F.S. LLC',
    value: [{ value: 'The WealthGarden F.S. LLC', key: 'firm_name' }],
  },
  {
    label: 'Acuitas Investments, LLC',
    value: [{ value: 'Acuitas Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Atlanta Consulting Group Advisors, LLC',
    value: [{ value: 'Atlanta Consulting Group Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Andina Family Offices',
    value: [{ value: 'Andina Family Offices', key: 'firm_name' }],
  },
  {
    label: 'Cottonwood Asset Management LLC',
    value: [{ value: 'Cottonwood Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Van Ake Capital LLC',
    value: [{ value: 'Van Ake Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Helix Capital, LLC',
    value: [{ value: 'Helix Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ridge Capital Management, LLC',
    value: [{ value: 'Ridge Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Minneapolis Portfolio Management Group, LLC',
    value: [{ value: 'Minneapolis Portfolio Management Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Life.Money.Balance.',
    value: [{ value: 'Life.Money.Balance.', key: 'firm_name' }],
  },
  {
    label: 'Shadowridge Asset Management, LLC',
    value: [{ value: 'Shadowridge Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'WESPAC Advisors, LLC',
    value: [{ value: 'WESPAC Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'J2 Capital Management, Inc.',
    value: [{ value: 'J2 Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Plancorp, LLC',
    value: [{ value: 'Plancorp, LLC', key: 'firm_name' }],
  },
  {
    label: 'Centennial Securities Company, Inc.',
    value: [{ value: 'Centennial Securities Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Asset Protection Wealth Management LLC',
    value: [{ value: 'Asset Protection Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Timothy Partners, Ltd.',
    value: [{ value: 'Timothy Partners, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'M Holdings Securities, Inc.',
    value: [{ value: 'M Holdings Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Premier Financial Group Inc',
    value: [{ value: 'Premier Financial Group Inc', key: 'firm_name' }],
  },
  {
    label: 'GrandView Asset Management LLC',
    value: [{ value: 'GrandView Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Community Capital Management, Inc.',
    value: [{ value: 'Community Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'KFA Private Wealth Group, LLC',
    value: [{ value: 'KFA Private Wealth Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cincap Investment Group, LLC',
    value: [{ value: 'Cincap Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'DoubleLine Equity LP',
    value: [{ value: 'DoubleLine Equity LP', key: 'firm_name' }],
  },
  {
    label: 'Brannen & Lyle Investment Company',
    value: [{ value: 'Brannen & Lyle Investment Company', key: 'firm_name' }],
  },
  {
    label: 'Second Avenue Capital',
    value: [{ value: 'Second Avenue Capital', key: 'firm_name' }],
  },
  {
    label: 'Diesslin Group, Inc.',
    value: [{ value: 'Diesslin Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Structured Portfolio Solutions, LLC',
    value: [{ value: 'Structured Portfolio Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Premier Prosperity Advisors LLC',
    value: [{ value: 'Premier Prosperity Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Copeland Capital Management, LLC',
    value: [{ value: 'Copeland Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Potomac Advisors, Inc.',
    value: [{ value: 'Potomac Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Appian Way Asset Management LP',
    value: [{ value: 'Appian Way Asset Management LP', key: 'firm_name' }],
  },
  {
    label: 'CCM Investment Management, LLC',
    value: [{ value: 'CCM Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Geneos Wealth Management, Inc.',
    value: [{ value: 'Geneos Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ingram Advisory Services, LLC',
    value: [{ value: 'Ingram Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ascent Capital Management, LLC',
    value: [{ value: 'Ascent Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Purview Investments LLC',
    value: [{ value: 'Purview Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Penserra Global Investors LLC',
    value: [{ value: 'Penserra Global Investors LLC', key: 'firm_name' }],
  },
  {
    label: 'Personal Financial Profiles Inc',
    value: [{ value: 'Personal Financial Profiles Inc', key: 'firm_name' }],
  },
  {
    label: 'Ascendiant Capital Markets, LLC',
    value: [{ value: 'Ascendiant Capital Markets, LLC', key: 'firm_name' }],
  },
  {
    label: 'Momentum Independent Network Inc.',
    value: [{ value: 'Momentum Independent Network Inc.', key: 'firm_name' }],
  },
  {
    label: 'Regency Wealth Management',
    value: [{ value: 'Regency Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Wealth Management, LLC',
    value: [{ value: 'Pinnacle Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Burlingame Investment Group LLC',
    value: [{ value: 'Burlingame Investment Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Griffis Residential Investment Advisor',
    value: [{ value: 'Griffis Residential Investment Advisor', key: 'firm_name' }],
  },
  {
    label: 'Domain Capital Advisors, LLC',
    value: [{ value: 'Domain Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Tribal Capital Markets, LLC',
    value: [{ value: 'Tribal Capital Markets, LLC', key: 'firm_name' }],
  },
  {
    label: 'EAM Investors, LLC',
    value: [{ value: 'EAM Investors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Isgrig Capital Asset Management, LLC',
    value: [{ value: 'Isgrig Capital Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'MGI Funds Distributors, LLC',
    value: [{ value: 'MGI Funds Distributors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Yukon Partners Management, L.L.C.',
    value: [{ value: 'Yukon Partners Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Cameron Advisors, LLC',
    value: [{ value: 'Cameron Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Taurus Asset Management, LLC',
    value: [{ value: 'Taurus Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Moulton Wealth Management, Inc',
    value: [{ value: 'Moulton Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Duke and Duke Wealth Management LLC',
    value: [{ value: 'Duke and Duke Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Harlow Wealth Management, Inc.',
    value: [{ value: 'Harlow Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Round Rock Advisors LLC',
    value: [{ value: 'Round Rock Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Frontier Management, LLC',
    value: [{ value: 'Strategic Frontier Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'BMO Capital Markets Corp.',
    value: [{ value: 'BMO Capital Markets Corp.', key: 'firm_name' }],
  },
  {
    label: 'Palmer Square BDC Advisor LLC',
    value: [{ value: 'Palmer Square BDC Advisor LLC', key: 'firm_name' }],
  },
  {
    label: 'Palmer Square Europe Capital Management LLC',
    value: [{ value: 'Palmer Square Europe Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Levatus LLC',
    value: [{ value: 'Levatus LLC', key: 'firm_name' }],
  },
  //   {
  // label:' Wealth Management",
  // value:[{value:' Wealth Management",key:'firm_name'}]
  //   },
  {
    label: 'Telemus Capital, LLC',
    value: [{ value: 'Telemus Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brazen Capital, LLC',
    value: [{ value: 'Brazen Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Patriot Investment Management Group Inc',
    value: [{ value: 'Patriot Investment Management Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Clear Perspective Advisors, LLC',
    value: [{ value: 'Clear Perspective Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Thrive Capital Management, LLC',
    value: [{ value: 'Thrive Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'ASB Capital Management LLC',
    value: [{ value: 'ASB Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Dakota Wealth, LLC',
    value: [{ value: 'Dakota Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Genter Advisors LLC',
    value: [{ value: 'Genter Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Clarity Capital Advisors, LLC',
    value: [{ value: 'Clarity Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Demming Financial Services Corp.',
    value: [{ value: 'Demming Financial Services Corp.', key: 'firm_name' }],
  },
  {
    label: 'Rockport Wealth Advisors',
    value: [{ value: 'Rockport Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'FMB Wealth Management',
    value: [{ value: 'FMB Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Advocacy Wealth Management, LLC',
    value: [{ value: 'Advocacy Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Clear Point Advisors, Inc.',
    value: [{ value: 'Clear Point Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mitchell Vaught & Taylor Inc',
    value: [{ value: 'Mitchell Vaught & Taylor Inc', key: 'firm_name' }],
  },
  {
    label: 'Axxcess Wealth Management, LLC',
    value: [{ value: 'Axxcess Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Consilium Wealth Advisory, LLC',
    value: [{ value: 'Consilium Wealth Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rovin Capital',
    value: [{ value: 'Rovin Capital', key: 'firm_name' }],
  },
  {
    label: 'St. Louis Financial Planners Inc.',
    value: [{ value: 'St. Louis Financial Planners Inc.', key: 'firm_name' }],
  },
  {
    label: 'World First Financial Services, Inc.',
    value: [{ value: 'World First Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Four Ponds Financial Planning',
    value: [{ value: 'Four Ponds Financial Planning', key: 'firm_name' }],
  },
  {
    label: 'Aecom-Canyon Partners Real Estate Fund Advisors LLC',
    value: [{ value: 'Aecom-Canyon Partners Real Estate Fund Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Canyon Capital Advisors LLC',
    value: [{ value: 'Canyon Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Legacy Capital Group California, Inc.',
    value: [{ value: 'Legacy Capital Group California, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Farmers Financial Solutions, LLC',
    value: [{ value: 'Farmers Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'SFC Financial LLC',
    value: [{ value: 'SFC Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'New Millennium Asset Management, Inc.',
    value: [{ value: 'New Millennium Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Advised Assets Group, LLC',
    value: [{ value: 'Advised Assets Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Schwab Private Client Investment Advisory, Inc.',
    value: [{ value: 'Schwab Private Client Investment Advisory, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Hixon Zuercher Capital Management',
    value: [{ value: 'Hixon Zuercher Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Walkner Condon Financial Advisors LLC',
    value: [{ value: 'Walkner Condon Financial Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Trinity Fund Advisors LLC',
    value: [{ value: 'Trinity Fund Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Riverstone Advisors, LLC',
    value: [{ value: 'Riverstone Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'BetterWealth LLC',
    value: [{ value: 'BetterWealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Cornell Capital Group, LLC',
    value: [{ value: 'Cornell Capital Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nadler Financial Group, Inc.',
    value: [{ value: 'Nadler Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'KL Financial LLC',
    value: [{ value: 'KL Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'The Holland Group, Inc.',
    value: [{ value: 'The Holland Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Boynton Financial LLC',
    value: [{ value: 'Boynton Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Frazier Wealth Management',
    value: [{ value: 'Frazier Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Retirement Plan Fiduciaries, LLC',
    value: [{ value: 'Retirement Plan Fiduciaries, LLC', key: 'firm_name' }],
  },
  {
    label: 'Atlas Wealth Management, LLC',
    value: [{ value: 'Atlas Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Colony Group, LLC',
    value: [{ value: 'The Colony Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eudaimonia Partners, LLC',
    value: [{ value: 'Eudaimonia Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heirloom Investment Management LLC',
    value: [{ value: 'Heirloom Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'David W. McKinlay, ChFC',
    value: [{ value: 'David W. McKinlay, ChFC', key: 'firm_name' }],
  },
  {
    label: 'Fortune Financial Services, Inc.',
    value: [{ value: 'Fortune Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Portfolio Strategies Inc',
    value: [{ value: 'Portfolio Strategies Inc', key: 'firm_name' }],
  },
  {
    label: 'BR Wealth Management',
    value: [{ value: 'BR Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Horst & Graben Wealth Management, LLC',
    value: [{ value: 'Horst & Graben Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sandor Advisors, LLC',
    value: [{ value: 'Sandor Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Latitude Advisors, LLC',
    value: [{ value: 'Latitude Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Conscious Capital Wealth Management, LLC',
    value: [{ value: 'Conscious Capital Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mascagni Wealth Management, Inc.',
    value: [{ value: 'Mascagni Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Horizon Kinetics Asset Management LLC',
    value: [{ value: 'Horizon Kinetics Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Compak Asset Management',
    value: [{ value: 'Compak Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Richard A. Stebbins CPA CFP CMFC',
    value: [{ value: 'Richard A. Stebbins CPA CFP CMFC', key: 'firm_name' }],
  },
  {
    label: 'Brean Capital, LLC',
    value: [{ value: 'Brean Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'American Trust Investment Services, Inc.',
    value: [{ value: 'American Trust Investment Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Guncheon Financial, LLC',
    value: [{ value: 'Guncheon Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Strategies',
    value: [{ value: 'Strategic Wealth Strategies', key: 'firm_name' }],
  },
  {
    label: 'Financial Engines Advisors L.L.C.',
    value: [{ value: 'Financial Engines Advisors L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Epic Wealth Management LLC',
    value: [{ value: 'Epic Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Boreal Advisors LLC',
    value: [{ value: 'Boreal Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'SoundPath Investment Advisors, LLC',
    value: [{ value: 'SoundPath Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Boston Standard Wealth Management, LLC',
    value: [{ value: 'Boston Standard Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'WealthStar Advisors, LLC',
    value: [{ value: 'WealthStar Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Atwood & Palmer, Inc.',
    value: [{ value: 'Atwood & Palmer, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Babylon Wealth Management LLC',
    value: [{ value: 'Babylon Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'McKinney Asset Management Inc.',
    value: [{ value: 'McKinney Asset Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'S.G. Long & Company',
    value: [{ value: 'S.G. Long & Company', key: 'firm_name' }],
  },
  {
    label: 'SGL Investment Advisors, Inc.',
    value: [{ value: 'SGL Investment Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SA Stone Wealth Management Inc.',
    value: [{ value: 'SA Stone Wealth Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bankoh Investment Services, Inc.',
    value: [{ value: 'Bankoh Investment Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Thames Capital Management, LLC',
    value: [{ value: 'Thames Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Elios Financial Group, Inc.',
    value: [{ value: 'Elios Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wallace Investments',
    value: [{ value: 'Wallace Investments', key: 'firm_name' }],
  },
  {
    label: 'SFG Wealth Management',
    value: [{ value: 'SFG Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Bridge Advisory LLC',
    value: [{ value: 'Bridge Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'Talon Private Wealth, LLC',
    value: [{ value: 'Talon Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Garden State Investment Advisory Services, LLC',
    value: [{ value: 'Garden State Investment Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gerber Kawasaki Wealth & Investment Management',
    value: [{ value: 'Gerber Kawasaki Wealth & Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Arista Advisory Group, LLC',
    value: [{ value: 'Arista Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'BNP Paribas Securities Corp.',
    value: [{ value: 'BNP Paribas Securities Corp.', key: 'firm_name' }],
  },
  {
    label: 'Ascendant Capital Management, LLC',
    value: [{ value: 'Ascendant Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'B. Riley Wealth Management',
    value: [{ value: 'B. Riley Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'CWM, LLC',
    value: [{ value: 'CWM, LLC', key: 'firm_name' }],
  },
  {
    label: 'Town Capital',
    value: [{ value: 'Town Capital', key: 'firm_name' }],
  },
  {
    label: 'Federated Investment Counseling',
    value: [{ value: 'Federated Investment Counseling', key: 'firm_name' }],
  },
  {
    label: 'MDT Advisers, A Division of Federated MDTA LLC',
    value: [{ value: 'MDT Advisers, A Division of Federated MDTA LLC', key: 'firm_name' }],
  },
  {
    label: 'Cedar Brook Group',
    value: [{ value: 'Cedar Brook Group', key: 'firm_name' }],
  },
  {
    label: 'P & L Advisors, LLC',
    value: [{ value: 'P & L Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Garcia Hamilton & Associates LP',
    value: [{ value: 'Garcia Hamilton & Associates LP', key: 'firm_name' }],
  },
  {
    label: 'The Professional Financial Company LLC',
    value: [{ value: 'The Professional Financial Company LLC', key: 'firm_name' }],
  },
  {
    label: 'Stern Capital LLC',
    value: [{ value: 'Stern Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Croft & Bender, LP',
    value: [{ value: 'Croft & Bender, LP', key: 'firm_name' }],
  },
  {
    label: 'Traner Smith Wealth Management, Inc.',
    value: [{ value: 'Traner Smith Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Flying Point Asset Management LLC',
    value: [{ value: 'Flying Point Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Zimmermann Investment Management & Planning LLC',
    value: [{ value: 'Zimmermann Investment Management & Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'Blackgem Advisors, LLC',
    value: [{ value: 'Blackgem Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Normandy Capital Management, LLC',
    value: [{ value: 'Normandy Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Goldman Sachs Personal Financial Management',
    value: [{ value: 'Goldman Sachs Personal Financial Management', key: 'firm_name' }],
  },
  {
    label: 'LSP Advisory LLC',
    value: [{ value: 'LSP Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'Tanglewood Legacy Advisors, LLC',
    value: [{ value: 'Tanglewood Legacy Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bailard, Inc.',
    value: [{ value: 'Bailard, Inc.', key: 'firm_name' }],
  },
  {
    label: 'RD Capital Group, Inc.',
    value: [{ value: 'RD Capital Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Karp Capital Management Corporation',
    value: [{ value: 'Karp Capital Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'Soares Financial, Inc.',
    value: [{ value: 'Soares Financial, Inc.', key: 'firm_name' }],
  },
  //   {
  // label:'s Worth Advisors",
  // value:[{value:'s Worth Advisors",key:'firm_name'}]
  //   },
  {
    label: 'Wela Strategies, Inc.',
    value: [{ value: 'Wela Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SWM Advisors',
    value: [{ value: 'SWM Advisors', key: 'firm_name' }],
  },
  {
    label: 'Bey-Douglas, LLC Investment Counsel',
    value: [{ value: 'Bey-Douglas, LLC Investment Counsel', key: 'firm_name' }],
  },
  {
    label: 'Global Investment Partners',
    value: [{ value: 'Global Investment Partners', key: 'firm_name' }],
  },
  {
    label: 'Regal Investment Advisors LLC',
    value: [{ value: 'Regal Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Platform Technology Partners',
    value: [{ value: 'Platform Technology Partners', key: 'firm_name' }],
  },
  {
    label: 'Crescent Advisor Group, Inc.',
    value: [{ value: 'Crescent Advisor Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Prudential Investment Management Services LLC',
    value: [{ value: 'Prudential Investment Management Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Kistler-Tiffany Advisors',
    value: [{ value: 'Kistler-Tiffany Advisors', key: 'firm_name' }],
  },
  {
    label: 'KCD Financial, Inc.',
    value: [{ value: 'KCD Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Barry Capital Management, Inc.',
    value: [{ value: 'Barry Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cojo Bay Advisors, LLC',
    value: [{ value: 'Cojo Bay Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rockledge Partners, LLC',
    value: [{ value: 'Rockledge Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Robert A. Weinstein',
    value: [{ value: 'Robert A. Weinstein', key: 'firm_name' }],
  },
  {
    label: 'Requisite Capital Management LLC',
    value: [{ value: 'Requisite Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Executive Wealth Counselors, LLC',
    value: [{ value: 'Executive Wealth Counselors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bellwether Investment Group, LLC',
    value: [{ value: 'Bellwether Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mason & Associates, Inc.',
    value: [{ value: 'Mason & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Emerging Oaks Capital LLC',
    value: [{ value: 'Emerging Oaks Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Legacy Capital Partners, Inc.',
    value: [{ value: 'Legacy Capital Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'M.M. Dillon & Co. LLC',
    value: [{ value: 'M.M. Dillon & Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Brighton Financial Planning',
    value: [{ value: 'Brighton Financial Planning', key: 'firm_name' }],
  },
  {
    label: 'Lakeview Capital Partners, LLC',
    value: [{ value: 'Lakeview Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sherman Wealth Management LLC',
    value: [{ value: 'Sherman Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'MG&A Wealth',
    value: [{ value: 'MG&A Wealth', key: 'firm_name' }],
  },
  {
    label: 'MBIA Capital Advisors',
    value: [{ value: 'MBIA Capital Advisors', key: 'firm_name' }],
  },
  {
    label: 'Leading Edge Wealth Management, LLC',
    value: [{ value: 'Leading Edge Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'First Heartland Consultants, Inc.',
    value: [{ value: 'First Heartland Consultants, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Griffith & Werner, Inc.',
    value: [{ value: 'Griffith & Werner, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WayneFirebaugh, Inc.',
    value: [{ value: 'WayneFirebaugh, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brasil Capital Adviser LLC',
    value: [{ value: 'Brasil Capital Adviser LLC', key: 'firm_name' }],
  },
  {
    label: 'Nexus Wealth Advisors',
    value: [{ value: 'Nexus Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Red Oak Capital Management, Inc.',
    value: [{ value: 'Red Oak Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Stratigraphic Asset Management, Inc.',
    value: [{ value: 'Stratigraphic Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Woodstock Wealth Management, Inc.',
    value: [{ value: 'Woodstock Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cumberland Advisors',
    value: [{ value: 'Cumberland Advisors', key: 'firm_name' }],
  },
  {
    label: 'Spire Wealth Management, LLC',
    value: [{ value: 'Spire Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'The H Group, Inc.',
    value: [{ value: 'The H Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Advisors, LLC',
    value: [{ value: 'Strategic Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Front Street Capital Management, Inc.',
    value: [{ value: 'Front Street Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Financial Northeastern Securities, Inc.',
    value: [{ value: 'Financial Northeastern Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bourbon Financial Management',
    value: [{ value: 'Bourbon Financial Management', key: 'firm_name' }],
  },
  {
    label: 'BFG Wealth Management LLC',
    value: [{ value: 'BFG Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Schear Investment Advisers, LLC',
    value: [{ value: 'Schear Investment Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Timberland Investment Resources, LLC',
    value: [{ value: 'Timberland Investment Resources, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stern Capital Management, Inc.',
    value: [{ value: 'Stern Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'V. M. Manning & Co., Inc.',
    value: [{ value: 'V. M. Manning & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Elevated Capital Advisors, LLC',
    value: [{ value: 'Elevated Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madrona Financial Services, LLC',
    value: [{ value: 'Madrona Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Weathervane Asset Management LLC',
    value: [{ value: 'Weathervane Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'AllianceBernstein Investments, Inc.',
    value: [{ value: 'AllianceBernstein Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Queen City Capital Management, LLC',
    value: [{ value: 'Queen City Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Matisse Capital',
    value: [{ value: 'Matisse Capital', key: 'firm_name' }],
  },
  {
    label: 'Hamrick Financial Services',
    value: [{ value: 'Hamrick Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Prosperitas Wealth Management LLC',
    value: [{ value: 'Prosperitas Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Marchand Faries Financial Management, Inc.',
    value: [{ value: 'Marchand Faries Financial Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Rockingstone Advisors',
    value: [{ value: 'Rockingstone Advisors', key: 'firm_name' }],
  },
  {
    label: 'Schwarz Dygos Wheeler Investment Advisors LLC',
    value: [{ value: 'Schwarz Dygos Wheeler Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Vantage Financial Partners Limited',
    value: [{ value: 'Vantage Financial Partners Limited', key: 'firm_name' }],
  },
  {
    label: 'Stewardship Advisory Group, LLC',
    value: [{ value: 'Stewardship Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cantu Tactical Wealth Management, Inc.',
    value: [{ value: 'Cantu Tactical Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Arin Risk Advisors LLC',
    value: [{ value: 'Arin Risk Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Northern Capital Retirement Services, Inc.',
    value: [{ value: 'Northern Capital Retirement Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Leaders Group, Inc.',
    value: [{ value: 'The Leaders Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WS Wealth Management, LLC',
    value: [{ value: 'WS Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Aul Financial Group, LLC',
    value: [{ value: 'Aul Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Falcon Financial of Oklahoma, LLC',
    value: [{ value: 'Falcon Financial of Oklahoma, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lebenthal Global Advisors, LLC',
    value: [{ value: 'Lebenthal Global Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cruiser Capital Advisors, LLC',
    value: [{ value: 'Cruiser Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kavout Algo Financial LLC',
    value: [{ value: 'Kavout Algo Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Valley Financial Group',
    value: [{ value: 'Valley Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Rockbridge Investment Management, LLC',
    value: [{ value: 'Rockbridge Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Palmer Wealth Management LLC',
    value: [{ value: 'Palmer Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Verus Capital Partners, LLC',
    value: [{ value: 'Verus Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Compass Rose Private Investment Management',
    value: [{ value: 'Compass Rose Private Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Securities Management & Research, Inc.',
    value: [{ value: 'Securities Management & Research, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Personal Financial Solutions, LLC',
    value: [{ value: 'Personal Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spurrier Capital Partners LLC',
    value: [{ value: 'Spurrier Capital Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Milestone Wealth Strategies',
    value: [{ value: 'Milestone Wealth Strategies', key: 'firm_name' }],
  },
  {
    label: 'RIA Advisory Group, LLC',
    value: [{ value: 'RIA Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fairview Capital',
    value: [{ value: 'Fairview Capital', key: 'firm_name' }],
  },
  {
    label: 'Trails Edge Wealth Advisers, LLC',
    value: [{ value: 'Trails Edge Wealth Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cleveland Hauswirth Investment Management',
    value: [{ value: 'Cleveland Hauswirth Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Alere Planning LLC',
    value: [{ value: 'Alere Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'Affinity Capital Advisors LLC',
    value: [{ value: 'Affinity Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Main Street Financial Solutions, LLC',
    value: [{ value: 'Main Street Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'aPriori Capital Partners',
    value: [{ value: 'aPriori Capital Partners', key: 'firm_name' }],
  },
  {
    label: 'ACM',
    value: [{ value: 'ACM', key: 'firm_name' }],
  },
  {
    label: 'Liberty Asset Management, Inc.',
    value: [{ value: 'Liberty Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Industrial Development Funding LLC',
    value: [{ value: 'Industrial Development Funding LLC', key: 'firm_name' }],
  },
  {
    label: 'Passive Capital Management, LLC',
    value: [{ value: 'Passive Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Kelly Group',
    value: [{ value: 'The Kelly Group', key: 'firm_name' }],
  },
  {
    label: 'Wildwood Trail Financial, LLC',
    value: [{ value: 'Wildwood Trail Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Greytown Advisors',
    value: [{ value: 'Greytown Advisors', key: 'firm_name' }],
  },
  {
    label: 'Triton Wealth Management, PLLC',
    value: [{ value: 'Triton Wealth Management, PLLC', key: 'firm_name' }],
  },
  {
    label: 'Sema IV Capital Management, LLC',
    value: [{ value: 'Sema IV Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Crestline Capital, LLC',
    value: [{ value: 'Crestline Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eaton Financial Group',
    value: [{ value: 'Eaton Financial Group', key: 'firm_name' }],
  },
  {
    label: 'United Advisors America',
    value: [{ value: 'United Advisors America', key: 'firm_name' }],
  },
  {
    label: 'Heritage Wealth Partners, LLC',
    value: [{ value: 'Heritage Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Charleston Financial Advisors, LLC',
    value: [{ value: 'Charleston Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'TZero Markets',
    value: [{ value: 'TZero Markets', key: 'firm_name' }],
  },
  {
    label: 'Velocity Clearing, LLC',
    value: [{ value: 'Velocity Clearing, LLC', key: 'firm_name' }],
  },
  {
    label: 'Safran Wealth Advisors, LLC',
    value: [{ value: 'Safran Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Donald Smith, L.P.',
    value: [{ value: 'Donald Smith, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Edge Financial Advisors, LLC',
    value: [{ value: 'Edge Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Day Hagan Asset Management',
    value: [{ value: 'Day Hagan Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Long Faith Capital LLC',
    value: [{ value: 'Long Faith Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'OneAscent Financial Services LLC',
    value: [{ value: 'OneAscent Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Golden State Wealth Management, LLC',
    value: [{ value: 'Golden State Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Singer Wealth Advisors LLC',
    value: [{ value: 'Singer Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Advanced Advisor Group, LLC',
    value: [{ value: 'Advanced Advisor Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'deFIned Wealth Management, LLC',
    value: [{ value: 'deFIned Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Westpark Management, LLC',
    value: [{ value: 'Westpark Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Trinity Wealth Management, LLC',
    value: [{ value: 'Trinity Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'JAM Capital Partners',
    value: [{ value: 'JAM Capital Partners', key: 'firm_name' }],
  },
  {
    label: 'Pleasant Street Wealth Advisors, LLC',
    value: [{ value: 'Pleasant Street Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bloomberg Tradebook LLC',
    value: [{ value: 'Bloomberg Tradebook LLC', key: 'firm_name' }],
  },
  {
    label: 'WCM Global Wealth, LLC',
    value: [{ value: 'WCM Global Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Watchman Group, Inc.',
    value: [{ value: 'The Watchman Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Gotham Asset Management, LLC',
    value: [{ value: 'Gotham Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'American Portfolios Financial Services, Inc.',
    value: [{ value: 'American Portfolios Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Insight Total Stewardship',
    value: [{ value: 'Insight Total Stewardship', key: 'firm_name' }],
  },
  {
    label: 'Enterprise Investors',
    value: [{ value: 'Enterprise Investors', key: 'firm_name' }],
  },
  {
    label: 'Insight Wealth Strategies, LLC',
    value: [{ value: 'Insight Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Isthmus Partners, LLC',
    value: [{ value: 'Isthmus Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Retirement Income Advisory Group LLC',
    value: [{ value: 'Retirement Income Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Hilton Capital Management, LLC',
    value: [{ value: 'Hilton Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Riley Friedman Asset Management',
    value: [{ value: 'Riley Friedman Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Artifex Financial Group, LLC',
    value: [{ value: 'Artifex Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'LongView Asset Management, LLC',
    value: [{ value: 'LongView Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Advocates Investment Management',
    value: [{ value: 'Financial Advocates Investment Management', key: 'firm_name' }],
  },
  {
    label: 'RF&L Wealth Management, LLC',
    value: [{ value: 'RF&L Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sweetgum Laboratories LLC',
    value: [{ value: 'Sweetgum Laboratories LLC', key: 'firm_name' }],
  },
  {
    label: 'Thomas J. Herzfeld Advisors, Inc.',
    value: [{ value: 'Thomas J. Herzfeld Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Ujano Advisory Group LLC',
    value: [{ value: 'The Ujano Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Nikulski Financial, Inc.',
    value: [{ value: 'Nikulski Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'CG Advisory Services',
    value: [{ value: 'CG Advisory Services', key: 'firm_name' }],
  },
  {
    label: 'AMG Financial Strategies, Inc.',
    value: [{ value: 'AMG Financial Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First Light Wealth Advisors, LLC',
    value: [{ value: 'First Light Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bernardi Asset Management, LLC',
    value: [{ value: 'Bernardi Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Coordinate Wealth',
    value: [{ value: 'Coordinate Wealth', key: 'firm_name' }],
  },
  {
    label: 'Householder Group Estate & Retirement Specialists',
    value: [{ value: 'Householder Group Estate & Retirement Specialists', key: 'firm_name' }],
  },
  {
    label: 'D.B. Root & Company, LLC',
    value: [{ value: 'D.B. Root & Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madison Advisory Services, Inc.',
    value: [{ value: 'Madison Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'RWC For Life',
    value: [{ value: 'RWC For Life', key: 'firm_name' }],
  },
  {
    label: 'Brown Financial Advisory',
    value: [{ value: 'Brown Financial Advisory', key: 'firm_name' }],
  },
  {
    label: 'Clarify Wealth Management',
    value: [{ value: 'Clarify Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Chaffe Securities, Inc.',
    value: [{ value: 'Chaffe Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'New Century Capital Management, LLC',
    value: [{ value: 'New Century Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ellevest',
    value: [{ value: 'Ellevest', key: 'firm_name' }],
  },
  {
    label: 'Centurion Advisory Group, Inc',
    value: [{ value: 'Centurion Advisory Group, Inc', key: 'firm_name' }],
  },
  {
    label: 'PRIMESolutions Advisors, LLC',
    value: [{ value: 'PRIMESolutions Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Access Advisory Group LLC',
    value: [{ value: 'Access Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Blackston Financial Advisory Group, LLC',
    value: [{ value: 'Blackston Financial Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rehmann Financial',
    value: [{ value: 'Rehmann Financial', key: 'firm_name' }],
  },
  {
    label: 'Herrmann & Cooke Wealth Management',
    value: [{ value: 'Herrmann & Cooke Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'DT Investments LLC',
    value: [{ value: 'DT Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'IG Better Future Management, LLC',
    value: [{ value: 'IG Better Future Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madison Park Capital Advisors, LLC',
    value: [{ value: 'Madison Park Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bleichroeder LP',
    value: [{ value: 'Bleichroeder LP', key: 'firm_name' }],
  },
  {
    label: 'Rossi Financial Group',
    value: [{ value: 'Rossi Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Helm Capital Management',
    value: [{ value: 'Helm Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Kudu Investment Holdings, LLC',
    value: [{ value: 'Kudu Investment Holdings, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Management Services, LLC',
    value: [{ value: 'Wealth Management Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gordian Wealth Advisors, LLC',
    value: [{ value: 'Gordian Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Teplitz Financial Group, LLC',
    value: [{ value: 'Teplitz Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eubank, Hutson & Associates, LLC',
    value: [{ value: 'Eubank, Hutson & Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Guerrilla Advisors LLC',
    value: [{ value: 'Guerrilla Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'GenCap Portfolio Management',
    value: [{ value: 'GenCap Portfolio Management', key: 'firm_name' }],
  },
  {
    label: 'Personal Wealth Advisory, LLC',
    value: [{ value: 'Personal Wealth Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eighth Wonder Investments',
    value: [{ value: 'Eighth Wonder Investments', key: 'firm_name' }],
  },
  {
    label: 'Krilogy Capital Management II, LLC',
    value: [{ value: 'Krilogy Capital Management II, LLC', key: 'firm_name' }],
  },
  {
    label: 'Liberty Partners Financial Services, LLC',
    value: [{ value: 'Liberty Partners Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Berkshire Money Management, Inc.',
    value: [{ value: 'Berkshire Money Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Denver Wealth Management, Inc.',
    value: [{ value: 'Denver Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lowery Thomas, LLC',
    value: [{ value: 'Lowery Thomas, LLC', key: 'firm_name' }],
  },
  {
    label: 'GEM Asset Management, LLC',
    value: [{ value: 'GEM Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Balboa Wealth Partners, Inc.',
    value: [{ value: 'Balboa Wealth Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lake Point Wealth Management, LLC',
    value: [{ value: 'Lake Point Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Acruence Capital, LLC',
    value: [{ value: 'Acruence Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Victus Capital Wealth',
    value: [{ value: 'Victus Capital Wealth', key: 'firm_name' }],
  },
  {
    label: 'Redwood Investment Management, LLC.',
    value: [{ value: 'Redwood Investment Management, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Harbor Group International, LLC',
    value: [{ value: 'Harbor Group International, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mayfair Advisory Group, LLC',
    value: [{ value: 'Mayfair Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Beacon Financial Advisors Ltd',
    value: [{ value: 'Beacon Financial Advisors Ltd', key: 'firm_name' }],
  },
  {
    label: 'Alliance Wealth Management Partners LLC',
    value: [{ value: 'Alliance Wealth Management Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Isaak Bond Investments, Inc.',
    value: [{ value: 'Isaak Bond Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Yanker Group',
    value: [{ value: 'The Yanker Group', key: 'firm_name' }],
  },
  {
    label: 'Heron Wealth',
    value: [{ value: 'Heron Wealth', key: 'firm_name' }],
  },
  {
    label: 'Rosenbaum Financial',
    value: [{ value: 'Rosenbaum Financial', key: 'firm_name' }],
  },
  {
    label: 'ValGrowth Fund Management, LLC',
    value: [{ value: 'ValGrowth Fund Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Campbell Capital Management Inc',
    value: [{ value: 'Campbell Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'First Allied Securities, Inc.',
    value: [{ value: 'First Allied Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wilkins Investment Counsel, Inc',
    value: [{ value: 'Wilkins Investment Counsel, Inc', key: 'firm_name' }],
  },
  {
    label: 'Comprehensive Wealth Management',
    value: [{ value: 'Comprehensive Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Olympus Wealth Management, LLC',
    value: [{ value: 'Olympus Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Maglan Capital LP',
    value: [{ value: 'Maglan Capital LP', key: 'firm_name' }],
  },
  {
    label: 'Cooper Eagle, LLC',
    value: [{ value: 'Cooper Eagle, LLC', key: 'firm_name' }],
  },
  {
    label: 'Citiwest Capital Corporation',
    value: [{ value: 'Citiwest Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Cobler Capital Management, LLC',
    value: [{ value: 'Cobler Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Russell Wealth Advisors',
    value: [{ value: 'Russell Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Azimuth Capital Management LLC',
    value: [{ value: 'Azimuth Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'SGL Financial, LLC',
    value: [{ value: 'SGL Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Manning Wealth Management, Inc.',
    value: [{ value: 'Manning Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Investment Capital Advisors LLC',
    value: [{ value: 'Investment Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Hotchkis and Wiley Capital Management, LLC',
    value: [{ value: 'Hotchkis and Wiley Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'PGP Wealth Services, LLC',
    value: [{ value: 'PGP Wealth Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Holland Financial Services, Inc.',
    value: [{ value: 'Holland Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Seeds Investor LLC',
    value: [{ value: 'Seeds Investor LLC', key: 'firm_name' }],
  },
  {
    label: 'Reap In Wealth Management, LLC',
    value: [{ value: 'Reap In Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Primus Capital Partners, Inc.',
    value: [{ value: 'Primus Capital Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Blackstone Securities Partners L.P.',
    value: [{ value: 'Blackstone Securities Partners L.P.', key: 'firm_name' }],
  },
  {
    label: 'Monon Wealth Management',
    value: [{ value: 'Monon Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Lambert, J Jeffrey',
    value: [{ value: 'Lambert, J Jeffrey', key: 'firm_name' }],
  },
  {
    label: 'Worm Capital, LLC',
    value: [{ value: 'Worm Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Berkeley Capital Partners, LLC',
    value: [{ value: 'Berkeley Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Paramount Associates, LLC',
    value: [{ value: 'Paramount Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Midwest Financial Network, LLC',
    value: [{ value: 'Midwest Financial Network, LLC', key: 'firm_name' }],
  },
  {
    label: 'Emmaus Investor Services Corp.',
    value: [{ value: 'Emmaus Investor Services Corp.', key: 'firm_name' }],
  },
  {
    label: 'Creative Planning',
    value: [{ value: 'Creative Planning', key: 'firm_name' }],
  },
  {
    label: 'Retirement Collaborative LLC',
    value: [{ value: 'Retirement Collaborative LLC', key: 'firm_name' }],
  },
  {
    label: 'Aspire Private Wealth Counsel, LLC',
    value: [{ value: 'Aspire Private Wealth Counsel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Axia Advisory',
    value: [{ value: 'Axia Advisory', key: 'firm_name' }],
  },
  {
    label: 'Clark Capital Management Group Inc',
    value: [{ value: 'Clark Capital Management Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Trek Wealth Planning',
    value: [{ value: 'Trek Wealth Planning', key: 'firm_name' }],
  },
  {
    label: 'Princeton Wealth Management Alliance, LLC',
    value: [{ value: 'Princeton Wealth Management Alliance, LLC', key: 'firm_name' }],
  },
  {
    label: 'Goldsborough Capital Management, LLC',
    value: [{ value: 'Goldsborough Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alden Capital',
    value: [{ value: 'Alden Capital', key: 'firm_name' }],
  },
  {
    label: 'Crossroads Asset Management LLC',
    value: [{ value: 'Crossroads Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'East Wind Securities, LLC',
    value: [{ value: 'East Wind Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wilson-Davis & Co., Inc.',
    value: [{ value: 'Wilson-Davis & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Baldwin Financial Advisors, LLC',
    value: [{ value: 'Baldwin Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Yakira Capital Management, Inc.',
    value: [{ value: 'Yakira Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Parallel Advisors, LLC',
    value: [{ value: 'Parallel Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'River Wealth Management LLC',
    value: [{ value: 'River Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Reliance Investing, Inc.',
    value: [{ value: 'Reliance Investing, Inc.', key: 'firm_name' }],
  },
  {
    label: 'CAPTRUST',
    value: [{ value: 'CAPTRUST', key: 'firm_name' }],
  },
  {
    label: 'Prosperity Wealth Management, Inc.',
    value: [{ value: 'Prosperity Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Tullett Prebon Financial Services LLC',
    value: [{ value: 'Tullett Prebon Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Burkett Financial Services, LLC',
    value: [{ value: 'Burkett Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'USAdvisors Wealth Management, LLC',
    value: [{ value: 'USAdvisors Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Edward Jones',
    value: [{ value: 'Edward Jones', key: 'firm_name' }],
  },
  {
    label: 'Antonelli Financial Advisors, LLC',
    value: [{ value: 'Antonelli Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Accuvest Global Advisors',
    value: [{ value: 'Accuvest Global Advisors', key: 'firm_name' }],
  },
  {
    label: 'Semmax Financial Advisors, Inc.',
    value: [{ value: 'Semmax Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Flourish Financial Planning',
    value: [{ value: 'Flourish Financial Planning', key: 'firm_name' }],
  },
  {
    label: 'Solic Capital LLC',
    value: [{ value: 'Solic Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Cornerstone Asset Management, LLC',
    value: [{ value: 'Cornerstone Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Higgins Capital Management, Inc.',
    value: [{ value: 'Higgins Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Intrepid Financial Planning Group, LLC',
    value: [{ value: 'Intrepid Financial Planning Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Management Solutions, LLC',
    value: [{ value: 'Strategic Wealth Management Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alpha Rising Advisors LLC',
    value: [{ value: 'Alpha Rising Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Wells Fargo Advisors Financial Network, LLC',
    value: [{ value: 'Wells Fargo Advisors Financial Network, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mace Capital Management, LLC',
    value: [{ value: 'Mace Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Independent Advisor Alliance, LLC',
    value: [{ value: 'Independent Advisor Alliance, LLC', key: 'firm_name' }],
  },
  {
    label: 'F3 Investment Management, LLC',
    value: [{ value: 'F3 Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'STA Wealth Management, LLC',
    value: [{ value: 'STA Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Oakwell Private Wealth Management',
    value: [{ value: 'Oakwell Private Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'AG Asset Advisory, LLC',
    value: [{ value: 'AG Asset Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fiduciary Management, Inc.',
    value: [{ value: 'Fiduciary Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Commonfund Securities, Inc.',
    value: [{ value: 'Commonfund Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'MRA Advisory Group',
    value: [{ value: 'MRA Advisory Group', key: 'firm_name' }],
  },
  {
    label: 'D.A. Davidson & Co.',
    value: [{ value: 'D.A. Davidson & Co.', key: 'firm_name' }],
  },
  {
    label: 'Cetera Investment Advisers LLC',
    value: [{ value: 'Cetera Investment Advisers LLC', key: 'firm_name' }],
  },
  {
    label: 'BrightPath Financial, Inc.',
    value: [{ value: 'BrightPath Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Arbor Point Advisors',
    value: [{ value: 'Arbor Point Advisors', key: 'firm_name' }],
  },
  {
    label: 'JKG Capital LLC',
    value: [{ value: 'JKG Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'The Lloyd Group',
    value: [{ value: 'The Lloyd Group', key: 'firm_name' }],
  },
  {
    label: 'TFS Securities, Inc.',
    value: [{ value: 'TFS Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'J.P. Morgan Securities LLC',
    value: [{ value: 'J.P. Morgan Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'TIAA-CREF Individual & Institutional Services, LLC',
    value: [{ value: 'TIAA-CREF Individual & Institutional Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Recore Growth Investments Inc.',
    value: [{ value: 'Recore Growth Investments Inc.', key: 'firm_name' }],
  },
  {
    label: 'Charles Schwab & Co., Inc.',
    value: [{ value: 'Charles Schwab & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Rebel Financial, LLC',
    value: [{ value: 'Rebel Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Royal Fund Management, LLC',
    value: [{ value: 'Royal Fund Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Locorr Fund Management, LLC',
    value: [{ value: 'Locorr Fund Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Steben & Company, LLC',
    value: [{ value: 'Steben & Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Seven Points Capital, LLC',
    value: [{ value: 'Seven Points Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Huntington Securities, Inc.',
    value: [{ value: 'Huntington Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'W&S Brokerage Services, Inc.',
    value: [{ value: 'W&S Brokerage Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Valley Wealth Strategies, LLC',
    value: [{ value: 'Valley Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lally Wealth Management, LLC',
    value: [{ value: 'Lally Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Western International Securities, Inc.',
    value: [{ value: 'Western International Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Peck Wealth Management, LLC',
    value: [{ value: 'Peck Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'WealthSource Partners',
    value: [{ value: 'WealthSource Partners', key: 'firm_name' }],
  },
  {
    label: 'Yeomans Consulting Group, Inc.',
    value: [{ value: 'Yeomans Consulting Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Patron Partners, LLC',
    value: [{ value: 'Patron Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Charles Carroll Financial Partners, LLC',
    value: [{ value: 'Charles Carroll Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Harvest Investment Services, LLC',
    value: [{ value: 'Harvest Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gofen and Glossberg, L.L.C.',
    value: [{ value: 'Gofen and Glossberg, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Next Financial Group, Inc.',
    value: [{ value: 'Next Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Global View Capital Management, LTD',
    value: [{ value: 'Global View Capital Management, LTD', key: 'firm_name' }],
  },
  {
    label: 'Eames Financial',
    value: [{ value: 'Eames Financial', key: 'firm_name' }],
  },
  {
    label: 'Legacy Portfolio Advisors, LLC',
    value: [{ value: 'Legacy Portfolio Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'American Portfolios Advisors, Inc',
    value: [{ value: 'American Portfolios Advisors, Inc', key: 'firm_name' }],
  },
  {
    label: 'Monocot Wealth Management, LLC',
    value: [{ value: 'Monocot Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Financial Advisors Corporation',
    value: [{ value: 'Lincoln Financial Advisors Corporation', key: 'firm_name' }],
  },
  {
    label: 'Timonier',
    value: [{ value: 'Timonier', key: 'firm_name' }],
  },
  {
    label: 'Jeffrey Matthews Wealth Management, LLC',
    value: [{ value: 'Jeffrey Matthews Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'MCM Wealth',
    value: [{ value: 'MCM Wealth', key: 'firm_name' }],
  },
  {
    label: 'Novem Group',
    value: [{ value: 'Novem Group', key: 'firm_name' }],
  },
  {
    label: 'Rothrock Financial Group',
    value: [{ value: 'Rothrock Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Investment',
    value: [{ value: 'Lincoln Investment', key: 'firm_name' }],
  },
  {
    label: 'Portsmouth Financial Services',
    value: [{ value: 'Portsmouth Financial Services', key: 'firm_name' }],
  },
  {
    label: 'The JM Financial Planning Group, Ltd.',
    value: [{ value: 'The JM Financial Planning Group, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Stewart Wealth Management, Inc.',
    value: [{ value: 'Stewart Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PFM Fund Distributors, Inc.',
    value: [{ value: 'PFM Fund Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Allstate Financial Services, LLC',
    value: [{ value: 'Allstate Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'RFG Advisory, LLC',
    value: [{ value: 'RFG Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stratos Wealth Partners, Ltd',
    value: [{ value: 'Stratos Wealth Partners, Ltd', key: 'firm_name' }],
  },
  {
    label: 'Cerity Partners LLC',
    value: [{ value: 'Cerity Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'DSP Securities, LLC',
    value: [{ value: 'DSP Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lyonross Capital Management, LLC',
    value: [{ value: 'Lyonross Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lord, Abbett & Co. LLC',
    value: [{ value: 'Lord, Abbett & Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Principal Securities, Inc.',
    value: [{ value: 'Principal Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Infinex Investments, Inc.',
    value: [{ value: 'Infinex Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Concourse Financial Group Securities, Inc.',
    value: [{ value: 'Concourse Financial Group Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'LaSalle St. Investment Advisors, L.L.C.',
    value: [{ value: 'LaSalle St. Investment Advisors, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Strada Financial Group LLC',
    value: [{ value: 'Strada Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'New England Retirement Consultants, LLC',
    value: [{ value: 'New England Retirement Consultants, LLC', key: 'firm_name' }],
  },
  {
    label: 'SeaCrest Wealth Management, LLC',
    value: [{ value: 'SeaCrest Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Woodbury Financial Services, Inc.',
    value: [{ value: 'Woodbury Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brookstone Capital Management LLC',
    value: [{ value: 'Brookstone Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Ward Capital Management LLC',
    value: [{ value: 'Ward Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Cetera Investment Services LLC',
    value: [{ value: 'Cetera Investment Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Investment Initiatives, LLC',
    value: [{ value: 'Investment Initiatives, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Consulting Professionals, LLC',
    value: [{ value: 'Financial Consulting Professionals, LLC', key: 'firm_name' }],
  },
  {
    label: 'BroadArch Capital, LLC',
    value: [{ value: 'BroadArch Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Solomon API, LP',
    value: [{ value: 'Solomon API, LP', key: 'firm_name' }],
  },
  {
    label: 'Cambridge Investment Research Advisors, Inc.',
    value: [{ value: 'Cambridge Investment Research Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Advisors Group',
    value: [{ value: 'Strategic Wealth Advisors Group', key: 'firm_name' }],
  },
  {
    label: 'Elite Income Advisors, Inc.',
    value: [{ value: 'Elite Income Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Legacy Consulting Group',
    value: [{ value: 'Legacy Consulting Group', key: 'firm_name' }],
  },
  {
    label: 'HighTower Advisors, LLC',
    value: [{ value: 'HighTower Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Oberon Asset Management, LLC',
    value: [{ value: 'Oberon Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Royal Oak Financial Advisors, LLC',
    value: [{ value: 'Royal Oak Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Founders Financial Securities LLC',
    value: [{ value: 'Founders Financial Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'S.E.E.D. Planning Group, LLC',
    value: [{ value: 'S.E.E.D. Planning Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'CFO4Life',
    value: [{ value: 'CFO4Life', key: 'firm_name' }],
  },
  {
    label: 'Houssels Capital Management, LLC',
    value: [{ value: 'Houssels Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'ICCF Wealth Management, LLC',
    value: [{ value: 'ICCF Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brown Robello Capital Management, LLC',
    value: [{ value: 'Brown Robello Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Life By Design Investment Advisory Services',
    value: [{ value: 'Life By Design Investment Advisory Services', key: 'firm_name' }],
  },
  //   {
  // label:'Connor Portfolio Management LLC",
  // value:[{value:'Connor Portfolio Management LLC",key:'firm_name'}]
  //   },
  {
    label: 'Equus Private Wealth Management LLC',
    value: [{ value: 'Equus Private Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Premier Estate Planners, LLC',
    value: [{ value: 'Premier Estate Planners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cornerstone Securities LLC',
    value: [{ value: 'Cornerstone Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Sentinel Pension Advisors Inc',
    value: [{ value: 'Sentinel Pension Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Client First Capital, LLC',
    value: [{ value: 'Client First Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Equity Services, Inc.',
    value: [{ value: 'Equity Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SCF Investment Advisors, Inc.',
    value: [{ value: 'SCF Investment Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Veracity Capital LLC',
    value: [{ value: 'Veracity Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Hanna Financial and Tax Services',
    value: [{ value: 'Hanna Financial and Tax Services', key: 'firm_name' }],
  },
  {
    label: 'Cambridge Investment Research, Inc.',
    value: [{ value: 'Cambridge Investment Research, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Zimmerman Advisory Group, LLC',
    value: [{ value: 'Zimmerman Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Warren Averett Asset Management, LLC',
    value: [{ value: 'Warren Averett Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Retirement Guys Formula',
    value: [{ value: 'The Retirement Guys Formula', key: 'firm_name' }],
  },
  {
    label: 'American Capital Partners, LLC',
    value: [{ value: 'American Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'CL Wealth Management LLC',
    value: [{ value: 'CL Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Advisor Network',
    value: [{ value: 'Capital Advisor Network', key: 'firm_name' }],
  },
  {
    label: 'Glovista Investments LLC',
    value: [{ value: 'Glovista Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Longboard Asset Management',
    value: [{ value: 'Longboard Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Loyola Asset Management LLC',
    value: [{ value: 'Loyola Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'BancWest Investment Services, Inc.',
    value: [{ value: 'BancWest Investment Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Uhlmann Investment Management,  L.L.C.',
    value: [{ value: 'Uhlmann Investment Management,  L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'CSP Financial Group LLC',
    value: [{ value: 'CSP Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Money Management Services Inc',
    value: [{ value: 'Money Management Services Inc', key: 'firm_name' }],
  },
  {
    label: 'PlanMember Securities Corporation',
    value: [{ value: 'PlanMember Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Kenney Investments Inc',
    value: [{ value: 'Kenney Investments Inc', key: 'firm_name' }],
  },
  {
    label: 'Pacific Select Distributors, LLC',
    value: [{ value: 'Pacific Select Distributors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Synovus Securities, Inc.',
    value: [{ value: 'Synovus Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'McLean Asset Management Corp.',
    value: [{ value: 'McLean Asset Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Grove Point Advisors, LLC',
    value: [{ value: 'Grove Point Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CFS Investment Advisory Services LLC',
    value: [{ value: 'CFS Investment Advisory Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Magnifina, LLC',
    value: [{ value: 'Magnifina, LLC', key: 'firm_name' }],
  },
  {
    label: 'Prism Fund Solutions, Inc.',
    value: [{ value: 'Prism Fund Solutions, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mid-Atlantic Securities, Inc.',
    value: [{ value: 'Mid-Atlantic Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Metanoia Financial, LLC',
    value: [{ value: 'Metanoia Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'McDermott Investment Advisors, LLC',
    value: [{ value: 'McDermott Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Retirement Planology, Inc.',
    value: [{ value: 'Retirement Planology, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Financial Life Advisors',
    value: [{ value: 'Financial Life Advisors', key: 'firm_name' }],
  },
  {
    label: 'Horter Investment Management, LLC',
    value: [{ value: 'Horter Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Management LLC',
    value: [{ value: 'Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Kenneth, Jerome & Co., Inc.',
    value: [{ value: 'Kenneth, Jerome & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Yates Financial Group Inc.',
    value: [{ value: 'Yates Financial Group Inc.', key: 'firm_name' }],
  },
  {
    label: 'Carthage Financial Group, LLC',
    value: [{ value: 'Carthage Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Chicory Wealth',
    value: [{ value: 'Chicory Wealth', key: 'firm_name' }],
  },
  {
    label: 'Inman Financial Services, Inc.',
    value: [{ value: 'Inman Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Blue Line Capital',
    value: [{ value: 'Blue Line Capital', key: 'firm_name' }],
  },
  {
    label: 'J.W. Korth & Company',
    value: [{ value: 'J.W. Korth & Company', key: 'firm_name' }],
  },
  {
    label: 'Bright Financial Advisors, Inc.',
    value: [{ value: 'Bright Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Vere Global Wealth Management',
    value: [{ value: 'Vere Global Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Stolzer Rothschild Levy LLC',
    value: [{ value: 'Stolzer Rothschild Levy LLC', key: 'firm_name' }],
  },
  {
    label: 'Cambria Capital, LLC',
    value: [{ value: 'Cambria Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hollencrest Capital Management',
    value: [{ value: 'Hollencrest Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Financial Innovations, LLC',
    value: [{ value: 'Financial Innovations, LLC', key: 'firm_name' }],
  },
  {
    label: 'Season Investments, LLC',
    value: [{ value: 'Season Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Adirondack Capital Advisors, LLC',
    value: [{ value: 'Adirondack Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sommers Financial Management',
    value: [{ value: 'Sommers Financial Management', key: 'firm_name' }],
  },
  {
    label: 'Summit Family Office Services, LLC',
    value: [{ value: 'Summit Family Office Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'I-Squared Wealth Management, Inc.',
    value: [{ value: 'I-Squared Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mercer Global Advisors Inc.',
    value: [{ value: 'Mercer Global Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'R. F. Lafferty & Co., Inc.',
    value: [{ value: 'R. F. Lafferty & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Voya Investment Management Co. LLC',
    value: [{ value: 'Voya Investment Management Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Capstone Partners',
    value: [{ value: 'Capstone Partners', key: 'firm_name' }],
  },
  {
    label: 'Selective Wealth Management, Inc.',
    value: [{ value: 'Selective Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Patton Albertson Miller Group, LLC',
    value: [{ value: 'Patton Albertson Miller Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'R M Davis Inc',
    value: [{ value: 'R M Davis Inc', key: 'firm_name' }],
  },
  {
    label: 'Asset Strategies Group, LLC',
    value: [{ value: 'Asset Strategies Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Morgens, Waterfall, Vintiadis & Company, Inc.',
    value: [{ value: 'Morgens, Waterfall, Vintiadis & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'GWN Securities Inc.',
    value: [{ value: 'GWN Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lulla Wealth Financial',
    value: [{ value: 'Lulla Wealth Financial', key: 'firm_name' }],
  },
  {
    label: 'NetVEST Financial, L.L.C.',
    value: [{ value: 'NetVEST Financial, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Acorn Financial Advisory Services, Inc.',
    value: [{ value: 'Acorn Financial Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Denali Wealth Management, Inc.',
    value: [{ value: 'Denali Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mission Wealth Management, LP',
    value: [{ value: 'Mission Wealth Management, LP', key: 'firm_name' }],
  },
  {
    label: 'MSCI ESG Research LLC',
    value: [{ value: 'MSCI ESG Research LLC', key: 'firm_name' }],
  },
  {
    label: 'Steel Peak Wealth Management, LLC',
    value: [{ value: 'Steel Peak Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Southern Capital Services Inc',
    value: [{ value: 'Southern Capital Services Inc', key: 'firm_name' }],
  },
  {
    label: 'Financial Resource Management, Inc.',
    value: [{ value: 'Financial Resource Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Truist Advisory Services, Inc.',
    value: [{ value: 'Truist Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Triad Advisors LLC',
    value: [{ value: 'Triad Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Stride Investments, LLC',
    value: [{ value: 'Stride Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Collins/Moody Financial Services, L.L.C.',
    value: [{ value: 'Collins/Moody Financial Services, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Hobbs Group Advisors LLC',
    value: [{ value: 'Hobbs Group Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'First Growth Investment Manager LP',
    value: [{ value: 'First Growth Investment Manager LP', key: 'firm_name' }],
  },
  {
    label: 'Miller Capital Management, Inc.',
    value: [{ value: 'Miller Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sycamore Financial Group',
    value: [{ value: 'Sycamore Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Origin Financial',
    value: [{ value: 'Origin Financial', key: 'firm_name' }],
  },
  {
    label: 'A.G. Morgan Financial Advisors, LLC',
    value: [{ value: 'A.G. Morgan Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Farther Finance Advisors, LLC',
    value: [{ value: 'Farther Finance Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Voya Investments Distributor, LLC',
    value: [{ value: 'Voya Investments Distributor, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sequent Asset Management, LLC',
    value: [{ value: 'Sequent Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lifestyle Freedom Advisory Group, LLC',
    value: [{ value: 'Lifestyle Freedom Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heath Capital Management, Inc.',
    value: [{ value: 'Heath Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Red Mountain Financial Partners',
    value: [{ value: 'Red Mountain Financial Partners', key: 'firm_name' }],
  },
  {
    label: 'Wedbush Securities Inc.',
    value: [{ value: 'Wedbush Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'FBL Marketing Services, LLC',
    value: [{ value: 'FBL Marketing Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sequent Planning, LLC',
    value: [{ value: 'Sequent Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Burke Wealth Management LLC',
    value: [{ value: 'Burke Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Webb Financial Group',
    value: [{ value: 'Webb Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Sasco Capital Inc',
    value: [{ value: 'Sasco Capital Inc', key: 'firm_name' }],
  },
  {
    label: 'Boyar Asset Management, Inc.',
    value: [{ value: 'Boyar Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Harbour Investments, Inc.',
    value: [{ value: 'Harbour Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'TD Private Client Wealth LLC',
    value: [{ value: 'TD Private Client Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Aetolia Capital LLC',
    value: [{ value: 'Aetolia Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Alpha Capital Management Group LLC',
    value: [{ value: 'Alpha Capital Management Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Columbia River Advisors',
    value: [{ value: 'Columbia River Advisors', key: 'firm_name' }],
  },
  {
    label: 'Alpha Wealth Funds, LLC',
    value: [{ value: 'Alpha Wealth Funds, LLC', key: 'firm_name' }],
  },
  {
    label: 'RTD Financial Advisors Inc',
    value: [{ value: 'RTD Financial Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'SharpeMachine, LLC',
    value: [{ value: 'SharpeMachine, LLC', key: 'firm_name' }],
  },
  {
    label: 'George M. Hiller Companies, LLC',
    value: [{ value: 'George M. Hiller Companies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cunningham Wealth Management',
    value: [{ value: 'Cunningham Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Concorde Investment Services, LLC',
    value: [{ value: 'Concorde Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kutscher Benner Barsness & Stevens, Inc.',
    value: [{ value: 'Kutscher Benner Barsness & Stevens, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Swords Wealth Management, LLC',
    value: [{ value: 'Swords Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spectrum Financial Alliance, Ltd., L.L.C.',
    value: [{ value: 'Spectrum Financial Alliance, Ltd., L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'IPI Wealth Management, Inc.',
    value: [{ value: 'IPI Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Per Stirling Capital Management, LLC',
    value: [{ value: 'Per Stirling Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spectrum Advisors, Inc.',
    value: [{ value: 'Spectrum Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ARS Wealth Advisors',
    value: [{ value: 'ARS Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Diastole Wealth Management',
    value: [{ value: 'Diastole Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Bankers Life Securities, Inc.',
    value: [{ value: 'Bankers Life Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wellington Shields & Co., LLC',
    value: [{ value: 'Wellington Shields & Co., LLC', key: 'firm_name' }],
  },
  {
    label: 'Pekin Hardy Strauss, Inc.',
    value: [{ value: 'Pekin Hardy Strauss, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Prosperity Financial Group, Inc.',
    value: [{ value: 'Prosperity Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Center Lake Capital Management, LLC',
    value: [{ value: 'Center Lake Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'D & G Advisory Group, L.L.C.',
    value: [{ value: 'D & G Advisory Group, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Wolf River Capital Management, LLC',
    value: [{ value: 'Wolf River Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Prentiss Smith & Company, Inc.',
    value: [{ value: 'Prentiss Smith & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Pine Harbor Wealth Management LLC',
    value: [{ value: 'Pine Harbor Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'AIG Capital Services, Inc.',
    value: [{ value: 'AIG Capital Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Creekside Partners',
    value: [{ value: 'Creekside Partners', key: 'firm_name' }],
  },
  {
    label: 'Tradition Wealth Management LLC',
    value: [{ value: 'Tradition Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Ten Capital Wealth Advisors, LLC',
    value: [{ value: 'Ten Capital Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Empirical Wealth Management',
    value: [{ value: 'Empirical Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Frost Investment Advisors, LLC',
    value: [{ value: 'Frost Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gries Financial LLC',
    value: [{ value: 'Gries Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Perigon Wealth Management, LLC',
    value: [{ value: 'Perigon Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'MFS Fund Distributors, Inc.',
    value: [{ value: 'MFS Fund Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ValueWealth Services, LLC',
    value: [{ value: 'ValueWealth Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ridge Investment Advisors',
    value: [{ value: 'Ridge Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Wealthcare Capital Management LLC',
    value: [{ value: 'Wealthcare Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'HCR Wealth Advisors',
    value: [{ value: 'HCR Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'ggCFO Advisors',
    value: [{ value: 'ggCFO Advisors', key: 'firm_name' }],
  },
  {
    label: 'Trader Wealth Management, LLC',
    value: [{ value: 'Trader Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Davies Wealth Management LLC',
    value: [{ value: 'Davies Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'National Securities Corporation',
    value: [{ value: 'National Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Shearwater Capital',
    value: [{ value: 'Shearwater Capital', key: 'firm_name' }],
  },
  {
    label: 'OZ Wealth Management LLC',
    value: [{ value: 'OZ Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Bridgeway Wealth Partners, LLC',
    value: [{ value: 'Bridgeway Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Global Wealth Advisors',
    value: [{ value: 'Global Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Eads & Heald Wealth Management',
    value: [{ value: 'Eads & Heald Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Magnus Financial Group LLC',
    value: [{ value: 'Magnus Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Integrated Wealth Concepts LLC',
    value: [{ value: 'Integrated Wealth Concepts LLC', key: 'firm_name' }],
  },
  {
    label: 'MWA Financial Services Inc.',
    value: [{ value: 'MWA Financial Services Inc.', key: 'firm_name' }],
  },
  {
    label: 'Rathnam Capital, LLC',
    value: [{ value: 'Rathnam Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Moloney Securities Asset Management LLC',
    value: [{ value: 'Moloney Securities Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Hall Financial Advisors, PC',
    value: [{ value: 'Hall Financial Advisors, PC', key: 'firm_name' }],
  },
  {
    label: 'Colwyn Investments LC',
    value: [{ value: 'Colwyn Investments LC', key: 'firm_name' }],
  },
  {
    label: 'Frisch Financial Group, Inc.',
    value: [{ value: 'Frisch Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Rain Capital Management',
    value: [{ value: 'Rain Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Portfolio Medics, LLC',
    value: [{ value: 'Portfolio Medics, LLC', key: 'firm_name' }],
  },
  {
    label: 'MidAtlantic Capital Management, Inc.',
    value: [{ value: 'MidAtlantic Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fortem Financial Group, LLC',
    value: [{ value: 'Fortem Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Monte Financial Group, LLC',
    value: [{ value: 'Monte Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Prospera Financial Services, Inc.',
    value: [{ value: 'Prospera Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Financial Distributors, Inc.',
    value: [{ value: 'Lincoln Financial Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Hallmark Capital Management Inc',
    value: [{ value: 'Hallmark Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Mindful Asset Planning',
    value: [{ value: 'Mindful Asset Planning', key: 'firm_name' }],
  },
  {
    label: 'Newbridge Securities Corporation',
    value: [{ value: 'Newbridge Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'SW Financial',
    value: [{ value: 'SW Financial', key: 'firm_name' }],
  },
  {
    label: 'Folger Nolan Fleming Douglas Incorporated',
    value: [{ value: 'Folger Nolan Fleming Douglas Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Associated Investment Advisors, Inc.',
    value: [{ value: 'Associated Investment Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Peregrine Asset Advisers, Inc.',
    value: [{ value: 'Peregrine Asset Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Secure Asset Management, L.L.C.',
    value: [{ value: 'Secure Asset Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Nomura Securities International, Inc.',
    value: [{ value: 'Nomura Securities International, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mutual of America Securities LLC',
    value: [{ value: 'Mutual of America Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Epus Global Energy, LLC',
    value: [{ value: 'Epus Global Energy, LLC', key: 'firm_name' }],
  },
  {
    label: 'FHN Financial Securities Corp.',
    value: [{ value: 'FHN Financial Securities Corp.', key: 'firm_name' }],
  },
  {
    label: 'Saddle River Capital Management, LLC',
    value: [{ value: 'Saddle River Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hallett Advisors',
    value: [{ value: 'Hallett Advisors', key: 'firm_name' }],
  },
  {
    label: 'Prudent Investor Advisors, LLC',
    value: [{ value: 'Prudent Investor Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pursell Management Co. LLC',
    value: [{ value: 'Pursell Management Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Lime Advisory Corp.',
    value: [{ value: 'Lime Advisory Corp.', key: 'firm_name' }],
  },
  {
    label: 'STM Asset Management LLC',
    value: [{ value: 'STM Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Transform Wealth, LLC',
    value: [{ value: 'Transform Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alexander Capital, L.P.',
    value: [{ value: 'Alexander Capital, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Stonegate Wealth Management, LLC',
    value: [{ value: 'Stonegate Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Blue Grotto Capital, LLC',
    value: [{ value: 'Blue Grotto Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Manske Wealth Management, LLC',
    value: [{ value: 'Manske Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Century Management Financial Advisors',
    value: [{ value: 'Century Management Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'U.S. Capital Wealth Advisors, LLC',
    value: [{ value: 'U.S. Capital Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Garrison Point Capital, LLC',
    value: [{ value: 'Garrison Point Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mayers Investment Company, LLC',
    value: [{ value: 'Mayers Investment Company, LLC', key: 'firm_name' }],
  },
  {
    label: '9M Investments, LLC',
    value: [{ value: '9M Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gitterman Wealth Management, LLC',
    value: [{ value: 'Gitterman Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heartland Wealth Strategies LLC',
    value: [{ value: 'Heartland Wealth Strategies LLC', key: 'firm_name' }],
  },
  {
    label: 'Symetra Securities, Inc.',
    value: [{ value: 'Symetra Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Haverford Financial Services, Inc.',
    value: [{ value: 'Haverford Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Loyd Capital Performance Partners, Inc.',
    value: [{ value: 'Loyd Capital Performance Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Qubera Wealth Management',
    value: [{ value: 'Qubera Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Mischler Financial Group, Inc.',
    value: [{ value: 'Mischler Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Axia Asset Management, LLC',
    value: [{ value: 'Axia Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Westwood Management Corp',
    value: [{ value: 'Westwood Management Corp', key: 'firm_name' }],
  },
  {
    label: 'Blue Chip Partners, Inc.',
    value: [{ value: 'Blue Chip Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Quad-Cities Investment Group, LLC',
    value: [{ value: 'Quad-Cities Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'DoubleLine Capital LP',
    value: [{ value: 'DoubleLine Capital LP', key: 'firm_name' }],
  },
  {
    label: 'Crew Capital Management, Ltd.',
    value: [{ value: 'Crew Capital Management, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Mesirow Financial Investment Management, Inc.',
    value: [{ value: 'Mesirow Financial Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: '6th Avenue Partners, LLC',
    value: [{ value: '6th Avenue Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'LTG Capital LLC',
    value: [{ value: 'LTG Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'BMO Asset Management Corp.',
    value: [{ value: 'BMO Asset Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Invicta Advisors LLC',
    value: [{ value: 'Invicta Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Cantella & Co., Inc.',
    value: [{ value: 'Cantella & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Core Capital Management, LLC',
    value: [{ value: 'Core Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Saxony Securities, Inc.',
    value: [{ value: 'Saxony Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Penserra Capital Management LLC',
    value: [{ value: 'Penserra Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Providence Financial and Insurances, Inc.',
    value: [{ value: 'Providence Financial and Insurances, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cape Securities Inc.',
    value: [{ value: 'Cape Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bill Spalding Wealth Management',
    value: [{ value: 'Bill Spalding Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Epiqwest Culver Wealth Advisors LLC',
    value: [{ value: 'Epiqwest Culver Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'ALPS Distributors, Inc.',
    value: [{ value: 'ALPS Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Harbor Advisory Corporation',
    value: [{ value: 'Harbor Advisory Corporation', key: 'firm_name' }],
  },
  {
    label: 'Foreside Financial Services, LLC',
    value: [{ value: 'Foreside Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wise, James Leroy',
    value: [{ value: 'Wise, James Leroy', key: 'firm_name' }],
  },
  {
    label: 'Starr-Mathews Financial, LLC',
    value: [{ value: 'Starr-Mathews Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nicholas Hoffman & Company, LLC',
    value: [{ value: 'Nicholas Hoffman & Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Constitution Research & Management Inc',
    value: [{ value: 'Constitution Research & Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Hancock Whitney Investment Services Inc.',
    value: [{ value: 'Hancock Whitney Investment Services Inc.', key: 'firm_name' }],
  },
  {
    label: 'Friedentag Portfolio Management',
    value: [{ value: 'Friedentag Portfolio Management', key: 'firm_name' }],
  },
  {
    label: 'Meritas Wealth Management, LLC',
    value: [{ value: 'Meritas Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Paragon Financial LLC',
    value: [{ value: 'Paragon Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'AmeriTrust Capital Management Ltd',
    value: [{ value: 'AmeriTrust Capital Management Ltd', key: 'firm_name' }],
  },
  {
    label: 'Fleming Wealth Management, LLC',
    value: [{ value: 'Fleming Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Calamos Financial Services LLC',
    value: [{ value: 'Calamos Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Tuttle Financial Services, Ltd.',
    value: [{ value: 'Tuttle Financial Services, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'NBS Financial Services, Inc.',
    value: [{ value: 'NBS Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'DMK Advisor Group, Inc.',
    value: [{ value: 'DMK Advisor Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Napa Wealth Management',
    value: [{ value: 'Napa Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Riverbend Investment Management, LLC',
    value: [{ value: 'Riverbend Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ironwood Wealth Advisers LLC',
    value: [{ value: 'Ironwood Wealth Advisers LLC', key: 'firm_name' }],
  },
  {
    label: 'Criterion Capital Advisors LLC',
    value: [{ value: 'Criterion Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'RNC Genter Capital Management',
    value: [{ value: 'RNC Genter Capital Management', key: 'firm_name' }],
  },
  {
    label: 'TCW Funds Distributors LLC',
    value: [{ value: 'TCW Funds Distributors LLC', key: 'firm_name' }],
  },
  {
    label: 'Park + Elm Investment Advisers',
    value: [{ value: 'Park + Elm Investment Advisers', key: 'firm_name' }],
  },
  {
    label: 'Procyon Advisors, LLC',
    value: [{ value: 'Procyon Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cutler Capital Management',
    value: [{ value: 'Cutler Capital Management', key: 'firm_name' }],
  },
  {
    label: 'HFP Investments, LLC',
    value: [{ value: 'HFP Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Majority Asset Management, Inc.',
    value: [{ value: 'Majority Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Moody, Lynn & Lieberson, LLC',
    value: [{ value: 'Moody, Lynn & Lieberson, LLC', key: 'firm_name' }],
  },
  {
    label: 'Penbrook Management LLC',
    value: [{ value: 'Penbrook Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Parallel Wealth Management',
    value: [{ value: 'Parallel Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Ryan Alm Advisers, LLC',
    value: [{ value: 'Ryan Alm Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Planning Solutions, LLC',
    value: [{ value: 'Financial Planning Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Simmons Capital Group',
    value: [{ value: 'Simmons Capital Group', key: 'firm_name' }],
  },
  {
    label: 'Bradley Wealth LLC',
    value: [{ value: 'Bradley Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'AgIS Management LLC',
    value: [{ value: 'AgIS Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Money Pilot Financial Advisor LLC',
    value: [{ value: 'Money Pilot Financial Advisor LLC', key: 'firm_name' }],
  },
  {
    label: 'Vero Advisors, LLC',
    value: [{ value: 'Vero Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gould Ruma Financial Advisors',
    value: [{ value: 'Gould Ruma Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Private Wealth Management of North Carolina LLC',
    value: [{ value: 'Private Wealth Management of North Carolina LLC', key: 'firm_name' }],
  },
  {
    label: 'Newbridge Wealth Management',
    value: [{ value: 'Newbridge Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'SPC',
    value: [{ value: 'SPC', key: 'firm_name' }],
  },
  {
    label: 'Rainey & Randall Investment Management, Inc.',
    value: [{ value: 'Rainey & Randall Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Great Point Wealth Advisors, LLC',
    value: [{ value: 'Great Point Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Coker & Palmer',
    value: [{ value: 'Coker & Palmer', key: 'firm_name' }],
  },
  {
    label: 'Powell Investment Advisors, LLC',
    value: [{ value: 'Powell Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Clare Market Investments, LLC',
    value: [{ value: 'Clare Market Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Planning and Design LLC',
    value: [{ value: 'Wealth Planning and Design LLC', key: 'firm_name' }],
  },
  {
    label: 'Marshall & Sterling Wealth Advisors, Inc.',
    value: [{ value: 'Marshall & Sterling Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Managed Asset Portfolios, LLC',
    value: [{ value: 'Managed Asset Portfolios, LLC', key: 'firm_name' }],
  },
  {
    label: 'Haese Financial Group Inc',
    value: [{ value: 'Haese Financial Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Impact Shares',
    value: [{ value: 'Impact Shares', key: 'firm_name' }],
  },
  {
    label: 'MJP Wealth Advisors',
    value: [{ value: 'MJP Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Variant Private Wealth LLC',
    value: [{ value: 'Variant Private Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Hayes Financial, Inc.',
    value: [{ value: 'Hayes Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'S.A. Mason, LLC',
    value: [{ value: 'S.A. Mason, LLC', key: 'firm_name' }],
  },
  {
    label: 'One Seven',
    value: [{ value: 'One Seven', key: 'firm_name' }],
  },
  {
    label: 'Calamos Advisors LLC',
    value: [{ value: 'Calamos Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'FinancialFamilies',
    value: [{ value: 'FinancialFamilies', key: 'firm_name' }],
  },
  {
    label: 'Peak Wealth Group LLC',
    value: [{ value: 'Peak Wealth Group LLC', key: 'firm_name' }],
  },
  {
    label: 'West Financial Strategies, Inc.',
    value: [{ value: 'West Financial Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fort Sheridan Advisors LLC',
    value: [{ value: 'Fort Sheridan Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Equinox Wealth Management',
    value: [{ value: 'Equinox Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'DeWitt Capital Management',
    value: [{ value: 'DeWitt Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Carroll Frank & Plotkin LLC',
    value: [{ value: 'Carroll Frank & Plotkin LLC', key: 'firm_name' }],
  },
  {
    label: 'MONECO Advisors',
    value: [{ value: 'MONECO Advisors', key: 'firm_name' }],
  },
  {
    label: 'Wilson Financial Group, LLC',
    value: [{ value: 'Wilson Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alexander Capital Wealth Management LLC',
    value: [{ value: 'Alexander Capital Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Meridian Wealth Advisors, LLC',
    value: [{ value: 'Meridian Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Quantum Advisers, LLC',
    value: [{ value: 'Quantum Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'QEM Advisers, LLC',
    value: [{ value: 'QEM Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'First Citizens Investor Services, Inc.',
    value: [{ value: 'First Citizens Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Clear Creek Advisors',
    value: [{ value: 'Clear Creek Advisors', key: 'firm_name' }],
  },
  {
    label: 'Inflection Financial',
    value: [{ value: 'Inflection Financial', key: 'firm_name' }],
  },
  {
    label: 'Five Points Investment Advisors, LLC',
    value: [{ value: 'Five Points Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Lind Partners, LLC',
    value: [{ value: 'The Lind Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'HeadInvest',
    value: [{ value: 'HeadInvest', key: 'firm_name' }],
  },
  {
    label: 'Konnect Health and Wealth',
    value: [{ value: 'Konnect Health and Wealth', key: 'firm_name' }],
  },
  {
    label: 'Whiterock Financial, LLC',
    value: [{ value: 'Whiterock Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'CLG LLC',
    value: [{ value: 'CLG LLC', key: 'firm_name' }],
  },
  {
    label: 'McCloskey & Associates, P.A.',
    value: [{ value: 'McCloskey & Associates, P.A.', key: 'firm_name' }],
  },
  {
    label: 'National Wealth Management, LLC',
    value: [{ value: 'National Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Red Cedar Investment Management',
    value: [{ value: 'Red Cedar Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Comprehensive Financial Planning Inc',
    value: [{ value: 'Comprehensive Financial Planning Inc', key: 'firm_name' }],
  },
  {
    label: 'Midwest Professional Planners, Ltd.',
    value: [{ value: 'Midwest Professional Planners, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'First Citizens Asset Management, Inc',
    value: [{ value: 'First Citizens Asset Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Wealth Management, Inc.',
    value: [{ value: 'Pinnacle Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'IFAM Capital',
    value: [{ value: 'IFAM Capital', key: 'firm_name' }],
  },
  {
    label: 'Crimson Management',
    value: [{ value: 'Crimson Management', key: 'firm_name' }],
  },
  {
    label: 'Leicht Financial Planning and Wealth Management',
    value: [{ value: 'Leicht Financial Planning and Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Stonecrop Wealth Advisors, LLC',
    value: [{ value: 'Stonecrop Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cabildo Capital LLC',
    value: [{ value: 'Cabildo Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Northern Trust Securities, Inc.',
    value: [{ value: 'Northern Trust Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Valor Wealth Management LLC',
    value: [{ value: 'Valor Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Zenyth Advisors LLC',
    value: [{ value: 'Zenyth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Macquarie Capital (USA) Inc.',
    value: [{ value: 'Macquarie Capital (USA) Inc.', key: 'firm_name' }],
  },
  {
    label: 'Troutman Wealth Management',
    value: [{ value: 'Troutman Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Leap Wealth Management',
    value: [{ value: 'Leap Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Mendota Financial Group, LLC',
    value: [{ value: 'Mendota Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Columbia Management Investment Advisers, LLC',
    value: [{ value: 'Columbia Management Investment Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cherokee Investment Partners, LLC',
    value: [{ value: 'Cherokee Investment Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'IFG Advisory, LLC',
    value: [{ value: 'IFG Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Capstone Advisors, LLC',
    value: [{ value: 'Capstone Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Touchstone Securities, Inc.',
    value: [{ value: 'Touchstone Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Barrow Hanley Global Investors',
    value: [{ value: 'Barrow Hanley Global Investors', key: 'firm_name' }],
  },
  {
    label: 'Altair Advisers, LLC',
    value: [{ value: 'Altair Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Securities Capital Corporation',
    value: [{ value: 'Securities Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Berthel, Fisher & Company Financial Services, Inc.',
    value: [{ value: 'Berthel, Fisher & Company Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'BFC Planning, Inc.',
    value: [{ value: 'BFC Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'LifeMark Securities Corp.',
    value: [{ value: 'LifeMark Securities Corp.', key: 'firm_name' }],
  },
  {
    label: 'MBE Wealth Management, LLC',
    value: [{ value: 'MBE Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Permanent Value Inc.',
    value: [{ value: 'Permanent Value Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wealth Management Strategies',
    value: [{ value: 'Wealth Management Strategies', key: 'firm_name' }],
  },
  {
    label: 'Planned Wealth Consultants',
    value: [{ value: 'Planned Wealth Consultants', key: 'firm_name' }],
  },
  {
    label: 'Wingate Wealth Advisors, Inc.',
    value: [{ value: 'Wingate Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Investment Advisory Professionals LLC',
    value: [{ value: 'Investment Advisory Professionals LLC', key: 'firm_name' }],
  },
  {
    label: 'January Capital Advisors LLC',
    value: [{ value: 'January Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'ProsperiFi LLC',
    value: [{ value: 'ProsperiFi LLC', key: 'firm_name' }],
  },
  {
    label: 'Valley View Management Co., LLC',
    value: [{ value: 'Valley View Management Co., LLC', key: 'firm_name' }],
  },
  {
    label: 'Plan Sponsor Adviser, LLC',
    value: [{ value: 'Plan Sponsor Adviser, LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Financial Concepts, LLC',
    value: [{ value: 'Strategic Financial Concepts, LLC', key: 'firm_name' }],
  },
  {
    label: 'Milestone Investment Advisors, LLC',
    value: [{ value: 'Milestone Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Zemenick & Walker Inc',
    value: [{ value: 'Zemenick & Walker Inc', key: 'firm_name' }],
  },
  {
    label: 'Family Legacy, Inc.',
    value: [{ value: 'Family Legacy, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Dwojeski, John J',
    value: [{ value: 'Dwojeski, John J', key: 'firm_name' }],
  },
  {
    label: 'Alpha Advisors, LLC',
    value: [{ value: 'Alpha Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Folionet Financial, LLC',
    value: [{ value: 'Folionet Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Claremont Capital Management, LLC',
    value: [{ value: 'Claremont Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Robert W. Baird & Co. Incorporated',
    value: [{ value: 'Robert W. Baird & Co. Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Bray Capital Advisors, LLC',
    value: [{ value: 'Bray Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Retirement Wealth Advisors, LLC',
    value: [{ value: 'Retirement Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'NYLIFE Securities LLC',
    value: [{ value: 'NYLIFE Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Eagle Strategies LLC',
    value: [{ value: 'Eagle Strategies LLC', key: 'firm_name' }],
  },
  {
    label: 'Johnson & White Wealth Management LLC',
    value: [{ value: 'Johnson & White Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Arthur Stein Financial, LLC',
    value: [{ value: 'Arthur Stein Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ashfield Capital Partners',
    value: [{ value: 'Ashfield Capital Partners', key: 'firm_name' }],
  },
  {
    label: 'Wallace Hart Capital Management',
    value: [{ value: 'Wallace Hart Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Nautica Asset Management LLC',
    value: [{ value: 'Nautica Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Castleview Partners, LLC',
    value: [{ value: 'Castleview Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Refactor Capital Management, LLC',
    value: [{ value: 'Refactor Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kaufman Advisory Services, Inc.',
    value: [{ value: 'Kaufman Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Securities America Advisors, Inc.',
    value: [{ value: 'Securities America Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Avantax Advisory Services',
    value: [{ value: 'Avantax Advisory Services', key: 'firm_name' }],
  },
  {
    label: 'John Hancock Distributors LLC',
    value: [{ value: 'John Hancock Distributors LLC', key: 'firm_name' }],
  },
  {
    label: 'Reliance Financial Advisors',
    value: [{ value: 'Reliance Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'PGIM Private Placement Investors, L.P.',
    value: [{ value: 'PGIM Private Placement Investors, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Wise Planning, Inc.',
    value: [{ value: 'Wise Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Retirement Group, LLC',
    value: [{ value: 'The Retirement Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kopman Investment Advisory',
    value: [{ value: 'Kopman Investment Advisory', key: 'firm_name' }],
  },
  {
    label: 'Evercore Group L.L.C.',
    value: [{ value: 'Evercore Group L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'First Command Advisory Services',
    value: [{ value: 'First Command Advisory Services', key: 'firm_name' }],
  },
  {
    label: 'Sound Asset Management, Inc.',
    value: [{ value: 'Sound Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Wealth Consulting Group',
    value: [{ value: 'The Wealth Consulting Group', key: 'firm_name' }],
  },
  {
    label: 'Hardy Reed, LLC',
    value: [{ value: 'Hardy Reed, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Transparency',
    value: [{ value: 'Financial Transparency', key: 'firm_name' }],
  },
  {
    label: 'MFA Wealth Advisors, LLC',
    value: [{ value: 'MFA Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Raymond James Financial Services Advisors, Inc',
    value: [{ value: 'Raymond James Financial Services Advisors, Inc', key: 'firm_name' }],
  },
  {
    label: 'Tealwood Asset Management',
    value: [{ value: 'Tealwood Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Compass Capital Corporation',
    value: [{ value: 'Compass Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Little House Capital, LLC',
    value: [{ value: 'Little House Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cetera Advisors LLC',
    value: [{ value: 'Cetera Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Beacon Financial Advisers, Inc.',
    value: [{ value: 'Beacon Financial Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PIMCO Investments LLC',
    value: [{ value: 'PIMCO Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Manning & Napier Investor Services, Inc.',
    value: [{ value: 'Manning & Napier Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WealthShield LLC',
    value: [{ value: 'WealthShield LLC', key: 'firm_name' }],
  },
  {
    label: 'Water Asset Management, LLC',
    value: [{ value: 'Water Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Forum Financial Management, LP',
    value: [{ value: 'Forum Financial Management, LP', key: 'firm_name' }],
  },
  {
    label: 'Meridian Investment Advisors',
    value: [{ value: 'Meridian Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'CF Secured, LLC',
    value: [{ value: 'CF Secured, LLC', key: 'firm_name' }],
  },
  {
    label: 'Investment Performance Services LLC',
    value: [{ value: 'Investment Performance Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Arbor Investment Management',
    value: [{ value: 'Arbor Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Independent Financial Group, LLC',
    value: [{ value: 'Independent Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Providence Wealth Advisors, LLC',
    value: [{ value: 'Providence Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mount Vernon Associates Inc',
    value: [{ value: 'Mount Vernon Associates Inc', key: 'firm_name' }],
  },
  {
    label: 'American Investors Company',
    value: [{ value: 'American Investors Company', key: 'firm_name' }],
  },
  {
    label: 'Northeast Planning Associates, Inc.',
    value: [{ value: 'Northeast Planning Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cox Capital Management LLC',
    value: [{ value: 'Cox Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Wells Fargo Securities, LLC',
    value: [{ value: 'Wells Fargo Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Naples Money Management LLC',
    value: [{ value: 'Naples Money Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Whitcomb & Hess, Inc.',
    value: [{ value: 'Whitcomb & Hess, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Anchor Capital',
    value: [{ value: 'Anchor Capital', key: 'firm_name' }],
  },
  {
    label: 'Pruco Securities, LLC.',
    value: [{ value: 'Pruco Securities, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Shepherd Financial Investment Advisory LLC',
    value: [{ value: 'Shepherd Financial Investment Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'VALIC Financial Advisors, Inc.',
    value: [{ value: 'VALIC Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Apex Strategic Wealth, LLC',
    value: [{ value: 'Apex Strategic Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fidelity Personal and Workplace Advisors',
    value: [{ value: 'Fidelity Personal and Workplace Advisors', key: 'firm_name' }],
  },
  {
    label: 'GWFS Equities, Inc.',
    value: [{ value: 'GWFS Equities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PNC Investments',
    value: [{ value: 'PNC Investments', key: 'firm_name' }],
  },
  {
    label: 'Strobus Capital LLC',
    value: [{ value: 'Strobus Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Griffin Capital Securities, LLC',
    value: [{ value: 'Griffin Capital Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Accelerated Wealth Advisors LLC',
    value: [{ value: 'Accelerated Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Creative Financial Designs, Inc.',
    value: [{ value: 'Creative Financial Designs, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kosmos Management LLC',
    value: [{ value: 'Kosmos Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Integra Wealth Advisors, LLC',
    value: [{ value: 'Integra Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'R.M. Stark & Co., Inc.',
    value: [{ value: 'R.M. Stark & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Synergy Capital I, LLC',
    value: [{ value: 'Synergy Capital I, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wolf Group Capital Advisors',
    value: [{ value: 'Wolf Group Capital Advisors', key: 'firm_name' }],
  },
  {
    label: 'StackHabit',
    value: [{ value: 'StackHabit', key: 'firm_name' }],
  },
  {
    label: 'Clarendon Insurance Agency, Inc.',
    value: [{ value: 'Clarendon Insurance Agency, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Jefferies LLC',
    value: [{ value: 'Jefferies LLC', key: 'firm_name' }],
  },
  {
    label: 'American Trust Investment Advisors, LLC',
    value: [{ value: 'American Trust Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Syverson Strege',
    value: [{ value: 'Syverson Strege', key: 'firm_name' }],
  },
  {
    label: 'Estes Financial Services, Inc.',
    value: [{ value: 'Estes Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Intervest International, Inc.',
    value: [{ value: 'Intervest International, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First of Georgia',
    value: [{ value: 'First of Georgia', key: 'firm_name' }],
  },
  {
    label: 'Essex LLC',
    value: [{ value: 'Essex LLC', key: 'firm_name' }],
  },
  {
    label: 'Arvest Wealth Management',
    value: [{ value: 'Arvest Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'URS Advisory',
    value: [{ value: 'URS Advisory', key: 'firm_name' }],
  },
  {
    label: 'OneAmerica Securities, Inc.',
    value: [{ value: 'OneAmerica Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Alpha Wealth Management and Planning LLC',
    value: [{ value: 'Alpha Wealth Management and Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'WFA Wealth Managers',
    value: [{ value: 'WFA Wealth Managers', key: 'firm_name' }],
  },
  {
    label: 'Kovitz Investment Group Partners, LLC',
    value: [{ value: 'Kovitz Investment Group Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fifth Third Securities, Inc.',
    value: [{ value: 'Fifth Third Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Capital Markets IQ, LLC',
    value: [{ value: 'Capital Markets IQ, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madison Avenue Securities, LLC',
    value: [{ value: 'Madison Avenue Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Marin Financial Advisors LLC',
    value: [{ value: 'Marin Financial Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'American Financial Advisors, LLC.',
    value: [{ value: 'American Financial Advisors, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Beall Financial Planning, Inc.',
    value: [{ value: 'Beall Financial Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'CIBC Private Wealth Advisors, Inc.',
    value: [{ value: 'CIBC Private Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Big Sioux Wealth Management',
    value: [{ value: 'Big Sioux Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Cordant, Inc.',
    value: [{ value: 'Cordant, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Focus Financial',
    value: [{ value: 'Focus Financial', key: 'firm_name' }],
  },
  {
    label: 'Cardinal Financial Consultants, LLC',
    value: [{ value: 'Cardinal Financial Consultants, LLC', key: 'firm_name' }],
  },
  {
    label: 'Barnes Wealth Management Group',
    value: [{ value: 'Barnes Wealth Management Group', key: 'firm_name' }],
  },
  {
    label: 'Principal Global Investors, LLC',
    value: [{ value: 'Principal Global Investors, LLC', key: 'firm_name' }],
  },
  {
    label: 'First Midwest Investments',
    value: [{ value: 'First Midwest Investments', key: 'firm_name' }],
  },
  {
    label: 'TriMarC Wealth Management LLC',
    value: [{ value: 'TriMarC Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'SEI Investments Management Corp',
    value: [{ value: 'SEI Investments Management Corp', key: 'firm_name' }],
  },
  {
    label: '401 Advisor, LLC',
    value: [{ value: '401 Advisor, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heritage Wealth Management',
    value: [{ value: 'Heritage Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Longboard Capital Advisors, LLC',
    value: [{ value: 'Longboard Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'R & R Financial Planners, Inc.',
    value: [{ value: 'R & R Financial Planners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Copper River Advisors LLC',
    value: [{ value: 'Copper River Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Davis, Glenn, & Glover Wealth Management Services, LLC',
    value: [{ value: 'Davis, Glenn, & Glover Wealth Management Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Meridian Wealth Management, LLC',
    value: [{ value: 'Meridian Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ford Financial Group',
    value: [{ value: 'Ford Financial Group', key: 'firm_name' }],
  },
  {
    label: 'SA Stone Investment Advisors Inc.',
    value: [{ value: 'SA Stone Investment Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'Nicol Advisors Corporation',
    value: [{ value: 'Nicol Advisors Corporation', key: 'firm_name' }],
  },
  {
    label: 'Multi-Bank Securities, Inc.',
    value: [{ value: 'Multi-Bank Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First Republic Investment Management, Inc.',
    value: [{ value: 'First Republic Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Freestone Capital Management, LLC',
    value: [{ value: 'Freestone Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ensemble Capital Management, LLC',
    value: [{ value: 'Ensemble Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hopwood Financial Services, Inc.',
    value: [{ value: 'Hopwood Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Trilogy Capital, Inc.',
    value: [{ value: 'Trilogy Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Crown Capital Securities, L.P.',
    value: [{ value: 'Crown Capital Securities, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Morningside Asset Management',
    value: [{ value: 'Morningside Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Elevate Wealth Advisory, Inc.',
    value: [{ value: 'Elevate Wealth Advisory, Inc.', key: 'firm_name' }],
  },
  {
    label: 'North Ridge Wealth Advisors, Inc.',
    value: [{ value: 'North Ridge Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Stableford Capital LLC',
    value: [{ value: 'Stableford Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Apricus Wealth LLC',
    value: [{ value: 'Apricus Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Madison Investment Advisors, LLC',
    value: [{ value: 'Madison Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pentwater Capital Management LP',
    value: [{ value: 'Pentwater Capital Management LP', key: 'firm_name' }],
  },
  {
    label: 'The AmeriFlex Group',
    value: [{ value: 'The AmeriFlex Group', key: 'firm_name' }],
  },
  {
    label: 'Hudson Valley Investment Advisors, Inc.',
    value: [{ value: 'Hudson Valley Investment Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'LGT Financial Advisors LLC',
    value: [{ value: 'LGT Financial Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Morgan Stanley AIP GP LP',
    value: [{ value: 'Morgan Stanley AIP GP LP', key: 'firm_name' }],
  },
  {
    label: 'Marble Harbor Investment Counsel, LLC',
    value: [{ value: 'Marble Harbor Investment Counsel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cutter & Company, Inc.',
    value: [{ value: 'Cutter & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Camden Capital, LLC',
    value: [{ value: 'Camden Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Allworth Financial, L.P.',
    value: [{ value: 'Allworth Financial, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Provision Wealth Management, Inc',
    value: [{ value: 'Provision Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Fidelity Brokerage Services LLC',
    value: [{ value: 'Fidelity Brokerage Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Third Eye Associates, Ltd',
    value: [{ value: 'Third Eye Associates, Ltd', key: 'firm_name' }],
  },
  {
    label: 'Alliant Financial Management, Inc.',
    value: [{ value: 'Alliant Financial Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Occidental Asset Management, LLC',
    value: [{ value: 'Occidental Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Crewe Advisors',
    value: [{ value: 'Crewe Advisors', key: 'firm_name' }],
  },
  {
    label: 'Eastgate Capital Advisors LLC',
    value: [{ value: 'Eastgate Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Belpointe Asset Management LLC',
    value: [{ value: 'Belpointe Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Norton Financial Consultants',
    value: [{ value: 'Norton Financial Consultants', key: 'firm_name' }],
  },
  {
    label: 'IC Advisory Services, Inc.',
    value: [{ value: 'IC Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Centaurus Financial, Inc.',
    value: [{ value: 'Centaurus Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Goldman Sachs Ayco Personal Financial Management',
    value: [{ value: 'Goldman Sachs Ayco Personal Financial Management', key: 'firm_name' }],
  },
  {
    label: 'Sculati Wealth Management, LLC',
    value: [{ value: 'Sculati Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'AdvicePeriod, LLC',
    value: [{ value: 'AdvicePeriod, LLC', key: 'firm_name' }],
  },
  {
    label: 'Zora Financial',
    value: [{ value: 'Zora Financial', key: 'firm_name' }],
  },
  {
    label: 'Bridgeworth Wealth Management',
    value: [{ value: 'Bridgeworth Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'ResQ Investment Partners, LLC',
    value: [{ value: 'ResQ Investment Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Total Investment Management, Inc.',
    value: [{ value: 'Total Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cooper Point Advisors LLC',
    value: [{ value: 'Cooper Point Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Certified Advisory Corp',
    value: [{ value: 'Certified Advisory Corp', key: 'firm_name' }],
  },
  {
    label: 'Banker Investment Group',
    value: [{ value: 'Banker Investment Group', key: 'firm_name' }],
  },
  {
    label: 'Alexander Investment Services Co.',
    value: [{ value: 'Alexander Investment Services Co.', key: 'firm_name' }],
  },
  {
    label: 'Monroe Vos Consulting, Inc.',
    value: [{ value: 'Monroe Vos Consulting, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Opus Investments',
    value: [{ value: 'Opus Investments', key: 'firm_name' }],
  },
  {
    label: 'Switchback Financial',
    value: [{ value: 'Switchback Financial', key: 'firm_name' }],
  },
  {
    label: 'Rollins Financial Advisors, LLC',
    value: [{ value: 'Rollins Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Peterkin Financial, LLC',
    value: [{ value: 'Peterkin Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brokers International Financial Services, LLC.',
    value: [{ value: 'Brokers International Financial Services, LLC.', key: 'firm_name' }],
  },
  {
    label: 'SEIA',
    value: [{ value: 'SEIA', key: 'firm_name' }],
  },
  {
    label: 'LaSalle St Securities, L.L.C.',
    value: [{ value: 'LaSalle St Securities, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Auctus Advisors LLC',
    value: [{ value: 'Auctus Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Jato Wealth Advisory',
    value: [{ value: 'Jato Wealth Advisory', key: 'firm_name' }],
  },
  {
    label: 'Van Eck Securities Corporation',
    value: [{ value: 'Van Eck Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Kimery Wealth Management, LLC',
    value: [{ value: 'Kimery Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kempen & Co U.S.A., Inc.',
    value: [{ value: 'Kempen & Co U.S.A., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Miracle Mile Advisors, LLC',
    value: [{ value: 'Miracle Mile Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Palladium Registered Investment Advisors',
    value: [{ value: 'Palladium Registered Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Cypress Strategies',
    value: [{ value: 'Cypress Strategies', key: 'firm_name' }],
  },
  {
    label: '401karat',
    value: [{ value: '401karat', key: 'firm_name' }],
  },
  {
    label: 'AlphaStar Capital Management, LLC',
    value: [{ value: 'AlphaStar Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bridgewater Advisors Inc.',
    value: [{ value: 'Bridgewater Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'Triad Hybrid Solutions, LLC',
    value: [{ value: 'Triad Hybrid Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brighton Securities Corp.',
    value: [{ value: 'Brighton Securities Corp.', key: 'firm_name' }],
  },
  {
    label: 'Barings Securities LLC',
    value: [{ value: 'Barings Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Jurika, Mills & Keifer, LLC',
    value: [{ value: 'Jurika, Mills & Keifer, LLC', key: 'firm_name' }],
  },
  {
    label: 'Beck Bode LLC',
    value: [{ value: 'Beck Bode LLC', key: 'firm_name' }],
  },
  {
    label: 'High Pointe Capital Management, LLC',
    value: [{ value: 'High Pointe Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Prien Asset Management, LLC',
    value: [{ value: 'Prien Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'SCU Financial',
    value: [{ value: 'SCU Financial', key: 'firm_name' }],
  },
  {
    label: 'Petra Financial Advisors Inc',
    value: [{ value: 'Petra Financial Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Human Capital Management',
    value: [{ value: 'Human Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Sierra Ocean, LLC',
    value: [{ value: 'Sierra Ocean, LLC', key: 'firm_name' }],
  },
  {
    label: 'Maxim Capital Advisors, Inc.',
    value: [{ value: 'Maxim Capital Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Associated Investor Services, Inc.',
    value: [{ value: 'Associated Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Asset Management Strategies, Inc.',
    value: [{ value: 'Asset Management Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Security First Advisors',
    value: [{ value: 'Security First Advisors', key: 'firm_name' }],
  },
  {
    label: 'Gate Key Financial L.L.C.',
    value: [{ value: 'Gate Key Financial L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Apeiron',
    value: [{ value: 'Apeiron', key: 'firm_name' }],
  },
  {
    label: 'Cole Wealth Management, LLC',
    value: [{ value: 'Cole Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Universal Capital Wealth Management',
    value: [{ value: 'Universal Capital Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Ames Capital Management, Inc.',
    value: [{ value: 'Ames Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Global Beta Advisors LLC',
    value: [{ value: 'Global Beta Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Highstone Group',
    value: [{ value: 'Highstone Group', key: 'firm_name' }],
  },
  {
    label: 'Measured Wealth Private Client Group, LLC',
    value: [{ value: 'Measured Wealth Private Client Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Retirement Planners of America',
    value: [{ value: 'Retirement Planners of America', key: 'firm_name' }],
  },
  {
    label: 'The Tidal Group, Inc.',
    value: [{ value: 'The Tidal Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Integrity Investment Advisors, LLC',
    value: [{ value: 'Integrity Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CUNA Brokerage Services, Inc.',
    value: [{ value: 'CUNA Brokerage Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Telos Capital Management, Inc.',
    value: [{ value: 'Telos Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ausdal Financial Partners, Inc.',
    value: [{ value: 'Ausdal Financial Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bridges Investment Management Inc',
    value: [{ value: 'Bridges Investment Management Inc', key: 'firm_name' }],
  },
  {
    label: 'First Fidelity Group LLC',
    value: [{ value: 'First Fidelity Group LLC', key: 'firm_name' }],
  },
  {
    label: 'The Huntington Investment Company',
    value: [{ value: 'The Huntington Investment Company', key: 'firm_name' }],
  },
  {
    label: 'Opinicus',
    value: [{ value: 'Opinicus', key: 'firm_name' }],
  },
  {
    label: 'Swan Global Investments, LLC',
    value: [{ value: 'Swan Global Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lighthouse Financial Planning, LLC',
    value: [{ value: 'Lighthouse Financial Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Family Asset Management, LLC',
    value: [{ value: 'Family Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Faith Investor Services',
    value: [{ value: 'Faith Investor Services', key: 'firm_name' }],
  },
  {
    label: 'Advisor Resource Council',
    value: [{ value: 'Advisor Resource Council', key: 'firm_name' }],
  },
  {
    label: 'CFG Wealth Management Services Inc',
    value: [{ value: 'CFG Wealth Management Services Inc', key: 'firm_name' }],
  },
  {
    label: 'National Wealth Management Group, LLC',
    value: [{ value: 'National Wealth Management Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ravello Partners LLC',
    value: [{ value: 'Ravello Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Sanctuary Advisors, LLC',
    value: [{ value: 'Sanctuary Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pacific Wealth Strategies Group, Inc.',
    value: [{ value: 'Pacific Wealth Strategies Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Leigh Baldwin & Co., LLC',
    value: [{ value: 'Leigh Baldwin & Co., LLC', key: 'firm_name' }],
  },
  {
    label: 'Franklin Retirement Solutions, Inc.',
    value: [{ value: 'Franklin Retirement Solutions, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Clarus Group LLC',
    value: [{ value: 'Clarus Group LLC', key: 'firm_name' }],
  },
  {
    label: 'ISTO Advisors, LLC',
    value: [{ value: 'ISTO Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Asset Solutions',
    value: [{ value: 'Asset Solutions', key: 'firm_name' }],
  },
  {
    label: 'MMA Securities LLC',
    value: [{ value: 'MMA Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Liberty Advisers Inc.',
    value: [{ value: 'Liberty Advisers Inc.', key: 'firm_name' }],
  },
  {
    label: 'Shalom Global Capital Group LLC',
    value: [{ value: 'Shalom Global Capital Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Slavic Mutual Funds Management Corp.',
    value: [{ value: 'Slavic Mutual Funds Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Clinton Investment Management, LLC',
    value: [{ value: 'Clinton Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Maxim Financial Advisors LLC',
    value: [{ value: 'Maxim Financial Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Ascent Wealth Partners, LLC',
    value: [{ value: 'Ascent Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Coastal Investment Advisors',
    value: [{ value: 'Coastal Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'NAV Wealth Management LLC',
    value: [{ value: 'NAV Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Schroder Fund Advisors LLC',
    value: [{ value: 'Schroder Fund Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Baker & Yoest Investment Advisers, Inc.',
    value: [{ value: 'Baker & Yoest Investment Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Endowment Wealth Management, Inc.',
    value: [{ value: 'Endowment Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Capital Preservation Management',
    value: [{ value: 'Capital Preservation Management', key: 'firm_name' }],
  },
  {
    label: 'Dogpatch Capital',
    value: [{ value: 'Dogpatch Capital', key: 'firm_name' }],
  },
  {
    label: 'Financial Life Focus, LLC',
    value: [{ value: 'Financial Life Focus, LLC', key: 'firm_name' }],
  },
  {
    label: 'Loomis Sayles Distributors, L.P.',
    value: [{ value: 'Loomis Sayles Distributors, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Private Wealth Partners, LLC',
    value: [{ value: 'Private Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Morgenthaler Management Corporation',
    value: [{ value: 'Morgenthaler Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'Southern Financial Group, LLC',
    value: [{ value: 'Southern Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Supreme Alliance LLC',
    value: [{ value: 'Supreme Alliance LLC', key: 'firm_name' }],
  },
  {
    label: 'Herbert J. Sims Capital Management, Inc.',
    value: [{ value: 'Herbert J. Sims Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kunath Karren Rinne & Atkin LLC',
    value: [{ value: 'Kunath Karren Rinne & Atkin LLC', key: 'firm_name' }],
  },
  {
    label: 'Divine Asset Management LLC',
    value: [{ value: 'Divine Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Full Court Strategic Wealth, LLC',
    value: [{ value: 'Full Court Strategic Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vickery Creek Capital Management',
    value: [{ value: 'Vickery Creek Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Timberlink LLC',
    value: [{ value: 'Timberlink LLC', key: 'firm_name' }],
  },
  {
    label: 'Patrick Capital Markets, LLC',
    value: [{ value: 'Patrick Capital Markets, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madrona Venture Group, LLC',
    value: [{ value: 'Madrona Venture Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Source Financial Advisors, LLC',
    value: [{ value: 'Source Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Toth Financial Advisory Corp',
    value: [{ value: 'Toth Financial Advisory Corp', key: 'firm_name' }],
  },
  {
    label: 'Financial Consulate',
    value: [{ value: 'Financial Consulate', key: 'firm_name' }],
  },
  {
    label: 'RKL Wealth Management LLC',
    value: [{ value: 'RKL Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'True Wealth Nation',
    value: [{ value: 'True Wealth Nation', key: 'firm_name' }],
  },
  {
    label: 'Presper Financial Architects, LLC',
    value: [{ value: 'Presper Financial Architects, LLC', key: 'firm_name' }],
  },
  {
    label: 'Buckeye Financial Solutions, Inc.',
    value: [{ value: 'Buckeye Financial Solutions, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Plum Pointe Wealth Management, Ltd',
    value: [{ value: 'Plum Pointe Wealth Management, Ltd', key: 'firm_name' }],
  },
  {
    label: 'Consolidated Planning Corporation',
    value: [{ value: 'Consolidated Planning Corporation', key: 'firm_name' }],
  },
  {
    label: 'Spinnaker Investment Group, LLC',
    value: [{ value: 'Spinnaker Investment Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Waterford Capital Investment Advisory Services LLC',
    value: [{ value: 'Waterford Capital Investment Advisory Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Hunt Wealth Management',
    value: [{ value: 'Hunt Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'B. Riley Securities, Inc.',
    value: [{ value: 'B. Riley Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ray|Theil Wealth Management, LLC',
    value: [{ value: 'Ray|Theil Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'QM Advisors LLC',
    value: [{ value: 'QM Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'WisdomTree Asset Management, Inc.',
    value: [{ value: 'WisdomTree Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Context Financial LLC',
    value: [{ value: 'Context Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Hohimer Wealth Management, LLC',
    value: [{ value: 'Hohimer Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Certior Financial Group, LLC',
    value: [{ value: 'Certior Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Enrich Financial Partners LLC',
    value: [{ value: 'Enrich Financial Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Paul R. Ried Financial Group, LLC',
    value: [{ value: 'Paul R. Ried Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Financial Group, LLC',
    value: [{ value: 'Strategic Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'HighTower Securities, LLC',
    value: [{ value: 'HighTower Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'CBIZ Financial Solutions, Inc.',
    value: [{ value: 'CBIZ Financial Solutions, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WisMed Financial, Inc.',
    value: [{ value: 'WisMed Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'EK&A',
    value: [{ value: 'EK&A', key: 'firm_name' }],
  },
  {
    label: 'Clifford Swan Investment Counselors',
    value: [{ value: 'Clifford Swan Investment Counselors', key: 'firm_name' }],
  },
  {
    label: 'Ethos Manager LLC',
    value: [{ value: 'Ethos Manager LLC', key: 'firm_name' }],
  },
  {
    label: 'Cunningham & Associates, LLC',
    value: [{ value: 'Cunningham & Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mark Monreal & Associates',
    value: [{ value: 'Mark Monreal & Associates', key: 'firm_name' }],
  },
  {
    label: 'Private Client Group Asset Management',
    value: [{ value: 'Private Client Group Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Park 10 Financial LLC',
    value: [{ value: 'Park 10 Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Broyhill Asset Management, LLC',
    value: [{ value: 'Broyhill Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Warwick Partners',
    value: [{ value: 'Warwick Partners', key: 'firm_name' }],
  },
  {
    label: 'American Funds Distributors, Inc.',
    value: [{ value: 'American Funds Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'HB Wealth Management, LLC',
    value: [{ value: 'HB Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'CapAcuity LLC',
    value: [{ value: 'CapAcuity LLC', key: 'firm_name' }],
  },
  {
    label: 'Harris Investment Advisors LLC',
    value: [{ value: 'Harris Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'ThinkBetter',
    value: [{ value: 'ThinkBetter', key: 'firm_name' }],
  },
  {
    label: 'M. E. Allison & Co., Inc.',
    value: [{ value: 'M. E. Allison & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Potomac Fund Management',
    value: [{ value: 'Potomac Fund Management', key: 'firm_name' }],
  },
  {
    label: 'StoneCrest Wealth Management, Inc',
    value: [{ value: 'StoneCrest Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Stegent Equity Advisors Inc.',
    value: [{ value: 'Stegent Equity Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brightworth, LLC',
    value: [{ value: 'Brightworth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sandy Morris Financial & Estate Planning Services, LLC',
    value: [{ value: 'Sandy Morris Financial & Estate Planning Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Smallwood Wealth Investment Management, LLC',
    value: [{ value: 'Smallwood Wealth Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Transformation Wealth Management, LLC',
    value: [{ value: 'Transformation Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Parnassus Investments, LLC',
    value: [{ value: 'Parnassus Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Presidio Capital Management, LLC',
    value: [{ value: 'Presidio Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cooper McManus',
    value: [{ value: 'Cooper McManus', key: 'firm_name' }],
  },
  {
    label: 'Light Financial Services, Inc.',
    value: [{ value: 'Light Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Clarkston Capital Partners, LLC',
    value: [{ value: 'Clarkston Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'TD Ameritrade, Inc.',
    value: [{ value: 'TD Ameritrade, Inc.', key: 'firm_name' }],
  },
  {
    label: 'AlphaCore Capital LLC',
    value: [{ value: 'AlphaCore Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Elk River Wealth Management LLC',
    value: [{ value: 'Elk River Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Symmetry Partners',
    value: [{ value: 'Symmetry Partners', key: 'firm_name' }],
  },
  {
    label: 'Perpetual Capital Management, LP',
    value: [{ value: 'Perpetual Capital Management, LP', key: 'firm_name' }],
  },
  {
    label: 'SWBC Investment Company',
    value: [{ value: 'SWBC Investment Company', key: 'firm_name' }],
  },
  {
    label: 'Signals Matter Advisors, LLC',
    value: [{ value: 'Signals Matter Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'SMArtX Advisory Solutions',
    value: [{ value: 'SMArtX Advisory Solutions', key: 'firm_name' }],
  },
  {
    label: 'Scharf Investments, LLC',
    value: [{ value: 'Scharf Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Art and Science of Successful Planning',
    value: [{ value: 'The Art and Science of Successful Planning', key: 'firm_name' }],
  },
  {
    label: 'Financial Resources Management Corp',
    value: [{ value: 'Financial Resources Management Corp', key: 'firm_name' }],
  },
  {
    label: 'BLB & B Advisors, LLC',
    value: [{ value: 'BLB & B Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'PeachCap Tax & Advisory, LLC',
    value: [{ value: 'PeachCap Tax & Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Buckingham Strategic Wealth, LLC',
    value: [{ value: 'Buckingham Strategic Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sussex Financial Group, Inc',
    value: [{ value: 'Sussex Financial Group, Inc', key: 'firm_name' }],
  },
  {
    label: 'W.G. Nielsen & Co.',
    value: [{ value: 'W.G. Nielsen & Co.', key: 'firm_name' }],
  },
  {
    label: 'Oakmont Capital Management, LLC',
    value: [{ value: 'Oakmont Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'JRL Capital Advisors LLC',
    value: [{ value: 'JRL Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Hoskin Capital',
    value: [{ value: 'Hoskin Capital', key: 'firm_name' }],
  },
  {
    label: 'Riverwater Partners LLC',
    value: [{ value: 'Riverwater Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Intelligence Driven Advisers, LLC',
    value: [{ value: 'Intelligence Driven Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'MariPau Wealth Management LLC',
    value: [{ value: 'MariPau Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Forsyth Capital LLC',
    value: [{ value: 'Forsyth Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Alder Financial Group Inc',
    value: [{ value: 'Alder Financial Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Optimus Wealth Management Group, Inc.',
    value: [{ value: 'Optimus Wealth Management Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Adaptive Investments',
    value: [{ value: 'Adaptive Investments', key: 'firm_name' }],
  },
  {
    label: 'Charter Advisory Corporation',
    value: [{ value: 'Charter Advisory Corporation', key: 'firm_name' }],
  },
  {
    label: 'Synchronicity',
    value: [{ value: 'Synchronicity', key: 'firm_name' }],
  },
  {
    label: 'Becker Capital Management, Inc.',
    value: [{ value: 'Becker Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Concentio Capital Management, LLC',
    value: [{ value: 'Concentio Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'CM First Capital Corp.',
    value: [{ value: 'CM First Capital Corp.', key: 'firm_name' }],
  },
  {
    label: 'SageOak Financial, LLC',
    value: [{ value: 'SageOak Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'AWM Capital, LLC',
    value: [{ value: 'AWM Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Maxim Group LLC',
    value: [{ value: 'Maxim Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Victory Fiduciary Consulting',
    value: [{ value: 'Victory Fiduciary Consulting', key: 'firm_name' }],
  },
  {
    label: 'Servant Wealth Management LLC',
    value: [{ value: 'Servant Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'LFA',
    value: [{ value: 'LFA', key: 'firm_name' }],
  },
  {
    label: 'Green Investment Management, Inc.',
    value: [{ value: 'Green Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'American Community Wealth Management, LLC',
    value: [{ value: 'American Community Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Broadwing Capital Advisors, Inc.',
    value: [{ value: 'Broadwing Capital Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Insight Wealth Partners',
    value: [{ value: 'Insight Wealth Partners', key: 'firm_name' }],
  },
  {
    label: 'Thomas Leo Advisory, LLC',
    value: [{ value: 'Thomas Leo Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bourgeon Capital Management LLC',
    value: [{ value: 'Bourgeon Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Ergawealth Advisors, Inc.',
    value: [{ value: 'Ergawealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Baron Capital Management, Inc.',
    value: [{ value: 'Baron Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Murray Street Capital Advisors, LLC',
    value: [{ value: 'Murray Street Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Donald Smith & Co., Inc.',
    value: [{ value: 'Donald Smith & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Baugh & Associates, LLC',
    value: [{ value: 'Baugh & Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fiduciary Investment Trusts, LLC',
    value: [{ value: 'Fiduciary Investment Trusts, LLC', key: 'firm_name' }],
  },
  {
    label: 'Dominion Investor Services, Inc.',
    value: [{ value: 'Dominion Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Simmons Partnership, LLC',
    value: [{ value: 'The Simmons Partnership, LLC', key: 'firm_name' }],
  },
  {
    label: 'GeoWealth Management LLC',
    value: [{ value: 'GeoWealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Shasta Ventures',
    value: [{ value: 'Shasta Ventures', key: 'firm_name' }],
  },
  {
    label: 'Seasons of Advice Wealth Management',
    value: [{ value: 'Seasons of Advice Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Archer Investment Management',
    value: [{ value: 'Archer Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Sugarloaf Wealth Management, LLC',
    value: [{ value: 'Sugarloaf Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eightfold Real Estate Capital, L.P.',
    value: [{ value: 'Eightfold Real Estate Capital, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Regal Capital Management, Inc.',
    value: [{ value: 'Regal Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Stern Brothers & Co.',
    value: [{ value: 'Stern Brothers & Co.', key: 'firm_name' }],
  },
  {
    label: 'The Bolin Company',
    value: [{ value: 'The Bolin Company', key: 'firm_name' }],
  },
  {
    label: 'Sapphire Wealth Planning LLC',
    value: [{ value: 'Sapphire Wealth Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'G. A. Repple & Company',
    value: [{ value: 'G. A. Repple & Company', key: 'firm_name' }],
  },
  {
    label: 'Good Life Advisors, LLC',
    value: [{ value: 'Good Life Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'SigFig Wealth Management LLC',
    value: [{ value: 'SigFig Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Needham & Company, LLC',
    value: [{ value: 'Needham & Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Focus Securities LLC',
    value: [{ value: 'Focus Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Eureka Wealth Solutions',
    value: [{ value: 'Eureka Wealth Solutions', key: 'firm_name' }],
  },
  {
    label: 'Blue Oak Capital LLC',
    value: [{ value: 'Blue Oak Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Stage 2 Planning Partners',
    value: [{ value: 'Stage 2 Planning Partners', key: 'firm_name' }],
  },
  {
    label: 'Bonfanti Investment Services, LLC',
    value: [{ value: 'Bonfanti Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ares Financial Consulting LLC',
    value: [{ value: 'Ares Financial Consulting LLC', key: 'firm_name' }],
  },
  {
    label: 'Wave Wealth Management, LLC',
    value: [{ value: 'Wave Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advisory Alpha, LLC',
    value: [{ value: 'Advisory Alpha, LLC', key: 'firm_name' }],
  },
  {
    label: 'FNEX Capital, LLC',
    value: [{ value: 'FNEX Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pismo Pacific Financial Services, LLC',
    value: [{ value: 'Pismo Pacific Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'AWM Global Advisors',
    value: [{ value: 'AWM Global Advisors', key: 'firm_name' }],
  },
  {
    label: 'Montello Wealth Management, Inc.',
    value: [{ value: 'Montello Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kuhn Capital Partners',
    value: [{ value: 'Kuhn Capital Partners', key: 'firm_name' }],
  },
  {
    label: 'Naviter Wealth, LLC',
    value: [{ value: 'Naviter Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'D/W Financial Consultants LLC',
    value: [{ value: 'D/W Financial Consultants LLC', key: 'firm_name' }],
  },
  {
    label: 'Miller Financial Services LLC',
    value: [{ value: 'Miller Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Copper Harbor Investment Advisors, LLC',
    value: [{ value: 'Copper Harbor Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Management Solutions',
    value: [{ value: 'Wealth Management Solutions', key: 'firm_name' }],
  },
  {
    label: 'Dubois Wealth Management, LLC',
    value: [{ value: 'Dubois Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'SW Private Wealth, LLC',
    value: [{ value: 'SW Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'IMA Wealth',
    value: [{ value: 'IMA Wealth', key: 'firm_name' }],
  },
  {
    label: 'Martone Capital Management, Inc.',
    value: [{ value: 'Martone Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'D.H. Hill Securities, LLLP',
    value: [{ value: 'D.H. Hill Securities, LLLP', key: 'firm_name' }],
  },
  {
    label: 'WesBanco Investment Department',
    value: [{ value: 'WesBanco Investment Department', key: 'firm_name' }],
  },
  {
    label: 'Simplicity Wealth',
    value: [{ value: 'Simplicity Wealth', key: 'firm_name' }],
  },
  {
    label: 'Plan Partners LLC',
    value: [{ value: 'Plan Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'RiverPark Capital Management LLC',
    value: [{ value: 'RiverPark Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Aurora Private Wealth, Inc.',
    value: [{ value: 'Aurora Private Wealth, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bills Asset Management',
    value: [{ value: 'Bills Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Contrarian Labs Inc.',
    value: [{ value: 'Contrarian Labs Inc.', key: 'firm_name' }],
  },
  {
    label: 'AMJ Financial Wealth Management LLC',
    value: [{ value: 'AMJ Financial Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Adirondack Research & Management Inc.',
    value: [{ value: 'Adirondack Research & Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Argent Retirement Plan Advisors, LLC',
    value: [{ value: 'Argent Retirement Plan Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Dunlap Equity Management, LLC.',
    value: [{ value: 'Dunlap Equity Management, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Childs Investment Group, Inc',
    value: [{ value: 'Childs Investment Group, Inc', key: 'firm_name' }],
  },
  {
    label: 'Contego Capital Group, Inc.',
    value: [{ value: 'Contego Capital Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'HFH Planning Inc.',
    value: [{ value: 'HFH Planning Inc.', key: 'firm_name' }],
  },
  {
    label: 'Highland Investment Advisors LLC',
    value: [{ value: 'Highland Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Smith Anglin Financial, LLC',
    value: [{ value: 'Smith Anglin Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'JSB Capital Management, LLC',
    value: [{ value: 'JSB Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hogin Financial and Insurance Services, Inc.',
    value: [{ value: 'Hogin Financial and Insurance Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Heritage Investment Group, Inc.',
    value: [{ value: 'Heritage Investment Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Coign Capital Advisors LLC',
    value: [{ value: 'Coign Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Management Group',
    value: [{ value: 'Strategic Wealth Management Group', key: 'firm_name' }],
  },
  {
    label: 'Family Legacy Planning',
    value: [{ value: 'Family Legacy Planning', key: 'firm_name' }],
  },
  {
    label: 'Strauss Financial Group',
    value: [{ value: 'Strauss Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Zevenbergen Capital Investments LLC',
    value: [{ value: 'Zevenbergen Capital Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Vision Capital Management Inc',
    value: [{ value: 'Vision Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Robinhood Financial, LLC',
    value: [{ value: 'Robinhood Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rosemeyer Management Group',
    value: [{ value: 'Rosemeyer Management Group', key: 'firm_name' }],
  },
  {
    label: 'Family Wealth Partners, LLC',
    value: [{ value: 'Family Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'BakerAvenue',
    value: [{ value: 'BakerAvenue', key: 'firm_name' }],
  },
  {
    label: 'Create Wealth Management, LLC',
    value: [{ value: 'Create Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cambridge Advisors, LLC',
    value: [{ value: 'Cambridge Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Carolina Financial Services Group, Inc.',
    value: [{ value: 'Carolina Financial Services Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'VBC Securities, LLC',
    value: [{ value: 'VBC Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cannon Advisors',
    value: [{ value: 'Cannon Advisors', key: 'firm_name' }],
  },
  {
    label: 'Cordatus Wealth Management LLC',
    value: [{ value: 'Cordatus Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Castle Wealth Management',
    value: [{ value: 'Castle Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Matrix Planning Inc',
    value: [{ value: 'Matrix Planning Inc', key: 'firm_name' }],
  },
  {
    label: 'United Capital Management of Kansas, Inc.',
    value: [{ value: 'United Capital Management of Kansas, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kruse Asset Management, LLC',
    value: [{ value: 'Kruse Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Holland Investment Management, Inc.',
    value: [{ value: 'Holland Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'IMS Capital Management',
    value: [{ value: 'IMS Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Cadent Capital Advisors, LLC',
    value: [{ value: 'Cadent Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cypress Financial Planning',
    value: [{ value: 'Cypress Financial Planning', key: 'firm_name' }],
  },
  {
    label: 'Ryan Poage & Co.',
    value: [{ value: 'Ryan Poage & Co.', key: 'firm_name' }],
  },
  {
    label: '79 Financial',
    value: [{ value: '79 Financial', key: 'firm_name' }],
  },
  {
    label: 'Santa Monica Wealth Management, LLC',
    value: [{ value: 'Santa Monica Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stock Plan Advisory Group LLC',
    value: [{ value: 'Stock Plan Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Ridgewood Investments',
    value: [{ value: 'Ridgewood Investments', key: 'firm_name' }],
  },
  {
    label: 'AlphaCentric Advisors LLC',
    value: [{ value: 'AlphaCentric Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'MPI Investment Management Inc',
    value: [{ value: 'MPI Investment Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Williams Wealth Management Group, Inc.',
    value: [{ value: 'Williams Wealth Management Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Stiles Financial Services, Inc.',
    value: [{ value: 'Stiles Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fortress Private Ledger, LLC',
    value: [{ value: 'Fortress Private Ledger, LLC', key: 'firm_name' }],
  },
  {
    label: 'Windward Partners, L.L.C.',
    value: [{ value: 'Windward Partners, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Commerce Street Investment Management',
    value: [{ value: 'Commerce Street Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Qualified Plan Advisory, LLC',
    value: [{ value: 'Qualified Plan Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nuveen Securities, LLC',
    value: [{ value: 'Nuveen Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Castle Financial & Retirement Planning Associates Inc',
    value: [{ value: 'Castle Financial & Retirement Planning Associates Inc', key: 'firm_name' }],
  },
  {
    label: 'Creative Capital Management Investments LLC',
    value: [{ value: 'Creative Capital Management Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Steamboat Capital Partners, LLC',
    value: [{ value: 'Steamboat Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Creand Management',
    value: [{ value: 'Creand Management', key: 'firm_name' }],
  },
  {
    label: 'Financial Planning Suite, Inc.',
    value: [{ value: 'Financial Planning Suite, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Glass Retirement Strategies, Inc.',
    value: [{ value: 'Glass Retirement Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'MacroView Investment Management LLC',
    value: [{ value: 'MacroView Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Baltimore-Washington Financial Advisors',
    value: [{ value: 'Baltimore-Washington Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Safra Securities LLC',
    value: [{ value: 'Safra Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Ciccarelli Advisory Services Inc',
    value: [{ value: 'Ciccarelli Advisory Services Inc', key: 'firm_name' }],
  },
  {
    label: 'GMS Group',
    value: [{ value: 'GMS Group', key: 'firm_name' }],
  },
  {
    label: 'Amicus Financial Advisors, LLC',
    value: [{ value: 'Amicus Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spain Wealth Management, LLC',
    value: [{ value: 'Spain Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kerntke Otto McGlone Wealth Management Group',
    value: [{ value: 'Kerntke Otto McGlone Wealth Management Group', key: 'firm_name' }],
  },
  {
    label: 'JFS Wealth Advisors, LLC',
    value: [{ value: 'JFS Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Adaptation Financial Advisors Inc.',
    value: [{ value: 'Adaptation Financial Advisors Inc.', key: 'firm_name' }],
  },
  //   {
  // label:'Brien Asset Management",
  // value:[{value:'Brien Asset Management",key:'firm_name'}]
  //   },
  {
    label: 'Headway Financial Solutions, LLC',
    value: [{ value: 'Headway Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Condor Capital Wealth Management',
    value: [{ value: 'Condor Capital Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'CRF Financial Group',
    value: [{ value: 'CRF Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Firethorn Wealth Partners, LLC',
    value: [{ value: 'Firethorn Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Leisure Capital Management, Inc.',
    value: [{ value: 'Leisure Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sound Financial Strategies Group, LLC',
    value: [{ value: 'Sound Financial Strategies Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Avior Wealth Management, LLC',
    value: [{ value: 'Avior Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Altus Wealth Group, LLC',
    value: [{ value: 'Altus Wealth Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Global Retirement Partners LLC',
    value: [{ value: 'Global Retirement Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'IAMS Wealth Management, LLC',
    value: [{ value: 'IAMS Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'One Day In July LLC',
    value: [{ value: 'One Day In July LLC', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Douglas Investments, LLC',
    value: [{ value: 'Lincoln Douglas Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kaye Capital Management',
    value: [{ value: 'Kaye Capital Management', key: 'firm_name' }],
  },
  {
    label: 'MSH Capital Advisors LLC',
    value: [{ value: 'MSH Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Vantage Wealth Management, LLC',
    value: [{ value: 'Vantage Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Edgemont Capital Partners, L.P.',
    value: [{ value: 'Edgemont Capital Partners, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Marsha Lee & Company, Inc.',
    value: [{ value: 'Marsha Lee & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Regent Peak Wealth Advisors, LLC',
    value: [{ value: 'Regent Peak Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pine Grove Financial Group',
    value: [{ value: 'Pine Grove Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Leumi Investment Services Inc.',
    value: [{ value: 'Leumi Investment Services Inc.', key: 'firm_name' }],
  },
  {
    label: 'Gary S. Resetar CPA P.A.',
    value: [{ value: 'Gary S. Resetar CPA P.A.', key: 'firm_name' }],
  },
  {
    label: 'Artisan Partners Distributors LLC',
    value: [{ value: 'Artisan Partners Distributors LLC', key: 'firm_name' }],
  },
  {
    label: 'Key Financial, Inc.',
    value: [{ value: 'Key Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'LongView Wealth Management',
    value: [{ value: 'LongView Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'BDK Financial Services, Inc.',
    value: [{ value: 'BDK Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'IRC Wealth',
    value: [{ value: 'IRC Wealth', key: 'firm_name' }],
  },
  {
    label: 'American Wealth Management, Inc.',
    value: [{ value: 'American Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Nottinghill Capital Management Inc.',
    value: [{ value: 'Nottinghill Capital Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'CUE Financial Group, Inc.',
    value: [{ value: 'CUE Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SST Benefits Consulting',
    value: [{ value: 'SST Benefits Consulting', key: 'firm_name' }],
  },
  {
    label: 'Empowered Retirement, Inc.',
    value: [{ value: 'Empowered Retirement, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Columbia Pacific Wealth Management',
    value: [{ value: 'Columbia Pacific Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Compass Wealth Management, LLC',
    value: [{ value: 'Compass Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Omega Advisory LLC',
    value: [{ value: 'Omega Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'Crossmark Global Investments, Inc.',
    value: [{ value: 'Crossmark Global Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Able Wealth Management LLC',
    value: [{ value: 'Able Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Claro Advisors, LLC',
    value: [{ value: 'Claro Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lederer & Associates Investment Counsel',
    value: [{ value: 'Lederer & Associates Investment Counsel', key: 'firm_name' }],
  },
  {
    label: 'Sheets Smith Wealth Management',
    value: [{ value: 'Sheets Smith Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'SB Wealth',
    value: [{ value: 'SB Wealth', key: 'firm_name' }],
  },
  {
    label: 'Ignite Planners LLC',
    value: [{ value: 'Ignite Planners LLC', key: 'firm_name' }],
  },
  {
    label: 'Rather & Kittrell, Inc.',
    value: [{ value: 'Rather & Kittrell, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Hays Financial Group, LLC',
    value: [{ value: 'Hays Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'LBG Advisor',
    value: [{ value: 'LBG Advisor', key: 'firm_name' }],
  },
  {
    label: 'Curbstone Financial Management Corp.',
    value: [{ value: 'Curbstone Financial Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Colbert Investment Management Co',
    value: [{ value: 'Colbert Investment Management Co', key: 'firm_name' }],
  },
  {
    label: 'Texas Financial Advisory',
    value: [{ value: 'Texas Financial Advisory', key: 'firm_name' }],
  },
  {
    label: 'T/R Financial Management Group, LLC',
    value: [{ value: 'T/R Financial Management Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Healthcare Value Capital, LLC',
    value: [{ value: 'Healthcare Value Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'MCMG Capital Advisors, Inc.',
    value: [{ value: 'MCMG Capital Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Oder Investment Management, LLC',
    value: [{ value: 'Oder Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Klaraos, LLC',
    value: [{ value: 'Klaraos, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gladstone Management Corporation',
    value: [{ value: 'Gladstone Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'RVX Asset Management LLC',
    value: [{ value: 'RVX Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Blue Marble Investments',
    value: [{ value: 'Blue Marble Investments', key: 'firm_name' }],
  },
  {
    label: 'Carter, Terry & Company, Inc.',
    value: [{ value: 'Carter, Terry & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'On Point Wealth Strategies, LLC',
    value: [{ value: 'On Point Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Growth Investment Management Services',
    value: [{ value: 'Capital Growth Investment Management Services', key: 'firm_name' }],
  },
  {
    label: 'Ogorek Wealth Management, LLC',
    value: [{ value: 'Ogorek Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'North Shore Private Asset Management, LLC',
    value: [{ value: 'North Shore Private Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'NSR Invest',
    value: [{ value: 'NSR Invest', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Associates, Ltd.',
    value: [{ value: 'Pinnacle Associates, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Ulrich Investment Consultants',
    value: [{ value: 'Ulrich Investment Consultants', key: 'firm_name' }],
  },
  {
    label: 'DEW Wealth Strategies, LLC',
    value: [{ value: 'DEW Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Broadridge Business Process Outsourcing, LLC',
    value: [{ value: 'Broadridge Business Process Outsourcing, LLC', key: 'firm_name' }],
  },
  {
    label: 'Charles D. Hyman & Company',
    value: [{ value: 'Charles D. Hyman & Company', key: 'firm_name' }],
  },
  {
    label: 'Sovereign Investment Advisors LLC',
    value: [{ value: 'Sovereign Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Perennial Financial Services',
    value: [{ value: 'Perennial Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Chicago Wealth Management, Inc.',
    value: [{ value: 'Chicago Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Investments, LLC',
    value: [{ value: 'Pinnacle Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Diversified Capital Corp.',
    value: [{ value: 'Diversified Capital Corp.', key: 'firm_name' }],
  },
  {
    label: 'Market Guard',
    value: [{ value: 'Market Guard', key: 'firm_name' }],
  },
  {
    label: 'Saugatuck Capital Company',
    value: [{ value: 'Saugatuck Capital Company', key: 'firm_name' }],
  },
  {
    label: '12th Street Asset Management',
    value: [{ value: '12th Street Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Cuthbert Financial Guidance',
    value: [{ value: 'Cuthbert Financial Guidance', key: 'firm_name' }],
  },
  {
    label: 'State Farm Investment Management Corp.',
    value: [{ value: 'State Farm Investment Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'BAC Capital Advisors LLC',
    value: [{ value: 'BAC Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'John Hancock Investment Management, LLC',
    value: [{ value: 'John Hancock Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'BofA Securities, Inc.',
    value: [{ value: 'BofA Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lumen Capital Management, L.L.C.',
    value: [{ value: 'Lumen Capital Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Jackson, Grant Investment Advisers, Inc.',
    value: [{ value: 'Jackson, Grant Investment Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Investment Management Services Group, LLC',
    value: [{ value: 'Investment Management Services Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Visionary Wealth Advisors, LLC',
    value: [{ value: 'Visionary Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'First Affirmative Financial Network LLC',
    value: [{ value: 'First Affirmative Financial Network LLC', key: 'firm_name' }],
  },
  {
    label: 'International Assets Investment Management, LLC',
    value: [{ value: 'International Assets Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advisors Asset Management, Inc.',
    value: [{ value: 'Advisors Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Meira',
    value: [{ value: 'Meira', key: 'firm_name' }],
  },
  {
    label: 'Covenant Asset Management, LLC',
    value: [{ value: 'Covenant Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nations Financial Group, Inc.',
    value: [{ value: 'Nations Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bonner & Smith, LLC',
    value: [{ value: 'Bonner & Smith, LLC', key: 'firm_name' }],
  },
  {
    label: 'N.D. Pitman & Company, Inc.',
    value: [{ value: 'N.D. Pitman & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Insight Strategic Wealth LLC',
    value: [{ value: 'Insight Strategic Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Summit Financial Working With People You Trust',
    value: [{ value: 'Summit Financial Working With People You Trust', key: 'firm_name' }],
  },
  {
    label: 'MKD Wealth Coaches, LLC',
    value: [{ value: 'MKD Wealth Coaches, LLC', key: 'firm_name' }],
  },
  {
    label: 'Newman Dignan & Sheerar, Inc',
    value: [{ value: 'Newman Dignan & Sheerar, Inc', key: 'firm_name' }],
  },
  {
    label: 'Garrison Point Advisors, LLC',
    value: [{ value: 'Garrison Point Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wakefield Wealth Management, LLLP',
    value: [{ value: 'Wakefield Wealth Management, LLLP', key: 'firm_name' }],
  },
  {
    label: 'DCM Advisors, LLC',
    value: [{ value: 'DCM Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'IP Financial Advisory Services LLC',
    value: [{ value: 'IP Financial Advisory Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Wootton Financial Group Inc',
    value: [{ value: 'Wootton Financial Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Paulson Investment Company LLC',
    value: [{ value: 'Paulson Investment Company LLC', key: 'firm_name' }],
  },
  {
    label: 'E2I Advisors, LLC',
    value: [{ value: 'E2I Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Shuttleworth & Company',
    value: [{ value: 'Shuttleworth & Company', key: 'firm_name' }],
  },
  {
    label: 'Chanos & Company L.P.',
    value: [{ value: 'Chanos & Company L.P.', key: 'firm_name' }],
  },
  {
    label: 'First Pacific Financial, Inc.',
    value: [{ value: 'First Pacific Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Palmer Square Capital Management LLC',
    value: [{ value: 'Palmer Square Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Frontier Asset Management, LLC',
    value: [{ value: 'Frontier Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Avasar Asset Management',
    value: [{ value: 'Avasar Asset Management', key: 'firm_name' }],
  },
  {
    label: 'ValMark Advisers, Inc.',
    value: [{ value: 'ValMark Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'NPA Asset Management, LLC',
    value: [{ value: 'NPA Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Virtuoso Capital Management',
    value: [{ value: 'Virtuoso Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Wealth Engagement',
    value: [{ value: 'Wealth Engagement', key: 'firm_name' }],
  },
  {
    label: 'Optura Advisors, Inc.',
    value: [{ value: 'Optura Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Archer Investment Corporation',
    value: [{ value: 'Archer Investment Corporation', key: 'firm_name' }],
  },
  {
    label: 'Divergent Wealth Advisors, LLC',
    value: [{ value: 'Divergent Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Commonwealth Financial Planners Inc',
    value: [{ value: 'Commonwealth Financial Planners Inc', key: 'firm_name' }],
  },
  {
    label: 'Diamond Equity Advisors',
    value: [{ value: 'Diamond Equity Advisors', key: 'firm_name' }],
  },
  {
    label: 'North Point Portfolio Managers Corporation',
    value: [{ value: 'North Point Portfolio Managers Corporation', key: 'firm_name' }],
  },
  {
    label: 'Paraklete Financial, Inc.',
    value: [{ value: 'Paraklete Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Redwood Wealth Management, LLC',
    value: [{ value: 'Redwood Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'ClearView Investments',
    value: [{ value: 'ClearView Investments', key: 'firm_name' }],
  },
  {
    label: 'Preisz Financial',
    value: [{ value: 'Preisz Financial', key: 'firm_name' }],
  },
  {
    label: 'Elevation Advisory Partners LLC',
    value: [{ value: 'Elevation Advisory Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'L.M. Kohn & Company',
    value: [{ value: 'L.M. Kohn & Company', key: 'firm_name' }],
  },
  {
    label: 'River Canyon Fund Management LLC',
    value: [{ value: 'River Canyon Fund Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Canyon Partners Real Estate LLC',
    value: [{ value: 'Canyon Partners Real Estate LLC', key: 'firm_name' }],
  },
  {
    label: 'Valley Forge Investment Consultants, Inc.',
    value: [{ value: 'Valley Forge Investment Consultants, Inc.', key: 'firm_name' }],
  },
  {
    label: 'React Investment Solutions, LLC',
    value: [{ value: 'React Investment Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Resources Investment Advisors',
    value: [{ value: 'Resources Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Feltl & Company',
    value: [{ value: 'Feltl & Company', key: 'firm_name' }],
  },
  {
    label: 'Jensen Investment Management Inc.',
    value: [{ value: 'Jensen Investment Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Toro Bravo Investment Advisors, LLC',
    value: [{ value: 'Toro Bravo Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Peterson Investment Management Inc',
    value: [{ value: 'Peterson Investment Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Chicago Capital Management Advisors, LLC',
    value: [{ value: 'Chicago Capital Management Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Arbor Capital Management Corp',
    value: [{ value: 'Arbor Capital Management Corp', key: 'firm_name' }],
  },
  {
    label: 'Natural Investments',
    value: [{ value: 'Natural Investments', key: 'firm_name' }],
  },
  {
    label: 'Dock Street Asset Management Inc',
    value: [{ value: 'Dock Street Asset Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Brand Asset Management Group, Inc.',
    value: [{ value: 'Brand Asset Management Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Leach, Bickmore & Weiss Wealth Management, LLC',
    value: [{ value: 'Leach, Bickmore & Weiss Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Investment Management Associates',
    value: [{ value: 'Investment Management Associates', key: 'firm_name' }],
  },
  {
    label: 'The Patriot Financial Group, LLC',
    value: [{ value: 'The Patriot Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hazlett, Burt & Watson, Inc.',
    value: [{ value: 'Hazlett, Burt & Watson, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Csenge Advisory Group, LLC',
    value: [{ value: 'Csenge Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Securities America, Inc.',
    value: [{ value: 'Securities America, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Northwest Asset Management',
    value: [{ value: 'Northwest Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Morling Financial Advisors, LLC',
    value: [{ value: 'Morling Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fit Financial Consulting LLC',
    value: [{ value: 'Fit Financial Consulting LLC', key: 'firm_name' }],
  },
  {
    label: 'SPG Advisors LLC',
    value: [{ value: 'SPG Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Venn Wealth & Benefit Services, LLC',
    value: [{ value: 'Venn Wealth & Benefit Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Karner Blue Capital, LLC',
    value: [{ value: 'Karner Blue Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Greene Wealth Management, LLC',
    value: [{ value: 'Greene Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Thoroughbred Financial Services, LLC',
    value: [{ value: 'Thoroughbred Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kassouf Wealth Advisors, LLC',
    value: [{ value: 'Kassouf Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advantage Investment Advisors, LLC',
    value: [{ value: 'Advantage Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Allspring Funds Distributor, LLC',
    value: [{ value: 'Allspring Funds Distributor, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heritage Financial LLC',
    value: [{ value: 'Heritage Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Quinn Financial Planning, LLC',
    value: [{ value: 'Quinn Financial Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cobblestone Capital Advisors, LLC',
    value: [{ value: 'Cobblestone Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CoreCap Investments, LLC',
    value: [{ value: 'CoreCap Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Regatta Capital Group, LLC',
    value: [{ value: 'Regatta Capital Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Entoro Investments, LLC',
    value: [{ value: 'Entoro Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Multnomah Group, Inc.',
    value: [{ value: 'Multnomah Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cantor Fitzgerald & Co.',
    value: [{ value: 'Cantor Fitzgerald & Co.', key: 'firm_name' }],
  },
  {
    label: 'Hamilton Advisors Inc',
    value: [{ value: 'Hamilton Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'MinichMacGregor Wealth Management, LLC',
    value: [{ value: 'MinichMacGregor Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pacific Global Investment Management Co',
    value: [{ value: 'Pacific Global Investment Management Co', key: 'firm_name' }],
  },
  {
    label: 'HR Investment Consultants, Inc.',
    value: [{ value: 'HR Investment Consultants, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Variable Annuity Life Insurance Company',
    value: [{ value: 'The Variable Annuity Life Insurance Company', key: 'firm_name' }],
  },
  {
    label: 'Trustmont Advisory Group, Inc.',
    value: [{ value: 'Trustmont Advisory Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Pickens Asset Management, LLC',
    value: [{ value: 'Pickens Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Morgan Consulting, Inc.',
    value: [{ value: 'Morgan Consulting, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Southwestern Investment Advisory Services, Inc.',
    value: [{ value: 'Southwestern Investment Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lifeworks Advisors, LLC',
    value: [{ value: 'Lifeworks Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sunburst Financial Group, LLC',
    value: [{ value: 'Sunburst Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'CIGX, LLC',
    value: [{ value: 'CIGX, LLC', key: 'firm_name' }],
  },
  {
    label: 'Quattro Advisors L.L.C.',
    value: [{ value: 'Quattro Advisors L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Apple & Associates',
    value: [{ value: 'Apple & Associates', key: 'firm_name' }],
  },
  {
    label: 'Mesirow Financial, Inc.',
    value: [{ value: 'Mesirow Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ARS Investment Partners, LLC',
    value: [{ value: 'ARS Investment Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Harvest Volatility Management, LLC',
    value: [{ value: 'Harvest Volatility Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Thompson Wealth Advisors',
    value: [{ value: 'Thompson Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Portfolio Wealth Advisors',
    value: [{ value: 'Portfolio Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Integrity Investments, Inc.',
    value: [{ value: 'Integrity Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bessemer Investor Services, Inc.',
    value: [{ value: 'Bessemer Investor Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Juncture Wealth Strategies, LLC',
    value: [{ value: 'Juncture Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Capital Corporation',
    value: [{ value: 'Lincoln Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Camelot Capital Management Ltd',
    value: [{ value: 'Camelot Capital Management Ltd', key: 'firm_name' }],
  },
  {
    label: 'Lisanti Capital Growth LLC',
    value: [{ value: 'Lisanti Capital Growth LLC', key: 'firm_name' }],
  },
  {
    label: 'Federated Securities Corp.',
    value: [{ value: 'Federated Securities Corp.', key: 'firm_name' }],
  },
  {
    label: 'Versant Venture Management, LLC',
    value: [{ value: 'Versant Venture Management, LLC', key: 'firm_name' }],
  },
  //   {
  // label:'Donnell Financial Services, LLC",
  // value:[{value:'Donnell Financial Services, LLC",key:'firm_name'}]
  //   },
  {
    label: 'Whitney & Company',
    value: [{ value: 'Whitney & Company', key: 'firm_name' }],
  },
  {
    label: 'Amherst Pierpont',
    value: [{ value: 'Amherst Pierpont', key: 'firm_name' }],
  },
  {
    label: 'Anderson Leneave & Co.',
    value: [{ value: 'Anderson Leneave & Co.', key: 'firm_name' }],
  },
  {
    label: 'Northland Securities, Inc.',
    value: [{ value: 'Northland Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brandon Financial Planning Inc.',
    value: [{ value: 'Brandon Financial Planning Inc.', key: 'firm_name' }],
  },
  {
    label: 'Immaculate Wealth Management, LLC',
    value: [{ value: 'Immaculate Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'PineBridge Securities LLC',
    value: [{ value: 'PineBridge Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'NWK Group',
    value: [{ value: 'NWK Group', key: 'firm_name' }],
  },
  {
    label: 'IMPACTfolio LLC',
    value: [{ value: 'IMPACTfolio LLC', key: 'firm_name' }],
  },
  {
    label: 'Investment Distributors, Inc.',
    value: [{ value: 'Investment Distributors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Minerva Planning Group',
    value: [{ value: 'Minerva Planning Group', key: 'firm_name' }],
  },
  {
    label: 'AOG Wealth Management',
    value: [{ value: 'AOG Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Titan Investment Management LLC',
    value: [{ value: 'Titan Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Solera Asset Managers, LLC',
    value: [{ value: 'Solera Asset Managers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Regal Securities, Inc.',
    value: [{ value: 'Regal Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Rikoon Group, LLC',
    value: [{ value: 'The Rikoon Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cordus Wealth Advisors LLC',
    value: [{ value: 'Cordus Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Asage Financial LLC',
    value: [{ value: 'Asage Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Polaris Investment Partners, Inc',
    value: [{ value: 'Polaris Investment Partners, Inc', key: 'firm_name' }],
  },
  {
    label: 'CorPay Retirement Services',
    value: [{ value: 'CorPay Retirement Services', key: 'firm_name' }],
  },
  {
    label: 'Silver Pine Capital, LLC',
    value: [{ value: 'Silver Pine Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mark Scordato & Associates',
    value: [{ value: 'Mark Scordato & Associates', key: 'firm_name' }],
  },
  {
    label: 'Ranch Capital Advisors Inc.',
    value: [{ value: 'Ranch Capital Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'Arive Capital Markets',
    value: [{ value: 'Arive Capital Markets', key: 'firm_name' }],
  },
  {
    label: 'Prosper Point LLC',
    value: [{ value: 'Prosper Point LLC', key: 'firm_name' }],
  },
  {
    label: 'StratoBridge Asset Management, LP',
    value: [{ value: 'StratoBridge Asset Management, LP', key: 'firm_name' }],
  },
  {
    label: 'Miller Equity Capital Advisors',
    value: [{ value: 'Miller Equity Capital Advisors', key: 'firm_name' }],
  },
  {
    label: 'ProActive Advisors, LLC',
    value: [{ value: 'ProActive Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cornerstone Advisors',
    value: [{ value: 'Cornerstone Advisors', key: 'firm_name' }],
  },
  {
    label: 'Maryland Financial Group, Inc.',
    value: [{ value: 'Maryland Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Maranatha MGT Corp',
    value: [{ value: 'Maranatha MGT Corp', key: 'firm_name' }],
  },
  {
    label: 'Trinity Asset Management, Inc.',
    value: [{ value: 'Trinity Asset Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Miller, Gesko & Company Inc.',
    value: [{ value: 'Miller, Gesko & Company Inc.', key: 'firm_name' }],
  },
  {
    label: 'Palisade Investments, LLC',
    value: [{ value: 'Palisade Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'IHT Wealth Management LLC',
    value: [{ value: 'IHT Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Cratero Capital LLC',
    value: [{ value: 'Cratero Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Profit Investments',
    value: [{ value: 'Profit Investments', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Wealth Planning Services, Inc.',
    value: [{ value: 'Pinnacle Wealth Planning Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Calton & Associates, Inc.',
    value: [{ value: 'Calton & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Shelter Rock Management, LLC',
    value: [{ value: 'Shelter Rock Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Purshe Kaplan Sterling Investments',
    value: [{ value: 'Purshe Kaplan Sterling Investments', key: 'firm_name' }],
  },
  {
    label: 'Island Financial Advisors',
    value: [{ value: 'Island Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Summit Financial Advisors, LLC',
    value: [{ value: 'Summit Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mosaic Pacific Investment Advisors',
    value: [{ value: 'Mosaic Pacific Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Prospect Financial Services LLC',
    value: [{ value: 'Prospect Financial Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Cardiff Park Advisors',
    value: [{ value: 'Cardiff Park Advisors', key: 'firm_name' }],
  },
  {
    label: 'Altfest Personal Wealth Management',
    value: [{ value: 'Altfest Personal Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Formidable Asset Management, LLC',
    value: [{ value: 'Formidable Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bigelow Investment Advisors, LLC',
    value: [{ value: 'Bigelow Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'ChangePath, LLC',
    value: [{ value: 'ChangePath, LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Financial Group Inc.',
    value: [{ value: 'Capital Financial Group Inc.', key: 'firm_name' }],
  },
  {
    label: 'Archetype Wealth Partners, LLC',
    value: [{ value: 'Archetype Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hewitt Lane, LLC',
    value: [{ value: 'Hewitt Lane, LLC', key: 'firm_name' }],
  },
  {
    label: 'MSF Investment Advisory LLC.',
    value: [{ value: 'MSF Investment Advisory LLC.', key: 'firm_name' }],
  },
  {
    label: 'Twenty Fifty Capital',
    value: [{ value: 'Twenty Fifty Capital', key: 'firm_name' }],
  },
  {
    label: 'Jamestown, L.P.',
    value: [{ value: 'Jamestown, L.P.', key: 'firm_name' }],
  },
  //   {
  // label:'s Vineyard Investment Advisors, LLC",
  // value:[{value:'s Vineyard Investment Advisors, LLC",key:'firm_name'}]
  //   },
  {
    label: 'Eversept Partners, L.P.',
    value: [{ value: 'Eversept Partners, L.P.', key: 'firm_name' }],
  },
  {
    label: 'RSM US Wealth Management LLC',
    value: [{ value: 'RSM US Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Pure Portfolios',
    value: [{ value: 'Pure Portfolios', key: 'firm_name' }],
  },
  {
    label: 'Spinosa Wealth Management Group',
    value: [{ value: 'Spinosa Wealth Management Group', key: 'firm_name' }],
  },
  {
    label: 'Seneca Financial Advisors LLC',
    value: [{ value: 'Seneca Financial Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Soundmark Wealth Management, LLC',
    value: [{ value: 'Soundmark Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ascribe Capital LLC',
    value: [{ value: 'Ascribe Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Ascribe Management LLC',
    value: [{ value: 'Ascribe Management LLC', key: 'firm_name' }],
  },
  {
    label: 'ARIA Wealth Management',
    value: [{ value: 'ARIA Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Complete Advisors',
    value: [{ value: 'Complete Advisors', key: 'firm_name' }],
  },
  {
    label: 'SMP Asset Management, LLC',
    value: [{ value: 'SMP Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brisbois Capital Management LLC',
    value: [{ value: 'Brisbois Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Della Porta Financial Advisors',
    value: [{ value: 'Della Porta Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Southeast Investments, N.C., Inc.',
    value: [{ value: 'Southeast Investments, N.C., Inc.', key: 'firm_name' }],
  },
  {
    label: 'AAM Fee-Only Financial Planning and Investment Advisory, LLC',
    value: [{ value: 'AAM Fee-Only Financial Planning and Investment Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Trident Partners Ltd.',
    value: [{ value: 'Trident Partners Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Willow Planning Group, LLC',
    value: [{ value: 'Willow Planning Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Equinum, LLC',
    value: [{ value: 'Equinum, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hawkes Wealth Management Inc.',
    value: [{ value: 'Hawkes Wealth Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Rivo Alto Capital Funding LLC',
    value: [{ value: 'Rivo Alto Capital Funding LLC', key: 'firm_name' }],
  },
  {
    label: 'Lifecare Funding Solutions, LLC',
    value: [{ value: 'Lifecare Funding Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Draco Capital Partners, LLC',
    value: [{ value: 'Draco Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Global Trust',
    value: [{ value: 'Global Trust', key: 'firm_name' }],
  },
  {
    label: 'Apella Capital',
    value: [{ value: 'Apella Capital', key: 'firm_name' }],
  },
  {
    label: 'Ashwood Advisors',
    value: [{ value: 'Ashwood Advisors', key: 'firm_name' }],
  },
  {
    label: 'BH Asset Management, LLC',
    value: [{ value: 'BH Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'SJS Investment Services',
    value: [{ value: 'SJS Investment Services', key: 'firm_name' }],
  },
  {
    label: 'Redhawk Wealth Advisors, Inc.',
    value: [{ value: 'Redhawk Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Associated Retirement Planners, Inc.',
    value: [{ value: 'Associated Retirement Planners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Lesko Securities Inc.',
    value: [{ value: 'Lesko Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'Private Portfolio Partners, LLC',
    value: [{ value: 'Private Portfolio Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ancora Family Wealth Advisors, LLC',
    value: [{ value: 'Ancora Family Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Reaves Asset Management',
    value: [{ value: 'Reaves Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Everett Harris & Co',
    value: [{ value: 'Everett Harris & Co', key: 'firm_name' }],
  },
  {
    label: 'Lebenthal Diversified Asset Management Inc.',
    value: [{ value: 'Lebenthal Diversified Asset Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Financial Alternatives, Inc.',
    value: [{ value: 'Financial Alternatives, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Congress Wealth Management LLC',
    value: [{ value: 'Congress Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Joseph Gunnar & Co. LLC',
    value: [{ value: 'Joseph Gunnar & Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Maxine Weiner, CFP',
    value: [{ value: 'Maxine Weiner, CFP', key: 'firm_name' }],
  },
  {
    label: 'Financial Connections Group, Inc.',
    value: [{ value: 'Financial Connections Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bigman Consulting Services LLC',
    value: [{ value: 'Bigman Consulting Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Preston Wealth Advisors, LLC.',
    value: [{ value: 'Preston Wealth Advisors, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Sloy, Dahl & Holst, LLC',
    value: [{ value: 'Sloy, Dahl & Holst, LLC', key: 'firm_name' }],
  },
  {
    label: 'Good Life Wealth Management LLC',
    value: [{ value: 'Good Life Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Hoffman Alan N Investment Management',
    value: [{ value: 'Hoffman Alan N Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Blue Summit Wealth Management',
    value: [{ value: 'Blue Summit Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Guardian Financial Partners, LLC',
    value: [{ value: 'Guardian Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Shaker Financial Services',
    value: [{ value: 'Shaker Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Mark Harris Services',
    value: [{ value: 'Mark Harris Services', key: 'firm_name' }],
  },
  {
    label: 'Earth Equity Advisors, LLC',
    value: [{ value: 'Earth Equity Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alexa Wealth Management, LLC',
    value: [{ value: 'Alexa Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advantage Wealth Advisors',
    value: [{ value: 'Advantage Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Allium Financial Advisors, LLC',
    value: [{ value: 'Allium Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lighthaven Capital Management LLC',
    value: [{ value: 'Lighthaven Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Nikko Asset Management Americas, Inc.',
    value: [{ value: 'Nikko Asset Management Americas, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ETF Model Solutions, LLC',
    value: [{ value: 'ETF Model Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Summit Group Retirement Planners, Inc.',
    value: [{ value: 'Summit Group Retirement Planners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'B. C. Ziegler and Company',
    value: [{ value: 'B. C. Ziegler and Company', key: 'firm_name' }],
  },
  {
    label: 'ETF Portfolio Partners, Inc.',
    value: [{ value: 'ETF Portfolio Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Thematic Investments, LLC',
    value: [{ value: 'Thematic Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nolan Capital Advisors LLC',
    value: [{ value: 'Nolan Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'OneAscent Investment Solutions, LLC',
    value: [{ value: 'OneAscent Investment Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Inlight Wealth',
    value: [{ value: 'Inlight Wealth', key: 'firm_name' }],
  },
  {
    label: 'MPWM Advisory Solutions LLC',
    value: [{ value: 'MPWM Advisory Solutions LLC', key: 'firm_name' }],
  },
  {
    label: 'Pallas Capital Advisors, LLC',
    value: [{ value: 'Pallas Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Silver Oak Securities, Incorporated',
    value: [{ value: 'Silver Oak Securities, Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Foundations Investment Advisors LLC',
    value: [{ value: 'Foundations Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Harper Financial Group',
    value: [{ value: 'Harper Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Provident Capital Group, L.P.',
    value: [{ value: 'Provident Capital Group, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Strong Wealth Management',
    value: [{ value: 'Strong Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'McLean Capital Management',
    value: [{ value: 'McLean Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Coastal Equities, Inc.',
    value: [{ value: 'Coastal Equities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Enhancing Capital LLC',
    value: [{ value: 'Enhancing Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Compass Securities Corporation',
    value: [{ value: 'Compass Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'TRUE Private Wealth Advisors, LLC',
    value: [{ value: 'TRUE Private Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stephens',
    value: [{ value: 'Stephens', key: 'firm_name' }],
  },
  {
    label: 'Blair Hall Advisors, LLC',
    value: [{ value: 'Blair Hall Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'MMC Securities LLC',
    value: [{ value: 'MMC Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Luther King Capital Management Corporation',
    value: [{ value: 'Luther King Capital Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'The Burney Company',
    value: [{ value: 'The Burney Company', key: 'firm_name' }],
  },
  {
    label: 'Manning & Napier Advisors, LLC',
    value: [{ value: 'Manning & Napier Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Holbrook Global Strategies',
    value: [{ value: 'Holbrook Global Strategies', key: 'firm_name' }],
  },
  {
    label: 'Barnes Pettey Financial Advisors, LLC',
    value: [{ value: 'Barnes Pettey Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'American Retirement & Investment Strategies, LLC',
    value: [{ value: 'American Retirement & Investment Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Private Wealth Solutions, LLC',
    value: [{ value: 'Private Wealth Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'L. K. Benson & Company, P.C.',
    value: [{ value: 'L. K. Benson & Company, P.C.', key: 'firm_name' }],
  },
  {
    label: 'Shiraishi Financial Group Advisors',
    value: [{ value: 'Shiraishi Financial Group Advisors', key: 'firm_name' }],
  },
  {
    label: 'Forward Wealth Management, LLC',
    value: [{ value: 'Forward Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kingsbrook Partners LP',
    value: [{ value: 'Kingsbrook Partners LP', key: 'firm_name' }],
  },
  {
    label: 'NTB Financial Corporation',
    value: [{ value: 'NTB Financial Corporation', key: 'firm_name' }],
  },
  {
    label: 'ProVise Management Group, LLC',
    value: [{ value: 'ProVise Management Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Rescue Strategies, Inc.',
    value: [{ value: 'Wealth Rescue Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mission Hill Advisors, LLC',
    value: [{ value: 'Mission Hill Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bray Capital Group LLC',
    value: [{ value: 'Bray Capital Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Protection Point Advisors',
    value: [{ value: 'Protection Point Advisors', key: 'firm_name' }],
  },
  {
    label: 'Horizon Technology Finance',
    value: [{ value: 'Horizon Technology Finance', key: 'firm_name' }],
  },
  {
    label: 'BLVD Private Wealth, LLC',
    value: [{ value: 'BLVD Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Parish & Company LLC',
    value: [{ value: 'Parish & Company LLC', key: 'firm_name' }],
  },
  {
    label: 'Tempus Wealth Planning, LLC',
    value: [{ value: 'Tempus Wealth Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Exome Asset Management LLC',
    value: [{ value: 'Exome Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Waterford Advisors, LLC',
    value: [{ value: 'Waterford Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Zimmerman Wealth Management, LLC',
    value: [{ value: 'Zimmerman Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Emerson Equity LLC',
    value: [{ value: 'Emerson Equity LLC', key: 'firm_name' }],
  },
  {
    label: 'Rochester Investments',
    value: [{ value: 'Rochester Investments', key: 'firm_name' }],
  },
  {
    label: 'Coastal Bridge Advisors',
    value: [{ value: 'Coastal Bridge Advisors', key: 'firm_name' }],
  },
  {
    label: 'Global Wealth Strategies and Associates, LLC',
    value: [{ value: 'Global Wealth Strategies and Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Planning For Generations, Inc.',
    value: [{ value: 'Financial Planning For Generations, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Clement Asset Management, LLC',
    value: [{ value: 'Clement Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Doyle Wealth Management, LLC',
    value: [{ value: 'Doyle Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fiduciary Counselling Inc',
    value: [{ value: 'Fiduciary Counselling Inc', key: 'firm_name' }],
  },
  {
    label: 'Epic Trust Investment Advisors LLC',
    value: [{ value: 'Epic Trust Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'RJ Capital LLC',
    value: [{ value: 'RJ Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'H & S Financial Advisors Inc.',
    value: [{ value: 'H & S Financial Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'Libretto',
    value: [{ value: 'Libretto', key: 'firm_name' }],
  },
  {
    label: 'East West Investment Advisors, LLC',
    value: [{ value: 'East West Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vivaldi Capital Management LP',
    value: [{ value: 'Vivaldi Capital Management LP', key: 'firm_name' }],
  },
  {
    label: 'Aequitas Investment Advisors, LLC',
    value: [{ value: 'Aequitas Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Clarus Wealth Group',
    value: [{ value: 'Clarus Wealth Group', key: 'firm_name' }],
  },
  {
    label: 'Convergence Financial',
    value: [{ value: 'Convergence Financial', key: 'firm_name' }],
  },
  {
    label: 'Ok401k, Inc.',
    value: [{ value: 'Ok401k, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mendon Capital Advisors Corp',
    value: [{ value: 'Mendon Capital Advisors Corp', key: 'firm_name' }],
  },
  {
    label: 'AXIO Financial LLC',
    value: [{ value: 'AXIO Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Bradley Foster & Sargent Inc',
    value: [{ value: 'Bradley Foster & Sargent Inc', key: 'firm_name' }],
  },
  {
    label: 'Blueprint Wealth Management',
    value: [{ value: 'Blueprint Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'HoyleCohen, LLC',
    value: [{ value: 'HoyleCohen, LLC', key: 'firm_name' }],
  },
  {
    label: 'John Hancock Personal Financial Services, LLC',
    value: [{ value: 'John Hancock Personal Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mersereau, Lazenby & Rockas, L.L.C.',
    value: [{ value: 'Mersereau, Lazenby & Rockas, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'AdvisorShares',
    value: [{ value: 'AdvisorShares', key: 'firm_name' }],
  },
  {
    label: 'Mulholland Wealth Advisors, LLC',
    value: [{ value: 'Mulholland Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Oliver Luxxe Assets LLC',
    value: [{ value: 'Oliver Luxxe Assets LLC', key: 'firm_name' }],
  },
  {
    label: 'Great Lakes Benefits and Wealth Management',
    value: [{ value: 'Great Lakes Benefits and Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Professional Financial Management, Inc.',
    value: [{ value: 'Professional Financial Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Donald H. Freimark CFA, LLC',
    value: [{ value: 'Donald H. Freimark CFA, LLC', key: 'firm_name' }],
  },
  {
    label: 'Open Wealth Network',
    value: [{ value: 'Open Wealth Network', key: 'firm_name' }],
  },
  {
    label: 'Wetherby Asset Management',
    value: [{ value: 'Wetherby Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Blacksheep Advisor',
    value: [{ value: 'Blacksheep Advisor', key: 'firm_name' }],
  },
  {
    label: 'Oregon Pacific Financial Advisors Inc',
    value: [{ value: 'Oregon Pacific Financial Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Gridley Associates Inc',
    value: [{ value: 'Gridley Associates Inc', key: 'firm_name' }],
  },
  {
    label: 'The Putney Financial Group, Registered Investment Advisors',
    value: [{ value: 'The Putney Financial Group, Registered Investment Advisors', key: 'firm_name' }],
  },
  {
    label: 'Medallion Wealth Advisors, LLC',
    value: [{ value: 'Medallion Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sojourn Financial Strategies, LLC',
    value: [{ value: 'Sojourn Financial Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'RPR Financial',
    value: [{ value: 'RPR Financial', key: 'firm_name' }],
  },
  {
    label: 'Retirement Strategies, Ltd.',
    value: [{ value: 'Retirement Strategies, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Aveo Capital Partners, LLC',
    value: [{ value: 'Aveo Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wedge Capital Management LLP',
    value: [{ value: 'Wedge Capital Management LLP', key: 'firm_name' }],
  },
  {
    label: 'First Financial Advisors',
    value: [{ value: 'First Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'PK Financial Strategies, LLC',
    value: [{ value: 'PK Financial Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'NFC Investments, LLC',
    value: [{ value: 'NFC Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hayek Kallen Investment Management L.L.C.',
    value: [{ value: 'Hayek Kallen Investment Management L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Omnia Family Wealth, LLC',
    value: [{ value: 'Omnia Family Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'NorthPointe Financial, LLC',
    value: [{ value: 'NorthPointe Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Loomis, Sayles & Company, L.P.',
    value: [{ value: 'Loomis, Sayles & Company, L.P.', key: 'firm_name' }],
  },
  {
    label: 'Frontier Financial Partners, LLC',
    value: [{ value: 'Frontier Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lightpath Wealth & Tax Advisors',
    value: [{ value: 'Lightpath Wealth & Tax Advisors', key: 'firm_name' }],
  },
  {
    label: 'Hawthorne Financial Advisors, LLC',
    value: [{ value: 'Hawthorne Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'United Brokerage Services, Inc',
    value: [{ value: 'United Brokerage Services, Inc', key: 'firm_name' }],
  },
  {
    label: 'Koss-Olinger Consulting, LLC.',
    value: [{ value: 'Koss-Olinger Consulting, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Smith & Wyatt',
    value: [{ value: 'Smith & Wyatt', key: 'firm_name' }],
  },
  {
    label: 'James River Asset Management, LLC',
    value: [{ value: 'James River Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'WrapManager Inc',
    value: [{ value: 'WrapManager Inc', key: 'firm_name' }],
  },
  {
    label: 'Capital Advisory Group',
    value: [{ value: 'Capital Advisory Group', key: 'firm_name' }],
  },
  {
    label: 'First American Asset Advisory, LLC',
    value: [{ value: 'First American Asset Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Frank, Rimerman Advisors LLC',
    value: [{ value: 'Frank, Rimerman Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Fnancial Depot',
    value: [{ value: 'Fnancial Depot', key: 'firm_name' }],
  },
  {
    label: 'FMSbonds, Inc.',
    value: [{ value: 'FMSbonds, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Graham Capital Wealth Management, LLC',
    value: [{ value: 'Graham Capital Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Institutional Investment Advisors Corp',
    value: [{ value: 'Institutional Investment Advisors Corp', key: 'firm_name' }],
  },
  {
    label: 'Putnam Retail Management Limited Partnership',
    value: [{ value: 'Putnam Retail Management Limited Partnership', key: 'firm_name' }],
  },
  {
    label: 'McKeel Capital Management',
    value: [{ value: 'McKeel Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Future U Financial Inc.',
    value: [{ value: 'Future U Financial Inc.', key: 'firm_name' }],
  },
  {
    label: 'Annex Wealth Management, LLC',
    value: [{ value: 'Annex Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Portsmouth - Smartlife Financial Group, LLC',
    value: [{ value: 'Portsmouth - Smartlife Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Waterloo Capital',
    value: [{ value: 'Waterloo Capital', key: 'firm_name' }],
  },
  {
    label: 'Valley Brook Capital Group',
    value: [{ value: 'Valley Brook Capital Group', key: 'firm_name' }],
  },
  {
    label: 'Overbrook Management Corporation',
    value: [{ value: 'Overbrook Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'Absolute Capital Management, LLC',
    value: [{ value: 'Absolute Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'L & S Advisors, Inc',
    value: [{ value: 'L & S Advisors, Inc', key: 'firm_name' }],
  },
  {
    label: 'Oppenheimer & Co. Inc.',
    value: [{ value: 'Oppenheimer & Co. Inc.', key: 'firm_name' }],
  },
  {
    label: 'American Asset Management Inc.',
    value: [{ value: 'American Asset Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wells Fargo Clearing Services, LLC',
    value: [{ value: 'Wells Fargo Clearing Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ameriprise Financial Services, LLC',
    value: [{ value: 'Ameriprise Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hourglass Capital, LLC',
    value: [{ value: 'Hourglass Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Merrill Lynch, Pierce, Fenner & Smith Incorporated',
    value: [{ value: 'Merrill Lynch, Pierce, Fenner & Smith Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Stifel, Nicolaus & Company, Incorporated',
    value: [{ value: 'Stifel, Nicolaus & Company, Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Hornor, Townsend & Kent, LLC',
    value: [{ value: 'Hornor, Townsend & Kent, LLC', key: 'firm_name' }],
  },
  {
    label: 'WorthPointe',
    value: [{ value: 'WorthPointe', key: 'firm_name' }],
  },
  {
    label: 'Morgan Stanley',
    value: [{ value: 'Morgan Stanley', key: 'firm_name' }],
  },
  {
    label: 'Steward Partners Investment Advisory, LLC',
    value: [{ value: 'Steward Partners Investment Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'RBC Capital Markets, LLC',
    value: [{ value: 'RBC Capital Markets, LLC', key: 'firm_name' }],
  },
  {
    label: 'Beacon Wealth Consultants, Inc.',
    value: [{ value: 'Beacon Wealth Consultants, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bucher Wealth Advisors',
    value: [{ value: 'Bucher Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Davenport & Company LLC',
    value: [{ value: 'Davenport & Company LLC', key: 'firm_name' }],
  },
  {
    label: 'Purus Wealth Management, LLC',
    value: [{ value: 'Purus Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Florin Group, LLC',
    value: [{ value: 'Florin Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Affinity Wealth Management LLC',
    value: [{ value: 'Affinity Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'J.P. Morgan Institutional Investments Inc.',
    value: [{ value: 'J.P. Morgan Institutional Investments Inc.', key: 'firm_name' }],
  },
  {
    label: 'New Age Wealth Advisors LLC',
    value: [{ value: 'New Age Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Beacon Pointe Advisors, LLC',
    value: [{ value: 'Beacon Pointe Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Thrivent Investment Management Inc.',
    value: [{ value: 'Thrivent Investment Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'Equitable Advisors, LLC',
    value: [{ value: 'Equitable Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'PGIM, Inc.',
    value: [{ value: 'PGIM, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Modera Wealth Management, LLC',
    value: [{ value: 'Modera Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hackett Robertson Tobe Group',
    value: [{ value: 'Hackett Robertson Tobe Group', key: 'firm_name' }],
  },
  {
    label: 'WealthPLAN Partners',
    value: [{ value: 'WealthPLAN Partners', key: 'firm_name' }],
  },
  {
    label: 'Cohen & Steers Securities, LLC',
    value: [{ value: 'Cohen & Steers Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Hendrickson Advisory Group, Inc.',
    value: [{ value: 'The Hendrickson Advisory Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'The Martin Worley Group',
    value: [{ value: 'The Martin Worley Group', key: 'firm_name' }],
  },
  {
    label: 'Variant Investments',
    value: [{ value: 'Variant Investments', key: 'firm_name' }],
  },
  {
    label: 'Raymond James & Associates, Inc.',
    value: [{ value: 'Raymond James & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'J.Derek Lewis & Associates, Inc.',
    value: [{ value: 'J.Derek Lewis & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Compass Point Retirement Planning, Inc.',
    value: [{ value: 'Compass Point Retirement Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WWM Financial',
    value: [{ value: 'WWM Financial', key: 'firm_name' }],
  },
  {
    label: 'Cross Border Wealth, LLC',
    value: [{ value: 'Cross Border Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'EquityCompass Investment Management, LLC',
    value: [{ value: 'EquityCompass Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lighthouse Capital, LLC',
    value: [{ value: 'Lighthouse Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hoff Private Wealth',
    value: [{ value: 'Hoff Private Wealth', key: 'firm_name' }],
  },
  {
    label: 'Northwestern Mutual Investment Services, LLC',
    value: [{ value: 'Northwestern Mutual Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Focus On Success, LLC',
    value: [{ value: 'Focus On Success, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vanderbilt Securities, LLC',
    value: [{ value: 'Vanderbilt Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Total Wealth Advisors, LLC',
    value: [{ value: 'Total Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Prime Capital Investment Advisors, LLC',
    value: [{ value: 'Prime Capital Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CWA Asset Management Group',
    value: [{ value: 'CWA Asset Management Group', key: 'firm_name' }],
  },
  {
    label: 'TD Ameritrade Investment Management, LLC',
    value: [{ value: 'TD Ameritrade Investment Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Executive Financial Services, Inc.',
    value: [{ value: 'Executive Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'GRANTvest Financial Group LLC',
    value: [{ value: 'GRANTvest Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'SagePoint Financial, Inc.',
    value: [{ value: 'SagePoint Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Meridian Wealth Management, Inc',
    value: [{ value: 'Meridian Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Monograph Wealth Advisors, LLC',
    value: [{ value: 'Monograph Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Citigroup Global Markets Inc.',
    value: [{ value: 'Citigroup Global Markets Inc.', key: 'firm_name' }],
  },
  {
    label: 'PHX Financial, Inc.',
    value: [{ value: 'PHX Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PGIM Investments LLC',
    value: [{ value: 'PGIM Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Paragon Financial Advisors',
    value: [{ value: 'Paragon Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'NWF Advisory Services Inc',
    value: [{ value: 'NWF Advisory Services Inc', key: 'firm_name' }],
  },
  {
    label: 'Markowski Investments',
    value: [{ value: 'Markowski Investments', key: 'firm_name' }],
  },
  {
    label: 'Sizemore Capital Management, LLC',
    value: [{ value: 'Sizemore Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Savant Wealth Management',
    value: [{ value: 'Savant Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Barnabas Capital, LLC',
    value: [{ value: 'Barnabas Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Integrated Wealth Strategies, LLC',
    value: [{ value: 'Integrated Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Neuberger Berman BD LLC',
    value: [{ value: 'Neuberger Berman BD LLC', key: 'firm_name' }],
  },
  {
    label: 'U.S. Bancorp Investments, Inc.',
    value: [{ value: 'U.S. Bancorp Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Huntleigh Advisors, Inc.',
    value: [{ value: 'Huntleigh Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Argonne Capital Group, LLC',
    value: [{ value: 'Argonne Capital Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cadaret, Grant & Co., Inc.',
    value: [{ value: 'Cadaret, Grant & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Bluprint Financial, LLC',
    value: [{ value: 'Bluprint Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Madison Partners',
    value: [{ value: 'Madison Partners', key: 'firm_name' }],
  },
  {
    label: 'Dutch Asset Corporation',
    value: [{ value: 'Dutch Asset Corporation', key: 'firm_name' }],
  },
  {
    label: 'Pine Valley Investments',
    value: [{ value: 'Pine Valley Investments', key: 'firm_name' }],
  },
  {
    label: 'Benjamin F. Edwards & Company, Inc.',
    value: [{ value: 'Benjamin F. Edwards & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Hill Island Financial LLC',
    value: [{ value: 'Hill Island Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'BCM Advisors, LLC',
    value: [{ value: 'BCM Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Newbridge Financial Services Group, Inc.',
    value: [{ value: 'Newbridge Financial Services Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First Horizon Advisors, Inc.',
    value: [{ value: 'First Horizon Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'McDonald Partners LLC',
    value: [{ value: 'McDonald Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Nicholas Wealth Management',
    value: [{ value: 'Nicholas Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Mathis Wealth Management',
    value: [{ value: 'Mathis Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Commonwealth Financial Network',
    value: [{ value: 'Commonwealth Financial Network', key: 'firm_name' }],
  },
  {
    label: 'Larimer Capital Corporation',
    value: [{ value: 'Larimer Capital Corporation', key: 'firm_name' }],
  },
  {
    label: 'Mariner Wealth Advisors',
    value: [{ value: 'Mariner Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'P.W.B., Inc.',
    value: [{ value: 'P.W.B., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ameritas Advisory Services, LLC',
    value: [{ value: 'Ameritas Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Shepherd Kaplan LLC',
    value: [{ value: 'Shepherd Kaplan LLC', key: 'firm_name' }],
  },
  {
    label: 'Andalusian Wealth Management',
    value: [{ value: 'Andalusian Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Blackridge Asset Management, LLC',
    value: [{ value: 'Blackridge Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Saxony Capital Management, LLC',
    value: [{ value: 'Saxony Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Virtue Capital Management, LLC',
    value: [{ value: 'Virtue Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Integrated Investment Consultants, LLC',
    value: [{ value: 'Integrated Investment Consultants, LLC', key: 'firm_name' }],
  },
  {
    label: 'Level 5 Financial, LLC',
    value: [{ value: 'Level 5 Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Private Wealth, LLC',
    value: [{ value: 'Pinnacle Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'TruNorth Capital Management, LLC',
    value: [{ value: 'TruNorth Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'First Financial Equity Corporation',
    value: [{ value: 'First Financial Equity Corporation', key: 'firm_name' }],
  },
  {
    label: 'Independent Financial Partners',
    value: [{ value: 'Independent Financial Partners', key: 'firm_name' }],
  },
  {
    label: 'Independent Wealth Network, Inc.',
    value: [{ value: 'Independent Wealth Network, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Momentum Advisors',
    value: [{ value: 'Momentum Advisors', key: 'firm_name' }],
  },
  {
    label: 'State Farm VP Management Corp.',
    value: [{ value: 'State Farm VP Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Interactive Financial Advisors',
    value: [{ value: 'Interactive Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Agvictus Capital Management LLC',
    value: [{ value: 'Agvictus Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'AAF Wealth Management, LLC',
    value: [{ value: 'AAF Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Key Wealth Partners, LLC',
    value: [{ value: 'Key Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Oregon Pacific Wealth Management',
    value: [{ value: 'Oregon Pacific Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Veritable LP',
    value: [{ value: 'Veritable LP', key: 'firm_name' }],
  },
  {
    label: 'Horizons Planning Corporation',
    value: [{ value: 'Horizons Planning Corporation', key: 'firm_name' }],
  },
  {
    label: 'Keeler Thomas Management LLC',
    value: [{ value: 'Keeler Thomas Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Lido Advisors',
    value: [{ value: 'Lido Advisors', key: 'firm_name' }],
  },
  {
    label: 'Private Advisor Group, LLC',
    value: [{ value: 'Private Advisor Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Infinity Wealth Counsel, LLC',
    value: [{ value: 'Infinity Wealth Counsel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Builder Investment Group',
    value: [{ value: 'Builder Investment Group', key: 'firm_name' }],
  },
  {
    label: 'RetireWise Tax and Wealth Advisors',
    value: [{ value: 'RetireWise Tax and Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Wealth Advisory Solutions, LLC',
    value: [{ value: 'Wealth Advisory Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Tigress Financial Partners, LLC',
    value: [{ value: 'Tigress Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Covered Bridge Advisors, LLC',
    value: [{ value: 'Covered Bridge Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'GLP Investment Services, LLC',
    value: [{ value: 'GLP Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Strategies Group, Inc',
    value: [{ value: 'Financial Strategies Group, Inc', key: 'firm_name' }],
  },
  {
    label: 'Tapparo Capital Management',
    value: [{ value: 'Tapparo Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Allspring Funds Management, LLC',
    value: [{ value: 'Allspring Funds Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sar Capital Management LLC',
    value: [{ value: 'Sar Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Vanderbilt Advisory Services',
    value: [{ value: 'Vanderbilt Advisory Services', key: 'firm_name' }],
  },
  {
    label: 'J M Brown Financial Partners',
    value: [{ value: 'J M Brown Financial Partners', key: 'firm_name' }],
  },
  {
    label: 'Sentinus, LLC',
    value: [{ value: 'Sentinus, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brahma Management LLC',
    value: [{ value: 'Brahma Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Lincoln Financial Securities Corporation',
    value: [{ value: 'Lincoln Financial Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Atlas Capital Advisors LLC',
    value: [{ value: 'Atlas Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Greenspring Advisors, LLC',
    value: [{ value: 'Greenspring Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Verity Wealth Advisors, LLC',
    value: [{ value: 'Verity Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Black Maple Capital Management LP',
    value: [{ value: 'Black Maple Capital Management LP', key: 'firm_name' }],
  },
  {
    label: 'Carlton, Hofferkamp and Jenks Wealth Management, LLC',
    value: [{ value: 'Carlton, Hofferkamp and Jenks Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Advosion',
    value: [{ value: 'Advosion', key: 'firm_name' }],
  },
  {
    label: 'FamilyWealth',
    value: [{ value: 'FamilyWealth', key: 'firm_name' }],
  },
  {
    label: 'Cito Capital Advisors LLC',
    value: [{ value: 'Cito Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Opes Wealth Management, LLC',
    value: [{ value: 'Opes Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Clearwater Advisors, LLC',
    value: [{ value: 'Clearwater Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Rothman Investment Management',
    value: [{ value: 'Rothman Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Guardian Rock Wealth Investment Management, Inc.',
    value: [{ value: 'Guardian Rock Wealth Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'J.J.F. Investments, Inc.',
    value: [{ value: 'J.J.F. Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Personal Money Planning, LLC',
    value: [{ value: 'Personal Money Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Talley Financial',
    value: [{ value: 'Talley Financial', key: 'firm_name' }],
  },
  {
    label: 'Fountainhead Capital Management, LLC',
    value: [{ value: 'Fountainhead Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Marin Bay Wealth Advisors, LLC',
    value: [{ value: 'Marin Bay Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Iron Gate Global Advisors',
    value: [{ value: 'Iron Gate Global Advisors', key: 'firm_name' }],
  },
  {
    label: 'Invesco Advisers, Inc.',
    value: [{ value: 'Invesco Advisers, Inc.', key: 'firm_name' }],
  },
  {
    label: 'DGS Capital Management',
    value: [{ value: 'DGS Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Netherby Advisors LLC',
    value: [{ value: 'Netherby Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Metropolitan Capital Investment Banc, Inc.',
    value: [{ value: 'Metropolitan Capital Investment Banc, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Harren Equity Partners, LLC',
    value: [{ value: 'Harren Equity Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Winthrop Capital Management',
    value: [{ value: 'Winthrop Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Client One Securities LLC',
    value: [{ value: 'Client One Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Ingalls & Snyder, LLC',
    value: [{ value: 'Ingalls & Snyder, LLC', key: 'firm_name' }],
  },
  {
    label: 'ON Investment Management Co',
    value: [{ value: 'ON Investment Management Co', key: 'firm_name' }],
  },
  {
    label: 'Wacker Wealth Partners, LLC',
    value: [{ value: 'Wacker Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spark Asset Management Group',
    value: [{ value: 'Spark Asset Management Group', key: 'firm_name' }],
  },
  {
    label: 'Investmark Advisory Group LLC',
    value: [{ value: 'Investmark Advisory Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Leonard Rickey Investment Advisors, P.L.L.C.',
    value: [{ value: 'Leonard Rickey Investment Advisors, P.L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Paul Manners & Associates, Inc.',
    value: [{ value: 'Paul Manners & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'NS Capital, LLC',
    value: [{ value: 'NS Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'SageBroadview Wealth Management, LLC',
    value: [{ value: 'SageBroadview Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Townsend Asset Management Corp.',
    value: [{ value: 'Townsend Asset Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'The Financial Team, Inc.',
    value: [{ value: 'The Financial Team, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kiely Wealth Advisory Group, Inc.',
    value: [{ value: 'Kiely Wealth Advisory Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Tennessee Valley Asset Management Partners, LLC',
    value: [{ value: 'Tennessee Valley Asset Management Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sound Income Strategies, LLC',
    value: [{ value: 'Sound Income Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'YH&C Investments Inc.',
    value: [{ value: 'YH&C Investments Inc.', key: 'firm_name' }],
  },
  {
    label: 'Delta Asset Management, LLC',
    value: [{ value: 'Delta Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Elevage Partners, LLC',
    value: [{ value: 'Elevage Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'CoreCap Advisors',
    value: [{ value: 'CoreCap Advisors', key: 'firm_name' }],
  },
  {
    label: 'Mercury Capital Advisors, LLC',
    value: [{ value: 'Mercury Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hanson & Doremus Investment Management',
    value: [{ value: 'Hanson & Doremus Investment Management', key: 'firm_name' }],
  },
  {
    label: 'Ratio Wealth Group LLC',
    value: [{ value: 'Ratio Wealth Group LLC', key: 'firm_name' }],
  },
  {
    label: 'The Advisory Group of San Francisco, LLC',
    value: [{ value: 'The Advisory Group of San Francisco, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Gravity Family Office Services, LLC',
    value: [{ value: 'Financial Gravity Family Office Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'A.G.P / Alliance Global Partners',
    value: [{ value: 'A.G.P / Alliance Global Partners', key: 'firm_name' }],
  },
  {
    label: 'Hilltop Securities Inc.',
    value: [{ value: 'Hilltop Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'Millennium Asset Management LLC',
    value: [{ value: 'Millennium Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Advance Capital Management Inc',
    value: [{ value: 'Advance Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Strategas Research Partners',
    value: [{ value: 'Strategas Research Partners', key: 'firm_name' }],
  },
  {
    label: 'Albion Financial Group',
    value: [{ value: 'Albion Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Cornerstone Wealth Management, LLC',
    value: [{ value: 'Cornerstone Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'WCM Wealth Advisors LLC',
    value: [{ value: 'WCM Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Bonaventure Capital, LLC',
    value: [{ value: 'Bonaventure Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cruz Wealth Management, LLC',
    value: [{ value: 'Cruz Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Goldman Sachs & Co.',
    value: [{ value: 'Goldman Sachs & Co.', key: 'firm_name' }],
  },
  {
    label: 'Three Bell Capital LLC',
    value: [{ value: 'Three Bell Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Raymond James Financial Services, Inc.',
    value: [{ value: 'Raymond James Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kennedy Wealth Management, LLC',
    value: [{ value: 'Kennedy Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stripes, LLC',
    value: [{ value: 'Stripes, LLC', key: 'firm_name' }],
  },
  {
    label: 'Scire Capital Partners, LLC',
    value: [{ value: 'Scire Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Orchard Capital Management, LLC',
    value: [{ value: 'Orchard Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'GW & Wade, LLC',
    value: [{ value: 'GW & Wade, LLC', key: 'firm_name' }],
  },
  {
    label: 'Tone Capital, LLC',
    value: [{ value: 'Tone Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Forte Capital Management, L.L.C.',
    value: [{ value: 'Forte Capital Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'First American Advisory, LLC',
    value: [{ value: 'First American Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'IFP Securities, LLC',
    value: [{ value: 'IFP Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Polen Capital Management, LLC',
    value: [{ value: 'Polen Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Otter Creek Advisors, LLC',
    value: [{ value: 'Otter Creek Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Transcend Capital Advisors, LLC',
    value: [{ value: 'Transcend Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Impact Financial',
    value: [{ value: 'Impact Financial', key: 'firm_name' }],
  },
  {
    label: 'Delta Wealth Management, LLC',
    value: [{ value: 'Delta Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Successful Portfolios LLC',
    value: [{ value: 'Successful Portfolios LLC', key: 'firm_name' }],
  },
  {
    label: 'Pershing LLC',
    value: [{ value: 'Pershing LLC', key: 'firm_name' }],
  },
  {
    label: 'StoneX Financial Inc.',
    value: [{ value: 'StoneX Financial Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fidelity Distributors Company LLC',
    value: [{ value: 'Fidelity Distributors Company LLC', key: 'firm_name' }],
  },
  {
    label: 'Virtu Americas LLC',
    value: [{ value: 'Virtu Americas LLC', key: 'firm_name' }],
  },
  {
    label: 'Fulcrum Equity Partners, Inc.',
    value: [{ value: 'Fulcrum Equity Partners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sageview Advisory Group, LLC',
    value: [{ value: 'Sageview Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sageview Private Client Group, LLC',
    value: [{ value: 'Sageview Private Client Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'SS&C Market Services, LLC',
    value: [{ value: 'SS&C Market Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Johnson Investment Counsel, Inc.',
    value: [{ value: 'Johnson Investment Counsel, Inc.', key: 'firm_name' }],
  },
  {
    label: 'AIRE Advisors, LLC',
    value: [{ value: 'AIRE Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Capital Planning Wealth Management',
    value: [{ value: 'Capital Planning Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Bristlecone Advisors LLC',
    value: [{ value: 'Bristlecone Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Taylor Hoffman Wealth Management, LLC',
    value: [{ value: 'Taylor Hoffman Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Taylor Hoffman Capital Management',
    value: [{ value: 'Taylor Hoffman Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Holmes Investment Management, Inc.',
    value: [{ value: 'Holmes Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'WebsterRogers Financial Advisors, LLC',
    value: [{ value: 'WebsterRogers Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'J.W. Cole Financial, Inc.',
    value: [{ value: 'J.W. Cole Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Asset Management Associates',
    value: [{ value: 'Asset Management Associates', key: 'firm_name' }],
  },
  {
    label: 'Marquam Capital',
    value: [{ value: 'Marquam Capital', key: 'firm_name' }],
  },
  {
    label: 'Investment Partners Asset Management',
    value: [{ value: 'Investment Partners Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Balser Wealth Management, LLC',
    value: [{ value: 'Balser Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Chicago Partners Investment Group LLC',
    value: [{ value: 'Chicago Partners Investment Group LLC', key: 'firm_name' }],
  },
  {
    label: 'USA Financial Securities Corporation',
    value: [{ value: 'USA Financial Securities Corporation', key: 'firm_name' }],
  },
  {
    label: 'Bayview Investment Counsel, Inc',
    value: [{ value: 'Bayview Investment Counsel, Inc', key: 'firm_name' }],
  },
  {
    label: 'Strategic Family Wealth Counselors, L.L.C.',
    value: [{ value: 'Strategic Family Wealth Counselors, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'MR Advisors, LLC',
    value: [{ value: 'MR Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Evart, Young & Hobbs Investment Management',
    value: [{ value: 'Evart, Young & Hobbs Investment Management', key: 'firm_name' }],
  },
  {
    label: 'AlphaMark Advisors, LLC',
    value: [{ value: 'AlphaMark Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Crescent Wealth Advisory, LLC',
    value: [{ value: 'Crescent Wealth Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cannon Financial Strategists, Inc.',
    value: [{ value: 'Cannon Financial Strategists, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Camden Financial Services',
    value: [{ value: 'Camden Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Three Bridge Wealth Advisors',
    value: [{ value: 'Three Bridge Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'HBW Advisory Services LLC',
    value: [{ value: 'HBW Advisory Services LLC', key: 'firm_name' }],
  },
  {
    label: 'Liquidnet, Inc.',
    value: [{ value: 'Liquidnet, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Voya Financial Partners, LLC',
    value: [{ value: 'Voya Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Calibration Financial',
    value: [{ value: 'Calibration Financial', key: 'firm_name' }],
  },
  {
    label: 'Keen Wealth Advisors',
    value: [{ value: 'Keen Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Arkenstone Financial, LLC',
    value: [{ value: 'Arkenstone Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fulbright Financial Consulting, PA',
    value: [{ value: 'Fulbright Financial Consulting, PA', key: 'firm_name' }],
  },
  {
    label: 'Lantern Wealth Guardians Ltd.',
    value: [{ value: 'Lantern Wealth Guardians Ltd.', key: 'firm_name' }],
  },
  {
    label: 'PAX Financial Group',
    value: [{ value: 'PAX Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Secure Financial Management',
    value: [{ value: 'Secure Financial Management', key: 'firm_name' }],
  },
  {
    label: 'Ameritas Investment Company, LLC',
    value: [{ value: 'Ameritas Investment Company, LLC', key: 'firm_name' }],
  },
  {
    label: 'Sound Financial Group',
    value: [{ value: 'Sound Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Amundi Asset Management US, Inc.',
    value: [{ value: 'Amundi Asset Management US, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Flagship Harbor Advisors, LLC',
    value: [{ value: 'Flagship Harbor Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hershey Financial Advisers, LLC',
    value: [{ value: 'Hershey Financial Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'PlanMember Asset Management Corporation',
    value: [{ value: 'PlanMember Asset Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'Benchmark Financial',
    value: [{ value: 'Benchmark Financial', key: 'firm_name' }],
  },
  {
    label: 'Columbus Macro, LLC',
    value: [{ value: 'Columbus Macro, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eagle Eye Advisory LLC',
    value: [{ value: 'Eagle Eye Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'A. Duncan Investment Advisory, LLC',
    value: [{ value: 'A. Duncan Investment Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'William Blair',
    value: [{ value: 'William Blair', key: 'firm_name' }],
  },
  {
    label: 'Capital Investment Advisory Services, LLC',
    value: [{ value: 'Capital Investment Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Altamira Capital Management LLC',
    value: [{ value: 'Altamira Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Merit Financial Advisors',
    value: [{ value: 'Merit Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'HSBC Securities (USA) Inc.',
    value: [{ value: 'HSBC Securities (USA) Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sprive Capital Management',
    value: [{ value: 'Sprive Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Packerland Brokerage Services, Inc.',
    value: [{ value: 'Packerland Brokerage Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sage Capital Advisors, LLC',
    value: [{ value: 'Sage Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Allred Jackson Financial Planning, LLC',
    value: [{ value: 'Allred Jackson Financial Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Innovest Portfolio Solutions LLC',
    value: [{ value: 'Innovest Portfolio Solutions LLC', key: 'firm_name' }],
  },
  {
    label: 'Second Look Financial, LLC.',
    value: [{ value: 'Second Look Financial, LLC.', key: 'firm_name' }],
  },
  {
    label: 'The Foundry Financial Group, Inc.',
    value: [{ value: 'The Foundry Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sanders Morris Harris LLC',
    value: [{ value: 'Sanders Morris Harris LLC', key: 'firm_name' }],
  },
  {
    label: 'Onyx Bridge Wealth Group LLC',
    value: [{ value: 'Onyx Bridge Wealth Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Manifest',
    value: [{ value: 'Manifest', key: 'firm_name' }],
  },
  {
    label: 'PFG Advisors',
    value: [{ value: 'PFG Advisors', key: 'firm_name' }],
  },
  {
    label: 'Partners Capital Services, Inc.',
    value: [{ value: 'Partners Capital Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'S. R. Schill & Associates Inc.',
    value: [{ value: 'S. R. Schill & Associates Inc.', key: 'firm_name' }],
  },
  {
    label: 'Voya Financial Advisors, Inc.',
    value: [{ value: 'Voya Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PVG Asset Management Corp',
    value: [{ value: 'PVG Asset Management Corp', key: 'firm_name' }],
  },
  {
    label: 'Compass Financial Management LLC',
    value: [{ value: 'Compass Financial Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Planning Consultants, Inc.',
    value: [{ value: 'Financial Planning Consultants, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ifrah Financial Services, Inc.',
    value: [{ value: 'Ifrah Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Aventura Capital Management, LLC',
    value: [{ value: 'Aventura Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Blue Water Wealth',
    value: [{ value: 'Blue Water Wealth', key: 'firm_name' }],
  },
  {
    label: 'Livingston Securities, LLC',
    value: [{ value: 'Livingston Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'R2 Wealth Management Partners',
    value: [{ value: 'R2 Wealth Management Partners', key: 'firm_name' }],
  },
  {
    label: 'Colorado West Investments, Inc.',
    value: [{ value: 'Colorado West Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'FPL Capital Management, LLC',
    value: [{ value: 'FPL Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Premier Southwest Planning Group, LLC',
    value: [{ value: 'Premier Southwest Planning Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Litman Gregory Wealth Management, LLC',
    value: [{ value: 'Litman Gregory Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Management of Kentucky, Inc.',
    value: [{ value: 'Wealth Management of Kentucky, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cross State Financial Group LLC',
    value: [{ value: 'Cross State Financial Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Adolos Asset Management',
    value: [{ value: 'Adolos Asset Management', key: 'firm_name' }],
  },
  {
    label: 'Roosevelt & Cross, Incorporated',
    value: [{ value: 'Roosevelt & Cross, Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Main Street Research LLC',
    value: [{ value: 'Main Street Research LLC', key: 'firm_name' }],
  },
  {
    label: 'Atlas Private Wealth Advisors',
    value: [{ value: 'Atlas Private Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Capital Investment Advisors, LLC',
    value: [{ value: 'Capital Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Charybdis Investments International',
    value: [{ value: 'Charybdis Investments International', key: 'firm_name' }],
  },
  {
    label: 'Laurel Financial Group Inc',
    value: [{ value: 'Laurel Financial Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Sovereign Wealth Advisors LLC',
    value: [{ value: 'Sovereign Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Brainard Capital Management LLC',
    value: [{ value: 'Brainard Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Horizon Advisory Services, Inc.',
    value: [{ value: 'Horizon Advisory Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cottonwood Wealth Strategies, LLC',
    value: [{ value: 'Cottonwood Wealth Strategies, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hubbell Strickland Wealth Management, LLC',
    value: [{ value: 'Hubbell Strickland Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Kingswood Wealth Advisors, LLC',
    value: [{ value: 'Kingswood Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CapTrust Financial Advisors',
    value: [{ value: 'CapTrust Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Montgomery Capital Advisors LLC',
    value: [{ value: 'Montgomery Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Fee-Only Financial Solutions, P.C.',
    value: [{ value: 'Fee-Only Financial Solutions, P.C.', key: 'firm_name' }],
  },
  {
    label: 'JPB Partners',
    value: [{ value: 'JPB Partners', key: 'firm_name' }],
  },
  {
    label: 'Crown Financial Group, Inc.',
    value: [{ value: 'Crown Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Corient Capital Partners, LLC',
    value: [{ value: 'Corient Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Investmentpost Advisory',
    value: [{ value: 'Investmentpost Advisory', key: 'firm_name' }],
  },
  {
    label: 'Asset Guidance Group, LLC',
    value: [{ value: 'Asset Guidance Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'David Lerner Associates, Inc.',
    value: [{ value: 'David Lerner Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Nationwide Investment Services Corporation',
    value: [{ value: 'Nationwide Investment Services Corporation', key: 'firm_name' }],
  },
  {
    label: 'Traphagen Financial Group',
    value: [{ value: 'Traphagen Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Hilton Head Financial Advisors, Inc.',
    value: [{ value: 'Hilton Head Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'American Advisors LLC',
    value: [{ value: 'American Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Fountainhead Financial Management, Inc.',
    value: [{ value: 'Fountainhead Financial Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Trent Capital Management Inc',
    value: [{ value: 'Trent Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Pinnacle Family Advisors, LLC',
    value: [{ value: 'Pinnacle Family Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'American Wealth Management',
    value: [{ value: 'American Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'IronBridge Private Wealth, LLC',
    value: [{ value: 'IronBridge Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'InspereX LLC',
    value: [{ value: 'InspereX LLC', key: 'firm_name' }],
  },
  {
    label: 'Rubicon Global Group',
    value: [{ value: 'Rubicon Global Group', key: 'firm_name' }],
  },
  {
    label: 'Grand Slam Asset Management, LLC',
    value: [{ value: 'Grand Slam Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'High Falls Advisors, Inc.',
    value: [{ value: 'High Falls Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'First Trust Advisors LP',
    value: [{ value: 'First Trust Advisors LP', key: 'firm_name' }],
  },
  {
    label: 'Hudock Capital Group',
    value: [{ value: 'Hudock Capital Group', key: 'firm_name' }],
  },
  {
    label: 'Capitol Wealth Management',
    value: [{ value: 'Capitol Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Beverly Investment Advisors LLC',
    value: [{ value: 'Beverly Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'AdvisorNet Wealth Management',
    value: [{ value: 'AdvisorNet Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'PPCA, Inc.',
    value: [{ value: 'PPCA, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Primark Advisors, LLC',
    value: [{ value: 'Primark Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Peninsula-JVL Capital Advisors, LLC',
    value: [{ value: 'Peninsula-JVL Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cambiar Investors, LLC',
    value: [{ value: 'Cambiar Investors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Garde Capital, Inc.',
    value: [{ value: 'Garde Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Windrose Retirement Income Planners, Inc.',
    value: [{ value: 'Windrose Retirement Income Planners, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cetera Financial Specialists LLC',
    value: [{ value: 'Cetera Financial Specialists LLC', key: 'firm_name' }],
  },
  {
    label: 'Nuveen Asset Management, LLC',
    value: [{ value: 'Nuveen Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nova Financial Services',
    value: [{ value: 'Nova Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Aegis Capital Corp.',
    value: [{ value: 'Aegis Capital Corp.', key: 'firm_name' }],
  },
  {
    label: 'Lewis and Associates Capital Advisors LLC',
    value: [{ value: 'Lewis and Associates Capital Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Arlington Securities, Inc.',
    value: [{ value: 'Arlington Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'J K R & Company, Inc.',
    value: [{ value: 'J K R & Company, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Mercadien Asset Management, LLC',
    value: [{ value: 'Mercadien Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Personal Capital Advisors Corporation',
    value: [{ value: 'Personal Capital Advisors Corporation', key: 'firm_name' }],
  },
  {
    label: 'Arrow Investment Advisors, LLC',
    value: [{ value: 'Arrow Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Knight and Company Wealth Advisors, LLC',
    value: [{ value: 'Knight and Company Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'J.K. Financial Services, Inc.',
    value: [{ value: 'J.K. Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'PNC Capital Markets LLC',
    value: [{ value: 'PNC Capital Markets LLC', key: 'firm_name' }],
  },
  {
    label: 'De Groote Financial Group, LLC',
    value: [{ value: 'De Groote Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Aspen Capital Management LLC',
    value: [{ value: 'Aspen Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Smith Securities LLC',
    value: [{ value: 'Smith Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'MCG Securities LLC',
    value: [{ value: 'MCG Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'The Pacific Financial Group, Inc.',
    value: [{ value: 'The Pacific Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brighton Jones LLC',
    value: [{ value: 'Brighton Jones LLC', key: 'firm_name' }],
  },
  {
    label: 'Halberstadt Financial Consultants Inc',
    value: [{ value: 'Halberstadt Financial Consultants Inc', key: 'firm_name' }],
  },
  {
    label: 'EAM Global Investors LLC',
    value: [{ value: 'EAM Global Investors LLC', key: 'firm_name' }],
  },
  {
    label: 'Total Clarity Wealth Management, Inc',
    value: [{ value: 'Total Clarity Wealth Management, Inc', key: 'firm_name' }],
  },
  {
    label: 'Black Swift Group, LLC',
    value: [{ value: 'Black Swift Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Inspire Investing, LLC',
    value: [{ value: 'Inspire Investing, LLC', key: 'firm_name' }],
  },
  {
    label: 'Peter Kimmelman Asset Management LLC',
    value: [{ value: 'Peter Kimmelman Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Wakefield Asset Management, LLLP',
    value: [{ value: 'Wakefield Asset Management, LLLP', key: 'firm_name' }],
  },
  {
    label: 'Cottage Street Advisors',
    value: [{ value: 'Cottage Street Advisors', key: 'firm_name' }],
  },
  {
    label: 'Bright Rock Capital Management LLC',
    value: [{ value: 'Bright Rock Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'RS Crum Inc.',
    value: [{ value: 'RS Crum Inc.', key: 'firm_name' }],
  },
  {
    label: 'PDS Planning Inc',
    value: [{ value: 'PDS Planning Inc', key: 'firm_name' }],
  },
  {
    label: 'USCA Asset Management LLC',
    value: [{ value: 'USCA Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Shulman Demeo Asset Management, LLC',
    value: [{ value: 'Shulman Demeo Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Walden Wealth Partners LLC',
    value: [{ value: 'Walden Wealth Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Milne Financial Planning, Inc.',
    value: [{ value: 'Milne Financial Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sheridan Road Advisors, LLC, A Hub International Company',
    value: [{ value: 'Sheridan Road Advisors, LLC, A Hub International Company', key: 'firm_name' }],
  },
  {
    label: 'Integrated Advisors Network LLC',
    value: [{ value: 'Integrated Advisors Network LLC', key: 'firm_name' }],
  },
  {
    label: 'Great Diamond Partners, LLC',
    value: [{ value: 'Great Diamond Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Legacy Financial Group, Inc.',
    value: [{ value: 'The Legacy Financial Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'SWAN DFW Financial Solutions, LLC',
    value: [{ value: 'SWAN DFW Financial Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'Alpha Fiduciary',
    value: [{ value: 'Alpha Fiduciary', key: 'firm_name' }],
  },
  {
    label: 'Boston Research and Management, Inc.',
    value: [{ value: 'Boston Research and Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Oppenheimer Investment Management LLC',
    value: [{ value: 'Oppenheimer Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Esoteric Wealth Partners LLC',
    value: [{ value: 'Esoteric Wealth Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Jaffe Tilchin Investment Partners, LLC',
    value: [{ value: 'Jaffe Tilchin Investment Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Arkadios Wealth Advisors',
    value: [{ value: 'Arkadios Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'BCM Wealth Management',
    value: [{ value: 'BCM Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'MD Wealth Partners, Inc',
    value: [{ value: 'MD Wealth Partners, Inc', key: 'firm_name' }],
  },
  {
    label: 'Gebhart & Associates, Inc',
    value: [{ value: 'Gebhart & Associates, Inc', key: 'firm_name' }],
  },
  {
    label: 'Canyon CLO Advisors LLC',
    value: [{ value: 'Canyon CLO Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Westshore Wealth',
    value: [{ value: 'Westshore Wealth', key: 'firm_name' }],
  },
  {
    label: 'First Atlantic Capital, Ltd.',
    value: [{ value: 'First Atlantic Capital, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'AllianceBernstein L.P.',
    value: [{ value: 'AllianceBernstein L.P.', key: 'firm_name' }],
  },
  {
    label: 'Bison Advisors',
    value: [{ value: 'Bison Advisors', key: 'firm_name' }],
  },
  {
    label: 'AVEC LLC',
    value: [{ value: 'AVEC LLC', key: 'firm_name' }],
  },
  {
    label: 'Phoenix Wealth Advisors Inc.',
    value: [{ value: 'Phoenix Wealth Advisors Inc.', key: 'firm_name' }],
  },
  {
    label: 'IBEX Wealth Advisors, LLC',
    value: [{ value: 'IBEX Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Spectrum Wealth Management',
    value: [{ value: 'Spectrum Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Jazurek & Co LLC',
    value: [{ value: 'Jazurek & Co LLC', key: 'firm_name' }],
  },
  {
    label: 'Threadgill Financial, LLC',
    value: [{ value: 'Threadgill Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vita Planning Group LLC',
    value: [{ value: 'Vita Planning Group LLC', key: 'firm_name' }],
  },
  {
    label: 'Thinagee Investments Inc.',
    value: [{ value: 'Thinagee Investments Inc.', key: 'firm_name' }],
  },
  {
    label: 'V-Square Quantitative Management',
    value: [{ value: 'V-Square Quantitative Management', key: 'firm_name' }],
  },
  {
    label: 'Prudent Prospera Planning, Inc.',
    value: [{ value: 'Prudent Prospera Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Harbor Investment Advisory, LLC',
    value: [{ value: 'Harbor Investment Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Boulay Financial Advisors, LLC',
    value: [{ value: 'Boulay Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'ShoreHaven Wealth Partners, LLC',
    value: [{ value: 'ShoreHaven Wealth Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Guidance Point Advisors, LLC',
    value: [{ value: 'Guidance Point Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'GP Nurmenkari Inc.',
    value: [{ value: 'GP Nurmenkari Inc.', key: 'firm_name' }],
  },
  {
    label: 'Independence Financial Advisors, LLC',
    value: [{ value: 'Independence Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Brandes Investment Partners, LP',
    value: [{ value: 'Brandes Investment Partners, LP', key: 'firm_name' }],
  },
  {
    label: 'Haven Financial Advisors',
    value: [{ value: 'Haven Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Pure Financial Advisors, LLC',
    value: [{ value: 'Pure Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Stonepath Wealth Management, LLC',
    value: [{ value: 'Stonepath Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: '1919 Investment Counsel, LLC',
    value: [{ value: '1919 Investment Counsel, LLC', key: 'firm_name' }],
  },
  {
    label: 'Public Safety Financial/Galloway',
    value: [{ value: 'Public Safety Financial/Galloway', key: 'firm_name' }],
  },
  {
    label: 'Leafhouse Financial Advisors, LLC',
    value: [{ value: 'Leafhouse Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Bailey Investment Strategies LLC',
    value: [{ value: 'Bailey Investment Strategies LLC', key: 'firm_name' }],
  },
  {
    label: 'Seizert Capital Partners',
    value: [{ value: 'Seizert Capital Partners', key: 'firm_name' }],
  },
  {
    label: 'Arlington Hall, LLC',
    value: [{ value: 'Arlington Hall, LLC', key: 'firm_name' }],
  },
  {
    label: 'GulfCoast Financial Services, Inc.',
    value: [{ value: 'GulfCoast Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cherry Creek Investment Advisors Inc',
    value: [{ value: 'Cherry Creek Investment Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Carter Advisory Services, Inc',
    value: [{ value: 'Carter Advisory Services, Inc', key: 'firm_name' }],
  },
  {
    label: 'OFG Financial Services, Inc.',
    value: [{ value: 'OFG Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Smith Asset Management Co., LLC',
    value: [{ value: 'Smith Asset Management Co., LLC', key: 'firm_name' }],
  },
  {
    label: 'Zacks Investment Management, Inc.',
    value: [{ value: 'Zacks Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'FMB Retirement Services',
    value: [{ value: 'FMB Retirement Services', key: 'firm_name' }],
  },
  {
    label: 'Stonebridge Wealth Management',
    value: [{ value: 'Stonebridge Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Joslin Financial Services, Inc.',
    value: [{ value: 'Joslin Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Fuller & Thaler Asset Management Inc',
    value: [{ value: 'Fuller & Thaler Asset Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Avalon Investment & Advisory',
    value: [{ value: 'Avalon Investment & Advisory', key: 'firm_name' }],
  },
  {
    label: 'USAA Investment Services Company',
    value: [{ value: 'USAA Investment Services Company', key: 'firm_name' }],
  },
  {
    label: 'Asset Management and Planning, LLC',
    value: [{ value: 'Asset Management and Planning, LLC', key: 'firm_name' }],
  },
  {
    label: 'Springdale Capital',
    value: [{ value: 'Springdale Capital', key: 'firm_name' }],
  },
  {
    label: 'Sentara Capital',
    value: [{ value: 'Sentara Capital', key: 'firm_name' }],
  },
  {
    label: 'Newman Schimel',
    value: [{ value: 'Newman Schimel', key: 'firm_name' }],
  },
  {
    label: 'Dinan Capital Advisors',
    value: [{ value: 'Dinan Capital Advisors', key: 'firm_name' }],
  },
  {
    label: 'Clover Leaf Financial, LLC',
    value: [{ value: 'Clover Leaf Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Unio Capital LLC',
    value: [{ value: 'Unio Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Wealth Management, LLC',
    value: [{ value: 'Strategic Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Cubic Advisors, LLC',
    value: [{ value: 'Cubic Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'The Welch Group, LLC',
    value: [{ value: 'The Welch Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hurst Wealth Advisors, PC',
    value: [{ value: 'Hurst Wealth Advisors, PC', key: 'firm_name' }],
  },
  {
    label: 'Costello Financial Planning, Inc.',
    value: [{ value: 'Costello Financial Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kestra Investment Services, LLC',
    value: [{ value: 'Kestra Investment Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'PR Squared Wealth Management LLC',
    value: [{ value: 'PR Squared Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Ronin Equity Partners LLC',
    value: [{ value: 'Ronin Equity Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Corcoran Wealth Management LLC',
    value: [{ value: 'Corcoran Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Pearl Wealth LLC',
    value: [{ value: 'Pearl Wealth LLC', key: 'firm_name' }],
  },
  {
    label: 'Prince Financial Advisory LLC',
    value: [{ value: 'Prince Financial Advisory LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Team LLC',
    value: [{ value: 'Wealth Team LLC', key: 'firm_name' }],
  },
  {
    label: 'ACK Asset Management LLC',
    value: [{ value: 'ACK Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Concentrum Wealth Management',
    value: [{ value: 'Concentrum Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'TLG Advisors, Inc.',
    value: [{ value: 'TLG Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Resource 1, Inc.',
    value: [{ value: 'Resource 1, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Retirement Planning Solutions, LLC',
    value: [{ value: 'Retirement Planning Solutions, LLC', key: 'firm_name' }],
  },
  {
    label: 'EFS Advisors',
    value: [{ value: 'EFS Advisors', key: 'firm_name' }],
  },
  {
    label: 'Sona Wealth',
    value: [{ value: 'Sona Wealth', key: 'firm_name' }],
  },
  {
    label: 'Federated Global Investment Management Corp.',
    value: [{ value: 'Federated Global Investment Management Corp.', key: 'firm_name' }],
  },
  {
    label: 'Avalon Capital Management',
    value: [{ value: 'Avalon Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Nationwide Securities, LLC',
    value: [{ value: 'Nationwide Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'The J R Baker Group',
    value: [{ value: 'The J R Baker Group', key: 'firm_name' }],
  },
  {
    label: 'Bloom Advisors',
    value: [{ value: 'Bloom Advisors', key: 'firm_name' }],
  },
  {
    label: 'North Star Investment Management Corporation',
    value: [{ value: 'North Star Investment Management Corporation', key: 'firm_name' }],
  },
  {
    label: 'First Washington Corporation',
    value: [{ value: 'First Washington Corporation', key: 'firm_name' }],
  },
  {
    label: 'Hornsby Asset Management, LLC',
    value: [{ value: 'Hornsby Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Raleigh Securities Inc',
    value: [{ value: 'Raleigh Securities Inc', key: 'firm_name' }],
  },
  {
    label: 'CC Securities, LLC',
    value: [{ value: 'CC Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Baker & Company Advisory Group Inc',
    value: [{ value: 'Baker & Company Advisory Group Inc', key: 'firm_name' }],
  },
  {
    label: 'P.A.W. Capital Partners, LP',
    value: [{ value: 'P.A.W. Capital Partners, LP', key: 'firm_name' }],
  },
  {
    label: 'Quantum Strategies Management Company',
    value: [{ value: 'Quantum Strategies Management Company', key: 'firm_name' }],
  },
  {
    label: 'Luesink Stenstrom Financial',
    value: [{ value: 'Luesink Stenstrom Financial', key: 'firm_name' }],
  },
  {
    label: 'KM Capital Management, Ltd.',
    value: [{ value: 'KM Capital Management, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Oak Harvest Investment Services',
    value: [{ value: 'Oak Harvest Investment Services', key: 'firm_name' }],
  },
  {
    label: 'Robertson Opportunity Capital, LLC',
    value: [{ value: 'Robertson Opportunity Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Blueline Advisors',
    value: [{ value: 'Blueline Advisors', key: 'firm_name' }],
  },
  {
    label: 'Quest Investment Portfolio Management, Limited',
    value: [{ value: 'Quest Investment Portfolio Management, Limited', key: 'firm_name' }],
  },
  {
    label: 'BCR Wealth Strategies',
    value: [{ value: 'BCR Wealth Strategies', key: 'firm_name' }],
  },
  {
    label: 'International Assets Advisory, LLC',
    value: [{ value: 'International Assets Advisory, LLC', key: 'firm_name' }],
  },
  {
    label: 'Network 1 Financial Securities Inc.',
    value: [{ value: 'Network 1 Financial Securities Inc.', key: 'firm_name' }],
  },
  {
    label: 'Island Capital Funds',
    value: [{ value: 'Island Capital Funds', key: 'firm_name' }],
  },
  {
    label: 'Morgan Stanley & Co. LLC',
    value: [{ value: 'Morgan Stanley & Co. LLC', key: 'firm_name' }],
  },
  {
    label: 'Hemington Wealth Management',
    value: [{ value: 'Hemington Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Cherokee & Walker Management',
    value: [{ value: 'Cherokee & Walker Management', key: 'firm_name' }],
  },
  {
    label: 'Alpha Cubed Investments, LLC',
    value: [{ value: 'Alpha Cubed Investments, LLC', key: 'firm_name' }],
  },
  {
    label: 'Aevitas Wealth Management, Inc.',
    value: [{ value: 'Aevitas Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ruggieri Wealth Advisors LLC',
    value: [{ value: 'Ruggieri Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'NextWave Advisors',
    value: [{ value: 'NextWave Advisors', key: 'firm_name' }],
  },
  {
    label: 'Aaron Capital Incorporated',
    value: [{ value: 'Aaron Capital Incorporated', key: 'firm_name' }],
  },
  {
    label: 'Yosemite Capital Management',
    value: [{ value: 'Yosemite Capital Management', key: 'firm_name' }],
  },
  {
    label: 'DMKC Advisory Services, LLC',
    value: [{ value: 'DMKC Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Roger R. Bell & Co., Inc.',
    value: [{ value: 'Roger R. Bell & Co., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Financial Partners, LLC',
    value: [{ value: 'Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'JAG Capital Management LLC',
    value: [{ value: 'JAG Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'FSC Wealth Advisors, LLC',
    value: [{ value: 'FSC Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Ipswich Investment Management Company, Inc',
    value: [{ value: 'Ipswich Investment Management Company, Inc', key: 'firm_name' }],
  },
  {
    label: 'Blue Capital',
    value: [{ value: 'Blue Capital', key: 'firm_name' }],
  },
  {
    label: 'Florida Financial Advisors, Inc.',
    value: [{ value: 'Florida Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Merit Fund Advisors',
    value: [{ value: 'Merit Fund Advisors', key: 'firm_name' }],
  },
  {
    label: 'Touchstone Advisors Inc',
    value: [{ value: 'Touchstone Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Fremont Wealth Management, LP',
    value: [{ value: 'Fremont Wealth Management, LP', key: 'firm_name' }],
  },
  {
    label: 'Krusen Capital Management, LLC',
    value: [{ value: 'Krusen Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Great Valley Advisor Group, Inc.',
    value: [{ value: 'Great Valley Advisor Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Vaikus Advisory Group, LLC',
    value: [{ value: 'Vaikus Advisory Group, LLC', key: 'firm_name' }],
  },
  //   {
  // label:'Neil Securities",
  // value:[{value:'Neil Securities",key:'firm_name'}]
  //   },
  {
    label: 'Risley Capital Management LLC',
    value: [{ value: 'Risley Capital Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Womack Financial LLC',
    value: [{ value: 'Womack Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Sovereign',
    value: [{ value: 'Sovereign', key: 'firm_name' }],
  },
  {
    label: 'Counterpoint Capital Advisers, LLC',
    value: [{ value: 'Counterpoint Capital Advisers, LLC', key: 'firm_name' }],
  },
  {
    label: 'RB Capital Management, LLC',
    value: [{ value: 'RB Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Heron Bay Capital Management',
    value: [{ value: 'Heron Bay Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Integrity Wealth Advisors, Inc.',
    value: [{ value: 'Integrity Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Wealth Enhancement Advisory Services, LLC',
    value: [{ value: 'Wealth Enhancement Advisory Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Granite Ventures LLC',
    value: [{ value: 'Granite Ventures LLC', key: 'firm_name' }],
  },
  {
    label: 'Efficient Advisors',
    value: [{ value: 'Efficient Advisors', key: 'firm_name' }],
  },
  {
    label: 'Budros, Ruhlin & Roe',
    value: [{ value: 'Budros, Ruhlin & Roe', key: 'firm_name' }],
  },
  {
    label: 'Triumph Capital Management',
    value: [{ value: 'Triumph Capital Management', key: 'firm_name' }],
  },
  {
    label: 'Triumph Wealth Advisors, Inc.',
    value: [{ value: 'Triumph Wealth Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Agile Wealth Partners',
    value: [{ value: 'Agile Wealth Partners', key: 'firm_name' }],
  },
  {
    label: 'The Investment Center, Inc.',
    value: [{ value: 'The Investment Center, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Kipling Capital, Inc.',
    value: [{ value: 'Kipling Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Manley Capital Management, LLC',
    value: [{ value: 'Manley Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'LaBrunerie Financial Services, Inc.',
    value: [{ value: 'LaBrunerie Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Oatley & Diak, LLC.',
    value: [{ value: 'Oatley & Diak, LLC.', key: 'firm_name' }],
  },
  {
    label: 'Amherst Financial Group, LLC',
    value: [{ value: 'Amherst Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'TFC Financial Management Inc',
    value: [{ value: 'TFC Financial Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Hudson Financial Advisors Inc',
    value: [{ value: 'Hudson Financial Advisors Inc', key: 'firm_name' }],
  },
  {
    label: 'Braun-Bostich & Associates Inc.',
    value: [{ value: 'Braun-Bostich & Associates Inc.', key: 'firm_name' }],
  },
  {
    label: 'Sprott Asset Management USA Inc.',
    value: [{ value: 'Sprott Asset Management USA Inc.', key: 'firm_name' }],
  },
  {
    label: 'Salem Partners Wealth Management',
    value: [{ value: 'Salem Partners Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Wescom Financial Services',
    value: [{ value: 'Wescom Financial Services', key: 'firm_name' }],
  },
  {
    label: 'Jackson Thornton Asset Management, LLC',
    value: [{ value: 'Jackson Thornton Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Darwin Wealth Management',
    value: [{ value: 'Darwin Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Advisors Capital Management, LLC',
    value: [{ value: 'Advisors Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'AHL Investment Management, Inc.',
    value: [{ value: 'AHL Investment Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'C2C Wealth Management, LLC',
    value: [{ value: 'C2C Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Instinet, LLC',
    value: [{ value: 'Instinet, LLC', key: 'firm_name' }],
  },
  {
    label: 'Smart Decisions of GA LLC',
    value: [{ value: 'Smart Decisions of GA LLC', key: 'firm_name' }],
  },
  {
    label: 'Stewardship Advisors, LLC',
    value: [{ value: 'Stewardship Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'CMC Financial Group, LLC',
    value: [{ value: 'CMC Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Legacy Financial Group',
    value: [{ value: 'Legacy Financial Group', key: 'firm_name' }],
  },
  {
    label: 'The Fairman Group, LLC',
    value: [{ value: 'The Fairman Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Financial Engineering LLC',
    value: [{ value: 'Financial Engineering LLC', key: 'firm_name' }],
  },
  {
    label: 'Lockton Investment Securities, LLC',
    value: [{ value: 'Lockton Investment Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'LaSalle Debt Investors, Inc.',
    value: [{ value: 'LaSalle Debt Investors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Signalert Asset Management LLC',
    value: [{ value: 'Signalert Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Herndon Plant Oakley, Ltd.',
    value: [{ value: 'Herndon Plant Oakley, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Tranquility Partners, LLC',
    value: [{ value: 'Tranquility Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Harbor Asset Planning, Inc.',
    value: [{ value: 'Harbor Asset Planning, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Alta Communications, Inc.',
    value: [{ value: 'Alta Communications, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Scissortail Wealth Management, LLC',
    value: [{ value: 'Scissortail Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Scalebuilder, LLC',
    value: [{ value: 'Scalebuilder, LLC', key: 'firm_name' }],
  },
  {
    label: 'McElhenny Sheffield Capital Management, LLC',
    value: [{ value: 'McElhenny Sheffield Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Olympus Peaks Financial, LLC',
    value: [{ value: 'Olympus Peaks Financial, LLC', key: 'firm_name' }],
  },
  {
    label: 'Strategic Portfolio Advisors',
    value: [{ value: 'Strategic Portfolio Advisors', key: 'firm_name' }],
  },
  {
    label: 'Caldwell Sutter Capital, Inc.',
    value: [{ value: 'Caldwell Sutter Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Aquilant Advisors',
    value: [{ value: 'Aquilant Advisors', key: 'firm_name' }],
  },
  {
    label: 'Soviero Asset Management, LP',
    value: [{ value: 'Soviero Asset Management, LP', key: 'firm_name' }],
  },
  {
    label: 'McBrearty Capital Management Inc',
    value: [{ value: 'McBrearty Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Counsel Fiduciary, LLC',
    value: [{ value: 'Counsel Fiduciary, LLC', key: 'firm_name' }],
  },
  //   {
  // label:'Connor LLC",
  // value:[{value:'Connor LLC",key:'firm_name'}]
  //   },
  {
    label: 'JMG Financial Group Ltd',
    value: [{ value: 'JMG Financial Group Ltd', key: 'firm_name' }],
  },
  {
    label: 'Family Wealth Leadership',
    value: [{ value: 'Family Wealth Leadership', key: 'firm_name' }],
  },
  {
    label: 'Worch Capital LLC',
    value: [{ value: 'Worch Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Advisors Financial, Inc.',
    value: [{ value: 'Advisors Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Stonewall Asset Mgmt., Inc.',
    value: [{ value: 'Stonewall Asset Mgmt., Inc.', key: 'firm_name' }],
  },
  {
    label: 'Runestone Wealth Advisors LLC',
    value: [{ value: 'Runestone Wealth Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'EntryPoint Wealth Management',
    value: [{ value: 'EntryPoint Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'BAMCO, Inc.',
    value: [{ value: 'BAMCO, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Green Ridge Wealth Planning LLC',
    value: [{ value: 'Green Ridge Wealth Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'Charter Research & Investment Group Inc',
    value: [{ value: 'Charter Research & Investment Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Core Investment Advisors LLC',
    value: [{ value: 'Core Investment Advisors LLC', key: 'firm_name' }],
  },
  {
    label: 'Skyview Financial Group, LLC',
    value: [{ value: 'Skyview Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Gradient Capital Advisors, LLC',
    value: [{ value: 'Gradient Capital Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Signature Resources Capital Management, LLC',
    value: [{ value: 'Signature Resources Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Benin Management Corp',
    value: [{ value: 'Benin Management Corp', key: 'firm_name' }],
  },
  {
    label: 'Sound Financial Management, Inc.',
    value: [{ value: 'Sound Financial Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ONE Advisory Partners, LLC',
    value: [{ value: 'ONE Advisory Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Summit Investment Management, Ltd.',
    value: [{ value: 'Summit Investment Management, Ltd.', key: 'firm_name' }],
  },
  {
    label: 'Verity Investment Partners',
    value: [{ value: 'Verity Investment Partners', key: 'firm_name' }],
  },
  {
    label: 'Hale Capital Management, LP',
    value: [{ value: 'Hale Capital Management, LP', key: 'firm_name' }],
  },
  {
    label: 'GRF Capital Investors, Inc.',
    value: [{ value: 'GRF Capital Investors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Crimson Investment Managers, LLC',
    value: [{ value: 'Crimson Investment Managers, LLC', key: 'firm_name' }],
  },
  {
    label: 'Investment Network, Inc.',
    value: [{ value: 'Investment Network, Inc.', key: 'firm_name' }],
  },
  {
    label: 'truAdvice LLC',
    value: [{ value: 'truAdvice LLC', key: 'firm_name' }],
  },
  {
    label: 'Sanders & Scott, LLC',
    value: [{ value: 'Sanders & Scott, LLC', key: 'firm_name' }],
  },
  {
    label: 'Elk Hill Advisors, Inc.',
    value: [{ value: 'Elk Hill Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Alilonu Financial Services, LLC',
    value: [{ value: 'Alilonu Financial Services, LLC', key: 'firm_name' }],
  },
  {
    label: 'Dougherty & Associates Financial Advisors, Inc.',
    value: [{ value: 'Dougherty & Associates Financial Advisors, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Townsend Wealth Management',
    value: [{ value: 'Townsend Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Vested Wealth Advisors',
    value: [{ value: 'Vested Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'MissionOG',
    value: [{ value: 'MissionOG', key: 'firm_name' }],
  },
  {
    label: 'Moors & Cabot, Inc.',
    value: [{ value: 'Moors & Cabot, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Royal Asset Managers',
    value: [{ value: 'Royal Asset Managers', key: 'firm_name' }],
  },
  {
    label: 'Riggs Capital Management, LLC',
    value: [{ value: 'Riggs Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Citizens Securities, Inc.',
    value: [{ value: 'Citizens Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Victoria Capital Management, Inc.',
    value: [{ value: 'Victoria Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'ViewTrade Securities, Inc.',
    value: [{ value: 'ViewTrade Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Augustine Asset Management Inc',
    value: [{ value: 'Augustine Asset Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Impact Cap Management, LLC',
    value: [{ value: 'Impact Cap Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Laidlaw Asset Management, LLC.',
    value: [{ value: 'Laidlaw Asset Management, LLC.', key: 'firm_name' }],
  },
  {
    label: 'S.P. Parkin & Co',
    value: [{ value: 'S.P. Parkin & Co', key: 'firm_name' }],
  },
  {
    label: 'Bragg Comer Financial Advisors, LLC',
    value: [{ value: 'Bragg Comer Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wulff, Hansen & Co.',
    value: [{ value: 'Wulff, Hansen & Co.', key: 'firm_name' }],
  },
  {
    label: 'Cassini Capital Management, LLC',
    value: [{ value: 'Cassini Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Reyes Financial Architecture Inc.',
    value: [{ value: 'Reyes Financial Architecture Inc.', key: 'firm_name' }],
  },
  {
    label: 'Brookwood Associates, L.L.C.',
    value: [{ value: 'Brookwood Associates, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Momentum Fintech LLC',
    value: [{ value: 'Momentum Fintech LLC', key: 'firm_name' }],
  },
  {
    label: 'Syntal Capital Partners, LLC',
    value: [{ value: 'Syntal Capital Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Summit Financial Group, LLC',
    value: [{ value: 'Summit Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Fifth Avenue Family Office',
    value: [{ value: 'Fifth Avenue Family Office', key: 'firm_name' }],
  },
  {
    label: 'Colorado Capital Management',
    value: [{ value: 'Colorado Capital Management', key: 'firm_name' }],
  },
  {
    label: 'First City Capital Management Inc',
    value: [{ value: 'First City Capital Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Gerald Butrimovitz Wealth Advisory',
    value: [{ value: 'Gerald Butrimovitz Wealth Advisory', key: 'firm_name' }],
  },
  {
    label: 'Investment Planning Advisors',
    value: [{ value: 'Investment Planning Advisors', key: 'firm_name' }],
  },
  {
    label: 'The Oak Ridge Financial Services Group, Inc.',
    value: [{ value: 'The Oak Ridge Financial Services Group, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Versatile Capital Management L.L.C.',
    value: [{ value: 'Versatile Capital Management L.L.C.', key: 'firm_name' }],
  },
  {
    label: '77 Financial Group',
    value: [{ value: '77 Financial Group', key: 'firm_name' }],
  },
  {
    label: 'Wintrust Investments LLC',
    value: [{ value: 'Wintrust Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Vulcan Value Partners LLC',
    value: [{ value: 'Vulcan Value Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Pearce Financial',
    value: [{ value: 'Pearce Financial', key: 'firm_name' }],
  },
  {
    label: 'Bison Creek Wealth Management LLC',
    value: [{ value: 'Bison Creek Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'HFG Advisors',
    value: [{ value: 'HFG Advisors', key: 'firm_name' }],
  },
  {
    label: 'Boyd Watterson Asset Management, LLC',
    value: [{ value: 'Boyd Watterson Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Dowling & Yahnke, LLC',
    value: [{ value: 'Dowling & Yahnke, LLC', key: 'firm_name' }],
  },
  {
    label: 'Accelerate Financial Group, LLC',
    value: [{ value: 'Accelerate Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Hickory Financial Group, LLC',
    value: [{ value: 'Hickory Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Vanguard Marketing Corporation',
    value: [{ value: 'Vanguard Marketing Corporation', key: 'firm_name' }],
  },
  {
    label: 'Coordinated Capital Securities, Inc.',
    value: [{ value: 'Coordinated Capital Securities, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Del Monte Group, LLC',
    value: [{ value: 'Del Monte Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Klingman and Associates, LLC',
    value: [{ value: 'Klingman and Associates, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mendham Capital Management, Inc.',
    value: [{ value: 'Mendham Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'BlackGold Capital Management LP',
    value: [{ value: 'BlackGold Capital Management LP', key: 'firm_name' }],
  },
  {
    label: 'Wealthcare Advisory Partners LLC',
    value: [{ value: 'Wealthcare Advisory Partners LLC', key: 'firm_name' }],
  },
  {
    label: 'Atlantic Private Wealth, LLC',
    value: [{ value: 'Atlantic Private Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'Houlihan Lokey Capital, Inc.',
    value: [{ value: 'Houlihan Lokey Capital, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Waters, Parkerson & Co., LLC',
    value: [{ value: 'Waters, Parkerson & Co., LLC', key: 'firm_name' }],
  },
  {
    label: 'Capstone Financial',
    value: [{ value: 'Capstone Financial', key: 'firm_name' }],
  },
  {
    label: 'Krilogy Financial LLC',
    value: [{ value: 'Krilogy Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Bass Financial Strategies, Inc.',
    value: [{ value: 'Bass Financial Strategies, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Milestone Advisory Group, LLC',
    value: [{ value: 'Milestone Advisory Group, LLC', key: 'firm_name' }],
  },
  {
    label: 'Petersen Hastings Investment Management Inc',
    value: [{ value: 'Petersen Hastings Investment Management Inc', key: 'firm_name' }],
  },
  {
    label: 'Sonoma Wealth Advisors, LLC',
    value: [{ value: 'Sonoma Wealth Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'KeyBanc Capital Markets Inc.',
    value: [{ value: 'KeyBanc Capital Markets Inc.', key: 'firm_name' }],
  },
  {
    label: 'Belwood Capital Management, LLC',
    value: [{ value: 'Belwood Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Retirement Wealth Management',
    value: [{ value: 'Retirement Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Catalano Financial',
    value: [{ value: 'Catalano Financial', key: 'firm_name' }],
  },
  {
    label: 'McClarren Financial Advisors',
    value: [{ value: 'McClarren Financial Advisors', key: 'firm_name' }],
  },
  {
    label: 'Financial Consultants Group Inc',
    value: [{ value: 'Financial Consultants Group Inc', key: 'firm_name' }],
  },
  {
    label: 'Facet Wealth, Inc',
    value: [{ value: 'Facet Wealth, Inc', key: 'firm_name' }],
  },
  {
    label: 'SCH Advisors, LLC',
    value: [{ value: 'SCH Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Eclectic Associates, Inc.',
    value: [{ value: 'Eclectic Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Focus Strategies Merchant Banking, LLC',
    value: [{ value: 'Focus Strategies Merchant Banking, LLC', key: 'firm_name' }],
  },
  {
    label: '4J Wealth Management, LLC',
    value: [{ value: '4J Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'DRH Investments, Inc.',
    value: [{ value: 'DRH Investments, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Axcent Private Capital, LLC',
    value: [{ value: 'Axcent Private Capital, LLC', key: 'firm_name' }],
  },
  {
    label: 'Lehman Financial Resources',
    value: [{ value: 'Lehman Financial Resources', key: 'firm_name' }],
  },
  {
    label: 'Howard Capital Management, Inc.',
    value: [{ value: 'Howard Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Maguire Investments LLC',
    value: [{ value: 'Maguire Investments LLC', key: 'firm_name' }],
  },
  {
    label: 'Santander Securities LLC',
    value: [{ value: 'Santander Securities LLC', key: 'firm_name' }],
  },
  {
    label: 'Sandy Porter, LLC',
    value: [{ value: 'Sandy Porter, LLC', key: 'firm_name' }],
  },
  {
    label: 'CKW Financial Group, LLC',
    value: [{ value: 'CKW Financial Group, LLC', key: 'firm_name' }],
  },
  {
    label: '1st Discount Brokerage, Inc.',
    value: [{ value: '1st Discount Brokerage, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Voss Capital LLC',
    value: [{ value: 'Voss Capital LLC', key: 'firm_name' }],
  },
  {
    label: 'Virtuoso Asset Management LLC',
    value: [{ value: 'Virtuoso Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Hillspring Financial, Inc.',
    value: [{ value: 'Hillspring Financial, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Regal Wealth Group Inc.',
    value: [{ value: 'Regal Wealth Group Inc.', key: 'firm_name' }],
  },
  {
    label: 'Ford Investment Counsel LLC',
    value: [{ value: 'Ford Investment Counsel LLC', key: 'firm_name' }],
  },
  {
    label: 'Alhambra Investment Management LLC',
    value: [{ value: 'Alhambra Investment Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Monere Wealth Management, Inc.',
    value: [{ value: 'Monere Wealth Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'DP Capital Management Inc.',
    value: [{ value: 'DP Capital Management Inc.', key: 'firm_name' }],
  },
  {
    label: 'FEF Distributors, LLC',
    value: [{ value: 'FEF Distributors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Navellier & Associates Inc',
    value: [{ value: 'Navellier & Associates Inc', key: 'firm_name' }],
  },
  {
    label: 'Stockshield, LLC',
    value: [{ value: 'Stockshield, LLC', key: 'firm_name' }],
  },
  {
    label: 'Blackthorn Asset Management LLC',
    value: [{ value: 'Blackthorn Asset Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Intellicents Investment Solutions Inc',
    value: [{ value: 'Intellicents Investment Solutions Inc', key: 'firm_name' }],
  },
  {
    label: 'Retter Capital Management, LLC',
    value: [{ value: 'Retter Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'DSC Meridian Capital LP',
    value: [{ value: 'DSC Meridian Capital LP', key: 'firm_name' }],
  },
  {
    label: 'BRS Financial LLC',
    value: [{ value: 'BRS Financial LLC', key: 'firm_name' }],
  },
  {
    label: 'Karpas Strategies LLC',
    value: [{ value: 'Karpas Strategies LLC', key: 'firm_name' }],
  },
  {
    label: 'BMS Financial Advisors, LLC',
    value: [{ value: 'BMS Financial Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Holland Capital Management, LLC',
    value: [{ value: 'Holland Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Peninsula Wealth, LLC',
    value: [{ value: 'Peninsula Wealth, LLC', key: 'firm_name' }],
  },
  {
    label: 'MPI Wealth Management LLC',
    value: [{ value: 'MPI Wealth Management LLC', key: 'firm_name' }],
  },
  {
    label: 'Saturn Management LLC',
    value: [{ value: 'Saturn Management LLC', key: 'firm_name' }],
  },
  {
    label: 'George C. McClintock, LLC',
    value: [{ value: 'George C. McClintock, LLC', key: 'firm_name' }],
  },
  {
    label: 'Nationwide Investment Advisors, LLC',
    value: [{ value: 'Nationwide Investment Advisors, LLC', key: 'firm_name' }],
  },
  {
    label: 'Curi Capital',
    value: [{ value: 'Curi Capital', key: 'firm_name' }],
  },
  {
    label: 'Guanill Wealth Management',
    value: [{ value: 'Guanill Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'SMB Financial Services, Inc.',
    value: [{ value: 'SMB Financial Services, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Cheldan Capital Management, L.L.C.',
    value: [{ value: 'Cheldan Capital Management, L.L.C.', key: 'firm_name' }],
  },
  {
    label: 'Black Swan Wealth Management',
    value: [{ value: 'Black Swan Wealth Management', key: 'firm_name' }],
  },
  {
    label: 'Plunkett & Associates, Inc.',
    value: [{ value: 'Plunkett & Associates, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Conrad Capital Management, Inc.',
    value: [{ value: 'Conrad Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Valicenti Advisory Services Inc',
    value: [{ value: 'Valicenti Advisory Services Inc', key: 'firm_name' }],
  },
  {
    label: 'DriveWealth Institutional LLC',
    value: [{ value: 'DriveWealth Institutional LLC', key: 'firm_name' }],
  },
  {
    label: 'Northern Capital Management, Inc.',
    value: [{ value: 'Northern Capital Management, Inc.', key: 'firm_name' }],
  },
  {
    label: 'Gutt Financial Management, LLC',
    value: [{ value: 'Gutt Financial Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'FourBridges Securities, LLC',
    value: [{ value: 'FourBridges Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'RainMaker Securities, LLC',
    value: [{ value: 'RainMaker Securities, LLC', key: 'firm_name' }],
  },
  {
    label: 'Apollon Wealth Management, LLC',
    value: [{ value: 'Apollon Wealth Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Susquehanna Financial Group, LLLP',
    value: [{ value: 'Susquehanna Financial Group, LLLP', key: 'firm_name' }],
  },
  {
    label: 'True Wealth Advisory Group',
    value: [{ value: 'True Wealth Advisory Group', key: 'firm_name' }],
  },
  {
    label: 'CoastEdge Partners, LLC',
    value: [{ value: 'CoastEdge Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Conexus Capital Management, LLC',
    value: [{ value: 'Conexus Capital Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Wealth Architects, LLC',
    value: [{ value: 'Wealth Architects, LLC', key: 'firm_name' }],
  },
  {
    label: 'Forestview Financial Partners, LLC',
    value: [{ value: 'Forestview Financial Partners, LLC', key: 'firm_name' }],
  },
  {
    label: 'Coburn Barrett, LLC',
    value: [{ value: 'Coburn Barrett, LLC', key: 'firm_name' }],
  },
  {
    label: 'PMA Asset Management, LLC',
    value: [{ value: 'PMA Asset Management, LLC', key: 'firm_name' }],
  },
  {
    label: 'Mestmaker & Petrey Wealth Advisors',
    value: [{ value: 'Mestmaker & Petrey Wealth Advisors', key: 'firm_name' }],
  },
  {
    label: 'Purpose Financial Planning LLC',
    value: [{ value: 'Purpose Financial Planning LLC', key: 'firm_name' }],
  },
  {
    label: 'M.C. Byrd Wealth Management, LLC',
    value: [{ value: 'M.C. Byrd Wealth Management, LLC', key: 'firm_name' }],
  },
];
