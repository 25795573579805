export const saveJsonToFile = async (jsonData: any, fileName?: string, dataType?: string) => {
  // console.log('In save Json data: ', jsonData);
  const element = document.createElement('a');
  const textFile = new Blob([JSON.stringify(jsonData)], {
    type: dataType ? dataType : dataType ? dataType : 'application/json',
  }); //pass data from localStorage API to blob
  element.href = URL.createObjectURL(textFile);
  element.download = fileName ? fileName : 'newArticle.json';
  document.body.appendChild(element);
  element.click();
};

export const downloadCSVFile = async (filename: string, fileArray: any) => {
  const fileContents: any = 'data:text/csv;charset=utf-8,' + fileArray.map((e: any) => e.join(',')).join('\n');
  const element = document.createElement('a');
  const uri = encodeURI(fileContents);

  element.href = uri;
  element.download = filename;

  document.body.appendChild(element);
  element.click();
};
