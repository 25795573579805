const contentIdGenerator = (length = 10, numbersOnly = false) => {
  const numChars = '0123456789';
  const alphaChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let chars = numChars;
  if (!numbersOnly) {
    chars = numChars + alphaChars;
  }
  var result = '';
  for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
};

export const initBlocks = () => [
  initCustomBlock('One Column Content', [generateContentColumn()]),
  initCustomBlock('Three Column Content', [generateContentColumn(), generateContentColumn(), generateContentColumn()]),
  initCustomBlock('Two Column Content', [generateContentColumn(), generateContentColumn()]),
];

export const initCustomBlock = (blockTitle: string, columns: any) => {
  const cells = columns.map((item: any) => 1);
  const customBlock = {
    id: contentIdGenerator(6, true),
    category: blockTitle,
    tags: [],
    data: {
      id: contentIdGenerator(),
      cells,
      columns,
      values: {
        displayCondition: null,
        columns: true,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: '0px',
        anchor: '',
        hideDesktop: false,
        _meta: {
          htmlClassNames: 'u_row',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
      },
    },
    displayMode: 'email',
  };

  return customBlock;
};

export const initHtmlBlock = (blockTitle: string, html: string) => {
  const customBlock = {
    id: contentIdGenerator(6, true),
    category: blockTitle,
    tags: [],
    data: {
      id: contentIdGenerator(),
      type: 'html',
      values: {
        displayCondition: null,
        columns: true,
        backgroundColor: '',
        columnsBackgroundColor: '',
        backgroundImage: {
          url: '',
          fullWidth: true,
          repeat: false,
          center: true,
          cover: false,
        },
        padding: '0px',
        anchor: '',
        hideDesktop: false,
        _meta: {
          htmlClassNames: 'u_row',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
        text: html,
      },
    },
    displayMode: 'email',
  };
};

export const generateContentColumn = (
  imageSource = null,
  title = 'Content Title',
  description = 'This is a new Text block. Change this text.',
  buttonText = 'Read more',
  articleLink = null,
) => ({
  id: contentIdGenerator(),
  contents: [
    {
      id: contentIdGenerator(),
      type: 'image',
      values: {
        containerPadding: '10px',
        anchor: '',
        src: {
          url: imageSource,
          width: 800,
          height: 200,
        },
        textAlign: 'center',
        altText: '',
        action: {
          name: 'web',
          values: {
            href: '',
            target: '_blank',
          },
        },
        displayCondition: null,
        _meta: {
          htmlClassNames: 'u_content_image',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
      },
    },
    {
      id: contentIdGenerator(),
      type: 'heading',
      values: {
        containerPadding: '10px',
        anchor: '',
        headingType: 'h1',
        fontFamily: {
          label: 'Arial',
          value: 'arial,helvetica,sans-serif',
        },
        fontSize: '22px',
        textAlign: 'center',
        lineHeight: '140%',
        linkStyle: {
          inherit: true,
          linkColor: '#242B2F',
          linkHoverColor: '#242B2F',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        displayCondition: null,
        _meta: {
          htmlClassNames: 'u_content_heading',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
        text: title,
      },
    },
    {
      id: contentIdGenerator(),
      type: 'text',
      values: {
        containerPadding: '10px',
        anchor: '',
        textAlign: 'center',
        lineHeight: '140%',
        linkStyle: {
          inherit: true,
          linkColor: '#242B2F',
          linkHoverColor: '#242B2F',
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        displayCondition: null,
        _meta: {
          htmlClassNames: 'u_content_text',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
        text: `<p style="font-size: 14px; line-height: 140%;">${description}</p>`,
      },
    },
    {
      id: contentIdGenerator(),
      type: 'button',
      values: {
        containerPadding: '10px',
        anchor: '',
        href: {
          name: 'web',
          values: {
            href: articleLink,
            target: '_blank',
          },
        },
        buttonColors: {
          color: '#FFFFFF',
          backgroundColor: '#242B2F',
          hoverColor: '#FFFFFF',
          hoverBackgroundColor: '#242B2FF0',
        },
        size: {
          autoWidth: true,
          width: '100%',
        },
        textAlign: 'center',
        lineHeight: '120%',
        padding: '10px 20px',
        border: {},
        borderRadius: '4px',
        displayCondition: null,
        _meta: {
          htmlClassNames: 'u_content_button',
        },
        selectable: true,
        draggable: true,
        duplicatable: true,
        deletable: true,
        hideable: true,
        text: buttonText,
        calculatedWidth: 109,
        calculatedHeight: 37,
      },
    },
  ],
  values: {
    _meta: {
      htmlClassNames: 'u_column',
    },
  },
});
