import React, { useState, useEffect } from 'react';
import { ResponsiveContainer, Scatter, ScatterChart, Tooltip, XAxis, YAxis, ZAxis } from 'recharts';
import Loader from '../Loader';
import { styled } from '@mui/material';
import { formatNumber } from '../../helpers/dataFormatter';
import { colors, typography } from '../../styles';
import { CardBody, CardContainer, CardHeader, H2, F3, Column } from '../';
import { testData } from './testData';

const TooltipContainer = styled('div')({
  background: '#242B2F',
  color: 'white',
  borderRadius: 5,
  width: 'fit-content',
  padding: '10px 40px',
  boxShadow: '0 0 0.4em #797D83',
  textAlign: 'center',
});

const TooltipName = styled('p')({
  fontSize: 18,
  fontWeight: 500,
  margin: '4px 0 10px',
});

const TooltipValue = styled('p')({
  fontSize: 12,
  fontWeight: 400,
  margin: '10px 0 4px',
});

const ChartContainer = styled('div')({
  background: '#fff',
  paddingBottom: 0,
  borderRadius: '5px',
  width: '100%',
  //padding: '0px 40px 0px 40px',
});

const NoDataContainer = styled('div')({
  position: 'absolute',
  zIndex: 1000,
  display: 'flex',
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  // margin: '60px 0px 0px',
  width: '100%',
  padding: '100px 170px',
  backgroundColor: colors.white_00,
  backdropFilter: 'blur(35px)',
  opacity: 0.94,
});

const defaultRange = [8, 24, 72];

interface Props {
  id?: string;
  title: string;
  getData?: any;
  timeArray?: any;
  style?: any;
  height?: number;
  chartData?: any;
  individualType?: string;
  testData?: boolean;
  demoMode?: boolean;
}

const AmplitudePoint = (props: any) => {
  const { cx, cy, width } = props;
  return (
    <svg>
      <ellipse cx={cx} cy={cy} ry={width * 8} rx={2.5} fill={colors.black_01} />
    </svg>
  );
};

const AmplitudeChart = ({
  title,
  id,
  getData,
  timeArray = defaultRange,
  style,
  height,
  chartData,
  demoMode,
}: Props) => {
  const [range, setRange] = useState([0, 100]);
  const [zRange, setZRange] = useState([0, 100]);
  const [error, setError] = useState(false);
  const [xTicks, setXTicks] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  //   const [chartData, setChartData] = useState<null | any>(null);

  useEffect(() => {
    getAndFormatData();
  }, [chartData]);

  const getAndFormatData = () => {
    const domainMax = chartData?.domain?.[1] ? chartData?.domain?.[1] : 0;
    const domainMin = chartData?.domain?.[0] ? chartData?.domain?.[0] : 0;
    const domainDiff = domainMax - domainMin;
    const domainIncrement = domainDiff / 6;
    const newXTicks = [];
    for (let runningTick = domainMin + domainIncrement; runningTick < domainMax; runningTick += domainIncrement) {
      newXTicks.push(runningTick);
    }

    setXTicks(newXTicks);

    console.log('xTicks in getAndFormatData: ', xTicks, newXTicks, domainMax, domainMin);
  };

  const CustomTooltip = ({ payload, active }: any) => {
    // console.log('payload: ', payload);
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipValue>{formatTimeString12Hour(payload?.[0]?.value)}</TooltipValue>
          <TooltipName>{formatNumber(payload?.[2]?.value)} Events</TooltipName>
        </TooltipContainer>
      );
    }

    return null;
  };

  const formatTimeString = (timeNumber: string) => {
    const newTime = new Date(timeNumber);
    const formattedTime = `${newTime.toLocaleString([], {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      hour12: true,
    })}`;

    return formattedTime.split(' ').join('').replace(',', ' - ');
  };

  const formatTimeString12Hour = (timeNumber: string) => {
    const newTime = new Date(timeNumber);
    const formattedTime = `${newTime.toLocaleDateString([], {
      month: 'numeric',
      day: 'numeric',
      year: '2-digit',
    })} - ${newTime.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`;

    return formattedTime;
  };

  return (
    <CardContainer sx={{ ...style, height: height ? height + 30 : 350 }}>
      <CardHeader>{title}</CardHeader>
      <ChartContainer style={{ position: 'relative' }}>
        {!chartData || chartData?.dataPoints?.length === 0 ? (
          error ? (
            <div style={{ margin: 'auto', width: '100%', padding: '36px' }}>
              <H2 sx={{ color: colors.grey_03, fontWeight: 600 }} id={'bubble-chart-error'}>
                {'Loading Error'}
              </H2>
              <F3 sx={{ color: colors.grey_02 }}>
                {`There was an unforseen issue loading your thematic data. Please adjust your selections and try again. (${error})`}
              </F3>
            </div>
          ) : loading ? (
            <Loader inputMargin={'100px auto 10px'} />
          ) : (
            <Column sx={{ padding: '100px 32px' }}>
              <H2>Data Not Available</H2>
              <F3 sx={{ color: colors.grey_02, marginTop: '-8px' }}>
                It can take up to forty-eight hours for engagement data to populate.{' '}
              </F3>
            </Column>
          )
        ) : (
          <>
            {/* @ts-ignore */}
            {chartData === testData && !demoMode && (
              <NoDataContainer>
                <h1
                  style={{
                    ...typography.mainFont,
                    color: colors.grey_03,
                    fontSize: '16px',
                    fontWeight: '500',
                  }}
                >
                  {'Insufficient Data'}
                </h1>
                <p
                  style={{
                    ...typography.mainFont,
                    color: colors.grey_02,
                    fontSize: '12px',
                    fontWeight: '500',
                    marginTop: '0px',
                  }}
                >
                  Please check back later for updated data
                </p>
              </NoDataContainer>
            )}
            <CardBody id={id} style={{ marginTop: height ? -height / 6 : -30, padding: '32px 0px 0px' }}>
              <ResponsiveContainer width={'99%'} height={height ? height : 280} id={'bubble-chart-data'}>
                {/* @ts-ignore */}
                <ScatterChart margin={{ left: 20, right: 20 }} height={'100%'}>
                  <XAxis
                    type="number"
                    dataKey={'timestamp'}
                    domain={chartData.domain}
                    tick={(tick) => {
                      return (
                        <text x={tick?.x - 36} y={tick?.y + 10} fill={colors.grey_02} fontFamily="Inter" fontSize="12">
                          {formatTimeString(tick?.payload?.value)}
                        </text>
                      );
                    }}
                    stroke={colors.grey_01}
                    ticks={xTicks}
                    tickLine={false}
                    axisLine={{ stroke: colors.grey_01 }}
                    //tick={{ stroke: '#758790', fontSize: 14, fontWeight: 100, fill: '#758790' }}
                  />
                  <YAxis
                    //type="catagory"
                    dataKey={'index'}
                    tickFormatter={(tick) => (chartData?.yAxisLegend?.[tick] ? chartData.yAxisLegend[tick] : ' ')}
                    tick={{
                      fill: colors.grey_02,
                      fontSize: 12,
                      ...typography.mainFont,
                      //@ts-ignore
                      textAlign: 'left',
                      whiteSpace: 'nowrap',
                    }}
                    mirror
                    width={180}
                    axisLine={false}
                    interval={0}
                    tickLine={false}
                    ticks={[-1, 0, 1]}
                    //@ts-ignore
                    padding={{ left: 80, right: 10 }}
                    minTickGap={0}
                    fill={colors.grey_02}
                    range={range}
                  />
                  <ZAxis
                    dataKey={'volume'}
                    //@ts-ignore
                    fill="#fff"
                    type="number"
                    scale="linear"
                    //domain={domain}
                    range={zRange}
                    //tick={{ stroke: '#758790', fontWeight: 100, fontSize: 14, fill: '#758790' }}
                  />
                  <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
                  <Scatter
                    data={chartData.dataPoints}
                    //@ts-ignore
                    domain={chartData.domain}
                    shape={<AmplitudePoint />}
                    fill={colors.black}
                  />
                </ScatterChart>
              </ResponsiveContainer>
            </CardBody>
          </>
        )}
      </ChartContainer>
    </CardContainer>
  );
};

AmplitudeChart.defaultProps = {
  chartData: testData,
};

export default AmplitudeChart;
