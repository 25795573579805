import React, { useEffect, useState } from 'react';
import { colors, typography } from '../../styles';
import { styled } from '@mui/system';
import ComingSoonModal from '../ComingSoonModal';
import SwitchFilter from '../SwitchFilter';
import { formatThousandDollar, formatPercent, formatDollar, formatLeadPoolFilters } from '../../helpers/dataFormatter';
import { PrimaryButtonShort, Row } from '..';
import Loader from '../Loader';

//images
import alert from '../../assets/icons/alert.svg';
import { LinkedDataBarChart } from './LinkedDataBarChart';
import { useStoreState } from '../../store';
import { LeadPoolChart } from './LeadPoolChart';
import { ChartSelections } from './ChartSelections';
import { CustomBarChart } from '../PackageCustomizePage/CustomBarChart';

const defaultHeader = {
  standard: [{ title: 'Advisor', id: 'advisor' }],
};

const defaultToggle = [
  {
    displayText: 'Age',
    title: 'Age',
    key: 'advisorClientsAndTifinByAge',
    dataKey: 'advisor_age',
    averageDataKey: 'age',
  },
  {
    displayText: 'Income',
    title: 'Income',
    key: 'advisorClientsAndTifinByIncome',
    dataKey: 'income',
    type: 'dollar',
    averageDataKey: 'income',
  },
  {
    displayText: 'Estimated Assets',
    title: 'Estimated Assets',
    key: 'advisorClientsAndTifinByInvestableAssets',
    dataKey: 'estimated_investable_assets',
    type: 'dollar',
    averageDataKey: 'estimated_investable_assets',
  },
  {
    displayText: 'Home Value',
    title: 'Home Value',
    key: 'advisorClientsAndTifinByHomeValue',
    dataKey: 'home_value',
    type: 'dollar',
    averageDataKey: 'home_value',
  },
  {
    displayText: 'Percent of Mortgage Paid',
    title: 'Mortgage Paid',
    key: 'advisorClientsAndTifinByPercOfHomeValuePaidOff',
    type: 'percent',
    dataKey: 'percent_of_home_value_paid_off',
    averageDataKey: 'percent_of_home_value_paid_off',
  },
  {
    displayText: 'Financial Planning Score',
    title: 'Financial Planning',
    key: 'advisorClientsAndTifinByFinPlanScore',
    dataKey: 'financial_planning_tendency_score',
    type: 'score',
    averageDataKey: 'financial_planning_tendency_score',
  },
  {
    displayText: 'Credit Score Proxy',
    title: 'Credit Score Proxy',
    key: 'advisorClientsAndTifinByProxyCreditScore',
    dataKey: 'proxy_credit_score',
    type: 'score',
    averageDataKey: 'proxy_credit_score',
  },
  {
    displayText: 'Future Assets',
    title: 'Future Assets',
    key: 'advisorClientsAndTifinByRetirementAssets',
    dataKey: 'projected_retirement_assets',
    type: 'dollar',
    averageDataKey: 'projected_retirement_assets',
  },
];

const ChartText = styled('div')({
  ...typography.mainFont,
  display: 'inline-block',
  color: colors.grey_03,
  fontWeight: 400,
  fontSize: '13px',
  lineHeight: '15px',
  margin: '16px 0px 8px ',
});

const RootContainer = styled('div')({
  width: '100%',
  margin: '25px auto',
  minHeight: 400,
  background: '#fff',
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});

const CardHeader = styled('div')({
  width: '100%',
  overflowX: 'auto',
  padding: 0,
  height: '50px',
  display: 'flex',
  borderBottom: '1px solid #e3e5e4',
  justifyContent: 'space-between',
  borderRadius: '5px 0px 0px 0px',
});

const CardBodyContainer = styled('div')({
  display: 'flex',
  borderBottom: '1px solid #e3e5e4',
  borderRadius: '5px',
  justifyContent: 'flex-start',
});

const BodyMiddleContainer = styled('div')({
  minHeight: 400,
  width: '16%',
  padding: '0px 16px 0px 0px',
  borderRight: '1px solid #e3e5e4',
});

const BodyRightContainer = styled('div')({
  width: '60%',
  minHeight: 400,
  background: '#F9F9FF',
  position: 'relative',
  borderRadius: '0px 0px 5px 0px',
  overflow: 'auto',
});

const AlertTextInfo = styled('div')({
  color: '#404B50',
  fontWeight: 400,
  fontSize: 14,
  margin: '16px 0px 16px',
});

const AlertContent = styled('div')({
  marginTop: '8px',
});

const AlertIcon = styled('img')({
  width: 12,
  margin: '0px 4px -1px 0',
  display: 'inline',
});

const ToggleHeader = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 20px 0',
  borderRadius: '5px',
});

const LeadPoolCount = styled('div')({
  ...typography.mainFont,
  fontWeight: '700',
  fontSize: '28px',
  color: colors.black,
  margin: '0px 0px 16px',
});

const ToggleSelected = styled('h2')({
  fontFamily: 'Inter',
  fontSize: 16,
  fontWeight: 500,
  margin: '0px 15px 0px 0px',
  padding: 0,
  color: colors.grey_03,
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.7,
  },
});

const ToggleMain = styled('h2')({
  fontFamily: 'Inter',
  fontSize: 16,
  fontWeight: 500,
  margin: '0px 15px 0px 0px',
  padding: 0,
  color: colors.grey_02,
  cursor: 'pointer',
  borderRadius: '5px 0px',
});

interface Props {
  dataPoints: any;
  loading: boolean;
  label: string;
  headerOptions: any;
  setFeatureSelections: any;
  featureSelections: any;
  selectedFilters: any;
  setSelectedFilters: any;
  getLeadPool: any;
  submitAction: any;
  displayData: any;
  selectedToggle: any;
  selectedMetric: any;
  clientCount: any;
  setSelectedToggle: any;
  switchMetric: any;
  explanation: any;
  selectOptions: any;
  setSelectedMetric: any;
  benchmark: any;
  activeTable: any;
  setActiveTable: any;
  showLinkedData: any;
  hideCurrentFundHolders?: boolean;
  setHideCurrentFundHolders?: any;
  toggleConnectModal: any;
}

const CharacterChart = ({
  dataPoints,
  label,
  headerOptions = defaultHeader,
  setFeatureSelections,
  featureSelections,
  selectedFilters,
  setSelectedFilters,
  getLeadPool,
  submitAction,
  displayData = defaultToggle,
  selectedToggle,
  selectedMetric,
  clientCount,
  setSelectedToggle,
  switchMetric,
  explanation,
  selectOptions,
  setSelectedMetric,
  benchmark,
  hideCurrentFundHolders,
  setHideCurrentFundHolders,
  activeTable,
  showLinkedData,
}: Props) => {
  const [currentPoolSize, setCurrentPoolSize] = useState<number | null>(null);
  const [totalPoolSize, setTotalPoolSize] = useState<number | null>(null);
  const [poolChange, setPoolChange] = useState(0);
  const [activePieSector, setActivePieSector] = useState(0);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const appType = useStoreState((state) => state.appType);

  // const RADIAN = Math.PI / 180;

  // console.log('display data:', displayData);

  useEffect(() => {
    setSelectedMetric(selectOptions[0]);
    switchMetric(selectOptions[0]);
  }, [selectedToggle]);

  useEffect(() => {
    if (!!getLeadPool) {
      const getTotalPoolSize = async () => {
        try {
          const result = await getLeadPool([]);
          setTotalPoolSize(result);
          // console.log('result from getTotalPoolSize: ', result);
        } catch (error) {
          console.warn('error in totalPoolSize', error);
        }
      };
      getTotalPoolSize();
    } else {
      setTotalPoolSize(null);
    }
  }, [getLeadPool]);

  useEffect(() => {
    let apiFilters = [];
    const getPoolSize = async () => {
      apiFilters = formatLeadPoolFilters(selectedFilters);
      // console.log('apiFilters: ', apiFilters);
      try {
        const result = await getLeadPool(apiFilters);
        // console.log('result from getPoolSize: ', result);
        setCurrentPoolSize(result);
        const newPoolChange: any = totalPoolSize ? (((totalPoolSize - result) / totalPoolSize) * 100).toFixed(0) : 0;
        setPoolChange(newPoolChange);
        return result;
      } catch (error) {
        console.warn('error in leadPoolFilters: ', error);
      }
    };
    if (getLeadPool) {
      getPoolSize();
    } else {
      setTotalPoolSize(0);
    }
  }, [selectedFilters]);

  const updateSelectedMetrics = (row: any) => {
    //Remove once we have data
    if (!row.linked && showLinkedData && selectedToggle.title === 'Mortgage Characteristics') {
      // console.log('disabled');
    } else {
      setFeatureSelections({ ...featureSelections, [row.dataKey]: !featureSelections[row.dataKey] });
      switchMetric(row);
    }
  };

  const updateFilters = (key: string, value: any) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };

  const updateMultipleFilters = (value: any, label: string) => {
    if (value.length < 1) {
      setSelectedFilters({ ...selectedFilters, [label]: ['Any'] });
    } else {
      if (value[0] === 'Any' && value.length > 1) {
        let notAny = value.splice(1, value.length);
        setSelectedFilters({ ...selectedFilters, [label]: { label: notAny } });
      } else if (value.includes('Any')) {
        let current = selectedFilters;
        delete current[label];
        setSelectedFilters({ ...current });
      } else {
        setSelectedFilters({ ...selectedFilters, [label]: { label: value } });
      }
    }
  };

  const updateData = (item: any) => {
    if (selectedToggle !== item) {
      switchMetric(item);
    }
    setSelectedToggle(item);
  };

  const formatAverage = (value: any, key: string) => {
    if (headerOptions[activeTable][0].id == 'crossSell' || headerOptions[activeTable][0].id == 'prioritizeProspects') {
      if (key === 'income' || key === 'home_value' || key === '' || key === 'estimated_investable_assets') {
        return formatDollar(value);
      } else if (key === 'financial_planning_tendency_score') {
        return formatPercent(value * 100);
      } else if (key === 'percent_of_home_value_paid_off' || key === 'b') {
        return formatPercent(value);
      } else if (key === 'projected_retirement_assets') {
        return formatDollar(value);
      } else {
        return value;
      }
    } else {
      if (key === 'income' || key === 'home_value' || key === '' || key === 'estimated_investable_assets') {
        return formatThousandDollar(value);
      } else if (key === 'financial_planning_tendency_score') {
        return formatPercent(value * 100);
      } else if (key === 'percent_of_home_value_paid_off' || key === 'b') {
        return formatPercent(value);
      } else if (key === 'estimated_investable_assets' || key === 'projected_retirement_assets') {
        return formatThousandDollar(value);
      } else {
        return value;
      }
    }
  };

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  // console.log('header options:', headerOptions);

  return (
    <RootContainer>
      <CardHeader>
        <ToggleHeader>
          {headerOptions[activeTable].map((item: any, index: number) => {
            return selectedToggle?.id === item?.id ? (
              <ToggleSelected
                key={index}
                onClick={() => {
                  updateData(item);
                }}
              >
                {item.title}
              </ToggleSelected>
            ) : (
              <ToggleMain
                key={index}
                onClick={() => {
                  updateData(item);
                }}
              >
                {item.title}
              </ToggleMain>
            );
          })}
        </ToggleHeader>
        <Row>
          {appType === 'LPL' && getLeadPool && (
            <SwitchFilter
              copy={'Exclude Current Fund Holders'}
              value={!!hideCurrentFundHolders}
              setValue={setHideCurrentFundHolders}
              style={{ margin: '0px 9px 0px' }}
            />
          )}
          <PrimaryButtonShort
            style={{ margin: '9px 9px' }}
            onClick={() => submitAction()}
            children={`Submit Selections`}
            id={`select-characteristics-button`}
            disabled={appType !== 'LPL' && currentPoolSize === 0}
          />
        </Row>
      </CardHeader>
      <CardBodyContainer>
        <ChartSelections
          {...{
            selectOptions,
            selectedMetric,
            showLinkedData,
            selectedToggle,
            selectedFilters,
            updateSelectedMetrics,
            featureSelections,
            updateFilters,
            updateMultipleFilters,
            setSelectedFilters,
            hideCurrentFundHolders: hideCurrentFundHolders,
            setHideCurrentFundHolders: setHideCurrentFundHolders,
          }}
        />
        <BodyMiddleContainer>
          {selectedToggle.id === 'advisorLeadPool' ||
          selectedToggle.id === 'individualLeadPool' ||
          selectedToggle.id === 'crossSellLeadPool' ||
          selectedToggle.id === 'prioritizeProspectsLeadPool' ? (
            <div>
              <AlertTextInfo>Size of {label ? label : 'Lead'} Pool with current customizations</AlertTextInfo>
              <Row>
                <LeadPoolCount>
                  {100 - poolChange}%{' '}
                  <span style={{ fontSize: '12px', fontWeight: '400', color: colors.grey_02 }}>of lead pool</span>
                </LeadPoolCount>
                {/* <LeadPoolChange>({100 - poolChange}% of pool)</LeadPoolChange> */}
              </Row>
              <ChartText style={{ fontWeight: 600 }}>Note</ChartText>
              <br />
              <ChartText style={{ margin: '10px 0px' }}>
                Adding filters will decrease the size of your {label ? label : 'Lead'} Pool
              </ChartText>
            </div>
          ) : (
            <AlertContent>
              {benchmark &&
              selectedToggle.title &&
              (headerOptions[activeTable][0].id == 'prioritizeAdvisors' ||
                headerOptions[activeTable][0].id == 'prioritizeProspects' ||
                headerOptions[activeTable][0].id == 'prioritizeClients' ||
                headerOptions[activeTable][0].id == 'crossSell') ? (
                <AlertTextInfo>
                  Your advisor {selectedMetric.displayText} Benchmark is{' '}
                  <span style={{ fontWeight: '600' }}>{formatAverage(benchmark, selectedMetric.dataKey)}</span>.
                </AlertTextInfo>
              ) : null}
              {explanation && (
                <>
                  <ChartText>
                    <AlertIcon src={alert} alt="alert" />
                    {explanation && explanation}{' '}
                  </ChartText>
                  <br />
                </>
              )}
              {headerOptions[activeTable][0].title == 'Individual' || headerOptions[activeTable][0].id == 'advisor' ? (
                <ChartText>
                  We, therefore, give these individuals a higher AMP Score. Deselect this characteristic if you do not
                  want to favor these individuals.
                </ChartText>
              ) : null}
            </AlertContent>
          )}
        </BodyMiddleContainer>
        <ComingSoonModal
          modalStatus={showComingSoon}
          closeModal={toggleComingSoon}
          custom={'This functionality is currently being built. Check back soon for updates.'}
        />
        <BodyRightContainer>
          {displayData.length === 0 && totalPoolSize === null && !showLinkedData ? (
            <div style={{ display: 'flex' }}>
              <Loader />
            </div>
          ) : selectedToggle.id === 'advisorLeadPool' ||
            selectedToggle.id === 'individualLeadPool' ||
            selectedToggle.id === 'crossSellLeadPool' ||
            selectedToggle.id === 'prioritizeProspectsLeadPool' ? (
            <LeadPoolChart label={label} currentPoolSize={currentPoolSize} totalPoolSize={totalPoolSize} />
          ) : showLinkedData && selectedToggle.title === 'Mortgage Characteristics' ? (
            <LinkedDataBarChart selectedToggle={selectedToggle} selectedMetric={selectedMetric} />
          ) : (
            <CustomBarChart
              title={'Breakdown of ' + label + ' by ' + selectedToggle.title}
              subheader={'Based on ' + clientCount + ' ' + label + ' in your lead pool'}
              // clientCount={clientCount}
              selectedMetric={selectedMetric}
              chartData={displayData}
              dataLegend={dataPoints}
              showLinkedData={showLinkedData}
              demoMode={false}
            />
          )}
        </BodyRightContainer>
      </CardBodyContainer>
    </RootContainer>
  );
};

export default CharacterChart;
