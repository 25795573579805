export const randomNumber = (max?: number, min?: number) => {
  const defaultMax = max ? max : 10;
  const defaultMin = min ? min : 1;

  const randomNumber = Math.floor(Math.random() * (defaultMax - defaultMin) + defaultMin);
  return randomNumber;
};

export const generateDistillScoreChange = () => {
  return randomNumber(5, 15);
};

export const generateKey = () => randomNumber(100000000);
