import React from 'react';
import { makeStyles } from '@mui/styles';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer } from 'recharts';
import { colors, typography } from '../styles';
import DetailedTooltip from './DetailedTooltip';
import { F4 } from '.';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  innerRadarContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
    width: '100%',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  labelContainer: {
    width: 'fit-content',
    display: 'flex',
    margin: 'auto',
    height: '24px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '25px 0',
  },
  labelContent: {
    width: 'fit-content',
    margin: 'auto',
    fontSize: 13,
    fontWeight: '500',
    letterSpacing: -0.5,
    marginLeft: '6px',
    color: colors.grey_03,
  },
  labelRow: {
    display: 'flex',
    padding: '0px 16px',
  },
  noDataContainer: {
    position: 'absolute',
    zIndex: 1000,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '140px 80px',
    backgroundColor: colors.white_00,
    backdropFilter: 'blur(35px)',
    opacity: 0.8,
  },
}));

const fade = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.6;
  }
`;

const ChartLabels = styled('text')({
  //animation: `${fade} 2s ease`,
});

interface Props {
  data: any;
  radarData: any;
  outerRadius?: any;
  ticks?: any;
  innerRadarContainer?: any;
  labelContainer?: any;
  width?: number;
  style?: any;
  labelDetails?: any;
  errorMessage?: any;
}

const GeneralRadar = ({
  data,
  radarData,
  outerRadius,
  ticks,
  innerRadarContainer,
  labelContainer,
  width,
  style,
  labelDetails,
  errorMessage,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.innerRadarContainer} style={{ ...innerRadarContainer }}>
        {data?.length === 0 && (
          <div className={classes.noDataContainer}>
            <h1
              style={{
                ...typography.mainFont,
                color: colors.grey_03,
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              {errorMessage?.header ? errorMessage?.header : 'Insufficient Data'}
            </h1>
            <p
              style={{
                ...typography.mainFont,
                color: colors.grey_02,
                fontSize: '12px',
                fontWeight: '500',
                marginTop: '0px',
              }}
            >
              Please check back later for updated data
            </p>
          </div>
        )}
        <ResponsiveContainer width="100%" height={width && width < 1000 ? 250 : 300}>
          <RadarChart outerRadius={outerRadius ? outerRadius : '62%'} data={data}>
            <defs>
              <linearGradient id="Gradient1">
                <stop id="stop1" offset="0%" stopColor="#AE94FF" />
                <stop id="stop2" offset="100%" stopColor="#99C0F8" />
              </linearGradient>
            </defs>
            <PolarGrid
              gridType={'circle'}
              stroke={colors.grey_00}
              strokeOpacity={1}
              strokeWidth={0.5}
              polarRadius={ticks ? ticks : ['10', '20', '30', '40', '50', '60', '70', '80', '90']}
            />
            <PolarAngleAxis
              dataKey="name"
              style={{ fontSize: 11, width: 50, letterSpacing: '-1' }}
              //@ts-ignore
              tick={(props: any) => {
                return (
                  props.payload.value[0] !== '~' && (
                    <>
                      <circle cx={props.x} cy={props.y} r="1.5px" fill={colors.grey_03} />
                      <DetailedTooltip
                        bodyText={
                          labelDetails?.[props?.payload?.value]
                            ? labelDetails?.[props?.payload?.value]
                            : props.payload.value
                        }
                      >
                        <ChartLabels
                          //@ts-ignore
                          payload={props.payload}
                          x={props.x + 6 * Math.cos(props.payload.coordinate * 0.0174533)}
                          y={props.y - 10 * Math.sin(props.payload.coordinate * 0.0174533)}
                          textAnchor={props.textAnchor}
                          dominantBaseline={'middle'}
                          style={{
                            animation: `${fade} 1s infinite ease`,
                            fontSize: '10px',
                            ...typography.mainFont,
                            fontWeight: '400',
                            color: colors.grey_03,
                            border: `1px solid ${colors.grey_03}`,
                            //opacity: '60%',
                            textAlign: 'center',
                            pointerEvents: 'stroke',
                          }}
                        >
                          {props.payload.value}
                        </ChartLabels>
                      </DetailedTooltip>
                    </>
                  )
                );
              }}
            />
            <PolarRadiusAxis style={{ fontSize: 11 }} domain={[0, 1]} />
            {radarData.map((item: any, index: number) => (
              <Radar
                key={index}
                name={item.name}
                dataKey={item.dataKey}
                stroke={item.stroke}
                fill={item.fill}
                fillOpacity={item.fillOpacity}
              />
            ))}
            <circle
              cx={'50%'}
              cy={'50%'}
              r={ticks ? ticks[ticks.length - 1] : '90'}
              strokeOpacity={0.3}
              stroke={colors.grey_03}
              fillOpacity={0}
              strokeWidth={1}
            />
          </RadarChart>
        </ResponsiveContainer>
      </div>
      <div className={classes.labelContainer} style={{ ...labelContainer }}>
        {radarData.map((item: any, index: number) => (
          <div key={index} className={classes.labelRow}>
            <svg height={'20px'} width={'10px'}>
              <circle cx={'50%'} cy={'50%'} r={'5'} fill={item.fill} fillOpacity={item.fillOpacity} />
            </svg>
            <F4 className={classes.labelContent}>{item.label}</F4>
          </div>
        ))}
      </div>
    </>
  );
};

export default GeneralRadar;
