import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { H2, F3 } from '..';
import { colors } from '../../styles';
import Loader from '../Loader';

const TextContainer = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  // height: 120,
  // margin: '80px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  setModalPage?: any;
  pageCopy?: any;
};

const defaultPageCopy = {
  header: 'Uploading Advisors',
  // subheader: <span>This may take a few moments...</span>,
  loader: true,
};

const Processing = ({ setModalPage, pageCopy }: Props) => {
  pageCopy = { ...defaultPageCopy, ...pageCopy };
  return (
    <TextContainer>
      <H2 style={{ color: colors.black, margin: '0px 0px 10px' }}>
        {pageCopy?.header ? pageCopy?.header : defaultPageCopy?.header}
      </H2>
      {pageCopy?.subheader && (
        <F3 style={{ color: colors.grey_02, margin: '20px 0px 0px', width: '400px', textAlign: 'center' }}>
          {pageCopy?.subheader}
        </F3>
      )}
      {pageCopy?.loader && <Loader />}
    </TextContainer>
  );
};

export default Processing;
