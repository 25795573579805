import React, { useState } from 'react';
import { colors } from '../styles';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { H2, F3, ModalContainer, ModalRoot, ModalHeader, ModalFooter, PrimaryButtonShort } from '.';
import Loader from './Loader';

export const MainTextInput = styled(TextField)({
  margin: '12px 0',
  width: '100%',
  '& label.Mui-focused': {
    color: colors.grey_02,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.grey_02,
      border: `1px solid ${colors.grey_02}`,
    },
  },
});

export const ModalBody = styled('div')({
  display: 'flex',
  flex: 1,
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '20px 32px',
});

interface Props {
  modalStatus: boolean;
  copy: any;
  closeModal: any;
  action: any;
  loading?: boolean;
}

const ConfirmationModal = ({ modalStatus, closeModal, copy, action, loading }: Props) => {
  const [emailSent, setEmailSent] = useState<any>(false);

  const exit = () => {
    setEmailSent(false);
    closeModal();
  };

  return (
    <ModalRoot
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer sx={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '16px' }}>
          <H2>{copy?.title}</H2>
        </ModalHeader>
        <ModalBody style={{ width: '100%' }}>
          <div style={{}}>
            <H2>{copy?.header}</H2>
            <F3 sx={{ color: colors.grey_02 }}>{copy?.body}</F3>
          </div>
        </ModalBody>
        <ModalFooter style={{ width: '100%', padding: '10px 16px 8px' }}>
          <PrimaryButtonShort
            onClick={exit}
            style={{ background: colors.grey_02, border: `1px solid ${colors.grey_02}`, margin: 'auto 4px auto 0px' }}
          >
            Cancel
          </PrimaryButtonShort>
          <PrimaryButtonShort onClick={action} style={{ margin: emailSent ? 'auto 0px' : 'auto 0px auto 4px' }}>
            {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Confirm'}
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default ConfirmationModal;
