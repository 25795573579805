import gql from 'graphql-tag';
import { apolloClient } from '../';

export const GET_LEAD_POOL = gql(`
  query getLeadPool($filters: [LeadFilter]) {
    investorLeads {
      poolSize(filters: $filters)
    }
  }
`);

export const GET_INDIVIDUAL_LEADS_ARCHIVE = gql(`
  query GetIndividualLeadsArchive(
          $pageSize: Int!
          $page: Int
          $query: String
          $sortKey: String
          $filters: [FilterType]
        ) {
          investorLeads {
            quotaUsed
            runs(pageSize: $pageSize, page: $page, query: $query, sortKey: $sortKey, filters: $filters, showAll: true) {
              page
              pages
              totalItems
              list {
                id
                dateRun
                customName
                nurturecampaignSet {
                  id
                  name
                  description
                  dateCreated
                  nurtureemailSet {
                    dateCreated
                    description
                    html
                    id
                    name
                    sendDatetime
                    status
                    sendProvider
                  }
                }
                package
                serveCount
                investorleadsrunparamsSet {
                  id
                  filters
                }
              }
            }
          }
        }
`);

export const GET_INDIVIDUAL_LEADS_RESULTS = gql(`
  query GetIndividualResults(
            $filters: [LeadFilter]
            $runId: ID!
            $fundId: ID!
            $page: Int
            $pageSize: Int
            $sortKey: String
            $query: String
          ) {
            investorLeads {
              results(filters: $filters, runId: $runId) {
                investors(fundId: $fundId, page: $page, pageSize: $pageSize, sortKey: $sortKey, query: $query) {
                  hasNext
                  hasPrev
                  page
                  pages
                  totalItems
                  list {
                    id
                    fullName
                    firstName
                    lastName
                    age
                    state
                    income
                    fundId
                    estimatedInvestableAssets
                    percentOfHomeValuePaidOff
                    proxyCreditScore
                    projectedRetirementAssets
                    latestScore
                    wantsMeeting
                    financialPlanningTendencyScore
                    themes
                    events {
                      id
                      eventType
                      provider
                      organization
                      eventId
                      timeStamp
                    }
                    score
                    email
                  }
                }
                themesRadarChart(fundId: $fundId)
              }
            }
          }`);

export const UPDATE_ALGO_SETTINGS = gql(`
  mutation updateAlgoSettings($trainingFrequency: String, $optimizationObjective: String) {
    updateAlgoSettings(
      packageName: "INVESTOR_LEADS"
      trainingFrequency: $trainingFrequency
      optimizationObjective: $optimizationObjective
    ) {
      success
    }
  }
`);

export const GET_ALGO_SETTINGS = gql(`
 query getAlgoSettings {
    investorLeads {
      algoSettings {
        id
        trainingFrequency
        optimizationObjective
        retrainings {
          id
          version
          company
          package
          dateCreated
          dateTrained
          runCount
          leadCount
        }
      }
    }
  }
`);

export const GENERATE_INDIVIDUAL_LEADS = gql(`
mutation GenerateLeads($taggedFunds: [ID]!, $featuresToExclude: [String], $filters: [LeadFilter]) {
          investorLeadsGenerateResults(
            taggedFunds: $taggedFunds
            featuresToExclude: $featuresToExclude
            filters: $filters
          ) {
            runId
          }
        }
`);

export const GET_RUN_INDIVIDUALS = gql(`
query GetRunIndividualResults($filters: [LeadFilter], $runId: ID!) {
            investorLeads {
              results(runId: $runId, filters: $filters) {
                allInvestors {
                  age
                  date
                  email
                  estimatedInvestableAssets
                  financialPlanningTendencyScore
                  fullName
                  firstName
                  lastName
                  fundId
                  hashedEmail
                  homeValue
                  latestScore
                  wantsMeeting
                  id
                  income
                  percentOfHomeValuePaidOff
                  projectedRetirementAssets
                  proxyCreditScore
                  themes
                  events {
                    id
                    eventType
                    provider
                    organization
                    eventId
                    timeStamp
                  }
                  score
                  state
                }
              }
            }
          }
`);

// class InvestorLeadsAPI {
// async getAllInvestors(filters: any, runId: number) {
//   const response = await apolloClient.query({
//     query: gql`
//       query GetAllInvestors($filters: [LeadFilter], $runId: ID!) {
//         investorLeads {
//           results(filters: $filters, runId: $runId) {
//             allInvestors {
//               age
//               date
//               email
//               estimatedInvestableAssets
//               financialPlanningTendencyScore
//               fullName
//               firstName
//               lastName
//               fundId
//               hashedEmail
//               homeValue
//               latestScore
//               wantsMeeting
//               id
//               income
//               percentOfHomeValuePaidOff
//               projectedRetirementAssets
//               proxyCreditScore
//               themes
//               score
//               state
//             }
//           }
//         }
//       }
//     `,
//     variables: {
//       filters,
//       runId,
//     },
//     fetchPolicy: 'network-only',
//   });
//   return response.data.investorLeads.getAllInvestors;
// }
// async getRadarChartData(investorId: string, runId?: string) {
//   const response = await apolloClient.query({
//     query: gql`
//       query GetResultsForFund2($investorId: ID!, $runId: ID!) {
//         investorLeads {
//           results(runId: $runId) {
//             investorAiqRadarChart(investorId: $investorId)
//           }
//         }
//       }
//     `,
//     variables: {
//       investorId,
//       runId,
//     },
//     fetchPolicy: 'network-only',
//   });
//   return response.data.investorLeads.results.investorAiqRadarChart;
// }
// async generateLeads(taggedFunds: any, featuresToExclude: any, filters: any) {
//   const response = await apolloClient.mutate({
//     mutation: gql`
//       mutation GenerateLeads($taggedFunds: [ID]!, $featuresToExclude: [String], $filters: [LeadFilter]) {
//         investorLeadsGenerateResults(
//           taggedFunds: $taggedFunds
//           featuresToExclude: $featuresToExclude
//           filters: $filters
//         ) {
//           runId
//         }
//       }
//     `,
//     variables: {
//       taggedFunds,
//       featuresToExclude,
//       filters,
//     },
//   });
//   return response.data.investorLeadsGenerateResults;
// }
// }

// export default new InvestorLeadsAPI();
