import React from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, F4, H3, H4 } from '..';
import { colors } from '../../styles';
import { formatPackageName, formatTimestamp } from '../../helpers/dataFormatter';
import logoMapping from '../../assets/visuals/logoMapping';
import ProgressBar from '../ProgressBar';

import { GET_SETS_FOR_INTEGRATION } from '../../api/integrations';
import moment from 'moment';
import { useQuery } from '@apollo/client';

const Container = styled('div')({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
  width: '100%',
  margin: '18px 8 px 0px',
  alignItems: 'flex-start',
  flexDirection: 'column',
});

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 30,
  margin: '10px 0px 0px',
  alignItems: 'flex-start',
  flexDirection: 'column',
  borderBottom: `1px solid ${colors.grey_01}`,
  width: '100%',
});

const HalfContainer = styled(Row)({
  width: '100%',
  backgroundColor: colors.white,
});

const ActionText = styled(F4)({
  color: colors.grey_02,
  margin: '0px 0px 0px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8,
  },
});

const BlurContainer = styled('div')({
  position: 'absolute',
  zIndex: 1000,
  display: 'flex',
  flex: 1,
  alignItems: 'flex-start',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '20px 0px 20px 0px',
  width: 340,
  backgroundColor: colors.white_00,
  backdropFilter: 'blur(35px)',
  opacity: 0.9,
});

const TableRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 0px',
  borderBottom: `1px solid ${colors.grey_01}`,
});

const QuarterContainer = styled(Column)({
  flex: 1,
});

type Props = {
  setModalPage: any;
  integrationDetails: any;
  integrationJob: any;
};

const CrmStatus = ({ setModalPage, integrationDetails, integrationJob }: Props) => {
  const [integrationImports, setIntegrationImports] = React.useState<any>([]);
  const [integrationExports, setIntegrationExports] = React.useState<any>([]);

  const pageCopy = {
    header: 'Integration Configuration',
    subheader: 'Select your CRM to automatically populate your advisor data',
  };

  useQuery(GET_SETS_FOR_INTEGRATION, {
    variables: { integrationId: Number(integrationDetails?.id) },
    onCompleted: (data) => {
      const newAdvisorSets = data?.advisorSets ?? [];
      setIntegrationImports(
        [...newAdvisorSets]?.sort((a: any, b: any) => moment(a?.dateCreated).diff(moment(b?.dateCreated))),
      );
      setIntegrationExports(data?.runHistoryExportJobs?.list);
    },
  });

  return (
    <Container>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
      </TextContainer>
      <HalfContainer style={{ borderBottom: `1px solid ${colors.grey_01}` }}>
        <QuarterContainer style={{ maxHeight: '160px', padding: '0px 16px 0px 0px' }}>
          <Row style={{ padding: '16px 0px' }}>
            <Column
              style={{
                padding: '12px 0px',
                margin: '0px 8px 0px 0px',
                width: '100px',
                height: '80px',
                borderRadius: '5px',
                boxShadow: colors.main_box_shadow,
              }}
            >
              <img
                style={{
                  height: '80px',
                  width: '100px',
                  objectFit: 'contain',
                  margin: '0px 0px 0px 0px',
                  padding: '0px 12px',
                }}
                src={logoMapping?.[integrationDetails?.type]}
                onClick={() => {}}
                alt={integrationDetails?.type}
              />
            </Column>
            <Column style={{ margin: '6px 0px 0px' }}>
              <F4 style={{ color: colors.grey_02 }}>CRM Connection</F4>
              <H3
                style={{
                  color: colors.grey_03,
                  margin: '0px 0px 0px',
                  width: '190px ',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {integrationDetails?.nickname}
              </H3>
              <Row style={{ height: '10px', margin: '2px 0px 8px' }}>
                <div
                  style={{
                    backgroundColor: colors.green,
                    margin: '5px 4px 0px 0px',
                    height: '7px',
                    width: '7px',
                    borderRadius: '25px',
                  }}
                />{' '}
                <F4 style={{ color: colors.grey_02, margin: '0px 0px' }}>
                  {integrationJob?.status ? formatPackageName(integrationJob?.status) : 'Active'}
                  <span
                    style={{ cursor: 'pointer', color: colors.grey_03, fontWeight: '500', margin: '0px 6px' }}
                    onClick={() => setModalPage('SetJourneyIntegration')}
                  >
                    Edit Connection
                  </span>
                </F4>
              </Row>
              {integrationJob?.dateUpdated && (
                <F4 style={{ color: colors.grey_02, margin: '2px 0px' }}>
                  Last updated: {integrationJob?.dateUpdated ? formatTimestamp(integrationJob?.dateUpdated) : '-'}
                </F4>
              )}
            </Column>
          </Row>
        </QuarterContainer>
        <QuarterContainer
          style={{ borderLeft: `1px solid ${colors.grey_01}`, padding: '10px 16px', maxHeight: '160px' }}
        >
          <BlurContainer>
            <H2 style={{ backgroundColor: colors.white }}>No Active Jobs</H2>
            <F3 style={{ color: colors.grey_02, margin: '0px 0px 8px' }}>Start a new job to see the progress here</F3>
          </BlurContainer>
          <H2>Active Integration Job</H2>
          <F3 style={{ color: colors.grey_02, margin: '0px 0px 8px' }}>Import Advisor Set</F3>
          <ProgressBar roundCorners percent={0} label={'0%'} height={36} />
        </QuarterContainer>
      </HalfContainer>
      <HalfContainer
        style={{
          flex: 1,
          justifyContent: 'space-between',
        }}
      >
        <QuarterContainer style={{ padding: '10px 16px 10px 0px' }}>
          <TableRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <H2>Recent Imports</H2>
            <ActionText
              style={{ color: colors.grey_02 }}
              onClick={() => {
                integrationDetails?.type === 'HUBSPOT'
                  ? setModalPage('HubspotAdvisorSets')
                  : setModalPage('SalesforceAdvisorSets');
              }}
            >
              New Import
            </ActionText>
          </TableRow>
          {integrationImports?.length > 0 ? (
            integrationImports?.slice(0, 5)?.map((importItem: any, index: number) => {
              return (
                <TableRow key={index + 21481024}>
                  <H3 style={{ color: colors.grey_03, margin: '0px 0px 0px' }}>{importItem?.name}</H3>
                  <F3 style={{ color: colors.grey_02, margin: '0px 0px 0px' }}>
                    {moment(importItem?.dateCreated).format('MM/DD/YY hh:mm A')}
                  </F3>
                </TableRow>
              );
            })
          ) : (
            <TableRow key={12048240}>
              <H4>No Imports Found</H4>
            </TableRow>
          )}
        </QuarterContainer>
        <QuarterContainer style={{ padding: '10px 16px', borderLeft: `1px solid ${colors.grey_01}` }}>
          <TableRow style={{ justifyContent: 'space-between', alignItems: 'flex-end' }} key={12481942}>
            <H2>Recent Exports</H2>
            <ActionText
              style={{ color: colors.grey_02 }}
              onClick={() =>
                integrationDetails?.type === 'HUBSPOT'
                  ? setModalPage('HsCrmIntegrationConfig')
                  : setModalPage('SfCrmIntegrationConfig')
              }
            >
              Edit export configuration
            </ActionText>
          </TableRow>
          {integrationExports?.length > 0 ? (
            integrationExports?.slice(0, 5)?.map((exportItem: any, index: number) => {
              return (
                <TableRow key={index + 12249012}>
                  <H3 style={{ color: colors.grey_03, margin: '0px 0px 0px' }}>{exportItem?.runHistory?.customName}</H3>
                  <F3 style={{ color: colors.grey_02, margin: '0px 0px 0px' }}>
                    {moment(exportItem?.dateCreated).format('MM/DD/YY hh:mm A')}
                  </F3>
                </TableRow>
              );
            })
          ) : (
            <TableRow key={129340038}>
              <H4 style={{ color: colors.grey_02 }}>No Exports Found</H4>
            </TableRow>
          )}
        </QuarterContainer>
      </HalfContainer>
    </Container>
  );
};

export default CrmStatus;
