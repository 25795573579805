import React, { useMemo, useState } from 'react';
import { colors } from '../../styles';
import { Row, F4, Column, H2, F3 } from '..';
import SimpleTable from '../SimpleTable';
import { formatDate, unformatCamelCase, unformatSnakeCase } from '../../helpers/dataFormatter';
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  FilterFn,
  ColumnDef,
  ColumnFiltersState,
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';

type Props = { data: any };

type EngagementType = {
  timestamp: string;
  category: string;
  title: string;
  metaData: any;
};

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);
  // Store the itemRank info
  addMeta({
    itemRank,
  });
  // Return if the item should be filtered in/out
  return itemRank.passed;
};

export const Engagement = ({ data }: Props) => {
  const dataAvailable = data?.tableData?.datapoints?.length > 0 || data?.tableData?.length > 0;
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns = useMemo<ColumnDef<EngagementType>[]>(
    () => [
      {
        accessorKey: 'timestamp',
        header: 'Date',
        cell: (info: any) => {
          return formatDate(info?.getValue(), true);
        },
      },
      {
        accessorKey: 'category',
        header: 'Type',
        cell: (info: any) => {
          const engagementType = info?.getValue();
          return engagementType ? unformatSnakeCase(engagementType) : '-';
        },
      },
      {
        accessorKey: 'title',
        header: 'Title',
        cell: (info: any) => {
          return (
            <span style={{ display: 'block', fontSize: '12px', width: '160px', color: colors.grey_02 }}>
              {info?.getValue()}
            </span>
          );
        },
      },
      {
        accessorKey: 'metadata',
        header: 'Activity Logged',
        cell: (info: any) => {
          const metaData = info?.getValue() ?? {};
          const metaArray = Object.entries(metaData);
          return (
            <span style={{ display: 'block', fontSize: '12px', width: '160px', color: colors.grey_02 }}>
              {metaArray
                .map((innerArray: any) => {
                  if (innerArray[0] === 'eventCount') {
                    return null;
                  } else if (innerArray[1] === 1) {
                    return innerArray[1] + ' ' + unformatCamelCase(innerArray[0]);
                  } else if (innerArray?.[1] > 1) {
                    return (innerArray[1] + ' ' + unformatCamelCase(innerArray[0])).replace('Email', 'Emails');
                  } else if (innerArray[1] === 0) {
                    return null;
                  } else {
                    return innerArray[1];
                  }
                })
                ?.filter((Boolean) => {
                  return Boolean;
                })
                ?.join(', ')}
            </span>
          );
        },
      },
    ],
    [],
  );

  const dataForTable: EngagementType[] = data?.tableData?.datapoints ?? data?.tableData;
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = React.useState('');

  const engagementTable = useReactTable({
    columns,
    data: dataForTable ?? [],
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableSorting: true,
  });

  return !dataAvailable ? (
    <Column style={{ padding: '80px 0px', alignItems: 'center' }}>
      <H2>No Data Available</H2>
      <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>Please check back in future for updated data.</F3>
    </Column>
  ) : (
    <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '0px 0px 0px' }}>
      <SimpleTable
        tableData={engagementTable}
        searchBar
        searchBarText={globalFilter}
        setSearchBarText={setGlobalFilter}
      />
    </Row>
  );
};
