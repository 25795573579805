import React, { useEffect, useState } from 'react';
import { Column, Title, H2, H3, H4, Row, F4, PrimaryButtonShort } from '..';
import { colors, typography } from '../../styles';
import { formatNumber, formatPercent, formatTimestamp, formatPackageName } from '../../helpers/dataFormatter';
import { styled } from '@mui/material/styles';
import UpArrowIcon from '../../assets/icons/arrow-black.svg';
import { LeadPoolChart } from '../PackageCustomize/LeadPoolChart';
import { Slider } from '@mui/material';
import RecentEventsModal from './RecentEventsModal';
import logoMapping from '../../assets/visuals/logoMapping';
import { TypedDocumentNode, useQuery } from '@apollo/client';

type Props = {
  influenceData: any;
  integrationDetails: any;
  integrationJob: any;
  setExportModalPage: any;
  recalculateData: any;
  packageName: string;
  packageRunName?: string;
  formattedEvents?: any;
  exportSegment: any;
  getSegment: any;
  GET_SEGMENT_QUERY: any;
  filters: any;
};

const EventCountIndicator = styled('div')({
  height: '36px',
  width: '170px',
  borderRadius: '5px',
  background: colors.gradient_01,
  padding: '6px 0px 6px 10px',
  margin: '0px 0px 20px 0px',
});

const CustomSlider = styled(Slider)({
  height: 2,
  // fontFamily: typography.mainFont,
  width: '100%',
  margin: '0px 20px 16px',
  '& .MuiSlider-thumb': {
    height: 14,
    width: 14,
    backgroundColor: colors.light_purple,
    transition: 'none',
    '&:focus, &:hover, &.Mui-active': {
      backgroundColor: colors.light_purple,
    },
  },
  '& .MuiSlider-rail': {
    backgroundColor: colors.grey_03,
  },
  '& .MuiSlider-track': {
    backgroundColor: colors.light_purple,
    border: `1px solid ${colors.light_purple}`,
  },
  '& .MuiSlider-mark': {
    backgroundColor: colors.grey_02,
  },
  '& .MuiSlider-markLabel': {
    ...typography.mainFont,
    fontSize: '11px',
    lineHeight: '20px',
    fontWeight: 500,
    color: colors.grey_03,
  },
});

export const RecentEventsCard = ({
  influenceData,
  integrationDetails,
  integrationJob,
  packageName,
  packageRunName,
  GET_SEGMENT_QUERY,
  exportSegment,
  setExportModalPage,
  getSegment,
  filters,
}: Props) => {
  const [sliderRange, setSliderRange] = useState<number[]>([0, 100]);
  const [showEventsModal, setShowEventsModal] = useState<boolean>(false);
  const [advisorTotal, setAdvisorTotal] = useState<number>(0);

  const handleChange = (event: Event, newValue: number | number[]) => {
    setSliderRange(newValue as number[]);
  };

  const newFilter = [
    { key: 'score__gte', value: String(sliderRange[0]) },
    { key: 'score__lte', value: String(sliderRange[1]) },
  ];

  const { data: segmentData, loading: segmentLoading } = useQuery(GET_SEGMENT_QUERY, {
    variables: { runId: influenceData?.id, filters: newFilter },
    skip: !influenceData?.id,
    onCompleted: (response) => {
      // console.log('response in get_segment_query: ', response);
      setAdvisorTotal(
        response?.[packageName === 'INFLUENCE' ? 'influence' : 'discoverLeads']?.results?.advisors?.totalItems,
      );
    },
    onError: (error) => {
      console.warn('error getting advisor segment: ', error);
    },
  });

  return (
    <Column
      style={{
        margin: '0px 0px',
        padding: '0px 20px',
        flex: 1,
      }}
    >
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Column style={{ display: 'flex', flex: 1 }}>
          <H3 style={{ color: colors.grey_03, margin: '0px 0px 6px' }}>
            Total Events for <span style={{ color: colors.grey_03, fontWeight: '600' }}>{packageRunName}</span> in Last
            7 Days
          </H3>
          <F4 style={{ color: colors.grey_02, margin: '0px 0px 6px' }}>
            Events include all transaction and behavioral data in the last 7 days.
          </F4>
          <Row
            style={{
              display: 'flex',
              flex: 1,
              height: '36px',
              justifyContent: 'space-between',
            }}
          >
            <EventCountIndicator>
              <H2 style={{ margin: '0px 0px 0px', color: colors.grey_03 }}>
                <span style={{ fontWeight: '900' }}>{formatNumber(influenceData?.eventData?.total_events)}</span>
                {influenceData?.eventData?.percent_delta ? (
                  <>
                    <img
                      src={UpArrowIcon}
                      style={{
                        height: '13px',
                        width: '13px',
                        margin: '0px 6px',
                        transform: true ? 'rotate(0deg)' : 'rotate(180deg)',
                      }}
                      alt={'arrow'}
                    />
                    <span style={{ fontWeight: '400', margin: '0px 6px 0px 0px' }}>
                      ({formatPercent(influenceData?.eventData?.percent_delta)})
                    </span>
                  </>
                ) : null}
              </H2>
            </EventCountIndicator>
            <H4
              style={{
                height: '36px',
                backgroundColor: colors.white,
                color: colors.black,
                border: `0px solid ${colors.black}`,
                margin: '16px 0px 0px 0px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setShowEventsModal(true);
              }}
            >
              See Recent Events
            </H4>
          </Row>
        </Column>
      </Row>
      <Column style={{ borderTop: `1px solid ${colors.grey_01}` }}>
        <Row style={{ justifyContent: 'space-between' }}>
          <H3 style={{ color: colors.grey_03, margin: '12px 0px 8px' }}>Export Advisor Segment</H3>
        </Row>
        <Row
          style={{
            // flex: 1,
            height: '160px',
            justifyContent: 'center',
            position: 'relative',
            margin: '0px 0px 0px',
          }}
        >
          <Row style={{ width: '230px' }}>
            <Column style={{ top: 8, left: 0, position: 'absolute' }}>
              <LeadPoolChart
                label={''}
                hideLabel
                hideSectionLabel
                currentPoolSize={advisorTotal}
                pieChartData={[]}
                totalPoolSize={influenceData?.advisors?.totalItems}
                // activePieSector={60}
                height={130}
                width={260}
                innerRadius={52}
                outerRadius={64}
              />
            </Column>
            <Column
              style={{
                position: 'absolute',
                top: 46,
                left: 42,
                width: '184px',
                // backgroundColor: colors.grey_01,
              }}
            >
              <Title
                style={{
                  fontSize: '32px',
                  margin: '6px 12px 0px 0px',
                  display: 'inline',
                  textAlign: 'center',
                }}
              >
                {advisorTotal ? formatNumber(advisorTotal) : 0}
              </Title>
              <Title
                style={{
                  fontSize: '16px',
                  // width: '218px',
                  margin: '0px 8px 0px 0px',
                  textAlign: 'center',
                }}
              >
                Advisors
              </Title>
              <F4
                style={{
                  color: colors.grey_02,
                  margin: '40px 0px 0px',
                  textAlign: 'center',
                }}
              >
                AMP Score Range {sliderRange[0]}% - {sliderRange[1]}%
              </F4>
            </Column>
          </Row>
          <Column>
            <Column
              style={{
                justifyContent: 'center',
                // flex: 1,
                padding: '12px 0px',
                width: '300px',
                height: '110px',
                borderRadius: '5px',
                boxShadow: colors.main_box_shadow,
              }}
            >
              {integrationDetails ? (
                <Row>
                  <img
                    style={{
                      height: '80px',
                      width: '100px',
                      // backgroundColor: colors.grey_02,
                      objectFit: 'contain',
                      margin: '0px 0px 0px 0px',
                      padding: '0px 12px',
                    }}
                    src={logoMapping?.[integrationDetails?.type]}
                    onClick={() => console.log('Logo Clicked')}
                    alt={integrationDetails?.type}
                  />
                  <Column style={{ margin: '6px 0px 0px' }}>
                    <F4 style={{ color: colors.grey_02 }}>CRM Connection</F4>
                    <H3
                      style={{
                        color: colors.grey_03,
                        margin: '0px 0px 0px',
                        width: '190px ',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {integrationDetails?.nickname}
                    </H3>
                    <Row style={{ height: '10px', margin: '2px 0px 8px' }}>
                      <div
                        style={{
                          backgroundColor:
                            integrationJob?.status === 'FAILED'
                              ? colors.red
                              : integrationJob?.status === 'IN_PROGRESS'
                              ? colors.yellow
                              : colors.green,
                          margin: '5px 4px 0px 0px',
                          height: '7px',
                          width: '7px',
                          borderRadius: '25px',
                        }}
                      />{' '}
                      <F4 style={{ color: colors.grey_02, margin: '0px 0px' }}>
                        {integrationJob?.status ? formatPackageName(integrationJob?.status) : 'Active'}
                        <span
                          style={{ cursor: 'pointer', color: colors.grey_03, fontWeight: '500', margin: '0px 6px' }}
                          onClick={() => setExportModalPage('SetJourneyIntegration')}
                        >
                          Edit Connection
                        </span>
                      </F4>
                    </Row>
                    {integrationJob?.dateUpdated && (
                      <F4
                        style={{
                          color: colors.grey_02,
                          margin: '2px 0px',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '180px',
                        }}
                      >
                        Last updated: {integrationJob?.dateUpdated ? formatTimestamp(integrationJob?.dateUpdated) : '-'}
                      </F4>
                    )}
                  </Column>
                </Row>
              ) : (
                <Column style={{ padding: '0px 16px', alignItems: 'center' }}>
                  <H3 style={{ textAlign: 'center', margin: '0px 0px 12px' }}>Connect a CRM</H3>
                  <PrimaryButtonShort
                    style={{ backgroundColor: colors.white, color: colors.black, width: '100px' }}
                    onClick={() => setExportModalPage('SetJourneyIntegration')}
                  >
                    Link
                  </PrimaryButtonShort>
                </Column>
              )}
            </Column>
            <Row>
              <PrimaryButtonShort
                style={{ width: '300px', margin: '12px 0px 6px' }}
                onClick={() => {
                  const newFilter = [
                    { key: 'score__gte', value: String(sliderRange[0]) },
                    { key: 'score__lte', value: String(sliderRange[1]) },
                  ];
                  exportSegment(newFilter);
                }}
              >
                Export Segment
              </PrimaryButtonShort>
            </Row>
          </Column>
        </Row>
        <Row style={{ margin: '4px 0px 0px' }}>
          <CustomSlider
            // getAriaLabel={() => 'Temperature range'}
            step={5}
            value={sliderRange}
            onChange={handleChange}
            // valueLabelDisplay="auto"
            // getAriaValueText={(value: number) => `${value}%`}
            marks={[
              { value: 0, label: '0%' },
              { value: 20, label: '20%' },
              { value: 40, label: '40%' },
              { value: 60, label: '60%' },
              { value: 80, label: '80%' },
              { value: 100, label: '100%' },
            ]}
          />
        </Row>
      </Column>
      <RecentEventsModal
        showModal={showEventsModal}
        setShowModal={setShowEventsModal}
        data={influenceData?.recentEvents}
      />
    </Column>
  );
};
