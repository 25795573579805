import {
  formatDollar,
  formatTimestamp,
  formatNumber,
  formatAdvisorType,
  formatThousandDollar,
  formatMillionDollar,
  formatPercent,
  formatThemes,
  formatFullName,
} from './dataFormatter';

export const advisorLeadsSpreadsheetHeaders = [
  {
    header: 'First Name',
    key: 'firstName',
  },
  {
    header: 'Last Name',
    key: 'lastName',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Career Tenure',
    key: 'careerTenure',
    format: (data: any) => {
      return formatNumber(data.careerTenure);
    },
  },
  {
    header: 'Advisor CRD',
    key: 'repcrd',
    format: (data: any) => (data.repcrd ? data.repcrd : '-'),
  },
  // {
  //   header: 'Assets Under Management',
  //   format: (data: any) => formatDollar(data.aumSelfReported),
  // },
  {
    header: 'Type (BD/RIA)',
    key: 'type',
    format: (row: any) => formatAdvisorType(row),
  },

  {
    header: 'Firm Name',
    key: 'firmName',
  },
  {
    header: 'Firm CRD',
    key: 'firmCrd',
    format: (data: any) => (data.firmCrd ? data.firmCrd : '-'),
  },
  {
    header: 'Firm Accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(data.firmTotalAccounts);
    },
  },
  {
    header: 'Firm ETF Assets',
    format: (data: any) => formatMillionDollar(data.firmEtfAssetsMillions),
  },
  {
    header: 'Firm MF Assets',
    format: (data: any) => formatMillionDollar(data.firmMfAssetsMillions),
  },
  {
    header: 'Firm ETF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsEtf),
  },
  {
    header: 'Firm MF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsMf),
  },
  {
    header: 'Firm AUM',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(data.firmTotalAssetsInMillions),
  },
  {
    header: 'Office State',
    format: (data: any) => (data?.officeState ? data?.officeState : '-'),
  },
  {
    header: 'Office Zipcode',
    format: (data: any) => (data?.officeZipcode ? data?.officeZipcode : '-'),
  },
  {
    header: 'Intial AMP Score',
    key: 'leadScore',
    format: (data: any) => formatPercent(data.leadScore),
  },
  {
    header: 'Current AMP Score',
    key: 'latestScore',
    format: (data: any) => {
      return formatPercent(data.latestScore ? data.latestScore : data.leadScore);
    },
  },
  // {
  //   header: 'Requested Meeting',
  //   format: (data: any) => (data.wantsMeeting ? 'True' : '-'),
  // },
  {
    header: 'Themes',
    key: 'themes',
    format: (row: any) => formatThemes(row.themes),
  },
];

export const individualSpreadsheetHeader = [
  {
    header: 'first_name',
    key: 'firstName',
  },
  {
    header: 'last_name',
    key: 'lastName',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'age',
    key: 'age',
  },
  {
    header: 'state',
    key: 'state',
  },
  {
    header: 'financial_planning_affinity',
    format: (data: any) =>
      data.financialPlanningTendencyScore ? formatPercent(data.financialPlanningTendencyScore * 100) : '-',
  },
  { header: 'mortgage_paid', format: (data: any) => formatPercent(data.percentOfHomeValuePaidOff) },
  {
    header: 'credit_quality',
    format: (data: any) => formatPercent(data.proxyCreditScore),
  },
  {
    header: 'income',
    key: 'income',
    format: (data: any) => formatThousandDollar(data.income),
  },
  { header: 'wealth', format: (data: any) => formatThousandDollar(data.estimatedInvestableAssets) },

  {
    header: 'initial_amp_score',
    key: 'score',
    format: (data: any) => formatPercent(data.score),
  },
  {
    header: 'current_amp_score',
    key: 'latestScore',
    format: (data: any) => {
      return formatPercent(data.latestScore ? data.latestScore : data.score);
    },
  },
];

export const prioritizeAdvisorsSpreadSheetHeaders = [
  {
    header: 'first_name',
    key: 'firstName',
  },
  {
    header: 'last_name',
    key: 'lastName',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'career_tenure',
    key: 'careerTenure',
  },
  {
    header: 'rep_crd',
    key: 'repcrd',
  },
  {
    header: 'Assets Under Management',
    format: (data: any) => formatDollar(data.aumSelfReported),
  },
  {
    header: 'Type (BD/RIA)',
    key: 'type',
    format: (row: any) => formatAdvisorType(row),
  },
  {
    header: 'Firm Name',
    key: 'firmName',
  },
  {
    header: 'Firm CRD',
    key: 'firmCrd',
  },
  {
    header: 'Firm Accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(data.firmTotalAccounts);
    },
  },
  {
    header: 'Firm ETF Assets',
    format: (data: any) => formatMillionDollar(data.firmEtfAssetsMillions),
  },
  {
    header: 'Firm MF Assets',
    format: (data: any) => formatMillionDollar(data.firmMfAssetsMillions),
  },
  {
    header: 'Firm AUM',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(data.firmTotalAssetsInMillions),
  },
  {
    header: 'AMP Score',
    key: 'score',
    format: (data: any) => formatPercent(data.score),
  },
];

export const AdvisorSetHeaders = [
  {
    header: 'first_name',
    key: 'firstName',
  },
  {
    header: 'last_name',
    key: 'lastName',
  },
  {
    header: 'email',
    key: 'email',
  },
  {
    header: 'career_tenure',
    key: 'careerTenure',
    format: (data: any) => {
      return formatNumber(data?.advisordistilldata?.careerTenure);
    },
  },
  {
    header: 'rep_crd',
    key: 'repcrd',
  },
  {
    header: 'aum_self_reported',
    key: 'aumSelfReported',
    format: (data: any) => {
      return formatNumber(data?.advisordistilldata?.aumSelfReported);
    },
  },

  {
    header: 'firm_name',
    key: 'firmName',
    format: (data: any) => {
      return formatNumber(data?.advisordistilldata?.firmName);
    },
  },
  {
    header: 'firm_crd',
    key: 'firmCrd',
    format: (data: any) => {
      return formatNumber(data?.advisordistilldata?.firmCrd);
    },
  },
  {
    header: 'firm_total_accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(data?.advisordistilldata?.firmTotalAccounts);
    },
  },
  {
    header: 'firm_total_assets_in_millions',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(data?.advisordistilldata?.firmTotalAssetsInMillions),
  },
  {
    header: 'firm_etf_assets',
    format: (data: any) => formatMillionDollar(data?.firmEtfAssetsMillions),
  },
  {
    header: 'firm_mf_assets',
    format: (data: any) => formatMillionDollar(data?.firmMfAssetsMillions),
  },
  {
    header: 'office_state',
    format: (data: any) => (data?.advisordistilldata?.officeState ? data?.advisordistilldata?.officeState : '-'),
  },
  {
    header: 'office_zipcode',
    format: (data: any) => (data?.advisordistilldata?.officeZipcode ? data?.advisordistilldata?.officeZipcode : '-'),
  },
];

export const generateWaitlistSpreadSheetHeaders = [
  {
    header: 'First Name',
    key: 'firstName',
  },
  {
    header: 'Last Name',
    key: 'lastName',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Career Tenure',
    key: 'careerTenure',
    format: (data: any) => {
      return formatNumber(data.careerTenure);
    },
  },
  {
    header: 'Advisor CRD',
    key: 'repcrd',
    format: (data: any) => (data.repcrd ? data.repcrd : '-'),
  },
  {
    header: 'Assets Under Management',
    format: (data: any) => formatDollar(data.aumSelfReported),
  },
  {
    header: 'Type (BD/RIA)',
    key: 'type',
    format: (row: any) => formatAdvisorType(row),
  },

  {
    header: 'Firm Name',
    key: 'firmName',
  },
  {
    header: 'Firm CRD',
    key: 'firmCrd',
    format: (data: any) => (data.firmCrd ? data.firmCrd : '-'),
  },
  {
    header: 'Firm Accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(data.firmTotalAccounts);
    },
  },
  {
    header: 'Firm ETF Assets',
    format: (data: any) => formatMillionDollar(data.firmEtfAssetsMillions),
  },
  {
    header: 'Firm MF Assets',
    format: (data: any) => formatMillionDollar(data.firmMfAssetsMillions),
  },
  {
    header: 'Firm ETF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsEtf),
  },
  {
    header: 'Firm MF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsMf),
  },
  {
    header: 'Firm AUM',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(data.firmTotalAssetsInMillions),
  },
  {
    header: 'Intial AMP Score',
    key: 'leadScore',
    format: (data: any) => formatPercent(data.leadScore),
  },
  {
    header: 'Current AMP Score',
    key: 'latestScore',
    format: (data: any) => {
      return formatPercent(data.latestScore ? data.latestScore : data.leadScore);
    },
  },
  // {
  //   header: 'Requested Meeting',
  //   format: (data: any) => (data.wantsMeeting ? 'True' : '-'),
  // },
  {
    header: 'Themes',
    key: 'themes',
    format: (row: any) => formatThemes(row.themes),
  },
];

export const crossSellSpreadSheetHeaders = [
  {
    header: 'First Name',
    key: 'firstName',
  },
  {
    header: 'Last Name',
    key: 'lastName',
  },
  {
    header: 'Email',
    key: 'email',
  },
  {
    header: 'Career Tenure',
    key: 'careerTenure',
    format: (data: any) => {
      return formatNumber(data.careerTenure);
    },
  },
  {
    header: 'Advisor CRD',
    key: 'repcrd',
    format: (data: any) => (data.repcrd ? data.repcrd : '-'),
  },
  {
    header: 'Assets Under Management',
    format: (data: any) => formatDollar(data.aumSelfReported),
  },
  {
    header: 'Type (BD/RIA)',
    key: 'type',
    format: (row: any) => formatAdvisorType(row),
  },

  {
    header: 'Firm Name',
    key: 'firmName',
  },
  {
    header: 'Firm CRD',
    key: 'firmCrd',
    format: (data: any) => (data.firmCrd ? data.firmCrd : '-'),
  },
  {
    header: 'Firm Accounts',
    key: 'firmTotalAccounts',
    type: 'number',
    format: (data: any) => {
      return formatNumber(data.firmTotalAccounts);
    },
  },
  {
    header: 'Firm ETF Assets',
    format: (data: any) => formatMillionDollar(data.firmEtfAssetsMillions),
  },
  {
    header: 'Firm MF Assets',
    format: (data: any) => formatMillionDollar(data.firmMfAssetsMillions),
  },
  {
    header: 'Firm ETF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsEtf),
  },
  {
    header: 'Firm MF Percent Assets',
    format: (data: any) => formatPercent(data.firmPercentAssetsMf),
  },
  {
    header: 'Firm AUM',
    key: 'firmTotalAssetsInMillions',
    type: 'dollar',
    format: (data: any) => formatMillionDollar(data.firmTotalAssetsInMillions),
  },
  {
    header: 'Intial AMP Score',
    key: 'leadScore',
    format: (data: any) => formatPercent(data.leadScore),
  },
  {
    header: 'Current AMP Score',
    key: 'latestScore',
    format: (data: any) => {
      return formatPercent(data.latestScore ? data.latestScore : data.leadScore);
    },
  },
  // {
  //   header: 'Requested Meeting',
  //   format: (data: any) => (data.wantsMeeting ? 'True' : '-'),
  // },
  {
    header: 'Themes',
    key: 'themes',
    format: (row: any) => formatThemes(row.themes),
  },
];
