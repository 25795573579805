import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../styles';

import downArrowGray from '../../../assets/icons/arrow-down-grey.svg';
import { formatTimestamp, formatPackageName, formatPercent, formatTickers } from '../helpers/dataFormatter';
import moment from 'moment';
import { SectionPageHeader } from './SectionPageHeader';
import EventChart from './EventChart';
import ClickChart from './ClickChart';
import { useNavigate } from 'react-router-dom';
import AmplitudeChart from './AmplitudeChart';
import { CardContainer, CardHeader, H2 } from '.';
import { useStoreState } from '../store';
import { styled } from '@mui/material/styles';

const CardBody = styled('div')({
  padding: '8px 16px',
});

const CampaignInfoP = styled('p')({
  ...typography.mainFont,
  fontSize: '12px',
  fontWeight: '400',
  color: colors.grey_02,
});

const CampaignInfoHeader = styled('h2')({
  ...typography.mainFont,
  fontSize: '14px',
  fontWeight: '600',
  color: colors.grey_03,
});

interface Props {
  campaign?: any;
}

const CampaignPerformanceCard = ({ campaign }: Props) => {
  const demoAccount = useStoreState((state) => state.user.demoAccount);

  return (
    <CardContainer style={{ margin: '16px 8px 0px 0px' }}>
      <CardHeader>
        <span>Most recent campaign email performance</span>
      </CardHeader>
      <CardBody>
        <CampaignInfoHeader>Explore more benefits to Campaigns</CampaignInfoHeader>
        <CampaignInfoP>
          Campaign {`• `}
          {campaign?.recipientCount === 1
            ? `${campaign?.recipientCount} Recipient`
            : `${campaign?.recipientCount ? campaign?.recipientCount : 0} Recipients`}
          {` •`} {moment(campaign?.dateCreated).format('MM.DD.YYYY')}
        </CampaignInfoP>
        <ClickChart
          stats={[
            // { label: 'Open rate', value: formatPercent(campaign?.openRate) },
            {
              label: 'Open rate',
              value: formatPercent(campaign?.openRate ? campaign.openRate * 100 : demoAccount ? 37.8 : 0, 1),
            },
            { label: 'Industry average', value: '20.5%' },
          ]}
        />
        <div style={{ margin: '10px' }} />
        <ClickChart
          stats={[
            // { label: 'Click through rate', value: formatPercent(campaign?.clickThroughRate) },
            {
              label: 'Click through rate',
              value: formatPercent(
                campaign?.clickThroughRate ? campaign.clickThroughRate * 100 : demoAccount ? 16.2 : 0,
                1,
              ),
            },
            { label: 'Industry average', value: '2.4%' },
          ]}
        />
      </CardBody>
    </CardContainer>
  );
};

export default CampaignPerformanceCard;
