import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Row, Column, H2, F3, H3, PrimaryButton } from '..';
import { GET_INTEGRATIONS } from '../../api/integrations';
import { useQuery } from '@apollo/client';
import { colors } from '../../styles';
import moment from 'moment';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '30px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

const TableRow = styled('div')({
  width: '480px',
  display: 'flex',
  height: '46px',
  padding: '0px 12px',
  alignItems: 'center',
  cursor: 'pointer',
  borderBottom: `1px solid ${colors.grey_01}`,
  ':hover': {
    background: colors.grey_01,
  },
});

type Props = {
  pullHubspotFields: any;
  setIntegrationId: any;
  setIntegrationDetails: any;
  setModalPage: any;
};

const HubSpot = ({ pullHubspotFields, setIntegrationId, setModalPage, setIntegrationDetails }: Props) => {
  const [integrationsList, setIntegrationList] = useState<any[]>([]);
  const [listSize, setListSize] = useState<number>(1);

  const { loading } = useQuery(GET_INTEGRATIONS, {
    onCompleted: (data) => {
      const hubspotIntegrations = data?.integrations?.filter((integration: any) => integration?.type === 'HUBSPOT');
      setIntegrationList(hubspotIntegrations ?? []);
    },
  });

  const pageCopy = {
    header: 'HubSpot Integrations',
    subheader: (
      <span>
        Here we have listed the hubspot instances that you have previously connected. You may click on an existing
        instance to intiate a new data pull.
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 40px' }}>
          {pageCopy.subheader}
        </F3>
      </TextContainer>
      <Row style={{ margin: '0px 0px 6px' }}>
        <H3 style={{ width: '300px', margin: '0px 10px', color: colors.grey_02 }}>Nickname</H3>
        <H3 style={{ color: colors.grey_02 }}>Date Created</H3>
      </Row>
      <Column
        style={{
          maxHeight: '320px',
          borderTop: `1px solid ${colors.grey_01}`,
          borderBottom: `1px solid ${colors.grey_01}`,
          overflowY: 'scroll',
        }}
      >
        {integrationsList
          ?.slice(-5 * listSize)
          .reverse()
          ?.map((hsConnection?: any) => (
            <TableRow
              style={{
                justifyContent: 'space-between',
              }}
              onClick={() => {
                setIntegrationId(hsConnection?.id);
                setIntegrationDetails(hsConnection);
                setModalPage('CrmStatus');
              }}
            >
              <H3>{hsConnection?.nickname}</H3>
              <F3 style={{ color: colors.grey_02 }}>{moment(hsConnection?.dateCreated).format('M/DD/YYYY - LT')}</F3>
            </TableRow>
          ))}
        <TableRow onClick={() => setListSize(listSize + 1)}>
          <F3 style={{ color: colors.grey_02 }}>Show More...</F3>
        </TableRow>
      </Column>
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrimaryButton style={{ width: '180px' }} onClick={() => setModalPage('HubspotNewIntegration')}>
          Create New Integration
        </PrimaryButton>
      </Row>
    </div>
  );
};

export default HubSpot;
