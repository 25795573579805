import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../styles';
import ContentModels from './ContentModels';
import ComingSoonModal from '../ComingSoonModal';
import { DistillScoreLogo } from '../DistillScoreLogo';
import {
  formatThemesArray,
  formatFirmName,
  formatEventOrganization,
  eventOrganizationColors,
  formatDollar,
  formatEventName,
  formatDistillScore,
  formatFullName,
} from '../../helpers/dataFormatter';
import ScrollingDiv from '../ScrollingDiv';
import { H1, F3 } from '..';
import rightArrow from '../../assets/icons/arrow-right.svg';
import leftArrow from '../../assets/icons/arrow-left.svg';
import ArrowGrey from '../../assets/icons/arrow-grey.svg';
import referralsGray from '../../assets/icons/referrals-grey.svg';
import { useStoreState } from '../../store';

const useStyles = makeStyles((theme) => ({
  emailContainer: {
    display: 'flex',
    margin: '10px auto 0',
    width: '80%',
    padding: '0 6%',
    height: 40,
    borderRadius: 20,
    background: '#5B8CFD',
    border: `1px solid #5B8CFD`,
    color: 'white',
    fontWeight: 400,
    fontSize: 14,
    textDecoration: 'none',
    '&:hover': {
      background: colors.black_01,
      color: 'white',
      border: `1px solid ${colors.black_01}`,
    },
  },
  clientCardStyle: {
    width: '100%',
    display: 'flex',
    minWidth: '300px',
    padding: '20px 0px 16px',
    color: 'black',
    textAlign: 'center',
    background: '#fff',
    boxShadow: colors.box_shadow_override,
  },
  innerClientCard: {
    display: 'table',
    width: '100%',
    //minHeight: 310,
    padding: '0px 0px',
    margin: '0px 0px 10px',
  },
  cardRow: {
    display: 'flex',
    margin: 0,
    color: '#758790',
    justifyContent: 'space-between',
    padding: '3px 15px 0',
    height: 30,
  },
  cardHeader: {
    display: 'flex',
    width: '100%',
    margin: 0,
    color: '#758790',
    padding: '0 0 8px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  rowTitle: {
    margin: '10px 0px 0px',
    fontSize: 14,
    color: colors.grey_02,
    whiteSpace: 'nowrap',
    minWidth: '70px',
    textAlign: 'left',
    // [theme.breakpoints.down(1200)]: {
    //   fontSize: 14,
    // },
    // [theme.breakpoints.down(1100)]: {
    //   letterSpacing: -1,
    // },
  },
  rowValue: {
    margin: '8px 0px 0px',
    fontSize: 14,
    color: colors.grey_02,
    whiteSpace: 'nowrap',
    // [theme.breakpoints.down(1200)]: {
    //   fontSize: 14,
    // },
    maxWidth: '200px',
    overflowX: 'hidden',
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowHeader: {
    margin: '5px 0',
    color: '#404B50',
    fontWeight: 500,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    ...typography.mainFont,
    fontSize: '14px',
    padding: '0 15px',
    lineHeight: 1,
    // [theme.breakpoints.up('lg')]: {
    //   fontSize: '14px',
    //   letterSpacing: 0,
    // },
  },
  rowSubheader: {
    margin: '2px 0 0px',
    color: colors.grey_02,
    fontWeight: 400,
    textAlign: 'left',
    overflow: 'wrap',
    width: '95%',
    minHeight: 29,
    ...typography.mainFont,
    fontSize: '14px',
    padding: '0 15px',
    lineHeight: 1,
    // [theme.breakpoints.up('lg')]: {
    //   fontSize: '14px',
    //   letterSpacing: 0,
    // },
  },
  rankHelper: {
    color: colors.lilac,
    fontSize: 10,
    margin: '6px 0px 0px 0px',
    textAlign: 'left',
    cursor: 'pointer',
  },
  scoreStyle: {
    fontSize: '36px',
    margin: 0,
    textAlign: 'left',
    fontFamily: 'Inter',
    letterSpacing: '-2px',
    fontWeight: 600,
    marginTop: '4px',
    color: '#404B50',
    padding: '0 15px',
  },
  buttonStyleInverted: {
    display: 'flex',
    padding: '10px 0px',
    marginRight: 20,
    borderRadius: 5,
    height: 30,
    color: colors.black_01,
    background: 'white',
    fontWeight: 400,
    fontSize: 14,
    cursor: 'pointer',
    textTransform: 'none',
    border: `1px solid ${colors.black_01}`,
    '&:hover': {
      background: colors.black_01,
      color: 'white',
      border: `1px solid ${colors.black_01}`,
    },
    // [theme.breakpoints.down(1190)]: {
    //   padding: '3px 0px',
    // },
  },
  smallDistillScore: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    padding: '0 0 5px',
    color: '#404B50',
  },
  scoreLogo: {
    display: 'flex',
    margin: '4px 0px',
    flexDirection: 'column',
    color: '#404B50',
    fontSize: 14,
  },
  modalContainer: {
    width: '40%',
    margin: '160px auto',
    padding: '10px 0',
    background: 'white',
    boxShadow: colors.main_box_shadow,
  },
  detailsButton: {
    display: 'flex',
    padding: '10px 0px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '0 15px',
    borderRadius: 5,
    height: 40,
    background: 'white',
    border: `1px solid ${colors.black_01}`,
    color: colors.black_01,
    fontWeight: 400,
    fontSize: 14,
    cursor: 'pointer',
    lineHeight: 1,
    textDecoration: 'none',
    '&:hover': {
      background: colors.black_01,
      color: colors.white_01,
      border: `1px solid ${colors.black_01}`,
    },
    // [theme.breakpoints.down(1170)]: {
    //   fontSize: 13,
    //   padding: '7px 0px',
    // },
  },
  modalTitle: {
    padding: '90px 0',
    margin: 'auto',
    textAlign: 'center',
  },
  divider: {
    borderTop: '1px solid #e3e5e4',
    margin: '5px 0px',
  },
  innerCardStyle: {
    padding: '10px 0px 40px',
    margin: 0,
    //minHeight: 200,
  },
  activePlatformRow: {
    display: 'flex',
    alignItems: 'space-between',
    height: '34px',
    maxWidth: '330px',
    overflowX: 'scroll',
    marginBottom: '24px',
    '&::-webkit-scrollbar': {
      height: '0px',
    },
  },
  platformIndicator: {
    display: 'flex',
    //height: '25px',
    alignItems: 'center',
    padding: '5px 14px',
    borderRadius: '5px',
    margin: '3px',
    backgroundColor: colors.grey_06,
    whiteSpace: 'nowrap',
    cursor: 'default',
  },
  platformText: {
    whiteSpace: 'nowrap',
    color: colors.grey_02,
    fontWeight: 400,
    fontSize: 14,
  },
  rightArrowStyle: {
    margin: 'auto 10px auto 10px',
    cursor: 'pointer',
    width: 8,
  },
  scoreText: {
    ...typography.mainFont,
    fontSize: '14px',
    color: colors.grey_02,
  },
  scoreHeader: {
    ...typography.mainFont,
    fontSize: '24px',
    fontWeight: '500',
    color: colors.black_01,
    padding: '0 15px',
    margin: '6px 0px 0px 0px',
  },
  lifeStageIcon: {
    height: 40,
  },
  lifeStageName: {
    fontSize: 14,
    fontWeight: 500,
    color: '#404B50',
    margin: '14px 14px 0',
  },
  referralToggle: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
    margin: '16px auto 0',
  },
  referralText: {
    fontSize: 14,
    fontWeight: 500,
    color: '#758790',
    margin: '0 5px',
  },
  referralIcon: {
    height: 16,
    width: 16,
  },
  arrowIcons: {
    height: 12,
    width: 12,
    margin: '2px 4px 0',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  referredContainer: {
    display: 'flex',
    justifyContent: 'start',
    margin: '5px 15px',
    width: 'calc(100% - 30px)',
    padding: '0 0px 15px',
    borderBottom: '1px solid #ececec',
  },
  faceIcon: {
    width: 36,
    height: 36,
    margin: '3px 6px 0 8px',
    borderRadius: '50%',
  },
  nameHeader: {
    margin: '10px 0 -3px',
    color: '#404B50',
    fontWeight: 500,
    textAlign: 'left',
    ...typography.mainFont,
    fontSize: 14,
    lineHeight: 1,
  },
  referralProbs: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 15px',
    margin: '10px auto -10px',
  },
  referralTypeBox: {
    background: '#E7EDF5',
    color: '#758790',
    fontSize: 14,
    fontWeight: 400,
    borderRadius: 5,
    padding: '6px 8px',
    width: 'fit-content',
    margin: '12px 0 0',
  },
  mainPageReferrals: {
    display: 'flex',
    marginTop: 5,
    justifyContent: 'space-between',
    borderTop: '1px solid #ececec',
    padding: '15px 15px 0',
    fontWeight: 400,
    marginBottom: -10,
  },
}));

interface Props {
  packageName: string;
  client: any;
  expandCard: any;
  contentType: any;
  textData: any;
  empty: any;
  id: string;
  key: string;
  index: number;
  cardType: any;
  customScoreTitle: any;
  CustomScoreComponent: any;
}

const GeneralCard = ({
  packageName,
  client,
  expandCard,
  contentType,
  textData,
  empty,
  id,
  key,
  index,
  cardType,
  customScoreTitle,
  CustomScoreComponent,
}: Props) => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [scrollingDivWidth, setScrollingDivWidth] = useState(300);
  const [referralIndices, setReferralIndices] = useState<any>({ 0: 0, 1: 0, 2: 0 });

  const appType = useStoreState((state) => state.appType);

  const cardParams = useRef<any>();

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  useEffect(() => {
    setScrollingDivWidth(cardParams.current.clientWidth - 44);
  }, [cardParams.current]);

  const classes = useStyles();

  const cardEvents = client.events ? client.events : [];

  const cardThemes = client.topThemes
    ? formatThemesArray(client.topThemes)
    : client.themes
    ? formatThemesArray(client.themes)
    : [];

  const generalView = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              padding: '4px 15px 6px',
              //margin: 0,
              display: 'flex',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {/* <h2 className={classes.smallDistillScore}>AMP Score</h2> */}
            <div className={classes.scoreLogo}>
              {client.matchScore ? (
                'Match Score'
              ) : customScoreTitle ? (
                customScoreTitle
              ) : (
                <DistillScoreLogo packageName={packageName} style={{ height: '14px', width: '82px' }} />
              )}
              {textData && (textData.metricName || textData.topTitle) && (
                <p className={classes.rankHelper}>{textData.topTitle}</p>
              )}
            </div>
            {/* <p className={classes.rankHelper} onClick={()=>setModalOpen(true)}>What's this?</p> */}
            <H1 className={classes.scoreStyle}>
              {formatDistillScore(
                textData && textData.metricName
                  ? Math.round(client[textData.metricName])
                  : client.leadScore
                  ? client.leadScore
                  : client.score,
              )}
              <p style={{ fontSize: 18, display: 'inline' }}>%</p>
            </H1>
          </div>
        </div>
      </>
    );
  };

  const nurtureView = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              margin: '0px 0px -6px',
              display: 'flex',
              padding: '0 15px',
              flex: 1,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {/* <h2 className={classes.smallDistillScore}>AMP Score</h2> */}
            <div className={classes.scoreLogo}>
              {CustomScoreComponent ? (
                <CustomScoreComponent />
              ) : (
                <DistillScoreLogo packageName={packageName} style={{ height: '11px', margin: '0px 0px 0px -14px' }} />
              )}
            </div>
            {/* <p className={classes.rankHelper} onClick={()=>setModalOpen(true)}>What's this?</p> */}
            <div className={classes.scoreText}>
              <span>
                {client.leadScore ? client.leadScore : client.score ? client.score : '-'}%
                <img
                  src={ArrowGrey}
                  alt="expand"
                  style={{
                    transform: 'rotate(90deg)',
                    transition: '0.3s',
                    height: '8px',
                    margin: '0px 6px 2px',
                  }}
                />
                {formatDistillScore(
                  client.latestScore && (client.latestScore > client.leadScore || client.latestScore > client.score)
                    ? client.latestScore
                    : client.leadScore
                    ? client.leadScore
                    : client.score,
                )}
                %
              </span>
            </div>
          </div>
          {/* <Button className={classes.buttonStyleInverted} children={`How it's calculated`} onClick={expandCard} /> */}
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {/* <h2 className={classes.smallDistillScore}>AMP Score</h2> */}
          <H1
            style={{
              margin: '4px 0px 0px',
              fontSize: '22px',
              letterSpacing: '-1px',
              fontWeight: '600',
            }}
          >
            Improvement
          </H1>
          {/* <p className={classes.rankHelper} onClick={()=>setModalOpen(true)}>What's this?</p> */}
          <div className={classes.scoreStyle}>
            <H1
              style={{
                margin: '4px 3px 0px',
                fontSize: '24px',
                fontWeight: '600',
              }}
            >
              {client.scoreChange > 0 ? '+' + client.scoreChange : 0}
              <span style={{ fontSize: '16px' }}>%</span>
            </H1>
          </div>
        </div>
      </>
    );
  };

  const tradingView = () => {
    const tradingEvents = client?.lplEvents ? client?.lplEvents : [];
    //filter trading events for inflows and outflows based on sign of flow total
    const outflows = tradingEvents
      ?.filter((event: any) => event.flowsTotal < 0)
      ?.reverse()
      ?.slice(0, 10);
    const inflows = tradingEvents?.filter((event: any) => event.flowsTotal > 0)?.slice(0, 10);

    return (
      <div>
        <div className={classes.divider} />
        <div style={{ padding: '0 15px' }}>
          <F3 className={classes.rowTitle} style={{ color: colors.grey_02, margin: '14px 0px 8px 0px' }}>
            Funds with Recent Inflow
          </F3>
          <ScrollingDiv width={cardParams?.current?.clientWidth ? cardParams.current.clientWidth - 26 : null}>
            {inflows?.length > 0 ? (
              inflows.map((transaction: any, index: number) => (
                <div className={classes.platformIndicator} key={index + 309825}>
                  <p className={classes.platformText}>
                    <span style={{ color: colors.grey_02 }}>{transaction?.securityTicker}</span>
                    <span style={{ color: '#8DC63F' }}>
                      {' +'}
                      {formatDollar(transaction?.flowsTotal)}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <div className={classes.platformIndicator} key={569309825}>
                <p className={classes.platformText}>
                  <span style={{ color: eventOrganizationColors['magnifi'] }}>No Trasactions Found</span>
                </p>
              </div>
            )}
          </ScrollingDiv>
        </div>
        <div style={{ padding: '0 15px' }}>
          <F3 className={classes.rowTitle} style={{ color: colors.grey_02, margin: '8px 0px 12px 0px' }}>
            Funds with Recent Outflows
          </F3>
          <ScrollingDiv width={cardParams?.current?.clientWidth ? cardParams?.current?.clientWidth - 26 : null}>
            {outflows?.length > 0 ? (
              outflows.map((transaction: any, index: number) => (
                <div className={classes.platformIndicator} key={index + 309825}>
                  <p className={classes.platformText}>
                    <span style={{ color: colors.grey_02 }}>{transaction?.securityTicker}</span>
                    <span style={{ color: colors.red }}>
                      {' -'}
                      {formatDollar(Math.abs(transaction?.flowsTotal))}
                    </span>
                  </p>
                </div>
              ))
            ) : (
              <div className={classes.platformIndicator} key={569309825}>
                <p className={classes.platformText}>
                  <span style={{ color: eventOrganizationColors['magnifi'] }}>No Trasactions Found</span>
                </p>
              </div>
            )}
          </ScrollingDiv>
        </div>
      </div>
    );
  };

  const eventsView = () => {
    return (
      <div>
        <div className={classes.divider} />
        <div style={{ padding: '0 15px' }}>
          <F3 className={classes.rowTitle} style={{ color: colors.grey_02, margin: '14px 0px 8px 0px' }}>
            Top Contributions to AMP Score
          </F3>
          <ScrollingDiv width={cardParams?.current?.clientWidth ? cardParams.current.clientWidth - 26 : null}>
            {cardEvents.length > 0 ? (
              cardEvents.map((event: any, index: number) => (
                <div className={classes.platformIndicator} key={index + 309825}>
                  <p className={classes.platformText}>
                    <span style={{ color: eventOrganizationColors[event.organization] }}>
                      {formatEventOrganization(event.organization)}
                    </span>
                    {' - '}
                    {formatEventName(event.eventType)}
                  </p>
                </div>
              ))
            ) : (
              <div className={classes.platformIndicator} key={569309825}>
                <p className={classes.platformText}>
                  {/*This is a temporary fix. Should be replaced with the following asap 
                <div className={classes.platformIndicator} key={569309825 + id}>
                  <p className={classes.platformText}>No events found</p>
                </div>
                  */}
                  <span style={{ color: eventOrganizationColors['magnifi'] }}>
                    {formatEventOrganization('magnifi')}
                  </span>
                  {' - '}
                  {formatEventName('Email Opened')}
                </p>
              </div>
            )}
          </ScrollingDiv>
        </div>
        {cardThemes.length > 0 && (
          <div style={{ padding: '0 15px' }}>
            <F3 className={classes.rowTitle} style={{ color: colors.grey_02, margin: '8px 0px 12px 0px' }}>
              Themes Driving Match
            </F3>
            <ScrollingDiv width={cardParams?.current?.clientWidth ? cardParams?.current?.clientWidth - 26 : null}>
              {cardThemes.length > 0 ? (
                <>
                  {cardThemes.map((theme, index) => (
                    <div className={classes.platformIndicator} key={index + 3429391}>
                      <p className={classes.platformText}>{theme}</p>
                    </div>
                  ))}
                </>
              ) : (
                <div className={classes.platformIndicator} key={569309825 + id}>
                  <p className={classes.platformText}>No themes found</p>
                </div>
              )}
            </ScrollingDiv>
          </div>
        )}
      </div>
    );
  };

  const countHelper = () => {
    switch (referralIndices[index]) {
      case 0:
        return '1st';
      case 1:
        return '2nd';
      case 2:
        return '3rd';
      default:
        return referralIndices[index] + 1 + 'th';
    }
  };

  const toggleReferrals = (side: any) => {
    if (side === 'left') {
      referralIndices[index] > 0 && setReferralIndices({ ...referralIndices, [index]: referralIndices[index] - 1 });
    } else {
      referralIndices[index] < client.availableReferrals.length - 1 &&
        setReferralIndices({ ...referralIndices, [index]: referralIndices[index] + 1 });
    }
  };

  const referralView = () => {
    const currentClient = client?.referrals?.[referralIndices[index]];

    if (currentClient) {
      return (
        <div>
          <div className={classes.referralToggle}>
            <div style={{ display: 'flex', width: 'fit-content', padding: '0 15px' }}>
              <img className={classes.referralIcon} src={referralsGray} alt="refer" />
              <h4 className={classes.referralText}> Can Be Referred By </h4>
            </div>
            <div style={{ display: 'flex', width: 'fit-content' }}>
              <h4 className={classes.referralText}>
                {countHelper()} of {client?.availableReferrals?.length ? client?.availableReferrals?.length : '1'}
              </h4>
              <img className={classes.arrowIcons} src={leftArrow} alt="left" onClick={() => toggleReferrals('left')} />
              <img
                className={classes.arrowIcons}
                src={rightArrow}
                alt="right"
                onClick={() => toggleReferrals('right')}
              />
            </div>
          </div>

          <div className={classes.referredContainer}>
            <img className={classes.faceIcon} src={currentClient.image} alt="icon" />
            <div style={{ display: 'table', marginLeft: 7 }}>
              <h4 className={classes.nameHeader}>
                {formatFullName(currentClient?.advisor ? currentClient?.advisor : currentClient)}
              </h4>
              <p className={classes.rankHelper}>Top Available Referral</p>
            </div>
          </div>

          <div className={classes.referredContainer} style={{ display: 'table', textAlign: 'left' }}>
            <h4 className={classes.referralText} style={{ marginTop: 8 }}>
              {currentClient.positionType}
            </h4>
            <h4 className={classes.referralText}>{currentClient.firmName}</h4>
          </div>
          <div className={classes.referralProbs}>
            <h4 className={classes.referralText}>Probability of Relationship</h4>
            <h4 className={classes.referralText}>{currentClient.relationshipProbability}%</h4>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div ref={cardParams} id={id} key={client.id + 'clientcard'} className={classes.clientCardStyle}>
      <div className={classes.innerClientCard}>
        <div style={{ padding: 0, margin: 0 }}>
          {contentType === 'nurtureAdvisors' ? nurtureView() : generalView()}
          <div className={classes.divider} />
          {/* <Content data={client} /> */}
          <div className={classes.innerCardStyle}>
            <div className={classes.cardHeader}>
              <h4 className={classes.rowHeader} id={`${id}-name`}>
                {client?.advisor ? formatFullName(client?.advisor) : formatFullName(client)}
              </h4>
              {client.positionType && client.firmName ? (
                <div>
                  <h4 className={classes.rowSubheader}>{client.positionType}</h4>
                  <h4 className={classes.rowSubheader} style={{ margin: '-10px 0 -6px' }}>
                    {client.firmName}
                  </h4>
                </div>
              ) : (
                client.firmName &&
                !client.positionType && <h4 className={classes.rowSubheader}>{formatFirmName(client.firmName)}</h4>
              )}
              {client.investorEmail && !client.positionType && (
                <h4 className={classes.rowSubheader} style={{ margin: '4px 0 -4px' }}>
                  {client.investorEmail}
                </h4>
              )}
            </div>
            {packageName !== 'prioritizeAdvisors' &&
            packageName !== 'prioritizeProspects' &&
            contentType !== 'referralData' &&
            packageName !== 'crossSell' &&
            packageName !== 'enrichClientData' &&
            packageName !== 'identifyLifeStages' &&
            packageName !== 'growthOpportunity' ? (
              eventsView()
            ) : (
              <div />
            )}
            {contentType === 'lplAdvisorData' && tradingView()}
            <div
              className={classes.divider}
              style={{ margin: contentType === 'referralData' ? '5px 0 10px' : '0px 0px 10px' }}
            />
            {contentType !== 'referralData'
              ? ContentModels[contentType].map((item: any, index: number) => (
                  <div className={classes.cardRow} key={index + 48594835}>
                    <F3 className={classes.rowTitle}>{item.title}</F3>
                    <F3 className={classes.rowValue}>{item.key ? client[item.key] : item.formatData(client)}</F3>
                  </div>
                ))
              : referralView()}
            {packageName === 'prioritizeProspects' &&
              contentType !== 'referralData' &&
              contentType !== 'nurtureAdvisors' && (
                <div className={classes.mainPageReferrals}>
                  <div style={{ display: 'flex', width: 'fit-content' }}>
                    <img className={classes.referralIcon} src={referralsGray} alt="refer" />
                    <h4 className={classes.referralText} style={{ fontWeight: 400 }}>
                      Referrals Available
                    </h4>
                  </div>
                  <h4 className={classes.referralText} style={{ fontWeight: 400 }}>
                    {client.referrals ? 'Yes' : 'Yes'}
                  </h4>
                </div>
              )}
          </div>
          <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
        </div>
      </div>
    </div>
  );
};

export default GeneralCard;
