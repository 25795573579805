import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from '../../../components';
import { SectionPageHeader } from '../../../components/SectionPageHeader';
import PageHeader from '../../../components/PageHeader';
import { Funds } from './Funds';
import { Models } from './Models';
import { useStoreState } from '../../../store';
import RoiReportsModal from '../../../components/RoiReportsModal';
type Props = {};

const TIFIN_DROPDOWN_OPTIONS: any[] = [
  {
    label: 'Magnifi',
    value: 'magnifi',
  },
  {
    label: 'TIFIN Wealth',
    value: 'wealth',
  },
  {
    label: 'Magnifi + TIFIN Wealth',
    value: '',
  },
];

const LPL_DROPDOWN_OPTIONS: any[] = [
  {
    label: 'LPL',
    value: '',
  },
];

export const ProductPlacements = (props: Props) => {
  const [pageTab, setPageTab] = useState('Funds');
  const [showRoiModal, setShowRoiModal] = useState(false);
  const appType = useStoreState((state) => state.appType);

  const platformOptions = appType === 'LPL' ? LPL_DROPDOWN_OPTIONS : TIFIN_DROPDOWN_OPTIONS;
  // console.log('selected drop down: ', platformOptions);
  // console.log('selected drop down: ', platformOptions, selectedDropDown);

  const [selectedDropDown, setSelectedDropDown] = useState<any>(platformOptions?.[0]?.label);

  const toggleRoiModal = () => {
    setShowRoiModal(!showRoiModal);
  };

  const navigate = useNavigate();

  return (
    <ContentContainer>
      <SectionPageHeader
        buttonText={'Zoom Out'}
        buttonAction={() => navigate('/build')}
        subtitle={'Build Awareness'}
        title={'Product Placements'}
        description={
          appType === 'LPL'
            ? 'Your fund avaliability within the LPL ecosystem'
            : 'Purchase shelf space in one of Tifin’s platforms to increase the visibility of your funds'
        }
      />
      {appType !== 'LPL' && (
        <div style={{ boxShadow: '0px 0px 25px 0px rgba(63, 106, 194, 0.15)', borderRadius: 5 }}>
          <PageHeader
            selectedButton={pageTab}
            leftButtons={[
              { text: 'Funds', onPress: setPageTab },
              { text: 'Models', onPress: setPageTab },
            ]}
            rightButtons={[{ modalText: 'Download ROI Reports', action: toggleRoiModal }]}
          />
        </div>
      )}
      {appType === 'LPL' ? (
        // <FundsAvaliability />
        <></>
      ) : pageTab === 'Funds' ? (
        <Funds
          selectedDropDown={selectedDropDown}
          platformOptions={platformOptions}
          setSelectedDropDown={setSelectedDropDown}
        />
      ) : (
        <Models />
      )}
      <RoiReportsModal modalStatus={showRoiModal} closeModal={toggleRoiModal} />
    </ContentContainer>
  );
};
