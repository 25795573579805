import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../../styles';
import { H2, F3, PrimaryButton } from '..';
import { styled } from '@mui/system';
import { Table, TableHead, TableBody, TableCell, TableRow, TableContainer, IconButton } from '@mui/material';
import { exampleTemplate } from './example-template';
import { downloadCSVFile } from '../../helpers/saveJSONFile';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '120px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  margin: '15px 10px 0px 10px',
  padding: '10px 12px',
  '&:hover': {
    opacity: 0.7,
  },
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '500px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-20px 0 0',
});

type Props = {
  setModalPage: any;
};

const SampleSpreadsheet = ({ setModalPage }: Props) => {
  const pageCopy = {
    header: 'A Quick Note on Spreadsheet Formatting',
    subheader: 'In order for us to make use of your spreadsheet(s) please provide them in the format below...',
    body: () => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: '100px',
          margin: '10px 0px 0px',
          alignItems: 'center',
        }}
      >
        <F3 style={{ color: colors.grey_02 }}>If you'd like a little help, use our template.</F3>
        <MainButton
          onClick={() => downloadCSVFile('example-template.csv', exampleTemplate)}
          style={{ margin: '-10px' }}
        >
          Download Template
        </MainButton>
      </div>
    ),
    buttons: [{ text: 'Continue', action: () => setModalPage('UploadFile') }],
  };

  const previewTable = {
    header: [
      { label: 'First Name', key: 'firstName' },
      { label: 'Last Name', key: 'lastName' },
      { label: 'Email', key: 'email' },
    ],
    body: [{ firstName: 'Joe', lastName: 'Montana', email: 'joe.montana@tifin.com' }],
  };

  return (
    <div style={{ flex: 1, margin: '-100px 0px 0px' }}>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02 }}>{pageCopy.subheader}</F3>
      </TextContainer>
      <TableContainer style={{ width: '600px', margin: '0px 20px' }}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              {previewTable.header.map((header, index) => {
                return (
                  <TableCell
                    sx={{
                      background: '#F7F7F7',
                      border: '0.5px solid #C2CBCF',
                      fontSize: 14,
                      color: '#758790',
                    }}
                    key={index}
                  >
                    {header?.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {previewTable.body.map((row: any, index) => {
              console.log('row header: ', row);
              return (
                <TableRow>
                  {previewTable.header.map((header: any, index) => {
                    return (
                      <TableCell
                        key={index}
                        sx={{
                          border: '0.5px solid #C2CBCF',
                          fontSize: 14,
                          color: '#758790',
                        }}
                      >
                        {row?.[header?.key] ? row?.[header?.key] : '-'}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pageCopy.body()}
      {pageCopy.buttons && (
        <ButtonContainer style={{ width: '100%' }}>
          {pageCopy &&
            pageCopy.buttons.map((button, index) => (
              <PrimaryButton style={{ width: '140px' }} key={index} onClick={button.action}>
                {button.text}
              </PrimaryButton>
            ))}
        </ButtonContainer>
      )}
    </div>
  );
};

export default SampleSpreadsheet;
