import React, { useState } from 'react';
import { Table, flexRender } from '@tanstack/react-table';
import { styled } from '@mui/material/styles';
import { Column } from '..';
import { colors, typography } from '../../styles';
import ArrowUp from '../../assets/icons/arrow-grey.svg';
import Search from '@mui/icons-material/Search';
import SearchBar from '../SearchBar';

type Props = {
  tableData: Table<any>;
  searchBar?: boolean;
  searchBarText?: string;
  setSearchBarText?: any;
};

const Container = styled('table')({
  flex: 1,
  borderCollapse: 'collapse',
});

const HeaderRow = styled('thead')({
  borderBottom: `1px solid ${colors.grey_00}`,
  padding: '8px 0px',
});

const HeaderText = styled('th')({
  flex: 1,
  ...typography.mainFont,
  fontSize: '13px',
  lineHeight: '22px',
  fontWeight: '700',
  textAlign: 'left',
  color: colors.grey_02,
  borderBottom: `1px solid ${colors.grey_02}`,
  cursor: 'pointer',
  whiteSpace: 'nowrap',
});

const TableRow = styled('tr')({
  borderBottom: `1px solid ${colors.grey_01}`,
  padding: '12px 0px',
});

const TableText = styled('td')({
  flex: 1,
  textAlign: 'left',
  color: colors.grey_02,
  ...typography.mainFont,
  fontSize: '14px',
  lineHeight: '22px',
  fontWeight: '400',
  padding: '8px 2px',
  bottomBorder: `2px solid ${colors.grey_01}`,
  whiteSpace: 'nowrap',
});

const Arrow = styled('img')({
  height: '10px',
  width: '10px',
  margin: '0px 5px',
  transition: 'transform 0.5s ease-in-out, opacity 0.3s ease-in-out',
});

const SimpleTable = ({ tableData, searchBar, searchBarText, setSearchBarText }: Props) => {
  return (
    <Container>
      <HeaderRow>
        {!!searchBar && (
          <SearchBar searchText={searchBarText ?? ''} setSearchText={setSearchBarText} debounce={500} condensed />
        )}
        {tableData.getHeaderGroups()?.map((headerGroup, index: number) => (
          <TableRow key={headerGroup?.id}>
            {headerGroup?.headers?.map((header) => {
              return (
                <HeaderText key={header?.id}>
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort()
                        ? header.column.getNextSortingOrder() === 'asc'
                          ? 'Sort ascending'
                          : header.column.getNextSortingOrder() === 'desc'
                          ? 'Sort descending'
                          : 'Clear sort'
                        : undefined
                    }
                  >
                    {flexRender(header?.column?.columnDef?.header, header?.getContext())}
                    <Arrow
                      src={ArrowUp}
                      alt="arrow-up"
                      style={{
                        transform: header?.column.getIsSorted() === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
                        opacity: header.column.getIsSorted() ? 1 : 0,
                      }}
                    />
                  </div>
                </HeaderText>
              );
            })}
          </TableRow>
        ))}
      </HeaderRow>
      <tbody>
        {tableData?.getRowModel()?.rows?.map((row: any, index: number) => {
          return (
            <TableRow key={row?.id}>
              {row.getVisibleCells()?.map((cell: any, index: number) => {
                return <TableText key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableText>;
              })}
            </TableRow>
          );
        })}
      </tbody>
    </Container>
  );
};

export default SimpleTable;
