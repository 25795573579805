import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Column, F3 } from '../../components';
import CarrotSrc from '../../assets/icons/carrot-black.svg';
import TifinAmpLogo from '../../assets/logos/tifin_switcher_logo.svg';
import LplLogo from '../../assets/logos/lpl_switcher_logo.svg';

const appType = process.env.REACT_APP_THEME === 'LPL' ? 'LPL' : 'TIFIN';
const tifinURL = process.env.REACT_APP_TIFIN_URL || 'https://app.tifinamp.com/';
const lplURL = 'https://lpl-app.tifinamp.com/';

export const Row = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
});

export const RootColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  borderTop: `1px solid ${colors.grey_01}`,
  cursor: 'pointer',
  padding: '0px 10px',
  borderRadius: '5px',
  ':hover': {
    background: colors.grey_01,
  },
});

export const CarrotIcon = styled('img')({
  justifyContent: 'space-between',
});

export const ImageContainer = styled('div')({
  height: '54px',
  //   width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '6px 0px',
  padding: '6px',
  borderRadius: '2px',
  background: colors.white,
});

export const NavLogo = styled('img')({
  height: '54px',
  width: '100%',
  objectFit: 'contain',
});

type Props = {};

export const EcosystemSwitcher = ({}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentURL = window?.location?.origin;
  const [open, setOpen] = useState(false);
  const [selectedEcosystem, setSelectedEcosystem] = useState('TIFIN');

  const toggleOpen = () => {
    setOpen(!open);
  };

  const ecosystemOptions = [
    {
      name: 'TIFIN',
      logo: TifinAmpLogo,
      style: { height: '28px', width: '124px' },
      url: tifinURL,
    },
    { name: 'LPL', logo: LplLogo, style: { height: '17px', width: '160px' }, url: lplURL },
  ];

  return (
    <RootColumn onClick={toggleOpen} style={open ? { background: colors.grey_01 } : {}}>
      <Row style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
        <F3 style={{ margin: '0px' }}>Ecosystem</F3>
        <CarrotIcon style={open ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }} src={CarrotSrc} />
      </Row>
      {open && (
        <Column style={{ margin: '0px 0px 6px' }}>
          {ecosystemOptions.map((item: any) => {
            // console.log(selectedEcosystem === item?.name);
            return (
              <ImageContainer
                onClick={(event: any) => {
                  event.stopPropagation();
                  setSelectedEcosystem(item?.name);
                  const newURL = item?.url + location?.pathname;
                  window.location.href = newURL;
                }}
                style={appType === item?.name ? { border: `1px solid ${colors.grey_03}` } : {}}
              >
                <NavLogo style={item?.style} src={item?.logo} />
              </ImageContainer>
            );
          })}
        </Column>
      )}
    </RootColumn>
  );
};
