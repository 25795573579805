import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { H2, F3, MainTextInput, PrimaryButton } from '..';
import { colors } from '../../styles';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  width: '360px',
  margin: '120px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  linkSalesforce: any;
  setModalPage: any;
};

const Salesforce = ({ linkSalesforce, setModalPage }: Props) => {
  const [domainName, setDomainName] = useState<string>('');

  const pageCopy = {
    header: 'Create New Salesforce Connection',
    subheader: (
      <span>
        Login into your Salesforce instance to create a new connection or{' '}
        <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => setModalPage('SalesforceIntegrations')}
        >
          view active connections
        </span>
      </span>
    ),
  };

  return (
    <div>
      <TextContainer>
        <H2 style={{ color: colors.black, margin: '0px 0px 10px' }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, margin: '0px 0px 10px', textAlign: 'center' }}>{pageCopy.subheader}</F3>
        <MainTextInput
          label="Salesforce Domain Prefix"
          size="small"
          style={{ margin: '22px 0' }}
          value={domainName}
          /*className={classes.fieldOverride}*/
          focused
          onChange={(event) => setDomainName(event.target.value)}
        />
        <PrimaryButton style={{}} onClick={() => linkSalesforce(domainName)}>
          Take me to Salesforce
        </PrimaryButton>
      </TextContainer>
    </div>
  );
};

export default Salesforce;
