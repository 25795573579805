import { gql } from '../../__generated__';

export const GET_INFLUENCE_ARCHIVES = gql(`
           query getInfluenceJourneys($filters: [FilterType], $pageSize: Int, $page: Int) {
            influence {
              quotaUsed # prospects Influenced
              quotaTotal # You'll have to calculate remaining yourself (this minus previous)
            }
            journeys(filters: $filters, showAll: true, pageSize: $pageSize, page: $page) {
              page
              totalItems
              list {
                customName
                dateCreated
                dateUpdated
                id
                isActive
                package
                runhistorySet {
                  id
                  customName
                  averageScore
                  dateRun
                  package
                  serveCount
                }
              }
            }
          }
`);

export const GET_ADVISOR_SET_SUMMARY = gql(`
  query getAdvisorSets1($advisorSetId: Int!, $filters: [FilterType]) {
          advisorSetSummary(advisorSetId: $advisorSetId, filters: $filters) {
            advisorTenureHistogram
            advisorCount
            ageHistogram
            aumHistogram
            etfMfAllocationHistogram
            firmSizeHistogram
            typeHistogram
          }
        }
`);

export const GET_ADVISOR_SETS = gql(`
  query getAdvisorSets2($integrationId: Int) {
    advisorSets(integrationId: $integrationId) {
      dateCreated
      dateUpdated
      dynamicRefresh
      filterConfig
      id
      lastPull
      lastPush
      distinctMemberCount
      memberCount
      migratedFromTag
      name
      propertiesMapping
      integration {
        type
        dateCreated
        dateUpdated
        id
        nickname
      }
    }
  }
`);

export const CREATE_JOURNEY = gql(`
  mutation createInfluenceJourney(
      $advisorSetIds: [Int]!
      $journeyName: String!
      $usePurchaseAlgorithm: Boolean
      $useRedemptionAlgorithm: Boolean
    ) {
      influenceCreateJourney(
        advisorSetIds: $advisorSetIds
        journeyName: $journeyName
        usePurchaseAlgorithm: $usePurchaseAlgorithm
        useRedemptionAlgorithm: $useRedemptionAlgorithm
      ) {
        traceback
        code
        success
        journey {
          id
          latestRun {
            id
            latestJob {
              processingDetails
              id
              dateUpdated
              dateCreated
              status
            }
          }
        }
      }
    }
`);

export const REFRESH_JOURNEY = gql(`
  mutation refreshInfluenceJourney($journeyId: ID!) {
    influenceRefreshJourney(journeyId: $journeyId) {
      run {
        id
      }
      success
    }
  }
`);

export const UPDATE_JOURNEY = gql(`
  mutation refreshJourney4($journeyId: ID!, $isActive: Boolean, $integrationId: Int) {
         updateJourney(journeyId: $journeyId, integrationId: $integrationId, isActive: $isActive) {
           code
           success
           traceback
         }
       }
`);

export const EXPORT_RESULTS = gql(`
  mutation exportInfluenceResults($runId: ID!) {
    influenceResultsExport(runId: $runId) {
      url
      traceback
      success
    }
  }
`);

export const RETRY_PROCESSING_JOB = gql(`
  mutation retryInfluenceProcessingJob($runId: ID!) {
    influenceRetryRun(runId: $runId) {
      success
      traceback
      run {
        id
      }
    }
  }
`);

export const GET_RUN_INTEGRATIONS = gql(`
  query getInfluenceIntegrations($runId: Int!) {
    influence {
      results(runId: $runId) {
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
        journey {
          id
          integration {
            id
            dateUpdated
            dateCreated
            nickname
            status
            type
            sfintegrationauthdetailsSet {
              instanceUrl
            }
          }
        }
      }
    }
  }
`);

export const GET_JOB_STATUS = gql(`
  query getInfluenceJobStatus($runId: Int!) {
    influence {
      results(runId: $runId, filters: []) {
        journey {
          integration {
            id
            dateUpdated
            dateCreated
            nickname
            status
            type
          }
          latestRun {
            id
            latestJob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
          runhistorySet {
            id
            dateRun
            latestJob {
              status
              processingDetails
              id
              dateUpdated
              dateCreated
            }
            runhistoryprocessingjob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
        }
      }
    }
  }
`);

export const GET_RADAR_CHART_DATA = gql(`
  query getInfluenceRadarChart($runId: Int!, $advisorId: ID!) {
    influence {
      results(runId: $runId) {
        advisorRadarChart(advisorId: $advisorId)
      }
    }
  }
`);

export const GET_ADVISOR_SEGMENT = gql(`
  query getAdvisorSegment($runId: Int!, $filters: [FilterType]) {
    influence {
      results(runId: $runId) {
        advisors(filters: $filters) {
          totalItems
        }
      }
    }
  }
`);

export const GET_EXPORT_JOB_STATUS = gql(`
  query getInfluenceExportJobStatus(
    $runId: Int!
  ) {
    influence {
      quotaTotal
      quotaUsed
      results(runId: $runId) {
        eventData
        recentEvents
        changeData
        ampScoresHistogram
        redemptionScoresHistogram
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
      }
    }
  }`);

export const GET_INFLUENCE_RESULTS = gql(`
  query getInfluenceResults(
    $runId: Int!
    $page: Int!
    $pageSize: Int!
    $sortKey: String!
    $query: String!
    $filters: [FilterType]
  ) {
    influence {
      quotaTotal
      quotaUsed
      results(runId: $runId, filters: $filters) {
        eventData
        recentEvents
        changeData
        ampScoresHistogram
        redemptionScoresHistogram
        exportJob {
          bookmark
          dateCreated
          dateDone
          dateUpdated
          id
          jobId
          percentComplete
          result
          status
          totalCount
          targetIntegration {
            dateCreated
            dateUpdated
            id
            nickname
            status
            type
          }
        }
        advisors(page: $page, pageSize: $pageSize, sortKey: $sortKey, query: $query, filters: $filters) {
          #filters:$filters) {
          totalItems
          page
          pages
          list {
            hasPurchaseResults
            hasRedemptionResults
            advisorExpertise
            advisor360Data
            age
            aumSelfReported
            careerTenure
            custodian1
            currentFirmTenureYears
            custodian2
            custodian3
            custodian4
            custodian5
            firmAvgAccountSize
            email
            firmCrd
            firmEtfAssetsMillions
            firmMfAssetsMillions
            firmName
            firmNumberBdReps
            firmNumberEmployees
            firmPercentAssetsEtf
            firmNumberIaReps
            firmPercentAssetsMf
            firmPercentClientsCharities
            firmPercentClientsCorporations
            firmTotalAccounts
            firmPercentClientsHnwIndividuals
            firmTotalAssetsInMillions
            firstName
            fullName
            id
            isBd
            isRia
            lastName
            networkToAdvisor
            officeState
            officeZipcode
            pathToEngage
            productsOfInterest
            purchaseLikelihood
            purchaseSize
            purchaseTiming
            score
            redemptionLikelihood
            redemptionSize
            redemptionTiming
            redemptionScore
            rating
            recordUuid
            repcrd
            riaEmployeeRatio
            scoreDetails
            title
            topThemes
            performanceInsights
            influenceredemptionadvisorsummarySet {
              id
              key
              orderNumber
              type
              value
            }
            influenceadvisorsummarySet {
              id
              key
              value
              type
              orderNumber
            }
            previousServeHistory {
              advisorExpertise
              age
              aumSelfReported
              date
              firstName
              fullName
              id
              isBd
              isRia
              lastName
              networkToAdvisor
              officeState
              officeZipcode
              pathToEngage
              productsOfInterest
              purchaseLikelihood
              purchaseTiming
              rating
              recordUuid
              repcrd
              riaEmployeeRatio
              score
              scoreDetails
              themes
              title
              topThemes
            }
          }
        }
        journey {
          isDemo
          integration {
            id
            dateUpdated
            dateCreated
            nickname
            status
            type
          }
          latestRun {
            id
            latestJob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
          runhistorySet {
            id
            latestJob {
              id
              status
              processingDetails
              dateUpdated
              dateCreated
            }
            runhistoryprocessingjob {
              dateCreated
              dateUpdated
              id
              processingDetails
              status
            }
          }
        }
      }
    }
  }
`);
