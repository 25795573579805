import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../../styles';
import { Title, F3, Row, Column, PrimaryButtonShort } from '../../components';
import DataFabric from '../../components/DataFabric';

type Props = {};

export const Root = styled('div')({
  flex: 1,
  display: 'flex',
  height: '100vh',
  // width: 100vw,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '30px 0px',
  background: colors.gradient_01,
});

export const UnifyData = (props: Props) => {
  return (
    <Root>
      <Row
        style={{ width: '100%', padding: '0px 32px', justifyContent: 'space-between', background: colors.gradient_01 }}
      >
        <Column>
          <Title>Data Fabric</Title>
          <F3 style={{ color: colors.grey_02, margin: '-10px 0px 0px' }}>
            This is the map of your current infrastructure.
          </F3>
        </Column>
      </Row>
      <DataFabric />
    </Root>
  );
};
