import React, { useState, useEffect } from 'react';
import { colors } from '../styles';
import { CardContainer, F3, CardHeader, Row, Column, MainTextInput } from '.';
import { formatDistillScore } from '../helpers/dataFormatter';
import { MenuItem } from '@mui/material';
import { DistillScoreLogo } from './DistillScoreLogo';
import ScrollingDiv from './ScrollingDiv';
import { styled } from '@mui/material/styles';
import TrendChart from './AmpScoreChart';
import ArrowBlack from '../assets/icons/arrow-black.svg';
import Loader from './Loader';

const ScoreContainer = styled('div')({
  display: 'flex',
  height: '40px',
  width: '140px',
  alignItems: 'center',
  padding: '5px 14px',
  borderRadius: '5px',
  margin: '3px',
  backgroundColor: colors.grey_06,
  fontWeight: '900',
  fontSize: '24px',
  color: colors.grey_03,
  whiteSpace: 'nowrap',
  cursor: 'default',
});

const InfoBadge = styled('div')({
  display: 'flex',
  height: '25px',
  alignItems: 'center',
  padding: '5px 14px',
  borderRadius: '5px',
  margin: '3px',
  fontSize: '14px',
  backgroundColor: colors.grey_06,
  color: colors.grey_02,
  whiteSpace: 'nowrap',
  cursor: 'default',
});

const DetailsButton = styled('button')({
  display: 'flex',
  padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  borderRadius: 5,
  height: 40,
  background: 'white',
  border: `1px solid ${colors.black_01}`,
  color: colors.black_01,
  fontWeight: 400,
  fontSize: 14,
  cursor: 'pointer',
  lineHeight: 1,
  textDecoration: 'none',
  '&:hover': {
    background: colors.black_01,
    color: colors.white_01,
    border: `1px solid ${colors.black_01}`,
  },
  // [theme.breakpoints.down(1170)]: {
  //   fontSize: 13,
  //   padding: '7px 0px',
  // },
});

const CampaignMenuItem = styled(MenuItem)({
  fontFamily: 'Inter',
  fontSize: '16px',
  color: colors.black_01,
});

interface Props {
  id?: string;
  selectedCampaign?: any;
  campaigns?: any;
  selectCampaign?: any;
  loading?: boolean;
  type: string;
}

export const CampaignAmpScoreCard = ({ id, campaigns, selectCampaign, selectedCampaign, loading, type }: Props) => {
  const [focusedCampaign, setFocusedCampaign] = useState<any>(null);

  useEffect(() => {
    setFocusedCampaign(campaigns ? campaigns?.[0] : selectedCampaign);
  }, [campaigns, selectCampaign]);

  return (
    <CardContainer style={{ margin: '10px 0px 22px' }}>
      <CardHeader>AMP Score Change over time</CardHeader>
      <Row style={{ margin: '16px 0px 0px' }}>
        {loading ? (
          <Loader inputMargin={'100px 46%'} />
        ) : (
          <>
            <Column
              style={{
                width: '364px',
                padding: '0px 30px',
                borderRight: `1px solid ${colors.grey_01}`,
              }}
              id={id}
            >
              {campaigns && (
                <MainTextInput
                  value={focusedCampaign}
                  onChange={(event) => {
                    console.log('selecting focused campaign', event);
                    setFocusedCampaign(event.target.value);
                  }}
                  focused
                  id="email-field"
                  label={type === 'Campaigns' ? 'Campaign' : 'Journey'}
                  select
                  size={'small'}
                >
                  {campaigns.map((item: any, index: number) => (
                    <CampaignMenuItem key={index} value={item}>
                      {item?.name ? item?.name : item?.customName}
                    </CampaignMenuItem>
                  ))}
                </MainTextInput>
              )}
              <Row style={{ margin: '16px 0px', justifyContent: 'space-between' }}>
                <Column style={{ height: '70px', justifyContent: 'space-between' }}>
                  <div style={{ padding: '0px 4px' }}>
                    <DistillScoreLogo style={{ height: '18px' }} packageName={'advisorLeads'} />
                  </div>
                  <ScoreContainer>{focusedCampaign?.currentAverageScore?.toFixed(0)}</ScoreContainer>
                </Column>
                <Column style={{ height: '70px', justifyContent: 'space-between' }}>
                  <F3 style={{ margin: '0px', padding: '0px 4px', color: colors.grey_03 }}>Since Last Action</F3>
                  <ScoreContainer>
                    {focusedCampaign?.averageScoreIncrease > 0 && (
                      <img style={{ margin: '0px 2px 0px 0px' }} src={ArrowBlack} />
                    )}
                    {formatDistillScore(focusedCampaign?.averageScoreIncrease)}%
                  </ScoreContainer>
                </Column>
              </Row>
              <Column>
                <F3 style={{ color: colors.grey_03, margin: '0px 0px' }}>High Impact Actions</F3>
                <ScrollingDiv width={298}>
                  {focusedCampaign?.nurtureemailSet?.map((email: any, index: number) => (
                    <InfoBadge>
                      {index + 1}. <span style={{ color: colors.grey_03, margin: '0px 3px' }}> Email</span> -{' '}
                      {email?.name}
                    </InfoBadge>
                  ))}
                </ScrollingDiv>
              </Column>
              {campaigns && (
                <DetailsButton id={'view-details-campaign'} onClick={() => selectCampaign(focusedCampaign)}>
                  View Details
                </DetailsButton>
              )}
            </Column>
            <TrendChart campaign={focusedCampaign} />
          </>
        )}
      </Row>
    </CardContainer>
  );
};
