import React from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../styles';
import { formatNumber } from '../helpers/dataFormatter';
import { StatType } from '../types';
import Loader from './Loader';

const CardRoot = styled('div')({
  background: '#fff',
  borderRadius: '5px',
  boxShadow: colors.main_box_shadow,
  display: 'flex',
  flex: 1,
  margin: '0px 10px',
});

const InnerCard = styled('div')({
  display: 'table',
  width: '100%',
  overflow: 'hidden',
  minHeight: 180,
});

const ColumnValueProgress = styled('h2')({
  ...typography.mainFont,
  fontSize: '36px',
  width: '100%',
  fontWeight: 700,
  display: 'flex',
  color: colors.grey_03,
  margin: '5px 0',
  textAlign: 'left',
  padding: '10px 20px 0',
});
const ColumnTitle = styled('h1')({
  ...typography.mainFont,
  fontSize: 16,
  fontWeight: 500,
  color: '#404B50',
  margin: '0px auto 0px',
  textAlign: 'left',
  width: '100%',
  padding: '17px 0px 17px 20px',
  lineHeight: 1,
  borderBottom: '1px solid #ECECEC',
});
const ButtonContainer = styled('div')({
  borderTop: '1px solid #ececec',
  padding: '15px 16px 5px',
  margin: 'auto',
});
const ButtonStyle = styled('button')({
  height: 30,
  color: colors.white_01,
  display: 'flex',
  fontSize: 14,
  fontWeight: 400,
  width: '100%',
  flexDirection: 'row',
  borderRadius: 5,
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${colors.black_01}`,
  backgroundColor: colors.black_01,
  margin: '0 auto',
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.7',
  },
});
const Divider = styled('div')({
  width: '85%',
  height: 3,
  display: 'flex',
  margin: '5px 0 15px 20px',
});

const EnrichedStyle = styled('p')({
  fontSize: 18,
  fontWeight: 400,
  margin: '10px 0 0 10px',
});

const CardBody = styled('p')({
  fontSize: '12px',
  color: colors.grey_02,
  padding: '0px 20px',
  height: '50px',
});

export const StatCard = ({ index, label, data, percent, body, button, action }: StatType) => {
  return (
    <CardRoot id={`stat-card-${index}`}>
      <InnerCard>
        <ColumnTitle id={`header-card-${index}-title`}>{label}</ColumnTitle>
        {!data ? (
          <div style={{ width: '80%', margin: 0 }}>
            <Loader inputMargin={'40px auto 10px'} />
          </div>
        ) : (
          <ColumnValueProgress id={`header-card-${index}-value`}>
            {data}
            {percent && (
              <EnrichedStyle sx={{ color: percent > 50 ? '#00CFAA' : '#FF7715' }}>
                ({formatNumber(percent)}% enriched)
              </EnrichedStyle>
            )}
          </ColumnValueProgress>
        )}
        {percent && (
          <Divider
            sx={{
              background:
                percent && percent > 50
                  ? `linear-gradient(0.25turn, #00CFAA 0 ${percent}%, #e3e5e4 ${percent}% 100%)`
                  : `linear-gradient(0.25turn, #FF7715 0 ${percent}%, #e3e5e4 ${percent}% 100%)`,
            }}
          />
        )}
      </InnerCard>
      {body && <CardBody sx={{ margin: percent ? '-54px 0px 8px' : '-64px 0px 8px' }}>{body}</CardBody>}
      {button && (
        <ButtonContainer>
          <ButtonStyle children={button} onClick={action} />
        </ButtonContainer>
      )}
    </CardRoot>
  );
};
