import React, { useEffect, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TablePagination,
  IconButton,
} from '@mui/material';
import { Row } from '..';
import { colors, typography } from '../../styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { tableCellClasses } from '@mui/material/TableCell';
import EditIcon from '@mui/icons-material/Edit';
import info from '../../assets/icons/info.svg';
import sortable from '../../assets/icons/sortable.svg';
import DetailedTooltip from '../DetailedTooltip';
import SearchBar from '../SearchBar';
import RatingButton from '../RatingButton';
import Checkbox from '../Checkbox';
import Loader from '../Loader';
import { makeStyles } from '@mui/styles';
import Switch from '@mui/material/Switch';
import { formatDistillScore, formatFullName } from '../../helpers/dataFormatter';
import { DistillScoreLogo } from '../DistillScoreLogo';
import { sortingHelper } from '../../helpers/tableUtils';

const useStyles: any = makeStyles(() => ({
  container: {
    position: 'fixed',
    top: 80,
    left: 450,
    width: '65%',
    //overflowY: 'scroll',
  },
  title: {
    fontSize: '16px',
  },
  headerCountStyle: {
    margin: '0 6px',
    fontSize: 14,
    fontWeight: 400,
    color: '#404B50',
  },
  tableContainer: {
    width: '100%',
    margin: '0 auto 0px',
    padding: '2px 0px 0px 0px',
    borderRadius: '5px',
  },
  tableStyle: {
    //maxHeight: '600px',
  },
  innerContainer: {
    margin: '32px 16px 0px',
  },
  topDetailsContainer: {
    padding: '15px 50px 0px',
    minHeight: 40,
  },
  chipStyle: {
    color: '#758790',
    margin: 'auto 5px auto 5px',
    backgroundColor: 'white',
    borderColor: '#758790',
  },
  searchIcon: {
    margin: '10px 6px',
    color: colors.grey_03,
  },
  input: {
    height: 40,
    borderRadius: 50,
    color: colors.grey_03,
    backgroundColor: colors.grey_01,
    border: 0,
    width: '100%',
    padding: '4px 10px 0 20px',
    outline: 'none',
  },
  headerText: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontWeight: '400',
    fontSize: '14px',
    cursor: 'pointer',
    //marginBottom: '-6px',
  },
  selectedHeaderText: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontWeight: '400',
    fontSize: '14px',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    //marginBottom: '-6px',
  },
  headerScoreLogo: {
    //padding: '0px 0px -10px 0px',
  },
  newIndicator: {
    height: '7px',
    width: '7px',
    borderRadius: '7px',
    backgroundColor: colors.green,
  },
  tooltip: {
    backgroundColor: colors.green,
  },
  newIndicatorContainer: {
    width: '6px',
    //marginRight: '-30px',
  },
  tableRow: {
    '& .MuiTableRow-root': {
      border: `1px solid ${colors.white_01}`,
      borderRadius: '8px',
      height: '30px',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: '#F6F9FB',
      borderRadius: '8px',
    },
  },
  tableCell: {
    cursor: 'pointer',
    ...typography.mainFont,
  },
  switchTableCell: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  tableCellRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    //justifyContent: 'space-between',
    cursor: 'pointer',
    //width: '120px',
    height: '10px',
  },
  enrichedText: {
    ...typography.mainFont,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '6px',
    color: colors.grey_02,
    cursor: 'pointer',
  },
  editIcon: {
    marginLeft: '5px',
  },
  nameContainer: {
    cursor: 'pointer',
  },
  titleStyle: {
    ...typography.mainFont,
    fontSize: 16,
    padding: '16px',
    margin: '0px',
    color: colors.grey_03,
    fontWeight: 500,
    borderBottom: '1px solid #ECECEC',
  },
  subtitle: {
    ...typography.mainFont,
    fontSize: '14px',
    color: colors.grey_02,
    fontWeight: 400,
    margin: '-10px 0px 0px 0px',
  },
  iconStyle: {
    marginLeft: '4px',
  },
  headerFunctionsRow: {
    height: '24px',
    padding: '0px 10px',
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '-24px 0px 0px',
  },
  tableHeaderStyle: {
    padding: '2px 16px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}));

interface Props {
  title?: string;
  subtitle?: string;
  headerData: Array<any>;
  data: any;
  dataControls: any;
  onRowPress: any;
  initSortColumn?: any;
  newItems?: Array<any>;
  loading: boolean;
  hideShadow?: boolean;
  refreshData?: any;
  setRating?: any;
  headerFunctions?: any;
  paginationControls?: any;
  hidePaginationControls?: boolean;
  search?: boolean;
  ratingType?: any;
  toggleComponent?: any;
  singleSelect?: boolean;
  rowsInput?: any;
  rowsInputOption?: any;
  getData?: any;
}

const GeneralTable = ({
  title,
  subtitle,
  headerData,
  data,
  dataControls,
  onRowPress,
  initSortColumn,
  loading,
  hideShadow,
  refreshData,
  setRating,
  headerFunctions,
  paginationControls,
  hidePaginationControls,
  search,
  toggleComponent,
  ratingType,
  singleSelect,
  rowsInput,
  rowsInputOption,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(rowsInput ? rowsInput : 25);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortColumn, setSortColumn] = useState(
    paginationControls?.sortKey ? paginationControls.sortKey : initSortColumn ? initSortColumn.replace('-', '') : '',
  );
  const [ascending, setAscending] = useState(initSortColumn?.includes('-') ? true : false);
  const [hoveredIndex, setHoveredIndex] = useState<any>(null);
  const [, updateState] = React.useState<any>();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  useEffect(() => {
    setCurrentPage(0);
  }, [dataControls.searchString, paginationControls]);

  useEffect(() => {
    if (rowsInput) {
      setRowsPerPage(rowsInput);
    }
  }, [rowsInput]);

  const apiPagination = (paginationDetails: any) => {
    const { tablePage, setTablePage, pageSize, setPageSize, totalItems } = paginationDetails;

    const pageRangeMin = pageSize * tablePage + 1;
    const pageRangeMax = (tablePage + 1) * pageSize < totalItems ? (tablePage + 1) * pageSize : totalItems;

    return (
      <TablePagination
        rowsPerPageOptions={rowsInputOption ? rowsInputOption : [25, 50, 100]}
        onRowsPerPageChange={(e) => setPageSize(e.target.value)}
        component="div"
        count={totalItems ?? 0}
        rowsPerPage={pageSize}
        labelDisplayedRows={() => {
          if (rowsPerPage) {
            const tableLabel = `${pageRangeMin}-${pageRangeMax} of ${totalItems}`;
            return tableLabel;
          } else {
            return 'None';
          }
        }}
        sx={{ ...typography.mainFont, color: colors.grey_02 }}
        page={tablePage}
        onPageChange={(e, page) => {
          return setTablePage(page);
        }}
      />
    );
  };

  const classes = useStyles();

  const toggleSelection = (event: any, row: any) => {
    event.stopPropagation();
    let newSelections = { ...dataControls.selections };

    if (dataControls.selections[row.id]) {
      delete newSelections[row.id];
    } else {
      //Maybe we can generalize this more?
      newSelections[row.id] = row;
    }
    dataControls.setSelections(newSelections);
  };

  const singleToggleSelection = (event: any, row: any) => {
    event.stopPropagation();
    let newSelection: any = {};
    console.log('dataControls.selections: ', dataControls.selections[row.id]);
    if (dataControls.selections[row.id] === row) {
      newSelection[row.id] = null;
    } else {
      newSelection[row.id] = row;
    }
    dataControls.setSelections(newSelection);
  };

  const updateRows = (e: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const filteredData = data; // data.filter(filter)
  const toggleAll = () => {
    if (allSelected) {
      dataControls.setSelections({});
    } else {
      let selectedRows: any = {};
      for (let row of data) {
        //ts-ignore
        selectedRows[row.id] = row;
      }
      dataControls.setSelections(selectedRows);
    }
  };
  const allSelected =
    Object.keys(dataControls.selections)?.length === filteredData?.length &&
    Object.keys(dataControls.selections)?.length > 0;

  const handleHeaderFunction = async (item: any) => {
    if (item.action) {
      item.action();
    } else {
      switch (item.name) {
        // case 'deleteAdvisors':
        //   setConfirmationInfo(item);
        //   break;
        // case 'deleteClients':
        //   setConfirmationInfo(item);
        //   break;
        default:
          console.warn('error with handleHeaderFunction');
      }
    }
  };

  const sortingManager = (headerItem: any, filteredData: any, ascending: boolean) => {
    if (paginationControls) {
      // const { setSortKey } = paginationControls;
      // let sortingValue = sortColumn;
      // if (ascending) {
      //   sortingValue = '-' + sortingValue;
      // }
      // setSortKey(sortingValue);
      return filteredData;
    } else {
      if (filteredData) {
        let newFilteredData = filteredData;
        if (dataControls?.searchString?.length > 0) {
          newFilteredData = filteredData.filter((item: any) => {
            return (
              item?.firstName?.toLowerCase().includes(dataControls?.searchString?.toLowerCase()) ||
              item?.lastName?.toLowerCase().includes(dataControls?.searchString?.toLowerCase()) ||
              item?.firmName?.toLowerCase().includes(dataControls?.searchString?.toLowerCase()) ||
              item?.name?.toLowerCase().includes(dataControls?.searchString?.toLowerCase())
            );
          });
        }
        return sortingHelper(sortColumn, newFilteredData, ascending, sortColumn.toLowerCase().includes('date'));
      } else {
        return [];
      }
    }
  };

  return (
    <div className={classes.tableContainer} style={{ boxShadow: hideShadow ? '' : colors.main_box_shadow }}>
      {title && <h2 className={classes.titleStyle}>{title}</h2>}
      {toggleComponent && <div>{toggleComponent()}</div>}
      <div className={classes.innerContainer}>
        {subtitle && (
          <div className={classes.tableHeaderStyle}>
            <div>{subtitle && <p className={classes.subtitle}>{subtitle}</p>}</div>
          </div>
        )}
        <div className={classes.headerRow}>
          {search && (
            <SearchBar
              placeholder={dataControls.searchPlaceholder ? dataControls.searchPlaceholder : 'Search for a name...'}
              searchText={dataControls.searchString}
              setSearchText={dataControls.searchFunc}
            />
          )}
          {headerFunctions && (
            <div className={classes.headerFunctionsRow}>
              {headerFunctions.map((item: any, index: number) => {
                return item?.icon ? (
                  <IconButton
                    style={{ margin: '0px 6px 0px 0px' }}
                    onClick={() => handleHeaderFunction(item)}
                    key={index + 1342392}
                  >
                    <img style={item.style} src={item.icon} alt={'Icon'} />
                  </IconButton>
                ) : null;
              })}
            </div>
          )}
        </div>
        <TableContainer sx={{ overflow: 'auto' }}>
          {loading ? (
            <div>
              {/* // <TableBody>
              //   <TableRow
              //     style={{ display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center', width: '100%' }}
              //   > */}
              <Loader width={'100px'} />
              {/* //   </TableRow>
              // </TableBody> */}
            </div>
          ) : (
            <Table
              stickyHeader
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  padding: '2px auto 2px auto',
                },
              }}
            >
              <>
                <TableHead>
                  <TableRow
                    sx={{
                      [`& .${tableCellClasses.root}`]: {
                        borderBottom: `1px solid ${colors.grey_01}`,
                        //height: '20px',
                        padding: '2px auto 2px auto',
                      },
                    }}
                  >
                    {/* ^^^^^^^^^^^^^^^ TABLE HEADER IS HERE ^^^^^^^^^^^^^^^*/}
                    {headerData.map((e: any, index: number) => {
                      if (e.type === 'checkbox') {
                        return (
                          <TableCell
                            className={classes.headerText}
                            key={index}
                            id={`table-header-${e.header ? e.header.replace(' ', '-') : e.key}`}
                          >
                            <Checkbox
                              onClick={toggleAll}
                              checked={allSelected}
                              style={{ margin: 'auto', color: allSelected ? '#5B8CFD' : '#E2E2E2' }}
                              disabled={singleSelect}
                            />
                          </TableCell>
                        );
                      } else if (e.header === 'AMP Score') {
                        return (
                          <TableCell
                            className={classes.headerScoreLogo}
                            key={index}
                            id={'table-header-' + e.header.replace(' ', '-')}
                            align="left"
                            onClick={() => {
                              if (e.key !== sortColumn && e.sortKey !== sortColumn) {
                                setSortColumn(e.sortKey ? e.sortKey : e.key);
                                setAscending(true);
                              } else {
                                setAscending(!ascending);
                              }
                            }}
                          >
                            <Row
                              style={{
                                margin: '0px 0px -10px 0px',
                              }}
                            >
                              <DistillScoreLogo packageName={e.package} />
                              <img className={classes.iconStyle} src={sortable} alt="info" />
                            </Row>
                          </TableCell>
                        );
                      } else if (e.header === 'Ignore This Fund') {
                        return (
                          <TableCell
                            className={
                              sortColumn === e.key || sortColumn === e.sortKey
                                ? classes.selectedHeaderText
                                : classes.headerText
                            }
                            key={index}
                            align="right"
                            id={'table-header-' + e.header.replace(' ', '-')}
                            onClick={() => {
                              if (e.key !== sortColumn && e.sortKey !== sortColumn) {
                                setSortColumn(e.sortKey ? e.sortKey : e.key);
                                setAscending(true);
                              } else {
                                setAscending(!ascending);
                              }
                            }}
                          >
                            <span
                              style={{ display: 'flex', justifyContent: 'flex-end' }}
                              id={'table-header-' + e.header.replace(' ', '-')}
                            >
                              <p
                                className={
                                  sortColumn === e.key || sortColumn === e.sortKey
                                    ? classes.selectedHeaderText
                                    : classes.headerText
                                }
                              >
                                {e.header}
                              </p>
                              {/* <img className={classes.iconStyle} src={sortable} alt="info" /> */}
                            </span>
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell
                            className={sortColumn === e.key ? classes.selectedHeaderText : classes.headerText}
                            key={index}
                            onClick={() => {
                              if (e.key !== sortColumn && e.sortKey !== sortColumn) {
                                setSortColumn(e.sortKey ? e.sortKey : e.key);
                                setAscending(true);
                              } else {
                                setAscending(!ascending);
                              }
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center', margin: '0px 0px -10px 0px' }}
                              id={'table-header-' + e.header.replace(' ', '-')}
                            >
                              <p
                                className={
                                  sortColumn === e.key || sortColumn === e.sortKey
                                    ? classes.selectedHeaderText
                                    : classes.headerText
                                }
                              >
                                {e.header}
                              </p>
                              {e.header !== 'Enriched Data' && e.header !== ' ' && e.type !== 'nonsortable' && (
                                <img className={classes.iconStyle} src={sortable} alt="info" />
                              )}
                              {e.header === 'Enriched Data' && (
                                <DetailedTooltip
                                  headerText={'How Enriched Data Works'}
                                  bodyText={
                                    'We enrich your Advisors with data from the AMP Data Lake. If data is not available on an advisor, try editing their email address or mailing address to get more data. '
                                  }
                                >
                                  <img className={classes.iconStyle} src={info} alt="info" />
                                </DetailedTooltip>
                              )}
                            </div>
                          </TableCell>
                        );
                      }
                    })}
                    {/* ^^^^^^^^^ TABLE HEADER IS HERE ^^^^^^^^^^^^ */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortingManager(sortColumn, filteredData, ascending)
                    ?.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                    ?.map((row: any, index: number) => {
                      return (
                        <TableRow
                          className={classes.tableRow}
                          key={index}
                          onClick={() => onRowPress(row)}
                          onMouseOver={() => setHoveredIndex(index)}
                          onMouseLeave={() => setHoveredIndex(null)}
                          id={`general-table-${index}`}
                        >
                          {headerData.map((headerInfo, headerIndex) => {
                            if (headerInfo.type === 'checkbox') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <div>
                                    <Checkbox
                                      onClick={(event: any) =>
                                        singleSelect ? singleToggleSelection(event, row) : toggleSelection(event, row)
                                      }
                                      checked={!!dataControls.selections[row.id]}
                                      style={{
                                        margin: 'auto',
                                        color: !!dataControls.selections[row.id] ? '#5B8CFD' : '#E2E2E2',
                                      }}
                                    />
                                  </div>
                                </TableCell>
                              );
                            }
                            if (headerInfo.key === 'rating') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <RatingButton saveRating={setRating} data={row} ratingType={ratingType} />
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'reserveTheme') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  sx={{ width: 280 }}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  {hoveredIndex === index && headerInfo.formatData(row, hoveredIndex === index)}
                                </TableCell>
                              );
                            } else if (headerInfo.formatData) {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <div
                                    style={{
                                      color: headerInfo.bold ? colors.grey_03 : colors.grey_02,
                                      fontWeight: headerInfo.bold ? '500' : '400',
                                      fontSize: headerInfo.big ? '24px' : '14px',
                                      padding: '0px 0px',
                                      marginBlockEnd: '0px',
                                      marginBlockStart: '4px',
                                      // lineHeight: '60px
                                    }}
                                  >
                                    {headerInfo.formatData(row, hoveredIndex === index)}
                                  </div>
                                </TableCell>
                              );
                            } else if (headerInfo.type === 'name') {
                              return (
                                <TableCell
                                  className={classes.tableCell}
                                  key={headerIndex}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  {row.firstName + ' ' + row.lastName}
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'lastName' || headerInfo.key === 'fullName') {
                              return (
                                <TableCell
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                  key={headerIndex}
                                  className={classes.nameContainer}
                                  onClick={() => onRowPress(row)}
                                >
                                  <p
                                    style={{
                                      color:
                                        (row['firstName'] && row['lastName']) || row['fullName']
                                          ? colors.grey_03
                                          : colors.grey_02,
                                      fontSize: '16px',
                                    }}
                                  >
                                    {formatFullName(row)}
                                  </p>
                                  <p style={{ color: colors.grey_02, fontSize: '12px', marginTop: '-18px' }}>
                                    {row.firmName ? row.firmName : row.email}
                                  </p>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'icon') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <IconButton onClick={() => onRowPress(row)}>
                                    <EditIcon sx={{ color: colors.grey_03 }} />
                                  </IconButton>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'score' || headerInfo.key === 'leadScore') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <p
                                    style={{
                                      color: colors.grey_03,
                                      fontWeight: '600',
                                      fontSize: '24px',
                                    }}
                                  >
                                    {row.score ? formatDistillScore(row.score) : row.leadScore ? row.leadScore : '0'}
                                    {/* <span
                                      style={{
                                        fontSize: '18px',
                                      }}
                                    >
                                      %
                                    </span> */}
                                  </p>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'enrichedData') {
                              return (
                                <TableCell
                                  key={headerIndex}
                                  className={classes.tableCell}
                                  id={`table-cell-${headerInfo?.key}-${index}`}
                                >
                                  <div className={classes.tableCellRow}>
                                    {headerInfo.formatData(row) ? (
                                      <>
                                        <CheckCircleOutlineIcon sx={{ color: colors.green }} />
                                        <div className={classes.enrichedText}>Available</div>
                                      </>
                                    ) : (
                                      <>
                                        <HighlightOffIcon sx={{ color: colors.grey_02 }} />
                                        <div className={classes.enrichedText}>Unavailable</div>
                                      </>
                                    )}
                                  </div>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'iconMore') {
                              return (
                                <TableCell key={headerIndex} className={classes.tableCell}>
                                  <IconButton className={classes.editIcon} onClick={() => onRowPress(row)}>
                                    <MoreHorizIcon sx={{ color: colors.black_01 }} />
                                  </IconButton>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'ticker') {
                              return (
                                <TableCell key={headerIndex} className={classes.tableCell}>
                                  <p style={{ color: '#758790' }}>{row[headerInfo.key]}</p>
                                </TableCell>
                              );
                            } else if (headerInfo.key === 'hidden') {
                              return (
                                <TableCell key={headerIndex} className={classes.switchTableCell} align="right">
                                  <Switch
                                    sx={{
                                      margin: '0 -55% 0 75%',
                                      '& .MuiSwitch-thumb': {
                                        color: colors.white_01,
                                      },
                                      '& .MuiSwitch-switchBase': {
                                        '&.Mui-checked': {
                                          //transform: 'translateX(12px)',
                                          //color: '#fff',
                                          '& + .MuiSwitch-track': {
                                            opacity: 1,
                                            backgroundColor: colors.sheer_blue,
                                          },
                                        },
                                      },
                                      '& .MuiSwitch-track': {
                                        backgroundColor: colors.grey_00,
                                      },
                                    }}
                                    checked={row.hidden}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      headerInfo.action([row.id], !row.hidden);
                                      row.hidden = !row.hidden;
                                      forceUpdate();
                                    }}
                                  />
                                </TableCell>
                              );
                            } else {
                              return (
                                <TableCell key={headerIndex} className={classes.tableCell}>
                                  <p style={{ color: '#758790', whiteSpace: 'nowrap' }}>{row[headerInfo.key]}</p>
                                </TableCell>
                              );
                            }
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </>
            </Table>
          )}
        </TableContainer>
        {hidePaginationControls ? (
          <div />
        ) : paginationControls ? (
          apiPagination(paginationControls)
        ) : (
          <TablePagination
            rowsPerPageOptions={rowsInputOption ? rowsInputOption : [25, 50, 100]}
            onRowsPerPageChange={(e: any) => setRowsPerPage(e.target.value)}
            component="div"
            count={data?.length ?? 0}
            rowsPerPage={rowsPerPage}
            sx={{ ...typography.mainFont, color: colors.grey_02, borderTop: `1px solid ${colors.grey_01}` }}
            page={currentPage}
            onPageChange={(e, page) => updateRows(e, page)}
          />
        )}
      </div>
    </div>
  );
};

export default GeneralTable;
