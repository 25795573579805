import React from 'react';
import { colors } from '../../styles';
import { H2, F3, PrimaryButton, Row } from '..';
import { styled } from '@mui/system';

import marketoLogo from '../../assets/logos/marketo-logo.svg';
import hubspotLogo from '../../assets/logos/hubspot-logo.svg';
import sendgridLogo from '../../assets/logos/Sendgrid-Logo.png';

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  width: '500px',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '-20px 0 0',
});

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '60px auto 0',
  alignItems: 'center',
  flexDirection: 'column',
});

const CRMLogos = styled('img')(`
  cursor: pointer;
  padding: 0px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0px -6px; 
  background: white;
  :hover {
    opacity: 0.7
  }
`);

interface Props {
  setModalPage: any;
}

function Landing({ setModalPage }: Props) {
  const landingOptions = {
    header: 'Integrate with an external marketing service',
    subHeader: 'Which service would you like to integrate with?',
    buttons: [
      {
        text: () => (
          <>
            <CRMLogos
              style={{
                height: '80px',
                width: '140px',
                padding: '0px 16px 0px',
                background: colors.white,
                objectFit: 'contain',
                margin: '25px 0px 0px',
                boxShadow: colors.main_box_shadow,
              }}
              src={marketoLogo}
              onClick={() => setModalPage('HubspotIntegrations')}
              alt="dynamics"
            />
          </>
        ),
        action: () => setModalPage('SampleSpreadsheet'),
      },
    ],
  };
  return (
    <div style={{ flex: 1, margin: '0px 0px 0px' }}>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{landingOptions.header}</H2>
        <F3 style={{ color: colors.grey_02 }}>{landingOptions.subHeader}</F3>
      </TextContainer>
      <Row style={{ flex: 1, justifyContent: 'space-between' }}>
        <CRMLogos style={{}} src={hubspotLogo} onClick={() => setModalPage('HubspotIntegrations')} alt="dynamics" />
        <CRMLogos
          style={{
            height: '80px',
            width: '140px',
            padding: '0px 16px 0px',
            background: colors.white,
            objectFit: 'contain',
            margin: '25px 0px 0px',
            boxShadow: colors.main_box_shadow,
          }}
          src={marketoLogo}
          onClick={() => setModalPage('MarketoIntegrations')}
          alt="dynamics"
        />
        <CRMLogos
          style={{
            height: '80px',
            width: '140px',
            padding: '0px 16px 0px',
            background: colors.white,
            objectFit: 'contain',
            margin: '25px 18px 0px',
            boxShadow: colors.main_box_shadow,
          }}
          src={sendgridLogo}
          onClick={() => setModalPage('SendgridIntegrations')}
          alt="Sendgrid"
        />
      </Row>
    </div>
  );
}

export default Landing;
