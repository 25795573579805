import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ModalPageType, PageCopyType } from '../../types';
import { colors } from '../../styles';
import { CardContainer, CardHeader, F3, H2, Row, PrimaryButtonShort } from '..';
import { formatTimestamp } from '../../helpers/dataFormatter';
import PackageUsageCard from '../PackageUsageCard';
import { SelectionTable } from '../Table/SelectionTable';
import UploadModal from '../UploadModal';
import { GET_ADVISOR_SETS } from '../../api/dataAPI';
import { useQuery } from '@apollo/client';

interface Props {
  pageCopy: PageCopyType;
  packageUsage?: any;
  customAction?: any;
  nextAction: any;
  advisorSetSelected: any;
  setAdvisorSetSelected: any;
  setNewJourneyName?: any;
}

const Root = styled('div')({
  padding: '30px 25px 0px',
});

export const PackageSelectAdvisorSet = ({
  pageCopy,
  packageUsage,
  nextAction,
  customAction,
  advisorSetSelected,
  setAdvisorSetSelected,
  setNewJourneyName,
}: Props) => {
  const [searchString, setSearchString] = React.useState<string>('');
  const [uploadModalPage, setUploadModalPage] = React.useState<ModalPageType>(null);
  const [usage, setUsage] = React.useState<any>(0);

  const headerData = [
    { type: 'checkbox', key: '', sortKey: '' },
    {
      header: 'Set name',
      key: 'name',
      sortKey: 'name',
    },
    {
      header: 'Date Created',
      key: 'dateCreated',
      sortKey: 'date_created',
      formatData: (row: any) => {
        return row?.startDate ? formatTimestamp(row.startDate) : formatTimestamp(row.dateCreated);
      },
    },
    {
      header: 'Date Updated',
      key: 'dateUpdated',
      sortKey: 'date_updated',
      formatData: (row: any) => {
        return row?.startDate ? formatTimestamp(row.startDate) : formatTimestamp(row.dateCreated);
      },
    },
    {
      header: 'Source',
      key: 'filterType',
      sortKey: 'filter_type',
    },
    {
      header: '# of Prospects Matched',
      key: 'distinctMemberCount',
      sortKey: 'distinct_member_count',
    },
  ];

  const selectAdvisorSet = (selectedSet: any) => {
    const set: any = Object.values(selectedSet)?.[0];
    setUsage(set?.distinctMemberCount ? set?.distinctMemberCount : 0);
    setAdvisorSetSelected(selectedSet);
    if (setNewJourneyName) {
      const newJourneyName = set?.name ? set?.name : 'Unnamed Journey';
      setNewJourneyName(newJourneyName);
    }
  };

  const { data, error, loading, refetch } = useQuery(GET_ADVISOR_SETS);

  return (
    <Root>
      <H2 style={{ color: colors.grey_03 }}>{pageCopy?.header}</H2>
      <F3 style={{ color: colors.grey_02, width: '800px', margin: '0px 0px 30px' }}>{pageCopy?.subheader}</F3>
      {packageUsage && (
        <PackageUsageCard
          index={0}
          label={'Influence Usage'}
          data={{
            ...packageUsage,
            usage,
            total: packageUsage?.total - usage,
            percent: (usage / packageUsage?.total) * 100,
          }}
        />
      )}
      <CardContainer>
        <CardHeader style={{ justifyContent: 'space-between' }}>
          <H2 style={{ margin: '0px', padding: '0px' }}>{'Advisor Sets'}</H2>
          <Row>
            <PrimaryButtonShort
              style={{ margin: '0px 8px', backgroundColor: colors.grey_02, borderColor: colors.grey_02 }}
              onClick={() => setUploadModalPage('UploadFile')}
            >
              Upload Set From CSV
            </PrimaryButtonShort>
            <PrimaryButtonShort
              style={{ margin: '0px 8px', backgroundColor: colors.grey_02, borderColor: colors.grey_02 }}
              onClick={() => setUploadModalPage('CRMSelection')}
            >
              Pull Set From CRM
            </PrimaryButtonShort>
            {customAction && (
              <PrimaryButtonShort
                style={{ margin: '0px 0px 0px 8px' }}
                onClick={customAction?.onClick}
                id="selection-custom"
              >
                {customAction?.label}
              </PrimaryButtonShort>
            )}
            <PrimaryButtonShort
              style={{ margin: '0px 0px 0px 8px' }}
              onClick={nextAction}
              disabled={Object.values(advisorSetSelected)?.length === 0}
              id="selection-continue"
            >
              Continue
            </PrimaryButtonShort>
          </Row>
        </CardHeader>
        <SelectionTable
          data={data?.advisorSets ?? []}
          loading={loading}
          dataControls={{
            selections: advisorSetSelected,
            setSelections: selectAdvisorSet,
            searchFunc: setSearchString,
            searchString,
            searchPlaceholder: 'Search for an advisor set',
          }}
          maxSelections={1}
          paginationControls={null}
          headerData={headerData}
        />
      </CardContainer>
      <UploadModal
        modalPage={uploadModalPage}
        setModalPage={setUploadModalPage}
        closeModal={() => {
          refetch();
          setUploadModalPage(null);
        }}
      />
    </Root>
  );
};
