import { states, firmAUM, age, firmAccounts, type, income, wealth, custodian } from '../../helpers/menuOptions';
import { firmNames } from '../../helpers/firmNameMenuOptions';

export const selectFields: any = {
  //advisor needs to be cross checked with actual query data
  advisorLeadPool: [
    {
      list: true,
      title: 'Firm AUM',
      options: firmAUM,
      multiple: true,
    },
    {
      list: true,
      title: 'Firm Accounts',
      options: firmAccounts,
      multiple: true,
    },
    {
      list: true,
      title: 'Type (BD/RIA)',
      options: type,
      multiple: true,
    },
    {
      list: true,
      title: 'Location',
      options: states,
      multiple: true,
    },
    {
      list: true,
      title: 'Custodian',
      options: custodian,
      multiple: true,
    },
    {
      list: true,
      search: true,
      title: 'Firm name',
      options: firmNames,
      multiple: true,
    },
  ],
  individualLeadPool: [
    {
      list: true,
      title: 'Age',
      options: age,
    },
    {
      list: true,
      title: 'Income',
      options: income,
    },
    {
      list: true,
      title: 'Wealth',
      options: wealth,
    },
    {
      list: true,
      title: 'Location',
      options: states,
    },
  ],
  crossSellLeadPool: [
    {
      list: true,
      title: 'Age',
      options: age,
      input: true,
    },
    {
      list: true,
      title: 'Income',
      options: income,
      multiple: true,
    },
    {
      list: true,
      title: 'Wealth',
      options: wealth,
      multiple: true,
    },
  ],
  prioritizeProspectsLeadPool: [
    {
      list: true,
      title: 'Age',
      options: age,
      input: true,
    },
    {
      list: true,
      title: 'Income',
      options: income,
      multiple: true,
    },
    {
      list: true,
      title: 'Wealth',
      options: wealth,
      multiple: true,
    },
  ],
  advisor: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'age',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'aum_self_reported',
      averageDataKey: 'aum_self_reported',
    },
    {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firm_total_assets_in_millions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'career_tenure',
      averageDataKey: 'career_tenure',
    },
    {
      displayText: 'Firm ETF Allocation',
      title: 'Firm ETF Allocation',
      key: 'firmPercentAssetsEtf',
      dataKey: 'firm_percent_assets_etf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_etf',
    },
    {
      displayText: 'Firm MF Allocation',
      title: 'Firm MF Allocation',
      key: 'firmPercentAssetsMf',
      dataKey: 'firm_percent_assets_mf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_mf',
    },
    {
      displayText: '# Advised Clients',
      title: '# Advised Clients',
      key: 'advisedInvestorsCnt',
      type: 'percent',
      dataKey: 'advisedInvestorsCnt',
      averageDataKey: 'advised_investors_cnt',
    },
  ],
  lplAdvisor: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'age',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'aumSelfReported',
      averageDataKey: 'aum_self_reported',
    },
    {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firmTotalAssetsInMillions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'careerTenure',
      averageDataKey: 'career_tenure',
    },
    {
      displayText: 'LPL Tenure',
      title: 'LPL Tenure',
      key: 'lplTenure',
      dataKey: 'advisorLplTenure',
      averageDataKey: 'lplTenure',
    },
    {
      displayText: 'ETF Assets',
      title: 'ETF Assets',
      key: 'lplEtfAssets',
      dataKey: 'etfAssets',
      type: 'dollar',
      averageDataKey: 'lplEtfAssets',
    },
    {
      displayText: 'Cash Assets',
      title: 'Cash Assets',
      key: 'lplCashAssets',
      dataKey: 'cashAssets',
      type: 'dollar',
      averageDataKey: 'lplCashAssets',
    },
    {
      displayText: '# Advised Clients',
      title: '# Advised Clients',
      key: 'advisedInvestorsCnt',
      type: 'percent',
      dataKey: 'advisedInvestorsCnt',
      averageDataKey: 'advisedInvestorsCnt',
    },
    {
      displayText: 'Net New Assets (Past Year)',
      title: 'Net New Assets (Past Year)',
      key: 'trailingTwelveMonthTotalNetNewAssets',
      type: 'percent',
      dataKey: 'trailingTwelveMonthTotalNetNewAssets',
      averageDataKey: 'trailingTwelveMonthTotalNetNewAssets',
    },
  ],
  lplPrioritizeAdvisor: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'age',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'total_assets',
      averageDataKey: 'total_assets',
    },
    {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firm_total_assets_in_millions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'career_tenure',
      averageDataKey: 'career_tenure',
    },
    {
      displayText: 'LPL Tenure',
      title: 'LPL Tenure',
      key: 'lplTenure',
      dataKey: 'advisor_lpl_tenure',
      averageDataKey: 'advisor_lpl_tenure',
    },
    {
      displayText: 'ETF Assets',
      title: 'ETF Assets',
      key: 'lplEtfAssets',
      dataKey: 'etf_assets',
      type: 'dollar',
      averageDataKey: 'etf_assets',
    },
    {
      displayText: 'Cash Assets',
      title: 'Cash Assets',
      key: 'lplCashAssets',
      type: 'dollar',
      dataKey: 'cash_assets',
      averageDataKey: 'cash_assets',
    },
    {
      displayText: 'Net New Assets (Past Year)',
      title: 'Net New Assets (Past Year)',
      key: 'trailingTwelveMonthTotalNetNewAssets',
      type: 'percent',
      dataKey: 'trailing_twelve_month_total_net_new_assets',
      averageDataKey: 'trailing_twelve_month_total_net_new_assets',
    },
  ],
  advisedClients: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'advisorClientsAndTifinByAge',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'Income',
      title: 'Income',
      key: 'advisorClientsAndTifinByIncome',
      dataKey: 'income',
      type: 'dollar',
      averageDataKey: 'income',
    },
    {
      displayText: 'Estimated Assets',
      title: 'Estimated Assets',
      key: 'advisorClientsAndTifinByInvestableAssets',
      dataKey: 'estimated_investable_assets',
      type: 'dollar',
      averageDataKey: 'estimated_investable_assets',
    },
    {
      displayText: 'Home Value',
      title: 'Home Value',
      key: 'advisorClientsAndTifinByHomeValue',
      dataKey: 'home_value',
      type: 'dollar',
      averageDataKey: 'home_value',
    },
    {
      displayText: 'Percent of Mortgage Paid',
      title: 'Mortgage Paid',
      key: 'advisorClientsAndTifinByPercOfHomeValuePaidOff',
      type: 'percent',
      dataKey: 'percent_of_home_value_paid_off',
      averageDataKey: 'percent_of_home_value_paid_off',
    },
    {
      displayText: 'Financial Planning Score',
      title: 'Financial Planning',
      key: 'advisorClientsAndTifinByFinPlanScore',
      dataKey: 'financial_planning_tendency_score',
      type: 'score',
      averageDataKey: 'financial_planning_tendency_score',
    },
    {
      displayText: 'Credit Score Proxy',
      title: 'Credit Score Proxy',
      key: 'advisorClientsAndTifinByProxyCreditScore',
      dataKey: 'proxy_credit_score',
      type: 'score',
      averageDataKey: 'proxy_credit_score',
    },
    {
      displayText: 'Future Assets',
      title: 'Future Assets',
      key: 'advisorClientsAndTifinByRetirementAssets',
      dataKey: 'projected_retirement_assets',
      type: 'dollar',
      averageDataKey: 'projected_retirement_assets',
    },
  ],
  advisorClients: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'age',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firm_total_assets_in_millions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'career_tenure',
      averageDataKey: 'career_tenure',
    },
    {
      displayText: 'Firm ETF Allocation',
      title: 'Firm ETF Allocation',
      key: 'firmPercentAssetsEtf',
      dataKey: 'firm_percent_assets_etf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_etf',
    },
    {
      displayText: 'Firm MF Allocation',
      title: 'Firm MF Allocation',
      key: 'firmPercentAssetsMf',
      dataKey: 'firm_percent_assets_mf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_mf',
    },
  ],
  prioritizeAdvisors: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'age',
      dataKey: 'advisor_age',
      averageDataKey: 'age',
    },
    // {
    //   displayText: 'AUM',
    //   title: 'AUM',
    //   key: 'aum_self_Reported',
    //   dataKey: 'aum_self_reported',
    //   averageDataKey: 'aum_self_reported',
    // },
    {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firm_total_assets_in_millions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'career_tenure',
      averageDataKey: 'career_tenure',
    },
    {
      displayText: 'Firm ETF Allocation',
      title: 'Firm ETF Allocation',
      key: 'firmPercentAssetsEtf',
      dataKey: 'firm_percent_assets_etf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_etf',
    },
    {
      displayText: 'Firm MF Allocation',
      title: 'Firm MF Allocation',
      key: 'firmPercentAssetsMf',
      dataKey: 'firm_percent_assets_mf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_mf',
    },
  ],
  crossSell: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'advisorClientsAndTifinByAge',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'Income',
      title: 'Income',
      key: 'advisorClientsAndTifinByIncome',
      dataKey: 'income',
      type: 'dollar',
      averageDataKey: 'income',
    },
    {
      displayText: 'Estimated Assets',
      title: 'Estimated Assets',
      key: 'advisorClientsAndTifinByInvestableAssets',
      dataKey: 'estimated_investable_assets',
      type: 'dollar',
      averageDataKey: 'estimated_investable_assets',
    },
    {
      displayText: 'Home Value',
      title: 'Home Value',
      key: 'advisorClientsAndTifinByHomeValue',
      dataKey: 'home_value',
      type: 'dollar',
      averageDataKey: 'home_value',
    },
    {
      displayText: 'Percent of Mortgage Paid',
      title: 'Mortgage Paid',
      key: 'advisorClientsAndTifinByPercOfHomeValuePaidOff',
      type: 'percent',
      dataKey: 'percent_of_home_value_paid_off',
      averageDataKey: 'percent_of_home_value_paid_off',
    },
    {
      displayText: 'Financial Planning Score',
      title: 'Financial Planning',
      key: 'advisorClientsAndTifinByFinPlanScore',
      dataKey: 'financial_planning_tendency_score',
      type: 'score',
      averageDataKey: 'financial_planning_tendency_score',
    },
    {
      displayText: 'Credit Score Proxy',
      title: 'Credit Score Proxy',
      key: 'advisorClientsAndTifinByProxyCreditScore',
      dataKey: 'proxy_credit_score',
      type: 'score',
      averageDataKey: 'proxy_credit_score',
    },
    {
      displayText: 'Future Assets',
      title: 'Future Assets',
      key: 'advisorClientsAndTifinByRetirementAssets',
      dataKey: 'projected_retirement_assets',
      type: 'dollar',
      averageDataKey: 'projected_retirement_assets',
    },
  ],
  individual: [
    {
      displayText: 'Age',
      title: 'Age',
      key: 'advisorClientsAndTifinByAge',
      dataKey: 'age',
      averageDataKey: 'age',
    },
    {
      displayText: 'Income',
      title: 'Income',
      key: 'advisorClientsAndTifinByIncome',
      dataKey: 'income',
      type: 'dollar',
      averageDataKey: 'income',
    },
    {
      displayText: 'Estimated Assets',
      title: 'Estimated Assets',
      key: 'advisorClientsAndTifinByInvestableAssets',
      dataKey: 'estimated_investable_assets',
      type: 'dollar',
      averageDataKey: 'estimated_investable_assets',
    },
    {
      displayText: 'Home Value',
      title: 'Home Value',
      key: 'advisorClientsAndTifinByHomeValue',
      dataKey: 'home_value',
      type: 'dollar',
      averageDataKey: 'home_value',
    },
    {
      displayText: 'Percent of Mortgage Paid',
      title: 'Mortgage Paid',
      key: 'advisorClientsAndTifinByPercOfHomeValuePaidOff',
      type: 'percent',
      dataKey: 'percent_of_home_value_paid_off',
      averageDataKey: 'percent_of_home_value_paid_off',
    },
    {
      displayText: 'Financial Planning Score',
      title: 'Financial Planning',
      key: 'advisorClientsAndTifinByFinPlanScore',
      dataKey: 'financial_planning_tendency_score',
      type: 'score',
      averageDataKey: 'financial_planning_tendency_score',
    },
    {
      displayText: 'Credit Score Proxy',
      title: 'Credit Score Proxy',
      key: 'advisorClientsAndTifinByProxyCreditScore',
      dataKey: 'proxy_credit_score',
      type: 'score',
      averageDataKey: 'proxy_credit_score',
    },
    {
      displayText: 'Future Assets',
      title: 'Future Assets',
      key: 'advisorClientsAndTifinByRetirementAssets',
      dataKey: 'projected_retirement_assets',
      type: 'dollar',
      averageDataKey: 'projected_retirement_assets',
    },
  ],
};
