import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
// import { ButtonUnstyled as Button } from '@mui/core';
import { H2, F3, F4, Row, PrimaryButton, MainTextInput, Column } from '..';
import { colors } from '../../styles';
import { styled } from '@mui/material/styles';
import { Popper, Fade, IconButton } from '@mui/material';
import closeIcon from '../../assets/icons/close.svg';
import { GET_ADVISOR_EMAIL } from '../../api/integrations';
import { useLazyQuery } from '@apollo/client';
import Loader from '../Loader';

const Root = styled('div')({
  display: 'flex',
  flex: 1,
  width: '100%',
  flexDirection: 'column',
});
const DataTable = styled('div')({
  display: 'table',
  justifyContent: 'space-between',
  borderRadius: '5px',
  width: '90%',
  margin: '0px auto 0px',
  overflow: 'auto',
  flex: 1,
});

const EditHeaders = styled('div')({
  borderBottom: '1px solid #ECECEC',
  margin: '0 auto 0px',
  height: '40px',
  paddingBottom: 10,
  justifyContent: 'flex-start',
  alignItems: 'center',
  display: 'flex',
});

const FieldContainer = styled('div')({
  maxHeight: 240,
  overflowY: 'auto',
});

const FieldTitleContainer = styled('div')({
  display: 'flex',
});

const FieldDataName = styled('p')({
  fontSize: 14,
  width: 160,
  overflow: 'hidden',
  maxHeight: '30px',
  minHeight: '30px',
  fontWeight: '400',
  textAlign: 'left',
  color: colors.grey_02,
  backgroundColor: colors.grey_01,
  textOverflow: 'ellipsis',
  // border: `1px solid #fff`,
  lineHeight: '20px',
  borderRadius: 5,
  margin: '6px 6px 0px 0px',
  cursor: 'pointer',
  padding: '5px 10px',
  '&:hover': {
    backgroundColor: colors.grey_06,
  },
});

const EditContentContainer = styled('div')({
  width: '220px',
  padding: '8px 20px 14px',
  backgroundColor: '#fff',
  borderRadius: '5px',
  boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
});

const EditPopper = styled(Popper)({
  zIndex: 2000,
});

type Props = {
  uploadedAdvisors: any;
  setUploadedAdvisors: any;
  uploadAdvisorDataset: any;
  setUploadedFile: any;
};

const ManualInput = ({ uploadAdvisorDataset, uploadedAdvisors, setUploadedAdvisors, setUploadedFile }: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editField, setEditField] = useState('');
  const [placement, setPlacement] = useState();
  const [currentIndex, setCurrentIndex] = useState<any>(null);
  const [editModalValue, setEditModalValue] = useState<any>(null);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [selectedAdvisors, setSelectedAdvisors] = useState<number>(0);
  const [newAdvisorCrd, setNewAdvisorCrd] = useState<string>('');
  const [loadingAdvisorData, setLoadingAdvisorData] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // console.log('uploadedAdvisors: ', uploadedAdvisors);
  useEffect(() => {}, []);

  const manualAdvisorUpload = () => {
    const formattedAdvisorData = uploadedAdvisors.map((row: any, index: number) => ({
      repCrd: row?.repCrd,
      email: `test_email${row?.repCrd}@test.com`,
    }));
    let csvContent = formattedAdvisorData.map((row: any) => Object.values(row).join(',')).join('\n');

    const csvHeaders = ['repCrd', 'email', '\n'];
    csvContent = csvHeaders.join(',') + csvContent;

    console.log('csv content right before upload:', csvContent);

    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    let file = new File([blob], 'advisors.csv', { type: 'text/csv;charset=utf-8;' });
    setUploadedFile(file);

    const defaultFieldMappings = [
      { srcKey: 'repCrd', dstKey: 'repcrd' },
      //   { srcKey: 'firstName', dstKey: 'first_name' },
      //   { srcKey: 'lastName', dstKey: 'last_name' },
      { srcKey: 'email', dstKey: 'email' },
    ];

    uploadAdvisorDataset('Manually Input Advisor', defaultFieldMappings, file);
  };

  const [getAdvisorEmail, { loading: advisorLoading, data: advisorData, error: advisorError }] = useLazyQuery(
    GET_ADVISOR_EMAIL,
    {
      onCompleted: (data) => {
        if (data?.advisorEmail) {
          const newUploadedAdvisors = [...uploadedAdvisors, { repCrd: newAdvisorCrd, email: data?.advisorEmail }];
          setUploadedAdvisors(newUploadedAdvisors);
          setNewAdvisorCrd('');
          setLoadingAdvisorData(false);
        } else {
          setLoadingAdvisorData(false);
          setError('Advisor CRD not found. Please try again.');
        }
      },
    },
  );

  const getAdvisor = async () => {
    setLoadingAdvisorData(true);
    if (newAdvisorCrd?.length < 3) {
      setError('Advisor CRD must be at least 3 digits long.');
      setLoadingAdvisorData(false);
      return null;
    }

    try {
      getAdvisorEmail({ variables: { repCrd: Number(newAdvisorCrd) } });
    } catch (error: any) {
      setError(error?.message);
      setLoadingAdvisorData(false);
    }
  };

  const closeEditPopper = () => {
    setOpen(!open);
    setTimeout(() => setEditField(''), 300);
  };

  const saveDataValue = (data: string) => {
    let currentFields = uploadedAdvisors[currentIndex];
    if (data === 'Name') currentFields['fullName'] = editModalValue;
    else if (data === 'First Name') currentFields['firstName'] = editModalValue;
    else if (data === 'Last Name') currentFields['lastName'] = editModalValue;
    else if (data === 'Email') currentFields['email'] = editModalValue;
    else if (data === 'Investor Email') currentFields['investorEmail'] = editModalValue;

    let output = [...uploadedAdvisors];
    output[currentIndex] = currentFields;
    setUploadedAdvisors(output);
    setOpen(!open);
  };

  const openEditPopper = (newPlacement: any, fieldToEdit: any, initialValue: any, index: number) => (event: any) => {
    if (open) {
      closeEditPopper();
    } else {
      setEditField(fieldToEdit);
      setCurrentIndex(index);
      setAnchorEl(event.currentTarget);
      setEditModalValue(initialValue);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    }
  };

  const removeAdvisor = (index: number) => {
    return setUploadedAdvisors(uploadedAdvisors.filter((row: any, i: number) => i !== index));
  };

  console.log('uploaded advisors: ', uploadedAdvisors, newAdvisorCrd);

  return (
    <Root>
      <Column style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', padding: '0px 28px' }}>
        <Column>
          <H2 style={{ margin: '10px 0px 10px' }}>Manually Add Advisors</H2>
          <F3 style={{ color: colors.grey_02, margin: '0px 0px 20px' }}>
            Use the input below to manually add advisors.
          </F3>
          <Row style={{ display: 'flex', flex: 1, alignItems: 'flex-start' }}>
            <MainTextInput
              style={{ width: '200px' }}
              size="small"
              focused
              label={'Advisor CRD'}
              value={newAdvisorCrd}
              onChange={(event) => setNewAdvisorCrd(event.target.value)}
            />
            <PrimaryButton style={{ width: '100px', height: '40px', margin: '12px 16px' }} onClick={getAdvisor}>
              {loadingAdvisorData ? (
                <Loader color={colors.white} hideText inputMargin={{ margin: '0px 0px' }} size={'10px'} />
              ) : (
                'Add Advisor'
              )}
            </PrimaryButton>
          </Row>
          {error && <F3 style={{ color: colors.red }}>{error}</F3>}
        </Column>
      </Column>
      {uploadedAdvisors?.length ? (
        <DataTable>
          <EditHeaders>
            <FieldTitleContainer style={{ width: '120px' }}>
              <F3 style={{ color: colors.grey_02 }}>Advisor CRD</F3>
            </FieldTitleContainer>
            {/* <FieldTitleContainer style={{ width: '120px' }}>
              <F3 style={{ color: colors.grey_02 }}>First Name</F3>
            </FieldTitleContainer>
            <FieldTitleContainer style={{ width: '120px' }}>
              <F3 style={{ color: colors.grey_02 }}>Last Name</F3>
            </FieldTitleContainer> */}
            <FieldTitleContainer style={{ width: '280px' }}>
              <F3 style={{ color: colors.grey_02 }}>Email</F3>
            </FieldTitleContainer>
          </EditHeaders>
          <FieldContainer>
            {uploadedAdvisors.map((rowItem: any, index: number) => {
              return (
                <Row style={{ margin: 'auto', alignItems: 'center', height: '36px' }} key={index}>
                  <FieldDataName
                    onClick={openEditPopper('bottom-start', 'Advisor CRD', rowItem?.repCrd, index)}
                    style={{ width: '115px' }}
                  >
                    {rowItem?.repCrd}
                  </FieldDataName>
                  {/* <FieldDataName
                    onClick={openEditPopper('bottom-start', 'First Name', rowItem?.firstName, index)}
                    style={{ width: '115px' }}
                  >
                    {rowItem?.firstName ? rowItem?.firstName : '-'}
                  </FieldDataName>
                  <FieldDataName
                    onClick={openEditPopper('bottom-start', 'Last Name', rowItem?.lastName, index)}
                    style={{ width: '115px' }}
                  >
                    {rowItem?.lastName ? rowItem?.lastName : '-'}
                  </FieldDataName> */}
                  <FieldDataName
                    onClick={openEditPopper('bottom-start', 'Email', rowItem?.email, index)}
                    style={{ width: '450px' }}
                  >
                    {rowItem?.email ? rowItem?.email : '-'}
                  </FieldDataName>
                  <IconButton sx={{ margin: '8px 0px 0px 4px', height: '10px', width: '10px' }}>
                    <img src={closeIcon} onClick={() => removeAdvisor(index)} alt={'Remove advisor'} />
                  </IconButton>
                </Row>
              );
            })}
          </FieldContainer>
        </DataTable>
      ) : (
        <div></div>
      )}
      <Row
        style={{
          justifyContent: 'flex-end',
          width: '102%',
          margin: '0px 0px',
          borderTop: `1px solid ${colors.grey_01}`,
        }}
      >
        <F4 style={{ color: colors.grey_02, margin: '17px 16px 0px' }}>
          {uploadedAdvisors?.length} advisors to upload
        </F4>
        <PrimaryButton
          style={{ width: '120px', margin: '6px -4px 0px 0px' }}
          children="Import"
          onClick={() => manualAdvisorUpload()}
        />
      </Row>
      <EditPopper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <EditContentContainer>
              <MainTextInput
                id="outlined-select"
                // className={classes.editTextField}
                label={editField}
                size="small"
                value={editModalValue}
                onChange={(event) => setEditModalValue(event.target.value)}
                sx={{ zIndex: 50000 }}
              />
              <Column>
                <PrimaryButton
                  style={{
                    margin: '2px 0px',
                  }}
                  onClick={() => saveDataValue(editField)}
                >
                  Save
                </PrimaryButton>
                <PrimaryButton
                  style={{
                    background: colors.grey_02,
                    margin: '2px 0px',
                    border: `1px solid ${colors.grey_02}`,
                  }}
                  onClick={closeEditPopper}
                >
                  Cancel
                </PrimaryButton>
              </Column>
            </EditContentContainer>
          </Fade>
        )}
      </EditPopper>
    </Root>
  );
};

export default ManualInput;
