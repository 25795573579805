import React, { useEffect, useState } from 'react';
import { NavBar } from '../components/NavBar';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { formatSubscriptions } from '../helpers/dataFormatter';
import { useStoreActions, AppType } from '../store';
import { useNavigate, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import Loader from '../components/Loader';
import { LOAD_PROFILE } from '../api/auth';
// import { AccessToken, IDToken, TokenManagerInterface, UserClaims } from '@okta/okta-auth-js';
// import { ok } from 'assert';

const appType: AppType = process.env.REACT_APP_THEME === 'LPL' ? 'LPL' : 'TIFIN';

type Props = {};

const Root = styled('div')(`
    display: flex;
    flex-direction: row; 
    flex: 1;
`);

export const AppContainer = (props: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [refetched, setRefetched] = useState(false);

  const { setUser, setSubscriptions, setDemoAccount } = useStoreActions((actions) => actions.user);
  const { setAppType } = useStoreActions((actions) => actions);

  const { loading, refetch } = useQuery(LOAD_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: async (data) => {
      const userOrganization = data?.meAssetManager?.organization;
      const email = data?.user?.email;
      let loginInfo = {
        user: { ...data?.meAssetManager, ...data?.user },
        success: true,
        organization: data?.meAssetManager?.organization,
      };
      datadogRum.setUser({ email });
      setUser(loginInfo);
      setSubscriptions(formatSubscriptions(data?.mySubscriptions ?? []));
      setDemoAccount(userOrganization?.isDemoOrg);
      // Sentry.setUser({ username: email, email: email });
      const currentPath = location.pathname;
      if (currentPath === '/') {
        navigate('/home');
        setRefetched(false);
      }
      let newAppType: AppType = userOrganization?.name === 'Franklin Templeton' ? 'FT' : appType;
      setAppType(newAppType);
    },
    onError: (error) => {
      console.warn('error checking user information: ', error);
      if (error.message.includes('You do not have permission to perform this action')) {
        navigate('/login');
      }
      if (!refetched) {
        setTimeout(() => {
          // getNewToken();
          setRefetched(true);
        }, 1000);
      }
    },
  });

  return (
    <Root>
      {loading ? (
        <div style={{ margin: '300px auto' }}>
          <Loader />
        </div>
      ) : (
        <>
          <NavBar />
          <div
            style={{
              position: 'absolute',
              left: 260,
              width: 'calc(100% - 260px)',
              zIndex: '1',
              minWidth: '880px',
              minHeight: '500px',
            }}
          >
            <Outlet />
          </div>
        </>
      )}
    </Root>
  );
};
