/* eslint-disable import/no-anonymous-default-export */
import { gql } from '../__generated__';
import { OktaAuth } from '@okta/okta-auth-js';

const issuer = process.env.REACT_APP_OKTA_ISSUER ?? '';
const clientId = process.env.REACT_APP_OKTA_CLIENT_ID ?? '';

export const oktaClient = new OktaAuth({
  issuer,
  clientId,
  redirectUri: window.location.protocol + '//' + window.location.host + '/login/callback',
  pkce: true,
});

export const checkLoggedOut = (message: string) => {
  // console.log('check loggout message', message);
  if (
    message &&
    (message.includes('Error decoding signature') ||
      message.includes('You do not have permission to perform this action') ||
      message.includes('Signature has expired'))
  ) {
    return true;
  }
  return false;
};

export const LOAD_PROFILE = gql(`
  query loadProfile {
    meAssetManager {
      firstName
      lastName
      phone
      timezone
      organization {
        name
        id
        isDemoOrg
        logoFile
        logoHeight
        logoWidth
        dateUpdated
        dateCreated
        backgroundColor
      }
    }
    user {
      isStaff
      email
      impersonationlink {
        id
        amProfile {
          organization {
            name
            id
            isDemoOrg
            logoFile
            logoHeight
            logoWidth
            dateUpdated
            dateCreated
            backgroundColor
          }
        }
      }
    }
    mySubscriptions {
      package
      quota
      isDemo
    }
  }
`);

export const USER_LOGIN = gql(`
  mutation userLogin ($username: String!, $password: String!) {
    tokenAuth(username:$username, password:$password) {
      token
      refreshToken
    }
  }
`);

export const USER_REFRESH = gql(`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      payload
      refreshExpiresIn
      refreshToken
      token
    }
  }
`);

export const USER_LOGOUT = gql(`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`);

export const GET_SUBSCRIPTIONS = gql(`
  query getMySubscriptions {
    mySubscriptions {
      package
      quota
      isDemo
    }
  }
`);

export const UPDATE_USER_PROFILE = gql(`
  mutation updateUserProfile($profileData: AssetManagerProfileUpdateInput!) {
    updateAssetManagerProfile(profileData: $profileData) {
      assetManagerProfile {
        firstName
        lastName
        phone
        timezone
      }
    }
  }
`);

export const RESET_PASSWORD = gql(`
  mutation setPasswordNewUser($emailAddress: String!) {
    resetPassword(emailAddress:$emailAddress){
      success
    }
  }
`);
