import React, { useState, useLayoutEffect } from 'react';
import { makeStyles } from '@mui/styles';
import lightPurple from '../assets/icons/purple-light.svg';
import { colors, typography } from '../styles';
import GeneralRadar from './GeneralRadar';
import { H2 } from '.';

const useStyles = makeStyles((theme) => ({
  outerRadarContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  radarContainers: {
    width: '33.33333%',
  },
  expandIcon: {
    marginRight: 40,
    width: 20,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.7,
    },
  },
  resultsBox: {
    boxShadow: colors.main_box_shadow,
    borderRadius: '5px',
    width: '100%',
    margin: '20px auto 40px',
    backgroundColor: '#fff',
    position: 'relative',
    padding: 0,
    'z-index': 6,
  },
  resultsHeader: {
    color: '#404B50',
    fontSize: 18,
    fontWeight: 500,
    margin: 0,
    padding: '0px 0px 0px 20px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

interface Props {
  radarFundData: any;
  radarFields: any;
  showRadar: any;
  setShowRadar: any;
  textData: any;
}

const labelDetails = {
  Age: 'Advisor age in years',
  AUM: 'Advisor level assets under management',
  'Career Tenure': 'Number of years advisor has worked in this capacity',
  'Firm AUM': 'Firm level assets under management',
  'Firm ETF Allocation': `Firm's percent holdings in ETFs`,
  'Firm MF Allocation': `Firm's percent holdings in MFs`,
};

const RadarCharts = ({ radarFundData, radarFields, showRadar, setShowRadar, textData }: Props) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const classes = useStyles();

  // console.log('radarCharts radarFundData: ', radarFundData);

  const radarBody = () => {
    return (
      <div className={classes.outerRadarContainer}>
        <div className={classes.radarContainers}>
          <GeneralRadar
            data={radarFundData}
            radarData={[
              {
                ...radarFields.fields.first,
                stroke: colors.grey_03,
                fill: colors.grey_03,
                label: radarFields.labels.first,
                icon: lightPurple,
              },
            ]}
            labelDetails={labelDetails}
            width={width}
          />
        </div>
        <div
          className={classes.radarContainers}
          style={{ borderLeft: `1px solid ${colors.grey_01}`, borderRight: `1px solid ${colors.grey_01}` }}
        >
          <GeneralRadar
            data={radarFundData}
            radarData={[
              {
                ...radarFields.fields.second,
                fill: 'url(#Gradient1)',
                fillOpacity: '0.7',

                label: radarFields.labels.second,
                icon: lightPurple,
              },
            ]}
            labelDetails={labelDetails}
            width={width}
          />
        </div>
        <div className={classes.radarContainers}>
          <GeneralRadar
            data={radarFundData}
            radarData={[
              {
                ...radarFields.fields.first,
                stroke: colors.grey_03,
                fill: colors.grey_03,
                label: radarFields.labels.first,
                icon: lightPurple,
              },
              {
                ...radarFields.fields.second,
                fill: 'url(#Gradient1)',
                fillOpacity: '0.7',
                label: radarFields.labels.second,
                icon: lightPurple,
              },
            ]}
            labelDetails={labelDetails}
            width={width}
            style={{ borderRight: '0px solid #C4C4C4' }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classes.resultsBox}>
      <div className={classes.resultsHeader} style={{ borderBottom: showRadar ? '1px solid #e3e5e4' : 0 }}>
        <H2>{textData.radarHeading}</H2>
      </div>
      {showRadar ? radarBody() : null}
    </div>
  );
};

export default RadarCharts;
