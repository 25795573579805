import React from 'react';
import { styled } from '@mui/material/styles';
import { F1, F4 } from '..';
import { colors } from '../../styles';
import { Row } from '..';
import check from '../../assets/icons/complete-green.svg';

const Card = styled('div')({
  height: '120px',
  width: '260px',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '24px 0px 0px 24px',
  padding: '8px 20px',
  boxShadow: colors.main_box_shadow,
  cursor: 'pointer',
  ':hover': {
    opacity: 0.9,
  },
});

const AlgoImg = styled('img')({
  cursor: 'pointer',
  height: '25px',
  width: '25px',
  margin: '4px 0px',
});

const AlgoDescription = styled(F4)({
  cursor: 'pointer',
  textAlign: 'center',
  color: colors.grey_02,
});

const SuccessIcon = styled('img')({
  height: '20px',
  width: '20px',
  right: '10px',
  top: '10px',
});

type Props = {
  title: string;
  icon: string;
  description: string;
  active?: boolean;
  onClick: any;
};

export const AlgoCard = ({ title, icon, description, active, onClick }: Props) => {
  return (
    <Card style={{ backgroundColor: active ? colors.black : colors.white }} onClick={onClick}>
      <Row>
        {active && <SuccessIcon src={check} />}
        <F1 style={{ margin: '0px 0px 0px 6px', color: active ? colors.white : colors.black }}>{title}</F1>
      </Row>
      <AlgoImg style={{ color: active ? colors.white : colors.black }} src={icon} />
      <AlgoDescription style={{ color: active ? colors.white : colors.grey_02 }}>{description}</AlgoDescription>
    </Card>
  );
};
