import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { H2, F2, F3, MainTextInput, PrimaryButtonShort, Column } from '..';
import { colors } from '../../styles';
import { MenuItem } from '@mui/material';
import { styled } from '@mui/system';
import closeIcon from '../../assets/icons/close.svg';
import { useMutation } from '@apollo/client';
import { UPLOAD_FLAT_FILE } from '../../api/dataAPI';
import Loader from '../Loader';

const OuterBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#EFF6F5',
  borderRadius: '4px',
  padding: '10px 10px',
});

const FileUploadBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '640px',
  height: '150px',
  backgroundColor: '#EFF6F5',
  border: `1px dashed #758790`,
  borderRadius: '4px',
  padding: '4px',
  cursor: 'pointer',
});

const FileInfoRow = styled('div')({
  display: 'flex',
  width: '640px',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: '#758790',
});

const FileInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '90px',
  alignItems: 'center',
});

const BrowseFiles = styled('label')({
  fontWeight: '600',
  cursor: 'pointer',
});

type Props = {
  setModalPage: any;
  uploadedFile: any;
  setInfoPageCopy: any;
  setUploadedFile: any;
  enableUploads: boolean;
};

const TypeFileData = ['Transactions', 'Data Packs', 'Marketing Data', 'CRM Metadata', 'Fund Availability', 'Other'];

const UploadFlatFile = ({ setInfoPageCopy, setModalPage, uploadedFile, setUploadedFile, enableUploads }: Props) => {
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [emphasis, setEmphasis] = useState({});
  const [buttonText, setButtonText] = useState('Upload');
  const [fileName, setFileName] = useState('');
  const [fileDataType, setFileDataType] = useState('');

  const [UploadFlatFile, { loading, data, error }] = useMutation(UPLOAD_FLAT_FILE, {
    onCompleted: (data) => {
      if (data?.importDataFile?.success) {
        setInfoPageCopy({
          header: 'File Successfully Uploaded',
          subheader: `Thank you for providing your data. We will process it and let you know when it's ready.`,
          loader: false,
        });
      } else {
        setInfoPageCopy({
          header: 'Error Uploading File',
          subheader: `An unknown error occured while uploading your file, please try again.`,
          loader: false,
        });
      }
      setModalPage('InfoView');
    },
    onError: (error) => {
      setInfoPageCopy({
        header: 'Error Uploading File',
        // subheader: `An unknown error occured while uploading your file${data?.traceback ? ' (' + error?.traceback + ')' : ''}. Please try again.`,
        loader: false,
      });
      setModalPage('InfoView');
    },
  });

  useEffect(() => {
    setButtonText('Upload');
  }, [uploadedFile]);

  const drop = (event: any) => {
    setUploadError(null);
    event.preventDefault();
    console.log('event.dataTransfer: ', event.dataTransfer);
    const data = event.dataTransfer.files[0];
    console.log('data.type', data.type);
    if (
      data.type === 'text/csv' ||
      'application/vnd.ms-excel' ||
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      setUploadedFile(data);
      setFileName(data.name);
    } else {
      setUploadError('Please only upload .csv, .xls, or .xlsx files');
      setUploadedFile(null);
    }
    setEmphasis(false);
  };

  const allowDrop = (event: any) => {
    setEmphasis(!emphasis);
    event.preventDefault();
  };

  return (
    <div
      style={{
        margin: '30px 0px 0px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        position: 'relative',
      }}
    >
      <H2 sx={{ color: colors.grey_03, margin: '0px 0px 20px' }}>Upload a Flat File</H2>
      <F3 sx={{ color: colors.grey_02, margin: '0px 0px 40px' }}>
        Here you can upload any other data you have (e.g. transactions, data packs) manually.
      </F3>
      <OuterBox>
        <FileUploadBox
          onDrop={drop}
          onDragOver={allowDrop}
          style={{
            border: emphasis ? `1px dashed #758790` : `2px dashed #758790`,
          }}
        >
          <F3 sx={{ color: colors.grey_03, margin: '10px 0px 6px' }}>
            Drop your file(s) here, or{' '}
            <input
              type="file"
              id="upload-file-button"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              hidden
              onChange={(e: any) => {
                setUploadedFile(e.target.files[0]);
                setFileName(e.target.files[0].name);
              }}
            />
            {/* @ts-ignore */}
            <BrowseFiles htmlFor={'upload-file-button'}>Browse files</BrowseFiles>
          </F3>
          <F3 sx={{ color: colors.grey_02 }}>Max file size 1 GB</F3>
        </FileUploadBox>
      </OuterBox>
      {error && <F3 style={{ color: colors.red }}>{error?.message}</F3>}
      {uploadedFile && (
        <Column>
          <FileInfoRow>
            <p>{uploadedFile.name}</p>
            <FileInfo>
              {/* @ts-ignore */}
              <p>{Number.parseFloat(uploadedFile?.size / 1000).toFixed(1)} KB</p>
              <IconButton
                onClick={() => {
                  setUploadError(null);
                  setUploadedFile(null);
                }}
              >
                <img src={closeIcon} alt={'Close'} />
              </IconButton>
            </FileInfo>
          </FileInfoRow>
          <MainTextInput
            id={'upload-file-name'}
            label={'File Name'}
            size={'small'}
            focused
            onChange={(event) => {
              return setFileName(event.target.value);
            }}
            value={fileName}
          />
          <MainTextInput
            id={'upload-file-type'}
            label={'File Data Type'}
            size={'small'}
            sx={{ margin: '10px 0px 50px' }}
            onChange={(event) => {
              return setFileDataType(event.target.value);
            }}
            focused
            value={fileDataType}
            select
          >
            {TypeFileData.map((field: any, index: number) => {
              return (
                <MenuItem key={index} id={`${index}-${field}`} onClick={() => setFileDataType(field)} value={field}>
                  <F2>{field}</F2>
                </MenuItem>
              );
            })}
          </MainTextInput>
        </Column>
      )}
      <div
        style={{
          display: 'flex',
          flex: 1,
          width: '100%',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          zIndex: '2',
        }}
      >
        {!enableUploads && (
          <F3 style={{ margin: '5px 20px', color: colors.grey_02 }}>
            Your infrastructure is not currently configured to enable uploads.
          </F3>
        )}
        <PrimaryButtonShort
          onClick={() => {
            const variables = { file: uploadedFile, fileName, fileDataType };
            UploadFlatFile({ variables });
          }}
          disabled={!enableUploads}
        >
          {loading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Upload'}
        </PrimaryButtonShort>
      </div>
    </div>
  );
};

export default UploadFlatFile;
