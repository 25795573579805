import React, { useState } from 'react';
import { colors, typography } from '../../styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import {
  F2,
  PrimaryButtonShort,
  F3,
  SecondaryButtonShort,
  ModalRoot,
  ModalHeader,
  ModalContainer,
  ModalFooter,
  Column,
  MainTextInput,
} from '../';
import Loader from '../Loader';
import { TextField } from '@mui/material';
import { GET_JOB_BY_ID, UPLOAD_BESPOKE_RUN } from '../../api/systemManagement';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';

interface Props {
  modalStatus: boolean;
  closeModal: any;
  uploadInfo: any;
  refreshAndSelect: any;
  setJobId: any;
}

const UploadJourneyModal = ({ closeModal, modalStatus, uploadInfo, refreshAndSelect, setJobId }: Props) => {
  const [newPackageSub, setNewPackageSub] = useState<string>('INFLUENCE');
  //   const newPackageQuota = 1_000_000;
  const [demoMode, setDemoMode] = useState<boolean>(false);
  const [runKey, setRunKey] = useState<string>('');
  const [runDate, setRunDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [journeyName, setJourneyName] = useState<string>('');

  const [uploadBespokeRun, { loading: uploadLoading, data: uploadData, error: uploadError }] = useMutation(
    UPLOAD_BESPOKE_RUN,
    {
      onCompleted: (data) => {
        if (data?.influencePullBespoke?.success) {
          console.log('setting uploadData jobId: ', data?.influencePullBespoke?.details);
          setJobId(data?.influencePullBespoke?.details);
          closeModal();
        }
      },
    },
  );

  const assetManagerId: string = uploadInfo?.assetmanagerprofileSet?.[0]?.id || '';

  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer style={{ padding: '4px 0px' }}>
        <ModalHeader style={{ width: '100%', padding: '8px 16px' }}>Upload a Bespoke Journey</ModalHeader>
        <Column
          style={{ width: '460px', padding: '8px 0px 16px', justifyContent: 'space-evenly', alignItems: 'flex-start' }}
        >
          <F3 style={{ padding: '12px 0px ', color: colors.grey_02 }}>
            Link an uploaded journey to this organization.
          </F3>
          <MainTextInput
            size={'small'}
            label={'Journey Name'}
            focused
            value={journeyName}
            onChange={(event: any) => {
              console.log('newValue mainTextInput: ', event);
              setJourneyName(event?.target?.value);
            }}
          />
          <MainTextInput
            size={'small'}
            label={'Run Key'}
            focused
            value={runKey}
            onChange={(event: any) => {
              setRunKey(event?.target?.value);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-us'}>
            <DatePicker
              renderInput={(props) => <TextField {...props} />}
              label="Run Date"
              value={runDate}
              onChange={(newValue: any) => {
                setRunDate(moment(newValue).format('YYYY-MM-DD'));
              }}
            />
          </LocalizationProvider>
          <F2 style={{ color: colors.red }}>{uploadError?.message}</F2>
        </Column>
        <ModalFooter style={{ width: '100%', padding: '8px 16px', alignItems: 'flex-end' }}>
          <SecondaryButtonShort onClick={closeModal}>Cancel</SecondaryButtonShort>
          <PrimaryButtonShort
            onClick={() => uploadBespokeRun({ variables: { assetManagerId, runKey, runDate, journeyName } })}
            style={{ margin: '0px 8px' }}
          >
            {uploadLoading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Upload Run'}
          </PrimaryButtonShort>
        </ModalFooter>
      </ModalContainer>
    </ModalRoot>
  );
};

export default UploadJourneyModal;
