import React from 'react';
import scoreLogo from '../assets/logos/score-logo.svg';
import { Row } from '../components';
import { styled } from '@mui/system';
import DetailedTooltip from './DetailedTooltip';
import InfoIcon from '../assets/icons/info.svg';

const Logo = styled('img')({
  height: 12,
  background: 'white',
  margin: '0 4px 0 0',
});

const infoIcon = styled('img')({
  height: '12px',
  width: '12px',
});

const tooltipCopy: any = {
  advisorLeads: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This AMP Score results from a thematic and financial analysis of each Advisor. The higher the score, the more
        their thematic activity is aligned with your fund and the stronger their book is.
        <br />
        <br />
        <b>Thematic Analysis</b>
        <br />
        <br />
        Funds are linked to themes by algorithms that consider several factors such as name, description, exposures, and
        holdings. Advisors are linked to themes by algorithms that consider several factors, such as the articles that
        the advisor distributes to their clients, search behavior, and holdings. With Fund and Advisor themes predicted,
        a similarity score is computed to find advisors with the most interest in your fund.
        <br />
        <br />
        <b>Financial Analysis</b>
        <br />
        <br />
        Advisors and their book of business are analyzed over an array of characteristics to identify advisors who will
        have the highest lifetime value (LTV) to you as a fund manager. An advisor’s LTV is assessed across features
        including Age, AUM, Tenure, etc. The advisors' books are assessed across Age, Income, Wealth, Projected
        Retirement Assets, etc. You will have the opportunity to exclude any financial features you’d prefer to deselect
        from your analysis when you “Customize Characteristics.”
      </p>
    ),
  },
  individualLeads: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This AMP Score results from a thematic and financial analysis of each Individual. The thematic analysis compares
        the selected funds' themes to individuals’ theme preferences. The higher the score, the stronger their
        financials and the more their thematic activity is aligned with your fund.
        <br />
        <br />
        <b>Thematic Analysis</b>
        <br />
        <br />
        Funds are linked to themes by algorithms that consider several factors such as name, description, exposures, and
        holdings. Individuals are linked to themes by algorithms that consider several factors such as email engagement,
        online article engagement, search behavior, and holdings. With fund and individual themes predicted, a
        similarity score is computed to find individuals with the most interest in your fund.
        <br />
        <br />
        <b>Financial Analysis</b>
        <br />
        <br />
        Individuals are analyzed over an array of characteristics to identify individuals who will have the highest
        lifetime value (LTV) to you as a fund manager. Individual LTV is assessed across features including Age, Income,
        Wealth, Projected Retirement Assets, etc. You will have the opportunity to exclude any financial features you’d
        prefer to deselect from your analysis when you “Customize Characteristics.”
      </p>
    ),
  },
  generateWaitlist: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This AMP Score results from a thematic and financial analysis of each Advisor. The higher the score, the more
        their thematic activity is aligned with your fund and the stronger their book is.
        <br />
        <br />
        <b>Thematic Analysis</b>
        <br />
        <br />
        Funds are linked to themes by algorithms that consider several factors such as name, description, exposures, and
        holdings. Advisors are linked to themes by algorithms that consider several factors, such as the articles that
        the advisor distributes to their clients, search behavior, and holdings. With Fund and Advisor themes predicted,
        a similarity score is computed to find advisors with the most interest in your fund.
        <br />
        <br />
        <b>Financial Analysis</b>
        <br />
        <br />
        Advisors and their book of business are analyzed over an array of characteristics to identify advisors who will
        have the highest lifetime value (LTV) to you as a fund manager. An advisor’s LTV is assessed across features
        including Age, AUM, Tenure, etc. The advisors' books are assessed across Age, Income, Wealth, Projected
        Retirement Assets, etc. You will have the opportunity to exclude any financial features you’d prefer to deselect
        from your analysis when you “Customize Characteristics.”
      </p>
    ),
  },
  prioritizeAdvisors: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This <b>AMP Score</b> is calculated by analyzing similarity in characteristics between your benchmark pool of
        advisors and those you select for analysis. The similarity score is calculated by comparing features including
        AUM, Advisor type (BD/RIA), career tenure, etc. Leveraging all available features, we build a nearest neighbors
        algorithm to calculate a score. You will have the opportunity to exclude any financial features you’d prefer to
        deselect from your analysis when you “Customize Characteristics."
      </p>
    ),
  },
  prioritizeProspects: {
    title: 'How the AMP Score is calculated',
    body: (
      <p>
        This <b>AMP Score</b> is calculated by analyzing similarity in characteristics between your benchmark pool of
        prospects and those you select for analysis. The similarity score is calculated by comparing features including
        wealth, income, home value, etc. Leveraging all available features, we build a nearest neighbors algorithm to
        calculate a score. You will have the opportunity to exclude any financial features you’d prefer to deselect from
        your analysis when you “Customize Characteristics."
      </p>
    ),
  },
  growthOpportunity: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This <b>AMP Score</b> is calculated by analyzing similarity in characteristics between your benchmark pool of
        prospects and those you select for analysis. The similarity score is calculated by comparing features including
        wealth, income, home value, etc. Leveraging all available features, we build a nearest neighbors algorithm to
        calculate a score. You will have the opportunity to exclude any financial features you’d prefer to deselect from
        your analysis when you “Customize Characteristics."
      </p>
    ),
  },
  prioritizeClients: {
    title: 'How the AMP score is calculated',
    body: (
      <p>
        This <b>AMP Score</b> is calculated by analyzing similarity in characteristics between your benchmark pool of
        clients and analyzing which clients have the highest growth opportunity. This match score is calculated by
        comparing features including wealth, income, home value, etc. Leveraging all available features. You will have
        the opportunity to exclude any financial features you’d prefer to deselect from your analysis when you
        “Customize Characteristics."
      </p>
    ),
  },
  crossSell: {
    title: 'How the AMP Score is calculated',
    body: (
      <p>
        This <b>AMP Score</b> is calculated by analyzing similarity in characteristics between your benchmark pool of
        prospects and those you select for analysis. The similarity score is calculated by comparing features including
        wealth, income, home value, etc. Leveraging all available features, we build a nearest neighbors algorithm to
        calculate a score. You will have the opportunity to exclude any financial features you’d prefer to deselect from
        your analysis when you “Customize Characteristics."
      </p>
    ),
  },
  prioritizeEngagementActivity: {
    title: 'How the AMP Score is calculated',
    body: (
      <p>
        This AMP Score results from an analysis of the number of firms who have engaged with your activities and have
        also purchased a share of a fund. The higher the score, the more the activity correlates with flows growing your
        fund.
      </p>
    ),
  },
  prioritizeEngagementSubset: {
    title: 'How the AMP Score is calculated',
    body: (
      <p>
        This AMP Score results from an analysis of the number of firms who have engaged with this specific activity and
        have also purchased a share of a fund. The higher the score, the more the activity is aligned with growing your
        fund.
      </p>
    ),
  },
};

interface Props {
  packageName: string;
  width?: string;
  style?: any;
}

export const DistillScoreLogo = ({ packageName = 'advisorLeads', width, style }: Props) => {
  return (
    <>
      <DetailedTooltip headerText={tooltipCopy?.[packageName]?.title} bodyText={tooltipCopy?.[packageName]?.body}>
        {/* <Row> */}
        <Logo sx={{ width: width ? width : '70px', ...style }} src={scoreLogo} alt="info" />
        {/* <img style={{ height: '14px', width: '14px', margin: '0px 0px 0px -10px' }} src={InfoIcon} /> */}
        {/* </Row> */}
      </DetailedTooltip>
    </>
  );
};
