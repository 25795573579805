import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { styled } from '@mui/material/styles';
import { colors } from '../../../styles';

import { exportClientsToSpreadsheet } from '../../../helpers/exportClients';

import { F3, H3, CardContainer, Title, H2 } from '../../../components';
import Table from '../../../components/Table';
import LoadingModal from '../../../components/LoadingModal';
import ComingSoonModal from '../../../components/ComingSoonModal';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useQuery } from '@apollo/client';
import { GET_DATAPACKS } from '../../../api/dataAPI';

const PageHeader = styled('div')({
  display: 'flex',
  height: '60px',
  width: '100%',
  background: colors.gradient_01,
  padding: '0px 36px',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

const PageBody = styled('div')({
  overflowY: 'scroll',
  height: '100%',
  padding: '0px 36px 20px',
});

const HeaderContainer = styled('div')({});

const Root = styled('div')({
  height: '100%',
  // borderLeft: `1px solid ${colors.grey_01}`,
});

export const Datapacks = () => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [tablePage, setTablePage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortKey, setSortKey] = useState('');
  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const { loading, data, error } = useQuery(GET_DATAPACKS);

  const downloadResults = async () => {
    setLoadingCSV(true);

    let sortKeyForAll = '-score';

    try {
      //@ts-ignore
      const allResults: any[] = [];
      exportClientsToSpreadsheet(
        allResults,
        //@ts-ignore
        TablePresets[resultsPage + tableType].spreadSheetHeaders,
        {},
        'LPL-Datapack',
      );
      setLoadingCSV(false);
    } catch (error: any) {
      console.warn('error downloading all results:', error.message);
    }
  };

  const TableHeaders = [
    {
      header: 'Name',
      key: 'name',
    },
    {
      header: 'Data Type',
      key: 'dateType',
    },
    {
      header: 'Datapoints',
      key: 'numDatapoints',
    },
    {
      header: 'Date Published',
      key: 'datePublished',
      formatData: (row: any) => {
        return moment(row.datePublished).format('llll');
      },
    },
    {
      header: ' ',
      key: 'clickCount',
      // sortKey: 'attendedCount',
      formatData: (row: any, hover: boolean) => {
        return (
          <H3
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
            onClick={(event) => {
              event.stopPropagation();
              console.log('row.fileData: ', row.fileData);
              window.open(row.fileData);
            }}
          >
            {hover && row?.leads?.length !== 0 ? 'Download' : <div style={{ width: '100px' }} />}
          </H3>
        );
      },
    },
  ];

  return (
    <Root>
      <PageHeader>
        <Title>Datapacks</Title>
      </PageHeader>
      <PageBody>
        <HeaderContainer>
          {/* <h1 className={classes.mainHeader}>{packages[archiveType].title} Archive</h1> */}
          {/* <H1 style={{ padding: '20px 0px 20px', borderBottom: `2px solid ${colors.grey_01}` }}>About</H1> */}
          <F3 sx={{ margin: '30px 0px', color: colors.grey_02, width: '360px' }}>
            {'Here you can download LEAP datapacks if you would like to do further analysis on your own.'}
          </F3>
        </HeaderContainer>
        {loading ? (
          <CardContainer>
            <Loader />
          </CardContainer>
        ) : data?.lpl?.leapDatapacks?.length === 0 ? (
          <CardContainer style={{ padding: '44px 26px' }}>
            <H2 style={{ margin: '8px 0px 8px', textAlign: 'center' }}>No Datapacks Found</H2>
            <F3 style={{ color: colors.grey_02, margin: '8px 0px 0px', textAlign: 'center' }}>
              Please contact your customer success representative to find out more.
            </F3>
          </CardContainer>
        ) : (
          <CardContainer>
            <Table
              //@ts-ignore
              headerData={TableHeaders}
              data={data?.lpl?.leapDatapacks}
              hideShadow={true}
              dataControls={{
                selections: {},
                setSelections: () => {},
                searchFunc: setSearchTerm,
                searchTerm,
                searchPlaceholder: 'Search for a datapack',
              }}
              // initSortColumn={sortKey}
              paginationControls={{
                tablePage,
                setTablePage,
                pageSize,
                setPageSize,
                totalItems: data?.lpl?.leapDatapacks?.length ?? 0,
                sortKey,
                setSortKey,
              }}
              search={true}
              loading={loading}
              onRowPress={(item: any) => console.log('Row pressed', item)}
              newItems={[]}
            />
          </CardContainer>
        )}
      </PageBody>
      <LoadingModal
        modalStatus={loadingCSV}
        closeModal={() => setLoadingCSV(false)}
        headerText={'Downloading all results'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Root>
  );
};
