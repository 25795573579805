import { LPL } from './lpl';

interface appTheme {
  primary_color: string;
  primary_gradient: string;
  secondary_color: string;
  secondary_gradient: string;
}

interface themesIndex {
  [key: string]: appTheme;
}

export const themes: themesIndex = { LPL };
