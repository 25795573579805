import React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  menuOptions: any;
  label: string;
  icon?: string;
  id: string;
  hideIcon?: boolean;
  iconStyle?: object;
  focused?: boolean;
  smallText?: boolean;
  link?: string;
  sx?: any;
};

const MenuButtonContainer = styled('button')(`
    font-family: Inter;
    display: flex;
    height: 38px;
    width: 200px;
    background:${colors.white_00};
    border: 0px solid;
    text-decoration: none;
    align-items:center;
    line-height:22px;
    font-weight: 500;
    font-size: 12px;
    padding: 0px;
    text-align:center;
    border-left: 2px solid ${colors.white_00};
    cursor: pointer;
    transition: 0.5s;
    :link {
      text-decoration: none;
    };
    :hover {
      background:${colors.grey_00};
    };
    color: #758790;

`);

const LabelText = styled('h1')`
  background: ${colors.white_00};
  padding: 0px 10px;
  margin: 16px 0 10px;
  height: 18px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #242b2f;
`;

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

const ButtonIcon = styled('img')(`
    height: 26px;
    width: 26px;
    background:${colors.grey_02}
    padding: 0px 6px 0px 0px;
`);

export const MenuButton = ({ id, label, icon, iconStyle, focused, link, hideIcon, smallText, sx }: Props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {link ? (
        <Link to={link} style={{ textDecoration: 'none' }}>
          <MenuButtonContainer
            id={id}
            sx={
              focused
                ? {
                    ...sx,
                    background: colors.gradient_03,
                    borderLeft: `2px solid ${colors.black_01}`,
                    color: colors.black_01,
                    fontSize: smallText ? '12px' : '14px',
                    padding: smallText ? '0px 18px' : '0px 10px',
                  }
                : {
                    ...sx,
                    fontSize: smallText ? '12px' : '14px',
                    padding: smallText ? '0px 18px' : '0px 10px',
                  }
            }
          >
            {icon && !hideIcon && (
              <IconContainer>
                <ButtonIcon src={icon} sx={iconStyle ? iconStyle : {}} />
              </IconContainer>
            )}
            {label}
          </MenuButtonContainer>
        </Link>
      ) : (
        <LabelText>{label}</LabelText>
      )}
    </>
  );
};
