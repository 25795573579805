import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { keyframes } from '@mui/system';
import { FadeContainer } from '..';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Root = styled('div')({
  zIndex: 1000,
  height: '80vh',
});

const TitleText = styled('h1')({
  position: 'absolute',
  textAlign: 'center',
  height: '60px',
  bottom: 0,
  top: '8%',
  left: '50%',
  width: '700px',
  transform: 'translate(-50%, -50%)',
  letterSpacing: '-2px',
  lineHeight: '60px',
  color: colors.black_01,
  ...typography.mainFont,
  fontSize: '64px',
  fontWeight: '700px',
  // '@media (min-width: 1550px)': {
  //   font-size: '76px',
  //   width: '400px',
  //   left: '0px',
  //   top: '120px',
  // },
});

const UnifyDataBackground = styled(motion.path)({});
const OptimizeMarketingBackground = styled(motion.path)({});
const DriveSalesBackground = styled(motion.path)({});

const FunnelBackground = styled(motion.path)({});
const FunnelArrows = styled(motion.path)({});
const FunnelArcs = styled(motion.path)({});

const UnifyLabel = styled(motion.path)({});
const UnifySubheader = styled(motion.path)({});
const DriveLabel = styled(motion.path)({});
const DriveSubheader = styled(motion.path)({});
const OptimizeLabel = styled(motion.path)({});
const OptimizeSubheader = styled(motion.path)({});

const fade = keyframes`
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
`;

const arcSections = {
  unfocused: { opacity: 0.1 },
  focused: { opacity: 1 },
};

const arcTypography = {
  unfocused: { fill: colors.grey_03 },
  focused: { fill: colors.white },
};

export const SalesFunnel = () => {
  const [hoverSection, setHoverSection] = useState<string>('default');
  const navigate = useNavigate();

  const resetSection = () => {
    setHoverSection('default');
  };

  const toggleSection = (sectionKey: string) => () => {
    setTimeout(() => {
      setHoverSection(sectionKey);
    }, 100);
  };

  const generateBackgroundConfig = () => {
    const sectionConfig = {
      onHoverEnd: resetSection,
    };
    return sectionConfig;
  };

  const generateSectionConfig = (sectionKey: string) => {
    const sectionConfig = {
      onClick: () => {
        navigate(pageCopy[sectionKey]?.link);
      },
      onHoverStart: toggleSection(sectionKey),
      variants: arcSections,
      initial: 'unfocused',
      //   onHoverEnd: resetSection,
      animate: hoverSection === sectionKey ? 'focused' : 'unfocused',
      style: { cursor: 'pointer' },
    };
    return sectionConfig;
  };

  const generateSectionLabelConfig = (sectionKey: string) => {
    const labelConfig = {
      onClick: () => {
        navigate(pageCopy[sectionKey]?.link);
      },
      onHoverStart: toggleSection(sectionKey),
      variants: arcTypography,
      initial: 'unfocused',
      animate: hoverSection === sectionKey ? 'focused' : 'unfocused',
      style: { cursor: 'pointer' },
    };
    return labelConfig;
  };

  const pageCopy: any = {
    default: {
      header: 'Drive Sales',
      subheader: '',
    },
    unify: {
      header: 'Unify Data',
      subheader: `Empower your sales strategies by generating actionable signals tailor-made to enhance your sales team's distribution efforts, and facilitating the creation of new leads.`,
      link: '/unify/',
    },
    optimize: {
      header: 'Optimize Marketing',
      subheader: `Gather feedback data to feed into the algorithms, thereby enabling a process of continuous learning and improvement. Allocate your marketing and sales resources strategically to engage potential customers.`,
      link: '/optimize/',
    },
    drive: {
      header: 'Drive Sales',
      subheader:
        'Get a comprehensive understanding of the performance of your distribution efforts. Observe how the algorithms learn and evolve based on the data points you provide and utilize this intelligence to fine-tune your strategies further.',
      link: '/drive-sales/',
    },
  };

  return (
    <Root>
      <TitleText
        sx={{
          animation: `${fade} 3s ease`,
          letterSpacing: '-0.09em',
        }}
      >
        AI Powered Distribution
      </TitleText>
      <FadeContainer
        style={{ zIndex: 1000, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        <motion.svg width="948" height="636" viewBox="0 0 948 636" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.g clipPath="url(#clip0_6577_3704)">
            {/* Funnel Sections */}
            <FunnelArrows
              d="M14.878 344.477L22.317 357.361L29.756 370.246H14.878H0L7.439 357.361L14.878 344.477Z"
              fill={colors.black}
            />
            <FunnelArrows
              onHoverStart={toggleSection('default')}
              d="M932.417 344.477L939.856 357.361L947.295 370.246H932.417H917.539L924.978 357.361L932.417 344.477Z"
              fill={colors.black}
            />
            <FunnelBackground
              {...generateBackgroundConfig()}
              opacity="0.05"
              d="M722.081 152.55C719.375 149.108 715.92 146.327 711.978 144.419C708.037 142.511 703.713 141.525 699.334 141.537H241.313C236.936 141.537 232.616 142.528 228.676 144.435C224.736 146.342 221.28 149.116 218.565 152.55C215.85 155.983 213.948 159.986 213 164.259C212.053 168.533 212.085 172.965 213.094 177.224L242.618 301.816L274.231 435.223L301.298 549.444C302.786 555.805 306.387 561.474 311.511 565.526C316.636 569.578 322.983 571.774 329.516 571.756H611.13C617.663 571.774 624.01 569.578 629.135 565.526C634.26 561.474 637.86 555.805 639.349 549.444L666.415 435.223L670.914 416.237L672.042 411.477L698.028 301.816L727.552 177.224C728.573 172.966 728.611 168.531 727.664 164.256C726.716 159.98 724.807 155.977 722.081 152.55Z"
              fill={colors.black}
            />
            <FunnelArcs
              onHoverStart={toggleSection('default')}
              opacity="0.05"
              d="M455.037 141.558V130.798C455.037 119.383 450.502 108.436 442.431 100.364C434.359 92.2924 423.412 87.7578 411.997 87.7578H282.877C134.312 87.7578 13.877 208.193 13.877 356.758C13.877 505.322 134.312 625.758 282.877 625.758H411.997C423.412 625.758 434.359 621.223 442.431 613.152C450.502 605.08 455.037 594.133 455.037 582.718V571.958"
              stroke={colors.black}
              strokeWidth="20"
              strokeMiterlimit="10"
            />
            <FunnelArcs
              onHoverStart={toggleSection('default')}
              d="M455.037 141.558V130.798C455.037 119.383 450.502 108.436 442.431 100.364C434.359 92.2924 423.412 87.7578 411.997 87.7578H282.877C134.312 87.7578 13.877 208.193 13.877 356.758C13.877 505.322 134.312 625.758 282.877 625.758H411.997C423.412 625.758 434.359 621.223 442.431 613.152C450.502 605.08 455.037 594.133 455.037 582.718V571.958"
              stroke="url(#paint0_radial_6577_3704)"
              strokeWidth="4"
              strokeMiterlimit="10"
            />
            <FunnelArcs
              onHoverStart={toggleSection('default')}
              opacity="0.05"
              d="M491.256 141.558V130.798C491.256 119.383 495.79 108.436 503.862 100.364C511.934 92.2924 522.881 87.7578 534.296 87.7578H663.416C811.981 87.7578 932.416 208.193 932.416 356.758C932.416 505.322 811.981 625.758 663.416 625.758H534.296C522.881 625.758 511.934 621.223 503.862 613.152C495.79 605.08 491.256 594.133 491.256 582.718V571.958"
              stroke={colors.black}
              strokeWidth="20"
              strokeMiterlimit="10"
            />
            <FunnelArcs
              onHoverStart={toggleSection('default')}
              d="M491.256 141.558V130.798C491.256 119.383 495.79 108.436 503.862 100.364C511.934 92.2924 522.881 87.7578 534.296 87.7578H663.416C811.981 87.7578 932.416 208.193 932.416 356.758C932.416 505.322 811.981 625.758 663.416 625.758H534.296C522.881 625.758 511.934 621.223 503.862 613.152C495.79 605.08 491.256 594.133 491.256 582.718V571.958"
              stroke="url(#paint1_radial_6577_3704)"
              strokeWidth="4"
              strokeMiterlimit="10"
            />
            <DriveSalesBackground
              {...generateSectionConfig('drive')}
              d="M581.269 426.477H287.574L315.893 545.984C316.62 549.051 318.361 551.783 320.833 553.738C323.305 555.693 326.364 556.756 329.516 556.756H611.131C614.282 556.756 617.342 555.693 619.814 553.738C622.286 551.783 624.027 549.051 624.753 545.984L653.073 426.477H581.269Z"
              fill={colors.black}
            />
            <UnifyDataBackground
              {...generateSectionConfig('unify')}
              d="M645.235 156.537H241.312C239.199 156.537 237.114 157.015 235.212 157.936C233.31 158.857 231.641 160.196 230.331 161.854C229.02 163.511 228.102 165.444 227.644 167.507C227.187 169.569 227.203 171.709 227.69 173.765L254.479 286.817H686.167L712.957 173.765C713.444 171.709 713.459 169.569 713.002 167.507C712.544 165.444 711.626 163.511 710.315 161.854C709.005 160.196 707.336 158.857 705.434 157.936C703.532 157.015 701.447 156.537 699.334 156.537H645.235Z"
              fill={colors.black}
            />
            <OptimizeMarketingBackground
              {...generateSectionConfig('optimize')}
              d="M612.883 293.07H535.194H405.456H333.652H255.963L286.094 420.223H363.783H435.587H505.063H582.752H654.556L684.687 293.07H612.883Z"
              fill={colors.black}
            />
            {/* Funnel Section Label - Unify Data */}
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M415.577 205.018H417.779V214.584C417.797 215.534 417.548 216.471 417.061 217.287C416.575 218.079 415.876 218.718 415.044 219.133C414.091 219.575 413.052 219.805 412.001 219.805C410.95 219.805 409.911 219.575 408.958 219.133C408.127 218.718 407.428 218.079 406.941 217.287C406.454 216.471 406.205 215.534 406.224 214.584V205.018H408.418V214.406C408.406 215.019 408.557 215.624 408.855 216.16C409.145 216.671 409.575 217.089 410.094 217.365C410.69 217.652 411.342 217.801 412.003 217.801C412.664 217.801 413.316 217.652 413.912 217.365C414.431 217.091 414.86 216.673 415.147 216.16C415.441 215.623 415.589 215.018 415.577 214.406L415.577 205.018Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M434.714 205.018V219.562H432.697L425.304 208.895H425.169V219.562H422.975V205.018H425.006L432.406 215.699H432.541V205.018H434.714Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M442.104 205.018V219.562H439.91V205.018H442.104Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M447.301 219.562V205.018H456.32V206.906H449.495V211.338H455.674V213.221H449.495V219.562H447.301Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M459.896 205.018H462.389L466.189 211.629H466.345L470.145 205.018H472.638L467.361 213.854V219.562H465.173V213.854L459.896 205.018Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M488.719 219.563H484.01V205.018H488.868C490.147 204.983 491.413 205.282 492.54 205.888C493.558 206.463 494.379 207.332 494.895 208.382C495.437 209.61 495.716 210.938 495.715 212.28C495.713 213.622 495.431 214.95 494.887 216.177C494.363 217.236 493.53 218.111 492.497 218.686C491.335 219.301 490.033 219.603 488.719 219.563ZM486.204 217.645H488.598C489.557 217.68 490.51 217.464 491.36 217.018C492.092 216.602 492.669 215.962 493.009 215.192C493.737 213.318 493.738 211.239 493.012 209.364C492.68 208.604 492.114 207.969 491.396 207.554C490.578 207.117 489.659 206.904 488.732 206.936H486.204V217.645Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M500.947 219.562H498.617L503.852 205.018H506.388L511.622 219.562H509.292L505.18 207.66H505.066L500.947 219.562ZM501.338 213.867H508.895V215.713H501.338V213.867Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M513.266 206.906V205.018H524.522V206.906H519.984V219.562H517.797V206.906H513.266Z"
              fill="white"
            />
            <UnifyLabel
              {...generateSectionLabelConfig('unify')}
              d="M528.497 219.562H526.168L531.402 205.018H533.938L539.172 219.562H536.843L532.73 207.66H532.616L528.497 219.562ZM528.888 213.867H536.444V215.713H528.888V213.867Z"
              fill="white"
            />
            {/* Funnel Section Subheader - Measure */}
            <UnifySubheader
              d="M430.719 228.861H432.4L436.355 238.521H436.491L440.446 228.861H442.128V240.498H440.81V231.657H440.696L437.06 240.498H435.787L432.15 231.657H432.037V240.498H430.719L430.719 228.861Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M446.545 240.498V228.861H453.567V230.111H447.954V234.044H453.204V235.294H447.954V239.248H453.659V240.498L446.545 240.498Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M458.19 240.498H456.713L460.985 228.861H462.44L466.713 240.498H465.235L461.758 230.702H461.667L458.19 240.498ZM458.735 235.952H464.69V237.202H458.735V235.952Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M476.494 231.77C476.468 231.498 476.379 231.236 476.235 231.003C476.092 230.771 475.897 230.575 475.665 230.429C475.131 230.097 474.51 229.931 473.881 229.952C473.419 229.942 472.959 230.027 472.531 230.202C472.176 230.347 471.866 230.584 471.634 230.889C471.421 231.177 471.308 231.527 471.312 231.885C471.304 232.167 471.383 232.446 471.537 232.682C471.688 232.905 471.884 233.093 472.114 233.233C472.347 233.379 472.594 233.499 472.852 233.592C473.11 233.684 473.346 233.759 473.562 233.816L474.744 234.135C475.088 234.227 475.426 234.337 475.759 234.464C476.139 234.608 476.5 234.8 476.832 235.035C477.178 235.278 477.468 235.593 477.682 235.958C477.917 236.379 478.033 236.856 478.017 237.339C478.024 237.943 477.853 238.536 477.525 239.044C477.171 239.573 476.675 239.993 476.094 240.254C474.673 240.835 473.083 240.852 471.651 240.299C471.081 240.06 470.586 239.671 470.219 239.174C469.868 238.682 469.664 238.101 469.631 237.498H471.085C471.108 237.9 471.265 238.283 471.531 238.586C471.801 238.879 472.142 239.098 472.52 239.222C472.936 239.363 473.373 239.433 473.812 239.429C474.308 239.437 474.8 239.348 475.261 239.166C475.658 239.012 476.009 238.757 476.278 238.427C476.528 238.111 476.661 237.719 476.653 237.316C476.668 236.972 476.552 236.635 476.329 236.373C476.092 236.116 475.801 235.914 475.478 235.782C475.106 235.624 474.724 235.491 474.335 235.385L472.903 234.975C472.107 234.773 471.369 234.39 470.745 233.856C470.482 233.615 470.275 233.32 470.137 232.992C470 232.663 469.935 232.308 469.949 231.952C469.936 231.34 470.123 230.741 470.48 230.245C470.849 229.747 471.343 229.355 471.912 229.108C472.547 228.83 473.234 228.692 473.926 228.702C474.614 228.689 475.296 228.826 475.926 229.103C476.475 229.343 476.954 229.719 477.315 230.197C477.656 230.652 477.845 231.202 477.857 231.77L476.494 231.77Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M489.572 228.861H490.981V236.566C490.994 237.314 490.8 238.05 490.421 238.694C490.043 239.322 489.497 239.832 488.845 240.166C488.099 240.519 487.284 240.702 486.459 240.702C485.633 240.702 484.818 240.519 484.072 240.166C483.42 239.832 482.874 239.322 482.496 238.694C482.117 238.05 481.923 237.314 481.936 236.566V228.861H483.345V236.452C483.335 236.981 483.465 237.503 483.72 237.967C483.97 238.409 484.342 238.769 484.791 239.007C485.312 239.256 485.882 239.385 486.46 239.385C487.037 239.385 487.607 239.256 488.129 239.007C488.579 238.771 488.951 238.41 489.2 237.967C489.454 237.503 489.582 236.981 489.572 236.452V228.861Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M495.398 240.498V228.861H499.33C500.103 228.831 500.872 228.99 501.569 229.325C502.122 229.603 502.575 230.047 502.864 230.594C503.152 231.163 503.296 231.793 503.285 232.429C503.297 233.063 503.153 233.689 502.864 234.254C502.575 234.793 502.124 235.229 501.575 235.501C500.881 235.826 500.119 235.981 499.354 235.952H496.171V234.679H499.308C499.823 234.7 500.336 234.607 500.811 234.407C501.166 234.249 501.458 233.976 501.64 233.631C501.821 233.257 501.91 232.845 501.898 232.429C501.908 232.007 501.819 231.589 501.638 231.208C501.456 230.853 501.162 230.568 500.802 230.398C500.325 230.188 499.806 230.089 499.285 230.111H496.808V240.498L495.398 240.498ZM500.876 235.27L503.739 240.498H502.103L499.285 235.27H500.876Z"
              fill="#878787"
            />
            <UnifySubheader
              d="M507.227 240.498V228.861H514.249V230.111H508.636V234.044H513.886V235.294H508.636V239.248H514.341V240.498L507.227 240.498Z"
              fill="#878787"
            />
            {/* Funnel Section Label - Optimize Marketing */}
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M360.102 345.416C360.139 346.799 359.847 348.17 359.25 349.418C358.731 350.479 357.922 351.371 356.916 351.99C355.892 352.579 354.731 352.889 353.55 352.889C352.368 352.889 351.207 352.579 350.183 351.99C349.177 351.37 348.368 350.477 347.85 349.414C347.288 348.155 346.998 346.792 346.998 345.414C346.998 344.036 347.288 342.673 347.85 341.414C348.368 340.353 349.178 339.462 350.183 338.844C351.207 338.255 352.368 337.945 353.55 337.945C354.731 337.945 355.892 338.255 356.916 338.844C357.922 339.462 358.731 340.353 359.25 341.414C359.847 342.662 360.139 344.033 360.102 345.416ZM357.928 345.416C357.959 344.388 357.764 343.366 357.356 342.422C357.025 341.668 356.482 341.027 355.794 340.576C355.118 340.165 354.343 339.947 353.552 339.947C352.761 339.947 351.985 340.165 351.309 340.576C350.622 341.028 350.081 341.669 349.75 342.422C348.988 344.345 348.988 346.487 349.75 348.41C350.08 349.163 350.622 349.804 351.309 350.256C351.985 350.667 352.761 350.885 353.552 350.885C354.343 350.885 355.118 350.667 355.794 350.256C356.483 349.805 357.026 349.164 357.356 348.41C357.764 347.466 357.959 346.444 357.928 345.416Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M364.83 352.689V338.142H370.015C370.989 338.107 371.957 338.32 372.827 338.762C373.54 339.143 374.122 339.731 374.496 340.447C374.861 341.198 375.051 342.021 375.05 342.856C375.05 343.69 374.859 344.514 374.493 345.264C374.115 345.982 373.531 346.57 372.816 346.953C371.949 347.397 370.982 347.612 370.007 347.576H366.442V345.715H369.809C370.411 345.74 371.01 345.611 371.549 345.342C371.975 345.113 372.318 344.757 372.529 344.322C372.744 343.862 372.851 343.359 372.841 342.851C372.851 342.346 372.744 341.846 372.529 341.389C372.316 340.958 371.972 340.608 371.545 340.389C370.996 340.124 370.389 339.999 369.78 340.025H367.024V352.689H364.83Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M378.896 340.033V338.143H390.153V340.033H385.615V352.689H383.428V340.033H378.896Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M396.825 338.143V352.689H394.631V338.143H396.825Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M402.021 338.143H404.685L409.315 349.451H409.486L414.117 338.143H416.78V352.689H414.692V342.164H414.557L410.267 352.668H408.534L404.244 342.156H404.109V352.689H402.021V338.143Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M424.171 338.143V352.689H421.977V338.143H424.171Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M428.99 352.689V351.227L436.639 340.033H428.926V338.143H439.323V339.607L431.674 350.799H439.387V352.689H428.99Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M444.145 352.689V338.143H453.264V340.033H446.339V344.465H452.788V346.346H446.339V350.799H453.349V352.689H444.145Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M465.502 338.143H468.165L472.796 349.451H472.966L477.597 338.143H480.26V352.689H478.172V342.164H478.037L473.748 352.668H472.015L467.725 342.156H467.59V352.689H465.502L465.502 338.143Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M486.685 352.689H484.355L489.59 338.143H492.125L497.359 352.689H495.03L490.918 340.785H490.804L486.685 352.689ZM487.075 346.992H494.632V348.84H487.075V346.992Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M501.453 352.689V338.142H506.638C507.608 338.109 508.571 338.309 509.447 338.726C510.156 339.078 510.74 339.639 511.119 340.334C511.5 341.065 511.69 341.879 511.674 342.703C511.691 343.52 511.499 344.328 511.116 345.051C510.733 345.735 510.148 346.285 509.439 346.623C508.56 347.026 507.598 347.219 506.631 347.185H502.703V345.295H506.432C507.025 345.316 507.617 345.212 508.168 344.99C508.587 344.813 508.934 344.5 509.152 344.101C509.371 343.668 509.479 343.188 509.465 342.703C509.477 342.208 509.369 341.718 509.148 341.275C508.93 340.866 508.583 340.541 508.161 340.349C507.607 340.112 507.006 340.001 506.403 340.025H503.647V352.689H501.453ZM508.634 346.127L512.228 352.689H509.728L506.205 346.127H508.634Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M516.352 352.689V338.143H518.546V345.09H518.724L524.824 338.143H527.587L521.721 344.691L527.608 352.689H524.966L520.265 346.189L518.546 348.164V352.689L516.352 352.689Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M531.641 352.689V338.143H540.76V340.033H533.835V344.465H540.284V346.346H533.835V350.799H540.845V352.689H531.641Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M544.982 340.033V338.143H556.239V340.033H551.701V352.689H549.514V340.033H544.982Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M562.913 338.143V352.689H560.719V338.143H562.913Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M579.849 338.143V352.689H577.832L570.438 342.021H570.304V352.689H568.109V338.143H570.141L577.541 348.826H577.676V338.143H579.849Z"
              fill="white"
            />
            <OptimizeLabel
              {...generateSectionLabelConfig('optimize')}
              d="M594.888 342.739C594.76 342.328 594.574 341.937 594.337 341.578C594.114 341.242 593.837 340.944 593.517 340.698C593.191 340.45 592.824 340.261 592.434 340.139C592.002 340.008 591.553 339.943 591.102 339.948C590.317 339.938 589.547 340.158 588.886 340.58C588.199 341.035 587.658 341.677 587.327 342.43C586.563 344.346 586.564 346.483 587.33 348.399C587.665 349.157 588.213 349.801 588.907 350.252C589.591 350.679 590.382 350.898 591.188 350.885C591.907 350.902 592.62 350.736 593.258 350.403C593.838 350.09 594.314 349.615 594.628 349.035C594.965 348.393 595.132 347.676 595.114 346.951L595.683 347.057H591.52V345.246H597.238V346.901C597.266 348.01 596.998 349.107 596.461 350.078C595.958 350.961 595.214 351.682 594.315 352.157C593.286 352.667 592.147 352.917 590.998 352.886C589.849 352.855 588.725 352.544 587.725 351.979C586.72 351.359 585.915 350.462 585.406 349.397C584.824 348.155 584.54 346.795 584.575 345.424C584.559 344.363 584.722 343.306 585.058 342.299C585.348 341.43 585.81 340.629 586.418 339.944C586.992 339.306 587.697 338.799 588.484 338.459C589.308 338.111 590.194 337.936 591.088 337.946C591.835 337.939 592.578 338.057 593.286 338.293C593.945 338.514 594.56 338.848 595.104 339.28C596.187 340.141 596.912 341.373 597.139 342.739L594.888 342.739Z"
              fill="white"
            />
            {/* Funnel Section Subheader - Pre-Conversion */}
            <OptimizeSubheader
              d="M392.131 375.508V363.871H396.062C396.84 363.84 397.612 364.009 398.304 364.363C398.86 364.663 399.311 365.126 399.597 365.689C399.882 366.268 400.026 366.907 400.017 367.552C400.026 368.2 399.883 368.84 399.6 369.422C399.316 369.988 398.867 370.455 398.312 370.76C397.626 371.117 396.859 371.288 396.085 371.257H393.267V370.007H396.04C396.561 370.03 397.08 369.919 397.545 369.683C397.907 369.486 398.197 369.178 398.372 368.804C398.543 368.409 398.631 367.984 398.631 367.553C398.631 367.123 398.543 366.697 398.372 366.302C398.196 365.931 397.904 365.627 397.54 365.435C397.066 365.206 396.543 365.098 396.017 365.121H393.54V375.507L392.131 375.508Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M403.889 375.507V363.871H407.82C408.593 363.841 409.361 364 410.059 364.334C410.612 364.612 411.064 365.056 411.354 365.603C411.642 366.172 411.786 366.802 411.775 367.439C411.787 368.072 411.643 368.699 411.354 369.263C411.064 369.802 410.614 370.238 410.065 370.509C409.371 370.835 408.609 370.989 407.843 370.961H404.661V369.689H407.798C408.313 369.709 408.826 369.616 409.3 369.416C409.656 369.258 409.948 368.985 410.13 368.64C410.311 368.266 410.4 367.854 410.389 367.439C410.398 367.017 410.309 366.598 410.127 366.216C409.946 365.862 409.652 365.578 409.292 365.408C408.815 365.197 408.296 365.099 407.775 365.121H405.298V375.507H403.889ZM409.366 370.279L412.229 375.507H410.593L407.775 370.279H409.366Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M415.717 375.508V363.871H422.739V365.121H417.126V369.053H422.376V370.303H417.126V374.258H422.831V375.508H415.717Z"
              fill="#878787"
            />
            <OptimizeSubheader d="M431.702 369.064V370.314H426.611V369.064H431.702Z" fill="#878787" />
            <OptimizeSubheader
              d="M445.211 367.508H443.802C443.728 367.127 443.581 366.764 443.367 366.44C443.166 366.138 442.912 365.876 442.617 365.665C442.321 365.453 441.992 365.292 441.643 365.19C441.281 365.083 440.906 365.029 440.529 365.03C439.854 365.022 439.19 365.208 438.617 365.565C438.023 365.949 437.551 366.495 437.259 367.139C436.589 368.774 436.589 370.606 437.259 372.241C437.551 372.885 438.023 373.432 438.617 373.815C439.19 374.172 439.854 374.357 440.529 374.348C440.906 374.35 441.281 374.296 441.643 374.19C441.992 374.088 442.321 373.927 442.617 373.716C442.912 373.503 443.166 373.239 443.367 372.936C443.58 372.613 443.727 372.251 443.802 371.872H445.211C445.116 372.434 444.92 372.975 444.631 373.467C444.36 373.928 444.005 374.334 443.586 374.665C443.166 374.994 442.692 375.246 442.185 375.411C441.65 375.585 441.091 375.671 440.529 375.667C439.595 375.683 438.676 375.431 437.881 374.94C437.091 374.436 436.459 373.719 436.057 372.872C435.619 371.868 435.393 370.785 435.393 369.69C435.393 368.595 435.619 367.512 436.057 366.508C436.459 365.661 437.091 364.944 437.881 364.44C438.676 363.949 439.595 363.696 440.529 363.712C441.091 363.708 441.65 363.794 442.185 363.967C442.692 364.133 443.166 364.386 443.586 364.715C444.005 365.046 444.359 365.45 444.631 365.909C444.921 366.402 445.118 366.944 445.211 367.508Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M458.901 369.689C458.929 370.787 458.702 371.876 458.237 372.871C457.835 373.718 457.203 374.435 456.413 374.939C455.612 375.415 454.697 375.666 453.765 375.666C452.834 375.666 451.919 375.415 451.118 374.939C450.327 374.435 449.695 373.718 449.293 372.871C448.855 371.868 448.629 370.784 448.629 369.689C448.629 368.594 448.855 367.511 449.293 366.508C449.695 365.661 450.327 364.944 451.118 364.439C451.919 363.963 452.833 363.711 453.765 363.711C454.697 363.711 455.612 363.963 456.413 364.439C457.203 364.944 457.835 365.661 458.237 366.508C458.702 367.502 458.93 368.592 458.901 369.689ZM457.538 369.689C457.565 368.812 457.393 367.94 457.035 367.139C456.743 366.495 456.271 365.948 455.677 365.564C455.101 365.214 454.44 365.029 453.765 365.029C453.091 365.029 452.43 365.214 451.854 365.564C451.259 365.948 450.787 366.494 450.495 367.139C449.825 368.773 449.825 370.606 450.495 372.24C450.787 372.884 451.259 373.431 451.854 373.814C452.43 374.163 453.091 374.348 453.765 374.348C454.439 374.348 455.101 374.163 455.677 373.814C456.271 373.431 456.743 372.884 457.035 372.24C457.393 371.439 457.565 370.567 457.538 369.689Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M472.092 363.871V375.508H470.729L464.388 366.371H464.274V375.508H462.865V363.871H464.229L470.592 373.029H470.706V363.871H472.092Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M476.987 363.871L480.441 373.666H480.578L484.032 363.871H485.51L481.237 375.508H479.782L475.51 363.871H476.987Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M488.928 375.508V363.871H495.95V365.121H490.337V369.053H495.587V370.303H490.337V374.258H496.042V375.508H488.928Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M500.098 375.507V363.871H504.029C504.802 363.841 505.57 364 506.268 364.334C506.82 364.612 507.273 365.056 507.563 365.603C507.851 366.172 507.995 366.802 507.983 367.439C507.996 368.072 507.851 368.699 507.563 369.263C507.273 369.802 506.822 370.238 506.273 370.509C505.579 370.835 504.818 370.989 504.052 370.961H500.87V369.689H504.007C504.522 369.709 505.034 369.616 505.509 369.416C505.865 369.258 506.157 368.985 506.339 368.64C506.52 368.266 506.608 367.854 506.598 367.439C506.607 367.017 506.517 366.598 506.336 366.216C506.155 365.862 505.861 365.578 505.501 365.408C505.024 365.197 504.505 365.099 503.983 365.121H501.507V375.507H500.098ZM505.574 370.279L508.438 375.507H506.802L503.983 370.279H505.574Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M518.287 366.78C518.261 366.508 518.172 366.246 518.028 366.014C517.885 365.781 517.689 365.585 517.458 365.44C516.924 365.108 516.303 364.941 515.674 364.962C515.212 364.952 514.752 365.037 514.324 365.212C513.969 365.357 513.659 365.594 513.427 365.899C513.214 366.187 513.101 366.537 513.105 366.895C513.097 367.178 513.175 367.456 513.33 367.692C513.48 367.915 513.677 368.103 513.906 368.243C514.139 368.388 514.387 368.509 514.645 368.602C514.902 368.694 515.14 368.77 515.355 368.827L516.537 369.145C516.881 369.238 517.219 369.347 517.552 369.473C517.932 369.618 518.293 369.811 518.625 370.046C518.971 370.289 519.26 370.603 519.475 370.968C519.71 371.389 519.825 371.866 519.81 372.348C519.817 372.953 519.646 373.546 519.318 374.053C518.964 374.583 518.468 375.003 517.887 375.264C516.465 375.845 514.876 375.861 513.443 375.309C512.874 375.07 512.379 374.681 512.012 374.184C511.661 373.693 511.457 373.112 511.424 372.509H512.878C512.901 372.911 513.058 373.294 513.324 373.596C513.594 373.889 513.934 374.108 514.312 374.233C514.729 374.373 515.166 374.443 515.606 374.44C516.101 374.448 516.593 374.359 517.054 374.177C517.451 374.022 517.802 373.767 518.071 373.436C518.321 373.121 518.454 372.729 518.446 372.327C518.461 371.983 518.345 371.646 518.122 371.384C517.885 371.126 517.594 370.924 517.271 370.792C516.899 370.634 516.517 370.502 516.128 370.395L514.696 369.985C513.9 369.783 513.161 369.4 512.537 368.866C512.274 368.625 512.067 368.33 511.93 368.001C511.792 367.673 511.728 367.318 511.741 366.962C511.729 366.35 511.916 365.751 512.273 365.255C512.642 364.756 513.136 364.364 513.705 364.118C514.34 363.841 515.026 363.702 515.719 363.712C516.407 363.699 517.089 363.837 517.719 364.114C518.268 364.353 518.747 364.73 519.108 365.208C519.449 365.662 519.638 366.212 519.65 366.78H518.287Z"
              fill="#878787"
            />
            <OptimizeSubheader d="M525.138 363.871V375.508H523.729V363.871H525.138Z" fill="#878787" />
            <OptimizeSubheader
              d="M539.374 369.689C539.402 370.787 539.174 371.876 538.709 372.871C538.308 373.718 537.676 374.435 536.886 374.939C536.084 375.415 535.17 375.666 534.238 375.666C533.306 375.666 532.391 375.415 531.59 374.939C530.799 374.435 530.167 373.718 529.766 372.871C529.328 371.868 529.102 370.784 529.102 369.689C529.102 368.595 529.328 367.511 529.766 366.508C530.167 365.661 530.799 364.944 531.59 364.439C532.391 363.963 533.306 363.711 534.238 363.711C535.17 363.711 536.085 363.963 536.886 364.439C537.676 364.944 538.308 365.661 538.709 366.508C539.174 367.502 539.402 368.592 539.374 369.689ZM538.011 369.689C538.037 368.812 537.865 367.94 537.508 367.139C537.215 366.495 536.744 365.948 536.149 365.564C535.573 365.214 534.912 365.029 534.238 365.029C533.564 365.029 532.902 365.214 532.326 365.564C531.731 365.948 531.26 366.494 530.968 367.139C530.297 368.773 530.297 370.606 530.968 372.24C531.26 372.885 531.731 373.431 532.326 373.814C532.903 374.163 533.564 374.348 534.238 374.348C534.912 374.348 535.573 374.163 536.149 373.814C536.744 373.431 537.215 372.884 537.508 372.24C537.865 371.439 538.037 370.567 538.011 369.689Z"
              fill="#878787"
            />
            <OptimizeSubheader
              d="M552.563 363.871V375.508H551.2L544.858 366.371H544.745V375.508H543.336V363.871H544.7L551.063 373.029H551.177V363.871H552.563Z"
              fill="#878787"
            />
            {/* Funnel Section Label - Drive Sales */}
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M404.931 488.133H400.223V473.587H405.081C406.359 473.551 407.625 473.851 408.752 474.457C409.77 475.032 410.591 475.901 411.107 476.95C411.649 478.178 411.929 479.506 411.927 480.848C411.926 482.191 411.644 483.518 411.1 484.745C410.576 485.805 409.742 486.68 408.71 487.256C407.547 487.87 406.245 488.172 404.931 488.133ZM402.417 486.215H404.811C405.77 486.25 406.723 486.033 407.573 485.587C408.304 485.171 408.882 484.531 409.221 483.762C409.949 481.887 409.95 479.809 409.225 477.933C408.893 477.173 408.327 476.539 407.609 476.123C406.791 475.686 405.872 475.473 404.945 475.505H402.417L402.417 486.215Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M416.654 488.134V473.588H421.839C422.808 473.554 423.772 473.754 424.648 474.171C425.357 474.523 425.94 475.085 426.32 475.779C426.701 476.51 426.891 477.325 426.875 478.148C426.892 478.965 426.7 479.773 426.317 480.495C425.934 481.18 425.349 481.73 424.641 482.068C423.76 482.471 422.799 482.663 421.832 482.63H417.904V480.74H421.633C422.227 480.762 422.818 480.658 423.369 480.434C423.788 480.258 424.134 479.945 424.353 479.547C424.572 479.114 424.679 478.633 424.666 478.148C424.678 477.654 424.57 477.164 424.35 476.721C424.132 476.311 423.785 475.985 423.362 475.794C422.808 475.557 422.207 475.447 421.604 475.471H418.849V488.134H416.654ZM423.834 481.571L427.428 488.134H424.928L421.406 481.571H423.834Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M433.747 473.586V488.132H431.553V473.586H433.747Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M440.214 473.586L444.262 485.433H444.426L448.474 473.586H450.846L445.612 488.132H443.076L437.842 473.586H440.214Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M454.941 488.132V473.586H464.061V475.476H457.136V479.907H463.585V481.789H457.136V486.242H464.146V488.132H454.941Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M484.232 477.407C484.205 477.092 484.107 476.788 483.944 476.517C483.782 476.245 483.56 476.015 483.295 475.842C482.676 475.451 481.953 475.256 481.222 475.284C480.692 475.272 480.165 475.367 479.673 475.565C479.273 475.724 478.923 475.989 478.661 476.331C478.424 476.65 478.298 477.038 478.303 477.436C478.294 477.752 478.379 478.063 478.547 478.331C478.714 478.585 478.935 478.8 479.193 478.959C479.465 479.129 479.755 479.27 480.057 479.378C480.348 479.485 480.644 479.576 480.944 479.652L482.365 480.022C482.851 480.142 483.328 480.296 483.792 480.483C484.277 480.675 484.734 480.934 485.148 481.25C485.565 481.568 485.91 481.969 486.161 482.429C486.431 482.948 486.564 483.528 486.548 484.113C486.561 484.891 486.339 485.656 485.912 486.307C485.454 486.974 484.817 487.499 484.073 487.82C483.155 488.212 482.162 488.401 481.164 488.373C480.196 488.396 479.234 488.22 478.338 487.855C477.59 487.547 476.943 487.037 476.47 486.381C476.009 485.712 475.75 484.925 475.725 484.113H477.926C477.951 484.593 478.131 485.053 478.44 485.422C478.752 485.778 479.151 486.045 479.599 486.197C480.098 486.369 480.622 486.454 481.15 486.449C481.717 486.458 482.28 486.359 482.809 486.155C483.256 485.985 483.652 485.7 483.956 485.33C484.235 484.978 484.383 484.54 484.375 484.091C484.391 483.705 484.26 483.327 484.009 483.033C483.733 482.736 483.396 482.503 483.021 482.351C482.567 482.157 482.098 481.998 481.619 481.875L479.9 481.407C478.881 481.164 477.933 480.685 477.134 480.007C476.796 479.699 476.53 479.32 476.354 478.898C476.178 478.476 476.097 478.021 476.115 477.564C476.099 476.778 476.338 476.008 476.797 475.369C477.272 474.728 477.909 474.224 478.644 473.909C479.473 473.551 480.368 473.373 481.271 473.388C482.169 473.37 483.059 473.547 483.881 473.907C484.591 474.216 485.207 474.707 485.667 475.33C486.103 475.936 486.342 476.661 486.35 477.407H484.232Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M492.283 488.132H489.953L495.188 473.586H497.724L502.958 488.132H500.628L496.516 476.229H496.402L492.283 488.132ZM492.674 482.436H500.23V484.282H492.674V482.436Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M507.053 488.132V473.586H509.247V486.242H515.838V488.132H507.053Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M520.338 488.132V473.586H529.457V475.476H522.532V479.907H528.982V481.789H522.532V486.242H529.543V488.132H520.338Z"
              fill="white"
            />
            <DriveLabel
              {...generateSectionLabelConfig('drive')}
              d="M542.331 477.407C542.304 477.093 542.205 476.788 542.042 476.517C541.88 476.246 541.658 476.015 541.394 475.842C540.774 475.451 540.051 475.256 539.319 475.284C538.79 475.272 538.263 475.367 537.771 475.565C537.371 475.724 537.021 475.989 536.759 476.331C536.522 476.65 536.396 477.038 536.4 477.436C536.392 477.752 536.478 478.063 536.646 478.331C536.812 478.586 537.033 478.8 537.292 478.959C537.564 479.129 537.853 479.27 538.154 479.378C538.445 479.484 538.742 479.576 539.042 479.652L540.463 480.022C540.949 480.143 541.426 480.297 541.891 480.483C542.376 480.676 542.832 480.934 543.247 481.25C543.664 481.567 544.009 481.969 544.259 482.429C544.529 482.948 544.663 483.528 544.646 484.113C544.659 484.891 544.438 485.656 544.011 486.307C543.552 486.975 542.915 487.499 542.171 487.82C541.253 488.212 540.261 488.401 539.263 488.373C538.295 488.396 537.332 488.22 536.436 487.855C535.688 487.547 535.041 487.037 534.568 486.381C534.107 485.712 533.848 484.925 533.822 484.113H536.024C536.049 484.593 536.23 485.053 536.539 485.422C536.85 485.778 537.249 486.045 537.696 486.197C538.195 486.369 538.72 486.454 539.248 486.449C539.815 486.458 540.378 486.359 540.907 486.155C541.355 485.985 541.75 485.701 542.054 485.33C542.333 484.978 542.481 484.54 542.473 484.091C542.488 483.705 542.358 483.327 542.107 483.033C541.832 482.736 541.495 482.503 541.12 482.351C540.665 482.157 540.196 481.998 539.717 481.875L537.998 481.407C536.979 481.164 536.031 480.685 535.232 480.007C534.895 479.699 534.628 479.32 534.452 478.898C534.276 478.476 534.194 478.021 534.213 477.564C534.197 476.778 534.436 476.008 534.895 475.369C535.37 474.728 536.007 474.224 536.741 473.909C537.57 473.551 538.466 473.373 539.369 473.388C540.266 473.37 541.157 473.547 541.979 473.907C542.69 474.216 543.306 474.707 543.766 475.33C544.202 475.936 544.44 476.661 544.447 477.407H542.331Z"
              fill="white"
            />
            {/* Funnel Section Subheader - Conversion */}
            <DriveSubheader
              d="M423.354 501.822H421.944C421.871 501.441 421.723 501.078 421.51 500.754C421.308 500.452 421.054 500.19 420.76 499.978C420.463 499.767 420.134 499.607 419.785 499.504C419.424 499.397 419.048 499.343 418.671 499.344C417.996 499.337 417.333 499.522 416.76 499.879C416.165 500.262 415.694 500.809 415.401 501.453C414.731 503.088 414.731 504.921 415.401 506.555C415.694 507.199 416.166 507.745 416.76 508.129C417.333 508.486 417.996 508.671 418.671 508.663C419.048 508.664 419.424 508.611 419.785 508.504C420.134 508.401 420.464 508.241 420.76 508.029C421.376 507.589 421.8 506.929 421.944 506.185H423.354C423.259 506.748 423.062 507.289 422.774 507.782C422.502 508.242 422.148 508.647 421.729 508.978C421.309 509.308 420.835 509.561 420.328 509.725C419.793 509.899 419.234 509.985 418.671 509.981C417.737 509.998 416.818 509.745 416.024 509.254C415.233 508.749 414.601 508.033 414.2 507.185C413.761 506.182 413.535 505.099 413.535 504.004C413.535 502.909 413.761 501.825 414.2 500.822C414.602 499.975 415.234 499.258 416.024 498.754C416.819 498.263 417.737 498.01 418.671 498.026C419.234 498.023 419.793 498.109 420.328 498.282C420.835 498.447 421.309 498.7 421.729 499.029C422.147 499.36 422.501 499.764 422.774 500.222C423.063 500.716 423.26 501.258 423.354 501.822Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M437.044 504.003C437.072 505.101 436.844 506.19 436.379 507.185C435.978 508.032 435.346 508.749 434.556 509.253C433.755 509.729 432.84 509.98 431.908 509.98C430.976 509.98 430.061 509.729 429.26 509.253C428.47 508.749 427.837 508.032 427.436 507.185C426.998 506.181 426.771 505.098 426.771 504.003C426.771 502.908 426.998 501.825 427.436 500.821C427.838 499.974 428.47 499.258 429.26 498.753C430.061 498.277 430.976 498.025 431.908 498.025C432.84 498.025 433.755 498.277 434.556 498.753C435.345 499.258 435.977 499.974 436.379 500.821C436.844 501.816 437.072 502.905 437.044 504.003ZM435.681 504.003C435.707 503.126 435.535 502.254 435.178 501.452C434.885 500.808 434.414 500.262 433.82 499.878C433.243 499.529 432.582 499.344 431.908 499.344C431.234 499.344 430.573 499.529 429.996 499.878C429.402 500.262 428.93 500.808 428.638 501.452C427.968 503.087 427.968 504.92 428.638 506.555C428.93 507.198 429.402 507.745 429.996 508.128C430.573 508.477 431.234 508.662 431.908 508.662C432.582 508.662 433.243 508.477 433.82 508.128C434.414 507.744 434.885 507.198 435.178 506.555C435.535 505.753 435.707 504.881 435.681 504.003Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M450.235 498.186V509.822H448.872L442.53 500.686H442.417V509.822H441.008V498.186H442.372L448.735 507.345H448.849V498.186H450.235Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M455.129 498.186L458.584 507.981H458.72L462.175 498.186H463.652L459.379 509.822H457.925L453.652 498.186H455.129Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M467.07 509.822V498.186H474.093V499.436H468.479V503.368H473.729V504.618H468.479V508.572H474.184V509.822H467.07Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M478.238 509.822V498.185H482.17C482.943 498.155 483.712 498.315 484.409 498.649C484.962 498.928 485.415 499.371 485.704 499.919C485.992 500.487 486.136 501.117 486.125 501.754C486.137 502.387 485.993 503.014 485.704 503.578C485.415 504.117 484.964 504.553 484.415 504.825C483.721 505.15 482.959 505.305 482.193 505.276H479.011V504.004H482.147C482.662 504.024 483.175 503.931 483.65 503.731C484.006 503.573 484.298 503.3 484.479 502.956C484.661 502.581 484.75 502.169 484.738 501.754C484.748 501.332 484.659 500.913 484.478 500.532C484.296 500.177 484.002 499.892 483.642 499.722C483.165 499.512 482.646 499.414 482.125 499.435H479.647V509.822H478.238ZM483.716 504.594L486.579 509.822H484.943L482.125 504.594H483.716Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M496.429 501.094C496.402 500.822 496.314 500.56 496.17 500.327C496.026 500.095 495.83 499.899 495.599 499.754C495.064 499.421 494.443 499.255 493.814 499.276C493.352 499.266 492.893 499.352 492.465 499.526C492.11 499.671 491.8 499.908 491.567 500.214C491.355 500.501 491.242 500.851 491.246 501.209C491.238 501.491 491.317 501.77 491.471 502.007C491.621 502.229 491.818 502.417 492.048 502.557C492.28 502.703 492.528 502.823 492.786 502.916C493.044 503.009 493.281 503.083 493.496 503.14L494.679 503.459C495.022 503.551 495.36 503.661 495.692 503.788C496.073 503.932 496.434 504.124 496.767 504.359C497.112 504.603 497.402 504.917 497.616 505.282C497.851 505.703 497.967 506.181 497.951 506.663C497.958 507.267 497.787 507.86 497.46 508.368C497.105 508.898 496.609 509.317 496.027 509.578C494.607 510.16 493.017 510.176 491.585 509.623C491.014 509.384 490.519 508.995 490.152 508.498C489.802 508.006 489.598 507.425 489.564 506.822H491.02C491.042 507.224 491.199 507.608 491.465 507.91C491.735 508.203 492.076 508.422 492.454 508.547C492.87 508.687 493.307 508.757 493.746 508.754C494.242 508.762 494.734 508.672 495.195 508.49C495.593 508.336 495.943 508.081 496.212 507.751C496.462 507.435 496.594 507.043 496.587 506.64C496.602 506.296 496.487 505.959 496.264 505.697C496.027 505.44 495.735 505.238 495.411 505.106C495.04 504.948 494.659 504.815 494.27 504.709L492.837 504.3C492.041 504.097 491.303 503.714 490.679 503.18C490.416 502.94 490.209 502.645 490.071 502.316C489.934 501.987 489.87 501.632 489.883 501.276C489.87 500.665 490.057 500.066 490.414 499.569C490.783 499.071 491.277 498.679 491.846 498.432C492.481 498.155 493.168 498.016 493.86 498.026C494.548 498.014 495.231 498.15 495.86 498.428C496.409 498.667 496.888 499.044 497.249 499.521C497.589 499.976 497.779 500.526 497.792 501.095L496.429 501.094Z"
              fill="#878787"
            />
            <DriveSubheader d="M503.278 498.186V509.822H501.869V498.186H503.278Z" fill="#878787" />
            <DriveSubheader
              d="M517.515 504.003C517.543 505.101 517.316 506.19 516.851 507.185C516.449 508.032 515.817 508.749 515.026 509.253C514.225 509.729 513.311 509.98 512.379 509.98C511.447 509.98 510.532 509.729 509.731 509.253C508.941 508.749 508.308 508.032 507.907 507.185C507.469 506.181 507.242 505.098 507.242 504.003C507.242 502.908 507.469 501.825 507.907 500.821C508.309 499.974 508.941 499.257 509.731 498.753C510.532 498.277 511.447 498.025 512.379 498.025C513.311 498.025 514.225 498.277 515.026 498.753C515.816 499.258 516.448 499.974 516.851 500.821C517.316 501.816 517.543 502.905 517.515 504.003ZM516.151 504.003C516.178 503.126 516.006 502.254 515.648 501.452C515.356 500.808 514.885 500.262 514.291 499.878C513.714 499.528 513.053 499.344 512.379 499.344C511.705 499.344 511.043 499.528 510.467 499.878C509.873 500.262 509.401 500.808 509.108 501.452C508.439 503.087 508.439 504.92 509.108 506.555C509.401 507.198 509.873 507.744 510.467 508.128C511.043 508.477 511.705 508.662 512.379 508.662C513.053 508.662 513.714 508.477 514.291 508.128C514.885 507.744 515.356 507.198 515.648 506.555C516.006 505.753 516.178 504.881 516.151 504.003Z"
              fill="#878787"
            />
            <DriveSubheader
              d="M530.706 498.186V509.822H529.343L523.001 500.686H522.888V509.822H521.479V498.186H522.843L529.206 507.345H529.319V498.186H530.706Z"
              fill="#878787"
            />
          </motion.g>
          <defs>
            <radialGradient
              id="paint0_radial_6577_3704"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(233.489 357.588) scale(248.433 248.433)"
            >
              <stop offset="0.07813" stopColor={colors.black} stopOpacity="0.1" />
              <stop offset="0.60861" stopColor={colors.black} stopOpacity="0.1" />
              <stop offset="0.6994" stopColor={colors.black} />
              <stop offset="1" stopColor={colors.black} stopOpacity="0.1" />
            </radialGradient>
            <radialGradient
              id="paint1_radial_6577_3704"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(711.835 356.757) rotate(180) scale(247.971 247.971)"
            >
              <stop offset="0.07813" stopColor={colors.black} stopOpacity="0.1" />
              <stop offset="0.60861" stopColor={colors.black} stopOpacity="0.1" />
              <stop offset="0.6994" stopColor={colors.black} />
              <stop offset="1" stopColor={colors.black} stopOpacity="0.1" />
            </radialGradient>
            <clipPath id="clip0_6577_3704">
              <rect width="947.295" height="635.757" fill="white" />
            </clipPath>
          </defs>
        </motion.svg>
      </FadeContainer>
    </Root>
  );
};
