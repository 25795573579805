import React from 'react';
import { ModalRoot, ModalContainer } from '..';

import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../styles';

import Landing from './Landing';
import InfoView from '../UploadModal/InfoView';
import MarketoIntegrations from './MarketoIntegrations';
import HubspotIntegrations from '../UploadModal/HubspotIntegrations';
import MarketoNewIntegration from './MarketoNewIntegration';
import HubspotNewIntegration from '../UploadModal/HubspotNewIntegration';
import MarketoNewPull from './MarketoNewPull';
import HubspotNewPull from './HubspotNewPull';
import SendgridConfig from './SendgridConfig';

//@ts-ignore
import close_icon from '../../assets/icons/close.svg';
import // createSendgridIntegration,
// createMarketoIntegration,
// createSendgridWebhook,
// importSendgridCampaigns,
'../../api/integrations';

import {
  CREATE_HS_INTEGRATION,
  CREATE_MARKETO_PULL,
  CREATE_MARKETO_INTEGRATION,
  CREATE_SENDGRID_WEBHOOK,
  GET_SENDGRID_DATA,
  GET_INTEGRATIONS,
  IMPORT_SENDGRID_CAMPAIGNS,
  IMPORT_HS_CONTACTS,
  CREATE_SENDGRID_INTEGRATION,
} from '../../api/integrations';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import SendgridIntegrations from './SendgridIntegrations';
import SendgridNewIntegration from './SendgridNewIntegration';
import SendgridNewPull from './SendgridNewPull';

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: '24px',
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  //   margin: '15px 10px 0px 10px',
  padding: '10px 0px',
  '&:hover': {
    opacity: 0.7,
  },
});

export type ModalPageType =
  | 'Landing'
  | 'MarketoIntegrations'
  | 'MarketoNewIntegration'
  | 'MarketoNewPull'
  | 'HubspotIntegrations'
  | 'HubspotNewIntegration'
  | 'HubspotNewPull'
  | 'SendgridIntegrations'
  | 'SendgridNewIntegration'
  | 'SendgridNewPull'
  | 'SendgridConfig'
  | 'InfoView'
  | null;
//Props interface
interface Props {
  modalPage: ModalPageType;
  setModalPage: any;
}

type infoPageCopyType = {
  header: string;
  subheader?: string;
  loader: boolean;
};

export const MarketingIntegrationModal = ({ modalPage, setModalPage }: Props) => {
  const [infoPageCopy, setInfoPageCopy] = React.useState<infoPageCopyType>();
  const [hubspotIntegrationId, setHubspotIntegrationId] = React.useState<string | null>(null);
  const [marketoIntegrationId, setMarketoIntegrationId] = React.useState<string | null>(null);
  const [sendgridIntegrationId, setSendgridIntegrationId] = React.useState<string | null>(null);

  const closeModal = () => {
    setModalPage(null);
  };

  const [startHubspotImportJob] = useMutation(IMPORT_HS_CONTACTS, {
    variables: { integrationId: hubspotIntegrationId ?? '', propertiesMapping: [] },
  });

  const [createSendgridWebhook] = useMutation(CREATE_SENDGRID_WEBHOOK, {
    onCompleted: (data) => {
      if (!data?.sendgridCreateWebhook?.success) {
        setInfoPageCopy({
          header: 'Sendgrid Error',
          subheader: `An unexpected error occured while creating your sendgrid webhook. (${data?.sendgridCreateWebhook?.traceback})`,
          loader: false,
        });
      }
      setTimeout(() => {
        setModalPage('SendgridNewPull');
      }, 500);
    },
  });

  const [importSendgridCampaigns] = useMutation(IMPORT_SENDGRID_CAMPAIGNS, {
    onCompleted: (data) => {
      setInfoPageCopy({
        header: 'Sendgrid data job created',
        subheader: `You should be able to see the status of your integration soon.`,
        loader: false,
      });
      setTimeout(() => {
        closeModal();
      }, 1000);
    },
  });

  const [getSendgridData] = useLazyQuery(GET_SENDGRID_DATA, {});
  const [createSendgridIntegration] = useMutation(CREATE_SENDGRID_INTEGRATION, {
    onCompleted: (data) => {
      if (data?.sendgridCreateIntegration?.success) {
        // console.log('sendgrid connection: ', sendgridConnection);
        const sendgridId = data?.sendgridCreateIntegration?.integrationDetails?.integration?.id;
        setSendgridIntegrationId(sendgridId ?? null);
        setInfoPageCopy({
          header: 'Creating Webhook',
          subheader: 'This will allow us to push and pull data from your sendgrid account.',
          loader: false,
        });
        // createSendgridWebhook({ variables: { integrationId: sendgridId, publicSigningKey:  } });
        // console.log('response from createSendgridWebhookAuto: ', response);
      } else {
        setInfoPageCopy({
          header: 'Sendgrid Error',
          subheader: `An unexpected error occured while connecting your email service provider. (${data?.sendgridCreateIntegration?.traceback})`,
          loader: false,
        });
      }
    },
  });

  const [createMarketoPull] = useMutation(CREATE_MARKETO_PULL, {
    onCompleted: (data) => {
      setInfoPageCopy({
        header: 'Marketo Successfully Configured',
        subheader: `You should be able to see the status of your integration soon.`,
        loader: false,
      });
    },
  });

  const pullMarketoData = async (marketoIntegrationId: string, startDate: string, formattedFieldMapping: any) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Creating New Hubspot Integration',
      loader: true,
    });
    try {
      createMarketoPull({
        variables: { integrationId: marketoIntegrationId, startDate, configuration: formattedFieldMapping },
      });
    } catch (err: any) {
      setInfoPageCopy({
        header: 'Marketo Error',
        subheader: `An unexpected error occured while configuring your pull. (${err.message})`,
        loader: false,
      });
    }
  };

  const pullSendgridData = async () => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Pulling Data from Sendgrid',
      loader: true,
    });
    try {
      importSendgridCampaigns({ variables: { integrationId: sendgridIntegrationId ?? '' } });
    } catch (err: any) {
      setInfoPageCopy({
        header: 'Sendgrid Error',
        subheader: `An unexpected error occured while configuring your pull. (${err.message})`,
        loader: false,
      });
    }
  };

  const [createHubspotIntegration] = useMutation(CREATE_HS_INTEGRATION, {
    onCompleted: (data) => {
      setHubspotIntegrationId(data?.hubspotCreateIntegration?.integrationDetails?.id);
      setModalPage('HubspotNewPull');
    },
  });

  const linkHubspot = async (id: string, name: string) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Creating New Hubspot Integration',
      loader: true,
    });
    try {
      let outputContacts = [];
      const hubspotConnection = await createHubspotIntegration({
        variables: { details: { apiKey: id, nickname: name } },
      });
      // console.log('hubspot connection: ', hubspotConnection);
    } catch (err: any) {
      console.warn('hubspot error:', err);
      setInfoPageCopy({
        header: 'Hubspot Error',
        subheader: `An unexpected error occured while importing your contacts. (${err.message})`,
        loader: false,
      });
    }
  };

  const linkSendgrid = async (apiKey: string, nickname: string, subuser: string) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Creating New Sendgrid Integration',
      loader: true,
    });
    try {
      let outputContacts = [];
      createSendgridIntegration({ variables: { apiKey, subuser, nickname } });
    } catch (err: any) {
      console.warn('sendgrid error:', err);
      setInfoPageCopy({
        header: 'Sendgrid Error',
        subheader: `An unexpected error occured while connecting your email service provider. (${err.message})`,
        loader: false,
      });
    }
  };

  const [createMarketoIntegration] = useMutation(CREATE_MARKETO_INTEGRATION, {
    onCompleted: (data) => {
      setMarketoIntegrationId(data?.marketoCreateIntegration?.details ?? null);
      setModalPage('MarketoNewPull');
    },
  });

  const linkMarketo = async (nickname: string, endpoint: string, clientId: string, clientSecret: string) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Creating New Marketo Integration',
      loader: true,
    });
    try {
      let outputContacts = [];
      createMarketoIntegration({ variables: { nickname, endpoint, clientId, clientSecret } });
      // console.log('marketo connection: ', marketoConnection);
    } catch (err: any) {
      console.warn('hubspot error:', err);
      setInfoPageCopy({
        header: 'Marketo Error',
        subheader: `An unexpected error occured while importing your contacts. (${err.message})`,
        loader: false,
      });
    }
  };

  const PageSelector = (page: ModalPageType) => {
    switch (page) {
      case 'Landing':
        return <Landing setModalPage={setModalPage} />;
      case 'MarketoIntegrations':
        return <MarketoIntegrations setModalPage={setModalPage} setMarketoIntegrationId={setMarketoIntegrationId} />;
      case 'MarketoNewIntegration':
        return <MarketoNewIntegration linkMarketo={linkMarketo} />;
      case 'MarketoNewPull':
        return (
          <MarketoNewPull
            setModalPage={setModalPage}
            pullMarketoData={pullMarketoData}
            marketoIntegrationId={marketoIntegrationId}
          />
        );
      case 'SendgridNewIntegration':
        return <SendgridNewIntegration linkSendgrid={linkSendgrid} />;
      case 'SendgridIntegrations':
        return <SendgridIntegrations setModalPage={setModalPage} setSendgridIntegrationId={setSendgridIntegrationId} />;
      case 'SendgridConfig':
        return (
          <SendgridConfig
            setModalPage={setModalPage}
            sendgridId={sendgridIntegrationId}
            setInfoPageCopy={setInfoPageCopy}
          />
        );
      case 'SendgridNewPull':
        return <SendgridNewPull pullSendgridData={pullSendgridData} />;
      case 'HubspotIntegrations':
        return (
          <HubspotIntegrations
            setModalPage={setModalPage}
            pullHubspotFields={startHubspotImportJob}
            setIntegrationId={setHubspotIntegrationId}
            setIntegrationDetails={() => {}}
          />
        );
      case 'HubspotNewIntegration':
        return <HubspotNewIntegration linkHubspot={linkHubspot} />;
      case 'HubspotNewPull':
        return <HubspotNewPull setModalPage={setModalPage} pullHubspotData={startHubspotImportJob} />;
      case 'InfoView':
        return <InfoView pageCopy={infoPageCopy} />;
      default:
        return <Landing setModalPage={setModalPage} />;
    }
  };

  const GoBack = () => {
    // console.log('modalPage: ', modalPage);
    switch (modalPage) {
      case 'InfoView':
        setModalPage('InfoView');
        break;
      case 'Landing':
        setModalPage('Landing');
        break;
      case 'MarketoNewIntegration':
        setModalPage('MarketoIntegrations');
        break;
      case 'MarketoNewPull':
        setModalPage('MarketoIntegrations');
        break;
      default:
        setModalPage('Landing');
        break;
    }
  };

  return (
    <ModalRoot open={!!modalPage} onClose={() => closeModal()}>
      <ModalContainer
        style={{ minHeight: '440px', width: '700px', justifyContent: 'flex-start', padding: '12px 16px' }}
      >
        <div style={{ width: '660px', height: '20px', display: 'flex', justifyContent: 'space-between' }}>
          {modalPage !== 'Landing' && (
            <MainButton sx={{ margin: '0px 0px 0px', background: colors.white }} onClick={GoBack}>
              Back
            </MainButton>
          )}
          <IconButton sx={{ margin: '4px 0px 0px', height: '16px', width: '16px' }} onClick={() => closeModal()}>
            <img src={close_icon} alt={'close'} />
          </IconButton>
        </div>
        {PageSelector(modalPage)}
      </ModalContainer>
    </ModalRoot>
  );
};
