import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { H2, F3 } from '..';
import { colors } from '../../styles';
import { formatNumber } from '../../helpers/dataFormatter';
// import { getGroupsAvailableToPullIn } from '../../api/integrations';
import { GET_CRM_ADVISORS_SETS } from '../../api/integrations';
import { useQuery } from '@apollo/client';
import GeneralTable from '../Table';

const TextContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  height: 120,
  margin: '30px auto -30px',
  alignItems: 'center',
  flexDirection: 'column',
});

type Props = {
  integrationId: any;
  type: string;
  setModalPage: any;
  setAdvisorSetFilter: any;
};

const CrmAdvisorSets = ({ integrationId, setModalPage, type, setAdvisorSetFilter }: Props) => {
  // const [advisorGroupList, setAdvisorGroupList] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  // const [loadingAdvisorSets, setLoadingAdvisorSets] = useState<boolean>(false);

  const { loading, data: advisorGroupList } = useQuery(GET_CRM_ADVISORS_SETS, {
    variables: {
      integrationId: integrationId,
    },
  });

  const pageCopy = {
    header: 'Add New Dataset',
    subheader: <span>Choose a CRM Campaign to import.</span>,
  };

  return (
    <>
      <TextContainer>
        <H2 style={{ color: colors.black }}>{pageCopy.header}</H2>
        <F3 style={{ color: colors.grey_02, textAlign: 'center', width: '430px', margin: '0px 0px 0px' }}>
          {pageCopy.subheader}
        </F3>
      </TextContainer>
      <div
        style={{
          border: `1px solid ${colors.grey_01}`,
          width: '103%',
          borderRadius: '5px',
          margin: '0px 0px 8px',
          maxHeight: '600px',
          overflowY: 'scroll',
        }}
      >
        <GeneralTable
          headerData={[
            {
              header: 'Campaign Name',
              key: 'name',
            },
            {
              header: 'Number of Contacts',
              key: 'memberCount',
              format: (row: any) => formatNumber(row?.memberCount),
            },
          ]}
          data={advisorGroupList}
          dataControls={{
            selections: {},
            setSelections: () => {},
            searchString: searchString,
            searchFunc: setSearchString,
          }}
          hideShadow
          singleSelect
          onRowPress={(row: any) => {
            //   console.log('row in format advisorSetFilter: ', row);
            const advisorSetFilter = { filterType: 'CRM_CONTACT_GROUPS', filterValue: [Number(row?.id)] };
            setAdvisorSetFilter(advisorSetFilter);
            setModalPage(type === 'Hubspot' ? 'HubspotMatchImportHeaders' : 'SalesforceMatchHeaders');
          }}
          rowsInput={5}
          rowsInputOption={[5, 10]}
          search
          loading={loading}
        />
      </div>
    </>
  );
};

export default CrmAdvisorSets;
