import { cloneDeep } from 'lodash';
import moment from 'moment';

const getKeyMetric = (key: string, item: any) => {
  if (key === 'fullName' && item[key]) {
    return item[key].split(' ').pop();
  } else if (key === 'enrichedData') {
    return !!item['fullName'];
  } else {
    return item[key];
  }
};

export const sortingHelper = (key: string, list: any, ascending: boolean, time?: boolean) => {
  const newList = cloneDeep(list);
  newList.sort((a: any, b: any) => {
    let metricA = getKeyMetric(key, a);
    let metricB = getKeyMetric(key, b);
    if (time) {
      metricA = moment(metricA).unix();
      metricB = moment(metricB).unix();
    }
    let ordering = 0;
    if (metricA < metricB) {
      ordering = 1;
    } else if (metricA > metricB || (metricA && !metricB)) {
      ordering = -1;
    }

    return ordering;
  });
  if (!ascending) {
    const reverseNewList = newList.reverse();
    return reverseNewList;
  }
  return newList;
};
