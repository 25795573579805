import React, { useEffect } from 'react';
import { ModalRoot, ModalContainer, H2, ModalHeader, Column, H3, F3 } from '../../components';
import { IconButton } from '@mui/material';
import { GET_ROI_REPORTS, DOWNLOAD_ROI_REPORT } from '../../api/dataAPI';
import { useMutation, useQuery } from '@apollo/client';
import GeneralTable from '../Table';
import { typography, colors } from '../../styles';
import closeIcon from '../../assets/icons/close.svg';
import { styled } from '@mui/material';
import moment from 'moment';

const ModalTitle = styled('h1')({
  ...typography.mainFont,
  fontSize: 16,
  color: colors.black_01,
  fontWeight: 500,
  cursor: 'pointer',
});

interface Props {
  modalStatus: boolean;
  closeModal?: any;
}

function RoiReportsModal({ modalStatus, closeModal }: Props) {
  const [reports, setReports] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [selections, setSelections] = React.useState<any>({});

  //refactor the code below to use the useQuery hook
  const {
    loading: roiReportLoading,
    error: roiLoading,
    data: roiReportData,
  } = useQuery(GET_ROI_REPORTS, {
    onCompleted: (data) => {
      const formattedReports = data?.roiReports?.map((report: any, index: number) => {
        return { ...report, id: index + 12834194 };
      });
      setReports(formattedReports ?? []);
    },
  });

  const formattedOrg: any = {
    wealth: 'REPORT_WEALTH_COMPANY',
    magnifi: 'REPORT_MAGNIFI_COMPANY',
  };

  const [downloadRoiReport, { loading: downloadLoading, data: downloadData, error: downloadError }] = useMutation(
    DOWNLOAD_ROI_REPORT,
    {
      onCompleted: (data) => {
        if (data?.exportRoiReport?.reportLink) {
          let link = document.createElement('a');
          link.setAttribute('href', data?.exportRoiReport?.reportLink);
          document.body.appendChild(link);
          link.click();
        }
      },
    },
  );

  // const getReports = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await dataAPI.getRoiReports();
  //     console.log('roi reports: ', response);
  //     const formattedReports = response.map((report: any, index: number) => {
  //       return { ...report, id: index + 12834194 };
  //     });
  //     setReports(formattedReports);
  //     setLoading(false);
  //   } catch (error: any) {
  //     setLoading(false);
  //     console.warn('error getting roi reports: ', error);
  //   }
  // };

  // const downloadReport = async (selectedReport: any) => {
  //   const reportOrg = formattedOrg?.[selectedReport?.organization];

  //   console.log('download response: ', reportOrg, selectedReport?.reportDate);
  //   try {
  //     const response = await dataAPI.downloadRoiReport(reportOrg, selectedReport?.reportDate);
  //     console.log('download response: ', response);
  //     let link = document.createElement('a');
  //     link.setAttribute('href', response?.reportLink);
  //     document.body.appendChild(link);
  //     link.click();
  //     //   window.open(response?.reportLink, '_blank');
  //   } catch (error: any) {
  //     console.warn('error downloading roi report: ', error?.message);
  //   }
  // };

  const headerData = [
    {
      header: 'Report Name',
      key: 'reportFileName',
    },
    {
      header: 'Date',
      key: 'reportDate',
      formatData: (row: any) => {
        return moment(row?.reportDate).format('MM/DD/YYYY');
      },
    },
    {
      header: ' ',
      key: ' ',
      formatData: (row: any, hover: boolean, toggleContactUs: any) => {
        return (
          <H3
            sx={{ minWidth: '140px', opacity: hover ? 1 : 0 }}
            onClick={(event) => {
              event.stopPropagation();
              downloadRoiReport({
                variables: { reportCompany: formattedOrg?.[row?.organization], reportDate: row?.reportDate },
              });
            }}
          >
            Download
          </H3>
        );
      },
    },
  ];

  return (
    <ModalRoot open={modalStatus} onClose={closeModal}>
      <ModalContainer
        style={{
          width: '800px',
          justifyContent: 'space-between',
          background: colors.white,
          padding: '0px 0px',
        }}
      >
        <ModalHeader style={{ width: '100%' }}>
          <ModalTitle>Product Placement Reports</ModalTitle>
          <IconButton onClick={closeModal}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </ModalHeader>
        <Column style={{ display: 'flex', flex: 1, height: '100%', width: '100%' }}>
          {reports.length === 0 && !loading ? (
            <Column style={{}}>
              <H2 style={{ color: colors.grey_03, margin: '60px 30px 4px ' }}>No Reports Found</H2>
              <F3 style={{ color: colors.grey_02, margin: '0px 30px 60px' }}>
                Please contact your customer success representative.
              </F3>
            </Column>
          ) : (
            <GeneralTable
              headerData={headerData}
              data={reports}
              dataControls={{
                selections,
                setSelections,
              }}
              hideShadow
              singleSelect
              onRowPress={(row: any) => {
                setSelections({ [row?.id]: !selections[row?.id] });
                //   setSelectedReport(row);
              }}
              search={false}
              loading={loading}
            />
          )}
        </Column>
      </ModalContainer>
    </ModalRoot>
  );
}

export default RoiReportsModal;
