import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentContainer, PrimaryButtonShort } from '../../../components';
import ComingSoonModal from '../../../components/ComingSoonModal';
import ModelsImageMd from '../../../assets/misc/ModelsImageMd.svg';
import { colors } from '../../../styles';
import { Title, F3, PreviewContainer } from '../../../components';
import { styled } from '@mui/material/styles';
type Props = {};

export const Models = (props: Props) => {
  const [showComingSoon, setShowComingSoon] = useState<boolean>(false);
  const navigate = useNavigate();

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  return (
    <div style={{ paddingBottom: '30' }}>
      <PreviewContainer>
        <div style={{ minWidth: 250, width: '33%' }}>
          <Title style={{ color: colors.grey_03 }}>Have your models added to the TIFIN Wealth marketplace</Title>
          <F3 style={{ margin: '0px 0px 20px' }} id={`model-content-body`}>
            Place your models front in center in the Tifin Wealth Model Marketplace. Models in Tifin Wealth are
            seamlessly purchased in the platform. Drive flows your to models today.{' '}
          </F3>
          <PrimaryButtonShort children={'Start by Getting in Touch'} onClick={toggleComingSoon} />
        </div>
        <img style={{ width: '66%' }} src={ModelsImageMd} alt="webinar" />
      </PreviewContainer>
      <ComingSoonModal
        modalStatus={showComingSoon}
        closeModal={toggleComingSoon}
        custom={'This feature is currently being built, please check back soon for updates.'}
      />
    </div>
  );
};
