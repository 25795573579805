import { gql } from '../__generated__';

export const UPLOAD_ADVISOR_DATASET = gql(`        
  mutation UploadAdvisorDataset($name: String!, $mapping: [DatasetColumnMappingInputType]!, $file: Upload!) {
      uploadAdvisorsDataset(file: $file, mapping: $mapping, name: $name) {
        datasetId
        jobId
        success
      }
    }
`);

export const EXPORT_RUN_HISTORY = gql(`
  mutation exportRunHistory($runHistoryId: ID!, $integrationId: ID!, $advisorFilters: [FilterType]) {
    exportRunHistory(
      runHistoryId: $runHistoryId
      integrationId: $integrationId
      advisorFilters: $advisorFilters
    ) {
      jobId
      errors
    }
  }
`);

export const GET_ADVISOR_EMAIL = gql(`
  query getAdvisorEmail($repCrd: Int!) {
    advisorEmail(repCrd: $repCrd)
  }
`);

export const VALIDATE_SF_INTEGRATION = gql(`
    mutation validateSfConfig($integrationId: ID!) {
          validateSfIntegration(integrationId: $integrationId) {
            success
            traceback
            validationErrors
        }
    }
`);

export const EXPORT_TO_INTEGRATION = gql(`
  mutation ExportContacts($runHistoryId: ID!, $integrationId: ID!) {
    exportRunHistory(runHistoryId: $runHistoryId, integrationId: $integrationId) {
      jobId
      errors
    }
  }
`);

export const CREATE_DYNAMICS_INTEGRATION = gql(`
  mutation create_dynamics($details: DynamicsIntegrationInput!) {
    dynamicsCreateIntegration(details: $details) {
      oauthLink
      redirectUrl
      success
      traceback
      integrationDetails {
        id
        crmUrl
        integration {
          id
          type
        }
      }
    }
  }
`);

export const IMPORT_DYNAMICS_CONTACTS = gql(`
  query dynamics($id: ID!) {
    dynamics {
      contacts(integrationId: $id) {
        id
        firstName
        middleName
        lastName
        phone
        email
        city
        state
        postalCode
        street
        country
      }
    }
  }
`);

export const GET_UPLOAD_JOB = gql(`
  query getJobStatus($jobId: ID!) {
    jobs {
      fromDramatiqId(jobId: $jobId) {
        status
        actorName
        message
        queueName
      }
    }
  }
`);

export const UPDATE_JOURNEY_NAME = gql(`
  mutation updateJourneyName($journeyId: ID!, $name: String) {
    updateJourney(journeyId: $journeyId, name: $name) {
      success
    }
  }
`);

export const GET_SELECTED_ADVISOR = gql(`
  query getSelectedAdvisor($filters: [FilterType]) {
    myAdvisors(filters: $filters, pageSize: 3000) {
      totalItems
      list {
        id
        firstName
        lastName
        hashedEmail
      }
    }
  }
`);

export const UPDATE_SF_INTEGRATION = gql(`
  mutation updateSfIntegration(
    $integrationId: ID!
    $nickname: String
    $advisorLeadPropertiesMapping: [DatasetColumnMappingInputType]
    $advisorLeadObjectType: String
    $advisorLeadShouldExportCampaign: Boolean
    $advisorLeadRepCrdSfField: String
  ) {
    updateSfIntegration(
      integrationId: $integrationId
      nickname: $nickname
      advisorLeadPropertiesMapping: $advisorLeadPropertiesMapping
      advisorLeadObjectType: $advisorLeadObjectType
      advisorLeadShouldExportCampaign: $advisorLeadShouldExportCampaign
      advisorLeadRepCrdSfField: $advisorLeadRepCrdSfField
    ) {
      details
      success
      traceback
    }
  }
`);

export const UPDATE_HS_INTEGRATION = gql(`
  mutation updateHsIntegration(
    $integrationId: ID!
    $nickname: String
    $propertiesMapping: [DatasetColumnMappingInputType]
    $repCrdKeyField: String
  ) {
    updateHubspotIntegration(
      integrationId: $integrationId
      nickname: $nickname
      propertiesMapping: $propertiesMapping
      repCrdKeyField: $repCrdKeyField
    ) {
      details
      success
    }
  }
`);

export const CREATE_HS_INTEGRATION = gql(`
  mutation addHubspotIntegration($details: HSIntegrationInput!) {
    hubspotCreateIntegration(details: $details) {
      success
      traceback
      integrationDetails {
        id
        nickname
        integration {
          id
        }
      }
    }
  }
`);

export const CREATE_MARKETO_PULL = gql(`
  mutation createMarketoPull($integrationId: ID!, $startDate: Date!, $configuration: [EventTypeConfiguration]!) {
    marketoConfigureMarketingIntegration(
      integrationId: $integrationId
      startDate: $startDate
      configuration: $configuration
    ) {
      success
      details
    }
  }
`);

export const GET_MARKETO_INTEGRATION = gql(`
  query getMarketoIntegration($integrationId: ID!) {
    marketo(integrationId: $integrationId) {
      importJobs {
        list {
          processed
          startDate
          query
          status
          type
          exportId
          endDate
          dateUpdated
          id
          fileData
          dateCreated
        }
      }
    }
  }
`);

export const GET_MARKETO_ACTIVITIES = gql(`
  query getMarketoActivities($integrationId: ID!) {
    marketo(integrationId: $integrationId) {
      activityTypes
    }
  }
`);

export const GET_INTEGRATIONS = gql(`
  query getIntegrations {
    integrations {
      id
      dateCreated
      dateUpdated
      nickname
      status
      type
      sfintegrationauthdetailsSet {
        id
        scopes
        signalRecordTypeIdMap
        shouldUseAppNamespace
        redirectUrl
        advisorLeadObjectType
        advisorLeadRepCrdSfField
        advisorLeadPropertiesMapping
        advisorLeadShouldExportCampaign
        validatedOn
        instanceUrl
        influenceLeadPropertiesMapping
        influenceLeadShouldExportCampaign
        influenceLeadObjectType
        dateCreated
        dateUpdated
        nickname
        prioritizeObjectType
        prioritizePropertiesMapping
        prioritizeShouldExportCampaign
      }
      sendgridintegrationdetailsSet {
        companyName
        dateCreated
        dateUpdated
        id
        nickname
        publicSigningKey
        subuser
        urlEndpoint
      }
      marketoauthdetails {
        analyticsPullStartDate
        contactPropertiesMapping
        contactsImportProgress
        contactsPropertiesMapping
        contactsPullStartDate
        doPullAnalytics
        doPullContacts
        analyticsImportProgress
        analyticsPropertiesMapping
      }
      hubspotintegrationdetailsSet {
        id
        nickname
        contactPropertiesMapping
        ampSignalObjectName
        ampSignalToContactAssocId
        repCrdHubspotField
        dateCreated
        dateUpdated
      }
      dynamicsintegrationauthdetailsSet {
        nickname
        id
        dateUpdated
        dateCreated
      }
    }
  }
`);

export const CREATE_MARKETO_INTEGRATION = gql(`
  mutation createMarketoIntegration(
    $clientId: String!
    $clientSecret: String!
    $endpoint: String!
    $nickname: String!
  ) {
    marketoCreateIntegration(
      clientId: $clientId
      clientSecret: $clientSecret
      endpoint: $endpoint
      nickname: $nickname
    ) {
      details
      success
      traceback
    }
  }
`);

export const GET_HS_FIELDS = gql(`
  query GetHubspotFields($integrationId: ID!) {
        data {
          hubspot {
            contactProperties(integrationId: $integrationId)
          }
        }
      }
`);

export const IMPORT_HS_CONTACTS = gql(`
  mutation importHubspotContacts($integrationId: ID!, $propertiesMapping: [DatasetColumnMappingInputType]!) {
    importHubspotContacts(integrationId: $integrationId, propertiesMapping: $propertiesMapping) {
      jobId
      success
      traceback
    }
  }
`);

export const GET_HS_CONTACTS = gql(`
  query GetHubspotContacts($integrationId: ID!, $after: ID) {
    data {
      hubspot {
        contacts(integrationId: $integrationId, after: $after) {
          list {
            id
            firstName
            lastName
            email
          }
          next
          hasNext
        }
      }
    }
  }
`);

export const GET_SF_FIELDS = gql(`
  query getSfintegrationHeaders($integrationId: ID!) {
    sf(integrationId: $integrationId) {
      Lead: objectFields(objectType: "Lead") {
        name
        label
      }
      Contact: objectFields(objectType: "Contact") {
        name
        label
      }
    }
  }
`);

export const GET_SET_MEMBERS = gql(`
 query GetDatasets($id: Int!) {
  advisorSet(id: $id) {
    id
    advisorsetmemberSet {
      id
      advisor {
        id
        advisedInvestorsCnt
        advisorType
        age
        averageClient
        careerTenure
        currentFirmTenureYears
        email
        firmCrd
        firmAvgAccountSize
        firmEtfAssetsMillions
        firmMfAssetsMillions
        firmName
        firmNumberBdReps
        firmNumberEmployees
        lastName
        officeState
        officeZipcode
        repcrd
        riaEmployeeRatio
        firstName
        fullName
        firmTotalAssetsInMillions
        firmTotalAccounts
        firmPercentClientsHnwIndividuals
        firmPercentClientsCorporations
        firmPercentClientsCharities
        firmPercentAssetsMf
        firmPercentAssetsEtf
        firmNumberIaReps
        custodian1
        aumSelfReported
        advisordistilldata {
          age
          aumSelfReported
          careerTenure
          currentFirmTenureYears
          custodian1
          firmAvgAccountSize
          firmCrd
          firmEtfAssetsMillions
          firmMfAssetsMillions
          firmName
          firmNumberBdReps
          firmNumberEmployees
          firmNumberIaReps
          firmPercentAssetsEtf
          firmPercentAssetsMf
          firmPercentClientsCharities
          firmPercentClientsCorporations
          firmPercentClientsHnwIndividuals
          firmTotalAccounts
          firmTotalAssetsInMillions
          firstName
          id
          fullName
          officeState
          officeZipcode
          repcrd
          riaEmployeeRatio
          title
        }
      }
    }
  }
  }
`);

export const GET_SETS_FOR_INTEGRATION = gql(`
  query GetIntegrationDatasets($integrationId: Int, $filters: [FilterType]) {
    advisorSets(integrationId: $integrationId) {
      id
      name
      filterConfig
      memberCount
      lastPull
      dateUpdated
      dateCreated
      lastPush
      advisorsetimportjobSet {
        totalCount
        taskId
        status
        progress
        processed
        importedCount
        id
        filterConfig
        dateUpdated
        dateCreated
        bookmark
      }
      integration {
        id
        type
        nickname
        dateCreated
        __typename
      }
    }
    runHistoryExportJobs(filters: $filters, sortKey: "-date_updated") {
      list {
        id
        jobId
        status
        totalCount
        result
        percentComplete
        dateUpdated
        bookmark
        dateCreated
        # dateDone
        runHistory {
          customName
        }
      }
    }
  }
`);

export const GET_INTEGRATION_HEADERS = gql(`
  query integrationHeaders($integrationId: ID!) {
    crm {
      contactProperties(integrationId: $integrationId)
    }
  }
`);

export const SAVE_ADVISOR_SET = gql(`
  mutation createAdvisorSet(
    $integrationId: Int!
    $name: String
    $filterType: String
    $filterConfig: GenericScalar
    $startImport: Boolean
    $propertiesMapping: [DatasetColumnMappingInputType]
  ) {
    upsertAdvisorSet(
      integrationId: $integrationId
      name: $name
      filterType: $filterType
      filterConfig: $filterConfig
      propertiesMapping: $propertiesMapping
      startImport: $startImport
    ) {
      success
      setId
      jobId
      __typename
    }
  }
`);

export const GET_ADVISOR_PREVIEW = gql(`
  mutation validateAdvisorSetFilter(
    $integrationId: Int!
    $filterType: String!
    $filterConfig: GenericScalar!
    $crmProperties: [String]! # $previewCount: Int
  ) {
    validateAdvisorSetFilter(
      integrationId: $integrationId
      filterType: $filterType
      filterConfig: $filterConfig
      previewCount: 10
      crmProperties: $crmProperties
    ) {
      success
      preview
      totalCount
      totalCount
      message
      isValid
      __typename
    }
  }
`);

export const GET_CRM_ADVISORS_SETS = gql(`
  query listCrmContactGroups($integrationId: ID!) {
    crmContactGroups(integrationId: $integrationId) {
      id
      name
      memberCount
      __typename
    }
  }
`);

export const IMPORT_SENDGRID_CAMPAIGNS = gql(`
  mutation importSendgridCampaign($integrationId: ID!) {
    importSendgridCampaigns(integrationId: $integrationId) {
      details
      success
      traceback
    }
  }
`);

export const CREATE_SENDGRID_INTEGRATION = gql(`
  mutation createSendgridIntegration($apiKey: String!, $subuser: String, $nickname: String) {
    sendgridCreateIntegration(details: { apiKey: $apiKey, subuser: $subuser, nickname: $nickname }) {
      success
      integrationDetails {
        id
        integration {
          id
        }
      }
      traceback
    }
  }
`);

export const CREATE_SENDGRID_WEBHOOK = gql(`
        mutation sendgridCreateWebhookManual($integrationId: ID!, $publicSigningKey: String!) {
          sendgridCreateWebhook(integrationId: $integrationId, publicSigningKey: $publicSigningKey) {
            success
            traceback
            integrationDetails {
              urlEndpoint
              publicSigningKey
              # webhookId
              webhooks {
                enabled
                id
                nickname
                url
              }
            }
          }
        }

`);

export const GET_SENDGRID_DATA = gql(`
  query getSendgridWebhooks($integrationId: String!) {
    sendgrid {
      connections(filters: [{ key: "id", value: $integrationId }]) {
        list {
          id
          webhookId
          urlEndpoint
          subuser
          publicSigningKey
          dateUpdated
          dateCreated
          companyName
        }
      }
    }
  }
`);
