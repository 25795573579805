import React, { useEffect, useRef, useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Chip,
  TableContainer,
  TablePagination,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../styles';
import SearchBar from '../SearchBar';
import { tableCellClasses } from '@mui/material/TableCell';
import { formatDollar, formatNumber, formatTimestamp, formatFullName } from '../../helpers/dataFormatter';
import { Row, F3 } from '../../components';
import { sortingHelper } from '../../helpers/tableUtils';
import Checkbox from '../Checkbox';
import Loader from '../Loader';
import { cloneDeep, max, toNumber } from 'lodash';

//images
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import rightArrow from '../../assets/icons/arrow-right.svg';
import sortable from '../../assets/icons/sortable.svg';
import enriched from '../../assets/icons/enriched.svg';
import notEnriched from '../../assets/icons/not-enriched.svg';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '100%',
    borderRadius: '5px',
    margin: '0 auto 20px',
  },
  tableStyle: {
    maxHeight: '700px',
  },
  innerContainer: {
    margin: '15px',
  },
  topDetailsContainer: {
    padding: '10px 10px 0px 20px',
    display: 'flex',
    minHeight: 40,
    marginBottom: 10,
  },
  chipStyle: {
    ...typography.mainFont,
    margin: 'auto 5px auto 0px',
    backgroundColor: colors.white_00,
    border: `1px solid ${colors.grey_02}`,
    fontSize: '15px',
    display: 'inline',
    '& .MuiChip-root': {
      color: colors.grey_03,
    },
    '& .MuiChip-deleteIcon': {
      color: colors.grey_02,
      opacity: 1,
      marginBottom: '-6px !important',
      //backgroundColor: colors.black,
    },
  },
  chipContainer: {
    margin: '18px 0px 0',
    lineHeight: 2,
    width: '70%',
    maxWidth: '800px',
    height: '46px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    padding: '4px 0px',
  },
  rightArrowStyle: {
    margin: '10px 0px 0px 10px',
    cursor: 'pointer',
    width: '10px',
    height: '50px',
  },
  selectedHeaderLabel: {
    ...typography.mainFont,
    color: colors.grey_03,
    fontSize: '14px',
    fontWeight: '600',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    cursor: 'pointer',
    paddingRight: 10,
  },
  headerLabel: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontSize: '14px',
    fontWeight: '400',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    paddingRight: 10,
    width: 'fit-content',
  },
  tableHeader: {
    border: `1px solid ${colors.grey_01}`,
  },
  tableRow: {
    height: '40px',
    '& .MuiTableRow-root': {
      border: `1px solid ${colors.white_01}`,
      borderRadius: '8px',
      cursor: 'pointer',
    },
    '&:hover': {
      backgroundColor: '#F6F9FB',
    },
  },
  iconStyle: {
    margin: '1px 0 0 4px',
  },
  enrichText: {
    fontSize: 14,
    fontWeight: 400,
    color: '#758790',
    paddingLeft: 8,
  },
}));

interface Props {
  data: any;
  dataControls: any;
  FilterComponent?: any;
  headerData: Array<any>;
  loading: boolean;
  initSortColumn?: string;
  paginationControls?: any;
  maxSelections?: number;
}

export const SelectionTable = ({
  data,
  dataControls,
  headerData,
  loading,
  initSortColumn,
  paginationControls,
  maxSelections,
  FilterComponent,
}: Props) => {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortColumn, setSortColumn] = useState(initSortColumn ? initSortColumn : headerData[0].key);
  const [ascending, setAscending] = useState<boolean>(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [n, setN] = useState<number>(1);
  const chipContainerRef = useRef<any>();

  useEffect(() => {
    if (paginationControls?.pageSize) {
      setRowsPerPage(paginationControls?.pageSize);
    }
  }, []);

  const classes = useStyles();

  const findContactInSelections = (id: any) => data.findIndex((e: any) => e.id === id);

  const toggleContact = (contact: any) => {
    let newSelections = { ...dataControls.selections };
    if (dataControls.selections[contact.id]) {
      delete newSelections[contact.id];
    } else if (!maxSelections || Object.values(newSelections).length < maxSelections) {
      newSelections[contact.id] = contact;
    } else if (maxSelections === 1) {
      newSelections = { [contact.id]: contact };
    }
    dataControls.setSelections(newSelections);
  };

  const filter = (client: any) => {
    if (dataControls.searchString) {
      return (
        !!client.firstName?.toUpperCase().includes(dataControls.searchString?.toUpperCase()) ||
        !!client.lastName?.toUpperCase().includes(dataControls.searchString?.toUpperCase()) ||
        !!client.investorEmail?.toUpperCase().includes(dataControls.searchString?.toUpperCase())
      );
    }
    return true;
  };

  const isOverflown = ({ clientWidth, clientHeight, scrollWidth, scrollHeight }: any) => {
    return scrollHeight > clientHeight || scrollWidth > clientWidth;
  };

  const updateRows = (e: any, newPage: any) => {
    setCurrentPage(newPage);
  };

  const filteredData = data; // data.filter(filter)
  const allSelected = data.filter((item: any) => !Object.keys(dataControls.selections).includes(item.id)).length === 0;
  // console.log('selections: ', dataControls.selections);
  // const allSelected = false;

  const toggleAll = async () => {
    const newSelections = cloneDeep(dataControls.selections);
    if (allSelected) {
      for (let item of data) {
        console.log('data in toggle all: ', data, item);
        delete newSelections[item.id];
      }
      // dataControls.setSelections({});
    } else {
      // else if (paginationControls?.selectAll) {
      let dataSlice = data;
      if (paginationControls?.selectAll) {
        const results = await paginationControls?.selectAll();
        dataSlice = results.list;
      }
      let allFundIds: any = {};
      if (maxSelections) {
        dataSlice = dataSlice.slice(0, maxSelections);
      }
      for (let fund of dataSlice) {
        newSelections[fund.id] = fund;
      }
    }
    dataControls.setSelections(newSelections);
  };
  const totalItems = paginationControls?.totalItems;

  const apiPagination = (paginationDetails: any) => {
    const { tablePage, setTablePage, pageSize, setPageSize, totalItems } = paginationDetails;
    const pageRangeMin = pageSize * tablePage + 1;
    const pageRangeMax = (tablePage + 1) * pageSize < totalItems ? (tablePage + 1) * pageSize : totalItems;

    return (
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        onRowsPerPageChange={(e) => {
          const newRowsPerPage = toNumber(e.target.value);
          setPageSize(e.target.value);
          setRowsPerPage(newRowsPerPage);
        }}
        component="div"
        count={totalItems}
        rowsPerPage={pageSize}
        labelDisplayedRows={() => {
          if (rowsPerPage) {
            const tableLabel = `${pageRangeMin}-${pageRangeMax} of ${totalItems}`;
            return tableLabel;
          } else {
            return 'None';
          }
        }}
        sx={{ ...typography.mainFont, color: colors.grey_02 }}
        page={tablePage}
        onPageChange={(e, page) => {
          return setTablePage(page);
        }}
      />
    );
  };

  const sortingManager = (headerItem: any, filteredData: any, ascending: boolean) => {
    if (paginationControls) {
      const { setSortKey } = paginationControls;
      let sortingValue = sortColumn;
      if (ascending) {
        sortingValue = '-' + sortingValue;
      }
      setSortKey(sortingValue);
      return filteredData;
    } else {
      return sortingHelper(sortColumn, filteredData, ascending);
    }
  };

  const scrollDiv = (itemRef: any, direction: number) => (event: any) => {
    console.log('scrolling div!', scrollDiv);
    const offset = direction === 0 ? -280 : 300;
    const destination = chipContainerRef.current.scrollLeft + offset;
    const result = itemRef.current.scrollTo({ behavior: 'smooth', left: destination });
  };

  const selectedChips = () => {
    const showMore = chipContainerRef && isOverflown(chipContainerRef);
    return (
      <>
        <div ref={chipContainerRef} id={'chipContainer'} className={classes.chipContainer}>
          {Object.keys(dataControls.selections)
            .slice(0, n * 10)
            .map((selectionId, index) => {
              let fund = data[findContactInSelections(selectionId)];
              return (
                <Chip
                  key={index}
                  className={classes.chipStyle}
                  label={
                    dataControls.selections[selectionId] && dataControls.selections[selectionId].ticker
                      ? dataControls.selections[selectionId].ticker
                      : dataControls.selections[selectionId]
                      ? formatFullName(dataControls.selections[selectionId])
                      : // : dataControls.selections[selectionId] && dataControls.selections[selectionId]?.firstName
                        // ? `${dataControls.selections[selectionId].firstName} ${dataControls.selections[selectionId].lastName}`
                        // : dataControls.selections[selectionId][0] && dataControls.selections[selectionId][0].firstName
                        // ? `${dataControls.selections[selectionId][0].firstName} ${dataControls.selections[selectionId][0].lastName}`
                        // : dataControls.selections[selectionId][0] && dataControls.selections[selectionId][0].fullName
                        // ? dataControls.selections[selectionId][0].fullName
                        ''
                  }
                  sx={{ margin: '5px', color: colors.grey_02, padding: '6px 5px' }}
                  variant="outlined"
                  onDelete={(e) => toggleContact(fund ? fund : dataControls.selections[selectionId])}
                  deleteIcon={<HighlightOffIcon />}
                />
              );
            })}
          {Object.keys(dataControls?.selections)?.length > n * 50 && (
            <Chip
              key={12839124}
              className={classes.chipStyle}
              label={'Show More'}
              sx={{ margin: '5px', color: colors.grey_02, padding: '6px 5px' }}
              onClick={() => setN(n + 1)}
              variant="outlined"
            />
          )}
        </div>
        {showMore && (
          <img
            alt="rightArrow"
            onClick={() => scrollDiv(chipContainerRef, 0)}
            src={rightArrow}
            className={classes.rightArrowStyle}
          />
        )}
      </>
    );
  };

  return (
    <div className={classes.tableContainer}>
      <div className={classes.topDetailsContainer}>
        <Row style={{ height: 70, width: '100%', alignItems: 'center' }}>
          <SearchBar
            searchText={dataControls.searchString}
            setSearchText={dataControls.searchFunc}
            placeholder={dataControls.searchPlaceholder}
          />
          {FilterComponent && FilterComponent()}
          {selectedChips()}
        </Row>
      </div>
      <div className={classes.innerContainer}>
        <TableContainer
          style={{
            marginTop: '-12px',
            marginLeft: -10,
          }}
        >
          {loading ? (
            <div style={{ display: 'flex', flex: 1 }}>
              <Loader />
            </div>
          ) : (
            <Table
              stickyHeader
              size="small"
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: 'none',
                  //height: '20px',
                  padding: '2px auto 2px auto',
                },
              }}
            >
              <TableHead onMouseOver={() => setHoveredIndex(null)}>
                <TableRow
                  className={classes.tableHeader}
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      borderBottom: `1px solid ${colors.grey_01}`,
                      //height: '20px',
                      padding: '2px auto 2px auto',
                    },
                  }}
                >
                  {headerData.map((e, index) => {
                    if (e.type === 'checkbox') {
                      return (
                        <TableCell key={index}>
                          <div style={{ marginLeft: '12px' }}>
                            <Checkbox onClick={toggleAll} checked={allSelected} />
                          </div>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={index}
                          style={{}}
                          onClick={() => {
                            if (e.key !== sortColumn && e.sortKey !== sortColumn) {
                              setSortColumn(e.sortKey ? e.sortKey : e.key);
                              setAscending(true);
                            } else {
                              setAscending(!ascending);
                            }
                          }}
                        >
                          <div style={{ display: 'flex' }}>
                            <h1
                              // className={classes.headerLabel}
                              className={
                                sortColumn === e.key || sortColumn === e.sortKey
                                  ? classes.selectedHeaderLabel
                                  : classes.headerLabel
                              }
                            >
                              {e.header}
                            </h1>
                            {e.header !== 'Enriched Data' && e.header !== ' ' && e.type !== 'nonsortable' && (
                              <img className={classes.iconStyle} src={sortable} alt="info" />
                            )}
                          </div>
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {totalItems === 0 && (
                  <TableRow>
                    <F3 style={{ margin: '16px 32px', color: colors.grey_02 }}>
                      No results found. Please adjust your filters or upload a new set of advisors.
                    </F3>
                  </TableRow>
                )}
                {sortingManager(sortColumn, filteredData, ascending)
                  .slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage)
                  .map((contact: any, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        className={classes.tableRow}
                        onClick={(e) => toggleContact(contact)}
                        id={`selection-row-${index}`}
                        onMouseOver={() => setHoveredIndex(index)}
                      >
                        {headerData.map((headerInfo, headerIndex) => {
                          if (headerInfo.type === 'checkbox') {
                            return (
                              <TableCell style={{ width: '20px' }} key={headerIndex}>
                                <div style={{ padding: '0px 0px 2px 0px', marginLeft: '12px' }}>
                                  <Checkbox
                                    onClick={(e: any) => toggleContact(contact)}
                                    checked={!!dataControls.selections[contact.id]}
                                    //style={{ color: colors.black }}
                                    id={`select-fund-${index}`}
                                  />
                                </div>
                              </TableCell>
                            );
                          } else if (headerInfo.formatData && headerInfo.key === 'addData') {
                            return (
                              <TableCell key={headerIndex}>
                                {hoveredIndex === index && headerInfo.formatData(contact)}
                              </TableCell>
                            );
                          } else if (headerInfo.formatData) {
                            return <TableCell key={headerIndex}>{headerInfo.formatData(contact)}</TableCell>;
                          } else if (headerInfo.type === 'name') {
                            return (
                              <TableCell key={headerIndex}>{contact.firstName + ' ' + contact.lastName}</TableCell>
                            );
                          } else if (headerInfo.type === 'prospectName') {
                            return (
                              <TableCell key={headerIndex}>
                                <p style={{ width: 120, margin: 0, color: '#404B50' }}>
                                  {contact.firstName + ' ' + contact.lastName}
                                </p>
                              </TableCell>
                            );
                          } else if (headerInfo.type === 'number') {
                            return (
                              <TableCell align="right" key={headerIndex}>
                                <p style={{ color: '#758790', margin: 0, width: 30 }}>
                                  {formatNumber(contact[headerInfo.key])}
                                </p>
                              </TableCell>
                            );
                          } else if (headerInfo.type === 'enrich') {
                            return (
                              <TableCell align="right" key={headerIndex}>
                                <div style={{ display: 'flex' }}>
                                  <img
                                    src={contact && contact[headerInfo.key].length ? enriched : notEnriched}
                                    alt={contact && contact[headerInfo.key].length ? 'enriched' : 'notEnriched'}
                                  />
                                  <p className={classes.enrichText}>
                                    {contact[headerInfo.key].length ? 'Enriched' : 'Not Provided'}
                                  </p>
                                </div>
                              </TableCell>
                            );
                          } else if (headerInfo.type === 'enrichData') {
                            let key = contact[headerInfo.key];
                            let length = key.length;
                            return (
                              <TableCell align="right" key={headerIndex}>
                                <div style={{ display: 'flex' }}>
                                  <p className={classes.enrichText}>
                                    {key && key[length - 1] && key[length - 1].dateRun
                                      ? formatTimestamp(key[length - 1].dateRun)
                                      : '-'}
                                  </p>
                                </div>
                              </TableCell>
                            );
                          } else if (headerInfo.type === 'text') {
                            return (
                              <TableCell align="left" key={headerIndex}>
                                <p style={{ color: '#758790', margin: 0, width: 30 }}>{contact[headerInfo.key]}</p>
                              </TableCell>
                            );
                          } else if (headerInfo.type === 'dollar') {
                            return (
                              <TableCell align="right" key={headerIndex}>
                                <p
                                  style={{
                                    color: '#758790',
                                    margin: 0,
                                    width: headerInfo.width ? headerInfo.width : 80,
                                  }}
                                >
                                  {formatDollar(contact[headerInfo.key])}
                                </p>
                              </TableCell>
                            );
                          } else if (headerInfo.key === 'ticker' || headerInfo.key === 'fullName') {
                            return (
                              <TableCell
                                style={{ width: headerInfo.key === 'ticker' ? '120px' : '400px' }}
                                key={headerIndex}
                              >
                                <p
                                  style={{
                                    ...typography.mainFont,
                                    fontWeight: 500,
                                    fontSize: 14,
                                    color: '#343434',
                                    margin: 0,
                                  }}
                                >
                                  {contact[headerInfo.key]}
                                </p>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={headerIndex}>
                                <p style={{ color: '#758790', margin: 0 }}>{contact[headerInfo.key]}</p>
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        {paginationControls ? (
          apiPagination(paginationControls)
        ) : (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            onRowsPerPageChange={(e: any) => setRowsPerPage(e.target.value)}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            sx={{ ...typography.mainFont, color: colors.grey_02 }}
            page={currentPage}
            onPageChange={(e, page) => updateRows(e, page)}
          />
        )}
      </div>
    </div>
  );
};
