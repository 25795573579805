import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors } from '../styles';

import { H2, H1, F3, Column } from '../components';
import { PrimaryButtonShort } from '.';
import { styled } from '@mui/system';
import InfoModal from './InfoModal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0px 0px',
  },
  header: {
    display: 'flex',
    padding: '0px 0px 16px',
    color: colors.grey_03,
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  subheader: {
    fontSize: '12px',
    color: colors.grey_02,
    margin: 'auto 16px auto 0px',
    cursor: 'default',
  },
  headerButton: {
    height: '30px',
    width: 'fit-content',
    padding: '0 14px',
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: '30%',
    },
    '&:hover': {
      opacity: '80%',
    },
  },
}));

const OuterBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#EFF6F5',
  borderRadius: '4px',
  padding: '10px 10px',
});

const FileUploadBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '640px',
  height: '150px',
  backgroundColor: '#EFF6F5',
  border: `1px dashed #758790`,
  borderRadius: '4px',
  padding: '4px',
  cursor: 'pointer',
});

interface Props {
  content: any;
  setContent: any;
  generate: any;
  title: any;
  saveChanges: any;
  HeaderButtons: any;
  HeaderComponent: any;
  saveDraft: any;
  lastSavedChange?: any;
  emailInfo: any;
  htmlOverride: any;
  focusedEmail: any;
  currentEmail: any;
  firstStep?: boolean;
  toggleHtmlUpload: any;
  type: any;
  emailBlocks: any;
}

const CustomEmailEditor = ({
  content,
  setContent,
  generate,
  title,
  saveChanges,
  HeaderButtons,
  HeaderComponent,
  saveDraft,
  lastSavedChange,
  emailInfo,
  focusedEmail,
  htmlOverride,
  toggleHtmlUpload,
  currentEmail,
  firstStep,
  type,
  emailBlocks,
}: Props) => {
  const classes = useStyles();
  const [editorStateJSON, setEditorStateJSON] = useState(content);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [newEmailInfo, setNewEmailInfo] = useState(currentEmail ? currentEmail : { title: '', subject: '' });
  const [loading, setLoading] = useState(false);
  const blocksRef = useRef();
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (content) {
      // console.log('loading content into unlayer editor: ', content);
      setEditorStateJSON(content);
      // unlayer.loadDesign(content);
      setUnsavedChanges(false);
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [content]);

  const sendContentAndSave = () => {
    if (!!htmlOverride) {
      const html = htmlOverride;
      const completeEmail = { ...newEmailInfo, html, json: {} };
      saveChanges(completeEmail);
    }
  };

  const sendContentAndSaveDraft = (destination: any) => {
    const completeEmail = { ...newEmailInfo, html: htmlOverride, json: {} };
    saveDraft(completeEmail);
  };

  return (
    <>
      <div
        className={classes.header}
        style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <H2 style={{ margin: '0px ' }}>
          {type === 'Document' ? 'Create an article to share in your campaigns' : 'Create an Email '}
          <span
            style={{
              display: 'inline',
              color: lastSavedChange ? colors.grey_02 : colors.red,
              fontWeight: 400,
              fontFamily: 'Inter',
              fontSize: '12px',
            }}
          >
            {lastSavedChange ? `- Saved at ${lastSavedChange.toLowerCase()}` : `- Unsaved Changes`}
          </span>
        </H2>
        <div style={{ display: 'flex' }}>
          <PrimaryButtonShort
            className={classes.headerButton}
            style={{ marginRight: '8px', backgroundColor: colors.grey_02, border: `1px solid ${colors.grey_02}` }}
            children={'Upload HTML'}
            placeholder={'Upload HTML'}
            onClick={toggleHtmlUpload}
          />
          <div style={{ width: '1px', background: colors.grey_02, margin: '0px 12px 0px 4px' }} />
          <PrimaryButtonShort
            className={classes.headerButton}
            style={{ marginRight: '8px', backgroundColor: colors.grey_02, border: `1px solid ${colors.grey_02}` }}
            children={'Save Progress'}
            onClick={sendContentAndSaveDraft}
            disabled={!HeaderButtons?.[0]?.noDisable && !unsavedChanges}
          />
          <PrimaryButtonShort
            className={classes.headerButton}
            children={firstStep ? 'Create Email' : 'Review & Schedule'}
            onClick={() => sendContentAndSave()}
            disabled={!htmlOverride}
          />
        </div>
      </div>
      <div>{HeaderComponent(newEmailInfo, setNewEmailInfo)}</div>
      <Column style={{ padding: '16px 0px' }}>
        <H1 style={{ color: colors.grey_03 }}>Upload Preview</H1>
        <F3 style={{ color: colors.grey_02 }}>
          Please upload the HTML for your email below. Once your have uploaded it a preview should appear.
        </F3>
      </Column>
      <div
        style={{
          width: '100%',
          height: htmlOverride ? '100vh' : '200px',
          padding: '16px 16px',
          background: colors.grey_00,
          border: `1px solid ${colors.grey_01}`,
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          overflowY: 'scroll',
        }}
      >
        {!!htmlOverride ? (
          <div
            style={{ width: '100%', height: '100%', background: colors.grey_02 }}
            dangerouslySetInnerHTML={{ __html: htmlOverride }}
          />
        ) : (
          <div style={{ width: '660px', height: '200px' }}>
            <OuterBox>
              <FileUploadBox
                style={{
                  border: `1px dashed #758790`,
                }}
              >
                <F3 sx={{ color: colors.grey_03, margin: '10px 0px 6px' }}>
                  No Uploads Found Please upload you file here.
                </F3>
                <PrimaryButtonShort style={{ margin: '8px 0px' }} onClick={toggleHtmlUpload}>
                  Upload HTML
                </PrimaryButtonShort>
                <F3 sx={{ color: colors.grey_02 }}>Max file size 1MB</F3>
              </FileUploadBox>
            </OuterBox>
          </div>
        )}
        {/* {<div style={{ width: '100%', height: '100%' }} id="editor-container" />} */}
      </div>
      <InfoModal
        modalStatus={!!error}
        closeModal={() => setError(null)}
        modalCopy={{
          header: 'Error',
          subheader: `An unknown error while creating your target with this advisor set(${error})`,
          body: undefined,
          loader: false,
        }}
      />
    </>
  );
};

export default CustomEmailEditor;
