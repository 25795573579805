import { createStore, persist, Action, action, createTypedHooks } from 'easy-peasy';
import { user, UserModel } from './models/user';
import { integrations, IntegrationsModel } from './models/integrations';
import { outcomes, OutcomesModel } from './models/Outcomes';
import { nurtureProspects, NurtureProspectsModel } from './models/NurtureProspects';
import { generateSignals, GenerateSignalsModel } from './models/GenerateSignals';
import { discoverIntent, DiscoverIntentModel } from './models/DiscoverIntent';
import { buildAwareness, BuildAwarenessModel } from './models/BuildAwareness';
import { driveSales, DriveSalesModel } from './models/DriveSales';

export type AppType = 'TIFIN' | 'LPL' | 'FT';
export type ScreenSize = 'sm' | 'md' | 'lg';

const appType: AppType = process.env.REACT_APP_THEME === 'LPL' ? 'LPL' : 'TIFIN';

export interface AppModel {
  appType: AppType;
  setAppType: Action<AppModel, AppType>;
  screenSize: ScreenSize;
  setScreenSize: Action<AppModel, ScreenSize>;

  user: UserModel;
  integrations: IntegrationsModel;
  buildAwareness: BuildAwarenessModel;
  discoverIntent: DiscoverIntentModel;
  generateSignals: GenerateSignalsModel;
  nurtureProspects: NurtureProspectsModel;
  driveSales: DriveSalesModel;
  outcomes: OutcomesModel;
}

const storeModel: AppModel = {
  appType: appType,
  setAppType: action((state, payload) => {
    state.appType = payload;
  }),
  screenSize: 'sm',
  setScreenSize: action((state, payload) => {
    state.screenSize = payload;
  }),
  user,
  integrations,
  buildAwareness,
  generateSignals,
  discoverIntent,
  nurtureProspects,
  driveSales,
  outcomes,
};

const store = createStore(persist(storeModel));

const typedHooks = createTypedHooks<AppModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export default store;
