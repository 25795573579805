import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../../styles';
import downArrowGray from '../../assets/icons/arrow-down-grey.svg';
import LeadsTable from '../Table/LeadsTable';
import UserProfile from '../UserProfile/UserProfile';
import { formatPercent } from '../../helpers/dataFormatter';
import TopRankedCards from '../TopRankedCards';
import { CardHeader } from '..';
import { formatFullName } from '../../helpers/dataFormatter';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    padding: '30px',
    overflowY: 'scroll',
  },
  modalTitle: {
    ...typography.mainFont,
    fontSize: '16px',
    color: colors.black_01,
    fontWeight: '500',
    cursor: 'pointer',
  },
  titleIcon: {
    height: '20px',
    width: '20px',
    margin: '0px 7px -5px 0px',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  emailContainer: {
    height: '337px',
    width: '306px',
    background: colors.grey_01,
  },
  //   infoHeader: { ...typography.header_03 },
  infoBody: {
    ...typography.mainFont,
    fontSize: '14px',
    color: colors.grey_02,
    width: '280px',
    textAlign: 'center',
    fontWeight: '400',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //width: '95%',
    padding: '12px 23px',
    borderTop: `1px solid ${colors.grey_00}`,
  },
  handIcon: {
    height: '20px',
  },
  topRankedCards: {
    //width: '95%',
    margin: '0px auto 0px auto',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    position: 'relative',
    'z-index': 6,
  },
  resultsHeader: {
    color: colors.grey_03,
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    margin: 0,
    padding: '0px 20px',
    background: colors.white_00,
    borderBottom: `1px solid ${colors.grey_00}`,
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const resultsHeader = [
  {
    header: 'Change',
    key: 'scoreChange',
    formatData: (lead: any) => {
      return (
        <span
          style={{
            fontSize: '16px',
            fontWeight: '600',
            color: lead.scoreChange > 0 ? colors.green : colors.grey_03,
            margin: '0px 3px',
          }}
        >
          {lead.scoreChange > 0 && '+'}
          {lead.scoreChange && lead.scoreChange > 0 ? formatPercent(lead?.scoreChange) : '0%'}
        </span>
      );
    },
  },
  {
    header: 'Score',
    key: 'leadScore',
    formatData: (lead: any) => (
      <span>
        {lead.leadScore ? lead.leadScore : lead.score}%
        <img
          src={downArrowGray}
          alt="expand"
          style={{
            transform: 'rotate(-90deg)',
            transition: '0.3s',
            height: '5px',
            margin: '0px 12px 1px',
          }}
        />
        {lead.latestScore && (lead.latestScore > lead.leadScore || lead.latestScore > lead.score)
          ? lead.latestScore
          : lead.leadScore
          ? lead.leadScore
          : lead.score}
        %
      </span>
    ),
  },
  {
    header: 'Name',
    formatData: (lead: any) => {
      return formatFullName(lead?.advisor);
    },
  },
  // {
  //   header: 'Requested Meeting',
  //   key: 'wantsMeeting',
  //   formatData: (lead: any) =>
  //     lead.wantsMeeting ? (
  //       <img style={{ height: '20px' }} src={handActive} alt="active" />
  //     ) : (
  //       <img style={{ height: '20px' }} src={handInactive} alt="inactive" />
  //     ),
  // },
];

interface Props {
  closeModal: any;
  leads: any;
  paginationControls: any;
  runId: number;
  cardProps: any;
  showComingSoon: any;
  selectedLead: any;
  setSelectedLead: any;
}

const ResultsView = ({
  closeModal,
  leads,
  cardProps,
  showComingSoon,
  selectedLead,
  setSelectedLead,
  runId,
  paginationControls,
}: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);
  const [scoredLeads, setScoredLeads] = useState([]);
  const [profileCardProps, setProfileCardProps] = useState<any>(null);

  useEffect(() => {
    const newlyScoredLeads = leads?.map((person: any) => {
      let currentScore = person.leadScore ? person.leadScore : person.score ? person.score : 0;
      return {
        ...person,
        scoreChange: person.latestScore && currentScore ? person.latestScore - currentScore : 0,
      };
    });
    let sortedLeads = newlyScoredLeads?.sort((a: any, b: any) => {
      if (a.scoreChange === b.scoreChange) {
        return a.latestScore < b.latestScore ? 1 : -1;
      } else {
        return a.scoreChange < b.scoreChange ? 1 : -1;
      }
    });
    setScoredLeads(sortedLeads);
  }, [leads]);

  const selectLead = (lead: any) => {
    setSelectedLead(lead);
    // console.log('select lead: ', lead, cardProps);
    // const packageKey = lead?.
    // const newProfileCardProps = cardProps[packageKey];
    const newProfileCardProps = null;
    setProfileCardProps(cardProps);
  };

  // console.log('leads in resultsView : ', leads);

  return (
    <div className={classes.rootContainer}>
      {selectedLead ? (
        <>
          <p
            className={classes.modalTitle}
            onClick={() => setSelectedLead(null)}
            style={{ margin: '-14px 0px 0px 0px' }}
          >
            <img
              src={downArrowGray}
              alt="expand"
              style={{
                transform: 'rotate(90deg)',
                transition: '0.3s',
                height: '8px',
                margin: '4px 4px 1px',
              }}
            />
            Back
          </p>
          <div
            style={{
              boxShadow: colors.main_box_shadow,
              margin: '16px 0px 0px 0px',
              borderRadius: '5px',
            }}
          >
            <UserProfile client={selectedLead} {...profileCardProps} showNurtureScore runId={runId} />
            {/* <div className={classes.modalFooter}>
              <PrimaryButton onClick={showComingSoon}>Email</PrimaryButton>
            </div> */}
          </div>
        </>
      ) : (
        <>
          <div>About your Nurture Results</div>
          <div className={classes.infoBody} style={{ width: '550px', textAlign: 'left', margin: '8px 0px 32px 0px' }}>
            Here you can see how your leads AMP Scores have been impacted thus far in your Nurture campaign. Each of
            your leads below have been ranked by the improvement in their AMP Score, based upon the interest they’ve
            shown in your campaign.
          </div>
          <div style={{ boxShadow: colors.main_box_shadow, margin: '16px 0px', borderRadius: '5px' }}>
            <CardHeader sx={{ background: colors.white_00 }}>Top Leads by improvement in AMP Score</CardHeader>
            <div className={classes.topRankedCards}>
              <TopRankedCards
                expanderFunc={(row: any) => selectLead({ ...row?.advisor, ...row })}
                cardData={scoredLeads}
                cardProps={{ ...cardProps, contentType: 'nurtureAdvisors' }}
                selectedClient={selectedLead}
              />
            </div>
          </div>
          {/* </div> */}
          {scoredLeads?.slice(3).length > 0 && (
            <div style={{ display: 'flex', flex: 1 }}>
              <LeadsTable
                tableHeaders={resultsHeader}
                data={scoredLeads.slice(3)}
                //@ts-ignore
                dataControls={{
                  selections: [],
                  setSelections: () => {},
                  searchFunc: () => {},
                  searchString: '',
                }}
                // paginationControls={paginationControls}
                headerButtons={[]}
                loading={loading}
                view={(row: any) => {
                  selectLead({ ...row?.advisor, ...row });
                }}
                containerStyle={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  overflowY: 'scroll',
                }}
                innerContainerStyle={{
                  //display: 'flex',
                  width: '100%',
                  height: '100%',
                  overflowY: 'scroll',
                  margin: '0px 16px 0px 16px',
                }}
                headerStyle={{
                  padding: '26px 10px 20px',
                }}
                headerFunctions={[]}
                chartTitle={'Ranking of leads by change in AMPScore'}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ResultsView;
