import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { formatNumber } from '../../helpers/dataFormatter';
import { StatType } from '../../types';
import Loader from '../Loader';
import { CardBody, CardHeader, H2, PrimaryButtonShort, Row, Title, Column, F4, F3, PageTitle } from '..';
import ProgressBar from '../ProgressBar';
import ComingSoonModal from '../ComingSoonModal';

const CardRoot = styled('div')({
  background: '#fff',
  borderRadius: '5px',
  boxShadow: colors.main_box_shadow,
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  margin: '0px 0px',
});

const CustomTitle = styled(Title)({
  fontSize: '48px',
  color: colors.grey_03,
  margin: '16px 0px 24px',
});

type Props = {
  index: number;
  label: string;
  data: any;
};

export const PackageUsageCard = ({ index, label, data }: Props) => {
  const [comingSoonModalOpen, setComingSoonModalOpen] = useState(false);

  const toggleComingSoonModal = () => {
    setComingSoonModalOpen(!comingSoonModalOpen);
  };

  return (
    <CardRoot id={`stat-card-${index}`}>
      <CardHeader style={{ justifyContent: 'space-between' }}>
        <H2 style={{ margin: '0px', padding: '0px' }}>{label}</H2>
        <PrimaryButtonShort onClick={toggleComingSoonModal}>Increase Prospect Limit</PrimaryButtonShort>
      </CardHeader>
      <CardBody>
        <Row style={{ justifyContent: 'space-between' }}>
          <Column>
            <F3 style={{ margin: '0px 0px' }}>{data?.usageLabel}</F3>
            <CustomTitle>{formatNumber(data?.usage)}</CustomTitle>
          </Column>
          <Column>
            <F3 style={{ textAlign: 'right', margin: '0px 0px' }}>{data?.totalLabel}</F3>
            <CustomTitle style={{ textAlign: 'right' }}>{formatNumber(data?.total)}</CustomTitle>
          </Column>
        </Row>
        <ProgressBar
          barColors={{ primaryColor: colors.green, backgroundColor: colors.grey_01 }}
          height={4}
          percent={data?.percent ? data?.percent : (data?.usage / data?.total) * 100}
          label={''}
        />
      </CardBody>
      <ComingSoonModal modalStatus={comingSoonModalOpen} closeModal={toggleComingSoonModal} />
    </CardRoot>
  );
};
