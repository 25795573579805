import React from 'react';
import { colors } from '../../styles';
import { Column, H2, F4, H4, F3, Row, PrimaryButtonShort, CardContainer } from '..';
import ProgressBar from '../ProgressBar';
import logoMapping from '../../assets/visuals/logoMapping';
import { formatPackageName, formatTimestamp } from '../../helpers/dataFormatter';
import { EXPORT_RUN_HISTORY } from '../../api/integrations';
import Loader from '../Loader';
import { useMutation, useQuery } from '@apollo/client';

type Props = {
  runId: number;
  getJobStatus: any;
  GET_EXPORT_STATUS: any;
  packageName: string;
  refresh: any;
  exportFilters?: any;
  integrationDetails?: any;
  integrationJob?: any;
  setInfoPageCopy: any;
  setIntegrationJob?: any;
  setExportModalPage: any;
  exportCount?: number;
};

export const CrmStatusCard = ({
  runId,
  getJobStatus,
  packageName,
  GET_EXPORT_STATUS,
  exportFilters,
  integrationDetails,
  integrationJob,
  setInfoPageCopy,
  setIntegrationJob,
  setExportModalPage,
  exportCount,
}: Props) => {
  const [exportRunHistory, { data, loading: exportLoading, error }] = useMutation(EXPORT_RUN_HISTORY, {
    onCompleted: (data) => {
      if (data?.exportRunHistory?.errors && data?.exportRunHistory?.errors?.length > 0) {
        setInfoPageCopy({
          header: 'Export Failed',
          subheader: `We could not create your export due to the following issues: ${data?.exportRunHistory?.errors.join(
            ',',
          )}`,
          oader: false,
        });
        setIntegrationJob({ status: 'FAILED', progress: 0 });
        setExportModalPage('InfoView');
      } else {
        setIntegrationJob({ status: 'STARTED', progress: 0 });
        startPollingStatus(1000);
      }
    },
  });

  const {
    data: exportData,
    loading,
    error: exportError,
    startPolling: startPollingStatus,
    stopPolling: stopPollingStatus,
  } = useQuery(GET_EXPORT_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { runId },
    onCompleted: (data) => {
      console.log('data in getExportStatus: ', data);
      const newExportJob = data?.[packageName === 'INFLUENCE' ? 'influence' : 'discoverLeads']?.results?.exportJob;
      setIntegrationJob(newExportJob);
      if (newExportJob?.status === 'FAILED' || newExportJob?.status === 'COMPLETED') {
        stopPollingStatus();
        getJobStatus();
      }
    },
  });

  return (
    <Column style={{ flex: 1, margin: '0px 8px', width: '200px', overflow: 'hidden' }}>
      <H2 style={{ color: colors.grey_03, margin: '0px 0px 6px' }}>Export Results to CRM</H2>
      <Row style={{ justifyContent: 'space-between' }}>
        <H4 style={{ color: colors.grey_02, margin: '14px 0px 8px' }}>CRM Integration</H4>
        <F4
          style={{ color: colors.grey_02, margin: '14px 0px 8px', cursor: 'pointer' }}
          onClick={() => setExportModalPage('SetJourneyIntegration')}
        >
          Edit Connection
        </F4>
      </Row>
      {!!integrationDetails ? (
        <Row style={{ border: `1px solid ${colors.grey_01}`, padding: '16px 0px', borderRadius: '5px' }}>
          <img
            style={{
              height: '110px',
              width: '160px',
              // padding: '0px 16px 0px',
              objectFit: 'contain',
              margin: '0px 18px 0px 0px',
              padding: '0px 22px',
              // boxShadow: colors.main_box_shadow,
              lineHeight: '60px',
            }}
            src={logoMapping?.[integrationDetails?.type]}
            onClick={() => console.log('Logo Clicked')}
            alt={integrationDetails?.type}
          />
          <Column>
            <H2 style={{ color: colors.grey_03, margin: '0px' }}>{integrationDetails?.nickname}</H2>
            <H4 style={{ color: colors.grey_02, margin: '8px 0px 4px' }}>
              Status: {integrationJob?.status ? formatPackageName(integrationJob?.status) : 'None'}
            </H4>
            {integrationDetails?.sfintegrationauthdetailsSet?.length > 0 && (
              <H4 style={{ color: colors.grey_02, margin: '0px 0px 4px', whiteSpace: 'nowrap' }}>
                Instance URL: {integrationDetails?.sfintegrationauthdetailsSet?.[0]?.instanceUrl}
              </H4>
            )}
            <H4 style={{ color: colors.grey_02, margin: '0px 0px 4px' }}>
              Last updated: {integrationJob?.dateUpdated ? formatTimestamp(integrationJob?.dateUpdated) : '-'}
            </H4>
          </Column>
        </Row>
      ) : (
        <Row style={{ justifyContent: 'space-between' }}>
          <CardContainer
            style={{
              margin: '6px 0px',
              padding: '26px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: '200px',
            }}
          >
            <H2 style={{ color: colors.grey_02, margin: '0px 0px 8px' }}>Select a CRM Instance</H2>
            <PrimaryButtonShort
              style={{
                height: '36px',
                width: '160px',
                backgroundColor: colors.white,
                color: colors.black,
                border: `1px solid ${colors.black}`,
              }}
              onClick={() => setExportModalPage('SetJourneyIntegration')}
            >
              {'Connect'}
            </PrimaryButtonShort>
          </CardContainer>
        </Row>
      )}
      {integrationJob?.status === 'IN_PROGRESS' || integrationJob?.status === 'STARTED' ? (
        <Row
          style={{
            borderBottom: `1px solid ${colors.grey_01}`,
            padding: '36px 0px 20px',
          }}
        >
          <Row style={{ flex: 1, margin: '0px 0px 0px 0px' }}>
            <ProgressBar
              percent={!!integrationJob ? integrationJob?.percentComplete : 0}
              label={
                !!integrationJob?.percentComplete
                  ? integrationJob?.percentComplete + '%'
                  : integrationDetails
                  ? '0%'
                  : '-'
              }
              height={36}
              roundCorners
            />
          </Row>
        </Row>
      ) : (
        <Column style={{ display: 'flex', flex: 1, alignItems: 'center', margin: '8px 0px' }}>
          <H2 style={{ color: colors.grey_03 }}>Export set of {exportCount ? exportCount : 20} results</H2>
          <F3 style={{ color: colors.grey_02 }}>
            {integrationJob?.status === 'FAILED' ? 'Job failed' : 'Begin the export job with the button below'}
          </F3>
          <PrimaryButtonShort
            style={{ width: '200px', margin: '8px 0px 0px' }}
            onClick={() =>
              exportRunHistory({
                variables: {
                  integrationId: integrationDetails?.id,
                  runHistoryId: String(runId),
                  advisorFilters: exportFilters,
                },
              })
            }
            disabled={exportLoading}
          >
            {exportLoading ? (
              <Loader color={colors.white} inputMargin={'0px 0px 0px'} hideText size={'10px'} />
            ) : integrationJob?.status === 'FAILED' ? (
              'Retry'
            ) : (
              'Start Export'
            )}
          </PrimaryButtonShort>
        </Column>
      )}
    </Column>
  );
};
