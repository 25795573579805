export const advisorDefaultFeatures = {
  age: true,
  aumSelfReported: true,
  firmTotalAssetsInMillions: true,
  tenurePerFirm: true,
  careerTenure: true,
  firmPercentAssetsEtf: true,
  firmPercentAssetsMf: true,
  advisedInvestorsCnt: true,
};

export const waitlistDefaultFeatures = {
  age: true,
  aumSelfReported: true,
  firmTotalAssetsInMillions: true,
  tenurePerFirm: true,
  careerTenure: true,
  firmPercentAssetsEtf: true,
  firmPercentAssetsMf: true,
  advisedInvestorsCnt: true,
};

export const individualDefaultFeatures = {
  age: true,
  estimated_investable_assets: true,
  financial_planning_tendency_score: true,
  home_value: true,
  income: true,
  percent_of_home_value_paid_off: true,
  projected_retirement_assets: true,
  proxy_credit_score: true,
};

export const prioritizeDefaultFeatures = {
  age: true,
  aum_self_reported: true,
  firm_total_assets_in_millions: true,
  career_tenure: true,
  percent_assets_in_etf_and_mf: true,
};

export const prioritizeProspectsDefaultFeatures = {
  age: true,
  income: true,
  home_value: true,
  estimated_investable_assets: true,
  percent_of_home_value_paid_off: true,
  financial_planning_tendency_score: true,
  proxy_credit_score: true,
  projected_retirement_assets: true,
};

export const crossSellDefaultFeatures = {
  age: true,
  income: true,
  home_value: true,
  estimated_investable_assets: true,
  percent_of_home_value_paid_off: true,
  financial_planning_tendency_score: true,
  proxy_credit_score: true,
  projected_retirement_assets: true,
};
