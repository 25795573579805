import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { PageBody, PrimaryButtonShort, Row, SecondaryButtonShort } from '../../../../components';
import { colors, typography } from '../../../../styles';
import { formatSubName, formatPackageName, formatTimestamp } from '../../../../helpers/dataFormatter';
import ComingSoonModal from '../../../../components/ComingSoonModal';
import { useStoreState } from '../../../../store';
import ArchiveTable from '../../../../components/Table';
import { SelectionTable } from '../../../../components/Table/SelectionTable';
import moment from 'moment';
import { CREATE_SEGMENT, GET_PACKAGE_RUNS } from '../../../../api/nurtureProspects';
import { GET_ADVISOR_SETS } from '../../../../api/dataAPI';
import nurtureIconBlack from '../../../../assets/icons/nurture-icon.svg';
import nurtureIconGrey from '../../../../assets/icons/nurture-icon-grey.svg';
import ExportModal from '../../../../components/ExportModal';
import UploadModal from '../../../../components/UploadModal';
import { ModalPageType } from '../../../../types';

import Loader from '../../../../components/Loader';
import InfoModal from '../../../../components/InfoModal';
import { useMutation, useQuery } from '@apollo/client';
import { GET_EXPORT_JOB_STATUS, UPDATE_JOURNEY } from '../../../../api/driveSales/influenceAPI';

const ToggleButton = styled('button')({
  fontFamily: 'Inter',
  height: '30px',
  borderRadius: '30px',
  border: `0px solid ${colors.black}`,
  color: colors.grey_02,
  fontWeight: '500',
  fontSize: 16,
  backgroundColor: colors.white,
  padding: '0px 0px',
  cursor: 'pointer',
  letterSpacing: '0px',
  marginRight: '20px',
});

const TableHeader = styled('div')({
  height: '50px',
  padding: '0px 20px',
  backgroundColor: colors.white,
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px 5px 0px 0px',
  borderBottom: `1px solid ${colors.grey_01}`,
  zIndex: '500',
});

const TableContainer = styled('div')({
  margin: '0px auto 20px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
  overflow: 'hidden',
});

const MainHeader = styled('div')({
  ...typography.mainFont,
  fontSize: 16,
  lineHeight: 1.5,
  fontWeight: 500,
  width: '60%',
  color: colors.grey_03,
  cursor: 'default',
  margin: '20px 0px 0px',
});

const advisorHeaderData = [
  { type: 'checkbox', key: '', sortKey: '' },
  {
    header: 'Set name',
    key: 'name',
    sortKey: 'name',
  },
  {
    header: 'Date Created',
    key: 'dateCreated',
    sortKey: 'date_created',
    formatData: (row: any) => {
      return row?.startDate ? formatTimestamp(row.startDate) : formatTimestamp(row.dateCreated);
    },
  },
  {
    header: 'Date Updated',
    key: 'dateUpdated',
    sortKey: 'date_updated',
    formatData: (row: any) => {
      return row?.startDate ? formatTimestamp(row.startDate) : formatTimestamp(row.dateCreated);
    },
  },
  {
    header: 'Source',
    key: 'filterType',
    sortKey: 'filter_type',
  },
  {
    header: '# of Prospects Matched',
    key: 'distinctMemberCount',
    sortKey: 'distinct_member_count',
  },
];

const packageRunsHeader = [
  {
    key: 'checkbox',
    type: 'checkbox',
    sortKey: '',
  },
  {
    header: 'Name',
    key: 'customName',
    sortKey: '',
    formatData: (row: any) => {
      return row?.customName ? row?.customName : 'Unnamed Journey';
    },
    bold: true,
  },
  {
    header: 'Package',
    key: 'package',
    sortKey: '',
    formatData: (row: any) => {
      return formatPackageName(row.package);
    },
    bold: true,
  },
  {
    header: 'Date Created',
    key: 'dateTime',
    sortKey: 'date_run',
    formatData: (row: any) => {
      return formatTimestamp(row.dateRun);
    },
    bold: true,
  },
  {
    header: 'Recipients',
    key: 'serve_count',
    formatData: (row: any) => (row.serveCount ? row.serveCount : '-'),
  },
  {
    header: 'Nurtured',
    key: 'nurturecampaignSet',
    formatData: (row: any) =>
      row?.nurturecampaignSet?.length > 0 ? (
        <img style={{ height: '20px' }} src={nurtureIconBlack} alt="nurture" />
      ) : (
        <img style={{ height: '20px' }} src={nurtureIconGrey} alt="nurture" />
      ),
  },
];

interface Props {
  nextAction: any;
  runId: any;
  setRunId: any;
  campaignInfo: any;
  setCampaignInfo: any;
}

const CampaignDetails = ({ nextAction, runId, setRunId, campaignInfo, setCampaignInfo }: Props) => {
  const [resultsPage, setResultsPage] = useState('startCampaign');
  const [searchString, setSearchString] = useState('');

  const [sortKey, setSortKey] = useState('-date_run');

  const [showComingSoon, setShowComingSoon] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selectedArchives, setSelectedArchives] = useState<any>({});
  const [error, setError] = useState<any>(false);

  const [showCustomSegmentModal, setShowCustomSegmentModal] = useState<ModalPageType>(null);
  const [campaignTarget, setCampaignTarget] = useState('packageRuns');
  const [tableOptions, setTableOptions] = useState<any[]>([]);
  const [selectedClients, setSelectedClients] = useState({});
  const [exportModalPage, setExportModalPage] = useState<string | null>(null);
  const [infoPageCopy, setInfoPageCopy] = useState<any | null>(null);

  const [sfIntegrationId, setSfIntegrationId] = useState<string | null>(null);
  const [sfIntegrationDetails, setSfIntegrationDetails] = useState<any | null>(null);

  const unformattedSubscriptions = useStoreState((state) => state.user.subscriptions);
  const subscriptions = unformattedSubscriptions?.map((sub: any) => sub.package);

  const [tableType, setTableType] = useState(subscriptions[0] ? formatSubName(subscriptions[0]) : 'prioritizeAdvisors');

  useEffect(() => {
    console.log('resultsPage ', resultsPage, ' tableType: ', tableType);
    if (resultsPage === 'nurturedResults') {
      setSortKey('run_history__date_run');
    } else {
      setSortKey('-date_run');
    }
    setTablePage(0);
    setSearchString('');
    // fetchPackageRuns('');
  }, [resultsPage, tableType]);

  useEffect(() => {
    if (runId) {
      setSelectedArchives({ [runId]: { name: 'selectedCampaign' } });
    }
  }, [runId]);

  useEffect(() => {
    let newCampaignTargets = [
      {
        text: 'Use Generated Target Audience',
        key: 'packageRuns',
      },
    ];
    if (subscriptions.includes('NEWSLETTER_SEGMENTS')) {
      newCampaignTargets = [
        {
          text: 'Use Generated Target',
          key: 'packageRuns',
        },
        {
          text: 'Use Custom Target',
          key: 'advisorEmails',
        },
      ];
    }
    setTableOptions(newCampaignTargets);
  }, []);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const {
    data: packageRuns,
    loading: loadingPackageRuns,
    error: errorPackageRuns,
  } = useQuery(GET_PACKAGE_RUNS, {
    variables: {
      page: tablePage + 1,
      pageSize,
      query: searchString,
      sortKey,
    },
  });

  const {
    loading: advisorSetLoading,
    data: advisorSetData,
    error: advisorSetError,
    refetch: refetchSets,
  } = useQuery(GET_ADVISOR_SETS, {
    fetchPolicy: 'network-only',
  });

  const [createSegment, { loading: segmentLoading }] = useMutation(CREATE_SEGMENT, {
    onCompleted: (data) => {
      const [advisorSetId, advisorSet] = Object.entries(selectedClients)?.[0];
      setRunId(data?.newsletterSegmentsGenerateRun?.runId);
      setCampaignInfo({
        ...campaignInfo,
        target: {
          package: 'Custom Target',
          //@ts-ignore
          serveCount: advisorSet?.distinctMemberCount,
          dateRun: moment().format(),
        },
      });
      nextAction();
    },
    onError: (error) => {
      console.warn('error creating segment: ', error);
      setError(error?.message);
    },
  });

  const createCustomSegment = async () => {
    const [advisorSetId, advisorSet] = Object.entries(selectedClients)?.[0];
    console.log('create custom segment: ', { advisorSetId, advisorSet });
    try {
      if (!!advisorSetId && !!advisorSet) {
        createSegment({ variables: { advisorSetId } });
      }
    } catch (error: any) {
      setError(error?.message);
      console.warn('error in create segment: ', error);
    }
  };

  const setSegment = () => {
    const newRunId = Object.keys(selectedArchives)?.[0];
    const selectedArchive: any = Object.values(selectedArchives)?.[0];
    // console.log('newRunId: ', newRunId);
    if (newRunId) {
      setRunId(newRunId);
      //@ts-ignore
      setCampaignInfo({
        ...campaignInfo,
        target: { ...selectedArchive, package: formatPackageName(selectedArchive.package) },
      });
      nextAction();
    }
  };

  const closeModal = () => {
    setExportModalPage(null);
  };

  const exportLeads = () => {
    const newRunId = Object.keys(selectedArchives)?.[0];
    // console.log('newRunId: ', newRunId);
    if (newRunId) {
      setRunId(newRunId);
      //@ts-ignore
      setExportModalPage('NurtureIntegrations');
    }
  };

  const selectUsingAdvisorList = async (advisorList: any) => {
    let newSelections: any = {};

    const maxLengthAdvisorList = advisorList;

    for (let entry of maxLengthAdvisorList) {
      if (entry?.id) {
        newSelections[entry?.id] = entry;
      } else {
        newSelections[entry] = entry;
      }
    }

    // console.log('newSelections', newSelections);
    setSelectedClients(newSelections);
    setSortKey('full_name');
  };

  const singleToggleSelection = (event: any, row: any) => {
    let newSelection: any = {};
    if (selectedArchives?.[row.id] === row) {
      newSelection[row.id] = null;
    } else {
      newSelection[row.id] = row;
    }
    setSelectedArchives(newSelection);
  };

  return (
    <div style={{ paddingBottom: 120 }}>
      <PageBody>
        <MainHeader style={{ margin: '16px 0px 24px' }}>Select a target audience for your campaign</MainHeader>
        <TableContainer>
          <TableHeader>
            <div>
              {tableOptions.map((item: any, index) => {
                return (
                  <ToggleButton
                    key={index + 342421}
                    id={`table-header-button-${item.text.replace(' ', '-')}`}
                    onClick={() => setCampaignTarget(item.key)}
                    style={campaignTarget === item.key ? { color: colors.black } : {}}
                    children={item.text}
                    // disabled={loading}
                  />
                );
              })}
            </div>
            <Row>
              <SecondaryButtonShort children={'Export Target to ESP'} onClick={exportLeads} />
              <SecondaryButtonShort
                onClick={() => setShowCustomSegmentModal('Landing')}
                style={{ margin: '0px 12px' }}
                id={'upload-benchmark-button'}
              >
                Upload Selections
              </SecondaryButtonShort>
              {campaignTarget === 'advisorEmails' ? (
                <PrimaryButtonShort disabled={segmentLoading} onClick={createCustomSegment}>
                  {segmentLoading ? <Loader color={colors.white} size={'10px'} hideText /> : 'Select Advisor Set'}
                </PrimaryButtonShort>
              ) : (
                <PrimaryButtonShort children={'Use Selected Target'} onClick={setSegment} />
              )}
            </Row>
          </TableHeader>
          {campaignTarget === 'packageRuns' ? (
            <ArchiveTable
              //@ts-ignore
              data={packageRuns?.packageRuns?.runs?.list ?? []}
              headerData={packageRunsHeader}
              hideShadow={true}
              dataControls={{
                selections: selectedArchives,
                setSelections: setSelectedArchives,
                searchFunc: setSearchString,
                searchString,
                searchPlaceholder: 'Search for a run...',
              }}
              paginationControls={
                //@ts-ignore
                {
                  tablePage,
                  setTablePage,
                  pageSize,
                  setPageSize,
                  totalItems: packageRuns?.packageRuns?.runs?.totalItems ?? 0,
                  sortKey,
                  setSortKey,
                }
              }
              // initSortColumn={sortKey}
              // search={resultsPage !== 'startCampaign' && resultsPage !== 'activeCampaign'}
              loading={loadingPackageRuns}
              //@ts-ignore
              onRowPress={(row: any) => singleToggleSelection({}, row)}
              newItems={[]}
              search
              singleSelect={true}
            />
          ) : (
            <SelectionTable
              data={advisorSetData?.advisorSets ?? []}
              headerData={advisorHeaderData}
              loading={advisorSetLoading}
              dataControls={{
                selections: selectedClients,
                setSelections: setSelectedClients,
                searchFunc: setSearchString,
                searchString,
                hideSelectAll: true,
              }}
              maxSelections={1}
            />
          )}
        </TableContainer>
      </PageBody>
      <UploadModal
        modalPage={showCustomSegmentModal}
        setModalPage={setShowCustomSegmentModal}
        closeModal={() => setShowCustomSegmentModal(null)}
        onResultsReturned={selectUsingAdvisorList}
        integrationId={sfIntegrationId}
        setIntegrationId={setSfIntegrationId}
        integrationDetails={sfIntegrationDetails}
        setIntegrationDetails={setSfIntegrationDetails}
        advisorType={'NewsletterSegment'}
      />
      <ExportModal
        closeModal={closeModal}
        modalPage={exportModalPage}
        setModalPage={setExportModalPage}
        setInfoPageCopy={setInfoPageCopy}
        runId={runId}
        packageName={'INFLUENCE'}
        GET_EXPORT_STATUS={GET_EXPORT_JOB_STATUS}
        UPDATE_JOURNEY_QUERY={UPDATE_JOURNEY}
      />
      <InfoModal
        modalStatus={!!error}
        closeModal={() => setError(null)}
        modalCopy={{
          header: 'Error',
          subheader: `An unknown error while creating your target with this advisor set(${error})`,
          body: undefined,
          loader: false,
        }}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </div>
  );
};

export default CampaignDetails;
