import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../styles';
import closeIcon from '../assets/icons/close.svg';
import downArrowGray from '../assets/icons/arrow-down-grey.svg';
import { Modal, IconButton } from '@mui/material';
import Loader from './Loader';
import { PrimaryButton, PrimaryButtonShort } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '500px',
    //minHeight: '580px',
    backgroundColor: colors.white_01,
    borderRadius: '5px',
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    width: '100%',
    padding: '2px 15px',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  modalTitle: {
    ...typography.mainFont,
    fontSize: '16px',
    color: colors.black_01,
    fontWeight: '500',
    cursor: 'pointer',
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 0px 16px 0px',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    padding: '12px 15px',
    borderTop: `1px solid ${colors.grey_01}`,
  },
  feedbackDescription: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontSize: '14px',
    width: '480px',
    padding: '14px 20px',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  selectionHeader: {
    ...typography.mainFont,
    color: colors.grey_02,
    fontSize: '14px',
    fontWeight: '500',
    margin: '16px 4px',
  },
  infoButton: {
    fontWeight: '600',
    fontSize: '12px',
    color: colors.black_01,
    margin: '8px 0px',
    cursor: 'pointer',
  },
  selectedButton: {
    width: '150px',
    height: '30px',
    borderRadius: '5px',
    border: `1px solid ${colors.grey_02}`,
    background: colors.grey_02,
    color: colors.white_01,
    margin: '0px 4px',
  },
  unselectedButton: {
    width: '150px',
    height: '30px',
    borderRadius: '5px',
    border: `1px solid ${colors.grey_02}`,
    background: colors.white_01,
    color: colors.grey_02,
    margin: '0px 4px',
    '&:hover': {
      background: colors.grey_00,
    },
  },
}));

interface Props {
  modalStatus: boolean;
  closeModal: any;
  // API: any;
}

const FeedbackModal = ({ modalStatus, closeModal }: Props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedLead, setSelectedLead] = useState(null);
  const [retrainSelection, setRetrainSelection] = useState('NEVER');
  const [optimizeSelection, setOptimizeSelection] = useState('OPENS');
  const [showMore, setShowMore] = useState(false);
  const [settingStatus, setSettingStatus] = useState('settings');

  const exit = () => {
    setSettingStatus('settings');
    setLoading(false);
    setError(null);
    closeModal();
  };

  useEffect(() => {
    updateData();
  }, []);

  const updateData = async () => {
    try {
      // const response = await API.getAlgoSettings();
      // const response = {};
      // setRetrainSelection(response?.trainingFrequency);
      // setOptimizeSelection(response?.optimizationObjective);
    } catch (error: any) {
      console.warn('Error getting data for algoSettings: ', error.message);
    }
  };

  const generateSuccessMessage = () => {
    const today = new Date();
    let retrainDate = null;
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    let message = 'Your settings have been saved.';
    if (retrainSelection === 'MONTHLY') {
      retrainDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      const retrainString = monthNames[retrainDate.getMonth()] + ' ' + retrainDate.getDate();
      message = `Your results will be recalculated on a monthly frequency, optimizing for ${optimizeSelection.toLowerCase()}. Your next retraining will occur on ${retrainString}.`;
    }
    if (retrainSelection === 'QUARTERLY') {
      const currentMonth = today.getMonth();
      let retrainMonth = 12;
      if (currentMonth <= 2) {
        retrainMonth = 3;
      } else if (currentMonth <= 5) {
        retrainMonth = 6;
      } else if (currentMonth <= 8) {
        retrainMonth = 9;
      }
      retrainDate = new Date(today.getFullYear(), retrainMonth, 0);
      const retrainString = monthNames[retrainDate.getMonth()] + ' ' + retrainDate.getDate();
      message = `Your results will be recalculated on a quarterly frequency, optimizing for ${optimizeSelection.toLowerCase()}. Your next retraining will occur on ${retrainString}.`;
    }
    return message;
  };

  const saveFeedbackSettings = async () => {
    setLoading(true);
    try {
      // await API.updateAlgoSettings(retrainSelection, optimizeSelection);
      setSettingStatus('complete');
      setLoading(false);
    } catch (error: any) {
      setSettingStatus('error');
      console.warn('Error saving feedback settings:', error.message);
      setLoading(false);
    }
  };

  const delay = (time: any) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };

  const retrainOptions = [
    { label: 'Monthly', value: 'MONTHLY' },
    { label: 'Quarterly', value: 'QUARTERLY' },
    { label: 'Never', value: 'NEVER' },
  ];

  const optimizationOptions = [
    { label: 'Opens', value: 'OPENS' },
    { label: 'Clicks', value: 'CLICKS' },
    { label: 'Meetings', value: 'MEETINGS' },
  ];

  return (
    <Modal
      open={modalStatus}
      onClose={exit}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <div className={classes.modalContainer} style={{}}>
        <div className={classes.modalHeader}>
          <div style={{ display: 'flex' }}>
            <p className={classes.modalTitle} onClick={() => setSelectedLead(null)}>
              Adjust Feedback Loop
            </p>
          </div>
          <IconButton onClick={exit}>
            <img src={closeIcon} alt="close" />
          </IconButton>
        </div>
        {settingStatus === 'complete' ? (
          <>
            <div
              style={{
                height: '280px',
                width: '520px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className={classes.modalTitle}>You're all set!</div>
              <div
                className={classes.feedbackDescription}
                style={{ textAlign: 'center', width: '360px', border: '0px' }}
              >
                {generateSuccessMessage()}
              </div>
            </div>
            <div className={classes.modalFooter}>
              <PrimaryButton onClick={exit}>Ok, got it!</PrimaryButton>
            </div>
          </>
        ) : (
          <>
            <div className={classes.modalBody}>
              <div
                className={classes.feedbackDescription}
                // style={{ transform: showMore ? 'scaleY(2)' : 'scaleY(1)', transition: '0.5s' }}
              >
                Here you can adjust how we retrain the AMP Score algorithm for you. Select the frequency you’d like to
                retrain, using the latest data, and the outcome variable for which you’d like to optimize. Our
                recommended approach is to begin with retraining the algorithm quarterly and optimizing for opens.
                <div className={classes.infoButton} onClick={() => setShowMore(!showMore)}>
                  Learn More{' '}
                  <img
                    src={downArrowGray}
                    alt="downArrow"
                    style={{
                      width: '12px',
                      height: '6px',
                      margin: showMore ? '0px 0px 2px 2px' : '0px 2px',
                      transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: '0.5s',
                    }}
                  />
                </div>
                {showMore && (
                  <div>
                    AMP’s algorithm will gain intelligence over time via the data gathered through your nurture
                    campaigns. We use that data to retrain our algorithm, providing you with increasingly relevant
                    leads. Whenever the algorithm is retrained, archived results and the leads therein will be rescored
                    for you automatically. If you prefer that those results remain unchanged, you can turn off this
                    feature. Our recommendation is to begin with retraining the algorithm quarterly and optimizing for
                    opens. As we further nurture your leads we will increase frequency and adjust those events for which
                    we optimize.
                  </div>
                )}
              </div>
              <div>
                <h1 className={classes.selectionHeader}>Retrain Frequency</h1>
                <div>
                  {retrainOptions.map((text) => (
                    <button
                      onClick={() => {
                        setRetrainSelection(text.value);
                      }}
                      key={text.value + 23813403}
                      className={text.value === retrainSelection ? classes.selectedButton : classes.unselectedButton}
                    >
                      {text.label}
                    </button>
                  ))}
                </div>
                <h1 className={classes.selectionHeader}>Optimize for</h1>
                <div>
                  {optimizationOptions.map((text) => (
                    <button
                      onClick={() => {
                        setOptimizeSelection(text.value);
                      }}
                      key={text.value + 1231431}
                      className={text.value === optimizeSelection ? classes.selectedButton : classes.unselectedButton}
                    >
                      {text.label}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <PrimaryButtonShort
                disabled={loading}
                sx={{ background: colors.grey_03, margin: '0px 6px' }}
                onClick={exit}
              >
                Cancel
              </PrimaryButtonShort>
              <PrimaryButtonShort disabled={loading} onClick={saveFeedbackSettings}>
                {loading ? <Loader /> : 'Submit Selections'}
              </PrimaryButtonShort>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackModal;
