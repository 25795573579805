import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import CharacterChart from './CharacterChart';
import { colors, typography } from '../../styles';
import { selectFields } from './Fields';
import { Slider, styled } from '@mui/material';
import { CardBody, CardContainer, Column, Row, CardHeader, Title, F3, F4 } from '..';

const defaultOptions = {
  standard: [
    { title: 'Advisor', id: 'advisor' },
    { title: 'Advised Clients', id: 'individual' },
  ],
};

const Root = styled('div')({
  padding: '0px 50px 80px 30px',
});

const PageHeaderStyle = styled('p')({
  ...typography.mainFont,
  fontSize: 16,
  fontWeight: 500,
  display: 'inline-block',
  color: colors.grey_03,
  margin: '0px 0 10px',
});

const PageSubHeaderStyle = styled('p')({
  display: 'inline-block',
  padding: '0px 20px 0px 0px',
  fontWeight: 400,
  fontSize: 14,
  color: colors.grey_02,
  margin: 0,
});

const ScreenLeadsStyle = styled('div')({
  width: '60%',
  fontWeight: 400,
  fontSize: 12,
  color: '#758790',
  margin: '24px 0 0px',
});

const ChartContainer = styled('div')({
  margin: '10px auto',
});

const HeaderContainer = styled('div')({});

interface Props {
  nextAction: any;
  headerData: any;
  dataPoints: any;
  featureSelections: any;
  setFeatureSelections: any;
  selectedFilters: any;
  setSelectedFilters: any;
  getLeadPool: any;
  getDataForMetric: any;
  getExplanationForMetric: any;
  getVizData: any;
  vizV2: any;
  headerOptions: any;
  getAdvisorData: any;
  activeTable: any;
  setActiveTable: any;
  screenLeads: any;
  label: any;
  showLinkedData: any;
  toggleConnectModal: any;
  customLeadCount?: number;
  setCustomLeadCount?: (event: any) => void;
  hideCurrentFundHolders?: boolean;
  setHideCurrentFundHolders?: (event: any) => void;
}

export const PackageCustomize = ({
  nextAction,
  headerData,
  dataPoints,
  featureSelections,
  setFeatureSelections,
  selectedFilters,
  setSelectedFilters,
  getLeadPool,
  getDataForMetric,
  getExplanationForMetric,
  getVizData,
  vizV2,
  headerOptions = defaultOptions,
  getAdvisorData,
  activeTable,
  setActiveTable,
  screenLeads,
  label,
  showLinkedData,
  toggleConnectModal,
  customLeadCount,
  setCustomLeadCount,
  hideCurrentFundHolders,
  setHideCurrentFundHolders,
}: Props) => {
  const [chartTrigger, setChartTrigger] = useState(undefined);
  const [selectedToggle, setSelectedToggle] = useState(headerOptions[activeTable][0]);
  const [selectedMetric, setSelectedMetric] = useState(selectFields[headerOptions[activeTable][0].id][0]);
  const [queryData, setQueryData] = useState([]);
  const [advisorCharacteristics, setAdvisorCharacteristics] = useState([]);
  const [explanation, setExplanationForMetric] = useState('');
  const [average, setAverage] = useState(0);
  const [clientCount, setClientCount] = useState(0);

  useEffect(() => {
    if (Object.keys(featureSelections).length < 1) setAllFeaturesTrue();
    if (!vizV2) getAdvisorData(setAdvisorCharacteristics);
    getVizData(setQueryData, setClientCount);
    getDataForMetric(selectFields[selectedToggle.id][0], setAverage, setClientCount);
    getExplanationForMetric(selectFields[selectedToggle.id][0], setExplanationForMetric);
  }, []);

  useEffect(() => {
    if (activeTable === 'leadPool') {
      setSelectedToggle(headerOptions['leadPool'][0]);
    } else {
      setSelectedToggle(headerOptions['standard'][0]);
    }
  }, [activeTable]);

  useEffect(() => {
    if (chartTrigger) {
      let metric = selectFields[selectedToggle.id].find((t: any) => t.averageDataKey === chartTrigger);
      switchMetric(metric);
    }
  }, [chartTrigger]);

  const setAllFeaturesTrue = () => {
    let output: any = {};

    for (let obj in headerOptions[activeTable]) {
      let range = selectFields[headerOptions[activeTable][obj].id];

      for (let obj in range) {
        output[range[obj].dataKey] = true;
      }
    }

    setFeatureSelections(output);
  };

  const switchMetric = (metric: any) => {
    getDataForMetric(metric, setAverage, setClientCount);
    getExplanationForMetric(metric, setExplanationForMetric);
    setSelectedMetric(metric);
  };

  const submitAction = async () => {
    nextAction();
  };

  const parseData = (input: any) => {
    let key = selectedMetric.dataKey;
    let output = [];

    for (let obj in input) {
      let transformedPoint = {
        [key]: input[obj][key] ? input[obj][key] : input[obj]['label'],
        [dataPoints[0].label]: input[obj][dataPoints[0].id],
        percent: input[obj]['percent'],
      };

      if (dataPoints.length > 1) {
        transformedPoint[dataPoints[1].label] = input[obj][dataPoints[1].id];
      }

      output.push(transformedPoint);
    }

    return output;
  };

  const parseDataAdvisor = (input: any) => {
    let key = selectedMetric.dataKey;
    let output = [];
    for (let obj in input) {
      let transformedPoint = {
        [key]: input[obj][selectedMetric.dataKey],
        [dataPoints[0].label]: input[obj][dataPoints[0].id],
        percent: input[obj]['percent'],
      };

      if (dataPoints.length > 1) {
        transformedPoint[dataPoints[1].label] = input[obj][dataPoints[1].id];
      }

      output.push(transformedPoint);
    }
    return output;
  };

  return (
    <Root>
      <HeaderContainer style={{ display: 'flex' }}>
        <Row style={{ flex: 1 }}>
          <Column>
            <Row>
              <PageHeaderStyle style={{ margin: '30px 0px 10px 0px' }}>
                {headerData?.[activeTable]?.pageHeading}
              </PageHeaderStyle>
            </Row>
            <Row style={{ flex: 1 }}>
              <Column style={{ width: '800px' }}>
                {headerData?.[activeTable]?.pageSubHeading ? (
                  <PageSubHeaderStyle>{headerData?.[activeTable]?.pageSubHeading()}</PageSubHeaderStyle>
                ) : null}
                {headerData?.[activeTable]?.pageSubText ? (
                  <PageSubHeaderStyle>{headerData?.[activeTable]?.pageSubText}</PageSubHeaderStyle>
                ) : null}
                {screenLeads ? <ScreenLeadsStyle>{screenLeads?.[activeTable]}</ScreenLeadsStyle> : null}
              </Column>
              <Column style={{ flex: 1 }}></Column>
            </Row>
          </Column>
          {customLeadCount && setCustomLeadCount && (
            <CardContainer style={{ flex: 1, margin: '32px 0px 0px' }}>
              <CardHeader>Customize # of Leads</CardHeader>
              <CardBody
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '8px 24px',
                }}
              >
                <Title style={{ margin: '10px 0px 0px', fontSize: '42px' }}>{customLeadCount}</Title>
                <F4 style={{ margin: '10px 0px 0px', color: colors.grey_02 }}>Advisor Leads</F4>
                <Slider
                  style={{ color: colors.grey_03 }}
                  onChange={(event: any) => setCustomLeadCount(event?.target?.value)}
                  value={customLeadCount}
                  step={5}
                  min={10}
                  max={250}
                />
              </CardBody>
            </CardContainer>
          )}
        </Row>
      </HeaderContainer>
      <ChartContainer>
        <CharacterChart
          dataPoints={dataPoints}
          headerOptions={headerOptions}
          featureSelections={featureSelections}
          setFeatureSelections={setFeatureSelections}
          clientCount={clientCount}
          submitAction={submitAction}
          selectedToggle={selectedToggle}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          setSelectedToggle={setSelectedToggle}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          getLeadPool={getLeadPool}
          switchMetric={switchMetric}
          benchmark={average}
          explanation={explanation}
          activeTable={activeTable}
          setActiveTable={setActiveTable}
          selectOptions={selectFields[selectedToggle.id]}
          hideCurrentFundHolders={hideCurrentFundHolders}
          setHideCurrentFundHolders={setHideCurrentFundHolders}
          label={label}
          //@ts-ignore
          toggleConnectModal={toggleConnectModal}
          //@ts-ignore
          displayData={
            selectedToggle.id === 'advisor' || selectedToggle.id === 'lplAdvisor'
              ? parseDataAdvisor(advisorCharacteristics[selectedMetric.key])
              : parseData(queryData[vizV2 ? selectedMetric.averageDataKey : selectedMetric.key])
          }
          showLinkedData={showLinkedData}
          loading={false}
        />
      </ChartContainer>
    </Root>
  );
};
