import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { ResponsiveContainer, Scatter, ScatterChart, XAxis, YAxis, Label, ReferenceLine, ReferenceDot } from 'recharts';
import { colors, typography } from '../../styles';
import { Column, Row, Title, H2, F2, F3 } from '..';
import { formatPackageName } from '../../helpers/dataFormatter';

const TooltipContainer = styled('div')({
  background: '#242B2F',
  color: 'white',
  borderRadius: 5,
  width: 'fit-content',
  padding: '10px 40px',
  boxShadow: '0 0 0.4em #797D83',
  textAlign: 'center',
});

const TooltipName = styled('p')({
  fontSize: 18,
  fontWeight: 500,
  margin: '4px 0 10px',
});

const TooltipValue = styled('p')({
  fontSize: 16,
  fontWeight: 400,
  margin: '10px 0 4px',
});

const TextContainer = styled(Column)({
  marginLeft: '20px',
  backgroundColor: colors.light_purple + '30',
  height: '280px',
  borderRadius: '16px',
  flex: 1,
  padding: '8px 24px',
});

const UserPoint = (props: any) => {
  const { x, y, cx, cy, width, color, label } = props;
  return label !== 'Typical JHI Holder' ? (
    <svg>
      <text
        style={{
          fontFamily: 'Inter',
          fontWeight: '500',
          fontSize: '10px',
          color: colors.grey_02,
        }}
        textAnchor="middle"
        dominantBaseline="middle"
        x={x + 5}
        y={y + 32}
      >
        {label}
      </text>
      <ellipse cx={cx} cy={cy} ry={20} rx={20} fill={colors.light_purple} />
      <path
        viewBox="0 0 20 20"
        height={20}
        width={20}
        transform={`translate(${cx - 13}, ${cy - 13})`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4286 21.0953V22.1429C24.4286 23.381 23.381 24.4286 22.1429 24.4286H3.85714C2.61905 24.4286 1.57143 23.381 1.57143 22.1429V21.0953C1.57143 18.3334 4.80952 16.6191 7.85714 15.2858L8.14286 15.1429C8.38095 15.0477 8.61905 15.0477 8.85714 15.1905C10.0952 16 11.4762 16.4286 12.9524 16.4286C14.4286 16.4286 15.8571 15.9524 17.0476 15.1905C17.2857 15.0477 17.5238 15.0477 17.7619 15.1429L18.0476 15.2858C21.1905 16.6191 24.4286 18.2858 24.4286 21.0953V21.0953ZM13 1.57141C16.1429 1.57141 18.6667 4.38094 18.6667 7.85714C18.6667 11.3333 16.1429 14.1429 13 14.1429C9.85718 14.1429 7.33337 11.3333 7.33337 7.85714C7.33337 4.38094 9.85718 1.57141 13 1.57141Z"
        fill="white"
      />
    </svg>
  ) : null;
};

type Props = {
  data?: any;
  advisorName?: string;
};

export const Persona = ({ data, advisorName }: Props) => {
  const [chartData, setChartData] = useState<any>(null);

  const formatPersonaData = (data: any) => {
    if (data?.advisorData?.axis0 !== undefined && data?.advisorData?.axis1 !== undefined) {
      const newPoint = {
        x: data?.advisorData?.axis0 !== undefined ? data?.advisorData?.axis0 * 10 + 5 : 0,
        y: data?.advisorData?.axis1 !== undefined ? data?.advisorData?.axis1 * 10 + 5 : 0,
        label: advisorName,
      };
      return [newPoint];
    } else {
      return data?.chartData?.data;
    }
  };

  useEffect(() => {
    setChartData(formatPersonaData(data));
  }, [data]);

  const dataAvailable = !!data?.type?.description || !!data?.advisorData?.summary;

  return !dataAvailable ? (
    <Column style={{ padding: '80px 0px', alignItems: 'center' }}>
      <H2>No Data Available</H2>
      <F3 style={{ color: colors.grey_02, padding: '8px 0px' }}>Please check back in future for updated data.</F3>
    </Column>
  ) : (
    <Row style={{ borderBottom: `1px solid ${colors.grey_01}`, padding: '20px 0px 30px' }}>
      <ResponsiveContainer width={480} id={'persona-chart-data'}>
        <ScatterChart margin={{ top: 30, left: 10, right: 10 }}>
          <XAxis
            type="number"
            dataKey={'x'}
            tickFormatter={(tick) => ''}
            domain={!!data?.axisData ? [0, 42] : [0, 100]}
            tickLine={false}
            axisLine={{ color: colors.grey_01 }}
          >
            <Label style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px', color: colors.grey_02 }}>
              {data?.axisData?.axis0Label
                ? formatPackageName(data?.axisData?.axis0Label)
                : data?.chartData?.xAxis?.label}
            </Label>
          </XAxis>
          <YAxis
            type="number"
            dataKey={'y'}
            tickFormatter={(tick) => ' '}
            domain={!!data?.axisData ? [0, 32] : [0, 100]}
            width={40}
            axisLine={{ color: colors.grey_01 }}
            tickLine={false}
            fill={colors.grey_02}
          >
            <Label
              style={{ fontFamily: 'Inter', fontWeight: '600', fontSize: '14px', color: colors.grey_02 }}
              viewBox={{ width: 0, height: 0 }}
              angle={-90}
              offset={0}
            >
              {data?.axisData?.axis1Label
                ? formatPackageName(data?.axisData?.axis1Label)
                : data?.chartData?.yAxis?.label}
            </Label>
          </YAxis>
          {/* <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} /> */}
          {!!data?.axisData ? (
            <>
              {data?.axisData?.datapoints?.map((point: any, index: number) => {
                return (
                  <ReferenceDot
                    y={point?.axis1 * 10 + 8}
                    x={point?.axis0 * 10 + 5}
                    textAnchor="start"
                    fill={colors.white}
                    stroke={colors.white}
                    key={index + 21041}
                  >
                    <Label
                      style={{
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        margin: '40px 30px',
                        fontSize: '8px',
                        // width: '20px',
                        color: colors.grey_01,
                      }}
                    >
                      {point?.persona ?? point?.personaName}
                    </Label>
                  </ReferenceDot>
                );
              })}
              <ReferenceLine x={10} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine y={10} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine x={20} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine y={20} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine x={30} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine x={40} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine y={30} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <Scatter
                data={chartData}
                //@ts-ignore
                domain={[0, 4]}
                range={[0, 4]}
                shape={<UserPoint />}
                fill={colors.black}
              />
            </>
          ) : (
            <>
              <ReferenceLine x={50} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceLine y={50} strokeWidth={2} stroke={colors.grey_01} strokeDasharray="4 0" />
              <ReferenceDot y={100} x={25} textAnchor="middle">
                Interested Prospect
              </ReferenceDot>
              <ReferenceDot y={100} x={25} textAnchor="middle">
                Engaged Investor
              </ReferenceDot>
              <ReferenceDot y={100} x={25} textAnchor="middle"></ReferenceDot>
              <ReferenceDot y={100} x={25} textAnchor="middle" fill={colors.white} stroke={colors.white}>
                <Label style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', color: colors.grey_01 }}>
                  Interested Prospect
                </Label>
              </ReferenceDot>
              <ReferenceDot y={100} x={75} textAnchor="middle" fill={colors.white} stroke={colors.white}>
                <Label style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', color: colors.grey_01 }}>
                  Engaged Investor
                </Label>
              </ReferenceDot>
              <ReferenceDot y={44} x={25} textAnchor="middle" fill={colors.white} stroke={colors.white}>
                <Label style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', color: colors.grey_01 }}>
                  Unaware Prospect
                </Label>
              </ReferenceDot>
              <ReferenceDot y={44} x={75} textAnchor="middle" fill={colors.white} stroke={colors.white}>
                <Label style={{ fontFamily: 'Inter', fontWeight: '500', fontSize: '12px', color: colors.grey_01 }}>
                  Dormant Investor
                </Label>
              </ReferenceDot>
              <Scatter
                data={data?.chartData?.data}
                //@ts-ignore
                domain={[0, 100]}
                range={[0, 100]}
                shape={<UserPoint />}
                fill={colors.black}
              />
            </>
          )}
        </ScatterChart>
      </ResponsiveContainer>
      <TextContainer>
        <Title style={{ textAlign: 'left' }}>{data?.type?.name ?? data?.advisorData?.persona}</Title>
        <F3 style={{ textAlign: 'left', color: colors.grey_02 }}>
          {data?.type?.description ?? data?.advisorData?.summary} <br />
          <br />
          {data?.insights?.details.join(' ')}
        </F3>
      </TextContainer>
    </Row>
  );
};
