import React from 'react';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import { ZoomOutButton } from '..';
import { StatType, StepDescriptionType } from '../../types';
import { StatCard } from '../StatCard';
import { PrimaryButtonShort } from '../../components';
import { formatNumber, unformatPackageName } from '../../helpers/dataFormatter';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table';
import { useStoreState } from '../../store';
import SubscriptionModal from '../SubscriptionModal';
import { useQuery } from '@apollo/client';

const Root = styled('div')({
  padding: '0px 0px',
});

const PackageHeaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: '100%';
  background: ${colors.gradient_01};
  padding: '30px 23px 20px';
  // height: 442px;
`;

const PackageName = styled('h1')`
  ${typography.header_02};
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -2px;
  margin: 30px 0px 10px 0px;
`;

const PackageDescription = styled('p')`
  ${typography.footer_03};
  color: ${colors.grey_02};
  width: 500px;
  line-height: 20px;
  text-align: center;
  margin: 0px 0px 0px;
`;

const Divider = styled('div')(`
    display: flex;
    width:92%;
    height: 1px;
    border-bottom: 1px solid ${colors.black_01};
    opacity: 0.2;
    margin: 23px 23px 30px;
`);

const PackageStepsContainer = styled('div')`
  display: flex;
  flex: 1;
`;

const StepContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 200px;
  margin: 0px 1px;
`;

const StepDescription = styled('p')`
  ${typography.footer_04};
  text-align: center;
  line-height: 20px;
  height: 45px;
  width: 170px;
`;

const StepIcon = styled('img')`
  height: 60px;
  width: 60px;
`;

const StepBlock = styled('div')`
  display: flex;
  min-width: 280px;
  height: 40px;
  background: ${colors.black_01};
  opacity: 0.2;
  border-radius: 2px;
`;

const StatCardContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: 20px 13px 10px;
`;

const InfoText = styled('p')`
  ${typography.footer_04};
  color: ${colors.grey_02};
  margin: 10px;
`;

const TableContainer = styled('div')({
  display: 'flex',
  flex: 1,
  padding: '10px 14px',
});

type Props = {
  name: string;
  description: string;
  stepDescriptions: Array<StepDescriptionType>;
  packageStats: Array<StatType>;
  nextStep: any;
  CustomStatComponent: any;
  openArchive: any;
  GET_ARCHIVE_QUERY: any;
  tableHeaders: Array<any>;
};

export const PackageHome = ({
  name,
  description,
  stepDescriptions,
  packageStats,
  nextStep,
  GET_ARCHIVE_QUERY,
  openArchive,
  tableHeaders,
  CustomStatComponent,
}: Props) => {
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState('');
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState(0);
  const [totalJourneys, setTotalJourneys] = useState(0);
  const [packageUsage, setPackageUsage] = useState(0);
  const [data, setData] = useState([]);

  const unformattedName: string = unformatPackageName(name);

  const subscriptionsList: any = useStoreState((state) => state.user.subscriptions);

  const packageKeys: any = {
    INFLUENCE: 'influence',
    DISCOVER_LEADS: 'discoverLeads',
  };

  const filters = unformattedName ? [{ key: 'package', value: unformattedName }] : [];

  const {
    data: archiveData,
    loading: archiveLoading,
    error: archiveError,
    refetch: refetchArchive,
  } = useQuery(GET_ARCHIVE_QUERY, {
    variables: { pageSize, tablePage: tablePage + 1, filters },
    onCompleted: (data) => {
      const formattedName = packageKeys?.[unformattedName];
      const newPackageUsage = data?.[formattedName]?.quotaUsed;
      // console.log('loading archive data: ', data, formattedName, newPackageUsage);
      setData(data.journeys.list);
      setTotalJourneys(data.journeys.totalItems);
      setPackageUsage(newPackageUsage ?? 0);
    },
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       setLoading(true);
  //       console.log('fetching data in useEffect: ', pageSize, tablePage + 1);
  //       const resultFromGetData = await getArchives(pageSize, tablePage + 1);
  //       setData(resultFromGetData.runs.list);
  //       console.log('result from get data: ', resultFromGetData);
  //       setTotalJourneys(resultFromGetData.runs.totalItems);
  //       setPackageUsage(resultFromGetData?.quotaUsed);
  //       setLoading(false);
  //       setFirstLoad(false);
  //     } catch (e: any) {
  //       setLoading(false);
  //       setError(e.message);
  //       console.warn('Error fetching Archive: ', e.message);
  //     }
  //   };
  //   fetchData();
  // }, [pageSize, tablePage]);

  const stats: Array<StatType> = packageStats
    ? [
        { label: packageStats?.[0]?.label, data: formatNumber(totalJourneys) },
        { label: packageStats?.[1]?.label, data: formatNumber(packageUsage) },
      ]
    : [
        { label: 'Completed Journeys', data: formatNumber(totalJourneys) },
        { label: 'Total Prospects', data: formatNumber(packageUsage) },
      ];

  return (
    <Root>
      <PackageHeaderContainer>
        <ZoomOutButton style={{ margin: '20px 0px 0px' }} id="ZoomOutButton" onClick={() => navigate('/generate')}>
          Zoom Out
        </ZoomOutButton>
        <PackageName>{name}</PackageName>
        <PackageDescription>{description}</PackageDescription>
        <Divider />
        <PackageStepsContainer>
          {stepDescriptions.map((item, index) => (
            <StepContainer key={index + 124391}>
              <StepIcon src={item.icon} />
              <StepDescription>
                <span style={{ fontWeight: 600 }}>{item.name}</span> {item.description}
              </StepDescription>
              <StepBlock />
            </StepContainer>
          ))}
        </PackageStepsContainer>
        <div style={{ margin: '0px 0px 28px 0px' }}>
          <PrimaryButtonShort id={'start-journey-button'} onClick={nextStep}>
            Begin a New Journey
          </PrimaryButtonShort>
        </div>
      </PackageHeaderContainer>
      {/* <InfoText>See the data source for this package in your dashboard</InfoText> */}
      <StatCardContainer>
        {CustomStatComponent ? (
          <CustomStatComponent />
        ) : (
          stats.map((statInfo, index) => <StatCard {...statInfo} key={index + 12831} index={index} />)
        )}
      </StatCardContainer>
      <TableContainer>
        <Table
          title={'Archived Results'}
          subtitle={'Select a previous journey below to return to the archived results.'}
          headerData={tableHeaders}
          data={data}
          loading={archiveLoading}
          dataControls={{
            selections: {},
            setSelections: () => {},
            searchFunc: setSearchString,
            searchString,
          }}
          paginationControls={{
            tablePage,
            setTablePage,
            pageSize,
            setPageSize,
            sortKey,
            setSortKey,
            totalItems: totalJourneys,
          }}
          onRowPress={openArchive}
          newItems={[]}
        />
      </TableContainer>
      <SubscriptionModal
        modalStatus={!subscriptionsList.find((sub: any) => sub?.package === unformattedName)}
        closeModal={() => navigate('/drive-sales')}
        requestNote={`User would like to activate the ${name}`}
        requestType={'Subscription Request'}
      />
    </Root>
  );
};
