import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { colors, typography } from '../styles';
import ComingSoonModal from './ComingSoonModal';
import { DistillScoreLogo } from './DistillScoreLogo';
import { formatDistillScore, formatPercent } from '../helpers/dataFormatter';
import { H1, H3, F3, F4, Row, Column, Divider, SecondaryButton } from '.';
import ArrowGrey from '../assets/icons/arrow-grey.svg';
import { styled } from '@mui/material/styles';
import moment from 'moment';

interface Props {
  campaign: any;
  selectCampaign: any;
  showBorders: boolean;
  type?: string;
  id: string;
}

const ClientCardContainer = styled('div')({
  display: 'flex',
  flex: 1,
  minWidth: '200px',
  padding: '20px 0px 16px',
  color: 'black',
  textAlign: 'center',
  background: '#fff',
  boxShadow: colors.box_shadow_override,
});

const InnerContainer = styled('div')({
  display: 'table',
  width: '100%',
  //minHeight: 310,
  padding: '0px 0px',
  margin: '0px 0px 10px',
});

const CampaignCard = ({ campaign, selectCampaign, id, showBorders, type }: Props) => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [scrollingDivWidth, setScrollingDivWidth] = useState(300);
  const [referralIndices, setReferralIndices] = useState<any>({ 0: 0, 1: 0, 2: 0 });

  const cardParams = useRef<any>();

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  useEffect(() => {
    setScrollingDivWidth(cardParams.current.clientWidth - 44);
  }, [cardParams.current]);

  const contentModel =
    type === 'Campaigns'
      ? [
          { label: 'Start Date', format: (data: any) => moment(data?.dateCreated).format('ll') },
          { label: 'Emails', format: (data: any) => data?.nurtureemailSet?.length },
          { label: 'Recipients', format: (data: any) => data?.recipientCount },
          { label: 'Open Rate', format: (data: any) => formatPercent(data?.openRate * 100, 1) },
          { label: 'Click Rate', format: (data: any) => formatPercent(data?.clickThroughRate * 100, 1) },
        ]
      : [
          {
            label: 'Advisor Count',
            format: (data: any) => {
              console.log('data in contentModel: ', data);
              return data?.runhistorySet?.[0].serveCount;
            },
          },
          { label: 'Source', format: (data: any) => (data?.source ? data?.source : 'CSV') },
          { label: 'Status', format: (data: any) => 'Active' },
          { label: 'Date Created', format: (data: any) => moment(data?.dateCreated).format('ll') },
        ];

  return (
    <ClientCardContainer
      ref={cardParams}
      id={id}
      key={campaign.id + 'clientcard'}
      style={
        showBorders ? { borderLeft: `1px solid ${colors.grey_01}`, borderRight: `1px solid ${colors.grey_01}` } : {}
      }
    >
      <InnerContainer>
        <div style={{ padding: 0, margin: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div
              style={{
                padding: '4px 15px 6px',
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              {/* <h2 className={classes.smallDistillScore}>AMP Score</h2> */}
              <Column style={{ width: '100%' }}>
                <Row style={{ justifyContent: 'space-between', width: '100%', margin: '0px 0px' }}>
                  <DistillScoreLogo packageName={'advisorLeads'} style={{ height: '14px', width: '82px' }} />
                  <F4 style={{ margin: '0px 0px', color: colors.grey_02 }}>
                    {formatDistillScore(campaign.previousAverageScore)}%{' '}
                    <img style={{ transform: 'rotate(90deg)', height: '8px' }} src={ArrowGrey} />{' '}
                    {formatDistillScore(campaign.currentAverageScore)}%
                  </F4>
                </Row>
                <Row style={{ justifyContent: 'space-between', width: '100%' }}>
                  <H1 style={{ margin: '0px 0px', letterSpacing: '-1px', fontSize: '22px', fontWeight: '600' }}>
                    {'Improvement'}
                  </H1>
                  <H1 style={{ margin: '0px 0px', fontSize: '24px', fontWeight: '600' }}>
                    {campaign?.averageScoreIncrease > 0 && '+'}
                    {formatDistillScore(campaign?.averageScoreIncrease)}
                    {/* <span style={{ fontSize: '12px' }}>%</span> */}
                  </H1>
                </Row>
              </Column>
            </div>
            {/* <Button className={classes.buttonStyleInverted} children={`How it's calculated`} onClick={expandCard} /> */}
          </div>
          <Divider />
          {/* <Content data={client} /> */}
          <Column style={{ justifyContent: 'flex-start', padding: '0px 16px', overflowX: 'hidden' }}>
            <H3
              style={{
                textAlign: 'left',
                whiteSpace: 'nowrap',
                width: '300px',
                textOverflow: 'ellipsis',
              }}
              id={`${id}-name`}
            >
              {campaign?.name ? campaign.name : campaign?.customName}
            </H3>
            <F3 style={{ color: colors.grey_02, textAlign: 'left', margin: '0px 0 -6px' }}>{campaign.description}</F3>
            <Divider style={{}} />
          </Column>
          {contentModel.map((item: any) => (
            <Row style={{ justifyContent: 'space-between', margin: '4px 16px' }}>
              <F4 style={{ margin: '0px 0px', color: colors.grey_02 }}>{item.label}</F4>
              <F4 style={{ margin: '0px 0px', color: colors.grey_02 }}>{item.format(campaign)}</F4>
            </Row>
          ))}
          <div
            style={{
              borderTop: '1px solid #e3e5e4',
              //   margin: ContentModels[contentType].length === 1 ? '-30px auto 0px' : '-16px auto 0px',
              padding: '20px 0 0',
              margin: '0px 16px 0px 16px',
              justifyContent: 'center',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SecondaryButton
                id={`${campaign.id}-view-details`}
                children={'View Details'}
                onClick={() => selectCampaign(campaign)}
              />
            </div>
          </div>
          <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
        </div>
      </InnerContainer>
    </ClientCardContainer>
  );
};

export default CampaignCard;
