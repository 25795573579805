import * as Sentry from '@sentry/browser';
import moment from 'moment';
import { cloneDeep } from 'lodash';

export const formatMillionDollar = (amount: any) => {
  if (amount && !isNaN(amount))
    if (Math.ceil(Math.log10(amount + 1)) > 3) {
      return (
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1 }).format(
          amount / 1000.0,
        ) + 'B'
      );
    } else {
      return (
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1 }).format(
          +amount,
        ) + 'M'
      );
    }
  return '-';
};

export const unformatMillionDollar = (amount: any) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount * 1000000);
};

export const formatThousandDollar = (amount: any) => {
  if ((amount && !isNaN(amount)) || amount === 0)
    if (Math.ceil(Math.log10(amount + 1)) > 6) {
      return (
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1 }).format(
          amount / 1000000.0,
        ) + 'B'
      );
    } else if (Math.ceil(Math.log10(amount + 1)) > 3) {
      return (
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1 }).format(
          amount / 1000.0,
        ) + 'M'
      );
    } else {
      return (
        new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }).format(
          +amount,
        ) + 'K'
      );
    }
  return '-';
};

export const unformatThousandDollar = (amount: any) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount * 1000);
};

export const formatCharacteristics = (key: string) => {
  const characteristicsKeys: any = {
    age: { displayText: 'Age', title: 'Age', key: 'age', dataKey: 'age', averageDataKey: 'age' },
    aumSelfReported: {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'aumSelfReported',
      averageDataKey: 'aum_self_reported',
    },
    totalAssets: {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'aumSelfReported',
      averageDataKey: 'aum_self_reported',
    },
    firmTotalAssetsInMillions: {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firmTotalAssetsInMillions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    firm_total_assets_in_millions: {
      displayText: 'Firm Size',
      title: 'Firm Size',
      key: 'firmTotalAssetsInMillions',
      dataKey: 'firmTotalAssetsInMillions',
      averageDataKey: 'firm_total_assets_in_millions',
    },
    careerTenure: {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'careerTenure',
      averageDataKey: 'career_tenure',
    },
    advisorLplTenure: {
      displayText: 'LPL Tenure',
      title: 'LPL Tenure',
      key: 'lplTenure',
      dataKey: 'advisorLplTenure',
      averageDataKey: 'lplTenure',
    },
    etfAssets: {
      displayText: 'ETF Assets',
      title: 'ETF Assets',
      key: 'lplEtfAssets',
      dataKey: 'etfAssets',
      type: 'dollar',
    },
    cashAssets: {
      displayText: 'Cash Assets',
      title: 'Cash Assets',
      key: 'lplCashAssets',
      dataKey: 'cashAssets',
      type: 'dollar',
    },
    advisedInvestorsCnt: {
      displayText: '# Advised Clients',
      title: '# Advised Clients',
      key: 'advisedInvestorsCnt',
      type: 'percent',
      dataKey: 'advisedInvestorsCnt',
    },
    trailingTwelveMonthTotalNetNewAssets: {
      displayText: 'Net New Assets (Past Year)',
      key: 'trailingTwelveMonthTotalNetNewAssets',
    },
    firmPercentAssetsEtf: {
      displayText: 'Firm ETF Allocation',
      title: 'Firm ETF Allocation',
      key: 'firmPercentAssetsEtf',
      dataKey: 'firmPercentAssetsEtf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_etf',
    },
    firmPercentAssetsMf: {
      displayText: 'Firm MF Allocation',
      title: 'Firm MF Allocation',
      key: 'firmPercentAssetsMf',
      dataKey: 'firmPercentAssetsMf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_mf',
    },
    total_assets: {
      displayText: 'AUM',
      title: 'AUM',
      key: 'aumSelfReported',
      dataKey: 'aumSelfReported',
      averageDataKey: 'aum_self_reported',
    },
    advisor_lpl_tenure: {
      displayText: 'LPL Tenure',
      title: 'LPL Tenure',
      key: 'lplTenure',
      dataKey: 'advisorLplTenure',
      averageDataKey: 'lplTenure',
    },
    etf_assets: {
      displayText: 'ETF Assets',
      title: 'ETF Assets',
      key: 'lplEtfAssets',
      dataKey: 'etfAssets',
      type: 'dollar',
    },
    cash_assets: {
      displayText: 'Cash Assets',
      title: 'Cash Assets',
      key: 'lplCashAssets',
      dataKey: 'cashAssets',
      type: 'dollar',
    },
    advised_investors_cnt: {
      displayText: '# Advised Clients',
      title: '# Advised Clients',
      key: 'advisedInvestorsCnt',
      type: 'percent',
      dataKey: 'advisedInvestorsCnt',
    },
    trailing_twelve_month_total_net_new_assets: {
      displayText: 'Net New Assets (Past Year)',
      key: 'trailingTwelveMonthTotalNetNewAssets',
    },
    firm_percent_assets_etf: {
      displayText: 'Firm ETF Allocation',
      title: 'Firm ETF Allocation',
      key: 'firmPercentAssetsEtf',
      dataKey: 'firmPercentAssetsEtf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_etf',
    },
    career_tenure: {
      displayText: 'Total Years as Advisor',
      title: 'Total Years as Advisor',
      key: 'careerTenure',
      dataKey: 'careerTenure',
      averageDataKey: 'career_tenure',
    },
    firm_percent_assets_mf: {
      displayText: 'Firm MF Allocation',
      title: 'Firm MF Allocation',
      key: 'firmPercentAssetsMf',
      dataKey: 'firmPercentAssetsMf',
      type: 'dollar',
      averageDataKey: 'firm_percent_assets_mf',
    },
  };

  const result = characteristicsKeys?.[key]?.displayText ? characteristicsKeys?.[key]?.displayText : '';

  return result;
};

export const formatDollar = (amount: any, digits?: number) => {
  let negative = '';
  let newAmount = Number(amount);
  if (amount < 0) {
    negative = '-';
    newAmount = Math.abs(amount);
  }
  if (newAmount === 0) {
    return '-';
  } else if (newAmount && !isNaN(newAmount)) {
    if (Math.ceil(Math.log10(newAmount + 1)) > 9) {
      return (
        negative +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: digits ? digits : 1,
        }).format(newAmount / 1000000000.0) +
        'B'
      );
    }
    if (Math.ceil(Math.log10(newAmount + 1)) >= 7) {
      return (
        negative +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: digits ? digits : 0,
        }).format(newAmount / 1000000.0) +
        'M'
      );
    } else if (Math.ceil(Math.log10(newAmount + 1)) > 3) {
      return (
        negative +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: digits ? digits : 0,
        }).format(newAmount / 1000.0) +
        'K'
      );
    } else {
      return (
        negative +
        new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          maximumFractionDigits: digits ? digits : 0,
        }).format(newAmount)
      );
    }
  }
  return '-';
};

export const formatBubbleChartData = (unformattedData: any, selectedTime: number) => {
  const workableData = cloneDeep(unformattedData);
  const newYAxisLegend = workableData.themes;
  const dataPointSet = workableData.dataPoints;
  // const dataPointSet = workableData.dataPoints.map((item) => {
  //   if (item.value > 0) {
  //     //item.value = Math.log(item.value) * 100;
  //     item.value_norm = Math.log(item.value) * 100;
  //   } else {
  //     item.value_norm = 0;
  //   }
  //   return { ...item };
  // });

  const { newDomain, tickArray } = parseDomainAndTickMarks(dataPointSet, selectedTime);
  // parseRange(dataPointSet, 'label');
  //console.log('OldBubbleChartData: ', bubbleChartData);
  const newBubbleChartData = {
    dataPoints: dataPointSet,
    domain: newDomain,
    xAxis: tickArray,
    yAxis: newYAxisLegend,
  };
  return newBubbleChartData;
};

const parseRange = (dataToParse: any, key: string) => {
  return [
    Math.min(
      Math.min.apply(
        null,
        dataToParse.map((entry: any) => entry[key]),
      ),
      Math.min.apply(
        null,
        dataToParse.map((entry: any) => entry[key]),
      ),
    ),
    Math.max(
      Math.max.apply(
        null,
        dataToParse.map((entry: any) => entry[key]),
      ),
      Math.max.apply(
        null,
        dataToParse.map((entry: any) => entry[key]),
      ),
    ),
  ];
};

const parseDomainAndTickMarks = (dataToParse: any, currentTimeSetting: any) => {
  const [dataMin, dataMax] = parseRange(dataToParse, 'label');
  const domainMin = dataMin - (currentTimeSetting === 72 ? 53000000 : currentTimeSetting === 24 ? 21000000 : 3000000);
  const domainMax = dataMax;

  const domainDifference = domainMax - domainMin;
  const tickInterval = domainDifference / 8;
  // const tickInterval = 3600000;

  const maxDateTime: any = new Date(dataMax);
  maxDateTime.setMinutes(0, 0, 0);

  let tickArray = [];
  let currentTick: number = maxDateTime - tickInterval / 2;
  //@ts-ignore
  while (currentTick > dataMin) {
    tickArray.push(currentTick);
    //@ts-ignore
    currentTick -= tickInterval;
  }
  return { newDomain: [domainMin, domainMax], tickArray };
};

export const formatAdvisorType = (data: any, enrichedData?: any) => {
  if (enrichedData && enrichedData.isBd === data.isBd && enrichedData.isRia === data.isRia) {
    return '-';
  } else if (data.isBd && data.isRia) {
    return 'RIA & BD';
  } else if (data.isBd) {
    return 'BD';
  } else if (data.isRia) {
    return 'RIA';
  } else {
    return '-';
  }
};

export const formatPercent = (amount: any, precision = 0, setMin?: any) => {
  if ((amount && !isNaN(amount)) || amount === 0) {
    if (setMin) {
      return Math.max(amount, 5).toFixed(precision) + '%';
    }
    return Number(amount)?.toFixed(precision) + '%';
  }
  return '-';
};

export const formatDistillScore = (value: string | number) => {
  let score: string = '-';
  if (value === '100' || value === 100) {
    score = '99';
  } else if (Number(value) > 0 && Number(value) < 1) {
    score = (Number(value) * 100).toFixed(0);
  } else if (typeof value === 'number') {
    score = String(value.toFixed(0));
  } else {
    score = value;
  }
  return score;
};

export const formatNumber = (amount: number | null, precision = 0) => {
  if (amount === 0) {
    return '-';
  } else if (amount && !isNaN(amount))
    return new Intl.NumberFormat('en-US', { maximumFractionDigits: precision }).format(+amount);
  return '-';
};

export const formatEnrichedData = (advisorDistillData: any) => {
  const dataValues = Object.values(advisorDistillData);
  const dataExists = !dataValues.every((item) => !item || item === 'AdvisorDistillDataType');
  return dataExists;
};

export const formatTimestamp = (timestamp: any, hideDayName?: boolean) => {
  const newDate = new Date(timestamp);
  const dateOptions: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  const timeOptions: any = { hour: '2-digit', minute: '2-digit' };
  return newDate.toLocaleDateString('en-US', dateOptions) + ' - ' + newDate.toLocaleTimeString('en-US', timeOptions);
};

export const formatDate = (timestamp: any, short?: boolean) => {
  const newDate = new Date(timestamp);
  const dateOptions: any = short
    ? { year: 'numeric', month: 'numeric', day: 'numeric' }
    : { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
  return newDate.toLocaleDateString('en-US', dateOptions);
};

export const formatDateDifference = (startTimestamp: any, endTimestamp: any) => {
  const dateDifference = moment(endTimestamp).diff(startTimestamp, 'days');
  return dateDifference > 0 ? dateDifference : 0;
};

export const formatErrorTitle = (error: string) => {
  Sentry.captureException(new Error(error));
  switch (error) {
    case 'No Results':
      return 'No results found';
    case 'GraphQL error: no investors available with given filters':
      return 'No results found';
    case 'GraphQL error: No prospects with these filters.':
      return 'No results found';
    case 'GraphQL error: zero-size array to reduction operation maximum which has no identity':
      return `Sorry, we weren't able to generate leads for this fund`;
    case 'GraphQL error: No Quota Remaining':
      return 'Quota Reached';
    case 'Discover leads stats not found No advisor found':
      return 'Custom Analysis Needed';
    default:
      return 'Error';
  }
};

export const formatThemes = (themes: any) => {
  if (!!themes) return '';
  else {
    const themesObject = JSON.parse(themes);
    let themeArray: any[] = [];
    if (themesObject) {
      themeArray = Object.keys(themesObject);
    }
    const parsedThemesArray = themeArray.map((theme) => theme.split('/').pop().replaceAll('_', ' ').replace(' n', ' '));
    const uppercaseThemesArray = parsedThemesArray.map(
      (uppercaseTheme) => uppercaseTheme.charAt(0).toUpperCase() + uppercaseTheme.slice(1),
    );
    return uppercaseThemesArray.join(', ');
  }
};

export const unformatSnakeCase = (str: string) => {
  const snakeArray = str?.split('_');

  const new_words = [];
  // Loop over the words.
  if (!!snakeArray) {
    for (const word of snakeArray) {
      // Convert the first letter of the word to uppercase.
      // console.log('word: ', word);
      const new_word = word?.[0]?.toUpperCase() + word?.substring(1);
      new_words.push(new_word);
    }
  }

  return new_words.join(' ');
};

export const formatSnakeCase = (str: string) => {
  const snakeResult = str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  return snakeResult;
};

export const formatCamelCase = (str: string, capFirst?: boolean) => {
  const splitString = str?.split(/[-_ ]/).map((item) => item.toLowerCase());
  let result = '';
  if (capFirst) {
    for (let item of splitString) {
      result += item[0].toUpperCase() + item.slice(1);
    }
  } else {
    result = splitString[0];

    for (let item of splitString.slice(1)) {
      result += item[0].toUpperCase() + item.slice(1);
    }
  }
  return result;
};

export const unformatCamelCase = (str: string) => {
  const splitString = str
    ?.replace(/([A-Z])/g, ` $1`)
    .trim()
    .replace('etf', 'ETF')
    .replace('Mf', 'MF')
    .replace('Aum', 'AUM');

  return splitString.charAt(0).toUpperCase() + splitString.slice(1);
};

export const formatThemesArray = (themes: any) => {
  let themesObject = {};
  if (typeof themes === 'string') {
    themesObject = JSON.parse(themes);
  }
  if (typeof themes === 'string') {
    themesObject = JSON.parse(themes);
  }
  const themeArray = Object.entries(themesObject).sort((a: any, b: any) => {
    if (a[1] < b[1]) {
      return 1;
    }
    if (a[1] > b[1]) {
      return -1;
    }
    return 0;
  });
  const parsedThemesArray = themeArray
    ?.slice(0, 3)
    ?.map((theme: any) => theme?.[0].split('/')?.pop()?.replaceAll('_', ' ')?.replace(' n', ' '));
  const uppercaseThemesArray = parsedThemesArray?.map(
    (uppercaseTheme) => uppercaseTheme?.charAt(0)?.toUpperCase() + uppercaseTheme?.slice(1),
  );
  return uppercaseThemesArray;
};

export const formatLabel = (str: string) => {
  if (!str) return '-';
  const splitString = str?.split(/[-_ ]/).map((item) => item.toLowerCase());
  // console.log('splitString in formatLabel: ', splitString);
  let result = [];
  for (let item of splitString) {
    if (item === 'esg') {
      result.push('ESG');
    } else {
      result.push(item[0].toUpperCase() + item.slice(1));
    }
  }
  return result.join(' ');
};

export const multiSelect: any = {
  'Firm AUM': 'firm_total_assets_in_millions__range',
  'Firm Accounts': 'firm_total_accounts__range',
  'Firm name': 'firm_name',
  Age: 'age__range',
  Location: 'state',
  Custodian: 'custodian',
  RIA: 'is_ria',
  BD: 'is_bd',
  Income: 'income__range',
  Wealth: 'wealth__range',
};

export const formatDatasetType = (value: string) => {
  const newDatasetName = formatPackageName(value.replace('DATASET_', '').replace('_TYPE', ''));

  return newDatasetName;
};

export const formatLeadPoolFilters = (selectedFilters: any) => {
  let formattedFilters: any[] = [];
  for (let obj in selectedFilters) {
    if (selectedFilters[obj]?.value) {
      formattedFilters = [...formattedFilters, ...selectedFilters[obj].value];
    } else {
      for (let item in selectedFilters[obj].label) {
        console.log('obj: ', obj, 'item: ', item);
        if (obj === 'Type (BD/RIA)') {
          let filteredType = formatType(selectedFilters[obj]);
          if (filteredType.length > 0) {
            for (let item in filteredType) {
              formattedFilters.push(filteredType[item]);
            }
          }
        } else if (obj === 'Age') {
          const splitFilters = selectedFilters['Age'].label[item].split('-');
          // console.log('splitFilters: ', splitFilters);
          formattedFilters.push({
            key: 'age__gte',
            value: splitFilters[0],
          });
          formattedFilters.push({
            key: 'age__lte',
            value: splitFilters[1],
          });
        } else if (obj === 'Custom Tag') {
          formattedFilters.push({
            key: 'tag',
            value: selectedFilters[obj].label[item],
          });
        } else if (obj === 'Firm name') {
          formattedFilters.push({
            key: multiSelect[obj],
            value: selectedFilters[obj].label[item],
          });
        } else
          formattedFilters.push({
            key: multiSelect[obj],
            value: unformatNumber(selectedFilters[obj].label[item], multiSelect[obj]),
          });
      }
    }
  }
  return formattedFilters;
};

export const unformatNumber = (input: any, label: string) => {
  let output = input;

  if (label === 'firm_total_assets_in_millions__range' || label === 'firm_total_accounts__range') {
    output = output.replace(',', '');
    output = output.replace('K', '000');
    output = output.replace('M', '');
    output = output.replace('K', '000');
    output = output.replace('M', '');
    output = output.replace('k', '000');
    output = output.replace('m', '');
    output = output.replace('k', '000');
    output = output.replace('m', '');
    output = output.replace('<', '0,');
    output = output.replace('-', ',');
    if (output[0] === '>') {
      output = output.replace('>', '');
      output = output + ',1000000000000000';
    }
  }
  return output;
};

export const formatType = (input: any) => {
  let output = [];
  if (input.label.includes('BD')) {
    output.push({ key: 'is_bd', value: 'True' });
  } else if (input.label.includes('RIA')) {
    output.push({ key: 'is_ria', value: 'True' });
  }

  return output;
};

export const formatErrorBody = (error: string) => {
  switch (error) {
    case 'No Results':
      return 'Please adjust your selections and try again.';
    case 'GraphQL error: No prospects with these filters.':
      return 'Please adjust your selections and try again.';
    case 'GraphQL error: no investors available with given filters':
      return 'Please adjust your selections and try again.';
    case 'GraphQL error: zero-size array to reduction operation maximum which has no identity':
      return `Please try another fund or contact your customer support representative for help.`;
    case 'GraphQL error: No Quota Remaining':
      return 'Please contact our support team to increase your quota.';
    case 'Discover leads stats not found No advisor found':
      return 'Please contact our data science team to create a custom analysis.';
    default:
      return `Unknown error. Please adjust your selections and try again. (${error})`;
  }
};

export const formatEventOrganization = (name: string) => {
  switch (name) {
    case '5b400e40-1cba-4ba3-92b3-646ff69d8019':
      return 'INO';
    case 'ino':
      return 'INO';
    case 'stocknews':
      return 'StockNews';
    case 'wealthpop':
      return 'Wealthpop';
    case 'investors_alley':
      return 'Investors Alley';
    case 'clout':
      return 'TIFIN Wealth: Clout';
    case 'positivly':
      return 'TIFIN Grow: Personality';
    case 'personality':
      return 'TIFIN Grow: Personality';
    case 'plan':
      return 'TIFIN Grow: Plan';
    case 'risk':
      return 'TIFIN Grow: Risk';
    case 'distill_data_acquisition':
      return 'Financial Answers';
    case 'magnifi':
      return 'TIFIN Magnifi';
    case 'magnifi-advisors':
      return 'TIFIN Magnifi';
    case 'asc':
      return 'All Star Charts';
    default:
      return name;
  }
};

export const tickerColors: any = [
  '#6351ED',
  '#064676',
  '#8DC63F',
  '#0762D0',
  '#41926E',
  '#022558',
  '#FF4548',
  '#006465',
  '#EC1A37',
  '#41926E',
];

export const eventOrganizationColors: any = {
  clout: '#6351ED',
  magnifi: '#064676',
  'magnifi-advisors': '#064676',
  stocknews: '#8DC63F',
  wealthpop: '#0762D0',
  positivly: '#41926E',
  personality: '#41926E',
  plan: '#41926E',
  risk: '#41926E',
  '5b400e40-1cba-4ba3-92b3-646ff69d8019': '#022558',
  distill_data_acquisition: '#FF4548',
  investors_alley: '#006465',
  asc: '#EC1A37',
};

export const formatEventName = (name: string) => {
  const eventNameArray = name.split('_').map((word) => word[0].toUpperCase() + word.substring(1));
  return eventNameArray.join(' ');
};

export const formatLastName = (name: string) => {
  const splitName = name.split(' ');
  return splitName.slice(1).join(' ');
};

export const formatFirstName = (name: string) => {
  const firstName = name.split(' ')[0];
  return firstName;
};

export const formatRadarData = (data: any, radarFeatures?: any) => {
  let newRadarChartData = [];
  for (let obj in data) {
    newRadarChartData.push({
      name: obj,
      ...data[obj],
    });
  }

  return newRadarChartData;
};

export const formatSubscriptions = (data: any[]) => {
  return data;
};

export const formatSubName = (string: string) => {
  const lowercaseString = string.toLowerCase();
  const stringArray = lowercaseString.split('_');
  let result = stringArray[0];
  for (let item of stringArray.slice(1)) {
    result += item[0].toUpperCase() + item.slice(1);
  }
  if (result === 'nurtureLeads') {
    result = 'prioritizeAdvisors';
  }
  return result;
};

export const formatFullName = (object: any) => {
  if (object?.firstName && object?.lastName) {
    return `${object?.firstName} ${object?.lastName}`;
  } else if (object?.fullName) {
    return object?.fullName;
  } else if (object?.name) {
    return object?.name;
  } else if (object?.email) {
    return object?.email;
  } else {
    return 'No Name Found';
  }
};

export const formatPackageName = (string: string) => {
  let stringArray: string[] = [];
  if (string === 'ATTRIBUTION') {
    return 'Prioritize Engagement Activity';
  }
  if (string) {
    const lowercaseString = string.toLowerCase();
    stringArray = lowercaseString.split('_');
  }
  let result = '';
  for (let item of stringArray) {
    result += item[0].toUpperCase() + item.slice(1) + ' ';
  }

  return result;
};

export const formatFirmName = (string: string) => {
  let stringArray: string[] = [];
  if (string) {
    const lowercaseString = string.toLowerCase();
    stringArray = lowercaseString.split(' ');
    let result = '';
    for (let item of stringArray) {
      if (item === 'llc') {
        result += 'LLC';
      } else if (item === 'j.p.' || item === 'jp') {
        result += 'J.P. ';
      } else if (item === 'l.p.') {
        result += 'L.P. ';
      } else if (item === 'ubs') {
        result += 'UBS ';
      } else if (item === 'alliancebernstein') {
        result += 'AllianceBernstein ';
      } else if (item === ' ' || item === '') {
      } else {
        result += item?.[0]?.toUpperCase() + item?.slice(1) + ' ';
      }
    }
    return result;
  } else {
    return 'Firm name not available';
  }
};

export const formatTickers = (string: string) => {
  let stringArray: string[] = [];
  if (string && typeof string === 'string') {
    stringArray = string.split('_');
  }
  let result = '';
  for (let item of stringArray) {
    result += item[0].toUpperCase() + item.slice(1) + ' ';
  }
  return result;
};

export const formatRadarDataWealth = (data: any, radarFeatures?: any) => {
  let newRadarChartData = [];
  for (let obj in data) {
    newRadarChartData.push({
      name: nameMetrics(data[obj].name),
      ...data[obj],
    });
  }
  return newRadarChartData;
};

const nameMetrics = (metric: string) => {
  let output = metric;
  switch (metric) {
    case 'age':
      output = 'Age';
      break;
    case 'income':
      output = 'Income';
      break;
    case 'home_value':
      output = 'Home Value';
      break;
    case 'estimated_investable_assets':
      output = 'Assets';
      break;
    case 'percent_of_home_value_paid_off':
      output = 'Mortgage Paid';
      break;
    case 'proxy_credit_score':
      output = 'Credit Score Proxy';
      break;
    case 'financial_planning_tendency_score':
      output = 'Financial Planning';
      break;
    case 'projected_retirement_assets':
      output = 'Future Assets';
      break;
    case 'percent_assets_in_etf_and_mf':
      output = 'Firm ETF/MF Allocation';
      break;
    case 'career_tenure':
      output = 'Career Tenure';
      break;
    case 'firm_total_assets_in_millions':
      output = 'Firm AUM';
      break;
    case 'aum_self_reported':
      output = 'AUM';
      break;
    default:
      break;
  }
  return output;
};

export const unformatPackageName = (name: string) => {
  let newName = name.toUpperCase().replace(' ', '_');
  if (name === 'Individual Leads') {
    newName = 'INVESTOR_LEADS';
  }
  if (name === 'Prioritize Advisor Prospects') {
    newName = 'PRIORITIZE_ADVISORS';
  }
  if (name === 'Prioritize Advisor Clients') {
    newName = 'PRIORITIZE_CLIENTS';
  }
  if (name === 'Prioritize Engagement Activity') {
    newName = 'ATTRIBUTION';
  }
  if (name === 'Influence Existing Prospects') {
    newName = 'INFLUENCE';
  }
  if (name === 'Discover New Leads') {
    newName = 'DISCOVER_LEADS';
  }
  return newName;
};
