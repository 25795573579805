import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/PageHeader';
import ComingSoonModal from '../../../components/ComingSoonModal';
import { SectionPageHeader } from '../../../components/SectionPageHeader';
import { formatNumber, formatTimestamp } from '../../../helpers/dataFormatter';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../../styles';
import { CardContainer, CardHeader, H2, Row, CardBody, F3, Column, Title } from '../../../components';
import { ResponsiveContainer, Bar, XAxis, LabelList, BarChart, ReferenceLine } from 'recharts';
import { GET_OUTCOME_ARCHIVES } from '../../../api/driveSales/outcomesAPI';
import Loader from '../../../components/Loader';
import { useQuery } from '@apollo/client';

const Container = styled('div')({
  width: '100%',
  margin: '0px auto 0px auto',
  overflow: 'hidden',
  paddingBottom: 120,
});

const Header = styled('div')({
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});

const PageBody = styled('div')({
  padding: '0px 23px',
});

export const Outcomes = () => {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<any>({ label: 'All', value: null });
  const [selectedJourney, setSelectedJourney] = useState<any>({ label: 'All', value: null });
  const [journeyOptions, setJourneyOptions] = useState<any[]>([]);

  useEffect(() => {
    console.log('allJourneyData: ', allJourneyData);
    if (selectedPackage?.value === 'INFLUENCE') {
      const formattedJourneys =
        allJourneyData?.influenceJourneys?.list?.map((journey: any) => {
          return { label: journey?.customName + ' - ' + formatTimestamp(journey?.dateCreated), value: journey?.id };
        }) ?? [];
      setJourneyOptions(formattedJourneys);
    } else if (selectedPackage?.value === 'DISCOVER_LEADS') {
      const formattedJourneys =
        allJourneyData?.discoverLeadJourneys?.list?.map((journey: any) => {
          return { label: journey?.customName + ' - ' + formatTimestamp(journey?.dateCreated), value: journey?.id };
        }) ?? [];
      setJourneyOptions(formattedJourneys);
    } else {
      setJourneyOptions([]);
    }
  }, [selectedPackage]);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const {
    data: allJourneyData,
    loading,
    error,
  } = useQuery(GET_OUTCOME_ARCHIVES, {
    variables: { packageName: selectedPackage?.value, journeyId: selectedJourney?.value },
  });

  const formattedLabels: any = {
    sale: {
      id: 'sales-conversions-card',
      title: 'Sales Conversions',
      label: 'Sales CVR Improvement',
    },
    model: {
      id: 'model-conversions-card',
      title: 'Model Portfolio Conversions',
      label: 'Model Portfolio CVR Improvement',
    },
    email: {
      id: 'email-conversions-card',
      title: 'Email Conversions',
      label: 'Open Rate Improvement',
    },
  };

  const legend = [
    {
      name: 'Leads provided but unscored by AMP',
      color: colors.black,
    },
    {
      name: 'Leads with AMP Scores >75%',
      color: colors.light_purple,
    },
  ];

  const newConversionCards =
    allJourneyData?.outcomes?.conversions.length > 0
      ? allJourneyData?.outcomes?.conversions?.map((conversion: any) => {
          return {
            ...formattedLabels?.[conversion?.label],
            change: conversion?.improvement_percent ? conversion?.improvement_percent : 0,
            chartData: [
              {
                ...conversion,
                control_percent: conversion?.control_percent ? conversion?.control_percent : 0,
                prospects_percent: conversion?.prospects_percent ? conversion?.prospects_percent : 0,
              },
            ],
            legend,
          };
        })
      : [];

  const AmpResults = [
    { label: 'Advisors Influenced', value: allJourneyData?.outcomes?.totalAdvisorsInfluenced },
    { label: 'New Advisor Leads', value: allJourneyData?.outcomes?.totalNewLeads },
    { label: 'Total Events Captured', value: allJourneyData?.outcomes?.totalEvents },
  ];

  const updateFilters = (label: string, value: any) => {
    console.log('update filters: ', label, value);
    if (label === 'Package') {
      setSelectedJourney({ label: 'All', value: null });
      setSelectedPackage(value);
    }
    if (label === 'Journey') setSelectedJourney(value);
  };

  return (
    <Container>
      <Header>
        <SectionPageHeader
          title={'Drive Sales'}
          buttonText={'Zoom Out'}
          buttonAction={() => navigate('/outcomes')}
          subtitle={'Outcomes for'}
          description={'Measure the effectiveness of AMP on your business outcomes.'}
        />
        <PageHeader
          title={'Your Conversion Rates for '}
          // runOptions={['Last 30 Days', 'Last 90 Days', 'Last 180 Days', 'Last 365 Days']}
          customFilters={
            !!selectedPackage?.value
              ? [
                  {
                    label: 'Package',
                    options: [
                      { label: 'All', value: null },
                      { label: 'Influence', value: 'INFLUENCE' },
                      { label: 'Discover New Leads', value: 'DISCOVER_LEADS' },
                    ],
                    value: selectedPackage,
                    width: '190px',
                  },
                  {
                    label: 'Journey',
                    options: [{ label: 'All', value: null }, ...journeyOptions],
                    value: selectedJourney,
                    width: '340px',
                  },
                ]
              : [
                  {
                    label: 'Package',
                    options: [
                      { label: 'All', value: null },
                      { label: 'Influence', value: 'INFLUENCE' },
                      { label: 'Discover New Leads', value: 'DISCOVER_LEADS' },
                    ],
                    value: selectedPackage,
                    width: '190px',
                  },
                ]
          }
          rightButtons={[
            {
              modalText: 'Download Report',
              // type: 'light',
              action: toggleComingSoon,
            },
          ]}
          updateFilters={updateFilters}
        />
      </Header>
      <PageBody>
        {loading ? (
          <Loader />
        ) : allJourneyData?.outcomes?.totalAdvisorsInfluenced === 0 ? (
          <CardContainer style={{ padding: '44px 0px' }}>
            <CardBody style={{ padding: '11px 22px' }}>
              <H2>No data available</H2>
              <F3 style={{ margin: '8px 0px 0px', color: colors.grey_02 }}>
                For more information please talk to your customer support representative.
              </F3>
            </CardBody>
          </CardContainer>
        ) : (
          <>
            <Row style={{}}>
              {newConversionCards?.map((card: any, index: number) => (
                <CardContainer id={card?.id} style={{ margin: index === 1 ? '24px 24px 8px' : '24px 0px 8px' }}>
                  <CardHeader>{card?.title}</CardHeader>
                  <CardBody style={{ padding: '11px 22px' }}>
                    <F3 style={{ margin: '0px', color: colors.grey_02 }}>{card?.label}</F3>
                    <Title style={{ fontSize: '70px', fontWeight: '700', color: colors.grey_03, margin: '26px 0px' }}>
                      {card?.change > 0 ? '+' : ''}
                      {card?.change}
                      <span style={{ fontSize: '48px' }}>%</span>
                    </Title>
                    <ResponsiveContainer width={'98%'} height={320} id={'bubble-chart-data'}>
                      {/* @ts-ignore */}
                      <BarChart
                        margin={{ left: 40, right: 40, top: 40, bottom: 20 }}
                        height={200}
                        data={card?.chartData}
                      >
                        {/* <Tooltip /> */}
                        <XAxis
                          dataKey={'score'}
                          tick={{
                            ...typography.mainFont,
                            // color: '#758790',
                            fontSize: '10px',
                            fontWeight: 400,
                            fill: '#758790',
                          }}
                          stroke={colors.grey_01}
                          interval={0}
                        />
                        <Bar isAnimationActive={false} dataKey={'control_percent'} fill={colors.black}>
                          <LabelList
                            position={'top'}
                            offset={12}
                            style={{ ...typography.mainFont, fontSize: '14px', fontWeight: 500, fill: colors.grey_03 }}
                            formatter={(value: any) => {
                              return value === 0 ? '' : value + '%';
                            }}
                          />
                          <LabelList
                            position={'bottom'}
                            style={{
                              ...typography.mainFont,
                              fontSize: '11px',
                              fill: colors.grey_02,
                              // margin: '16px 0px',
                              width: '20px',
                            }}
                            offset={12}
                            formatter={(value: any) => {
                              return 'Control Group';
                            }}
                          />
                        </Bar>
                        <Bar isAnimationActive={false} dataKey={'prospects_percent'} fill={colors.light_purple}>
                          <LabelList
                            position={'top'}
                            offset={12}
                            style={{ ...typography.mainFont, fontSize: '14px', fontWeight: 500, fill: colors.grey_03 }}
                            formatter={(value: any) => {
                              return value === 0 ? '' : value + '%';
                            }}
                          />
                          <LabelList
                            position={'bottom'}
                            style={{
                              ...typography.mainFont,
                              fontSize: '11px',
                              fill: colors.grey_02,
                              // padding: '16px 0px',
                              width: '10px',
                            }}
                            offset={12}
                            formatter={(value: any) => {
                              return 'AMP Prospects';
                            }}
                          />
                        </Bar>
                        <ReferenceLine
                          y={card?.chartData[0]?.control_percent}
                          stroke={colors.black}
                          strokeWidth={2}
                          label=" "
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  </CardBody>
                </CardContainer>
              ))}
            </Row>
            <Row>
              <CardContainer>
                <CardHeader>AMP Results Dashboard</CardHeader>
                <CardBody style={{ padding: '11px 0px' }}>
                  <Row>
                    {AmpResults.map((result, index) => (
                      <Column
                        style={{
                          flex: 1,
                          border: index === 1 ? `1px solid ${colors.grey_01}` : '0px solid',
                          borderTop: '0px solid',
                          borderBottom: '0px solid',
                          padding: '0px 22px 120px',
                          margin: '16px 0px',
                        }}
                      >
                        <F3 style={{ color: colors.grey_02 }}>{result?.label}</F3>
                        <Title
                          style={{
                            float: 'right',
                            fontWeight: 700,
                            fontSize: '70px',
                            color: colors.grey_03,
                            margin: '24px 0px 0px',
                          }}
                        >
                          {formatNumber(result?.value ?? null)}
                        </Title>
                      </Column>
                    ))}
                  </Row>
                </CardBody>
              </CardContainer>
            </Row>
          </>
        )}
      </PageBody>
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Container>
  );
};
