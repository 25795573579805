import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  TextField,
  Popper,
  Fade,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import Modal from '@mui/material/Modal';
import { ButtonUnstyled as Button } from '@mui/core';
import ComingSoonModal from '../ComingSoonModal';
import {
  formatDollar,
  formatMillionDollar,
  formatNumber,
  formatAdvisorType,
  formatFullName,
} from '../../helpers/dataFormatter';
import { ABR_TO_STATES_MAP } from './stateMap';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ThemeChip from '../ThemeChip';
import DetailedTooltip from '../DetailedTooltip';
import info from '../../assets/icons/info.svg';
import closeIcon from '../../assets/icons/close-icon.svg';
import _ from 'lodash';
// import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerStyle: {
    fontSize: 30,
    fontWeight: 500,
    textAlign: 'center',
    color: '#404B50',
    width: '100%',
  },
  subHeader: {
    color: '#758790',
    fontSize: 18,
    fontWeight: 400,
    width: 'fit-content',
    maxWidth: 500,
    textAlign: 'center',
  },
  container: {
    width: '100%',
    margin: 'auto',
    display: 'table',
    padding: '0px 0px 40px 0',
  },
  modalContainer: {
    width: '55%',
    margin: '20px auto',
    background: '#fff',
    minHeight: 200,
    borderRadius: '5px',
    boxShadow: '0px 0px 25px 0px rgba(63, 106, 194, 0.15)',
    padding: '0 20px 20px 20px',
    overflowY: 'auto',
  },
  upperContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 14px 0px 14px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 0px 0px 0px',
  },
  nameText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    color: '#404B50',
    margin: '0px 0px 0px 0px',
  },
  firmName: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    color: '#404B50',
    margin: '12px 0px 0px 0px',
  },
  firmInfoContainer: {
    display: 'flex',
    margin: '0px 16px 16px 16px',
    alignItems: 'center',
    width: '100%',
  },
  tableHeader: {
    display: 'flex',
    margin: '0px 16px 8px 16px',
    padding: '0px 0px 18px 0px',
    borderBottom: `1px solid #ececec`,
    alignItems: 'center',
    width: '97%',
  },
  buttonStyle: {
    display: 'flex',
    padding: '10px 50px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    background: '#242B2F',
    border: `1px solid #242B2F`,
    color: 'white',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      opacity: '0.8',
    },
    '&:disabled': {
      opacity: '0.3',
    },
  },
  cancelButton: {
    display: 'flex',
    padding: '10px 50px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    height: 40,
    background: '#fff',
    border: `1px solid #242B2F`,
    color: '#242B2F',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      background: '#ececec',
    },
  },
  fieldContainer: {
    minHeight: 200,
    width: '100%',
    display: 'flex',
    padding: '0 20px 20px',
  },
  leftFields: {
    width: '96%',
  },
  fieldThemes: {
    width: '96%',
    //height: '40px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '5px -5px',
  },
  noteContainer: {
    width: '60%',
  },
  notes: {
    width: '80%',
  },
  iconStyle: {
    margin: '0px 0px -3px 4px',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  bodyText: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '400',
    color: '#758790',
  },
  dataRow: {
    display: 'flex',
    height: '30px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '5px',
    width: '100%',
  },
  fieldLabel: {
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: '400',
    color: '#758790',
  },
  radioRow: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: '400',
    color: '#758790',
    margin: '-4px 0px',
    '& .MuiFormControlLabel-label': {
      fontSize: '14px',
    },
  },
  fieldDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '280px',
  },
  fieldData: {
    fontFamily: 'Inter',
    fontSize: 14,
    width: '300px',
    fontWeight: '400',
    textAlign: 'right',
    color: '#758790',
    cursor: 'pointer',
  },
  fieldDataClickable: {
    fontFamily: 'Inter',
    fontSize: 14,
    width: '300px',
    fontWeight: '400',
    textAlign: 'right',
    color: '#758790',
    backgroundColor: '#F6F9FB',
    border: `1px solid #fff`,
    height: '24px',
    lineHeight: '24px',
    borderRadius: '5px',
    marginLeft: '16px',
    cursor: 'pointer',
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: '#E4EBEF',
    },
  },
  fieldDataClickableError: {
    fontFamily: 'Inter',
    fontSize: 14,
    width: '300px',
    fontWeight: '400',
    textAlign: 'right',
    color: '#758790',
    backgroundColor: '#F6F9FB',
    border: `1px solid #fd0379`,
    height: '24px',
    lineHeight: '24px',
    borderRadius: '5px',
    marginLeft: '16px',
    cursor: 'pointer',
    padding: '0px 10px',
    '&:hover': {
      backgroundColor: '#E4EBEF',
    },
  },
  editPopper: {
    zIndex: 2000,
  },
  editContentContainer: {
    width: '180px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 0px 25px 0px rgba(63, 106, 194, 0.15)',
  },
  editContentContainerError: {
    display: 'flex',
    width: '210px',
    padding: '4px 16px',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderRadius: '5px',
    boxShadow: '0px 0px 25px 0px rgba(63, 106, 194, 0.15)',
  },
  editTextField: {
    marginBottom: '15px',
    width: '100%',
    color: '#242B2F',
    '& .MuiSelect-select': {
      zIndex: 9000,
      '& .MuiPaper-root': {
        zIndex: 9000,
        background: '#fff',
        boxShadow: '0px 0px 25px 0px rgba(63, 106, 194, 0.15)',
      },
    },
    '& label.Mui-focused': {
      color: '#404B50',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#404B50',
        color: '#404B50',
      },
      '&:hover fieldset': {
        borderColor: '#404B50',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#404B50',
        border: `1px solid #404B50`,
        color: '#242B2F',
      },
    },
  },
  editAdvisorContainer: {
    margin: '-18px 0px 0px 0px',
  },
  editButtonPrimary: {
    width: '100%',
    border: '0px solid',
    marginTop: '20px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#242B2F',
    color: '#fff',
    '&:hover': {
      opacity: 0.8,
    },
  },
  editButtonSecondary: {
    width: '100%',
    border: '0px solid',
    marginTop: '5px',
    height: '40px',
    borderRadius: '5px',
    backgroundColor: '#758790',
    color: '#fff',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));

const advisorTypes = ['BD', 'RIA', 'RIA & BD'];

const fundThemeFields = [{ key: 'themes', label: 'Theme:', type: 'text' }];

const advisorFirmFields = [
  { key: 'firmTotalAccounts', label: 'Firm Accounts', type: 'number' },
  { key: 'firmTotalAssetsInMillions', label: 'Firm Total Assets', type: 'dollar' },
  { key: 'firmEtfAssetsMillions', label: 'Firm Total ETF Assets', type: 'dollar' },
  { key: 'firmMfAssetsMillions', label: 'Firm Total MF Assets', type: 'dollar' },
  { key: 'firmCrd', label: 'Type' },
  { key: 'careerTenure', label: 'Total Years as Advisor', type: 'number' },
  { key: 'aumSelfReported', label: 'Assets Under Management', type: 'dollar' },
];

type Props = {
  modalStatus: any;
  closeModal: any;
  selectedData: any;
  dataType: string;
  // refresh: any;
};

const EditDataModal = ({ modalStatus, closeModal, selectedData, dataType }: Props) => {
  const [notes, setNotes] = useState('');
  const [editFields, setEditFields] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [localData, setLocalData] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();
  const [editField, setEditField] = useState<any>({});
  const [editModalValue, setEditModalValue] = useState('');
  const [selectedThemes, setSelectedThemes] = useState<any>({});
  const [valuesChanged, setValuesChanged] = useState(false);
  const [advisorType, setAdvisorType] = useState<string | null>(null);
  const [editedAdvisorType, setEditedAdvisorType] = useState<string | null>(null);

  const classes = useStyles();

  useEffect(() => {
    setUpLocalData();
  }, [selectedData]);

  useEffect(() => {
    setEditFields(false);
  }, [modalStatus]);

  const defaultThemes = {
    Culture: 0,
    Consumer: 0,
    ESG: 0,
    Financials: 0,
    Healthcare: 0,
    Materials: 0,
    Industrials: 0,
    Energy: 0,
    'Real Estate': 0,
    Tech: 0,
  };

  const resetModal = (selectedItem: any) => {
    if (valuesChanged) {
      saveDataToBackend();
    }
    setOpen(false);
    setAnchorEl(null);
    setValuesChanged(false);
    closeModal(selectedItem);
    setEditField({});
  };

  const saveDataToBackend = async () => {
    let result = null;
    try {
      if (dataType === 'Funds') {
        // console.log('save the following fund: ', selectedData);
        const fundId = [selectedData.id];
        const formattedFundThemes = JSON.stringify(selectedThemes);
        // result = await DataAPI.updateFundsPreferences(fundId, selectedData.hidden, formattedFundThemes);
      } else {
        const isBd = advisorType === 'BD' || advisorType === 'RIA & BD';
        const isRia = advisorType === 'RIA' || advisorType === 'RIA & BD';
        // result = await DataAPI.updateAdvisorPreferences({ ...localData, advisorId: localData.id, isBd, isRia });
      }
      // refresh();
      // console.log('result in saveAllData: ', result);
    } catch (error) {
      console.warn('Error saving data: ', error);
    }
  };

  const setUpLocalData = () => {
    if (dataType === 'Advisors' && selectedData) {
      const copyOfSelectedData = _.cloneDeep(selectedData);
      // console.log('copying selected data: ', copyOfSelectedData);
      setLocalData(copyOfSelectedData);
      setAdvisorType(formatAdvisorType(copyOfSelectedData, copyOfSelectedData.advisordistilldata));
      setEditedAdvisorType(formatAdvisorType(copyOfSelectedData));
    } else if (dataType === 'Funds' && selectedData && selectedData.themes) {
      const parseThemes = JSON.parse(selectedData.themes);
      const newSelectedThemes: any = _.cloneDeep(defaultThemes);
      for (let theme in parseThemes) {
        newSelectedThemes[theme] = parseThemes[theme];
      }
      if (selectedData.excludedTraits) {
        const excludedParsedThemes = JSON.parse(selectedData.excludedTraits);
        for (let theme in excludedParsedThemes) {
          newSelectedThemes[theme] = excludedParsedThemes[theme];
        }
      }
      delete newSelectedThemes[0];
      delete newSelectedThemes[1];
      setSelectedThemes(newSelectedThemes);
    }
  };

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const handleChange = (event: any) => {
    setEditModalValue(event.target.value);
  };

  const toggleFundTheme = (theme: string) => {
    const newSelectedThemes: any = { ...selectedThemes };
    if (newSelectedThemes[theme]) {
      newSelectedThemes[theme] = 0;
    } else {
      newSelectedThemes[theme] = 100;
    }
    setSelectedThemes(newSelectedThemes);
    setValuesChanged(true);
  };

  const saveDataValue = (data: any) => {
    if (data.label === 'Type') {
      setAdvisorType(editedAdvisorType);
    } else {
      localData[data.key] = formatValueToSave(editModalValue, data?.type, data?.key);
    }
    setOpen(!open);
    setValuesChanged(true);
  };

  const openEditPopper = (newPlacement: any, fieldToEdit: any, uneditable: any) => (event: any) => {
    if (open) {
      closeEditPopper();
    } else {
      // console.log('handling click!', newPlacement);
      setEditField({ ...fieldToEdit, uneditable });
      setAnchorEl(event.currentTarget);
      setEditModalValue(
        localData[fieldToEdit.key]
          ? formatValueToEdit(localData[fieldToEdit.key], fieldToEdit.type, fieldToEdit.key)
          : formatValueToEdit(selectedData[fieldToEdit.key], fieldToEdit.type, fieldToEdit.key),
      );
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    }
  };

  const formatValueToEdit = (value: any, type: string, key: string) => {
    if (type === 'dollar' && key !== 'aumSelfReported') {
      return value * 1000000;
    } else {
      return value;
    }
  };

  const formatValueToSave = (value: any, type: any, key: any) => {
    if (type === 'dollar' && key !== 'aumSelfReported') {
      return value / 1000000;
    } else {
      return value;
    }
  };

  const closeEditPopper = () => {
    setOpen(!open);
    if (editedAdvisorType !== advisorType) {
      setEditedAdvisorType(advisorType);
    }
    setTimeout(() => setEditField({}), 300);
  };

  const formatCurrentFirmTenure = (numOfYears: number) => {
    //TODO make this work
    // console.log('num of years: ', numOfYears);
    if (numOfYears) {
      var date = new Date();
      date.setDate(date.getDate() - 6);
      date.setFullYear(date.getFullYear() - numOfYears);
      //'#searchDateFrom'.val(date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear());
      return `${date.getFullYear()} - Present`;
    }
  };

  //Field mapper layout component
  const fieldMapper = (fields: any) => {
    let empty = 0;

    if (selectedData.email) {
      return (
        <div className={classes.leftFields}>
          {fields.map((row: any, index: number) => {
            return (
              <div className={classes.dataRow} key={index + 2423581}>
                <p className={classes.fieldLabel}>{row.label}</p>
                <div className={classes.fieldDataContainer}>
                  <p
                    key={index + 324324}
                    className={
                      editField.key === row.key && editField.uneditable
                        ? classes.fieldDataClickableError
                        : classes.fieldDataClickable
                    }
                    onClick={openEditPopper('bottom-start', row, true)}
                  >
                    {selectedData[row.key]
                      ? row.key === 'aumSelfReported'
                        ? formatDollar(selectedData.advisordistilldata[row.key])
                        : row.type === 'dollar'
                        ? formatMillionDollar(selectedData.advisordistilldata[row.key])
                        : row.key === 'firmCrd'
                        ? formatAdvisorType(selectedData.advisordistilldata)
                        : row.type === 'number'
                        ? formatNumber(selectedData.advisordistilldata[row.key])
                        : selectedData.selectedData.advisordistilldata[row.key]
                      : '-'}
                  </p>
                  <p
                    key={index + 242305225}
                    className={classes.fieldDataClickable}
                    onClick={openEditPopper('bottom-start', row, false)}
                  >
                    {localData[row.key]
                      ? selectedData.advisordistilldata[row.key] === localData[row.key]
                        ? '-'
                        : row.key === 'aumSelfReported'
                        ? formatDollar(localData[row.key])
                        : row.type === 'dollar'
                        ? formatMillionDollar(localData[row.key])
                        : row.key === 'firmCrd'
                        ? advisorType
                        : row.type === 'number'
                        ? formatNumber(localData[row.key])
                        : localData[row.key]
                      : '-'}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (selectedData.ticker) {
      return (
        <div className={classes.fieldThemes}>
          {Object.keys(selectedThemes).map((theme, index) => (
            <ThemeChip
              key={index + 222324234}
              themeName={theme}
              selected={!!selectedThemes?.[theme]}
              onClick={toggleFundTheme}
            />
          ))}
        </div>
      );
    } else return <p className={classes.fieldData}>No Data to Show</p>;
  };

  return (
    <>
      <Modal
        open={modalStatus}
        onClose={resetModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        className={classes.root}
        id={`data-profile-${dataType}`}
      >
        <div className={classes.modalContainer}>
          <Popper className={classes.editPopper} open={open} anchorEl={anchorEl} placement={placement} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                {editField.uneditable ? (
                  <div className={classes.editContentContainerError}>
                    <img src={info} />
                    <p className={classes.fieldLabel}>Enriched data is not editable</p>
                  </div>
                ) : (
                  <div className={classes.editContentContainer}>
                    {editField.label === 'Type' ? (
                      <div className={classes.editAdvisorContainer}>
                        <p className={classes.fieldLabel} style={{ fontSize: '16px' }}>
                          Advisor Type
                        </p>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={editedAdvisorType}
                          onChange={(event) => setEditedAdvisorType(event.target.value)}
                        >
                          {advisorTypes.map((type, index) => (
                            <FormControlLabel
                              className={classes.radioRow}
                              value={type}
                              key={index}
                              control={
                                <Radio
                                  sx={{
                                    fontSize: '12px',
                                    color: '#758790',
                                    '&.Mui-checked': {
                                      color: '#404B50',
                                    },
                                  }}
                                />
                              }
                              label={type}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    ) : (
                      <TextField
                        id="outlined-select-currency"
                        className={classes.editTextField}
                        label={editField?.label}
                        size="small"
                        type={editField?.type}
                        // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        value={editModalValue}
                        onChange={(event) => setEditModalValue(event.target.value)}
                        // MenuProps={{
                        //   sx: { zIndex: 95001 },
                        // }}
                        sx={{ zIndex: 50000 }}
                        // InputProps={{
                        //   inputComponent: NumberFormatCustom,
                        // }}
                      />
                    )}
                    <Button className={classes.editButtonPrimary} onClick={() => saveDataValue(editField)}>
                      Save
                    </Button>
                    <Button className={classes.editButtonSecondary} onClick={closeEditPopper}>
                      Cancel
                    </Button>
                  </div>
                )}
              </Fade>
            )}
          </Popper>
          <div className={classes.upperContainer}>
            <div className={classes.header}>
              <p className={classes.nameText}>
                {selectedData.lastName
                  ? formatFullName(selectedData)
                  : selectedData.ticker
                  ? selectedData.ticker
                  : 'Data not available'}
              </p>
              <IconButton onClick={resetModal} id={'close-button'}>
                <img src={closeIcon} />
              </IconButton>
            </div>
            {selectedData.firmName ? <p className={classes.firmName}>{selectedData.firmName} </p> : null}
            {selectedData.ticker ? <p className={classes.firmName}>{selectedData.name} </p> : null}
            {selectedData.title ? <p className={classes.bodyText}>{selectedData.title} </p> : null}
            {ABR_TO_STATES_MAP?.[selectedData?.officeState as keyof typeof ABR_TO_STATES_MAP] ?? ''}
            {!selectedData.officeState && selectedData.email ? (
              <p className={classes.bodyText}>{selectedData.email}</p>
            ) : null}
          </div>
          <div className={classes.tableHeader}>
            <div className={classes.dataRow}>
              <p className={classes.fieldLabel}>{selectedData.ticker ? `This fund's themes ` : 'Metric'}</p>
              <div className={classes.fieldDataContainer}>
                {selectedData.ticker ? (
                  <p className={classes.fieldData}></p>
                ) : (
                  <p className={classes.fieldData}>
                    Enriched Data
                    <DetailedTooltip
                      headerText={'How Enriched Data Works'}
                      bodyText={
                        'We enrich your Advisors with data from the AMP Data Lake. If data is not available on an advisor, try editing their email address or mailing address to get more data. '
                      }
                    >
                      <img className={classes.iconStyle} src={info} alt="info" />
                    </DetailedTooltip>
                  </p>
                )}
                {selectedData.ticker ? (
                  <p className={classes.fieldData}> </p>
                ) : (
                  <p className={classes.fieldData}>Supplied Data</p>
                )}
              </div>
            </div>
          </div>
          <div className={classes.firmInfoContainer}>
            {fieldMapper(dataType === 'Advisors' ? advisorFirmFields : fundThemeFields)}
          </div>
          {false ? (
            <div className={classes.noteContainer}>
              <h4 style={{ margin: '0 0 10px 0' }}>Notes</h4>
              <TextField
                className={classes.notes}
                id="outlined-multiline-flexible"
                placeholder="Start note..."
                multiline
                value={notes}
                variant="outlined"
                onChange={handleChange}
                rows={6}
              />
            </div>
          ) : null}
          {editFields && (
            <div
              style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0px 30px 0px', width: '430px' }}
            >
              <Button className={classes.buttonStyle} children="Save Changes" onClick={toggleComingSoon} />
              <Button
                className={classes.cancelButton}
                children="Discard Changes"
                onClick={() => setEditFields(false)}
              />
            </div>
          )}
        </div>
      </Modal>
      <ComingSoonModal
        modalStatus={showComingSoon}
        closeModal={toggleComingSoon}
        custom={'This functionality is currently being built, please check back soon for updates'}
      />
    </>
  );
};

export default EditDataModal;
