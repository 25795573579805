import React from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { colors } from '../../styles';

type Props = {
  element?: any;
  label: string;
};

const Container = styled('div')(`
  width: 100%;
  height: 100vh;
  display: table;
  overflowY: auto;
  margin: 0;
`);

const HeaderBlock = styled('div')(`
  width: 100%;
  background: ${colors.gradient_01};
  height: 60px;
  margin: 0;
`);

const HeaderText = styled('h2')(`
  width: 95%;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: -0.09em;
  color: #242B2F;
  padding: 16px 0 0;
  margin: 0 auto;
`);

export const Settings = ({ label }: Props) => {
  const link = window.location.pathname;
  const navigate = useNavigate();

  React.useEffect(() => {
    if (link === '/profile') {
      navigate('/profile/firm-details');
    } else if (link === '/integrations') {
      navigate('/integrations/crm');
    }
  });

  return (
    <Container>
      <HeaderBlock>
        <HeaderText>{label}</HeaderText>
      </HeaderBlock>
      <Outlet />
    </Container>
  );
};
