import React, { useState, useEffect } from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  ReferenceArea,
} from 'recharts';
import { styled } from '@mui/material';
import { formatNumber } from '../../helpers/dataFormatter';
import { colors, typography } from '../../styles';
import { testData } from './testData';
import moment from 'moment';

const TooltipContainer = styled('div')({
  background: '#242B2F',
  color: 'white',
  borderRadius: 5,
  width: '260px',
  padding: '10px 20px',
  boxShadow: '0 0 0.4em #797D83',
  textAlign: 'left',
});

const TooltipName = styled('p')({
  fontSize: 12,
  fontWeight: 500,
  margin: '0px 0 0px',
});

const TooltipValue = styled('p')({
  fontSize: 12,
  fontWeight: 400,
  margin: '0px 0 0px',
  color: colors.grey_02,
});

const Divider = styled('div')({
  borderTop: `1px solid ${colors.grey_02}`,
  margin: '8px 0px',
});

const TooltipScore = styled('div')({
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'space-between',
});

interface Props {
  campaign?: any;
  height?: string;
}

const AmpScoreChart = ({ campaign, height }: Props) => {
  const [chartData, setChartData] = useState(testData);
  const [referenceArea1, setReferenceArea1] = useState<any>(null);
  const [referenceArea2, setReferenceArea2] = useState<any>(null);
  const [xTicks, setXTicks] = useState<any>([]);
  // const [chartData, setChartData] = useState<null | any>(null);

  useEffect(() => {
    generateDataForCampaign();
  }, [campaign]);

  const generateDataForCampaign = () => {
    console.log('campaign for amp score chart: ', campaign);
    const today = moment();
    const emailSendDate = campaign?.dateCreated;
    const emailEndDate = moment(emailSendDate).add(7, 'days').isAfter(today)
      ? today
      : moment(emailSendDate).add(7, 'days');
    const graph_start = moment(emailSendDate).subtract(7, 'days').unix();
    const start = moment(emailSendDate).unix();
    const end = moment(emailEndDate).unix();
    const graph_end = moment(emailEndDate).add(7, 'days').unix();
    const newChartData = [
      {
        index: 0,
        timestamp: graph_start,
        volume: campaign?.previousAverageScore ? campaign?.previousAverageScore : 50,
      },
      { index: 0, timestamp: start, volume: campaign?.previousAverageScore ? campaign?.previousAverageScore : 50 },
      { index: 0, timestamp: end, volume: campaign?.currentAverageScore ? campaign?.currentAverageScore : 50 },
      { index: 0, timestamp: graph_end, volume: campaign?.currentAverageScore ? campaign?.currentAverageScore : 50 },
    ];
    setXTicks([graph_start + 100000, start, end, graph_end - 100000]);
    setReferenceArea1([graph_start + 100000, start]);
    setReferenceArea2([end, graph_end - 100000]);
    setChartData({
      domain: [graph_start, graph_end],
      range: [0, 160],
      dataPoints: newChartData,
    });
    // console.log('start and end for generateCampaign data: ', start, end);
  };

  const CustomTooltip = ({ payload, active }: any) => {
    if (active && payload && payload.length) {
      const emailName = campaign?.nurtureemailSet?.[0]?.name ? campaign?.nurtureemailSet?.[0]?.name : 'No Name Found';
      return (
        <TooltipContainer>
          <TooltipValue>{moment(payload?.[0]?.payload?.timestamp * 1000).format('ll')}</TooltipValue>
          <TooltipName>Email</TooltipName>
          <TooltipValue>{emailName}</TooltipValue>
          <Divider />
          <TooltipScore>
            <span>AMP Score</span>
            <span>{formatNumber(payload?.[0]?.value)}%</span>
          </TooltipScore>
        </TooltipContainer>
      );
    }

    return null;
  };

  const formatTimeString = (timeNumber: string) => {
    const newTime = new Date(Number(timeNumber) * 1000);
    return newTime.toLocaleDateString([], { month: 'numeric', day: 'numeric' });
  };

  const CustomLabel = (props: any) => {
    const { viewBox } = props;
    const { x } = viewBox;

    return (
      <>
        <circle cx={x} cy="290" r="9" fill="#252C30" />
        <text cx={x} cy={'290'} stroke={colors.grey_03}>
          E
        </text>
        <path
          d="M0.892401 8V0.727273H5.28161V1.50852H1.77308V3.96591H5.05433V4.74716H1.77308V7.21875H5.33842V8H0.892401Z"
          fill="white"
          transform={`translate(${x - 3}, 286)`}
        />
      </>
    );
  };

  return (
    <ResponsiveContainer width={'99%'} height={height ? height : 320} id={'bubble-chart-data'}>
      {/* @ts-ignore */}
      <LineChart
        margin={{ top: 20, left: 20, right: 20 }}
        data={chartData?.dataPoints ? chartData.dataPoints : testData.dataPoints}
      >
        <CartesianGrid fill={colors.grey_00} strokeWidth={1} stroke={colors.white_00} strokeDasharray={'1'} />
        <XAxis
          type="number"
          dataKey={'timestamp'}
          domain={chartData.domain}
          tickFormatter={(tick) => formatTimeString(tick)}
          tick={{
            fill: colors.grey_02,
            fontSize: 12,
            ...typography.mainFont,
            //@ts-ignore
            textAlign: 'left',
            width: '60px',
          }}
          ticks={xTicks}
          tickLine={false}
          axisLine={{ stroke: colors.white_01 }}
        />
        <YAxis
          axisLine={{ stroke: colors.white_01 }}
          tick={{
            fill: colors.grey_02,
            fontSize: 12,
            ...typography.mainFont,
            //@ts-ignore
            textAlign: 'left',
            width: '60px',
          }}
          ticks={[0, 20, 40, 60, 80, 100]}
          // padding={{ left: 10, right: 10 }}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
        <Line
          dataKey="volume"
          stroke={colors.dark_purple}
          strokeWidth={1}
          dot={(props) => {
            return <svg />;
          }}
          activeDot={(props: any) => {
            return (
              <>
                <circle cx={props.cx} cy={props.cy} r="3.5" fill="#252C30" />
                <circle cx={props.cx} cy={props.cy} r="11" stroke="#252C30" fill="None" />
              </>
            );
          }}
        />
        <ReferenceArea x1={referenceArea1?.[0]} x2={referenceArea1?.[1]} fill={colors.grey_01} />
        <ReferenceArea x1={referenceArea2?.[0]} x2={referenceArea2?.[1]} fill={colors.grey_01} />
        {campaign?.nurtureemailSet?.map((email: any) => {
          console.log('email in campaign: ', email);
          return (
            <ReferenceLine
              x={moment(email.sendDatetime).unix()}
              label={<CustomLabel value={'Start'} />}
              stroke={colors.black_01}
            />
          );
        })}
        {/* <ReferenceLine
          x={chartData?.domain?.[0] + 200}
          label={<CustomLabel value={'End'} />}
          stroke={colors.black_01}
        /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AmpScoreChart;
