import React from 'react';
import { useState, useEffect, useLayoutEffect } from 'react';
import { colors } from '../../styles';
import { DistributionStepType } from '../../types';
import { styled } from '@mui/material/styles';
import { useStoreState } from '../../store';
import { useStoreActions } from '../../store';
import { LplWheelCopy, LplWheelSections } from '../../components/DistributionWheel/LplSectionImages';
import { DefaultWheelSections, DefaultWheelCopy } from '../../components/DistributionWheel/SectionImages';
import SalesFunnel from '../../components/SalesFunnel';

type Props = {};

export const PageContainer = styled('div')({
  display: 'flex',
  flex: 1,
  height: '100vh',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  background: colors.gradient_01,
});

const BackgroundImage = styled(`img`)({
  margin: '-120px 220px 0px 0px',
  position: 'absolute',
  flex: 1,
});

export const Home = (props: Props) => {
  const [loading, setLoading] = useState<Boolean>(true);
  const appType = useStoreState((state) => state.appType);

  const screenSize = useStoreState((state) => state.screenSize);
  const setScreenSize = useStoreActions((actions) => actions.setScreenSize);

  //  console.log('screenSize', screenSize);

  useLayoutEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 1550) setScreenSize('sm');
      else if (window.innerWidth > 1850) setScreenSize('lg');
      else if (screenSize !== 'md') setScreenSize('md');
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  return (
    <PageContainer>
      {/* {appType === 'LPL' && (
        <BackgroundImage
          sx={screenSize === 'sm' ? { margin: '-120px 230px 0px 0px' } : { margin: '-200px 260px 0px 0px' }}
          src={Lpl_Background}
        />
      )} */}
      <SalesFunnel />
    </PageContainer>
  );
};
