import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import SubscriptionCard from '../../components/SubscriptionCards';
import { F1, H2, F4, Row } from '../../components';
import { useStoreState } from '../../store';
import ComingSoonModal from '../../components/ComingSoonModal';
import { colors, typography } from '../../styles';
import ContactUsModal from '../../components/ContactUsModal';

type Props = {};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: table;
`);

const PageHeader = styled('div')(`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 100%;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding-bottom: 20px;
`);

const SectionHeader = styled('div')(`
    ${typography.main_font};
    display: flex;
    justify-content: space-between;
    font-size: 16px
    font-weight: 500px;
    color: #404B50;
    margin: 20px 20px 20px 0px;
    width: 150px;
`);

const RowContainer = styled('div')(`
    width: 100%;
    margin: 0px auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid #ececec;
`);

const ComingSoonCard = styled('div')(`
    border-radius: 5px;
    background: #fff;
    flex:1;
    padding: 20px 20px;
    margin: 20px 0px 0px 0px;
    cursor: pointer;
    transition: 1s;
    border: 1px solid #C0C0C0;
    min-height: 220px;
    &:hover {
        box-shadow: 0px 0px 25px rgba(63, 106, 194, 0.35);
    }
`);

export const Subscriptions = (props: Props) => {
  const [showComingSoon, setShowComingSoon] = useState(false);
  const subscriptions: any = useStoreState((state) => state.user.subscriptions);
  const [interestRequest, setInterestRequest] = useState<any>(null);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const toggleInterestModal = (packageOfInterest: any) => {
    if (interestRequest) {
      setInterestRequest(null);
    } else {
      setInterestRequest(packageOfInterest);
    }
  };

  const driveSalesPackage = [
    {
      title: 'Influence Prospects',
      key: 'INFLUENCE',
      price: 9999,
      active: subscriptions.includes('INFLUENCE'),
      // link: '/generate/advisor-leads/home',
      description:
        'This package enriches your existing prospects with unique intelligence to improve your sales opportunities.',
      requestType: 'Subscription Request',
      requestNote: 'User would like to activate Influence Prospects',
      action: toggleInterestModal,
    },
    {
      title: 'Discover New Leads',
      key: 'DISCOVER_LEADS',
      active: subscriptions.includes('DISCOVER_LEADS'),
      // price: 9999,
      // link: '/generate/waitlist/home',
      description: `This package identifies new leads by comparing their interests to your fund's thematic exposures.`,
      requestType: 'Subscription Request',
      requestNote: 'User would like to activate the Prioritize Advisors',
      action: toggleInterestModal,
    },
  ];

  return (
    <Container>
      <PageHeader>Subscriptions</PageHeader>
      <RowContainer>
        <SectionHeader>Data Partners</SectionHeader>
        <ComingSoonCard>
          <H2>Coming Soon</H2>
          <F4 sx={{ color: colors.grey_02 }}>Please check back in the future.</F4>
        </ComingSoonCard>
      </RowContainer>
      <Row style={{ padding: '20px 0px 20px', borderBottom: `1px solid ${colors.grey_01}` }}>
        <F1 style={{ width: '160px' }}>Drive Sales</F1>
        {driveSalesPackage?.map((cardData: any, index: number) => (
          <SubscriptionCard cardData={cardData} buttonCopy={'Activate Package'} />
        ))}
      </Row>
      <ContactUsModal
        modalStatus={interestRequest}
        closeModal={toggleInterestModal}
        requestNote={interestRequest?.requestNote}
        requestType={interestRequest?.requestType}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </Container>
  );
};
