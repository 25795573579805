import { Action, action } from 'easy-peasy';

export interface EmailInputType {
  // State Reserved for  Advisor Leads Package
  id: number;
  campaign: string; //type
  name: string;
  description: string;
  html: string;
  sendDatetime: string;
}

export interface CreateCampaignModel {
  // State Reserved for  Advisor Leads Package
  runId: number;
  progress: number;
  progressTitles: Array<string>;
  campaignInfo: any;
  emailArray: Array<any>;

  setRunId: Action<CreateCampaignModel, any>;
  setProgress: Action<CreateCampaignModel, any>;
  setCampaignInfo: Action<CreateCampaignModel, any>;
  setEmailArray: Action<CreateCampaignModel, any>;
  setProgressTitles: Action<CreateCampaignModel, any>;
}

export const createCampaign: CreateCampaignModel = {
  runId: 0,
  progress: 0,
  progressTitles: ['', '', '', ''],
  emailArray: [],
  campaignInfo: {},

  setRunId: action((state, payload) => {
    state.runId = payload;
  }),
  setProgress: action((state, payload) => {
    state.progress = payload;
  }),
  setCampaignInfo: action((state, payload) => {
    state.campaignInfo = payload;
  }),
  setEmailArray: action((state, payload) => {
    state.emailArray = payload;
  }),
  setProgressTitles: action((state, payload) => {
    state.progressTitles = payload;
  }),
};
