import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';
import { colors } from '../../styles';
import { CloseButton, Column, PrimaryButton, Row } from '..';
import Loader from '../Loader';
import closeIcon from '../../assets/icons/close.svg';

const ModalContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  flexDirection: 'column',
  width: '530px',
  //   height: '250px',
  backgroundColor: colors.white_01,
  //paddingBottom: 120,
  borderRadius: '5px',
});

const HeaderStyle = styled('h1')({
  fontSize: '18px',
  fontWeight: 500,
  textAlign: 'left',
  color: '#404B50',
  marginTop: '16px',
});

const SubHeader = styled('p')({
  color: '#758790',
  fontSize: 14,
  fontWeight: 400,
  //width: 'fit-content',
  maxWidth: 500,
  textAlign: 'center',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '300px',
  marginBottom: '24px',
});

type PageCopy = {
  header: string;
  subheader?: string;
  body?: any;
  loader?: boolean;
};

interface Props {
  modalStatus: boolean;
  closeModal: any;
  modalCopy: PageCopy;
}

const InfoModal = ({ modalStatus, closeModal, modalCopy }: Props) => {
  const { header, subheader, body, loader } = modalCopy;

  return (
    <Modal
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ModalContainer>
        <div>
          <Row
            style={{
              borderBottom: `1px solid ${colors.grey_01}`,
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 19px 0px 24px',
              width: '530px',
            }}
          >
            <HeaderStyle>{header}</HeaderStyle>
            <CloseButton style={{ height: '24px', width: '24px' }} onClick={closeModal}>
              <img src={closeIcon} />{' '}
            </CloseButton>
          </Row>
          <Column
            style={{
              padding: '0px 32px 20px 24px',
            }}
          >
            {loader && <Loader inputMargin={'8px auto 4px'} />}
            {subheader && <SubHeader>{subheader}</SubHeader>}
            {body && body()}
          </Column>
        </div>
      </ModalContainer>
    </Modal>
  );
};

export default InfoModal;
