export const testData = {
  domain: [1661384700000, 1661429700000],
  range: [0, 100],
  dataPoints: [
    { index: 0, timestamp: 1661384700000, volume: 50 },
    { index: 0, timestamp: 1661384800000, volume: 50 },
    { index: 0, timestamp: 1661384900000, volume: 50 },
    { index: 0, timestamp: 1661385600000, volume: 50 },
    { index: 0, timestamp: 1661385800000, volume: 60 },
    { index: 0, timestamp: 1661386500000, volume: 61 },
    { index: 0, timestamp: 1661417100000, volume: 65 },
    { index: 0, timestamp: 1661418000000, volume: 65 },
    { index: 0, timestamp: 1661418900000, volume: 67 },
    { index: 0, timestamp: 1661419800000, volume: 71 },
    { index: 0, timestamp: 1661420700000, volume: 71 },
    { index: 0, timestamp: 1661421600000, volume: 71 },
    { index: 0, timestamp: 1661422500000, volume: 71 },
    { index: 0, timestamp: 1661423400000, volume: 78 },
    { index: 0, timestamp: 1661424300000, volume: 78 },
    { index: 0, timestamp: 1661425200000, volume: 82 },
    { index: 0, timestamp: 1661426100000, volume: 82 },
    { index: 0, timestamp: 1661427000000, volume: 82 },
    { index: 0, timestamp: 1661427900000, volume: 94 },
    { index: 0, timestamp: 1661428800000, volume: 94 },
    { index: 0, timestamp: 1661429700000, volume: 94 },
  ],
};
