import React from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';
import { colors } from '../../styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logoutIcon from '../../assets/icons/logoutIcon.svg';
import navLogo from '../../assets/logos/nav-logo.png';
import lplNavLogo from '../../assets/logos/lpl-nav-logo.png';
import { MenuButton } from './MenuButton';
import Tooltip from '@mui/material/Tooltip';
import { useStoreState } from '../../store';
import { formatCamelCase, formatSnakeCase } from '../../helpers/dataFormatter';

type Props = {
  sectionMenuOptions: any;
  menuOptions: any;
};

const fade = keyframes`
   from {
     opacity: 0;
   }
   to {
     opacity: 1;
   }
`;

const Root = styled('div')(`
  position: fixed;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 17px 0px;
  background: ${colors.white_03};
  border-right:1px solid ${colors.grey_01}
`);

const NavContainer = styled('div')`
  border-top: 1px solid ${colors.grey_01};
  display: flex;
  flex-direction: row;
  padding: 10px 0 0;
  height: 100%;
`;

const ParentNavContainer = styled('div')`
  width: 60px;
  padding: 0px 10px;
`;

const SectionNavContainer = styled('div')`
  background: ${colors.white_00};
  width: 100%;
`;

const MenuContainer = styled('div')(`
    padding: 0px;
    margin-top: -8px;
`);

const LogoContainer = styled('div')(`
    display: flex;
    margin: 0px 16px 16px 0px;
    align-items: center;
    justify-content: center;
    flex: 1;
`);

const MainLogo = styled('img')(`
    display: flex;
    // margin: 0px 16px 16px 52px;
    // margin: 0px 16px 16px 20px;
    width: 180px;
    height: 28px;
    cursor: pointer;
    object-fit: contain;
    object-position: 0 0;
`);

const ParentMenuButton = styled('button')(`
    display: flex;
    height: 40px;
    width: 40px;
    border: 0px solid;
    margin: 8px 0px;
    background: ${colors.white_03};
    border-radius: 5px;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s;
    :link {
      text-decoration: none;
    };
    :hover {
      opacity:0.4;
    };
`);

const IconContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
`;

const ButtonIcon = styled('img')(`
    height: 30px;
    width: 30px;
    margin: 0 2px 3px 0;
`);

const LogoutButton = styled('button')(`
  position: sticky; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  margin: 20px auto;
  padding: 6px 20px;
  width: 130px;
  background: white;
  cursor: pointer;
  transition: 1s;
  :hover {
    background: #ececec;
  }
`);

export const SectionNav = ({ sectionMenuOptions, menuOptions }: Props) => {
  const logoFile = useStoreState((state: any) => state.user.organization.logoFile);
  const appType = useStoreState((state: any) => state.appType);
  const location = useLocation();
  const navigate = useNavigate();

  const defaultNavLogo = appType === 'LPL' ? lplNavLogo : navLogo;

  const split_path = location.pathname.split('/');

  return (
    <Root sx={{ animation: `${fade} 4s ease` }}>
      <LogoContainer>
        <MainLogo
          src={logoFile && appType !== 'LPL' ? logoFile : defaultNavLogo}
          style={appType === 'LPL' ? { margin: '0px 0px 0px 16px' } : { margin: '0px 0px 0px 52px' }}
          onClick={() => navigate('/home')}
        />
      </LogoContainer>
      <NavContainer>
        <ParentNavContainer>
          <MenuContainer>
            {menuOptions.map((item: any, index: number) => (
              <React.Fragment key={index}>
                {item?.divider && <div style={{ paddingTop: 10, marginTop: 20, borderTop: '1px solid #ececec' }} />}
                <Link
                  id={`menuButton-${menuOptions.link}`}
                  to={item.link}
                  style={{ textDecoration: 'none' }}
                  key={index}
                >
                  <ParentMenuButton sx={sectionMenuOptions.link === item.link ? { background: colors.grey_03 } : {}}>
                    <Tooltip title={item.label} placement="right">
                      <IconContainer>
                        <ButtonIcon
                          id="ButtonIcon"
                          src={sectionMenuOptions.link === item.link ? item.iconSelected : item.icon}
                          sx={item.iconStyle ? { ...item.iconStyle } : {}}
                        />
                      </IconContainer>
                    </Tooltip>
                  </ParentMenuButton>
                </Link>
              </React.Fragment>
            ))}
          </MenuContainer>
        </ParentNavContainer>
        <SectionNavContainer>
          <MenuButton
            id="MenuButton"
            hideIcon
            focused={split_path.length === 2}
            {...sectionMenuOptions}
            sx={{ borderBottom: `1px solid ${colors.grey_01}` }}
          />
          {sectionMenuOptions.children.map((item: any, index: number) => {
            return (
              <MenuButton
                id={`menuButton-${formatCamelCase(item.label)}`}
                key={index}
                focused={location.pathname.includes(item.link)}
                smallText
                {...item}
                style={{ color: location.pathname.includes(item.link) ? 'orange' : 'blue' }}
              />
            );
          })}
          {sectionMenuOptions.showLogout && (
            <LogoutButton onClick={() => navigate('logout')}>
              <img src={logoutIcon} alt="logout" />
              &nbsp; Logout
            </LogoutButton>
          )}
        </SectionNavContainer>
      </NavContainer>
    </Root>
  );
};
