import salesforceLogoPNG from '../logos/Salesforce-Logo.png';
import hubspotLogoPNG from '../logos/Hubspot-Logo.png';
import dynamicsLogo from '../logos/dynamics-logo.png';
import csvLogoPNG from '../logos/CSV-Logo.png';
import sendgridLogo from '../logos/Sendgrid-Logo.png';
import marketoLogo from '../logos/marketo-logo.svg';

const logoMapping: any = {
  MARKETO: marketoLogo,
  DYNAMICS: dynamicsLogo,
  HUBSPOT: hubspotLogoPNG,
  CSV: csvLogoPNG,
  SENDGRID: sendgridLogo,
  SALESFORCE: salesforceLogoPNG,
};

export default logoMapping;
