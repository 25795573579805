import React from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
import Checkbox from '../Checkbox';
import { H4 } from '..';
import FilterMenu from '../FilterMenu';
import eyeball from '../../assets/icons/eyeball.svg';
import dataLinked from '../../assets/icons/data-linked.svg';
import dataNotLinked from '../../assets/icons/data-not-linked.svg';

const BodyLeftContainer = styled('div')({
  width: '24%',
  margin: '0px 20px',
  display: 'flex',
  borderRight: '1px solid #e3e5e4',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const DataInnerLeftContainer = styled('div')({
  width: '100%',
  position: 'relative',
  overflowX: 'auto',
  padding: '0px 0px 0px 0px',
});

const LightHeader = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
  color: '#758790',
  margin: '20px 0 10px',
});

const MultiSelectRow = styled('div')({
  cursor: 'pointer',
  margin: '-1px 0',
  display: 'flex',
  width: '90%',
  '&:hover': {
    opacity: 0.7,
  },
});

const EyeballIcon = styled('img')({
  width: 14,
  margin: '2px 0 0 12%',
});

const LinkedIcon = styled('img')({
  width: 14,
  margin: '2px 10px 0 0',
});

const SelectTitle = styled('div')({
  color: '#404B50',
  fontWeight: 400,
  minWidth: 90,
  fontSize: 14,
  margin: '3px 0 0 10px',
});

interface Props {
  selectOptions: any;
  showLinkedData: boolean;
  selectedToggle: any;
  selectedFilters: any;
  featureSelections: any;
  selectedMetric: any;
  updateFilters: any;
  updateMultipleFilters: any;
  setSelectedFilters: any;
  updateSelectedMetrics: any;
}

export const ChartSelections = ({
  selectOptions,
  showLinkedData,
  selectedToggle,
  selectedFilters,
  selectedMetric,
  featureSelections,
  updateFilters,
  updateMultipleFilters,
  setSelectedFilters,
  updateSelectedMetrics,
}: Props) => {
  return (
    <BodyLeftContainer>
      <DataInnerLeftContainer
        style={{
          height: '100%',
        }}
      >
        <LightHeader>Selected For Algorithm</LightHeader>
        {selectOptions &&
          selectOptions.map((row: any, index: number) => {
            return row.list ? (
              <MultiSelectRow onClick={() => updateSelectedMetrics(row)}>
                <FilterMenu
                  label={row.title}
                  filterOptions={row.options}
                  selectedFilter={selectedFilters[row.title]}
                  updateFilters={updateFilters}
                  multiple={row.multiple}
                  search={row.search}
                  input={row.input}
                  updateMultipleFilters={updateMultipleFilters}
                  allFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </MultiSelectRow>
            ) : (
              <div
                key={index}
                style={
                  !row.linked && showLinkedData && selectedToggle.title === 'Mortgage Characteristics'
                    ? {
                        cursor: 'disabled',
                        margin: '5px 0',
                        width: '90%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        opacity: 0.5,
                      }
                    : {
                        cursor: 'pointer',
                        margin: '5px 0',
                        width: '90%',
                        justifyContent: 'space-between',
                        display: 'flex',
                        // '&:hover': {
                        //   opacity: 0.7,
                        // },
                      }
                }
                onClick={() => updateSelectedMetrics(row)}
              >
                <div style={{ display: 'flex', width: '90%', margin: '3px 0px' }}>
                  <Checkbox
                    checked={
                      !row.linked && showLinkedData && selectedToggle.title === 'Mortgage Characteristics'
                        ? false
                        : featureSelections?.[row.dataKey]
                    }
                    style={{ margin: '3px 0px 0px', height: '18px', width: '18px' }}
                    size={'14px'}
                  />
                  <SelectTitle
                    style={
                      featureSelections?.[row.dataKey]
                        ? {
                            color: colors.black_01,
                          }
                        : { color: colors.grey_02 }
                    }
                  >
                    {row.title}
                  </SelectTitle>
                  {selectedMetric?.dataKey === row?.dataKey ? <EyeballIcon src={eyeball} alt="visible" /> : null}
                </div>
                {/* Change this, BAD>>>>> */}
                {showLinkedData && selectedToggle.title === 'Mortgage Characteristics' && (
                  <LinkedIcon src={row.linked ? dataLinked : dataNotLinked} alt="linked" />
                )}
              </div>
            );
          })}
      </DataInnerLeftContainer>
    </BodyLeftContainer>
  );
};
