import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import { colors } from '../styles';
import { PrimaryButton } from '.';
import { useStoreState } from '../store';
import { H2, F3, Row } from '.';
import { styled } from '@mui/material/styles';

const Root = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 20,
});

const ModalContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  width: '500px',
  backgroundColor: colors.white_01,
  borderRadius: '5px',
  margin: '0px 0px 0px 270px',
  padding: '16px 32px',
});

interface Props {
  modalStatus: boolean;
  closeModal: any;
  requestNote: string;
  requestType: string;
}

const SubscriptionModal = ({ modalStatus, closeModal, requestNote, requestType }: Props) => {
  const demoAccount = useStoreState((state) => state.user.demoAccount);
  const [activatePackage, setActivatePackage] = useState<any>(null);

  useEffect(() => {
    setActivatePackage(false);
  }, [modalStatus]);

  const sendAlertMessage = async () => {
    if (!demoAccount) {
      // const response = await systemManagement.notifyRep(requestNote, requestType);
    }
    setActivatePackage(true);
  };

  return (
    <Root
      open={!demoAccount && modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        {activatePackage ? (
          <div>
            <div style={{ margin: '16px 0px' }}>
              <H2>Thanks for your Interest!</H2>
            </div>
            <F3 sx={{ color: colors.grey_02 }}>{'We have let our sales team know, and they will contact you soon.'}</F3>
          </div>
        ) : (
          <div>
            <div style={{ margin: '12px 0px' }}>
              <H2>Your subscription does not include this package.</H2>
            </div>
            <F3 sx={{ color: colors.grey_02, margin: '0px 0px 16px' }}>
              {
                'If you would like to start using this package you may contact a sales representive by clicking the button below.'
              }
            </F3>
          </div>
        )}
        <Row sx={{ width: '100%' }}>
          {!activatePackage && (
            <PrimaryButton
              onClick={sendAlertMessage}
              style={{ background: colors.grey_02, border: `1px solid ${colors.grey_02}`, margin: 'auto 8px auto 0px' }}
            >
              Activate This Package
            </PrimaryButton>
          )}
          <PrimaryButton onClick={closeModal} style={{ margin: activatePackage ? 'auto 0px' : 'auto 0px auto 8px' }}>
            Back
          </PrimaryButton>
        </Row>
      </ModalContainer>
    </Root>
  );
};

export default SubscriptionModal;
