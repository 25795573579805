import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { colors, typography, buttons } from '../../../../styles';
import { TextField, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import ComingSoonModal from '../../../../components/ComingSoonModal';
import { formatTimestamp, formatPackageName } from '../../../../helpers/dataFormatter';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import moment from 'moment';
import { PrimaryButtonShort, SecondaryButtonShort, Row } from '../../../../components';
import TestEmailModal from '../../../../components/TestEmailModal';
// import NewsletterSegmentsAPI from '../../../../api/nurtureProspects';
import { GET_NURTURE_EMAIL_SETTINGS } from '../../../../api/nurtureProspects';
import CampaignConfirmationModal from '../../../../components/CampaignConfirmationModal';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useQuery } from '@apollo/client';

const loginRock = 'https://distill-ui-images.s3.us-east-2.amazonaws.com/loginRock.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden',
    paddingBottom: 120,
  },
  pageBody: {
    overflowY: 'scroll',
    height: '100%',
    padding: '0px 30px 0px 30px',
  },
  mainHeader: {
    ...typography.mainFont,
    fontSize: 16,
    lineHeight: 1.5,
    fontWeight: 500,
    width: '60%',
    color: colors.grey_03,
    cursor: 'default',
    margin: '20px 0px 0px',
  },
  cardContainer: {
    margin: '0px auto 50px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '5px',
  },
  cardHeader: {
    height: '50px',
    padding: '8px 24px',
    backgroundColor: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px 5px 0px 0px',
    borderBottom: `1px solid ${colors.grey_01}`,
    zIndex: '500',
  },
  cardTitleStyle: {
    fontFamily: 'Inter',
    height: 30,
    borderRadius: '30px',
    color: colors.black,
    fontWeight: 500,
    verticalAlign: 'center',
    fontSize: 16,
    backgroundColor: colors.white,
    padding: '0px 0px',
    cursor: 'pointer',
    letterSpacing: '0px',
    margin: '10px 0 0',
  },
  titleButton: {
    ...buttons.primary,
    height: '30px',
    width: 'fit-content',
    border: `0px solid ${colors.grey_01}`,
    padding: '0 20px',
    margin: '0px 0 0',
    whiteSpace: 'nowrap',
    '&:disabled': {
      opacity: '30%',
    },
    '&:hover': {
      opacity: '80%',
    },
  },
  overviewCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    //width: '100%',
  },
  radioTitle: {
    fontSize: 14,
    color: colors.black,
    fontWeight: 400,
    letterSpacing: 0.05,
  },
  articleImage: {
    height: '124px',
    width: '180px',
    borderRadius: '5px',
    objectFit: 'cover',
    'img:before': {
      backgroundImage: loginRock,
    },
  },
  primaryButton: {
    ...buttons.primary,
    height: '40px',
  },
  header: {
    display: 'flex',
    padding: '10px 16px',
    color: colors.grey_03,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${colors.grey_01}`,
  },
  campaignInfoP: {
    ...typography.mainFont,
    fontSize: '12px',
    fontWeight: '400',
    color: colors.grey_02,
    // width: '110px',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  campaignInfoRow: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0px 0px 0px',
  },
  cardBody: {
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  footer: {
    display: 'flex',
    padding: '10px 16px',
    color: colors.grey_03,
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${colors.grey_01}`,
  },
}));

interface Props {
  nextAction: any;
  basePath: string;
  navActions: any;
  emailArray: any;
  setEmailArray: any;
  campaignInfo: any;
  setProgress: number;
  seedArticle: any;
  saveDraft: any;
  sendDatetime: string;
  setSendDatetime: any;
}

const CampaignOverview = ({
  nextAction,
  basePath,
  navActions,
  emailArray,
  setEmailArray,
  setProgress,
  campaignInfo,
  seedArticle,
  sendDatetime,
  setSendDatetime,
  saveDraft,
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showComingSoon, setShowComingSoon] = useState(false);

  const [showTestEmailModal, setShowTestEmailModal] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [nurtureSender, setNurtureSender] = useState<any>(null);

  // console.log('emailArray: ', emailArray, ' campaignInfo: ', campaignInfo);

  const { data, error, loading } = useQuery(GET_NURTURE_EMAIL_SETTINGS, {
    onCompleted: (data) => {
      setNurtureSender(data?.meAssetManager?.organization?.amsponsor?.outboundInsightsEmail);
    },
  });

  const toggleBoolean = (value: boolean, setter: any) => () => {
    setter(!value);
  };

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const addCampaign = () => {
    nextAction(emailArray);
    setShowConfirmation(false);
  };

  const deliverySettings = () => {
    return (
      <div style={{ margin: '30px 0 0' }}>
        <FormControl sx={{ height: 200 }}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="immediately"
            name="radio-buttons-group"
          >
            <FormControlLabel
              value="immediately"
              sx={{ height: 20 }}
              control={
                <Radio
                  size="small"
                  sx={{
                    color: colors.black,
                    '&.Mui-checked': {
                      color: colors.black,
                    },
                  }}
                />
              }
              label={<h4 className={classes.radioTitle}>Schedule</h4>}
            />
            <FormControlLabel
              value="schedule"
              sx={{ height: 40, margin: '8px 0px 8px -10px' }}
              control={
                <Radio
                  size="small"
                  sx={{
                    color: colors.black,
                    '&.Mui-checked': {
                      color: colors.black,
                    },
                  }}
                  disabled
                />
              }
              label={
                <>
                  <h4 className={classes.radioTitle}>Schedule Date and Time</h4>{' '}
                </>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const emailDetails = [
    {
      label: 'Name',
      key: 'name',
      formatData: (row: any) => (emailArray?.[0]?.name ? emailArray?.[0]?.name : '-'),
    },
    {
      label: 'Subject',
      key: 'subject',
      formatData: (row: any) => (emailArray?.[0]?.subject ? emailArray?.[0]?.subject : '-'),
    },
  ];

  const targetDetails = [
    {
      label: 'Source',
      key: 'source',
      formatData: (row: any) =>
        campaignInfo?.target?.package ? formatPackageName(campaignInfo?.target?.package) : '-',
    },
    {
      label: 'Size',
      key: 'subject',
      formatData: (row: any) => (campaignInfo?.target?.serveCount ? campaignInfo?.target?.serveCount : '-'),
    },
    {
      label: 'Date created',
      key: 'dateCreated',
      formatData: (row: any) => (campaignInfo?.target?.dateRun ? formatTimestamp(campaignInfo?.target?.dateRun) : '-'),
    },
  ];

  return (
    <>
      <div className={classes.container}>
        <div className={classes.pageBody}>
          <h1 className={classes.mainHeader} style={{ padding: '0px 0px 20px' }}>
            Review campaign details below and then schedule your campaign
          </h1>
          <div
            className={classes.cardContainer}
            style={{
              margin: '0px auto 16px',
              overflowX: 'scroll',
              minWidth: '1100px',
              boxShadow: colors.main_box_shadow,
            }}
          >
            <div className={classes.cardHeader}>
              <h2 className={classes.cardTitleStyle}>
                {campaignInfo?.name} -{' '}
                <span style={{ fontWeight: '400', fontSize: '14px', color: colors.grey_02 }}>
                  {campaignInfo?.description}
                </span>
              </h2>
              <div style={{ display: 'flex', flexDirection: 'row', padding: '8px 0px' }}>
                <SecondaryButtonShort
                  style={{ margin: '0px 6px', backgroundColor: colors.grey_02, border: `1px solid ${colors.grey_02}` }}
                  children={'Save Progress'}
                  onClick={saveDraft}
                />
                <div style={{ width: '1px', background: colors.grey_02, margin: '0px 6px 0px 6px' }} />
                <PrimaryButtonShort onClick={() => setShowConfirmation(true)}>Schedule Campaign</PrimaryButtonShort>
              </div>
            </div>
            <div style={{ padding: '5px 20px 20px', display: 'flex', justifyContent: 'space-between' }}>
              {emailArray &&
                emailArray.map((rows: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={classes.overviewCard}
                      style={{ flex: 1, justifyContent: 'space-between' }}
                    >
                      <div
                        className={classes.cardContainer}
                        style={{
                          margin: '16px 8px 0px 0px',
                          minWidth: '410px',
                          display: 'flex',
                          flex: 1,
                          border: `1px solid ${colors.grey_01}`,
                        }}
                      >
                        <div className={classes.header}>
                          <span>Content</span>
                          <Row>
                            <SecondaryButtonShort
                              onClick={toggleBoolean(showTestEmailModal, setShowTestEmailModal)}
                              sx={{ border: '0', background: colors.grey_02, margin: '0px 8px 0px 0px' }}
                            >
                              Send Test Email
                            </SecondaryButtonShort>
                            <button
                              className={classes.titleButton}
                              onClick={() => navigate('/nurture/campaigns/select-content')}
                              style={{ backgroundColor: colors.grey_02 }}
                            >
                              Edit
                            </button>
                          </Row>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              border: `1px solid ${colors.grey_01}`,
                              borderRadius: '5px',
                              margin: '16px 0px 16px 16px',
                            }}
                          >
                            <object
                              type="image/png"
                              className={classes.articleImage}
                              data={seedArticle?.coverImage ? seedArticle?.coverImage : loginRock}
                            >
                              <img className={classes.articleImage} src={loginRock} alt={'placeholder'} />
                            </object>
                          </div>
                          <div className={classes.cardBody}>
                            {emailDetails.map((item, index) => (
                              <div
                                key={index + 492304}
                                className={classes.campaignInfoRow}
                                style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
                              >
                                <p className={classes.campaignInfoP} style={{ width: '60px' }}>
                                  {item.label}
                                </p>
                                <p
                                  className={classes.campaignInfoP}
                                  style={{
                                    width: '180px',
                                    color: colors.grey_03,
                                    fontWeight: '500',
                                    textAlign: 'right',
                                  }}
                                >
                                  {item.formatData ? item.formatData(campaignInfo) : campaignInfo[item.key]}
                                </p>
                              </div>
                            ))}
                            {nurtureSender && (
                              <div className={classes.campaignInfoRow} style={{ margin: '-16px 0px 0px' }}>
                                <p className={classes.campaignInfoP} style={{ width: '60px' }}>
                                  Sender
                                </p>
                                <p
                                  className={classes.campaignInfoP}
                                  style={{
                                    width: '180px',
                                    color: colors.grey_03,
                                    fontWeight: '500',
                                    textAlign: 'right',
                                  }}
                                >
                                  {nurtureSender}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={classes.cardContainer}
                        style={{
                          margin: '16px 8px 0px 0px',
                          minWidth: '410px',
                          display: 'flex',
                          flex: 1,
                          minHeight: '212px',
                          border: `1px solid ${colors.grey_01}`,
                        }}
                      >
                        <div className={classes.header}>
                          <span>Target</span>
                          <button
                            className={classes.titleButton}
                            style={{ backgroundColor: colors.grey_02 }}
                            onClick={() => {
                              navigate('/nurture/campaigns/target');
                            }}
                          >
                            Edit
                          </button>
                        </div>
                        <div className={classes.cardBody}>
                          {targetDetails.map((item, index) => (
                            <div
                              key={index + 492304}
                              className={classes.campaignInfoRow}
                              style={{ margin: index === 0 ? '0px' : '-16px 0px 0px' }}
                            >
                              <p className={classes.campaignInfoP}>{item.label}</p>
                              <p
                                className={classes.campaignInfoP}
                                style={{ width: '280px', color: colors.grey_03, fontWeight: '500', textAlign: 'right' }}
                              >
                                {item.formatData ? item.formatData(campaignInfo) : campaignInfo[item.key]}
                              </p>
                            </div>
                          ))}
                          <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en-us'}>
                            <DateTimePicker
                              renderInput={(props) => <TextField {...props} />}
                              label="Send Time"
                              //   sx={{ margin: '0px 0px 0px 0px' }}
                              value={sendDatetime}
                              onChange={(newValue: any) => {
                                setSendDatetime(newValue);
                              }}
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {/* <div>{deliverySettings()}</div> */}
            </div>
          </div>
        </div>
      </div>
      <TestEmailModal
        modalStatus={showTestEmailModal}
        closeModal={toggleBoolean(showTestEmailModal, setShowTestEmailModal)}
        sender={nurtureSender}
        emails={emailArray}
      />
      <CampaignConfirmationModal
        modalStatus={showConfirmation}
        closeModal={() => setShowConfirmation(!showConfirmation)}
        emailSender={nurtureSender}
        copy={`Please confirm the following details <br/><br/>
            <strong>Subject:</strong> ${emailArray?.[0]?.subject}<br/>
            <strong>Audience Size:</strong> ${campaignInfo?.target?.serveCount}<br/>
            <strong>Send Date Time:</strong> ${moment(sendDatetime).format('LTS')} <br/><br/>
          `}
        confirmCampaign={addCampaign}
        error={false}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </>
  );
};

export default CampaignOverview;
