import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../styles';
// import { Column, PageContainer } from '..';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon, Row, Column, H2, F4, Title } from '..';
import CompleteIconGreen from '../../assets/icons/complete-green.svg';
import RingGrey from '../../assets/icons/ring-grey.svg';
import RingPurple from '../../assets/icons/ring-purple.svg';
import PackageNameModal from '../PackageNameModal';
import { formatTimestamp } from '../../helpers/dataFormatter';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';

type Props = {
  title: string;
  PackageSteps: any;
  progress: number;
  algoType?: string;
  setAlgoType?: any;
  algoSettings?: any;
  progressTitles: Array<string>;
  navActions?: any;
  setProgress?: any;
  navSteps?: any;
  runId?: number | null;
  journeyId?: number | null;
  packageRunName?: string | null;
  dateRun?: string | null;
  setPackageRunName?: any | null;
};

const Root = styled('div')({
  width: '100%',
  height: '120px',
  background: colors.gradient_01,
  padding: '0px 30px',
  transition: 'background-color ease-in 0.5s',
});

const NavTitle = styled('h1')`
  ${typography.title};
  line-height: 24px;
  letter-spacing: -2px;
  cursor: pointer;
`;

const ProgressContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin: 0px 0px 0px;
  height: 40px;
`;

const RoutingButton = styled('div')`
  ${typography.header_03};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${colors.black_01};
  color: ${colors.white_01};
  width: 50px;
  margin: 0px 1px;
  border-radius: 2px 2px 2px 2px;
`;

const ArrowContainer = styled('div')`
  display: flex;
  opacity: 0.6;
  cursor: pointer;
  &:hover {
    opacity: 0.4;
  }
`;

const BlankRectangle = styled('div')`
  opacity: 0.2;
  background: ${colors.black_01};
  border-radius: 2px;
  margin-left: 1px;
  height: 40px;
  width: 60px;
`;

const BackArrow = styled('div')`
  width: 0;
  height: 0;
  margin: 0px -2px 0 0;
  border-radius: 2px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid ${colors.black_01};
`;

const NextArrow = styled('div')`
  width: 0;
  height: 0;
  margin: 0 0 0 -2px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid ${colors.black_01};
`;

const PackageStep = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: ${colors.black_01};
  color: ${colors.white_01};
  opacity: 0.6;
  margin: 0px 1px;
  padding: 7px 10px;
  border-radius: 2px;
  cursor: pointer;
  :hover {
    // opacity: 0.4;
  }
`;

const StepStatus = styled('p')`
  ${typography.footer_04};
  color: ${colors.white_01};
  margin: -2px 0px 3px;
  line-height: 12px;
  height: 10px;
  font-size: 10px;
`;

const StepLabel = styled('p')`
  ${typography.footer_03};
  margin: 0px 0px 0px;
  line-height: 12px;
  color: ${colors.white_01};
`;

const AlgoSwitch = styled(TextField)({
  margin: '11px 0px 0px 2px',
  padding: '0px 4px 0px',
  color: colors.black,
  borderColor: '#ECECEC',
  '& .MuiSelect-select': {
    cursor: 'pointer',
    fontSize: '24px',
    fontFamily: 'Inter',
    fontWeight: 600,
    padding: '4px 4px 0px',
    lineHeight: '28px',
    letterSpacing: '-1px',
    zIndex: 100,
    borderBottom: '0px',
    '& .MuiInputBase-input': {
      zIndex: 100,
      background: '#fff',
      boxShadow: '4px 4px 25px rgba(63, 106, 194, 0.3)',
      animationName: 'none',
    },
  },
  '& label': {
    fontSize: '13px',
    margin: '0px 0px 0px 4px',
    color: colors.grey_03,
    '&.Mui-focused': {
      color: '#404B50',
    },
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: colors.white + '00',
  },
  '& .MuiInput-underline:hover': {
    borderBottomColor: colors.grey_03,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.grey_03,
  },
});

const AlgoType = styled(MenuItem)({
  margin: '0px 0px 0px 0px',
  color: colors.black,
  cursor: 'pointer',
  fontSize: '24px',
  fontFamily: 'Inter',
  lineHeight: '28px',
  letterSpacing: '-1px',
  borderColor: '#ECECEC',
  fontWeight: 500,
});

export const PackageNav = ({
  title,
  navSteps,
  setProgress,
  PackageSteps,
  progress,
  progressTitles,
  runId,
  journeyId,
  packageRunName,
  setPackageRunName,
  dateRun,
  algoType,
  algoSettings,
  setAlgoType,
  navActions,
}: Props) => {
  const navigate = useNavigate();
  const [showPackageNameModal, setShowPackageNameModal] = useState<boolean>(false);

  const location = useLocation();

  const runGenerated = runId ? true : false;
  const disabledSteps = runGenerated ? { cursor: 'default' } : {};

  const goBack = () => {
    if (progress > 1) {
      setProgress(progress - 1);
    }
    navigate(navSteps[progress - 1]);
  };

  const goNext = () => {
    if (progressTitles[progress] === 'Complete') {
      setProgress(progress + 1);
      navigate(navSteps[progress + 1]);
    }
  };

  const navigateToStep = (newStep: number) => {
    // if (!runGenerated) {
    if (progressTitles[newStep - 1] !== ' ' && progressTitles[newStep - 1] !== '') {
      setProgress(newStep);
      navigate(navSteps[newStep]);
    }
    // }
  };

  const togglePackageNameModal = () => {
    setShowPackageNameModal(!showPackageNameModal);
  };

  let algoOptions = [
    { label: 'Purchase', value: 'purchase', color: colors.red_01 },
    { label: 'Redemption', value: 'redemption', color: colors.green_01 },
  ];

  if (!!algoSettings) {
    algoOptions = algoOptions.filter((item) => algoSettings[item.label]);
  }

  return (
    <Root
      style={{
        backgroundColor:
          algoType === 'Redemption' && location?.pathname?.includes('/signals')
            ? colors.red_02 + '50'
            : algoType === 'Purchase' && location?.pathname?.includes('/signals')
            ? colors.green_01 + '50'
            : colors.gradient_01,
      }}
    >
      <Row style={{ height: '56px' }}>
        <NavTitle style={{ minWidth: '150px' }} id={'header-nav-home'} onClick={() => navigate('home')}>
          {title}
        </NavTitle>
        {algoType && location?.pathname?.includes('/signals') && (
          <>
            <Title style={{ margin: '15px -4px 0px 6px' }}>- </Title>
            <AlgoSwitch value={algoType} select variant="standard" label="">
              {algoOptions.map((field: any, index: number) => {
                return (
                  <AlgoType
                    key={index}
                    id={`algoSwitcher-${field?.label}`}
                    onClick={() => setAlgoType(field?.label)}
                    value={field?.label}
                  >
                    {field?.label}
                  </AlgoType>
                );
              })}
            </AlgoSwitch>
          </>
        )}
        {runId && (
          <Row style={{ flex: 1, justifyContent: 'space-between' }}>
            <Column
              style={{
                height: '30px',
                padding: '0px 12px',
                margin: '13px 12px',
                borderLeft: `3px solid ${colors.grey_03}`,
              }}
            >
              <F4 style={{ margin: '-3px 0px 0px', color: colors.grey_03 }}>Journey Name</F4>
              <Row style={{ height: '18px', alignItems: 'flex-end' }}>
                <H2 style={{ margin: '8px 0px -2px', fontWeight: '600' }}>
                  {packageRunName ? packageRunName : 'Unnamed Journey'}
                </H2>
                <F4
                  style={{ margin: '0px 8px -1px', color: colors.dark_purple, cursor: 'pointer' }}
                  onClick={togglePackageNameModal}
                >
                  Edit
                </F4>
              </Row>
            </Column>
            {dateRun && (
              <Column
                style={{
                  height: '30px',
                  padding: '0px 0px',
                  margin: '13px 0px',
                }}
              >
                <F4 style={{ margin: '-3px 0px 0px', color: colors.grey_03, textAlign: 'end' }}>Run Date</F4>
                <Row style={{ height: '18px', alignItems: 'flex-end' }}>
                  <H2 style={{ margin: '8px 0px -2px', fontWeight: '600' }}>
                    {dateRun ? formatTimestamp(dateRun) : '-'}
                  </H2>
                </Row>
              </Column>
            )}
          </Row>
        )}
      </Row>
      <ProgressContainer>
        <ArrowContainer>
          <BackArrow />
          <RoutingButton onClick={() => goBack()}>Back</RoutingButton>
        </ArrowContainer>
        {PackageSteps.map((step: any, index: number) => {
          return (
            index > 0 && (
              <PackageStep
                key={index + 2191204}
                sx={
                  location?.pathname?.includes('/' + navSteps[index]) ? { opacity: 1, ...disabledSteps } : disabledSteps
                }
                onClick={() => navigateToStep(index)}
              >
                <Row>
                  <Icon
                    sx={{ height: '9px', width: '9px', margin: '-1px 3px 6px 0px' }}
                    src={
                      progressTitles[index - 1] === 'Complete'
                        ? CompleteIconGreen
                        : progressTitles[index - 1] === 'Current'
                        ? RingPurple
                        : RingGrey
                    }
                  />
                  <StepStatus
                    sx={{ color: progressTitles[index - 1] === 'Complete' ? colors.light_green : colors.light_purple }}
                  >
                    {progressTitles[index - 1]}
                  </StepStatus>
                </Row>
                <StepLabel>
                  {index}.{step.label}
                </StepLabel>
              </PackageStep>
            )
          );
        })}
        {PackageSteps.length > progress + 1 ? (
          <ArrowContainer>
            <RoutingButton onClick={() => goNext()}>Next</RoutingButton>
            <NextArrow />
          </ArrowContainer>
        ) : (
          <BlankRectangle />
        )}
      </ProgressContainer>
      <PackageNameModal
        journeyId={journeyId}
        packageRunName={packageRunName}
        setPackageRunName={setPackageRunName}
        modalStatus={showPackageNameModal}
        closeModal={togglePackageNameModal}
      />
    </Root>
  );
};
