import React from 'react';
import { styled } from '@mui/material';
import { colors, typography } from '../../styles';
import { CardChip, F3 } from '../../components';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from '@mui/styles';
import { TellMeMoreContent } from '../TopRankedCards';
import { SummaryType } from '../../types';
import ScrollingDiv from '../ScrollingDiv';

type Props = {
  width?: number;
  faqData: SummaryType[];
};

const ViewContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignContent: 'center',
  margin: '16px 0px 6px 0px',
});

const TellMeMoreChip = styled('div')({
  height: '25px',
  width: '100%',
  background: colors.grey_02,
  borderRadius: '5px',
  color: colors.white,
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  lineHeight: '25px',
  ...typography.mainFont,
  fontWeight: 600,
  fontSize: '14px',
  fontSpacing: '0.1px',
  flex: 1,
  // margin: '0px 0px 16px 0px',
});

const AccordionContainer = styled('div')({
  marginBottom: '20px',
});

const Summary = styled(AccordionSummary)({
  padding: '0px',
  margin: '0px 0px',
});

const DetailsContainer = styled(AccordionDetails)({
  paddingLeft: '0px',
  paddingRight: '0px',
});

const AccordionStyle = styled(Accordion)({
  borderBottom: `1px solid ${colors.grey_02}`,
  margin: '0px 0px',
  padding: '0px 0px',
});

const SummaryText = styled(F3)({
  fontSize: '14px',
  fontWeight: '400',
  color: colors.grey_03,
  textAlign: 'left',
  '& span.highlight': {
    fontWeight: 600,
    color: colors.lilac,
  },
  '& span.bold': {
    fontWeight: 600,
    color: colors.grey_02,
  },
});

function TellMeMoreView({ faqData, width }: Props) {
  const isValidJSON = (str: string) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <ViewContainer>
      <TellMeMoreChip>TELL ME MORE</TellMeMoreChip>
      <AccordionContainer>
        <>
          {faqData?.map((item: any, index: number) => (
            <AccordionStyle key={index + 1276553} disableGutters={true} square={true} elevation={0}>
              <Summary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-2-${index + 1276553}-a-header`}
                id={`panel-2-${index + 1276553}-a-header`}
              >
                <SummaryText
                  dangerouslySetInnerHTML={{
                    __html: item?.key ?? 'Lorem ipsum dolor sit amet, consectetur adipiscing',
                  }}
                />
              </Summary>
              <DetailsContainer>
                <SummaryText
                  dangerouslySetInnerHTML={{
                    __html: isValidJSON(item?.value.replaceAll(`'`, `"`))
                      ? JSON.parse(item?.value.replaceAll(`'`, `"`)).join(' ')
                      : item?.value,
                  }}
                />
              </DetailsContainer>
            </AccordionStyle>
          ))}
        </>
      </AccordionContainer>
    </ViewContainer>
  );
}

export default TellMeMoreView;
