import {
  formatDollar,
  formatThousandDollar,
  formatMillionDollar,
  formatPercent,
  formatNumber,
  formatAdvisorType,
} from '../../helpers/dataFormatter';
import handActive from '../../assets/icons/hand-active.svg';
import handInactive from '../../assets/icons/hand-inactive.svg';
import React from 'react';

//The following models are used on the Advisor side of the application
const clientData = [
  { title: 'Age', key: 'age' },
  { title: 'State', key: 'state' },
  {
    title: 'Gender',
    key: 'gender',
  },
  {
    title: 'Est. Investable Assets',
    formatData: (data: any) => formatThousandDollar(data.estimatedInvestableAssets),
  },
  { title: 'Future Assets', formatData: (data: any) => formatThousandDollar(data.projectedRetirementAssets) },
  { title: 'Income', formatData: (data: any) => formatThousandDollar(data.income) },
  { title: 'Home Value', formatData: (data: any) => formatThousandDollar(data.homeValue) },
  { title: 'Mortgage Paid', formatData: (data: any) => formatPercent(data.percentOfHomeValuePaidOff, 0, true) },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: any) => formatPercent(data.financialPlanningTendencyScore * 100, 0, true),
  },
  {
    title: 'Credit Score Proxy',
    formatData: (data: any) => formatPercent(data.proxyCreditScore, 0, true),
  },
];

const prospectData = [
  { title: 'Age', key: 'age' },
  { title: 'State', key: 'state' },
  {
    title: 'Gender',
    key: 'gender',
  },
  {
    title: 'Est. Investable Assets',
    formatData: (data: any) => formatThousandDollar(data.estimatedInvestableAssets),
  },
  { title: 'Future Assets', formatData: (data: any) => formatThousandDollar(data.projectedRetirementAssets) },
  { title: 'Income', formatData: (data: any) => formatThousandDollar(data.income) },
  { title: 'Home Value', formatData: (data: any) => formatThousandDollar(data.homeValue) },
  { title: 'Mortgage Paid', formatData: (data: any) => formatPercent(data.percentOfHomeValuePaidOff, 0, true) },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: any) => formatPercent(data.financialPlanningTendencyScore * 100, 0, true),
  },
  {
    title: 'Credit Score Proxy',
    formatData: (data: any) => formatPercent(data.proxyCreditScore, 0, true),
  },
];

//The following models are used on the Asset Management side of the application
const individualData = [
  { title: 'Age', key: 'age' },
  { title: 'State', key: 'state' },
  { title: 'Income', formatData: (data: any) => formatThousandDollar(data.income) },
  { title: 'Wealth', formatData: (data: any) => formatThousandDollar(data.estimatedInvestableAssets) },
  { title: 'Mortgage Paid', formatData: (data: any) => formatPercent(data.percentOfHomeValuePaidOff) },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: any) =>
      data.financialPlanningTendencyScore ? formatPercent(data.financialPlanningTendencyScore * 100) : '-',
  },
  {
    title: 'Credit Quality',
    formatData: (data: any) => formatPercent(data.proxyCreditScore),
  },
];

const advisorData = [
  // { title: 'Firm Name', key: 'firmName' },
  { title: 'Age', formatData: (data: any) => (data.age ? data.age : '-') },
  {
    title: 'Type (BD/RIA)',
    formatData: (data: any) => formatAdvisorType(data),
  },
  { title: 'Total Years as Advisor', formatData: (data: any) => formatNumber(data.careerTenure) },
  { title: 'Assets Under Management', formatData: (data: any) => formatDollar(data.aumSelfReported) },
  { title: 'Firm Accounts', formatData: (data: any) => formatNumber(data.firmTotalAccounts) },
  { title: 'Firm Total Assets', formatData: (data: any) => formatMillionDollar(data.firmTotalAssetsInMillions) },
  { title: 'Firm ETF Total Assets', formatData: (data: any) => formatMillionDollar(data.firmEtfAssetsMillions) },
];

const lplAdvisorData = [
  { title: 'Age', formatData: (data: any) => (data.age ? data.age : '-') },
  {
    title: 'LPL Tenure',
    formatData: (data: any) =>
      data?.lpladvisordataSet?.[0]?.advisorLplTenure ? data?.lpladvisordataSet?.[0]?.advisorLplTenure : '-',
  },
  { title: 'Industry Tenure', formatData: (data: any) => formatNumber(data.careerTenure) },
  {
    title: 'Assets Under Management',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.totalAssets),
  },
  {
    title: 'Net New Assets (Past Year)',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.trailingTwelveMonthTotalNetNewAssets),
  },
  {
    title: 'Advisor ETF Assets',
    formatData: (data: any) => formatDollar(data?.lpladvisordataSet?.[0]?.etfAssets),
  },
];

const prioritizeProspects = [
  // { title: 'Firm Name', key: 'firmName' },
  { title: 'Age', formatData: (data: any) => (data.age ? data.age : '-') },
  { title: 'State', key: 'state' },
  { title: 'Income', formatData: (data: any) => formatDollar(data.income) },
  { title: 'Wealth', formatData: (data: any) => formatDollar(data.estimatedInvestableAssets) },
  { title: 'Mortgage Paid', formatData: (data: any) => formatPercent(data.percentOfHomeValuePaidOff, 0, true) },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: any) => formatPercent(data.financialPlanningTendencyScore, 0, true),
  },
  {
    title: 'Credit Score Proxy',
    formatData: (data: any) => formatPercent(data.proxyCreditScore, 0, true),
  },
];

const crossSell = [
  // { title: 'Firm Name', key: 'firmName' },
  { title: 'Age', formatData: (data: any) => (data.age ? data.age : '-') },
  { title: 'State', key: 'state' },
  { title: 'Income', formatData: (data: any) => formatDollar(data.income) },
  { title: 'Wealth', formatData: (data: any) => formatDollar(data.estimatedInvestableAssets) },
  { title: 'Mortgage Paid', formatData: (data: any) => formatPercent(data.percentOfHomeValuePaidOff, 0, true) },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: any) => formatPercent(data.financialPlanningTendencyScore, 0, true),
  },
  {
    title: 'Credit Score Proxy',
    formatData: (data: any) => formatPercent(data.proxyCreditScore, 0, true),
  },
];

const enrichedData = [
  // { title: 'Firm Name', key: 'firmName' },
  {
    title: 'Enriched Datapoints',
    formatData: (data: { numDatapointsEnriched: number | null }) => formatNumber(data.numDatapointsEnriched),
  },
];

const enrichedDataModal = [
  // { title: 'Firm Name', key: 'firmName' },
  { title: 'Age', key: 'age' },
  { title: 'State', key: 'state', formatData: (data: { state: any }) => (data.state ? data.state : '-') },
  { title: 'Income', formatData: (data: { income: any }) => formatDollar(data.income) },
  { title: 'Savings', formatData: (data: { savings: any }) => formatDollar(data.savings) },
  { title: 'Securities', formatData: (data: { securities: any }) => formatDollar(data.securities) },
  { title: 'Home Value', formatData: (data: { homeValue: any }) => formatDollar(data.homeValue) },
  {
    title: 'Mortgage Paid',
    formatData: (data: { percentOfHomeValuePaidOff: any }) => formatPercent(data.percentOfHomeValuePaidOff),
  },
  {
    title: 'Projected Retirment Assets',
    formatData: (data: { projectedRetirementAssets: any }) => formatDollar(data.projectedRetirementAssets),
  },
  {
    title: 'Financial Planning Affinity',
    formatData: (data: { financialPlanningTendencyScore: number }) =>
      data.financialPlanningTendencyScore ? formatPercent(data.financialPlanningTendencyScore * 100) : '-',
  },
  {
    title: 'Credit Quality',
    formatData: (data: { proxyCreditScore: any }) => formatPercent(data.proxyCreditScore),
  },
  {
    title: 'Enriched Datapoints',
    formatData: (data: { numDatapointsEnriched: number | null }) => formatNumber(data.numDatapointsEnriched),
  },
];

const lifeStageData = [
  // { title: 'Firm Name', key: 'firmName' },
  {
    title: 'Matching Individuals',
    formatData: (data: { matchingIndividuals: any }) => data.matchingIndividuals,
  },
];

const referralData = [
  {
    title: 'Top Available Referral',
    formatData: (data: { firstName: any; lastName: any }) => `${data.firstName}  ${data.lastName}`,
  },
  {
    title: 'Probability of Relationship',
    formatData: (data: any) => '100%',
  },
];

const nurtureAdvisors = [
  {
    title: 'Requested meeting',
    formatData: (data: { wantsMeeting: any }) =>
      data.wantsMeeting ? (
        <img style={{ height: '20px' }} src={handActive} alt="active" />
      ) : (
        <img style={{ height: '20px' }} src={handInactive} alt="inactive" />
      ),
  },
];

const content: any = {
  advisorData,
  lplAdvisorData,
  individualData,
  clientData,
  crossSell,
  prospectData,
  prioritizeProspects,
  nurtureAdvisors,
  enrichedData,
  enrichedDataModal,
  lifeStageData,
  referralData,
};

export default content;
