import React, { useEffect, useState } from 'react';
import { colors } from '../styles';

import { styled } from '@mui/system';
import { Modal, IconButton } from '@mui/material';
import { CardHeader, CardContainer, H1, H2, H3, F3, F4, CardBody, Row } from '.';
import { formatTimestamp, formatPackageName } from '../helpers/dataFormatter';
import Table from '../components/Table';
import trash_icon from '../assets/icons/trash-icon.svg';

import nurtureIcon from '../assets/icons/nurture-icon.svg';
import close_icon from '../assets/icons/close.svg';

const ModalContainer = styled('div')({
  height: '700px',
  margin: '90px',
  padding: '0px 0px 20px',
  background: colors.white_01,
  borderRadius: '5px',
  overflow: 'hidden',
});

const ContainerBody = styled('div')({
  height: '100%',
  overflowY: 'scroll',
  background: colors.white,
});

const headerData = [
  {
    key: 'checkbox',
    type: 'checkbox',
    sortKey: '',
  },
  {
    header: 'Campaign Name',
    key: 'name',
    sortKey: 'name',
    formatData: (row: any) => {
      return row.name;
    },
    bold: true,
  },
  {
    header: 'Campaign Purpose',
    key: 'name',
    sortKey: 'name',
    formatData: (row: any) => {
      return row.description;
    },
  },
  {
    header: 'Created',
    key: 'dateCreated',
    sortKey: 'date_created',
    formatData: (row: any) => {
      return formatTimestamp(row.dateCreated);
    },
  },
  {
    header: 'Target Audience',
    sortKey: 'serve_count',
    formatData: (row: any) => formatPackageName(row?.run?.package),
  },
  // {
  //   header: 'Audience Size',
  //   sortKey: 'serve_count',
  //   formatData: (row:any) => row?.run?.serveCount,
  // },
];

interface Props {
  loading: boolean;
  closeModal: any;
  openDraft: any;
  draftCampaigns: any;
  showCampaignDraftModal: boolean;
  draftSearchTerm: string;
  setDraftSearchTerm: any;
  draftTablePage: number;
  setDraftTablePage: any;
  draftPageSize: any;
  setDraftPageSize: any;
  draftTotal: any;
  selections: any;
  setSelections: any;
  draftSortKey: any;
  setDraftSortKey: any;
  showConfirmationModal: boolean;
  setShowConfirmationModal: any;
}

const CampaignDraftModal = ({
  loading,
  closeModal,
  openDraft,
  draftCampaigns,
  draftTotal,
  draftSearchTerm,
  setDraftSearchTerm,
  draftPageSize,
  setDraftPageSize,
  draftSortKey,
  setDraftSortKey,
  draftTablePage,
  setDraftTablePage,
  showCampaignDraftModal,
  showConfirmationModal,
  setShowConfirmationModal,
  selections,
  setSelections,
}: Props) => {
  useEffect(() => {}, []);

  const exit = () => {
    closeModal();
  };

  const deleteDraft = () => {};

  return (
    <Modal open={!!showCampaignDraftModal} onClose={exit}>
      <ModalContainer>
        <CardHeader
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${colors.grey_01}`,
            margin: '0px',
            padding: '6px 24px',
            background: colors.white,
          }}
        >
          <Row>
            <img
              src={nurtureIcon}
              style={{ height: '20px', width: '20px', margin: '14px 8px 6px 0px' }}
              alt="nurture"
            />
            <H2>Continue Draft Campaign</H2>
          </Row>
          <div>
            <IconButton sx={{ margin: '8px 0px 0px' }}>
              <img src={close_icon} onClick={exit} alt={'close'} />
            </IconButton>
          </div>
        </CardHeader>
        <ContainerBody>
          <div style={{ margin: '20px 32px' }}>
            <H3>Select a campaign to continue</H3>
            <F4 style={{ color: colors.grey_02, width: '440px' }}>
              Here you can see those campaigns you’ve previously begun, but which are still in draft form.{' '}
            </F4>
          </div>
          <CardContainer style={{ margin: '0px 32px' }}>
            <CardHeader style={{ padding: '16px 32px' }}>
              <H2 key={342421}>Draft Campaign</H2>
            </CardHeader>
            {draftCampaigns.length === 0 ? (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '30px 0px' }}>
                <H2 sx={{ margin: '0px 16px 8px' }}>You don't have any draft campaigns yet</H2>
                <F3 sx={{ margin: '0px 16px 8px', color: colors.grey_02 }}>
                  When you save a campaign draft it will appear here.
                </F3>
              </div>
            ) : (
              <Table
                //@ts-ignore
                headerData={headerData}
                data={draftCampaigns}
                hideShadow={true}
                dataControls={{
                  selections,
                  setSelections,
                  searchFunc: setDraftSearchTerm,
                  draftSearchTerm,
                  searchPlaceholder: 'Search for a draft...',
                }}
                paginationControls={{
                  tablePage: draftTablePage,
                  setTablePage: setDraftTablePage,
                  pageSize: draftPageSize,
                  setPageSize: setDraftPageSize,
                  totalItems: draftTotal,
                  sortKey: draftSortKey,
                  setSortKey: setDraftSortKey,
                }}
                headerFunctions={[
                  {
                    name: 'deleteAdvisors',
                    icon: trash_icon,
                    action: () => {
                      setShowConfirmationModal(true);
                    },
                  },
                ]}
                search={true}
                loading={loading}
                //@ts-ignore
                onRowPress={(row: any) => {
                  openDraft(row);
                }}
                newItems={[]}
              />
            )}
          </CardContainer>
        </ContainerBody>
      </ModalContainer>
    </Modal>
  );
};

export default CampaignDraftModal;
