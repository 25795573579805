import React, { useState } from 'react';
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import { H3, F3 } from '.';
import { colors } from '../styles';
import { styled } from '@mui/material/styles';
import { formatNumber, formatDollar } from '../helpers/dataFormatter';

type Props = {
  data: any;
  legend: any;
};

const TooltipContainer = styled('div')({
  backgroundColor: colors.black,
  borderRadius: '5px',
  padding: '10px 8px',
  boxShadow: colors.main_box_shadow,
});

export const SimplePieChart = ({ data, legend }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const height = 120;
  const width = 100;

  const innerRadius = 0;
  const outerRadius = 56;

  const CustomTooltip = ({ payload, active }: any) => {
    return (
      <TooltipContainer>
        <H3 style={{ color: colors.white }}>{payload?.[0]?.payload?.[legend?.['nameKey']]}</H3>
        <F3 style={{ color: colors.white }}>Flows: {formatDollar(payload?.[0]?.payload?.[legend?.['dataKey']])}</F3>
      </TooltipContainer>
    );
  };

  return (
    <ResponsiveContainer style={{ backgroundColor: colors.black + '02' }}>
      <PieChart height={height} width={width} style={{ backgroundColor: colors.black + '00' }}>
        <Pie
          data={data}
          activeIndex={activeIndex}
          // renderActiveShape={hideSectionLabel ? renderActiveShape : null}
          dataKey={legend?.dataKey}
          nameKey={legend?.nameKey}
          cx="50%"
          cy="50%"
          legendType="line"
          labelLine={true}
          isAnimationActive={false}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          //   activeShape={renderActiveShape}
          onClick={(props, index) => {
            setActiveIndex(index);
          }}
          //   inactiveShape={renderActiveShape}
          fill={colors.grey_03}
          label={(props) => {
            return (
              <>
                <text
                  fill={props?.fill}
                  textAnchor={props?.textAnchor}
                  x={props?.x + 2}
                  y={props?.y}
                  style={{ fontSize: '12px', fontFamily: 'Inter', width: '20px' }}
                >
                  {props?.payload?.payload?.[legend?.['nameKey']] ? props?.payload?.payload?.[legend?.['nameKey']] : 0}
                </text>
                <text
                  fill={props?.fill}
                  textAnchor={props?.textAnchor}
                  x={props?.x + 2}
                  y={props?.y + 12}
                  style={{ fontSize: '12px', fontFamily: 'Inter' }}
                >
                  {props?.payload?.payload?.[legend?.['dataKey']]
                    ? formatDollar(props?.payload?.payload?.[legend?.['dataKey']]) + ' '
                    : '0 '}
                </text>
              </>
            );
          }}
        >
          <Cell key={`cell-0`} fill={colors.light_purple} />
          <Cell key={`cell-1`} fill={colors.grey_03} />
          <Cell key={`cell-2`} fill={colors.red} />
          <Cell key={`cell-3`} fill={colors.yellow} />
          <Cell key={`cell-4`} fill={colors.green} />
          <Cell key={`cell-5`} fill={colors.dark_blue} />
          <Cell key={`cell-6`} fill={colors.yellow} />
        </Pie>
        <Tooltip content={<CustomTooltip />} cursor={{ strokeDasharray: '3 3' }} />
      </PieChart>
    </ResponsiveContainer>
  );
};
