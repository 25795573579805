import graphql from 'graphql-tag';
import { gql } from '../../__generated__';
import { apolloClient } from '..';

export const CREATE_DRAFT_CAMPAIGN = gql(`
  mutation CreateNurtureCampaign1($runId: ID!, $emails: [NurtureEmailInputType], $name: String!, $description: String) {
    nurtureLeadsCreateCampaign(
      runId: $runId
      emails: $emails
      name: $name
      description: $description
      status: "DRAFT"
    ) {
      success
      runId
    }
  }
`);

export const CREATE_NURTURE_CAMPAIGN = gql(`
  mutation CreateNurtureCampaign2(
    $runId: ID!
    $emails: [NurtureEmailInputType]
    $name: String!
    $description: String
  ) {
    nurtureLeadsCreateCampaign(
      runId: $runId
      emails: $emails
      name: $name
      description: $description
      status: "ACTIVE"
    ) {
      success
      runId
    }
  }
`);

export const GET_MARKETING_ACTIVITIES = gql(`
  query getMarketingData1($pageSize: Int, $page: Int, $query: String, $filters: [FilterType]) {
    marketing {
      campaigns(pageSize: $pageSize, page: $page, query: $query, filters: $filters) {
        totalItems
        pages
        list {
          id
          campaignId
          campaignName
          clickThroughRate
          dateCreated
          dateUpdated
          description
          eventsOverTime
          openRate
          recipientCount
          startDate
          status
          marketingemailSet {
            sendDate
            subject
            openRate
            clickThroughRate
          }
        }
      }
    }
  }
`);

export const DELETE_DRAFT_CAMPAIGN = gql(`
  mutation deleteDraftCampaign($campaignId: ID!) {
    nurtureLeadsDeleteCampaign(campaignId: $campaignId) {
      details
      success
    }
  }
`);

export const CANCEL_CAMPAIGN = gql(`
  mutation cancelCampaign($campaignId: ID!) {
    nurtureLeadsCancelCampaign(campaignId: $campaignId) {
      success
      details
    }
  }
`);

export const UPLOAD_ARTICLE = gql(`
  mutation UploadArticle(
    $data: String!
    $filename: String!
    $title: String!
    $description: String! # $coverImage: String
    $imageData: String! # $coverImage: String
  ) {
    uploadArticlePdf(
      data: $data
      filename: $filename
      title: $title
      description: $description # coverImage: $coverImage
      imageData: $imageData
    ) {
      success
      details
    }
  }
`);

export const CREATE_SEGMENT = gql(`
  mutation generateAnalysis($advisorSetId: ID) {
    newsletterSegmentsGenerateRun(advisorSetId: $advisorSetId) {
      runId
    }
  }
`);

export const GET_CAMPAIGNS = gql(`
  query Campaigns($pageSize: Int!, $page: Int, $query: String, $sortKey: String, $filters: [FilterType]) {
    nurtureLeads {
      campaigns(pageSize: $pageSize, page: $page, query: $query, sortKey: $sortKey, filters: $filters) {
        page
        pages
        totalItems
        list {
          status
          openRate
          clickThroughRate
          eventsOverTime
          isCancellable
          averageScoreIncrease
          recipientCount
          dateCreated
          description
          id
          name
          nurtureemailSet {
            html
            description
            dateCreated
            name
            id
            editorJson
            sendDatetime
            status
            subject
          }
          run {
            package
            serveCount
            dateRun
            id
          }
        }
      }
    }
  }
`);

export const SEND_TEST_EMAIL = gql(`
  mutation sendTestEmail($testEmailAddress: String!, $emailContent: NurtureTestEmailInputType!) {
    nurtureLeadsSendTestEmail(testEmailAddress: $testEmailAddress, emailContent: $emailContent) {
      success
    }
  }
`);

export const GET_PACKAGE_RUNS = gql(`
  query getPackageRuns($pageSize: Int!, $page: Int, $query: String, $sortKey: String) {
    packageRuns {
      runs(
        page: $page
        pageSize: $pageSize
        query: $query
        sortKey: $sortKey
        filters: [{ key: "package_name__in", value: "INFLUENCE,DISCOVER_LEADS,NEWSLETTER_SEGMENTS" }]
        showAll: true
      ) {
        totalItems
        list {
          dateRun
          algoVersion
          id
          package
          customName
          serveCount
          nurturecampaignSet {
            id
            name
          }
        }
      }
    }
  }
`);

export const GET_NURTURE_EMAIL_SETTINGS = gql(`
  query nurtureEmails {
    meAssetManager {
      organization {
        id
        amsponsor {
          outboundInsightsEmail
          emailsEnabled
        }
      }
    }
  }
`);

// export const SAVE_EMAIL_TEMPLATE = gql(`
// mutation SaveEmailTemplate($data: JSONString!, $name: String!, $description: String) {
//             createEmailTemplate(data: $data, title: $name, description: $description) {
//               success
//               templateId
//             }
//           }
// `);

class NurtureLeadsAPI {
  // async createDraftCampaign(runId: number, emails: any, name: string, description: string) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation CreateNurtureCampaign1(
  //           $runId: ID!
  //           $emails: [NurtureEmailInputType]
  //           $name: String!
  //           $description: String
  //         ) {
  //           nurtureLeadsCreateCampaign(
  //             runId: $runId
  //             emails: $emails
  //             name: $name
  //             description: $description
  //             status: "DRAFT"
  //           ) {
  //             success
  //             runId
  //           }
  //         }
  //       `,
  //       variables: {
  //         name,
  //         description,
  //         runId,
  //         emails,
  //       },
  //     });
  //     return response.data.nurtureLeadsCreateCampaign.success;
  //   } catch (error: any) {
  //     console.warn('errror creating nurture campaign: ', error.message);
  //     throw error;
  //   }
  // }
  // async createNurtureCampaign(runId: number, emails: any, name: string, description: string) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  // mutation CreateNurtureCampaign2(
  //   $runId: ID!
  //   $emails: [NurtureEmailInputType]
  //   $name: String!
  //   $description: String
  // ) {
  //   nurtureLeadsCreateCampaign(
  //     runId: $runId
  //     emails: $emails
  //     name: $name
  //     description: $description
  //     status: "ACTIVE"
  //   ) {
  //     success
  //     runId
  //   }
  // }
  //       `,
  //       variables: {
  //         name,
  //         description,
  //         runId,
  //         emails,
  //       },
  //     });
  //     return response.data.nurtureLeadsCreateCampaign.success;
  //   } catch (error: any) {
  //     console.warn('errror creating nurture campaign: ', error.message);
  //     throw error;
  //   }
  // }
  // async saveEmailTemplate(name: string, description: string, data: any) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation SaveEmailTemplate($data: JSONString!, $name: String!, $description: String) {
  //           createEmailTemplate(data: $data, title: $name, description: $description) {
  //             success
  //             templateId
  //           }
  //         }
  //       `,
  //       variables: {
  //         data,
  //         name,
  //         description,
  //       },
  //     });
  //     return response.data.createEmailTemplate;
  //   } catch (error: any) {
  //     console.warn('errror creating email template: ', error.message);
  //     throw error;
  //   }
  // }
  // async getMarketingCampaigns({
  //   pageSize,
  //   page,
  //   query,
  //   sortKey,
  //   filters,
  // }: {
  //   pageSize?: number;
  //   page: number;
  //   query?: string;
  //   sortKey?: string;
  //   filters?: any;
  // }) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         query getMarketingData1($pageSize: Int, $page: Int, $query: String, $filters: [FilterType]) {
  //           marketing {
  //             campaigns(pageSize: $pageSize, page: $page, query: $query, filters: $filters) {
  //               totalItems
  //               pages
  //               list {
  //                 id
  //                 campaignId
  //                 campaignName
  //                 clickThroughRate
  //                 dateCreated
  //                 dateUpdated
  //                 description
  //                 eventsOverTime
  //                 openRate
  //                 recipientCount
  //                 startDate
  //                 status
  //                 marketingemailSet {
  //                   sendDate
  //                   subject
  //                   openRate
  //                   clickThroughRate
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       `,
  //       variables: {
  //         pageSize,
  //         page,
  //         sortKey,
  //         filters,
  //       },
  //     });
  //     return response?.data?.marketing?.campaigns;
  //   } catch (error: any) {
  //     console.warn('error in get marketing data: ', error.message);
  //   }
  // }
  // async deleteDraftCampaign(campaignId: string) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation deleteDraftCampaign($campaignId: ID!) {
  //           nurtureLeadsDeleteCampaign(campaignId: $campaignId) {
  //             details
  //             success
  //           }
  //         }
  //       `,
  //       variables: {
  //         campaignId,
  //       },
  //     });
  //     return response.data.nurtureLeadsDeleteCampaign;
  //   } catch (error: any) {
  //     console.warn('error updating email templates: ', error.message);
  //     throw error;
  //   }
  // }
  // async updateEmailTemplate(templateId: string, name: string, description: string, data: any) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation updateEmailTemplate($templateId: ID!, $data: JSONString!, $name: String!, $description: String) {
  //           updateEmailTemplate(templateId: $templateId, data: $data, title: $name, description: $description) {
  //             success
  //           }
  //         }
  //       `,
  //       variables: {
  //         data,
  //         name,
  //         templateId,
  //         description,
  //       },
  //     });
  //     return response.data.updateEmailTemplate.success;
  //   } catch (error: any) {
  //     console.warn('error updating email templates: ', error.message);
  //     throw error;
  //   }
  // }
  // async cancelCampaign(campaignId: string) {
  //   const response = await apolloClient.mutate({
  //     mutation: graphql`
  //       mutation CancelCampaign($campaignId: ID!) {
  //         nurtureLeadsCancelCampaign(campaignId: $campaignId) {
  //           success
  //           details
  //         }
  //       }
  //     `,
  //     variables: {
  //       campaignId,
  //     },
  //   });
  //   return response.data?.nurtureLeadsCancelCampaign;
  // }
  // async uploadArticle(data: any, filename: string, title: string, description: string, coverImage: any) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation UploadArticle(
  //           $data: String!
  //           $filename: String!
  //           $title: String!
  //           $description: String! # $coverImage: String
  //           $imageData: String! # $coverImage: String
  //         ) {
  //           uploadArticlePdf(
  //             data: $data
  //             filename: $filename
  //             title: $title
  //             description: $description # coverImage: $coverImage
  //             imageData: $imageData
  //           ) {
  //             success
  //             details
  //           }
  //         }
  //       `,
  //       variables: {
  //         data: data,
  //         filename: filename,
  //         title: title,
  //         description: description,
  //         imageData: coverImage,
  //       },
  //     });
  //     return response.data.uploadArticlePdf;
  //   } catch (error) {
  //     throw error;
  //   }
  // }
  // async getPackageRuns({ pageSize, page, query, sortKey, filters }: any) {
  //   console.log('getting package runs: ', { pageSize, page, query, sortKey });
  //   try {
  //     const response = await apolloClient.query({
  //       query: graphql`
  //         query getPackageRuns($pageSize: Int!, $page: Int, $query: String, $sortKey: String) {
  //           packageRuns {
  //             runs(
  //               page: $page
  //               pageSize: $pageSize
  //               query: $query
  //               sortKey: $sortKey
  //               filters: [{ key: "package_name__in", value: "INFLUENCE,DISCOVER_LEADS,NEWSLETTER_SEGMENTS" }]
  //               showAll: true
  //             ) {
  //               totalItems
  //               list {
  //                 dateRun
  //                 algoVersion
  //                 id
  //                 package
  //                 customName
  //                 serveCount
  //                 nurturecampaignSet {
  //                   id
  //                   name
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       `,
  //       variables: {
  //         pageSize,
  //         page,
  //         query,
  //         sortKey,
  //         // filters,
  //       },
  //       fetchPolicy: 'network-only',
  //     });
  //     return response.data.packageRuns;
  //   } catch (error: any) {
  //     console.warn('error getting packageRuns: ', error?.message);
  //     throw error?.message;
  //   }
  // }
  // async getCampaigns({
  //   pageSize,
  //   page,
  //   query,
  //   sortKey,
  //   filters,
  // }: {
  //   pageSize?: number;
  //   page: number;
  //   query?: string;
  //   sortKey?: string;
  //   filters: any;
  // }) {
  //   try {
  //     const response = await apolloClient.query({
  //       query: graphql`
  //         query Campaigns($pageSize: Int!, $page: Int, $query: String, $sortKey: String, $filters: [FilterType]) {
  //           nurtureLeads {
  //             campaigns(pageSize: $pageSize, page: $page, query: $query, sortKey: $sortKey, filters: $filters) {
  //               page
  //               pages
  //               totalItems
  //               list {
  //                 status
  //                 openRate
  //                 clickThroughRate
  //                 eventsOverTime
  //                 isCancellable
  //                 averageScoreIncrease
  //                 recipientCount
  //                 dateCreated
  //                 description
  //                 id
  //                 name
  //                 nurtureemailSet {
  //                   html
  //                   description
  //                   dateCreated
  //                   name
  //                   id
  //                   editorJson
  //                   sendDatetime
  //                   status
  //                   subject
  //                 }
  //                 run {
  //                   package
  //                   serveCount
  //                   dateRun
  //                   id
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       `,
  //       variables: {
  //         pageSize,
  //         page,
  //         query,
  //         sortKey,
  //         filters,
  //       },
  //       fetchPolicy: 'network-only',
  //     });
  //     return response.data.nurtureLeads.campaigns;
  //   } catch (error: any) {
  //     console.warn('error in get campaigns: ', error.message);
  //     throw error?.message;
  //   }
  // }
  // async getMarketingData() {
  //   const response = await apolloClient.query({
  //     query: graphql`
  //       query getMarketingData2 {
  //         marketing {
  //           campaigns {
  //             list {
  //               recipientCount
  //               openRate
  //               campaignId
  //               campaignName
  //             }
  //           }
  //         }
  //       }
  //     `,
  //     fetchPolicy: 'network-only',
  //   });
  //   return response.data.contentCreator.userTemplates;
  // }
  // async getNurtureEmailSettings() {
  //   const response = await apolloClient.query({
  //     query: graphql`
  //       query nurtureEmails {
  //         meAssetManager {
  //           organization {
  //             id
  //             amsponsor {
  //               outboundInsightsEmail
  //               emailsEnabled
  //             }
  //           }
  //         }
  //       }
  //     `,
  //   });
  //   return response.data.meAssetManager.organization.amsponsor;
  // }
  // async sendTestEmail(testEmailAddress: string, emailContent: any) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation sendTestEmail($testEmailAddress: String!, $emailContent: NurtureTestEmailInputType!) {
  //           nurtureLeadsSendTestEmail(testEmailAddress: $testEmailAddress, emailContent: $emailContent) {
  //             success
  //           }
  //         }
  //       `,
  //       variables: {
  //         testEmailAddress,
  //         emailContent,
  //       },
  //     });
  //     return response.data.nurtureLeadsSendTestEmail;
  //   } catch (error: any) {
  //     console.warn('error sending test sendingTestEmail: ', error.message);
  //     throw new Error(error.message);
  //   }
  // }
  // async generateSegment(advisorSetId: any) {
  //   try {
  //     const response = await apolloClient.mutate({
  //       mutation: graphql`
  //         mutation generateAnalysis($advisorSetId: ID) {
  //           newsletterSegmentsGenerateRun(advisorSetId: $advisorSetId) {
  //             runId
  //           }
  //         }
  //       `,
  //       variables: {
  //         advisorSetId,
  //       },
  //     });
  //     return response?.data?.newsletterSegmentsGenerateRun?.runId;
  //   } catch (error: any) {
  //     console.warn('error generating segment: ', error.message);
  //     throw new Error(error.message);
  //   }
  // }
  // async fetchMyAdvisors(page: number, pageSize: number, sortKey?: string, query?: string) {
  //   const resp = await apolloClient.query({
  //     query: graphql`
  //       query fetchMyAdvisors($page: Int!, $pageSize: Int!, $sortKey: String, $query: String) {
  //         myAdvisors(
  //           page: $page
  //           pageSize: $pageSize
  //           sortKey: $sortKey
  //           query: $query
  //           filters: [{ key: "themes_isnull", value: "false" }]
  //         ) {
  //           totalItems
  //           list {
  //             id
  //             manager {
  //               id
  //             }
  //             email
  //             repcrd
  //             firstName
  //             lastName
  //             title
  //             age
  //             isRia
  //             isBd
  //             aumSelfReported
  //             officeZipcode
  //             officeState
  //             currentFirmTenureYears
  //             careerTenure
  //             firmName
  //             firmCrd
  //             firmTotalAccounts
  //             firmTotalAssetsInMillions
  //             firmEtfAssetsMillions
  //             firmMfAssetsMillions
  //             firmPercentAssetsMf
  //             firmPercentAssetsEtf
  //             prospectScore
  //           }
  //         }
  //       }
  //     `,
  //     variables: {
  //       page,
  //       pageSize,
  //       sortKey,
  //       query,
  //     },
  //     fetchPolicy: 'network-only',
  //   });
  //   return resp.data.myAdvisors;
  // }
}

// export default new NurtureLeadsAPI();
