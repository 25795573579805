import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContentContainer, HeaderTextButton, H3, PreviewContainer, PrimaryButtonShort } from '../../../components';
import { SectionPageHeader } from '../../../components/SectionPageHeader';
// import BuildAwarenessAPI from '../../../api/optimizeEngagement';
import ComingSoonModal from '../../../components/ComingSoonModal';
import PageHeader from '../../../components/PageHeader';
import ArchiveTable from '../../../components/Table';
import WebinarsMultiView from '../../../assets/misc/WebinarsMultiView.svg';
import WebinarsScreen from '../../../assets/misc/WebinarsScreen.svg';
import ContactUsModal from '../../../components/ContactUsModal';

import { Title, F3 } from '../../../components';
import { formatNumber, formatTimestamp } from '../../../helpers/dataFormatter';
import { exportClientsToSpreadsheet } from '../../../helpers/exportClients';
import { colors } from '../../../styles';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const BodyContainer = styled('div')({
  margin: '16px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});

const TableHeader = styled('div')({
  height: '50px',
  padding: '0px 32px',
  backgroundColor: colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0px 0px',
  borderBottom: `1px solid ${colors.grey_01}`,
  zIndex: '500',
});

type Props = {};

type resultsType = 'productPlacements' | 'comMarketing' | 'webinars' | 'leads' | 'influence';

export const Webinars = ({}: Props) => {
  const [showComingSoon, setShowComingSoon] = useState<boolean>(false);
  const [showContactUs, setShowContactUs] = useState<boolean>(false);
  const [resultsPage, setResultsPage] = useState<resultsType>('comMarketing');
  const [tableType, setTableType] = useState('emails');
  const [pageTab, setPageTab] = useState('overview');
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState(false);
  const [searchPlaceholder, setSearchPlaceholder] = useState<string>('Search Keyword');

  const [extendSelectedKeyword, setExtendSelectedKeyword] = useState<string | null>(null);

  // const [tablePage, setTablePage] = useState(0);
  // const [pageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState('email_date');

  const [selectedMarketingData, setSelectedMarketingData] = useState<any>(null);

  const [dataTotal, setDataTotal] = useState(0);
  const [data, setData] = useState<any>([]);

  const [showReportsModal, setShowReportsModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  const toggleReportsModal = () => {
    setShowReportsModal(!showReportsModal);
  };

  const toggleContactUs = (keyword: string) => {
    if (keyword) {
      setExtendSelectedKeyword(keyword);
    }
  };

  useEffect(() => {
    fetchData(searchString);
  }, [tableType, pageTab]);

  const fetchData = async (searchTerm: any) => {
    try {
      setLoading(true);
      const dataSet = tableType === 'emails' ? comMarketing : webinars;

      // let resultFromGetData = await dataSet.getData(tablePage + 1, pageSize, searchTerm, sortKey);
      let resultFromGetData: any[] = [];

      resultFromGetData = resultFromGetData.map((item: any) => ({
        ...item,
        extend: () => toggleContactUs(item?.keyword),
      }));
      setData(resultFromGetData);
      setDataTotal(resultFromGetData.length);
      setLoading(false);
    } catch (error) {
      console.warn('Error fetching awareness outcomes: ', error);
      setLoading(false);
    }
  };

  const tableHeaders = {
    comMarketing: [
      {
        text: 'Emails',
        key: 'emails',
        onPress: () => {
          setSortKey('email_date');
          setTableType('emails');
        },
      },
      {
        text: 'Webinars',
        key: 'webinars',
        onPress: () => setTableType('webinars'),
      },
    ],
  };

  const leadSpreadsheetHeaders = [
    {
      header: 'First Name',
      key: 'firstName',
    },
    {
      header: 'Last Name',
      key: 'lastName',
    },
    {
      header: 'Rep CRD',
      key: 'repCrd',
    },
    {
      header: 'Email',
      key: 'email',
    },
  ];

  const comMarketing = {
    headerData: [
      {
        header: 'Email Subject',
        key: 'subject',
        sortKey: 'subject',
      },
      {
        header: 'Date',
        key: 'emailDate',
        // sortKey: 'email_date',
        formatData: (row: any) => {
          // console.log('emailData: ', row);
          return moment(row.emailDate).utc().format('dddd, M/DD/YYYY');
        },
      },
      {
        header: 'Deliveries',
        key: 'deliveryCount',
        // sortKey: 'delivery_count',
        formatData: (row: any) => {
          return formatNumber(row.deliveryCount);
        },
      },
      {
        header: 'Opens',
        key: 'openCount',
        // sortKey: 'registeredCount',
        formatData: (row: any) => {
          return formatNumber(row.openCount);
        },
      },
      {
        header: 'Clicks',
        key: 'uniqueCrdCount',
        // sortKey: 'attendedCount',
        formatData: (row: any) => {
          return formatNumber(row.uniqueCrdCount);
        },
      },
      {
        header: ' ',
        key: 'uniqueCrdCount',
        // sortKey: 'attendedCount',
        formatData: (row: any, hover: boolean) => {
          return (
            <H3
              sx={{ minWidth: '140px' }}
              onClick={(event) => {
                event.stopPropagation();
                exportClientsToSpreadsheet(row.leads, leadSpreadsheetHeaders, null, 'CommunityMarketing');
              }}
            >
              {hover && row?.leads?.length !== 0 ? 'Download Leads' : <div style={{ width: '120px' }} />}
            </H3>
          );
        },
      },
    ],
    apiPagination: false,
    getData: async (page: number, pageSize: number, query: string, sortKey: string) => {
      try {
        //const result = await DataAPI.getFunds(searchString);
        // const buildAwarenessResults = await BuildAwarenessAPI.getEmails(pageSize, page, query, sortKey);
        // return buildAwarenessResults;
        return [];
      } catch (error) {
        console.warn(error);
        throw error;
      }
    },
  };

  const webinars = {
    headerData: [
      {
        header: 'Webinar Subject',
        key: 'subject',
        sortKey: 'subject',
      },
      {
        header: 'Event Date',
        key: 'eventDate',
        sortKey: 'eventDate',
        formatData: (row: any) => {
          return formatTimestamp(row.eventDate);
        },
      },
      {
        header: 'Registered Count',
        key: 'registeredCount',
        sortKey: 'registeredCount',
        formatData: (row: any) => {
          return formatNumber(row.registeredCount);
        },
      },
      {
        header: 'Attended Count',
        key: 'attendedCount',
        sortKey: 'attendedCount',
        formatData: (row: any) => {
          return formatNumber(row.attendedCount);
        },
      },
      {
        header: ' ',
        key: 'uniqueCrdCount',
        // sortKey: 'attendedCount',
        formatData: (row: any, hover: boolean) => {
          return (
            <H3
              sx={{ minWidth: '140px' }}
              onClick={(event) => {
                event.stopPropagation();
                exportClientsToSpreadsheet(row.leads, leadSpreadsheetHeaders, null, 'CommunityMarketing');
              }}
            >
              {hover && row?.leads?.length !== 0 ? 'Download Leads' : <div style={{ width: '120px' }} />}
            </H3>
          );
        },
      },
    ],
    apiPagination: false,
    getData: async (page: number, pageSize: number, query: string, sortKey: string) => {
      try {
        // const buildAwarenessResults = await BuildAwarenessAPI.getWebinars(pageSize, page, query, sortKey);
        // return buildAwarenessResults;
        return [];
      } catch (error) {
        console.warn(error);
        throw error;
      }
    },
  };

  return (
    <ContentContainer>
      <SectionPageHeader
        buttonText={'Zoom Out'}
        buttonAction={() => navigate('/build')}
        subtitle={'Build Awareness'}
        title={'Webinars'}
        description={'Host a webinar with Tifin to get the word out about your products'}
        backgroundImage={WebinarsMultiView}
      />
      <PageHeader
        selectedButton={pageTab}
        leftButtons={[
          { text: 'Overview', key: 'overview', onPress: setPageTab },
          { text: 'Outcomes', key: 'outcomes', onPress: setPageTab },
        ]}
      />
      {pageTab === 'overview' ? (
        <div>
          <PreviewContainer>
            <div style={{ minWidth: 250, width: '33%' }}>
              <Title style={{ margin: '20px 0px', color: colors.grey_03 }}>A Means to More Sales</Title>
              <F3 style={{ margin: '0px 0px 20px' }}>
                Team up with TIFIN’s publisher partners to create advisor-targeted webinars showcasing your firm’s
                insights, thought leadership, and thematic expertise.
              </F3>
              <F3 style={{ margin: '0px 0px 20px' }}>
                Our webinars enable and facilitate meaningful connections between Asset Managers and Wealth Managers,
                generating high-quality leads. All interactions generated from audiences will be incorporated into the
                TIFIN Asset Management Platform.
              </F3>
              <PrimaryButtonShort children={'Start by Getting in Touch'} onClick={() => setShowContactUs(true)} />
            </div>
            <img style={{ width: '66%' }} src={WebinarsScreen} alt="webinar" />
          </PreviewContainer>
        </div>
      ) : (
        <BodyContainer>
          <TableHeader>
            <div>
              {/* @ts-ignore */}
              {tableHeaders[resultsPage].map((item: any, index) => (
                <HeaderTextButton
                  key={index + 342421}
                  id={`table-header-button-${item.text.replace(' ', '-')}`}
                  onClick={() => item.onPress(item.key ? item.key : item.text)}
                  style={
                    tableType === item.key || tableType === item.text ? { fontWeight: '500', color: colors.black } : {}
                  }
                  children={item.text}
                  disabled={loading}
                />
              ))}
            </div>
          </TableHeader>
          <ArchiveTable
            headerData={tableType === 'emails' ? comMarketing?.headerData : webinars?.headerData}
            data={data}
            hideShadow={true}
            dataControls={{
              selections: {},
              setSelections: () => {},
              searchFunc: setSearchString,
              searchString,
              searchPlaceholder: `Search for ${searchPlaceholder}...`,
            }}
            paginationControls={false}
            loading={loading}
            //@ts-ignore
            onRowPress={(row: any) => {
              console.log('selecting row in awareness outcomes', row);
              if (resultsPage !== 'productPlacements' && resultsPage !== 'comMarketing') {
                setShowComingSoon(true);
              } else if (resultsPage === 'comMarketing') {
                setSelectedMarketingData(row);
              } else {
                toggleReportsModal();
              }
            }}
            newItems={[]}
          />
        </BodyContainer>
      )}
      <ComingSoonModal
        modalStatus={showComingSoon}
        closeModal={toggleComingSoon}
        custom={'This feature is currently being built, please check back soon for updates.'}
      />
      <ContactUsModal
        modalStatus={showContactUs}
        closeModal={() => setShowContactUs(false)}
        requestNote={`Information on build awareness webinars requested.`}
        requestType={'Build Awareness Webinars'}
      />
    </ContentContainer>
  );
};
