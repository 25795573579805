import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import { TextField, IconButton } from '@mui/material';
import { colors } from '../styles';
import { UPLOAD_ARTICLE } from '../api/nurtureProspects';
import closeIcon from '../assets/icons/close.svg';
import { toBase64 } from '../helpers/fileHelper';
import { F4, CardHeader, PrimaryButtonShort } from '.';
import Loader from './Loader';
import { useMutation } from '@apollo/client';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    // width: '500px',
    // height: '200px',
    backgroundColor: colors.white_01,
    //paddingBottom: 120,
    overflowY: 'scroll',
    borderRadius: '5px',
    padding: '0px 16px 16px',
  },
  headerStyle: {
    fontSize: 30,
    fontWeight: 500,
    width: '100%',
    textAlign: 'center',
    color: '#404B50',
  },
  subHeader: {
    color: '#758790',
    fontSize: 18,
    fontWeight: 400,
    width: 'fit-content',
    maxWidth: 500,
    textAlign: 'center',
  },
  browseFiles: {
    fontWeight: '600',
    cursor: 'pointer',
  },
  uploadSubheader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    marginTop: '-7px',
    color: '#758790',
  },
  uploadHeader: {
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#404B50',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 0px 16px',
    color: colors.grey_03,
    borderBottom: `1px solid ${colors.grey_00}`,
  },
  fileUploadBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '660px',
    height: '150px',
    backgroundColor: '#EFF6F5',
    borderRadius: '4px',
    padding: '4px',
    cursor: 'pointer',
    border: '1px dashed #758790',
  },
  fieldOverride: {
    '& label.Mui-focused': {
      color: colors.grey_02,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
    },
  },
  fileInfoRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    color: '#758790',
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0px 0px 0px 16px',
    width: '90px',
    alignItems: 'center',
  },
  fileUploadStatus: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: '16px 0px',
  },
  outerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: '#EFF6F5',
    borderRadius: '4px',
    padding: '10px 10px',
  },
  headerButton: {
    height: 10,
    width: 60,
  },
}));

const UploadButton = styled('label')`
    margin: 20px 0;
    padding: 6px 20px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    background: ${colors.black_01};
    border: 2px solid ${colors.black_01};
    color: white;
    font-weight: 400;
    font-size: 14;
    :hover {
      opacity: 0.7;
    },
    :disabled {
      background: ${colors.grey_02};
      color: ${colors.white_01};
      padding: 9px 20px;
      border: 1px solid ${colors.grey_02};
    },
`;

interface Props {
  modalStatus: boolean;
  closeModal: any;
  refresh: any;
}

const UploadArticle = ({ modalStatus, closeModal, refresh }: Props) => {
  const classes = useStyles();
  const defaultText = 'This page is currently being built, please check back soon for updates.';
  const [emphasis, setEmphasis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState<any>(null);
  const [selectedCoverImage, setSelectedCoverImage] = useState<any>(null);
  const [articleInfo, setArticleInfo] = useState<any>({
    title: 'Enter your article title',
    description: 'Please enter a description of your article here...',
  });
  const [errors, setErrors] = useState<any>(null);

  const [uploadArticle] = useMutation(UPLOAD_ARTICLE, {
    onCompleted: () => {
      setLoading(false);
      refresh();
      closeModal();
    },
  });

  const prepAndUploadArticle = async () => {
    //setArticleTitle(newArticleTitle);
    setLoading(true);

    setErrors(null);
    const newErrors: any[] = [];
    try {
      if (!articleInfo?.title || articleInfo.title === '') {
        newErrors.push('No title');
      }
      if (!articleInfo?.description || articleInfo.description === '') {
        newErrors.push('No description');
      }
      if (!selectedPdf) {
        newErrors.push('No article');
      }

      if (newErrors.length === 0) {
        const newFile = selectedPdf;
        const unformattedBase64: any = await toBase64(selectedPdf);
        const base64Text = unformattedBase64.split(',').pop();

        let base64Image: any = ' ';
        if (selectedCoverImage) {
          const unformattedBase64Image: any = await toBase64(selectedCoverImage);
          base64Image = unformattedBase64Image.split(',').pop();
        }

        const formattedFileName = selectedPdf?.name?.replaceAll(' ', '_').slice(0, 48);
        // const formattedFileName = selectedPdf?.name;
        // console.log('formattedFileName: ', formattedFileName);

        uploadArticle({
          variables: {
            data: base64Text,
            filename: formattedFileName,
            title: articleInfo.title,
            description: articleInfo.description,
            imageData: base64Image,
          },
        });
      } else {
        setLoading(false);
        setErrors(newErrors);
      }
    } catch (error: any) {
      setLoading(false);
      newErrors.push('Unknown Error');
      setErrors(newErrors);
    }
  };

  const allowDrop = (event: any) => {
    setEmphasis(!emphasis);
    event.preventDefault();
  };

  const drop = (event: any) => {
    event.preventDefault();
    const data = event.dataTransfer.files[0];
    setSelectedPdf(data);
    setEmphasis(false);
  };

  return (
    <Modal
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <div className={classes.modalContainer}>
        <div>
          <CardHeader>Upload an article PDF</CardHeader>
          <div className={classes.outerBox}>
            <div className={classes.fileUploadBox} onDrop={drop} onDragOver={allowDrop}>
              <p className={classes.uploadHeader}>
                Drop your pdf file here, or{' '}
                <input
                  type="file"
                  id="upload-file-button"
                  accept=".pdf"
                  hidden
                  onChange={(e: any) => setSelectedPdf(e.target.files[0])}
                  //inputComponent={`<p>Browse</p>`}
                  //dangerouslySetInnerHTML={<p className={classes.browseFiles}>Browse files</p>}
                />
                <label className={classes.browseFiles} htmlFor="upload-file-button">
                  Browse files
                </label>
              </p>
              <p className={classes.uploadSubheader}>Max file size 1MB</p>
            </div>
          </div>
          {selectedPdf && (
            <div className={classes.fileInfoRow}>
              <p>{selectedPdf.name}</p>
              <div className={classes.fileInfo}>
                <p>{(Number.parseFloat(selectedPdf.size) / 1000).toFixed(1)} KB</p>
                <IconButton
                  onClick={() => {
                    setErrors(null);
                    setSelectedPdf(null);
                  }}
                >
                  <img src={closeIcon} alt="close" />
                </IconButton>
              </div>
            </div>
          )}
          {errors && errors.includes('No article') && (
            <div className={classes.fileInfoRow}>
              <F4 style={{ color: colors.red }}>{'Please upload a article in pdf form.'}</F4>
            </div>
          )}
          <TextField
            id="email-field"
            label="Title"
            variant="outlined"
            size="small"
            sx={{ margin: '12px 0', width: '100%' }}
            value={articleInfo?.title ? articleInfo?.title : ''}
            className={classes.fieldOverride}
            helperText={errors && errors.includes('No title') ? 'Please enter a valid article title' : ''}
            focused
            onChange={(event) => setArticleInfo({ ...articleInfo, title: event.target.value.slice(0, 120) })}
          />
          <F4 style={{ color: colors.grey_02, margin: '4px 0px 20px', textAlign: 'right' }}>
            {articleInfo?.title?.length}/120 characters
          </F4>
          <div className={classes.fileInfoRow}>
            <p className={classes.uploadHeader}>
              <input
                type="file"
                id="upload-cover-image-button"
                accept=".png"
                hidden
                onChange={(e: any) => setSelectedCoverImage(e.target.files[0])}
              />
              <UploadButton htmlFor="upload-cover-image-button">Upload cover image</UploadButton>
            </p>
            {selectedCoverImage && (
              <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '0px 0px 0px' }}>
                <p>{selectedCoverImage.name}</p>
                <div className={classes.fileInfo}>
                  {/* <p>{Number.parseFloat(selectedCoverImage.size / 1000).toFixed(1)} KB</p> */}
                  <IconButton
                    onClick={() => {
                      setErrors(null);
                      setSelectedCoverImage(null);
                    }}
                  >
                    <img src={closeIcon} alt="close" />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
          <TextField
            id="email-field"
            label="Description"
            variant="outlined"
            multiline
            rows={4}
            size="small"
            sx={{ margin: '12px 0', width: '100%' }}
            value={articleInfo?.description ? articleInfo?.description : ''}
            className={classes.fieldOverride}
            helperText={
              errors && errors.includes('No description') ? 'Please enter a description for your article' : ''
            }
            focused
            onChange={(event) => setArticleInfo({ ...articleInfo, description: event.target.value.slice(0, 500) })}
          />
          <F4 style={{ color: colors.grey_02, margin: '4px 0px 20px', textAlign: 'right' }}>
            {articleInfo?.description?.length}/500 characters
          </F4>
        </div>
        <div
          style={{ display: 'flex', height: '30px', width: '100%', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          {errors && errors.includes('Unknown Error') && (
            <F4 style={{ color: colors.grey_02, margin: '4px 0px 0px' }}>
              {'An unknown error has occured with your upload'}
            </F4>
          )}
          <PrimaryButtonShort
            sx={{
              opacity: 0.8,
              background: colors.grey_02,
              border: `1px solid ${colors.grey_02}`,
              margin: '0px 8px',
              '&:hover': { opacity: '60%' },
            }}
            onClick={closeModal}
          >
            Close
          </PrimaryButtonShort>
          <PrimaryButtonShort onClick={prepAndUploadArticle}>
            {loading ? <Loader hideText={true} size={'10px'} color={colors.white_01} /> : 'Upload'}
          </PrimaryButtonShort>
        </div>
      </div>
    </Modal>
  );
};

export default UploadArticle;
