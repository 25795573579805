import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { colors, typography } from '../../../../styles';
import { useStoreState } from '../../../../store';
import { exportClientsToSpreadsheet } from '../../../../helpers/exportClients';
import ArchiveTable from '../../../../components/Table';
// import { TablePresets } from '../tableSettings';
import ComingSoonModal from '../../../../components/ComingSoonModal';
import LoadingModal from '../../../../components/LoadingModal';
// import { generateCardProps } from '../../../../components/ClientCard/CardProps';
import CampaignEngagement from './CampaignEngagement';
import ClientModal from '../../../../components/ClientModal';
import { SectionPageHeader } from '../../../../components/SectionPageHeader';
import AmplitudeChart from '../../../../components/AmplitudeChart';
import { H2 } from '../../../../components';
import CampaignPerformanceCard from '../../../../components/CampaignPerformanceCard';
import SubscriptionModal from '../../../../components/SubscriptionModal';
import { useQuery } from '@apollo/client';
import { GET_CAMPAIGNS } from '../../../../api/nurtureProspects';
import { formatTimestamp, formatPackageName } from '../../../../helpers/dataFormatter';

const Container = styled('div')({
  width: '100%',
  margin: '0px auto 0px auto',
  overflowY: 'auto',
});

const PageBody = styled('div')({
  width: '95%',
  margin: '20px auto',
});

const SectionHeader = styled('h1')({
  ...typography.mainFont,
  display: 'flex',
  flex: 1,
  fontSize: 16,
  fontWeight: 500,
  color: '#404B50',
  // margin: '0px auto 0px',
  textAlign: 'left',
  padding: '8px 0px',
  lineHeight: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BodyContainer = styled('div')({
  margin: '0px auto 50px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: colors.main_box_shadow,
  borderRadius: '5px',
});
const RightButton = styled('button')({
  color: 'white',
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '5px',
  padding: '0px 20px',
  background: '#242B2F',
  height: 30,
  margin: '10px 0px',
  cursor: 'pointer',
  lineHeight: 1,
  fontSize: 14,
  border: 0,
  '&:hover': {
    opacity: 0.7,
  },
  '&:disabled': {
    color: 'white',
    background: '#D2D7DA',
  },
});

const TableHeader = styled('div')({
  height: '50px',
  padding: '0px 32px',
  backgroundColor: colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '5px 5px 0px 0px',
  borderBottom: `1px solid ${colors.grey_01}`,
  zIndex: '500',
});

const LeftButton = styled('button')({
  fontFamily: 'Inter',
  height: '30px',
  borderRadius: '30px',
  border: `0px solid ${colors.black}`,
  color: colors.grey_02,
  fontWeight: '500',
  fontSize: '16px',
  backgroundColor: colors.white,
  padding: '0px 0px',
  cursor: 'pointer',
  letterSpacing: '0px',
  marginRight: '20px',
});

const CampaignHeaderData = [
  {
    header: 'Campaign Name',
    key: 'name',
    sortKey: 'name',
    formatData: (row: any) => {
      return row?.campaignName ? row?.campaignName : row?.name;
    },
    bold: true,
  },
  {
    header: 'Description',
    key: 'name',
    sortKey: 'name',
    formatData: (row: any) => {
      return row?.description;
    },
  },
  {
    header: 'Created',
    key: 'dateCreated',
    sortKey: 'date_created',
    formatData: (row: any) => {
      return row?.startDate ? formatTimestamp(row.startDate) : formatTimestamp(row.dateCreated);
    },
  },
  {
    header: 'Target Audience',
    sortKey: 'serve_count',
    formatData: (row: any) => formatPackageName(row?.run?.package),
  },
  // {
  //   header: 'Audience Size',
  //   sortKey: 'serve_count',
  //   formatData: (row:any) => row?.run?.serveCount,
  // },
  {
    header: 'Email Count',
    sortKey: 'nurtureemail_Set',
    formatData: (row: any) => {
      // console.log('in format email count: ', row?.nurturecampaignSet?.[0].nurtureemailSet);
      return row?.nurtureemailSet?.length
        ? row?.nurtureemailSet?.length
        : row?.marketingemailSet?.length
          ? row?.marketingemailSet?.length
          : '-';
    },
  },
];

export const Engagement = () => {
  const navigate = useNavigate();
  const [resultsPage, setResultsPage] = useState('campaigns');
  const [searchString, setSearchString] = useState('');
  const [dataTotal, setDataTotal] = useState(0);
  const [sortKey, setSortKey] = useState('-date_created');
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [expandedModal, setExpandedModal] = useState(false);
  const [tablePage, setTablePage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selectedArchives, setSelectedArchives] = useState({});
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showCampaignDetailsModal, setShowCampaignDetailsModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const subscriptionsList: any = useStoreState((state) => state.user.subscriptions).map((sub: any) => sub.package);
  const unformattedName = 'NURTURE_LEADS';

  const userInfo = useStoreState((state) => state.user.info);
  // const userInfo = appState((state) => state.user.userInfo);
  // const subscriptions = appState((state) => state.user.subscriptions);

  const [tableType, setTableType] = useState('Active');

  const demoAccount = useStoreState((state) => state.user.demoAccount);

  const toggleComingSoon = () => {
    setShowComingSoon(!showComingSoon);
  };

  useEffect(() => {
    setSortKey('name');
    setTablePage(0);
    setSearchString('');
  }, [resultsPage, tableType]);

  const toggleShowCampaignDetailsModal = () => {
    setShowCampaignDetailsModal(!showCampaignDetailsModal);
  };

  useEffect(() => {
    setTablePage(0);
  }, [searchString, sortKey]);

  const { data, loading, error } = useQuery(GET_CAMPAIGNS, {
    variables: {
      page: tablePage,
      pageSize,
      query: searchString,
      sortKey,
      filters: [{ key: 'status', value: 'Active' }],
    },
    onCompleted: (data: any) => {
      setDataTotal(data?.nurtureLeads?.campaigns?.totalItems);
    },
    onError: (error: any) => {
      console.warn('Error fetching Archive: ', error);
    },
  });

  // const fetchData = async (searchTerm: string) => {
  //   try {
  //     // setLoading(true);
  //     // console.log('resultsPage + tableType', resultsPage + tableType);
  //     //@ts-ignore
  //     const resultFromGetData = await TablePresets[resultsPage + tableType].getData(
  //       tablePage + 1,
  //       pageSize,
  //       searchTerm,
  //       sortKey,
  //       [{ key: 'status', value: 'Active' }],
  //     );
  //     // setData(resultFromGetData.list);
  //     // setLoading(false);
  //   } catch (error) {
  //     console.warn('Error fetching Archive: ', error);
  //     setLoading(false);
  //   }
  // };

  const openCampaignDetails = (campaign: any) => {
    setSelectedCampaign(campaign);
    toggleShowCampaignDetailsModal();
  };

  const tableHeaders = {
    campaigns: [
      //{ text: 'Individuals', onPress: setDataType },
      {
        text: 'Active Campaigns',
        key: 'Active',
        onPress: () => setTableType('Active'),
      },
      {
        text: 'Completed',
        key: 'Completed',
        onPress: () => setTableType('Completed'),
      },
    ],
  };

  const openArchive = () => {};

  // const downloadResults = async () => {
  //   setLoadingCSV(true);

  //   let sortKeyForAll = '-run_history__date_run';

  //   if (resultsPage === 'individualLeads') {
  //     sortKeyForAll = '-date';
  //   }

  //   try {
  //     //@ts-ignore
  //     const allResults = await TablePresets[resultsPage + tableType].getData(1, dataTotal, '', sortKeyForAll);
  //     exportClientsToSpreadsheet(
  //       allResults.list,
  //       //@ts-ignore
  //       TablePresets[resultsPage + tableType].spreadSheetHeaders,
  //       {},
  //       tableType + '-AllResults',
  //     );
  //     setLoadingCSV(false);
  //   } catch (error: any) {
  //     console.warn('error downloading all results:', error.message);
  //   }
  // };

  const closeUserProfile = (client: any) => {
    setSelectedClient(null);
    setModalOpen(false);
    setExpandedModal(false);
  };

  const textData = {
    radarHeading: 'Fund Characteristics vs. Individual Interest',
    allChartHeading: 'All Individuals',
    bestChartHeading: 'Best Individuals',
    themesHeading: 'Your Fund Themes',
    topTitle: 'Top Lead',
    //metricName: 'Individual',
    distillExplanation:
      'is the result of a thematic and financial analysis of each Individual. The higher the score, the stronger their financials, and the more their thematic activity is aligned with your fund.',
  };

  const generateCardProps = (
    modalOpen: boolean,
    setModalOpen: any,
    closeUserProfile: any,
    textData: any,
    userInfo: any,
  ) => ({
    advisorLeads: {
      modalOpen,
      setModalOpen,
      contentType: 'advisorData',
      close: closeUserProfile,
      radarFeatures: [
        { label: 'All Advisors', id: 'all_advisors' },
        { label: 'Selected Advisor', id: 'advisor' },
      ],
      textData,
      userData: userInfo,
    },
  });

  const cardProps: any = generateCardProps(modalOpen, setModalOpen, closeUserProfile, textData, userInfo);

  return (
    <>
      <LoadingModal
        modalStatus={loadingCSV}
        closeModal={() => setLoadingCSV(false)}
        headerText={'Downloading all results'}
        subtext={'This may take a few moments...'}
        buttonText={'Cancel'}
      />
      <Container>
        <SectionPageHeader
          buttonText={'Zoom Out'}
          buttonAction={() => navigate('/nurture')}
          subtitle={'Nurture Prospects'}
          title={'View Engagement'}
          description={'This is your home for analyzing nurture campaign engagement.'}
          // primaryButtonText={'Create a Campaign'}
          // primaryButtonAction={() => }
        />
        {selectedCampaign ? (
          <PageBody>
            <CampaignEngagement
              campaign={selectedCampaign}
              setCampaign={setSelectedCampaign}
              openArchive={openArchive}
            />
          </PageBody>
        ) : (
          <>
            <PageBody>
              <div style={{ margin: '32px 0px 10px' }}>
                <H2>Campaign Overview</H2>
              </div>
              <div style={{ display: 'flex' }}>
                <AmplitudeChart
                  title={'Recent events across all campaigns'}
                  style={{ margin: '16px 16px 0px 0px' }}
                  demoMode={demoAccount}
                />
                <CampaignPerformanceCard campaign={data?.nurtureLeads?.campaigns?.list} />
              </div>
              <SectionHeader style={{ margin: '26px 0px 0px' }}>
                Select a campaign below to see it's performance
              </SectionHeader>
              <BodyContainer style={{ margin: '16px 0px 0px' }}>
                <TableHeader>
                  <div>
                    {/* @ts-ignore */}
                    {tableHeaders[resultsPage].map((item, index) => (
                      <LeftButton
                        key={index + 342421}
                        id={`table-header-button-${item.text.replace(' ', '-')}`}
                        onClick={() => item.onPress(item.key ? item.key : item.text)}
                        style={tableType === item.key || tableType === item.text ? { color: colors.black } : {}}
                        children={item.text}
                        disabled={loading}
                      />
                    ))}
                  </div>
                  {resultsPage === 'nurturedResults' && (
                    <RightButton
                      key={342421}
                      id={`table-header-button-download`}
                      // onClick={downloadResults}
                      children={'Export All'}
                      disabled={loading}
                    />
                  )}
                </TableHeader>
                <div>
                  <ArchiveTable
                    //  @ts-ignore
                    headerData={CampaignHeaderData}
                    data={data?.nurtureLeads?.campaigns?.list ?? []}
                    hideShadow={true}
                    dataControls={{
                      selections: selectedArchives,
                      setSelections: setSelectedArchives,
                      searchFunc: setSearchString,
                      searchPlaceholder: 'Search for an campaign...',
                      searchString,
                    }}
                    paginationControls={
                      //  @ts-ignore
                      true
                        ? {
                            tablePage,
                            setTablePage,
                            pageSize,
                            setPageSize,
                            totalItems: dataTotal,
                            sortKey,
                            setSortKey,
                          }
                        : null
                    }
                    initSortColumn={'dateCreated'}
                    search={resultsPage !== 'startCampaign' && resultsPage !== 'activeCampaign'}
                    loading={loading}
                    // @ts-ignore
                    // setRating={TablePresets[resultsPage + tableType].setRating}
                    onRowPress={resultsPage === 'campaigns' ? openCampaignDetails : null}
                    newItems={[]}
                  />
                </div>
              </BodyContainer>
            </PageBody>
          </>
        )}
      </Container>
      <ClientModal
        packageName={tableType}
        client={{
          ...selectedClient,
          leadScore:
            selectedClient?.leadScore || selectedClient?.leadScore === 0
              ? selectedClient.leadScore
              : selectedClient?.score
                ? selectedClient?.score
                : '',
        }}
        expandedModal={expandedModal}
        setExpandedModal={setExpandedModal}
        {...cardProps[tableType]}
      />
      <SubscriptionModal
        modalStatus={!subscriptionsList.includes(unformattedName)}
        closeModal={() => navigate('/drive-sales')}
        requestNote={`User would like to activate the nurture prospects`}
        requestType={'Subscription Request'}
      />
      <ComingSoonModal modalStatus={showComingSoon} closeModal={toggleComingSoon} />
    </>
  );
};
