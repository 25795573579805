import React from 'react';
import { BeatLoader } from 'react-spinners';
import { keyframes } from '@mui/system';
import { styled } from '@mui/material/styles';
import { colors } from '../styles';
import { F3 } from '.';
import Intro_Default from '../assets/visuals/distribution-wheel/Intro_Default.svg';

interface Props {
  inputMargin?: any;
  width?: string;
  color?: string;
  size?: string;
  hideText?: boolean;
}

const Root = styled('div')({
  // width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const LoaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // margin: '0px 0px 60px',
});

const Loader = ({ inputMargin, width, color, size, hideText }: Props) => {
  return (
    <Root sx={{ margin: inputMargin ? inputMargin : '15% auto', width: width }}>
      <LoaderContainer>
        <BeatLoader size={size ? size : '16px'} color={color} />
        {/* <Circle
          src={Intro_Default}
          //@ts-ignore
          style={{ width: '16px', height: '16px' }}
          sx={{
            opacity: 0,
            animation: `${rotate} infinite ease`,
          }}
        /> */}
      </LoaderContainer>
      {!hideText && (
        <F3 sx={{ color: colors.grey_02, textAlign: 'center', margin: '60px -130px 0px' }}>
          This may take a few moments...
        </F3>
      )}
    </Root>
  );
};

export default Loader;
