import { gql } from '../__generated__';

export const GET_ORGANIZATION = gql(`
  query getOrganizations($query: String, $pageSize: Int, $page: Int, $sortKey: String) {
        platformManagement {
          organizations(page: $page, pageSize: $pageSize, query: $query, sortKey: $sortKey) {
            page
            pages
            totalItems
            list {
              id
              name
              dateCreated
              dateUpdated
              backgroundColor
              isDemoOrg
              logoFile
              logoHeight
              logoWidth
              amsponsor {
                emailsEnabled
                id
                outboundInsightsEmail
                sponsorClean
              }
              organizationpackagesubscriptionSet {
                package
                quota
              }
              assetmanagerprofileSet {
                lastName
                firstName
                id
                company
                timezone
                phone
                user {
                  lastName
                  email
                  dateJoined
                  lastLogin
                  isStaff
                  username
                  firstName
              }
            }
          }
        }
      }  
    }
`);


export const CREATE_CHAT = gql(`
mutation createChat($prompt: String!) {
  createChat(prompt: $prompt) {
    success
    chat {
      id
      segmentationchatmessageSet {
        id
        message
        chatType
        queriedData
      }
    }
  }
}`)

export const REPLY_TO_CHAT = gql(`
mutation replyToChat($chatId: ID!, $message: String!) {
  replyToChat(chatId: $chatId, message: $message) {
    success
    chat {
      id
      segmentationchatmessageSet {
        id
        message
        chatType
        queriedData
      }
    }
  }
}`)


export const REVERT_USER = gql(`
  mutation revertUser {
    platformManagementClearImpersonate {
      success
    }
  }
`);

export const CREATE_ORGANIZATION = gql(`
  mutation createOrganization(
    $orgName: String!
    $sponsorClean: String!
    $insightsEmail: String
    $adminEmail: String
    $logoData: String!
  ) {
    platformManagementCreateAmOrganization(
      organizationName: $orgName
      sponsorClean: $sponsorClean
      insightsEmail: $insightsEmail
      adminEmail: $adminEmail
      logoData: $logoData
    ) {
      details
      success
    }
  }
`);

export const UPLOAD_BESPOKE_RUN = gql(`
  mutation uploadBespokeRun($assetManagerId:ID!, $runKey:String!, $runDate:Date!, $journeyName:String!) {
    influencePullBespoke(assetManagerId: $assetManagerId, runKey: $runKey, runDate: $runDate, journeyName: $journeyName) {
      success
      details
    }
  }
`);

export const GET_JOB_BY_ID = gql(`
  query getDramatiqJobId($jobId: ID!) {
    jobs {
      fromDramatiqId(jobId: $jobId) {
        id
        status
        message
      }
    }
  }
`);

export const IMPERSONATE_USER = gql(`
        mutation impersonateUser($amProfileId: ID!) {
          platformManagementImpersonateUser(amProfileId: $amProfileId) {
            success
            details
        }
      }
`);

export const CREATE_SUBSCRIPTION = gql(`
  mutation createSubscription($organizationId: ID!, $packageName: String!, $quota: Int!) {
          platformManagementCreateAmSubscription(organizationId: $organizationId, packageName: $packageName, quota: $quota) {
            details
            success
          }
        }
  `);

export const INVITE_USER = gql(`
mutation inviteUserToOrganization($emailAddress: String!, $organizationId: ID!) {
  platformManagementInviteUserToOrganization(emailAddress: $emailAddress, organizationId: $organizationId) {
    success
    details
  }
}`);
