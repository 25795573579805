import React from 'react';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
import { colors } from '../styles';
import { PrimaryButton, H2, F3 } from '.';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    width: '500px',
    // height: '200px',
    backgroundColor: colors.white_01,
    //paddingBottom: 120,
    borderRadius: '5px',
    padding: '16px 32px',
  },
}));

interface Props {
  modalStatus: boolean;
  closeModal: any;
  header: string;
  body: string;
}

const ErrorModal = ({ modalStatus, closeModal, header, body }: Props) => {
  const classes = useStyles();
  const defaultText = 'Please contact your customer success representative.';

  return (
    <Modal
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.root}
    >
      <div className={classes.modalContainer}>
        <div>
          <H2>{header}</H2>
          <F3 sx={{ color: colors.grey_02 }}>{body ? body : defaultText}</F3>
        </div>
        <PrimaryButton onClick={closeModal}>Close</PrimaryButton>
      </div>
    </Modal>
  );
};

export default ErrorModal;
