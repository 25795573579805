import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../../styles';
import { ModalContainer, ModalRoot, PrimaryButtonShort } from '..';
import { useStoreState, useStoreActions } from '../../store';

import {
  UPLOAD_ADVISOR_DATASET,
  VALIDATE_SF_INTEGRATION,
  CREATE_DYNAMICS_INTEGRATION,
  IMPORT_DYNAMICS_CONTACTS,
  GET_UPLOAD_JOB,
  UPDATE_SF_INTEGRATION,
  UPDATE_HS_INTEGRATION,
  CREATE_HS_INTEGRATION,
  GET_HS_FIELDS,
  SAVE_ADVISOR_SET,
} from '../../api/integrations';

import CRMSelection from './CRMSelection';
import Dynamics from './Dynamics';

import HubspotIntegrations from './HubspotIntegrations';
import HubspotNewIntegration from './HubspotNewIntegration';

import Salesforce from './Salesforce';
import SalesforceIntegrations from './SalesforceIntegrations';
import CrmAdvisorSets from './CrmAdvisorSets';
import CrmMatchHeaders from './CrmMatchHeaders';
import CrmIntegrationConfig from './CrmIntegrationConfig';
import CrmStatus from './CrmStatus';

import Landing from './Landing';
import SampleSpreadsheet from './SampleSpreadsheet';
import UploadFile from './UploadFile';
import InfoView from './InfoView';
import ManualInput from './ManualInput';
import UploadFlatFile from './UploadFlatFile';
//@ts-ignore
import close_icon from '../../assets/icons/close.svg';
import { formatPercent } from '../../helpers/dataFormatter';
import { useLocation } from 'react-router-dom';
import MatchHeaders from './MatchHeaders';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { randomNumber } from '../../helpers/numberGenerator';

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: '24px',
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  //   margin: '15px 10px 0px 10px',
  padding: '10px 0px',
  '&:hover': {
    opacity: 0.7,
  },
});

type ModalPageType =
  | 'Landing'
  | 'CRMSelection'
  | 'UploadSpreadsheet'
  | 'SampleSpreadsheet'
  | 'UploadFile'
  | 'InfoView'
  | 'Dynamics'
  | 'MatchHeaders'
  | 'Hubspot'
  | 'HubspotNewIntegration'
  | 'HubspotMatchHeaders'
  | 'HubspotMatchImportHeaders'
  | 'HubspotMatchExportHeaders'
  | 'HubspotAdvisorSets'
  | 'Salesforce'
  | 'ManualInput'
  | 'SalesforceIntegrations'
  | 'SalesforceMatchHeaders'
  | 'SalesforceAdvisorSets'
  | 'UploadFlatFile'
  | 'SfCrmIntegrationConfig'
  | 'HsCrmIntegrationConfig'
  | 'CrmStatus'
  | null;

type Props = {
  // visible: boolean;
  modalPage: ModalPageType;
  setModalPage: any;
  closeModal: any;
  advisorType?: string;
  onResultsReturned?: any;
  urlQuery?: any;
  integrationId?: string | null;
  setIntegrationId?: any;
  integrationDetails?: any;
  setIntegrationDetails?: any;
  enableUploads?: boolean;
};

type infoPageCopyType = {
  header: string;
  subheader?: string | JSX.Element;
  loader: boolean;
};

const crmAcceptedFields = [
  'read_repcrd',
  'email',
  'write_repcrd',
  'first_name',
  'last_name',
  'full_name',
  'age',
  'phone_number',
  'career_tenure',
  'rep_type',
  'is_bd',
  'is_ria',
  'title',
  'firm_name',
  'firm_crd',
  'firm_aum',
  'firm_total_accounts',
  'firm_etf_assets',
  'firm_mf_assets',
  'firm_percent_assets_etf',
  'firm_percent_assets_mf',
  'office_state',
  'office_zipcode',
  'themes',
  'amp_score',
  'amp_score_ticker',
];

const acceptedFields = [
  'repcrd',
  'email',
  'first_name',
  'last_name',
  'full_name',
  'phone_number',
  'aum_self_reported',
  'age',
  'career_tenure',
  'current_firm_tenure_years',
  'title',
  'tickers_held',
  'firm_crd',
  'firm_etf_assets_millions',
  'firm_mf_assets_millions',
  'firm_name',
  'firm_number_bd_reps',
  'firm_number_ia_reps',
  'firm_total_accounts',
  'firm_total_assets_in_millions',
  'percent_assets_in_etf_and_mf',
  'office_state',
  'office_zipcode',
  'ria_employee_ratio',
];

const UploadModal = ({
  modalPage,
  setModalPage,
  closeModal,
  onResultsReturned,
  urlQuery,
  integrationId,
  setIntegrationId,
  integrationDetails,
  setIntegrationDetails,
  enableUploads,
}: Props) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(null);

  const [uploadedAdvisors, setUploadedAdvisors] = useState<any[]>([]);
  const [selectedRows, setSelectedRows] = useState<any>({});

  const [advisorSetFilter, setAdvisorSetFilter] = useState<any>(null);

  const [jobId, setJobId] = useState<null | string>(null);
  const [infoPageCopy, setInfoPageCopy] = useState<infoPageCopyType | null>();

  const [hubspotFields, setHubspotFields] = useState<any>([]);
  const [hubspotIntegrationId, setHubspotIntegrationId] = useState<null | string>(null);

  const showUploadModal = useStoreState((state) => state.integrations.showUploadModal);

  const setShowUploadModal = useStoreActions((actions) => actions.integrations.setShowUploadModal);
  const setJobUpdate = useStoreActions((actions) => actions.integrations.setJobUpdate);

  const location = useLocation();

  const [saveAdvisorSet] = useMutation(SAVE_ADVISOR_SET, {
    onCompleted: (data) => {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: `Integration import job created`,
        subheader: 'The advisors from your CRM should begin populating soon.',
        loader: false,
      });
      setTimeout(() => {
        closeModal();
      }, 2000);
    },
  });

  const [getHubspotFields] = useLazyQuery(GET_HS_FIELDS, {
    onCompleted: (data) => {
      setHubspotFields(data?.data?.hubspot?.contactProperties);
      setModalPage('HubspotAdvisorSets');
    },
  });

  const [uploadAdvisorDataset, { loading, data, error: uploadError }] = useMutation(UPLOAD_ADVISOR_DATASET, {
    onCompleted: (data) => {
      if (data?.uploadAdvisorsDataset?.success) {
        setJobId(data?.uploadAdvisorsDataset?.jobId ?? null);
      } else {
        setInfoPageCopy({
          header: 'Upload Failed',
          subheader: `Please check that your headers are properly mapped and try again.`,
          loader: false,
        });
      }
    },
  });

  const [validateSfIntegration, { loading: validateLoading, data: validateData, error: validateError }] = useMutation(
    VALIDATE_SF_INTEGRATION,
    {
      onCompleted: (data) => {
        setInfoPageCopy({
          header: 'Validating Configuration',
          subheader: `Your Salesforce configuration has been updated.`,
          loader: true,
        });
        if (data?.validateSfIntegration?.success) {
          setInfoPageCopy({
            header: 'Salesforce Configuration Updated',
            subheader: `Your Salesforce configuration has been updated.`,
            loader: false,
          });
        } else {
          setInfoPageCopy({
            header: 'Invalid Salesforce Configuration',
            subheader: (
              <span>
                We were unable to validate your Salesforce configuration. Please review the following error(s): <br />
                <br />
                {data?.validateSfIntegration?.validationErrors?.map((error: any) => {
                  return (
                    <>
                      <span style={{ width: '500px', margin: '0px 0px 0px -40px', whiteSpace: 'nowrap' }}>
                        • {error}
                      </span>
                      <br />
                    </>
                  );
                })}
                <PrimaryButtonShort style={{ margin: '20px' }} onClick={() => setModalPage('SfIntegrationConfig')}>
                  Go Back
                </PrimaryButtonShort>
              </span>
            ),
            loader: false,
          });
        }
      },
    },
  );

  const { data: uploadJobData, loading: uploadJobLoading } = useQuery(GET_UPLOAD_JOB, {
    skip: !jobId,
    variables: { jobId: String(jobId) },
    pollInterval: 500,
    fetchPolicy: 'network-only',
    onError: (error) => {
      setInfoPageCopy({
        header: 'Upload Job Failed',
        subheader: `Please check that your headers are properly mapped and try again.(${error.message})`,
        loader: false,
      });
      setJobId(null);
    },
  });

  useEffect(() => {
    const newJobUpdateLocal = uploadJobData?.jobs?.fromDramatiqId;
    if (newJobUpdateLocal?.status === 'RUNNING') {
      let currentPercent =
        typeof infoPageCopy?.subheader === 'string' &&
        !!Number(infoPageCopy?.subheader?.split(' ')?.[1]?.replace('%', ''))
          ? Number(infoPageCopy?.subheader?.split(' ')?.[1]?.replace('%', ''))
          : 0;
      if (currentPercent < 90) {
        currentPercent += randomNumber(5);
      }
      setInfoPageCopy({
        header: 'Processing Large Upload',
        subheader: `Upload ${currentPercent}% complete`,
        loader: true,
      });
    } else if (newJobUpdateLocal?.status === 'DONE') {
      setInfoPageCopy({
        header: 'Processing Large Upload',
        subheader: `Upload 100% complete`,
        loader: true,
      });
      setTimeout(() => {
        setInfoPageCopy({
          header: 'Upload Complete',
          subheader: `Your advisor set have been successfully uploaded.`,
          loader: false,
        });
      }, 800);
      setJobId(null);
    } else if (newJobUpdateLocal?.status === 'FAILED') {
      setJobUpdate(newJobUpdateLocal);
      setInfoPageCopy({
        header: 'Upload Failed',
        subheader: `Please check that your headers are properly mapped and try again.`,
        loader: false,
      });
      setJobId(null);
    }
  }, [uploadJobData]);

  useEffect(() => {
    if (urlQuery?.success) {
      if (urlQuery?.type === 'SALESFORCE') {
        setIntegrationId(urlQuery?.id);
        setIntegrationDetails(null);
        setModalPage('SfIntegrationConfig');
      } else if (urlQuery?.type === 'DYNAMICS') {
        getMDContacts();
      }
    }
  }, [urlQuery]);

  const updateSFIntegrationId = async (integrationId: string) => {
    setIntegrationId(integrationId);
    setModalPage('SalesforceAdvisorSets');
  };

  const uploadDataset = async (name: string, fieldMapping: any, manualFile?: any) => {
    try {
      setInfoPageCopy({
        header: 'Processing Large Upload',
        subheader: `Upload 0% complete`,
        loader: true,
      });
      setModalPage('InfoView');
      const fileToUpload = manualFile ? manualFile : uploadedFile;
      uploadAdvisorDataset({ variables: { file: fileToUpload, mapping: fieldMapping, name } });
    } catch (error: any) {
      console.warn('error uploading advisor dataset', error.message);
    }
  };

  const linkSalesforce = async (subdomain: string | null) => {
    try {
      const redirectUrl = window.location.origin + location.pathname;
      const requestURL =
        process.env.REACT_APP_BACKEND_URL +
        `crm/salesforce/?redirect_url=${redirectUrl}` +
        (subdomain ? `&subdomain=${subdomain}` : '');

      const response = await fetch(requestURL, {
        method: 'GET',
        headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token') },
      });
      const parsed = await response.json();
      setShowUploadModal(false);
      window.open(parsed.link);
    } catch (e: any) {
      //Not sure why I added this but this shouldn't be the place for this
      // if (Auth.checkLoggedOut(e.message)) {
      //   // await Auth.getNewToken();
      //   fetch(process.env.REACT_APP_BACKEND_URL + 'crm/salesforce/', {
      //     method: 'GET',
      //     headers: { Authorization: 'Bearer ' + window.localStorage.getItem('token') },
      //   })
      //     .then((e) => e.json())
      //     .then((e) => window.open(e.link))
      //     .catch((e) => console.warn('error linking salesfore: ', error));
      // }
    }
  };

  const [importDynamicsContacts] = useLazyQuery(IMPORT_DYNAMICS_CONTACTS, {
    onCompleted: (data) => {
      const outputArr: any[] = [];
      const contactArray = data?.dynamics?.contacts ?? [];
      for (let contact of contactArray) {
        if (contact?.['email']) {
          outputArr.push({
            firstName: contact?.['firstName'] ?? ' ',
            lastName: contact['lastName'] ?? ' ',
            email: contact['email'] ?? ' ',
          });
        }
      }
      const newSelectedAdvisors: any = {};
      for (let obj of outputArr) {
        newSelectedAdvisors[obj.email] = true;
      }
      setSelectedRows(newSelectedAdvisors);

      setUploadedAdvisors(outputArr);
    },
    onError: (error) => {
      setInfoPageCopy({
        header: 'Error pulling contacts',
        subheader: `Please try again. (${error.message})`,
        loader: false,
      });
    },
  });

  const getMDContacts = async () => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Pulling contacts from Microsoft Dynamics',
      loader: true,
    });
    try {
      let outputArr: any[] = [];
      importDynamicsContacts({ variables: { id: urlQuery?.id } });
    } catch (err: any) {
      setInfoPageCopy({
        header: 'Error pulling contacts',
        subheader: `Please try again. (${err.message})`,
        loader: false,
      });
      console.warn(err);
    }
  };

  const [createDynamicsIntegration] = useMutation(CREATE_DYNAMICS_INTEGRATION, {
    onCompleted: (data) => {
      if (data?.dynamicsCreateIntegration?.oauthLink) {
        window.open(data?.dynamicsCreateIntegration?.oauthLink);
        setInfoPageCopy({
          header: 'Microsoft Dynamics Integration Created',
          subheader: `Integration connected.`,
          loader: false,
        });
      } else {
        setInfoPageCopy({
          header: 'Dynamics Error',
          subheader: `An unexpected error occured while integrating with Dynamics`,
          loader: false,
        });
      }
    },
  });

  const linkDynamics = async (crmUrl: string) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Getting Contacts from Microsoft Dynamics',
      loader: true,
    });
    try {
      const redirectUrl = window.location.origin + location.pathname;
      console.log('dynamics details: ', redirectUrl);
      createDynamicsIntegration({ variables: { details: { crmUrl, redirectUrl } } });
    } catch (err: any) {
      console.warn('dynamics error:', err);
      setInfoPageCopy({
        header: 'Dynamics Error',
        subheader: `An unexpected error occured while importing your contacts. (${err.message})`,
        loader: false,
      });
    }
  };

  const pullHubspotFields = async (integrationId: any) => {
    try {
      setModalPage('InfoView');
      setInfoPageCopy({
        header: 'Pulling hubspot fields',
        subheader: `This may take a few moments.`,
        loader: true,
      });
      setHubspotIntegrationId(integrationId);
      getHubspotFields();
      // setModalPage('HubspotMatchHeaders');
    } catch (err: any) {
      setInfoPageCopy({
        header: 'Error pulling hubspot fields',
        subheader: `Please try again. (${err.message})`,
        loader: false,
      });
    }
  };

  const createHubspotImportJob = async (importName: string, propertiesMapping: any) => {
    setInfoPageCopy({
      header: 'Creating your hubspot import job.',
      loader: true,
    });
    setModalPage('InfoView');
    try {
      const formattedPropertiesMapping: any[] = Object.values(propertiesMapping).filter((item: any) => item?.srcKey);
      if (integrationId) {
        saveAdvisorSet({
          variables: {
            integrationId: Number(integrationId),
            name: importName,
            filterType: advisorSetFilter?.filterType,
            filterConfig: advisorSetFilter?.filterValue,
            startImport: true,
            propertiesMapping: formattedPropertiesMapping ?? [],
          },
        });
      }
    } catch (err: any) {
      setInfoPageCopy({
        header: 'Error creating import job.',
        subheader: `Please try again. (${err.message})`,
        loader: false,
      });
    }
  };

  const createSFImportJob = async (importName: string, propertiesMapping: any) => {
    try {
      setInfoPageCopy({
        header: 'Processing Large Upload',
        // subheader: `Upload 0% complete`,
        loader: true,
      });
      setModalPage('InfoView');
      const formattedPropertiesMapping: any[] = Object.values(propertiesMapping).filter((item: any) => item?.srcKey);
      saveAdvisorSet({
        variables: {
          integrationId: Number(integrationId),
          name: importName,
          filterType: advisorSetFilter?.filterType,
          filterConfig: advisorSetFilter?.filterValue,
          startImport: true,
          propertiesMapping: formattedPropertiesMapping ?? [],
        },
      });
      // if (response?.success) {
      //   setInfoPageCopy({
      //     header: 'Salesforce import job created',
      //     subheader: 'The advisors from your CRM should begin populating soon.',
      //     loader: false,
      //   });
      // } else {
      //   setInfoPageCopy({
      //     header: 'Error Creating Import Job',
      //     subheader: `An unknown error occured. (${response?.details})`,
      //     loader: false,
      //   });
      // }
    } catch (e: any) {
      setInfoPageCopy({
        header: 'Error Creating Import Job',
        subheader: e?.message,
        loader: false,
      });
      console.warn('Error creating dataset: ', e);
    }
  };

  const [updateSfIntegration] = useMutation(UPDATE_SF_INTEGRATION, {
    onCompleted: (data) => {
      setInfoPageCopy({
        header: 'Updating Salesforce Configuration',
        subheader: `This may take a few moments.`,
        loader: true,
      });
      if (data?.updateSfIntegration?.success) {
        setInfoPageCopy({
          header: 'Salesforce Configuration Updated',
          subheader: `Your Salesforce configuration has been updated.`,
          loader: false,
        });
        validateSfIntegration({ variables: { integrationId: integrationId ?? '' } });
      } else {
        setInfoPageCopy({
          header: 'Error Updating Salesforce Configuration',
          subheader: `Please contact support for more information.`,
          loader: false,
        });
      }
    },
    onError: (error) => {
      setInfoPageCopy({
        header: 'Error Updating Salesforce Configuration',
        subheader: `Please contact support for more information. (${error.message})`,
        loader: false,
      });
    },
  });

  const updateSfIntegrationConfig = async (
    alConfig: any,
    alObjectType: string,
    alAllowCampaign: boolean,
    nickname: string,
  ) => {
    try {
      setInfoPageCopy({
        header: 'Updating Salesforce Configuration',
        subheader: `This may take a few moments.`,
        loader: true,
      });
      setModalPage('InfoView');
      if (integrationId) {
        const readCrdField = alConfig.find((item: any) => item?.dstKey === 'read_repcrd')?.srcKey ?? '';
        console.log('advisorLeadRepCrdSfField', alConfig, readCrdField);
        updateSfIntegration({
          variables: {
            integrationId,
            nickname,
            advisorLeadObjectType: alObjectType,
            advisorLeadShouldExportCampaign: alAllowCampaign,
            advisorLeadPropertiesMapping: alConfig,
            advisorLeadRepCrdSfField: readCrdField,
          },
        });
      } else {
        setInfoPageCopy({
          header: 'Error Updating Salesforce Configuration',
          subheader: `Please contact support for more information.`,
          loader: true,
        });
      }
    } catch (e: any) {
      setInfoPageCopy({
        header: 'Error Updating Salesforce Configuration',
        subheader: `An unexpected error occured (${e?.message})`,
        loader: false,
      });
      console.warn('Error updating config: ', e);
    }
  };

  const [updateHsIntegration] = useMutation(UPDATE_HS_INTEGRATION, {});

  const updateHubspotIntegrationConfig = async (
    alConfig: any,
    alObjectType: string,
    alAllowCampaign: boolean,
    nickname: string,
  ) => {
    try {
      setInfoPageCopy({
        header: 'Updating Hubspot Configuration',
        subheader: `This may take a few moments.`,
        loader: true,
      });
      setModalPage('InfoView');
      if (integrationId) {
        const response: any = await updateHsIntegration({
          variables: {
            integrationId,
            propertiesMapping: alConfig,
            repCrdKeyField: alObjectType,
            // alAllowCampaign,
            nickname,
          },
        });
        setInfoPageCopy({
          header: 'Updating Hubspot Configuration',
          subheader: `This may take a few moments.`,
          loader: true,
        });
        if (response?.success) {
          setInfoPageCopy({
            header: 'Hubspot Configuration Updated',
            subheader: `Your Hubspot configuration has been updated.`,
            loader: false,
          });
        } else {
          setInfoPageCopy({
            header: 'Error Updating Hubspot Configuration',
            subheader: `Please contact support for more information.`,
            loader: true,
          });
        }
      } else {
        setInfoPageCopy({
          header: 'Error Updating Hubspot Configuration',
          subheader: `Please contact support for more information.`,
          loader: true,
        });
      }
    } catch (e: any) {
      setInfoPageCopy({
        header: 'Error Updating Hubspot Configuration',
        subheader: `An unexpected error occured (${e?.message})`,
        loader: false,
      });
      console.warn('Error updating config: ', e);
    }
  };

  const [createHubspotIntegration] = useMutation(CREATE_HS_INTEGRATION, {
    onCompleted: (data) => {
      setHubspotIntegrationId(data?.hubspotCreateIntegration?.integrationDetails?.integration?.id ?? null);
      pullHubspotFields(data?.hubspotCreateIntegration?.integrationDetails?.integration?.id);
    },
  });

  const linkHubspot = async (id: string, name: string) => {
    setModalPage('InfoView');
    setInfoPageCopy({
      header: 'Creating Hubspot Integration',
      loader: true,
    });
    try {
      createHubspotIntegration({
        variables: { details: { apiKey: id, nickname: name } },
      });
    } catch (err: any) {
      console.warn('hubspot error:', err);
      setInfoPageCopy({
        header: 'Hubspot Error',
        subheader: `An unexpected error occured while importing your contacts. (${err.message})`,
        loader: false,
      });
    }
  };

  const exit = () => {
    setShowUploadModal(!showUploadModal);
    setUploadedAdvisors([]);
    setModalPage(null);
    setUploadedFile(null);

    setError(null);
  };

  const GoBack = () => {
    switch (modalPage) {
      case 'SampleSpreadsheet':
        setModalPage('Landing');
        break;
      case 'UploadFile':
        setModalPage('Landing');
        break;
      case 'InfoView':
        setModalPage('Landing');
        break;
      case 'Hubspot':
        setModalPage('CRMSelection');
        break;
      case 'Salesforce':
        setModalPage('CRMSelection');
        break;
      case 'SalesforceIntegrations':
        setModalPage('CRMSelection');
        break;
      case 'SalesforceAdvisorSets':
        setModalPage('CrmStatus');
        break;
      case 'SalesforceMatchHeaders':
        setModalPage('CrmStatus');
        break;
      case 'Dynamics':
        setModalPage('CRMSelection');
        break;
      default:
        setModalPage('Landing');
        break;
    }
  };

  const PageSelector = (page: ModalPageType) => {
    switch (page) {
      case 'Landing':
        return <Landing setModalPage={setModalPage} />;
      case 'SampleSpreadsheet':
        return <SampleSpreadsheet setModalPage={setModalPage} />;
      case 'UploadFile':
        return (
          <UploadFile
            setModalPage={setModalPage}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            setUploadedAdvisors={setUploadedAdvisors}
            setSelectedRows={setSelectedRows}
          />
        );
      case 'UploadFlatFile':
        return (
          <UploadFlatFile
            setModalPage={setModalPage}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            setInfoPageCopy={setInfoPageCopy}
            enableUploads={!!enableUploads}
          />
        );
      case 'MatchHeaders':
        return (
          <MatchHeaders
            setModalPage={setModalPage}
            uploadedFile={uploadedFile}
            setUploadedFile={setUploadedFile}
            uploadDataset={uploadDataset}
            acceptedFields={acceptedFields}
            exampleTemplate={[]}
            datasetTypes={[]}
          />
        );
      case 'CRMSelection':
        return <CRMSelection setModalPage={setModalPage} />;
      case 'Hubspot':
        return (
          <HubspotIntegrations
            setModalPage={setModalPage}
            pullHubspotFields={pullHubspotFields}
            setIntegrationId={setIntegrationId}
            setIntegrationDetails={setIntegrationDetails}
          />
        );
      case 'HubspotNewIntegration':
        return <HubspotNewIntegration linkHubspot={linkHubspot} />;
      case 'ManualInput':
        return (
          <ManualInput
            uploadedAdvisors={uploadedAdvisors}
            setUploadedAdvisors={setUploadedAdvisors}
            uploadAdvisorDataset={uploadDataset}
            setUploadedFile={setUploadedFile}
          />
        );
      case 'HubspotMatchImportHeaders':
        return (
          <CrmMatchHeaders
            setModalPage={setModalPage}
            setInfoPageCopy={setInfoPageCopy}
            integrationId={integrationId}
            advisorSetFilter={advisorSetFilter}
            uploadDataset={createHubspotImportJob}
            uploadName="Hubspot Advisor Set"
            acceptedFields={acceptedFields}
          />
        );
      case 'HubspotAdvisorSets':
        return (
          <CrmAdvisorSets
            integrationId={integrationId}
            type={'Hubspot'}
            setModalPage={setModalPage}
            setAdvisorSetFilter={setAdvisorSetFilter}
          />
        );
      case 'CrmStatus':
        return <CrmStatus setModalPage={setModalPage} integrationDetails={integrationDetails} integrationJob={jobId} />;
      case 'SfCrmIntegrationConfig':
        return (
          <CrmIntegrationConfig
            setModalPage={setModalPage}
            updateIntegrationConfig={updateSfIntegrationConfig}
            acceptedFields={crmAcceptedFields}
            integrationId={integrationId}
            integrationDetails={integrationDetails}
            type={'SALESFORCE'}
          />
        );
      case 'HsCrmIntegrationConfig':
        return (
          <CrmIntegrationConfig
            setModalPage={setModalPage}
            updateIntegrationConfig={updateHubspotIntegrationConfig}
            acceptedFields={crmAcceptedFields}
            integrationId={integrationId}
            integrationDetails={integrationDetails}
            type={'HUBSPOT'}
          />
        );
      case 'Salesforce':
        return <Salesforce linkSalesforce={linkSalesforce} setModalPage={setModalPage} />;
      case 'SalesforceIntegrations':
        return (
          <SalesforceIntegrations
            setIntegrationId={updateSFIntegrationId}
            setModalPage={setModalPage}
            setIntegrationDetails={setIntegrationDetails}
          />
        );
      case 'SalesforceAdvisorSets':
        return (
          <CrmAdvisorSets
            integrationId={integrationId}
            type={'Salesforce'}
            setModalPage={setModalPage}
            setAdvisorSetFilter={setAdvisorSetFilter}
          />
        );
      case 'SalesforceMatchHeaders':
        return (
          <CrmMatchHeaders
            setModalPage={setModalPage}
            setInfoPageCopy={setInfoPageCopy}
            integrationId={integrationId}
            advisorSetFilter={advisorSetFilter}
            uploadDataset={createSFImportJob}
            acceptedFields={acceptedFields}
          />
        );
      case 'Dynamics':
        return <Dynamics setModalPage={setModalPage} linkDynamics={linkDynamics} />;
      case 'InfoView':
        return <InfoView pageCopy={infoPageCopy} />;
      default:
        return <Landing setModalPage={setModalPage} />;
    }
  };

  return (
    <ModalRoot open={!!modalPage} onClose={closeModal}>
      <ModalContainer
        style={{
          minHeight: '440px',
          maxHeight: '800px',
          minWidth: '740px',
          padding: '12px 16px',
          overflowY: 'scroll',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            height: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {modalPage !== 'Landing' && modalPage !== 'InfoView' && (
            <MainButton sx={{ margin: '0px 0px 0px', background: colors.white }} onClick={GoBack}>
              Back
            </MainButton>
          )}
          <IconButton sx={{ margin: '4px 0px 0px', height: '16px', width: '16px' }}>
            <img src={close_icon} onClick={closeModal} alt={'close'} />
          </IconButton>
        </div>
        {PageSelector(modalPage)}
      </ModalContainer>
    </ModalRoot>
  );
};

export default UploadModal;
