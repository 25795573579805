export const chartDataCache = {
  aum: [
    { label: '<10M', value: 11 },
    { label: '10-25M', value: 3 },
    { label: '25-50M', value: 7 },
    { label: '50-100M', value: 8 },
    { label: '100-500M', value: 93 },
    { label: '>500M', value: 342 },
  ],
  age: [
    { label: '<5', value: 28 },
    { label: '5-10', value: 63 },
    { label: '10-20', value: 162 },
    { label: '20-30', value: 149 },
    { label: '>30', value: 97 },
  ],
  type: [
    { label: 'BD Only', value: 60 },
    { label: 'RIA Only', value: 333 },
    { label: 'RIA & BD', value: 187 },
    { label: 'Neither', value: 177 },
  ],
  firmSize: [
    { label: '<10M', value: 11 },
    { label: '10-25M', value: 3 },
    { label: '25-50M', value: 7 },
    { label: '50-100M', value: 8 },
    { label: '100-500M', value: 93 },
    { label: '>500M', value: 342 },
  ],
  advisorTenure: [
    { label: '<5', value: 28 },
    { label: '5-10', value: 63 },
    { label: '10-20', value: 162 },
    { label: '20-30', value: 149 },
    { label: '>30', value: 97 },
  ],
  etfMfAllocation: [
    { label: '<10M', value: 10 },
    { label: '10-25M', value: 7 },
    { label: '25-50M', value: 11 },
    { label: '50-100M', value: 19 },
    { label: '100-500M', value: 66 },
    { label: '>500M', value: 295 },
  ],
};
