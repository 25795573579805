import React from 'react';
import { Column, H2, F3, Row, PrimaryButtonShort } from '..';
import { colors } from '../../styles';

interface Props {
  pullSendgridData: any;
}

const SendgridNewPull = ({ pullSendgridData }: Props) => {
  return (
    <Column
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        flex: 1,
      }}
    >
      <Row />
      <Column style={{ justifyContent: 'center', alignItems: 'center' }}>
        <H2 style={{ margin: '0px 0px 16px' }}>Import Sendgrid Data</H2>
        <F3>Update data pulled from sendgrid.</F3>
      </Column>
      <Row
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          borderTop: `1px solid ${colors.grey_01}`,
          padding: '16px 0px 0px',
        }}
      >
        <PrimaryButtonShort onClick={pullSendgridData}>Create Import Job</PrimaryButtonShort>
      </Row>
    </Column>
  );
};

export default SendgridNewPull;
