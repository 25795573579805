import React, { useState } from 'react';
import { ButtonUnstyled as Button } from '@mui/core';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { TextField } from '@mui/material';
import { colors, typography } from '../../styles';
import { PrimaryButton, F3 } from '../../components';
import loginLogo from '../../assets/logos/TifinAmp_Logo.png';
import { RESET_PASSWORD } from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useMutation } from '@apollo/client';

const loginRock = 'https://distill-ui-images.s3.us-east-2.amazonaws.com/loginRock.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '80%',
    margin: 'auto',
  },
  forms: {
    width: '100%',
  },
  logoStyle: {
    margin: '0 0 20px 0',
  },
  loginInput: {
    width: '100%',
    height: '3rem',
    color: '#797D83',
    marginTop: '2rem',
    paddingLeft: '1rem',
  },
  submitContainer: {
    width: '100%',
    margin: 'auto',
  },
  buttonStyle: {
    margin: '20px 0',
    padding: '10px 0',
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 5,
    width: '100%',
    background: '#242B2F',
    border: `2px solid #242b2f`,
    color: 'white',
    height: 40,
    fontWeight: 600,
    fontSize: 14,
    '&:hover': {
      background: 'white',
      color: '#242b2f',
      border: `2px solid #242b2f`,
    },
    '&:disabled': {
      background: '#D2D7DA',
      color: 'white',
      padding: '9px 20px',
      border: `1px solid #D2D7DA`,
    },
  },
  aidLinks: {
    textAlign: 'center',
    padding: '8px 0 0px 0',
    fontSize: 14,
    display: 'flex',
    width: 'fit-content',
    margin: 'auto',
    // [theme.breakpoints.down('xs')]: {
    //   flexWrap: 'wrap',
    //   justifyContent: 'center',
    // },
  },
  newAccount: {
    padding: '0px 5px',
    color: '#6d8c91',
    cursor: 'pointer',
  },
  login: {
    padding: '0px 5px',
    cursor: 'pointer',
  },
  forgotPassword: {
    padding: '0px 5px',
    cursor: 'pointer',
  },
  pageHeader: {
    fontSize: 18,
    fontWeight: 600,
    color: '#404B50',
    margin: 0,
  },
  pageSubHeader: {
    fontSize: 14,
    fontWeight: 400,
    color: '#758790',
    margin: '10px 0 20px 0',
  },
  submitMessage: {
    color: colors.green,
    fontSize: 18,
    fontWeight: 500,
    width: '80%',
    textAlign: 'center',
    margin: '40px auto 20px',
  },
  fieldOverride: {
    '& label.Mui-focused': {
      color: colors.grey_02,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.grey_02,
        border: `1px solid ${colors.grey_02}`,
      },
    },
  },
}));

const Root = styled('div')`
  height: 100vh;
  display: flex;
  flex: 1;
  padding: 0;
  background: ${colors.white_01};
`;

const Form = styled('div')`
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  flex:4;
    margin: auto,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
`;

const Content = styled('div')`
  max-width: 400px;
`;

const MainLogo = styled('img')`
  height: 60px;
  margin: '0 -20px 10px 0';
`;

const MainImage = styled('img')`
  display: flex;
  flex: 1;
`;

const Header = styled('h1')`
  ${typography.header_01};
`;

const Subheader = styled('p')`
  ${typography.footer_03};
  color: ${colors.grey_02};
`;

const loginCopy = {
  header: 'Reset your password',
  subHeader: 'To request a new password please enter your email address.',
};

interface Props {
  setFormType: any;
}

export const ResetPassword = ({ setFormType }: any) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();

  const [resetPassword, { loading, data, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => {
      if (data?.resetPassword?.success) {
        setSubmitted(true);
      }
    },
  });

  return (
    <Root>
      <Form>
        <Content>
          <MainLogo src={loginLogo} alt="login" />
          <Header>{loginCopy.header}</Header>
          <Subheader className={classes.pageSubHeader}>{loginCopy.subHeader}</Subheader>
          {!submitted ? (
            <>
              <TextField
                label="Email"
                variant="outlined"
                id="outlined-medium"
                size="small"
                focused
                className={classes.fieldOverride}
                sx={{ margin: '12px 0', width: '100%' }}
                value={emailAddress}
                onChange={(event) => setEmailAddress(event.target.value)}
              />
              <div className={classes.aidLinks}>
                <div id={'back-button'} className={classes.forgotPassword} onClick={() => navigate('/login/')}>
                  Return to Login
                </div>
              </div>
            </>
          ) : (
            <F3 sx={{ color: colors.dark_purple, margin: '30px 0px 20px' }}>
              Your request has been submitted. If we find an account associated with your email, we will send
              instructions to recover your account.
            </F3>
          )}

          <div className={classes.submitContainer}>
            {!submitted ? (
              <PrimaryButton onClick={() => resetPassword({ variables: { emailAddress } })}>
                {loading ? (
                  <Loader hideText={true} size={'9px'} color={colors.white_01} inputMargin={'0px 0px 0px'} />
                ) : (
                  'Submit'
                )}
              </PrimaryButton>
            ) : (
              <PrimaryButton children={'Go Back'} onClick={() => navigate('/login/')} />
            )}
          </div>
        </Content>
      </Form>
      <MainImage /*className={classes.coolRocks}*/ src={loginRock} alt="Granite Background" />
    </Root>
  );
};
