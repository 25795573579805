import React from 'react';
import { Row, F3 } from '.';
import { Switch } from '@mui/material';
import { colors } from '../styles';

interface Props {
  copy: string;
  value: boolean;
  setValue: any;
  style?: any;
}

const SwitchFilter = ({ style, copy, value, setValue }: Props) => {
  return (
    <Row
      style={{
        alignItems: 'center',
        borderRadius: '5px',
        padding: '0px 0px 0px 8px',
        margin: '8px 16px 0px 0px',
        height: '46px',
        ...style,
      }}
    >
      <F3 style={{ whiteSpace: 'nowrap', color: colors.grey_02 }}>{copy}</F3>
      <Switch
        sx={{
          '& .MuiSwitch-thumb': {
            color: colors.white_01,
          },
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              //transform: 'translateX(12px)',
              //color: '#fff',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: colors.green,
              },
            },
          },
          '& .MuiSwitch-track': {
            backgroundColor: colors.grey_00,
          },
        }}
        checked={value}
        onClick={() => {
          setValue(!value);
        }}
      />
    </Row>
  );
};
export default SwitchFilter;
