import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { H2, H3, F3, PrimaryButton } from '..';
import { colors } from '../../styles';
import { CSVToArray } from '../../helpers/csvHelper';
import { downloadCSVFile } from '../../helpers/saveJSONFile';
import { exampleTemplate } from './example-template';
import { styled } from '@mui/system';
import closeIcon from '../../assets/icons/close.svg';
import { formatFirstName, formatLastName } from '../../helpers/dataFormatter';
import { ConstructionOutlined } from '@mui/icons-material';

const MainButton = styled('button')({
  display: 'flex',
  //   padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: colors.white_01,
  fontFamily: 'Inter',
  border: 0,
  color: '#758790',
  fontWeight: 500,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  margin: '15px 10px 0px 10px',
  padding: '10px 12px',
  '&:hover': {
    opacity: 0.7,
  },
});

const OuterBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#EFF6F5',
  borderRadius: '4px',
  padding: '10px 10px',
});

const FileUploadBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '640px',
  height: '150px',
  backgroundColor: '#EFF6F5',
  border: `1px dashed #758790`,
  borderRadius: '4px',
  padding: '4px',
  cursor: 'pointer',
});

const FileInfoRow = styled('div')({
  display: 'flex',
  width: '640px',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Inter',
  fontSize: '14px',
  color: '#758790',
});

const FileInfo = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '90px',
  alignItems: 'center',
});

const BrowseFiles = styled('label')({
  fontWeight: '600',
  cursor: 'pointer',
});

const UploadButton = styled('button')({
  display: 'flex',
  padding: '10px 50px',
  width: 145,
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 5,
  height: 40,
  background: '#242B2F',
  border: `1px solid #242B2F`,
  color: 'white',
  fontWeight: 400,
  fontSize: 14,
  lineHeight: 1,
  cursor: 'pointer',
  textDecoration: 'none',
  backgroundPosition: 'left',
  '&:hover': {
    opacity: 0.7,
    backgroundPosition: 'right',
  },
  '&:disabled': {
    opacity: '0.5',
    background: '#242B2F',
    border: `1px solid #242B2F`,
  },
  '&:focus': {
    background: 'linear-gradient(to left, #B1CEE8 50%, #242B2F 50%)',
    backgroundSize: '200%',
    transition: '1s ease-out',
  },
  margin: '0px 0px 0px 16px',
});

type Props = {
  setModalPage: any;
  uploadedFile: any;
  setUploadedFile: any;
  setUploadedAdvisors: any;
  setSelectedRows: any;
};

const UploadFile = ({ setModalPage, uploadedFile, setUploadedFile, setUploadedAdvisors, setSelectedRows }: Props) => {
  const [error, setError] = useState<null | string>(null);
  const [emphasis, setEmphasis] = useState({});
  const [buttonText, setButtonText] = useState('Upload');

  useEffect(() => {
    setButtonText('Upload');
  }, [uploadedFile]);

  const allowDrop = (event: any) => {
    setEmphasis(!emphasis);
    event.preventDefault();
  };

  const drop = (event: any) => {
    setError(null);
    event.preventDefault();
    console.log('event.dataTransfer: ', event.dataTransfer);
    const data = event.dataTransfer.files[0];
    console.log('data.type', data.type);
    if (data.type === 'text/csv') {
      setUploadedFile(data);
    } else {
      setError('Please only upload files in the .csv format');
      setUploadedFile(null);
    }
    setEmphasis(false);
  };

  const formatField = (rawField: string) => {
    // This makes the csv formatting a little more forgivable
    let splitString = rawField.toLowerCase().split(/[-_ ]/g);
    splitString = splitString.map((item, index) => (index === 0 ? item : item[0].toUpperCase() + item.slice(1)));
    const formattedString = splitString.join('');
    switch (formattedString) {
      case 'firstname':
        return 'firstName';
      case 'lastname':
        return 'lastName';
      case 'fullname':
        return 'fullName';
      case 'homevalue':
        return 'homeValue';
      case 'estimatedinvestableassets':
        return 'estimatedInvestableAssets';
      case 'savingbonds':
        return 'savingBonds';
      case 'mortgagevalue':
        return 'mortgageValue';
      case 'percentofhomevaluepaidoff':
        return 'percentOfHomeValuePaidOff';
      case 'proxycreditscore':
        return 'proxyCreditScore';
      case 'proxycreditscore':
        return 'proxyCreditScore';
      case 'aumselfreported':
        return 'aumSelfReported';
      case 'currentfirmtenureyears':
        return 'currentFirmTenureYears';
      case 'careertenure':
        return 'careerTenure';
      case 'firmtotalassetsinmillions':
        return 'firmTotalAssetsInMillions';
      case 'firmtotalaccounts':
        return 'firmTotalAccounts';
      case 'firmetfassetsmillions':
        return 'firmEtfAssetsMillions';
      case 'firmmfassetsmillions':
        return 'firmMfAssetsMillions';
      default:
        return formattedString;
        break;
    }
  };

  const acceptedFields = [
    'age',
    'aumSelfReported',
    'careerTenure',
    'currentFirmTenureYears',
    'email',
    'firmCrd',
    'firmEtfAssetsMillions',
    'firmMfAssetsMillions',
    'firmName',
    'firmTotalAccounts',
    'firmTotalAssetsInMillions',
    'firstName',
    'fullName',
    'isBd',
    'isProspect',
    'isRia',
    'officeState',
    'officeZipcode',
    'lastName',
    'percentAssetsInEtfAndMf',
    'riaEmployeeRatio',
    'tickersHeld',
    'title',
  ];

  const readFile = () => {
    let fileReader = new FileReader();

    const handleFileRead = async (e: any) => {
      // console.log('reading file');
      try {
        const content: any = fileReader.result;
        let csv = CSVToArray(content);
        console.log('csv', csv);
        let lines = [];
        let unformattedFields: any = csv[0];
        const fields = unformattedFields.map((item: any) => formatField(item));
        console.log('fields', fields);
        for (let x = 1; x < csv.length; x++) {
          let obj: any = {};
          let line: any = csv[x];
          for (let index in line) {
            if (line[index] !== undefined && acceptedFields.includes(fields[index])) {
              switch (fields[index]) {
                case 'fullName':
                  obj['firstName'] = formatFirstName(line[index]);
                  obj['lastName'] = formatLastName(line[index]);
                case 'isProspect':
                  obj[fields[index]] = line[index].length > 1 ? (line[index] == true ? true : false) : false;
                  break;
                case 'percentAssetsInEtfAndMf':
                  obj[fields[index]] = line[index].length > 0 ? parseInt(line[index]) : 0;
                  break;
                default:
                  obj[fields[index]] = line[index];
                  break;
              }
            }
          }
          if (obj['email'] !== '') {
            lines.push(obj);
          }
        }

        //first go to edit advisors via function here
        const newSelectedAdvisors: any = {};
        for (let obj of lines) {
          newSelectedAdvisors[obj.email] = true;
        }
        setSelectedRows(newSelectedAdvisors);
        setUploadedAdvisors(lines);

        setTimeout(() => {
          setButtonText('Done');
        }, 1000);
      } catch (error: any) {
        setError(error.message);
        setModalPage('ReviewData');
      }
    };

    fileReader.onloadend = handleFileRead;
    if (uploadedFile) {
      fileReader.readAsText(uploadedFile);
    }
  };

  const proceedFunc = () => {
    setModalPage('MatchHeaders');
  };

  return (
    <div
      style={{
        margin: '30px 0px 0px',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <H2 sx={{ color: colors.grey_03, margin: '0px 0px 20px' }}>Upload with a Spreadsheet</H2>
      <MainButton
        onClick={() => downloadCSVFile('example-template.csv', exampleTemplate)}
        style={{ margin: '10px 0px 10px' }}
      >
        Download Template
      </MainButton>
      <OuterBox>
        <FileUploadBox
          onDrop={drop}
          onDragOver={allowDrop}
          style={{
            border: emphasis ? `1px dashed #758790` : `2px dashed #758790`,
          }}
        >
          <F3 sx={{ color: colors.grey_03, margin: '10px 0px 6px' }}>
            Drop your file(s) here, or{' '}
            <input
              type="file"
              id="upload-file-button"
              accept=".csv"
              hidden
              onChange={(e: any) => {
                setUploadedFile(e.target.files[0]);
              }}
            />
            {/* @ts-ignore */}
            <BrowseFiles htmlFor={'upload-file-button'}>Browse files</BrowseFiles>
          </F3>
          <F3 sx={{ color: colors.grey_02 }}>Max file size 1MB</F3>
        </FileUploadBox>
      </OuterBox>
      {error && <F3 style={{ color: colors.red }}>{error}</F3>}
      {uploadedFile && (
        <FileInfoRow>
          <p>{uploadedFile.name}</p>
          <FileInfo>
            {/* @ts-ignore */}
            <p>{Number.parseFloat(uploadedFile?.size / 1000).toFixed(1)} KB</p>
            <IconButton
              onClick={() => {
                setError(null);
                setUploadedFile(null);
              }}
            >
              <img src={closeIcon} />
            </IconButton>
          </FileInfo>
        </FileInfoRow>
      )}
      <div style={{ display: 'flex', flex: 1, width: '100%', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <UploadButton
          style={{ width: '140px', margin: uploadedFile ? '-10px 0px 0px' : '0px 0px 0px' }}
          onClick={() => setModalPage('MatchHeaders')}
          disabled={!uploadedFile}
        >
          {buttonText}
        </UploadButton>
      </div>
    </div>
  );
};

export default UploadFile;
