import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '../../components/TextField';

import { PrimaryButtonShort, Row, F3, Column, Divider } from '../../components';
import { useStoreState, useStoreActions } from '../../store';
import { colors } from '../../styles';
import { UPDATE_USER_PROFILE } from '../../api/auth';
import Loader from '../../components/Loader';
import { useMutation } from '@apollo/client';

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

type Props = {};

const Container = styled('div')(`
  width: 95%;
  margin: 30px auto;
  display: flex;
`);

const PageTitle = styled('div')(`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #242B2F;
  width: 200px;
  margin-right: 40px;
`);

export const AccountDetails = (props: Props) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [timezone, setTimezone] = useState<string>('');

  const [currentPassword, setCurrentPassword] = useState<any>('');
  const [newPassword, setNewPassword] = useState<any>('');
  const [confirmPassword, setConfirmPassword] = useState<any>('');
  const [updatePasswordStatus, setUpdatePasswordStatus] = useState<any>(null);
  const [updateInfoStatus, setUpdateInfoStatus] = useState<any>(null);

  const userInfo: any = useStoreState((state) => state.user?.info);

  const [updateProfile, { loading, data }] = useMutation(UPDATE_USER_PROFILE, {
    variables: { profileData: { firstName, lastName, phone, timezone } },
    onCompleted: (data) => {
      setUpdateInfoStatus({ success: true, details: 'User information updated successfully' });
    },
    onError: (error) => {
      setUpdateInfoStatus({ success: false, details: 'Error updating user information' });
    },
  });

  // const updatePassword = async () => {
  //   setUpdatePasswordStatus({ success: 'loading', details: '' });
  //   try {
  //     if (newPassword === confirmPassword) {
  //       const response = await Auth.resetUserPassword(newPassword, currentPassword);
  //       setUpdatePasswordStatus(response);
  //       console.log('update password: ', response);
  //       if (response?.sucess) {
  //         setCurrentPassword('');
  //         setNewPassword('');
  //         setConfirmPassword('');
  //       }
  //     } else {
  //       setUpdatePasswordStatus({ success: false, details: 'Passwords do not match.' });
  //     }
  //   } catch (error: any) {
  //     setUpdatePasswordStatus({ success: false, details: error.message });
  //   }
  // };

  const updateUserProfile = async () => {
    setUpdateInfoStatus({ success: 'loading', details: '' });
    updateProfile();
  };

  useEffect(() => {
    setFirstName(userInfo?.firstName);
    setLastName(userInfo?.lastName);
    setEmail(userInfo?.email);
    setPhone(userInfo?.phone);
    const currentTimezone = Intl.DateTimeFormat('default').resolvedOptions().timeZone;
    setTimezone(userInfo?.timezone ? userInfo?.timezone : currentTimezone);
  }, []);

  return (
    <Container>
      <Column style={{ width: '100%' }}>
        <PageTitle>Account Details</PageTitle>
        <Divider />
        <F3 style={{ color: colors.grey_02 }}>Signed in as {userInfo?.email}</F3>
        <Row style={{ width: '666px', justifyContent: 'space-between' }}>
          <TextField
            inputText={firstName}
            setInputText={setFirstName}
            titleText={'First Name'}
            placeholder={'Type your first name...'}
          />
          <TextField
            inputText={lastName}
            setInputText={setLastName}
            titleText={'Last Name'}
            placeholder={'Type your last name...'}
          />
        </Row>
        <TextField
          disabled
          inputText={email}
          setInputText={setEmail}
          titleText={'Email'}
          placeholder={'Type your email...'}
        />
        <TextField inputText={phone} setInputText={setPhone} titleText={'Phone'} placeholder={'Type your phone...'} />
        {/* <TextField
          inputText={timezone}
          setInputText={setTimezone}
          titleText={'Timezone'}
          placeholder={'Type your timezone...'}
        >
          {timezones.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField> */}
        {updateInfoStatus && (
          <F3 style={{ color: updateInfoStatus.success ? colors.green : colors.red }}>{updateInfoStatus.details}</F3>
        )}
        <PrimaryButtonShort
          onClick={updateUserProfile}
          style={{ width: '180px' }}
          disabled={firstName === userInfo?.firstName && lastName === userInfo?.lastName && phone === userInfo?.phone}
        >
          {updateInfoStatus?.success === 'loading' ? (
            <Loader color={colors.white} hideText size={'10px'} inputMargin={'2px 0px'} />
          ) : (
            'Save Changes'
          )}
        </PrimaryButtonShort>
        <Divider style={{ width: '100%' }} />
        {/* <PageTitle style={{ margin: '0px 0px 20px' }}>Change Password</PageTitle>
        {/* <F3 style={{ color: colors.grey_02 }}>Last Changed October, 21 2022</F3> */}
        {/*<TextField
          inputText={currentPassword}
          setInputText={setCurrentPassword}
          titleText={'Current Password'}
          placeholder={'Enter your current password...'}
          hidePassword
        />
        <TextField
          inputText={newPassword}
          setInputText={setNewPassword}
          titleText={'New Password'}
          placeholder={'Enter your new password...'}
          hidePassword
        />
        <TextField
          inputText={confirmPassword}
          setInputText={setConfirmPassword}
          titleText={'Confirm Password'}
          placeholder={'Enter your new password...'}
          hidePassword
        />
        {updatePasswordStatus && (
          <F3 style={{ color: updatePasswordStatus.success ? colors.green : colors.red }}>
            {updatePasswordStatus.details}
          </F3>
        )}
        <PrimaryButtonShort
          // onClick={updatePassword}
          style={{ width: '180px' }}
          disabled={true}
        >
          {updatePasswordStatus?.success === 'loading' ? (
            <Loader color={colors.white} hideText size={'10px'} inputMargin={'2px 0px'} />
          ) : (
            'Update Password'
          )}
        </PrimaryButtonShort> 
        <Divider style={{ width: '100%' }} />
        */}
      </Column>
    </Container>
  );
};
