import { colors } from './index';

export const mainFont = {
  fontFamily: 'Inter',
};

export const main_font = ` 
  font-family: Inter;
`;

export const title = `
  ${main_font};
  font-size:24px;
  font-weight:600;
  line-height:28px;
  letter-spacing:-1px;
`;

export const header_01 = `
  ${main_font}
  font-size: 18px;
  line-height:26px;
  font-weight: 500;
  color: ${colors.black_01};
`;

export const header_02 = `
  ${main_font}
  font-size: 16px;
  line-height:24px;
  font-weight: 500;
  color: ${colors.black_01};
`;

export const header_03 = `
  ${main_font}
  font-size: 14px;
  line-height:22px;
  font-weight: 500;
  color: ${colors.black_01};
`;

export const header_04 = `
  ${main_font}
  font-size: 12px;
  line-height:20px;
  font-weight: 500;
  color: ${colors.black_01};
`;

export const footer_01 = `
  ${main_font}
  font-size: 18px;
  line-height:24px;
  font-weight: 400;
  color: ${colors.black_01};
`;

export const footer_02 = `
  ${main_font}
  font-size: 16px;
  line-height:22px;
  font-weight: 400;
  color: ${colors.black_01};
`;

export const footer_03 = `
  ${main_font}
  font-size: 14px;
  line-height:20px;
  font-weight: 400;
  color: ${colors.black_01};
`;

export const footer_04 = `
  ${main_font}
  font-size: 12px;
  line-height:18px;
  font-weight: 400;
  color: ${colors.black_01};
`;
