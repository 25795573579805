import React, { useState, useEffect } from 'react';
import { PackageContainer } from '../../../components/PackageContainer';
import { PackageHome } from '../../../components/PackageHome';
import { PackageFundSelector } from '../../../components/PackageFundSelector';
import { PackageCustomize } from '../../../components/PackageCustomize';
import { H2 } from '../../../components';
import Signals from './Signals';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from '../../../store';
import { DistillScoreLogo } from '../../../components/DistillScoreLogo';
import { formatNumber, formatTimestamp } from '../../../helpers/dataFormatter';

import SelectIcon from '../../../assets/icons/select.svg';
import CustomizeIcon from '../../../assets/icons/customize.svg';
import DiscoverIcon from '../../../assets/icons/discover.svg';
// import { updatePackageRunName } from '../../../api/integrations';
import moment from 'moment';
import InfoModal from '../../../components/InfoModal';
import { GET_INDIVIDUAL_LEADS_ARCHIVE } from '../../../api/driveSales/individualLeadsAPI';

const headerOptions = {
  standard: [{ title: 'Individual Characteristics', id: 'individual' }],
  leadPool: [{ title: 'Customize Lead Pool', id: 'individualLeadPool' }],
};

const archiveTableHeaders = [
  {
    header: 'Name',
    key: 'customName',
    formatData: (row: any) => {
      return <H2>{row.customName ? row.customName : '-'}</H2>;
    },
    //bold: true,
  },
  {
    header: 'Date',
    key: 'dateTime',
    formatData: (row: any) => {
      return formatTimestamp(row.dateRun);
    },
    //bold: true,
  },
  {
    header: 'Individual Leads',
    key: 'individualLeads',
    //formatData: (data) => '20',
    formatData: (row: any) => (row.serveCount ? row.serveCount : '-'),
  },
];
type Props = {};

export const IndividualLeads = (props: Props) => {
  const basePath = '';
  const title = 'Individual Leads';
  const navigate = useNavigate();
  const [completed, setCompleted] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const [generateError, setGenerateError] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [activeTable, setActiveTable] = useState('standard');
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const { progress, progressTitles, fundsSelected, filterOptions, characteristics, runId, packageRunName, dateRun } =
    useStoreState((state) => {
      return state.generateSignals.individualLeads;
    });

  const {
    setProgress,
    setProgressTitles,
    setFundsSelected,
    setFilterOptions,
    setCharacteristics,
    setRunId,
    loadArchive,
    setPackageRunName,
    setDateRun,
  } = useStoreActions((state) => {
    return state.generateSignals.individualLeads;
  });

  useEffect(() => {
    setProgress(0);
  }, []);

  const openArchive = async (Archive: any) => {
    loadArchive(Archive);
    navigate('signals');
  };

  const generateResults = async () => {
    setGenerateError(null);
    setRunId(null);

    //setTimeout(() => setGenerateError('Error generating results@'), 1000);
    const excludedFields = Object.entries(characteristics)
      .filter((e) => !e[1])
      .map((e) => e[0]);

    try {
      const selectedFunds = Object.keys(fundsSelected);
      const apiFilters = Object.values(selectedFilters).flatMap((item: any) => item.value);
      // const results = await IndividualLeadsAPI.generateLeads(selectedFunds, excludedFields, apiFilters);
      // console.log('results from generate leads: ', results);
      //   const response = await updatePackageRunName('Unnamed Journey', results.runId);
      setPackageRunName('Unnamed Journey');
      setDateRun(moment().format());
      // setRunId(results.runId);
    } catch (error: any) {
      console.warn('error in generateResults: ', error);
      setGenerateError(error.message);
    }
  };

  const PackageSteps = [
    {
      label: 'Home',
      link: 'home',
      Component: PackageHome,
      props: {
        name: title,
        description: `This package identifies individual leads by comparing their interests to your fund's thematic exposures.`,
        stepDescriptions: [
          {
            icon: SelectIcon,
            name: 'Select funds',
            description: `for which you’d like to find advisors`,
          },
          {
            icon: CustomizeIcon,
            name: 'Customize',
            description: 'characteristics for the algorithm',
          },
          {
            icon: DiscoverIcon,
            name: 'Discover',
            description: 'the most promising advisors',
          },
        ],
        packageStats: [
          { label: 'Completed Journeys', data: '527' },
          { label: 'Total Prospects', data: '2,701' },
        ],
        nextStep: () => {
          setShowInfoModal(true);
          //   navigate('funds');
          //   setFundsSelected({});
          //   setProgressTitles(['Current', ' ', ' ']);
          //   setProgress(1);
          //   setRunId(null);
          //   setPackageRunName(null);
          //   setDateRun(null);
        },
        openArchive,
        GET_ARCHIVE_QUERY: GET_INDIVIDUAL_LEADS_ARCHIVE,
        tableHeaders: archiveTableHeaders,
      },
    },
    {
      label: 'Select Funds',
      link: 'funds',
      Component: PackageFundSelector,
      props: {
        setProgress,
        completed,
        fundsSelected,
        setFundsSelected,
        maxSelections: 1,
        textData: {
          pageHeader:
            "Select a fund for which you'd like to find individual investors (You may select a maximum of one fund)",
        },
        nextAction: () => {
          setProgressTitles(['Complete', 'Current', '']);
          setProgress(2);
          navigate('custom-criteria');
        },
      },
    },
    {
      label: 'Customize Criteria',
      link: 'custom-criteria',
      Component: PackageCustomize,
      props: {
        nextAction: async () => {
          setProgressTitles(['Complete', 'Complete', 'Complete']);
          setProgress(3);
          setFirstTime(true);
          generateResults();
          navigate('signals');
        },
        backAction: () => setProgress(1),
        dataFromSource: [],
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        headerOptions,
        activeTable,
        setActiveTable,
        headerData: {
          standard: {
            // pageHeading: 'Select characteristics for evaluation',
            // pageSubText: 'Distill utilizes the selected characteristics to identify which leads are most compatible. Customize characteristics to include or leave them all.',
            pageHeading: (
              <span>
                Would you like to customize characteristics to calculate the best match reflected in the{' '}
                <DistillScoreLogo packageName="individualLeads" width={'80px'} />?
              </span>
            ),
            pageSubHeading: () => {
              return (
                <span>
                  <DistillScoreLogo packageName="individualLeads" width={'80px'} /> uses all available characteristics
                  to identify individuals most likely to purchase your selected funds. This is our recommended approach.
                  However, you can deselect certain characteristics below.
                </span>
              );
            },
          },
          customizeHeader: 'Characteristics',
          percentPrefix: 'Individuals',
          clientChartHeading: 'See Investor Distribution',
        },
        headerText:
          'Use these visualizations to help determine which characteristics to select. You may want to emphasize some characteristics over others.',
        dataPoints: [{ label: 'Individuals', fill: '#8380CC', id: 'percent_tifin' }],
        fullpage: false,
        getDataForMetric: async (metric: any, setAverage: any, setClientCount: any) => {
          try {
            // const result = await IndividualLeadsAPI.getAverages();
            // setAverage(result.averages[metric.averageDataKey]);
            // setClientCount(formatNumber(result.countLeads));
          } catch (error) {
            console.warn('error in getDataForMetric', error);
          }
        },
        getExplanationForMetric: (metric: any, setExplanationForMetric: any) => {
          // IndividualLeadsAPI.visualizationExplanations()
          //   .then((e) => {
          //     setExplanationForMetric(e[metric.averageDataKey]);
          //   })
          //   .catch((e) => console.log(e));
        },
        getVizData: async (setData: any) => {
          // let data = await IndividualLeadsAPI.visualization();
          // setData(data);
        },
        selectedFilters,
        setSelectedFilters,
        getLeadPool: () => {},
        showClientCount: false,
        vizV2: true,
      },
    },
    {
      label: 'Signals',
      link: 'signals',
      Component: Signals,
      props: {
        fundsSelected,
        setFundsSelected,
        textData: {
          radarHeading: 'Fund Characteristics vs. Individual Interest',
          allChartHeading: 'All Individuals',
          bestChartHeading: 'Best Individuals',
          themesHeading: 'Your Fund Themes',
          topTitle: 'Top Lead',
          //metricName: 'Individual',
          distillExplanation:
            'is the result of a thematic and financial analysis of each Individual. The higher the score, the stronger their financials, and the more their thematic activity is aligned with your fund.',
        },
        featureSelections: characteristics,
        setFeatureSelections: setCharacteristics,
        runId,
        generateError,
        setRunId,
        filterOptions,
        setFilterOptions,
        firstTime,
        setFirstTime,
        getRadarChartData: async (clientId: string) => {
          try {
            //@ts-ignore
            let data = await IndividualLeadsAPI.getRadarChartData(clientId, runId);
            let newRadarChartData = [];
            console.log('data', data);
            for (let obj in data) {
              //console.log('data <><>', data, data[obj]);
              newRadarChartData.push({
                name: obj,
                all_investors: data[obj]['all_investors'] ? data[obj]['all_investors'] : 0,
                investor: data[obj]['investor'] ? data[obj]['investor'] : 0,
              });
            }
            return newRadarChartData;
          } catch (e) {
            console.log(e);
          }
        },
        radarFeatures: [
          { label: 'All Investors', id: 'all_investors' },
          { label: 'Individual', id: 'investor' },
        ],
      },
    },
  ];

  const navSteps = {
    0: 'home',
    1: 'funds',
    2: 'custom-criteria',
    3: 'signals',
  };

  return (
    <>
      <PackageContainer
        runId={runId}
        packageRunName={packageRunName}
        setPackageRunName={setPackageRunName}
        dateRun={dateRun}
        basePath={basePath}
        title={title}
        PackageSteps={PackageSteps}
        progress={progress}
        progressTitles={progressTitles}
        setProgress={setProgress}
        navSteps={navSteps}
      />
      <InfoModal
        modalStatus={showInfoModal}
        closeModal={() => setShowInfoModal(false)}
        modalCopy={{
          header: 'Information',
          subheader: `To ensure the highest quality results. One of our data scientist will work with you to create a bespoke set of results for your use case. Please contact your support representative to learn more.`,
        }}
      />
    </>
  );
};
