import React from 'react';
import { Column, Row } from '..';
import { styled } from '@mui/material';
import { typography, colors } from '../../styles';
import { F4, H4 } from '..';
import moment from 'moment';

/**
 * Original AmpScoreView Props by Mike
 */
type Props = {
  score: number;
  scoreName: string;
  scoreChange?: number | null | undefined;
  scoreSubtext: string;
  algoType?: string;
  lastUpdated?: string;
};

const ViewContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
  margin: '10px 0px 0px 0px',
});

const AmpScore = styled('div')({
  ...typography.mainFont,
  margin: '0px 0px 0px 0px',
  fontSize: '38px',
  lineHeight: 'normal',
  fontWeight: '600',
  color: colors.grey_03,
});

const ScoreName = styled('div')({
  ...typography.mainFont,
  fontSize: '16px',
  fontWeight: '500',
  color: colors.grey_03,
  margin: '6px 0px 0px 0px',
  textAlign: 'left',
  cursor: 'pointer',
});

/**
 * Added by Luda
 */
const ScoreSubtext = styled('div')({
  ...typography.mainFont,
  fontSize: '14px',
  color: colors.lilac,
  margin: '0px 0px 8px 0px',
  textAlign: 'left',
  cursor: 'pointer',
  fontWeight: '500',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '220px',
});

const ScoreIncrease = styled(AmpScore)({
  margin: '20px 4px 0px 0px',
  fontWeight: '400',
  fontSize: '16px',
  color: colors.green,
});

const ScoreDecrease = styled(ScoreIncrease)({
  color: colors.red,
});

/**
 * Refactored AmpScoreView by Luda
 * @param score
 * @param scoreChange
 * @param packageName
 * @param scoreSubtext
 * @param scoreName
 * @constructor
 */

export function AmpScoreView({ score, scoreChange, scoreSubtext, scoreName, algoType, lastUpdated }: Props) {
  return (
    <ViewContainer>
      <Column style={{ justifyContent: 'center' }}>
        <ScoreName>{scoreName}</ScoreName>
        <ScoreSubtext>{scoreSubtext}</ScoreSubtext>
      </Column>
      <Column style={{ alignItems: 'flex-start' }}>
        <Row style={{ margin: 'auto 0px' }}>
          <AmpScore style={{ color: algoType === 'Redemption' ? colors.red_02 : colors.green_01 }}>
            {score}
            {/* <span style={{ fontSize: '16px' }}></span> */}
          </AmpScore>
          {/* Design Preview Block  -- PLEASE REMOVE ME */}
          {!!scoreChange &&
            (scoreChange > 0 ? (
              <ScoreIncrease>(+{scoreChange ?? '2'})</ScoreIncrease>
            ) : (
              <ScoreDecrease>({scoreChange ?? '2'})</ScoreDecrease>
            ))}
          {/* end of Design Preview Block */}
        </Row>
        {false && (
          <Row style={{ justifyContent: 'flex-end', margin: '-5px 0px 0px' }}>
            <H4 style={{ fontSize: '8px', lineHeight: '11px', color: colors.grey_02, width: '110px' }}>
              CHANGE SINCE {moment(lastUpdated).format('DD/MM')}
            </H4>
          </Row>
        )}
        {/* <Row>
          {scoreChange && (
            <F3 style={{ color: colors.grey_02, margin: '0px 6px 0px 6px' }}>
              {formatDistillScore(score - scoreChange)}% &#8594; {formatDistillScore(score)}%
            </F3>
          )}
        </Row> */}
      </Column>
    </ViewContainer>
  );
}
export default AmpScoreView;
