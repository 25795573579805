import React from 'react';
import { colors } from '../styles';
import Loader from './Loader';
import { styled } from '@mui/material/styles';
import { PrimaryButton, H2, H3, ModalRoot } from '../components';

const ModalContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'column',
  width: '500px',
  // height: '230px',
  backgroundColor: colors.white_00,
  //paddingBottom: 120,
  borderRadius: '5px',
  padding: '16px 32px',
});

interface Props {
  modalStatus: boolean;
  closeModal: any;
  headerText: any;
  subtext: any;
  buttonText: any;
  hideLoader?: boolean;
}

const LoadingModal = ({ modalStatus, closeModal, headerText, subtext, buttonText, hideLoader }: Props) => {
  return (
    <ModalRoot
      open={modalStatus}
      onClose={closeModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalContainer>
        <H2 style={{ textAlign: 'center', display: 'flex', flex: 1, margin: '16px auto' }}>{headerText}</H2>
        {/* <p className={classes.subHeader}>{subtext}</p> */}
        {!hideLoader && (
          <div style={{ margin: '0px 180px' }}>
            <Loader />
          </div>
        )}
        <PrimaryButton onClick={closeModal}>{buttonText}</PrimaryButton>
      </ModalContainer>
    </ModalRoot>
  );
};

export default LoadingModal;
