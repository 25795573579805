import React, { useState, useEffect } from 'react';
import { colors, typography } from '../../../styles';
import { useStoreState } from '../../../store';
import { F4, CardHeader, Row, H3, H2 } from '../../../components';
// import downArrowGray from '../../assets/icons/arrow-down-grey.svg';
import ArrowGrey from '../../../assets/icons/arrow-grey.svg';

import { formatPackageName, formatFullName, formatPercent } from '../../../helpers/dataFormatter';

import LeadsTable from '../../../components/Table/LeadsTable';
import TopRankedCards from '../../../components/TopRankedCards';
import { randomNumber } from '../../../helpers/numberGenerator';
import { styled } from '@mui/material/styles';
import { CampaignAmpScoreCard } from '../../../components/CampaignAmpScoreCard';
import Loader from '../../../components/Loader';
import ClientModal from '../../../components/ClientModal';
// import influenceAPI from '../../../api/driveSales/influenceAPI';
// import discoverLeadsAPI from '../../../api/driveSales/discoverLeadsAPI';

const PageBody = styled('div')({
  overflowY: 'scroll',
  height: '100%',
  padding: '0px 20px',
});

const HeaderContainer = styled('div')({
  margin: '0px',
});

const TopCardContainer = styled('div')({
  //width: '95%',
  margin: '0px auto 0px auto',
  display: 'flex',
  flex: 1,
  justifyContent: 'space-evenly',
  position: 'relative',
  'z-index': 6,
});

const PageTitle = styled('div')({
  ...typography.mainFont,
  letterSpacing: '-2px',
  fontSize: '48px',
  fontWeight: '600',
  lineHeight: '56px',
  color: colors.grey_03,
});

interface Props {
  setSelectedCampaign: any;
  campaign?: any;
}

export const DetailedCampaignView = ({ campaign, setSelectedCampaign }: Props) => {
  //   const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [scoredLeads, setScoredLeads] = useState<any[]>([]);
  const [leads, setLeads] = useState<any[] | null>([]);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [sortKey, setSortKey] = useState<string>('');

  const [selectedLead, setSelectedLead] = useState(null);
  const [profileCardProps, setProfileCardProps] = useState<any>(null);
  const [error, setError] = useState<any>(null);

  const demoAccount = useStoreState((state) => state.user.demoAccount);

  // console.log('detailed campaign view campaign: ', campaign);

  useEffect(() => {
    getLeads(campaign);
  }, [campaign]);

  const exit = () => {
    // closeModal();
    setSelectedLead(null);
  };

  const selectLead = (lead: any) => {
    setSelectedLead(lead);
  };

  const generateCardProps = (
    modalOpen: boolean,
    setModalOpen: any,
    closeUserProfile: any,
    textData: any,
    userInfo: any,
  ) => ({
    advisorLeads: {
      modalOpen,
      setModalOpen,
      contentType: 'advisorData',
      close: closeUserProfile,
      radarFeatures: [
        { label: 'All Advisors', id: 'all_advisors' },
        { label: 'Selected Advisor', id: 'advisor' },
      ],
      textData,
      userData: userInfo,
    },
  });

  const getLeads = async (campaign: any) => {
    setLoading(true);
    setError(null);
    setLeads(null);
    try {
      const packageName = campaign?.package ? campaign?.package : campaign?.run?.package;
      let newLeadArray: any = [];
      console.log('in getLeads campaign: ', campaign, packageName);
      const newResults: any = null;
      if (packageName === 'INFLUENCE') {
        const influenceRunId = campaign?.runhistorySet?.[0]?.id;
        // const newResults = await influenceAPI.getResults(Number(influenceRunId), page, pageSize, sortKey, '', []);
        newLeadArray = newResults?.results?.advisors?.list ?? [];
        console.log('newLeads array: ', newResults, newLeadArray);
      } else if (packageName === 'DISCOVER_LEADS') {
        const discoverLeadsRunId = campaign?.runhistorySet?.[0]?.id;
        //  const newResults = await discoverLeadsAPI.getResults(
        //   Number(discoverLeadsRunId),
        //   page,
        //   pageSize,
        //   sortKey,
        //   '',
        //   [],
        // );
        newLeadArray = newResults?.advisors;
      } else {
        setError({ header: 'Error', subheader: `Newsletter segments do not impact AMP Score.` });
        newLeadArray = null;
      }

      if (demoAccount) {
        newLeadArray = newLeadArray.map((leadItem: any) => {
          let newLatestScore = leadItem.score ? leadItem.score : leadItem.leadScore;
          newLatestScore += randomNumber(11, 0);
          return {
            ...leadItem,
            runHistory: campaign?.run,
            latestScore: newLatestScore,
          };
        });
      }

      setLeads(newLeadArray);
      const newCardProps = generateCardProps(true, exit, () => setSelectedLead(null), {}, selectedLead);
      //@ts-ignore
      const newProfileCardProps = newCardProps?.['advisorLeads'];
      setProfileCardProps(newProfileCardProps);
      const newlyScoredLeads = newLeadArray?.map((person: any) => {
        let currentScore = person.leadScore ? person.leadScore : person.score ? person.score : 0;
        console.log('currentScore: ', currentScore, person?.latestScore);
        const demo = true;
        if (demo) {
          const scoreChange = randomNumber(campaign?.averageScoreIncrease, 0);
          const latestScore = currentScore + scoreChange;
          return {
            ...person,
            latestScore,
            scoreChange,
          };
        } else {
          return {
            ...person,
            scoreChange: person.latestScore && currentScore ? person.latestScore - currentScore : 0,
          };
        }
      });
      let sortedLeads = newlyScoredLeads?.sort((a: any, b: any) => {
        if (a.scoreChange === b.scoreChange) {
          return a.latestScore < b.latestScore ? 1 : -1;
        } else {
          return a.scoreChange < b.scoreChange ? 1 : -1;
        }
      });
      setScoredLeads(sortedLeads ? sortedLeads : []);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn('getLeads error: ', error);
    }
  };

  const resultsHeader = [
    {
      header: 'Change',
      key: 'scoreChange',
      formatData: (lead: any) => {
        return (
          <span
            style={{
              fontSize: '16px',
              fontWeight: '600',
              color: lead.scoreChange > 0 ? colors.green : colors.grey_03,
              margin: '0px 3px',
            }}
          >
            {lead.scoreChange > 0 && '+'}
            {lead.scoreChange && lead.scoreChange > 0 ? formatPercent(lead?.scoreChange) : '0%'}
          </span>
        );
      },
    },
    {
      header: 'Score',
      key: 'leadScore',
      formatData: (lead: any) => (
        <span>
          {lead.leadScore ? lead.leadScore : lead.score}%
          <img
            src={ArrowGrey}
            alt="expand"
            style={{
              transform: 'rotate(90deg)',
              transition: '0.3s',
              height: '8px',
              margin: '0px 12px 1px',
            }}
          />
          {lead.latestScore && (lead.latestScore > lead.leadScore || lead.latestScore > lead.score)
            ? lead.latestScore
            : lead.leadScore
              ? lead.leadScore
              : lead.score}
          %
        </span>
      ),
    },
    {
      header: 'Name',
      formatData: (lead: any) => {
        return formatFullName(lead?.advisor ? lead?.advisor : lead);
      },
    },
  ];

  return (
    <PageBody>
      {loading ? (
        <Loader inputMargin={'100px 46%'} />
      ) : (
        <>
          <HeaderContainer>
            <Row style={{ margin: '30px 0px 0px' }}>
              <H3 style={{ cursor: 'pointer' }} onClick={() => setSelectedCampaign(null)}>
                Outcomes
              </H3>
              <img
                src={ArrowGrey}
                alt="expand"
                style={{
                  height: '8px',
                  transform: 'rotate(90deg)',
                  transition: '0.3s',
                  margin: '7px 8px 0px',
                }}
              />
              <H3 style={{ cursor: 'pointer' }}>{campaign?.name ? campaign?.name : campaign?.customName}</H3>
            </Row>
            <PageTitle style={{}}>{campaign?.name}</PageTitle>
          </HeaderContainer>
          <H2 style={{ letterSpacing: '-1px', fontWeight: '600', color: colors.grey_03 }}>{campaign?.description}</H2>
          <F4 style={{ width: '550px', color: colors.grey_02, textAlign: 'left', margin: '0px 0px 32px 0px' }}>
            Here you can see how your leads AMP Scores have been impacted thus far in your Nurture campaign. Each of
            your leads below have been ranked by the improvement in their AMP Score, based upon the interest they’ve
            shown in your campaign.
          </F4>
          <CampaignAmpScoreCard id={`selected-campaign-score-card`} selectedCampaign={campaign} type={'Campaigns'} />
          {loading ? (
            <Loader inputMargin={'100px 46%'} />
          ) : (
            <>
              <div
                style={{ boxShadow: colors.main_box_shadow, margin: '16px 0px', borderRadius: '5px' }}
                id={'top-leads-container'}
              >
                <CardHeader sx={{ background: colors.white_00 }}>Top Leads by improvement in AMP Score</CardHeader>
                <TopCardContainer>
                  <TopRankedCards
                    expanderFunc={(row: any) => selectLead({ ...row?.advisor, ...row })}
                    cardData={scoredLeads}
                    cardProps={{ ...profileCardProps, contentType: 'nurtureAdvisors' }}
                    selectedClient={selectedLead}
                  />
                </TopCardContainer>
              </div>
              {/* </div> */}
              {scoredLeads?.slice(3).length > 0 && (
                <div style={{ display: 'flex', flex: 1 }}>
                  <LeadsTable
                    tableHeaders={resultsHeader}
                    data={scoredLeads.slice(3)}
                    //@ts-ignore
                    dataControls={{
                      selections: [],
                      setSelections: () => {},
                      searchFunc: () => {},
                      searchString: '',
                    }}
                    headerButtons={[]}
                    loading={loading}
                    view={(row: any) => {
                      selectLead({ ...row?.advisor, ...row });
                    }}
                    containerStyle={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'column',
                      overflowY: 'scroll',
                    }}
                    innerContainerStyle={{
                      //display: 'flex',
                      width: '100%',
                      height: '100%',
                      overflowY: 'scroll',
                      margin: '0px 16px 0px 16px',
                    }}
                    headerStyle={{
                      padding: '26px 10px 20px',
                    }}
                    headerFunctions={[]}
                    chartTitle={'Ranking of leads by change in AMP Score'}
                  />
                </div>
              )}
            </>
          )}
          <ClientModal
            client={selectedLead}
            close={() => setSelectedLead(null)}
            expandedModal={!!selectedLead}
            showNurture
            {...profileCardProps}
          />
        </>
      )}
    </PageBody>
  );
};
